import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

interface LoadingProps {
  isLoading: boolean;
}

const Loading = ({ isLoading }: LoadingProps) => {
  return (
    <>
      <Backdrop
        open={isLoading}
        sx={{ background: " rgba(0, 0, 0, 0.2)", zIndex: 10 }}
      >
        <CircularProgress
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}
        />
      </Backdrop>
    </>
  );
};

export default Loading;
