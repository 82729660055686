import { instance } from "../Instance";
import type { CommonApi } from "../type";
import type { EmailParams, VerifyEmail } from "./types";

export const verifyEmail = async (emailParams: EmailParams) => {
  const { authorization, code, collectPersonal, receiveMarketing } =
    emailParams;
  const { data } = await instance.post<CommonApi<VerifyEmail>>(
    "/email/verify",
    { code, collectPersonal, receiveMarketing },
    {
      headers: {
        Authorization: `${authorization}`
      }
    }
  );
  return data;
};

export const resendEmail = async (authorization: string) => {
  const { data } = await instance.post<CommonApi<string>>(
    "/resend/authenticate-code",
    {},
    {
      headers: {
        Authorization: `${authorization}`
      }
    }
  );
  return data;
};
