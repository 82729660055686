import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { useRecoilState } from "recoil";
import { getPaymentsView } from "../../api/v2/Payments";
import { addCommas } from "../../libs/thousands-commas";
import isModalOpenAtom from "../../recoil/isModalOpen";
import SendReceiptModal from "./SendReceiptModal";

interface PaymentViewModalProps {
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
  paymentKeys: any;
}

const PaymentViewModal = ({
  isOpen,
  setIsOpen,
  paymentKeys
}: PaymentViewModalProps) => {
  // ===============================================================================================
  // 스테이트
  // ===============================================================================================
  const [isModalOpen, setIsModalOpen] = useRecoilState(isModalOpenAtom);

  // 모달 열기 스테이트
  const [emailModal, setEmailModal] = useState(false);

  // ===============================================================================================
  // 리액트 쿼리 - 카드사별 상세 조회
  // ===============================================================================================
  // 거래 내역 영수증 조회
  const { data, isLoading } = useQuery(
    ["/payments/view", paymentKeys],
    () => getPaymentsView(paymentKeys),
    {
      keepPreviousData: true,
      enabled: !!paymentKeys && paymentKeys.length > 0, // paymentKeys가 있을 때만 조회
      onError: (error: any) => {
        setIsModalOpen({
          value: true,
          position: "top",
          alertSeverity: "error",
          message: error?.response?.data?.message
        });
      }
    }
  );

  const payment = data?.content.list[0];

  // ===============================================================================================
  // 모달 열기
  // ===============================================================================================
  const handleClickModelOpen = () => {
    setEmailModal(true);
  };

  return (
    <>
      <Dialog
        open={isOpen}
        fullWidth
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "480px"
            }
          }
        }}
      >
        <DialogTitle
          sx={{ boxShadow: "0px 5px 14px -1px rgba(204, 204, 204, 0.5)" }}
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          신용카드 매출전표
          <Stack direction={"row"} gap={2}>
            <Button variant="outlined" onClick={handleClickModelOpen}>
              영수증 발송
            </Button>
            <IconButton onClick={() => setIsOpen(false)}>
              <CloseIcon />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <Box sx={{ border: "1px solid #999", px: 3, py: 2, mt: 3 }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                position: "relative",
                width: "100%",
                borderBottom: "4px solid #FF6634"
              }}
            >
              <Typography
                sx={{
                  fontSize: "16px",
                  display: "block",
                  marginBottom: "6px",
                  color: "#FF6634"
                }}
              >
                <b>온라인 신용카드 매출전표</b>
              </Typography>
              <img
                src="https://payment.ozicpay.com/img/ozicpay_logo_text.png"
                alt="ozicpay logo"
                width="56"
                height="auto"
                style={{
                  zIndex: 10,
                  position: "absolute",
                  right: 0,
                  bottom: "-4px",
                  marginLeft: "auto"
                }}
                loading="lazy"
              />
            </Box>

            <Grid container spacing={2} sx={{ mt: 1 }}>
              <Grid item xs={6}>
                <Typography fontSize={14}>카드종류</Typography>
              </Grid>
              <Grid item xs={6} style={{ textAlign: "right" }}>
                <Typography fontSize={14}>
                  {payment?.cardCompanyName}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography fontSize={14}>카드번호</Typography>
              </Grid>
              <Grid item xs={6} style={{ textAlign: "right" }}>
                <Typography fontSize={14}>{payment?.cardNumber}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography fontSize={14}>구매자</Typography>
              </Grid>
              <Grid item xs={6} style={{ textAlign: "right" }}>
                <Typography fontSize={14}>{payment?.buyerName}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography fontSize={14}>거래일시(취소일자)</Typography>
              </Grid>
              <Grid item xs={6} style={{ textAlign: "right" }}>
                <Typography fontSize={14}>{payment?.createdAt}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography fontSize={14}>상품명</Typography>
              </Grid>
              <Grid item xs={6} style={{ textAlign: "right" }}>
                <Typography fontSize={14}>{payment?.orderName}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography fontSize={14}>할부</Typography>
              </Grid>
              <Grid item xs={6} style={{ textAlign: "right" }}>
                <Typography fontSize={14}>
                  {payment?.installmentMonth}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography fontSize={14}>승인번호</Typography>
              </Grid>
              <Grid item xs={6} style={{ textAlign: "right" }}>
                <Typography fontSize={14}>{payment?.approvalNum}</Typography>
              </Grid>
            </Grid>

            <Box
              sx={{
                borderTop: "2px solid #FF6634",
                marginTop: 2,
                paddingTop: 2
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Typography fontSize={14}>과세금액</Typography>
                </Grid>
                <Grid item xs={6} style={{ textAlign: "right" }}>
                  <Typography fontSize={14}>
                    {addCommas(Number(payment?.taxAmount))}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography fontSize={14}>부가세</Typography>
                </Grid>
                <Grid item xs={6} style={{ textAlign: "right" }}>
                  <Typography fontSize={14}>
                    {addCommas(Number(payment?.vat))}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography fontSize={14}>합계</Typography>
                </Grid>
                <Grid item xs={6} style={{ textAlign: "right" }}>
                  <Typography fontSize={14}>
                    {addCommas(Number(payment?.price))}
                  </Typography>
                </Grid>
              </Grid>
            </Box>

            <Typography
              variant="h6"
              sx={{ fontSize: "16px", marginTop: 2, fontWeight: "bold" }}
            >
              가맹점 정보
            </Typography>
            <Box
              sx={{
                borderTop: "2px solid #FF6634",
                mt: 0.5,
                mb: 2
              }}
            >
              <Grid container spacing={2} mt={1}>
                <Grid item xs={6}>
                  <Typography fontSize={14}>가맹점명</Typography>
                </Grid>
                <Grid item xs={6} style={{ textAlign: "right" }}>
                  <Typography fontSize={14}>{payment?.mallName}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography fontSize={14}>가맹점번호</Typography>
                </Grid>
                <Grid item xs={6} style={{ textAlign: "right" }}>
                  <Typography fontSize={14}>{payment?.mallId}</Typography>
                </Grid>
              </Grid>
            </Box>
            <ul
              style={{
                display: "block",
                listStyleType: "circle",
                fontSize: "12px",
                color: "#999",
                marginTop: "unset",
                marginBottom: "24px",
                paddingInlineStart: "16px"
              }}
            >
              <li style={{ marginBottom: "8px" }}>
                본 메일은 발신전용이며, 결제내용 확인의 용도 외 어떠한 용도로도
                사용되지 않습니다.
              </li>
              <li style={{ marginBottom: "8px" }}>
                결제하신 물품에 대한 취소 및 배송, 반품 문의는 해당 상점에서
                확인해주시기 바랍니다.
              </li>
              <li>
                본 메일은 ㈜옵타움플랫폼의 전자결제인 OZICPAY에서 자동발송하는
                것으로 '전자상거래 등에서의 소비자 보호에 관한 법률 제8조 3항'에
                따라 해당 상점과는 별도로 카드사 결제내역을 통보해드립니다.
              </li>
            </ul>

            <Box sx={{ background: "#F9F9F9", padding: "16px" }}>
              <Typography
                sx={{ fontSize: "12px", lineHeight: 1.6, color: "#999" }}
              >
                <strong
                  style={{
                    color: "#999",
                    marginBottom: "8px",
                    display: "block"
                  }}
                >
                  ㈜옵타움플랫폼
                </strong>
                대표 : 김병규 | 부산 금정구 금정로 61 옵타움빌딩 (3층)
                <br />
                고객센터 : 1577-6281 | E-mail : msupport@optatumplatform.com
                <br />
                사업자등록번호 : 582-81-01285 | 전자지급결제대행업 등록번호 :
                02-004-00132
                <br />
                <span style={{ display: "block", marginTop: "8px" }}>
                  ⓒ 2023. Optatumplatform Co.,Ltd. All rights reserved.
                </span>
              </Typography>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>

      {/* 이메일 전송 모달창 */}
      <SendReceiptModal
        paymentKeys={paymentKeys}
        isOpen={emailModal}
        setIsOpen={setEmailModal}
      />
    </>
  );
};
export default PaymentViewModal;
