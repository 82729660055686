import SaveIcon from "@mui/icons-material/Save";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import { useRecoilState } from "recoil";
import { PurchaseTotalData } from "../../api/v2/Purchases/types";
import NewExcelDownload from "../../libs/new-excel-download";
import isModalOpenAtom from "../../recoil/isModalOpen";
import PurchasesState from "../../recoil/purchases/atom";
import LoadingButton from "../LoadingButton";

interface PurchaseTotalColumnHeadCell {
  id: keyof PurchaseTotalData;
  label: string;
}
const columns: PurchaseTotalColumnHeadCell[] = [
  {
    id: "mallId",
    label: "가맹점 ID"
  },
  {
    id: "mallName",
    label: "가맹점명"
  },
  {
    id: "datePeriod",
    label: "매입일자"
  },
  {
    id: "receiveTotal",
    label: "거래합계 (건수)"
  },
  {
    id: "confirmTotal",
    label: "승인합계 (건수)"
  },
  {
    id: "cancelTotal",
    label: "취소합계 (건수)"
  },
  {
    id: "cardConfirmFee",
    label: "카드사승인 수수료"
  },
  {
    id: "cardCancelFee",
    label: "카드사 취소 수수료"
  },
  {
    id: "purchasePrice",
    label: "입금예정금액"
  }
];

interface Props {
  data: PurchaseTotalData[];
  isLoading: boolean;
}

const PurchaseTotal = ({ data, isLoading }: Props) => {
  // ===========================================================================
  // 리코일 스테이트
  // ===========================================================================
  const [isModalOpen, setIsModalOpen] = useRecoilState(isModalOpenAtom);
  const [PurchasesTotal, setPurchasesTotal] = useRecoilState(PurchasesState);

  // ===========================================================================
  // 엑셀 다운로드
  // ===========================================================================
  const handleTotalPurchaseExcel = () => {
    const content = data;
    if (!content || content.length === 1) {
      setIsModalOpen({
        value: true,
        position: "top",
        alertSeverity: "error",
        message: "데이터가 없습니다."
      });
      return;
    } else {
      setIsModalOpen({
        value: true,
        position: "top",
        alertSeverity: "success",
        message: "다운로드를 시작합니다."
      });
    }
    const merchantTotalList = {
      가맹점Id: "통합",
      가맹점명: "통합",
      매입일자: content[content.length - 1].datePeriod,
      "거래합계(건수)": content[content.length - 1].receiveTotal,
      "승인합계(건수)": content[content.length - 1].confirmTotal,
      "취소합계(건수)": content[content.length - 1].cancelTotal,
      "카드사승인 수수료": content[content.length - 1].cardConfirmFee,
      "카드사 취소 수수료": content[content.length - 1].cardCancelFee,
      입금예정금액: content[content.length - 1].purchasePrice
    };

    const merchantsList = content.map((d: PurchaseTotalData) => ({
      가맹점Id: d.mallId,
      가맹점명: d.mallName,
      매입일자: d.datePeriod,
      "거래합계(건수)": d.receiveTotal,
      "승인합계(건수)": d.confirmTotal,
      "취소합계(건수)": d.cancelTotal,
      "카드사승인 수수료": d.cardConfirmFee,
      "카드사 취소 수수료": d.cardCancelFee,
      입금예정금액: d.purchasePrice
    }));

    const thead = [
      "가맹점Id",
      "가맹점명",
      "매입일자",
      "거래합계(건수)",
      "승인합계(건수)",
      "취소합계(건수)",
      "카드사승인 수수료",
      "카드사 취소 수수료",
      "입금예정금액"
    ];
    NewExcelDownload({
      thead,
      merchantTotalList,
      merchantsList,
      sheetName:
        PurchasesTotal.mallName === ""
          ? "승인·취소 내역 가맹점별 합계"
          : `승인·취소 내역 합계_${PurchasesTotal.mallName}`,
      fileName:
        PurchasesTotal.mallName === ""
          ? "승인·취소 내역 가맹점별 합계.xlsx"
          : `승인·취소 내역 합계_${PurchasesTotal.mallName}.xlsx`
    });
  };

  return (
    <Box>
      {/* 총합계 ------------------------------------------------------------------------------------------------- */}
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"end"}
        mb={1}
      >
        <Typography sx={{ fontWeight: "bold" }}>총합계</Typography>
        <LoadingButton
          icon={<SaveIcon />}
          size="medium"
          fullWidth={false}
          color="secondary"
          variant="outlined"
          loading={false}
          handleClick={handleTotalPurchaseExcel}
        >
          EXCEL 다운로드
        </LoadingButton>
      </Stack>
      <Paper
        sx={{
          border: "1px solid #F2F3F5",
          borderRadius: 3,
          overflow: "hidden",
          mb: 3
        }}
      >
        <TableContainer>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow sx={{ backgroundColor: "#FBFBFB" }}>
                {columns.map((column, index) => (
                  <TableCell
                    variant="head"
                    align="center"
                    key={index}
                    sx={{ borderTop: "none" }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data && !isLoading ? (
                <>
                  {data.slice(-1).map((i, index) => (
                    <TableRow key={index}>
                      {columns.map((column) => (
                        <TableCell
                          key={column.id}
                          align="center"
                          sx={{ borderBottom: "none" }}
                        >
                          {column.id === "mallId" || column.id === "mallName"
                            ? "통합"
                            : i[column.id]}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </>
              ) : (
                <TableRow>
                  {columns.map((column) => (
                    <TableCell key={column.id}>
                      <Skeleton variant="rounded" animation="wave" />
                    </TableCell>
                  ))}
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>

      {/* 가맹점별 합계 ------------------------------------------------------------------------------------------- */}
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"end"}
        mb={1}
      >
        <Typography sx={{ fontWeight: "bold" }}>가맹점별 합계</Typography>
      </Stack>
      <Paper
        sx={{
          border: "1px solid #F2F3F5",
          borderRadius: 3,
          overflow: "hidden"
        }}
      >
        <TableContainer>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow sx={{ backgroundColor: "#FBFBFB" }}>
                {columns.map((column) => (
                  <TableCell
                    variant="head"
                    align="center"
                    key={column.id}
                    sx={{ borderTop: "none" }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data && !isLoading ? (
                <>
                  {data.map((i, index) => (
                    <TableRow
                      key={i.mallId}
                      sx={{
                        backgroundColor:
                          index === data.length - 1 ? "#FBFBFB" : ""
                      }}
                    >
                      {columns.map((column) => (
                        <TableCell
                          key={column.id}
                          align="center"
                          sx={{
                            borderBottom:
                              index === data.length - 1 ? "none" : ""
                          }}
                        >
                          {i[column.id]}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </>
              ) : (
                <TableRow>
                  {columns.map((column) => (
                    <TableCell key={column.id}>
                      <Skeleton variant="rounded" animation="wave" />
                    </TableCell>
                  ))}
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Box>
  );
};
export default PurchaseTotal;
