import { createTheme, ThemeProvider } from "@mui/material";

interface Props {
  children: React.ReactNode;
}

const Fonts = ({ children }: Props) => {
  const theme = createTheme({
    typography: {
      fontFamily: "'Noto Sans KR', sans-serif",
      fontSize: 7
    }
  });
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export default Fonts;
