import React from "react";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Slide from "@mui/material/Slide";
import { useRecoilState } from "recoil";
import isModalOpenAtom from "../../recoil/isModalOpen";

const Alert = () => {
  const [isModalOpen, setIsModalOpen] = useRecoilState(isModalOpenAtom);
  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") return;
    setIsModalOpen((prevValue) => ({ ...prevValue, value: false }));
  };
  return (
    <Snackbar
      open={isModalOpen.value}
      autoHideDuration={5000}
      anchorOrigin={{
        vertical: isModalOpen.position === "top" ? "top" : "bottom",
        horizontal: "center"
      }}
      onClose={handleClose}
      TransitionComponent={(props) => (
        <Slide
          {...props}
          direction={isModalOpen.position === "top" ? "down" : "up"}
        />
      )}
    >
      <MuiAlert
        severity={isModalOpen.alertSeverity}
        variant="filled"
        onClose={handleClose}
        sx={{ width: "100%", color: "white" }}
      >
        {isModalOpen.message}
      </MuiAlert>
    </Snackbar>
  );
};

export default Alert;
