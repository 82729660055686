import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import LogoImage from "../../images/logo_contract.png";
import Divider from "@mui/material/Divider";
import Fonts from "./Fonts";
import grey from "@mui/material/colors/grey";
import Grid from "@mui/material/Grid";
import { getTodayKor } from "../../libs/get-today";
import { MerchantsDetail } from "../../api/Merchants/types";

interface PageProps {
  data?: MerchantsDetail;
}

const Page7 = ({ data }: PageProps) => {
  return (
    <Fonts>
      <Box
        sx={{
          width: "210mm",
          height: "297mm",
          backgroundColor: "white",
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <Card
          sx={{
            width: "195mm",
            height: "282mm",
            backgroundColor: "white",
            border: "1px solid black"
          }}
        >
          {/* 머릿글 ======================================================== */}
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            sx={{
              width: "100%",
              height: "15mm"
            }}
          >
            <Stack
              justifyContent={"center"}
              alignItems={"center"}
              sx={{
                width: "120px"
              }}
            >
              {/* <Box component={"img"} src={LogoImage}></Box> */}
            </Stack>
            <Stack
              justifyContent={"center"}
              alignItems={"center"}
              sx={{ fontSize: "18px", fontWeight: 700, color: "#a29fb0" }}
            >
              [부칙 2] 서비스 신청서
            </Stack>
            <Stack
              justifyContent={"center"}
              alignItems={"center"}
              sx={{
                width: "35mm",
                color: "white",
                fontSize: "16px"
              }}
            >
              <Box component={"img"} src={LogoImage}></Box>
            </Stack>
          </Stack>
          <Divider
            sx={{ border: "none", borderTop: "4px solid #a29fb0", mx: 1 }}
          ></Divider>

          {/* 본문 ========================================================== */}
          <Box
            component={"ol"}
            start={3}
            sx={{
              height: "254mm",
              mt: 1,
              px: 1
            }}
          >
            <Typography sx={{ fontWeight: 700, fontSize: "8pt" }}>
              [고객확인 관련 추가 기재 사항]
            </Typography>
            <Typography>
              ※ 「특정 금융거래정보의 보고 및 이용 등에 관한 법률」
              제5조의2(고객확인의무) 및 시행령 제10조의5(실소유자 확인)에 따라
              작성이 필요한 서식으로, 아래 정보는 동법 제5조의 4에 따라
              금융거래관계가 종료한 때부터 5년간 보존됩니다.
            </Typography>
            <Typography>
              ※ 회사는 「특정 금융거래정보의 보고 및 이용 등에 관한 법률」에
              따라 고객확인을 위해 정보, 문서, 자료 등을 추가로 요청할 수
              있으며, 만일 정보/자료 제출이 되지 않을 경우 거래가 거절 또는
              중단될 수 있습니다.
            </Typography>
            <Stack direction={"row"} alignItems={"center"} spacing={1}>
              <Typography>
                ※ 본인은 아래 정보가 변경될 경우 즉시 통보하며, 통보하지
                않음으로 인해 발생한 모든 문제에 대해 책임지는 것에 동의합니다.{" "}
              </Typography>
              <Typography sx={{ fontSize: "10pt" }}>□</Typography>
            </Stack>
            {/* 실제소유자 확인 영역 ========================================== */}
            <Typography sx={{ fontWeight: 700, fontSize: "8pt" }}>
              1) 실제소유자 확인 기재사항
            </Typography>
            <Box sx={{ mt: 1 }}>
              <Stack direction={"row"}>
                <Stack
                  justifyContent={"center"}
                  alignItems={"center"}
                  sx={{
                    width: "25mm",
                    backgroundColor: "#e5dfec",
                    borderLeft: "1px dotted black",
                    borderTop: "1px dotted black",
                    p: 1
                  }}
                >
                  <Typography sx={{ fontSize: "8pt", fontWeight: 700 }}>
                    개인사업자
                  </Typography>
                </Stack>
                <Stack
                  sx={{
                    width: "50mm",
                    borderLeft: "1px dotted black",
                    borderTop: "1px dotted black",
                    p: 1
                  }}
                >
                  <Typography>대표자와 실제소유자가 동일합니까?</Typography>
                </Stack>
                <Stack
                  direction={"row"}
                  spacing={1}
                  sx={{
                    width: "115mm",
                    borderLeft: "1px dotted black",
                    borderTop: "1px dotted black",
                    borderRight: "1px dotted black",
                    p: 1
                  }}
                >
                  <Typography>□</Typography>
                  <Typography>예</Typography>
                  <Typography>□</Typography>
                  <Typography>
                    아니오 ('아니오' 선택시 하단 실제 소유자 정보란 기재)
                  </Typography>
                </Stack>
              </Stack>
              <Stack direction={"row"}>
                <Stack
                  justifyContent={"center"}
                  alignItems={"center"}
                  sx={{
                    width: "25mm",
                    p: 1,
                    backgroundColor: "#e5dfec",
                    borderTop: "1px dotted black",
                    borderLeft: "1px dotted black"
                  }}
                >
                  <Typography sx={{ fontSize: "8pt", fontWeight: 700 }}>
                    법인사업자,
                  </Typography>
                  <Typography sx={{ fontSize: "8pt", fontWeight: 700 }}>
                    임의단체
                  </Typography>
                </Stack>
                <Stack sx={{ width: "165mm" }}>
                  <Stack direction={"row"}>
                    <Typography
                      sx={{
                        width: "50mm",
                        borderLeft: "1px dotted black",
                        borderTop: "1px dotted black",
                        p: 1
                      }}
                    >
                      실제소유자 확인 생략대상
                    </Typography>
                    <Stack
                      direction={"row"}
                      spacing={1}
                      sx={{
                        width: "115mm",
                        borderLeft: "1px dotted black",
                        borderTop: "1px dotted black",
                        borderRight: "1px dotted black",
                        p: 1
                      }}
                    >
                      <Typography>□</Typography>
                      <Typography>국가·지자체·공공단체</Typography>
                      <Typography>□</Typography>
                      <Typography>금융회사</Typography>
                      <Typography>□</Typography>
                      <Typography>사업보고서 제출 대상법인</Typography>
                    </Stack>
                  </Stack>
                  <Stack direction={"row"}>
                    <Typography
                      sx={{
                        width: "50mm",
                        borderLeft: "1px dotted black",
                        borderTop: "1px dotted black",
                        p: 1
                      }}
                    >
                      실제소유자 확인 대상
                    </Typography>
                    <Box
                      sx={{
                        width: "115mm",
                        borderLeft: "1px dotted black",
                        borderTop: "1px dotted black",
                        borderRight: "1px dotted black",
                        p: 1
                      }}
                    >
                      <Typography>
                        다음의 3단계 절차에 따라 실제소유자를 확인해주시기
                        바랍니다.
                      </Typography>
                      <Stack direction={"row"} spacing={1}>
                        <Typography>□</Typography>
                        <Typography>
                          1단계) 25%이상의 지분을 소유한 사람이 있는 경우 최대
                          지분 소유자 중 1인
                        </Typography>
                      </Stack>
                      <Typography sx={{ ml: 2.3 }}>
                        2단계) 1단계를 확인할 수 없는 경우로, 아래 중 어느
                        하나에 해당하는 자
                      </Typography>
                      <Stack direction={"row"} spacing={1} sx={{ ml: 2.3 }}>
                        <Typography>□</Typography>
                        <Typography>① 최대 지분 소유자 중 1인</Typography>
                      </Stack>
                      <Stack direction={"row"} spacing={1} sx={{ ml: 2.3 }}>
                        <Typography>□</Typography>
                        <Typography>
                          ② 대표자,업무집행사원 또는 임원 등의 과반수를 선임한
                          주주(자연인)
                        </Typography>
                      </Stack>
                      <Stack direction={"row"} spacing={1} sx={{ ml: 2.3 }}>
                        <Typography>□</Typography>
                        <Typography>
                          ③ ①,②외에 법인 또는 단체를 사실상 지배하는 자
                        </Typography>
                      </Stack>
                      <Stack direction={"row"} spacing={1}>
                        <Typography>□</Typography>
                        <Typography>
                          3단계) 2단계를 확인할 수 없는 경우로, 법인 또는 단체의
                          대표자
                        </Typography>
                      </Stack>
                    </Box>
                  </Stack>
                </Stack>
              </Stack>
              <Stack direction={"row"}>
                <Stack
                  justifyContent={"center"}
                  alignItems={"center"}
                  sx={{
                    width: "25mm",
                    p: 1,
                    backgroundColor: "#e5dfec",
                    borderTop: "1px dotted black",
                    borderLeft: "1px dotted black",
                    borderBottom: "1px dotted black"
                  }}
                >
                  <Typography sx={{ fontSize: "8pt", fontWeight: 700 }}>
                    실제소유자 정보
                  </Typography>
                  <Typography sx={{ fontSize: "8pt", fontWeight: 700 }}>
                    (필수 기재)
                  </Typography>
                </Stack>
                <Stack>
                  <Grid
                    container
                    sx={{
                      width: "165mm",
                      borderLeft: "1px dotted black",
                      borderTop: "1px dotted black",
                      borderRight: "1px dotted black",
                      p: 1
                    }}
                  >
                    <Grid item xs={4}>
                      <Typography>성명(한글):</Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography>성명(영문):</Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography>국적:</Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography>지분율:</Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Stack direction={"row"} spacing={1}>
                        <Typography>성별: 남</Typography>
                        <Typography>□</Typography>
                        <Typography>여</Typography>
                        <Typography>□</Typography>
                      </Stack>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography>생년월일:</Typography>
                    </Grid>
                  </Grid>
                  <Stack
                    sx={{
                      width: "165mm",
                      border: "1px dotted black",
                      p: 0.5
                    }}
                  >
                    <Typography sx={{ fontSize: "6pt" }}>
                      ※ 실제소유자정보 기재시 유의사항
                    </Typography>
                    <Typography sx={{ fontSize: "6pt" }}>
                      체크 또는 기재사항은 작성 부탁드립니다.
                    </Typography>
                    <Typography sx={{ fontSize: "6pt" }}>
                      (한글성명 : 정자로 기재, 영문 : 여권의 영문명 기재, 성별 :
                      남,여, 체크 , 지분율: 1단계, 2단계 ①인 경우 지분율 기재)
                    </Typography>
                  </Stack>
                </Stack>
              </Stack>
            </Box>
            {/* 실제소유자 확인 영역 ========================================== */}
            {/* 고객확인 추가 기재 영역 ======================================= */}
            <Typography sx={{ fontWeight: 700, fontSize: "8pt", mt: 1 }}>
              2) 고객확인 관련 추가 기재사항
            </Typography>
            <Box sx={{ mt: 1 }}>
              <Stack direction={"row"}>
                <Stack
                  justifyContent={"center"}
                  alignItems={"center"}
                  sx={{
                    width: "80mm",
                    backgroundColor: "#e5dfec",
                    borderLeft: "1px dotted black",
                    borderTop: "1px dotted black",
                    p: 1
                  }}
                >
                  <Typography sx={{ fontWeight: 700, fontSize: "8pt" }}>
                    구분
                  </Typography>
                </Stack>
                <Stack
                  justifyContent={"center"}
                  alignItems={"center"}
                  sx={{
                    width: "110mm",
                    backgroundColor: "#e5dfec",
                    borderLeft: "1px dotted black",
                    borderTop: "1px dotted black",
                    borderRight: "1px dotted black",
                    p: 1
                  }}
                >
                  <Typography sx={{ fontWeight: 700, fontSize: "8pt" }}>
                    내용기재
                  </Typography>
                </Stack>
              </Stack>
              <Stack direction={"row"}>
                <Stack
                  justifyContent={"center"}
                  alignItems={"center"}
                  sx={{
                    width: "40mm",
                    backgroundColor: "#e5dfec",
                    borderTop: "1px dotted black",
                    borderLeft: "1px dotted black",
                    p: 1
                  }}
                >
                  <Typography sx={{ fontWeight: 700, fontSize: "8pt" }}>
                    공통
                  </Typography>
                </Stack>
                <Stack>
                  <Stack direction={"row"}>
                    <Stack
                      justifyContent={"center"}
                      alignItems={"center"}
                      sx={{
                        width: "40mm",
                        p: 1,
                        backgroundColor: "#e5dfec",
                        borderLeft: "1px dotted black",
                        borderTop: "1px dotted black"
                      }}
                    >
                      <Typography
                        sx={{
                          fontWeight: 700,
                          fontSize: "8pt"
                        }}
                      >
                        거래목적
                      </Typography>
                    </Stack>
                    <Stack
                      sx={{
                        width: "110mm",
                        borderLeft: "1px dotted black",
                        borderTop: "1px dotted black",
                        borderRight: "1px dotted black",
                        p: 0.5
                      }}
                    >
                      <Grid container>
                        <Grid item>
                          <Typography>□ 급여 및 생활비</Typography>
                        </Grid>
                        <Grid item sx={{ ml: 1 }}>
                          <Typography>□ 저축 및 투자</Typography>
                        </Grid>
                        <Grid item sx={{ ml: 1 }}>
                          <Typography>□ 보험료납부 결제</Typography>
                        </Grid>
                        <Grid item sx={{ ml: 1 }}>
                          <Typography>□ 공과금납부 결제</Typography>
                        </Grid>
                      </Grid>
                      <Grid container>
                        <Grid item>
                          <Typography>□ 카드대금 결제</Typography>
                        </Grid>
                        <Grid item sx={{ ml: 1 }}>
                          <Typography>□ 대출원리금 상환 결제</Typography>
                        </Grid>
                        <Grid item sx={{ ml: 1 }}>
                          <Typography>□ 사업상거래</Typography>
                        </Grid>
                        <Grid item sx={{ ml: 1 }}>
                          <Typography>□ 보장</Typography>
                        </Grid>
                        <Grid item sx={{ ml: 1 }}>
                          <Typography>□ 상속</Typography>
                        </Grid>
                      </Grid>
                      <Typography>□ 기타:</Typography>
                    </Stack>
                  </Stack>
                  <Stack direction={"row"}>
                    <Stack
                      justifyContent={"center"}
                      alignItems={"center"}
                      sx={{
                        width: "40mm",
                        p: 1,
                        backgroundColor: "#e5dfec",
                        borderLeft: "1px dotted black",
                        borderTop: "1px dotted black"
                      }}
                    >
                      <Typography
                        sx={{
                          fontWeight: 700,
                          fontSize: "8pt"
                        }}
                      >
                        신원확인정보
                      </Typography>
                    </Stack>
                    <Stack
                      sx={{
                        width: "110mm",
                        borderLeft: "1px dotted black",
                        borderTop: "1px dotted black",
                        borderRight: "1px dotted black",
                        p: 0.5
                      }}
                    >
                      <Grid container>
                        <Grid item>
                          <Typography>□ 주민등록증</Typography>
                        </Grid>
                        <Grid item sx={{ ml: 1 }}>
                          <Typography>□ 운전면허증</Typography>
                        </Grid>
                        <Grid item sx={{ ml: 1 }}>
                          <Typography>□ 여권</Typography>
                        </Grid>
                        <Grid item sx={{ ml: 1 }}>
                          <Typography>□ 여권 + 외국인 등록증</Typography>
                        </Grid>
                      </Grid>
                      <Typography>□ 여권 + 제외구간 국내거소신고증</Typography>
                    </Stack>
                  </Stack>
                  <Stack direction={"row"}>
                    <Stack
                      justifyContent={"center"}
                      alignItems={"center"}
                      sx={{
                        width: "40mm",
                        p: 1,
                        backgroundColor: "#e5dfec",
                        borderTop: "1px dotted black",
                        borderLeft: "1px dotted black"
                      }}
                    >
                      <Typography
                        sx={{
                          fontWeight: 700,
                          fontSize: "8pt"
                        }}
                      >
                        자금 원천 및 출처
                      </Typography>
                    </Stack>
                    <Stack
                      sx={{
                        width: "110mm",
                        borderLeft: "1px dotted black",
                        borderTop: "1px dotted black",
                        borderRight: "1px dotted black",
                        p: 0.5
                      }}
                    >
                      <Grid container>
                        <Grid item>
                          <Typography>□ 근로 및 연금소득</Typography>
                        </Grid>
                        <Grid item sx={{ ml: 1 }}>
                          <Typography>□ 퇴직소득</Typography>
                        </Grid>
                        <Grid item sx={{ ml: 1 }}>
                          <Typography>□ 사업소득</Typography>
                        </Grid>
                        <Grid item sx={{ ml: 1 }}>
                          <Typography>□ 부동산 임대소득</Typography>
                        </Grid>
                      </Grid>
                      <Grid container>
                        <Grid item>
                          <Typography>□ 부동산 양도소득</Typography>
                        </Grid>
                        <Grid item sx={{ ml: 1 }}>
                          <Typography>□ 금융소득(이자 및 배당)</Typography>
                        </Grid>
                        <Grid item sx={{ ml: 1 }}>
                          <Typography>□ 상속/증여</Typography>
                        </Grid>
                      </Grid>
                      <Grid container>
                        <Grid item>
                          <Typography>□ 일시 재산양도로 인한 소득</Typography>
                        </Grid>
                        <Grid item sx={{ ml: 1 }}>
                          <Typography>□ 기타:</Typography>
                        </Grid>
                      </Grid>
                    </Stack>
                  </Stack>
                </Stack>
              </Stack>
              <Stack direction={"row"}>
                <Stack
                  justifyContent={"center"}
                  alignItems={"center"}
                  sx={{
                    width: "40mm",
                    backgroundColor: "#e5dfec",
                    borderTop: "1px dotted black",
                    borderLeft: "1px dotted black",
                    p: 0.5
                  }}
                >
                  <Typography sx={{ fontWeight: 700, fontSize: "8pt" }}>
                    개인사업자
                  </Typography>
                </Stack>
                <Stack direction={"row"}>
                  <Stack
                    justifyContent={"center"}
                    alignItems={"center"}
                    sx={{
                      width: "40mm",
                      p: 0.5,
                      backgroundColor: "#e5dfec",
                      borderLeft: "1px dotted black",
                      borderTop: "1px dotted black"
                    }}
                  >
                    <Typography
                      sx={{
                        fontWeight: 700,
                        fontSize: "8pt"
                      }}
                    >
                      국내 거주여부
                    </Typography>
                  </Stack>
                  <Stack
                    direction={"row"}
                    sx={{
                      width: "110mm",
                      borderLeft: "1px dotted black",
                      borderTop: "1px dotted black",
                      borderRight: "1px dotted black",
                      p: 0.5
                    }}
                  >
                    <Typography>□ 거주</Typography>
                    <Typography sx={{ ml: 1 }}>
                      □ 비거주(외국인 비거주자는 생년월일, 성별, 실제 거소,
                      연락처 확인 필수)
                    </Typography>
                  </Stack>
                </Stack>
              </Stack>
              <Stack direction={"row"}>
                <Stack
                  justifyContent={"center"}
                  alignItems={"center"}
                  sx={{
                    width: "40mm",
                    backgroundColor: "#e5dfec",
                    borderTop: "1px dotted black",
                    borderLeft: "1px dotted black",
                    borderBottom: "1px dotted black",
                    p: 0.5
                  }}
                >
                  <Typography sx={{ fontWeight: 700, fontSize: "8pt" }}>
                    법인사업자
                  </Typography>
                  <Typography sx={{ fontWeight: 700, fontSize: "8pt" }}>
                    (비영리법인/임의단체 포함)
                  </Typography>
                </Stack>
                <Stack>
                  <Stack direction={"row"}>
                    <Stack
                      justifyContent={"center"}
                      alignItems={"center"}
                      sx={{
                        width: "40mm",
                        p: 0.5,
                        backgroundColor: "#e5dfec",
                        borderLeft: "1px dotted black",
                        borderTop: "1px dotted black"
                      }}
                    >
                      <Typography
                        sx={{
                          fontWeight: 700,
                          fontSize: "8pt"
                        }}
                      >
                        법인확인정보
                      </Typography>
                    </Stack>
                    <Stack
                      sx={{
                        width: "110mm",
                        borderLeft: "1px dotted black",
                        borderTop: "1px dotted black",
                        borderRight: "1px dotted black",
                        p: 0.5
                      }}
                    >
                      <Grid container>
                        <Grid item>
                          <Typography>□ 주주명부</Typography>
                        </Grid>
                        <Grid item sx={{ ml: 1 }}>
                          <Typography>□ 외국인투자기업 등록증명서</Typography>
                        </Grid>
                        <Grid item sx={{ ml: 1 }}>
                          <Typography>□ 기타:</Typography>
                        </Grid>
                      </Grid>
                    </Stack>
                  </Stack>
                  <Stack direction={"row"}>
                    <Stack
                      justifyContent={"center"}
                      alignItems={"center"}
                      sx={{
                        width: "40mm",
                        p: 0.5,
                        backgroundColor: "#e5dfec",
                        borderLeft: "1px dotted black",
                        borderTop: "1px dotted black"
                      }}
                    >
                      <Typography
                        sx={{
                          fontWeight: 700,
                          fontSize: "8pt"
                        }}
                      >
                        비영리법인/임의단체
                      </Typography>
                    </Stack>
                    <Stack
                      sx={{
                        width: "110mm",
                        borderLeft: "1px dotted black",
                        borderTop: "1px dotted black",
                        borderRight: "1px dotted black",
                        p: 0.5
                      }}
                    >
                      <Grid container>
                        <Grid item>
                          <Typography>□ 회원명부</Typography>
                        </Grid>
                        <Grid item sx={{ ml: 1 }}>
                          <Typography>□ 사원명부</Typography>
                        </Grid>
                        <Grid item sx={{ ml: 1 }}>
                          <Typography>□ 구성원명부</Typography>
                        </Grid>
                        <Grid item sx={{ ml: 1 }}>
                          <Typography>□ 출자자명부</Typography>
                        </Grid>
                        <Grid item sx={{ ml: 1 }}>
                          <Typography>□ 이사회명부</Typography>
                        </Grid>
                      </Grid>
                      <Typography>□ 기타:</Typography>
                    </Stack>
                  </Stack>
                  <Stack direction={"row"}>
                    <Stack
                      justifyContent={"center"}
                      alignItems={"center"}
                      sx={{
                        width: "40mm",
                        p: 0.5,
                        backgroundColor: "#e5dfec",
                        borderTop: "1px dotted black",
                        borderLeft: "1px dotted black",
                        borderBottom: "1px dotted black"
                      }}
                    >
                      <Typography
                        sx={{
                          fontWeight: 700,
                          fontSize: "8pt"
                        }}
                      >
                        상장여부
                      </Typography>
                    </Stack>
                    <Stack>
                      <Grid
                        container
                        sx={{
                          width: "110mm",
                          borderLeft: "1px dotted black",
                          borderTop: "1px dotted black",
                          borderRight: "1px dotted black",
                          p: 0.5
                        }}
                      >
                        <Grid item>
                          <Typography>□ 상장</Typography>
                        </Grid>
                        <Grid item sx={{ ml: 1 }}>
                          <Typography>□ 비상장</Typography>
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        sx={{
                          width: "110mm",
                          border: "1px dotted black",
                          borderBottom: "none",
                          p: 0.5
                        }}
                      >
                        <Grid item>
                          <Typography>□ 유가증권시장</Typography>
                        </Grid>
                        <Grid item sx={{ ml: 1 }}>
                          <Typography>□ 코스닥시장</Typography>
                        </Grid>
                        <Grid item sx={{ ml: 1 }}>
                          <Typography>□ 뉴욕증권거래소</Typography>
                        </Grid>
                        <Grid item sx={{ ml: 1 }}>
                          <Typography>□ NASDAQ</Typography>
                        </Grid>
                        <Grid item sx={{ ml: 1 }}>
                          <Typography>□ 런던증권거래소</Typography>
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        sx={{
                          width: "110mm",
                          border: "1px dotted black",
                          borderTop: "none",
                          p: 0.5,
                          pt: 0
                        }}
                      >
                        <Grid item>
                          <Typography>□ 홍콩증권거래소</Typography>
                        </Grid>
                        <Grid item sx={{ ml: 1 }}>
                          <Typography>□ 기타:</Typography>
                        </Grid>
                      </Grid>
                    </Stack>
                  </Stack>
                </Stack>
              </Stack>
            </Box>
            {/* 고객확인 추가 기재 영역 ======================================= */}

            <Box sx={{ height: "30mm", mt: 1 }}>
              <Stack
                direction={"row"}
                justifyContent={"center"}
                alignItems={"center"}
                sx={{ p: 1 }}
              >
                <Typography sx={{ fontWeight: 700, letterSpacing: 3 }}>
                  {getTodayKor()}
                </Typography>
              </Stack>
              <Stack alignItems={"flex-end"}>
                <Stack
                  direction={"row"}
                  alignItems={"center"}
                  sx={{ pt: 1, width: "65mm", mt: 1 }}
                >
                  <Typography sx={{ width: "15mm", fontWeight: 700 }}>
                    상호:
                  </Typography>
                  <Typography
                    sx={{
                      backgroundColor: grey[300],
                      textAlign: "center",
                      width: "47.2mm"
                    }}
                  >
                    {data?.mallName}
                  </Typography>
                </Stack>
                <Stack
                  direction={"row"}
                  alignItems={"center"}
                  sx={{ pr: 1.3, width: "65mm", mt: 1 }}
                >
                  <Typography sx={{ width: "15mm", fontWeight: 700 }}>
                    대표(이사):
                  </Typography>
                  <Typography
                    sx={{
                      backgroundColor: grey[300],
                      textAlign: "center",
                      letterSpacing: 10,
                      width: "40mm"
                    }}
                  >
                    {data?.merchantOwnerInformation[0].name}
                  </Typography>
                  <Typography
                    sx={{
                      backgroundColor: grey[300],
                      textAlign: "center",
                      width: "8mm"
                    }}
                  >
                    (인)
                  </Typography>
                </Stack>
                <Typography
                  sx={{ color: grey[500], pr: 1.3, fontSize: "6pt", mt: 1 }}
                >
                  ※ 개인사업자: 대표자 인감 / 법인사업자: 법인 인감 날인 필수
                </Typography>
              </Stack>
            </Box>
          </Box>

          {/* 바닥글 ======================================================== */}
          <Divider
            sx={{
              border: "none",
              borderTop: "1px dotted",
              borderColor: grey[700],
              mx: 1
            }}
          ></Divider>
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            sx={{ height: "7mm", color: grey[400], mx: 3, mt: 0 }}
          >
            <Typography>국내 최고의 통합 결제 서비스 OZICPAY</Typography>
            <Typography>페이지 7 / 9</Typography>
          </Stack>
        </Card>
      </Box>
    </Fonts>
  );
};

export default Page7;
