import styled from "@emotion/styled";
import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import { useMutation } from "@tanstack/react-query";
import { Controller, useForm } from "react-hook-form";
import { useSetRecoilState } from "recoil";
import { queryClient } from "../..";
import { postBlockUser } from "../../api/Merchants";
import { BlockUserParams } from "../../api/Merchants/types";
import isModalOpen from "../../recoil/isModalOpen/atom";

interface Props {
  userId: number;
  closeModal?: () => void;
}

export const HelperTextMessage = styled("span")({
  position: "absolute",
  color: "#d94452"
});

const UserLockModal = ({ userId, closeModal }: Props) => {
  // ===========================================================================
  // 리코일 스테이트
  // ===========================================================================
  const setIsModalOpen = useSetRecoilState(isModalOpen);

  // 사용자 계정 비활성화
  const { mutate } = useMutation(postBlockUser, {
    onSuccess: () => {
      queryClient.invalidateQueries();
      setIsModalOpen({
        value: true,
        position: "top",
        alertSeverity: "success",
        message: "계정이 비활성화되었습니다."
      });
    },
    onError: (error: any) => {
      setIsModalOpen({
        value: true,
        position: "top",
        alertSeverity: "error",
        message: error?.response?.data?.message
      });
    }
  });

  // ===========================================================================
  // 리액트 훅 폼
  // ===========================================================================
  const {
    control,
    handleSubmit,
    formState: { errors, isValid }
  } = useForm<BlockUserParams>({
    mode: "onChange",
    defaultValues: {
      userId: 0,
      memo: "",
      isUse: false
    }
  });

  // 메모 & 비활성화
  const onSubmit = (formData: BlockUserParams) => {
    const params = {
      ...formData,
      isUse: false,
      userId: userId
    };
    mutate(params);
    closeModal?.()
  };

  return (
    <>
      {/* 비활성화 모달 */}
      <Dialog open={true} fullWidth maxWidth="xs">
        <DialogTitle>사용자 계정 비활성화</DialogTitle>

        <IconButton
          onClick={closeModal}
          sx={{
            position: "absolute",
            right: 8,
            top: 12
          }}
        >
          <CloseIcon />
        </IconButton>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent>
            <DialogContentText gutterBottom>
              사유 입력 (비활성화)
            </DialogContentText>

            <Controller
              name="memo"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: "사유를 반드시 입력해주세요."
                },
                pattern: {
                  value: /^(?!\s*$).+/,
                  message: "유효한 사유를 입력해주세요.",
                },
                maxLength: {
                  value: 50,
                  message: "50자 이하로 입력해 주세요."
                }
              }}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="사유"
                  required
                  fullWidth
                  multiline
                  maxRows={2}
                  placeholder="사유를 반드시 입력해주세요."
                  helperText={
                    <HelperTextMessage>
                      {errors.memo?.message}
                    </HelperTextMessage>
                  }
                />
              )}
            />
          </DialogContent>
          <DialogActions>
            <Button
              type="submit"
              variant="contained"
              sx={{ color: "white" }}
              fullWidth
              disabled={!isValid}
            >
              비활성화 상태로 전환
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

export default UserLockModal;
