import { atom } from "recoil";
import { getToday } from "../../libs/get-today";
import { PaymentsDetailParams, PaymentsTotalParams } from "../../api/v2/Payments/types";


/**
 * 거래내역 리코일 스테이트
 */
const PaymentsState = atom<PaymentsTotalParams>({
  key: "PaymentsState",
  default: {
    startDate: getToday(),
    endDate: getToday(),
    cardCompany: "기본",
    status: "DEFAULT",
    mallId: "",
    mallName: "",
    transactionKey: "",
    paymentKey: "",
    approvalNum: "",
    purchaseStatus: "DEFAULT"
  }
});

/**
 * 거래내역 + 페이지 리코일 스테이트
 */
export const PaymentsDetailState = atom<PaymentsDetailParams>({
  key: "PaymentsDetailState",
  default: {
    page: 0,
    size: 50,
    startDate: getToday(),
    endDate: getToday(),
    cardCompany: "기본",
    status: "DEFAULT",
    mallId: "",
    mallName: "",
    transactionKey: "",
    paymentKey: "",
    approvalNum: "",
    purchaseStatus: "DEFAULT",
    sort: "DESC"
  }
});
export default PaymentsState;
