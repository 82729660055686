import React from "react";
import LogoutIcon from "@mui/icons-material/Logout";
import IconButton from "@mui/material/IconButton";
import { useNavigate } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import loginInfoAtom from "../../recoil/loginInfo";
import { loginInfoDefault } from "../../recoil/loginInfo/atom";

const MiddleLogoutBtn = () => {
  const setLoginInfo = useSetRecoilState(loginInfoAtom);
  const navigate = useNavigate();
  const handleClick = () => {
    setLoginInfo(loginInfoDefault);
    sessionStorage.removeItem("loginInfo");
    navigate("../login", { replace: true });
  };
  return (
    <IconButton
      aria-label="logout"
      size="large"
      sx={{
        position: "absolute",
        right: 16,
        top: 16,
        "&:hover": {
          color: "primary.main"
        }
      }}
      onClick={handleClick}
    >
      <LogoutIcon />
    </IconButton>
  );
};

export default MiddleLogoutBtn;
