import LinearProgress from "@mui/material/LinearProgress";
import FormHelperText from "@mui/material/FormHelperText";

interface PasswordStrengthProps {
  status: string | number;
}

const PasswordStrength = ({ status }: PasswordStrengthProps) => {
  return (
    <>
      <LinearProgress
        variant="determinate"
        color={status === "성공" ? "success" : "error"}
        value={status === "성공" ? 100 : (status as number) * 33}
        sx={{ my: 1 }}
      />
      <FormHelperText
        sx={{
          color: [1, 2, "성공"].includes(status) ? "success.main" : "error.main"
        }}
      >
        영어 대문자, 소문자, 숫자, 특수문자(~!@#$%^&*()) 사용 가능
      </FormHelperText>
      <FormHelperText
        sx={{
          color: status === "성공" ? "success.main" : "error.main"
        }}
      >
        2종류 조합 시 10자 이상, 3종류 이상 조합 시 8자 이상
      </FormHelperText>
    </>
  );
};

export default PasswordStrength;
