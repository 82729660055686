import CloseIcon from "@mui/icons-material/Close";
import {
  Button,
  Grid,
  InputAdornment,
  InputLabel,
  Stack,
  TextField
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { getDate } from "../../libs/get-date";
import koLocale from "date-fns/locale/ko";
import { Controller, useForm } from "react-hook-form";
import { useSetRecoilState } from "recoil";
import { getToday } from "../../libs/get-today";
import isModalOpenAtom from "../../recoil/isModalOpen";
import { HelperTextMessage } from "../Merchants/TerminateContractModal";
import { useMutation } from "@tanstack/react-query";
import { postCardLimit } from "../../api/CardLimitManagement";
import { queryClient } from "../..";
import { inputPriceFormat } from "../../libs/format-utils";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  selectedId: number;
}

interface Form {
  limitAmount: string | number;
  reservationDate: string;
}

const ModifyCardLimitModal = ({ isOpen, onClose, selectedId }: Props) => {
  // ===================================================================================================================
  // 리코일 스테이트
  // ===================================================================================================================
  const setIsModalOpen = useSetRecoilState(isModalOpenAtom);

  // ===================================================================================================================
  // 리액트 훅 폼
  // ===================================================================================================================
  const {
    handleSubmit,
    control,
    setValue,
    reset,
    watch,
    formState: { errors }
  } = useForm<Form>({
    defaultValues: {
      limitAmount: "0",
      reservationDate: getToday()
    }
  });

  // 한도 수정
  const { mutate } = useMutation(postCardLimit, {
    onSuccess: () => {
      // 데이터 새로조회
      queryClient.invalidateQueries();
      setIsModalOpen({
        value: true,
        position: "top",
        alertSeverity: "success",
        message: "한도 수정을 완료했습니다."
      });
    },
    onError: (error: any) => {
      setIsModalOpen({
        value: true,
        position: "top",
        alertSeverity: "error",
        message: error?.response?.data?.message
      });
    }
  });

  // ===================================================================================================================
  // 모달창 닫기 + 폼 리셋
  // ===================================================================================================================
  const handleClose = () => {
    reset();
    onClose();
  };

  // ===================================================================================================================
  // 한도 업데이트
  // ===================================================================================================================
  const onSubmit = (formData: Form) => {
    const limitAmount = parseInt(
      formData.limitAmount.toString().replace(/,/g, "")
    );
    const today = new Date();
    const selectedDate = new Date(formData.reservationDate);

    // 오늘 날짜 이후인지 검증
    today.setHours(0, 0, 0, 0);
    selectedDate.setHours(0, 0, 0, 0);

    if (limitAmount < 1) {
      setIsModalOpen({
        value: true,
        position: "top",
        alertSeverity: "error",
        message: "한도 금액은 1원 이상이어야 합니다."
      });
      return;
    }

    if (selectedDate <= today) {
      setIsModalOpen({
        value: true,
        position: "top",
        alertSeverity: "error",
        message: "적용 날짜는 오늘 이후의 날짜여야 합니다."
      });
      return;
    }

    const params = {
      cardLimitTotalId: selectedId,
      limitAmount,
      reservationDate: formData.reservationDate
    };
    mutate(params);
    handleClose();
  };

  return (
    <>
      {/* 관리자가 가맹점 별 카드 한도를 업데이트하는 모달창 ------------------------------------------------------------*/}
      <Dialog open={isOpen} fullWidth maxWidth="sm">
        <DialogTitle>한도 수정</DialogTitle>

        <IconButton
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 12
          }}
        >
          <CloseIcon />
        </IconButton>

        <DialogContent>
          <DialogContentText gutterBottom></DialogContentText>
          {/* 폼 ----------------------------------------------------------------------------------------------------*/}
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack gap={3} justifyContent={"space-between"}>
              <Stack
                direction={"row"}
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <InputLabel htmlFor="limitAmount" sx={{ fontWeight: "bold" }}>
                  한도 금액
                </InputLabel>
                <Controller
                  name="limitAmount"
                  control={control}
                  rules={{
                    required: "한도 금액은 필수값 입니다."
                  }}
                  render={({ field: { value, onChange } }) => (
                    <TextField
                      id="limitAmount"
                      label="한도금액"
                      required
                      value={value}
                      size="small"
                      sx={{ width: 255 }}
                      onChange={(e) => {
                        const formattedValue = inputPriceFormat(e.target.value);
                        onChange(formattedValue);
                        setValue("limitAmount", formattedValue, {
                          shouldValidate: true
                        });
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">원</InputAdornment>
                        )
                      }}
                      error={!!errors.limitAmount}
                      helperText={
                        <HelperTextMessage>
                          {errors.limitAmount?.message}
                        </HelperTextMessage>
                      }
                    />
                  )}
                />
              </Stack>

              <Stack
                direction={"row"}
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <InputLabel
                  htmlFor="reservationDate"
                  sx={{ fontWeight: "bold" }}
                >
                  적용 날짜
                </InputLabel>
                <LocalizationProvider
                  adapterLocale={koLocale}
                  dateAdapter={AdapterDateFns}
                >
                  <Grid item xs={2}>
                    <Controller
                      name="reservationDate"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <DatePicker
                          label="적용날짜"
                          inputFormat="yyyy-MM-dd"
                          value={value}
                          onChange={(value) => onChange(getDate(value))}
                          renderInput={(param) => (
                            <TextField
                              fullWidth
                              size="small"
                              required
                              {...param}
                              helperText={
                                <HelperTextMessage>
                                  {errors.reservationDate?.message}
                                </HelperTextMessage>
                              }
                            />
                          )}
                        />
                      )}
                    />
                  </Grid>
                </LocalizationProvider>
              </Stack>
              {/* 수정 완료 버튼 -------------------------------------------------------------------------------------*/}
              <Button
                type="submit"
                variant="contained"
                fullWidth
                sx={{ color: "white" }}
                disabled={!watch("limitAmount")}
              >
                수정 완료
              </Button>
            </Stack>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ModifyCardLimitModal;
