import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import KeyboardArrowRightRoundedIcon from "@mui/icons-material/KeyboardArrowRightRounded";
import Button from "@mui/material/Button";
import { StepData } from "../../api/MerchantRegistration/types";
import { postMerchantRegistrationTemp } from "../../api/MerchantRegistration";
import Box from "@mui/material/Box";
import counseling from "../../images/counseling_img.png";
import IconButton from "@mui/material/IconButton";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { useMutation } from "@tanstack/react-query";
import { useSetRecoilState } from "recoil";
import isModalOpenAtom from "../../recoil/isModalOpen";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
import Chip from "@mui/material/Chip";

interface MerchantBridgePageProps {
  nextEvent: ({ currentStep }: any) => void;
  tempDataProp: StepData;
  refreshEvent: () => void;
}

const Bridge = ({
  nextEvent,
  tempDataProp,
  refreshEvent
}: MerchantBridgePageProps) => {
  const nextStep = () => {
    nextEvent({ currentStep: 0 });
  };
  // ===========================================================================
  // 리코일 스테이트
  // ===========================================================================
  const setIsModalOpen = useSetRecoilState(isModalOpenAtom);

  // ===========================================================================
  // 스테이트
  // ===========================================================================
  // 프롬프트 스테이트
  const [openPrompt, setOpenPrompt] = useState(false);
  const handleOpenPrompt = () => {
    setOpenPrompt(true);
  };
  const handleClosePrompt = () => {
    setOpenPrompt(false);
  };

  // ===========================================================================
  // 리액트 쿼리 - 등록된 가맹 점 삭제
  // ===========================================================================
  const { mutate, isLoading } = useMutation(postMerchantRegistrationTemp, {
    onSuccess: () => {
      setIsModalOpen({
        value: true,
        position: "top",
        alertSeverity: "success",
        message: "가맹점이 삭제되었습니다."
      });
      // 삭제 성공시 페이지 갱신
      refreshEvent();
    },
    onError: (error: any) => {
      setIsModalOpen({
        value: true,
        position: "top",
        alertSeverity: "error",
        message: error?.response?.data?.message
      });
    }
  });

  // ===========================================================================
  // 등록된 가맹점 삭제
  // ===========================================================================
  const deleteTempData = async () => {
    const stepData = {
      ...tempDataProp
    };
    if (isLoading) return;
    mutate({
      id: stepData?.id ?? 0,
      userId: stepData?.userId ?? 0,
      isUse: false,
      jsonString: JSON.stringify(stepData),
      registrant: "CLIENT"
    });
    handleClosePrompt();
  };

  return (
    <>
      <Paper
        elevation={0}
        sx={{
          height: 800,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          border: "1px solid #E6E5E5",
          borderRadius: 2
        }}
      >
        <Stack justifyContent={"center"} alignItems="center" width={"50%"}>
          <Box component="img" src={counseling} sx={{ width: 350 }} />
          <Stack
            direction="row"
            spacing={3}
            justifyContent="space-between"
            sx={{
              position: "relative",
              width: "100%",
              height: 180,
              alignItems: "center",
              backgroundColor: "#f2f2f2",
              borderRadius: 2,
              p: 5,
              "&:hover .hover": { opacity: 1 },
              cursor: "pointer"
            }}
          >
            <Stack direction="row" alignItems="center" spacing={5}>
              <PlaylistAddIcon
                sx={{
                  color: "white",
                  p: 4,
                  width: 100,
                  height: 100,
                  borderRadius: 2,
                  backgroundColor: "#ccc"
                }}
              />
              <Stack spacing={1}>
                <Chip
                  label="작성중"
                  color="secondary"
                  sx={{ color: "white", width: 80 }}
                  size="small"
                />
                <Typography variant="h5" sx={{ fontWeight: 600 }}>
                  {tempDataProp?.mallName}
                </Typography>
                <Typography variant="body2">
                  사업자등록번호 : &nbsp;
                  {tempDataProp?.merchantInformation?.registrationNumber}
                </Typography>
              </Stack>

              <Stack
                className="hover"
                direction="row"
                spacing={3}
                sx={{
                  width: "100%",
                  height: 180,
                  backgroundColor: "rgba(0, 0, 0, 0.5)",
                  opacity: 0,
                  borderRadius: 2,
                  justifyContent: "right",
                  alignItems: "center",
                  position: "absolute",
                  left: -40,
                  transition: "all 0.3s",
                  pr: 12
                }}
              >
                <Button
                  variant="contained"
                  sx={{
                    width: 100,
                    height: 40,
                    backgroundColor: "white",
                    borderRadius: 1,
                    color: "primary.main",
                    "&:hover": { color: "white" }
                  }}
                  onClick={handleOpenPrompt}
                >
                  삭제하기
                </Button>
                <Dialog open={openPrompt} fullWidth maxWidth="xs">
                  <DialogTitle>임시 등록된 가맹점 삭제</DialogTitle>
                  <IconButton
                    onClick={handleClosePrompt}
                    sx={{
                      position: "absolute",
                      right: 8,
                      top: 12
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                  <DialogContent>
                    <DialogContentText gutterBottom>
                      삭제하면 복구할 수 없습니다.
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      type="button"
                      variant="contained"
                      onClick={deleteTempData}
                      sx={{ color: "white" }}
                      fullWidth
                    >
                      삭제하기
                    </Button>
                  </DialogActions>
                </Dialog>
                <Button
                  variant="contained"
                  onClick={nextStep}
                  sx={{
                    width: 100,
                    height: 40,
                    borderRadius: 1,
                    color: "white"
                  }}
                >
                  이어쓰기
                </Button>
              </Stack>
            </Stack>
            <Stack flex="right">
              <KeyboardArrowRightRoundedIcon />
            </Stack>
          </Stack>
          <Typography variant="body2" sx={{ mt: 3, color: "error.main" }}>
            * 가맹점 등록은 동시에 하나만 진행할 수 있습니다. <br />* 새로운
            가맹점 등록을 원할 시 임시 저장된 내용을 삭제하고 다시 새로운
            가맹점을 등록해 주세요.
          </Typography>
        </Stack>
      </Paper>
    </>
  );
};
export default Bridge;
