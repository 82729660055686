import React from "react";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/material";

const EPaymentService = () => {
  return (
    <>
      <Typography component="h3" sx={{ fontWeight: "bold", mb: 3 }}>
        전자결제서비스 이용약관
      </Typography>
      <Typography component="h3" sx={{ fontWeight: "bold", mb: 1 }}>
        제 1 조 (목적)
      </Typography>
      <Typography sx={{ mb: 3 }}>
        본 약관은 ”고객사”가 자신의 상품 또는 서비스(이하”상품”이라 함)을
        이용자에게 판매함에 있어서 “회사”가 제공하는 전자결제서비스를 이용하는
        것과 관련하여 필요한 사항을 정하는 데에 그 목적이 있다.
      </Typography>
      <Typography component="h3" sx={{ fontWeight: "bold", mb: 1 }}>
        제 2 조 (용어의 정의)
      </Typography>
      <Box className="ol" component="ol">
        <Typography component="li" sx={{ mb: 1 }}>
          신용카드 결제 서비스 : “고객사”가 인터넷 상에 설치한 매장에서 이용자가
          OZICPAY를 이용하여 “고객사”의 상품 및 서비스를 구매할 때에 “회사”가
          제휴한 카드사를 통해 무전표 매출승인 및 입금, 대금결제 기타 관련
          서비스를 다음 각 호의 수단을 통해 제공하는 것을 말한다.
        </Typography>

        <Box component="ol">
          <Typography component="li" sx={{ mb: 1 }}>
            인터넷 전자결제 통신프로그램 (결제서비스용 프로그램 모듈)의 제공{" "}
          </Typography>
          <Typography component="li" sx={{ mb: 1 }}>
            위 프로그램의 설치 안내문 제공
          </Typography>
        </Box>

        <Typography component="li" sx={{ mb: 1 }}>
          이용자 : “고객사”의 재화 또는 용역을 이용하는 자를 말한다.
        </Typography>
        <Typography component="li" sx={{ mb: 1 }}>
          대표가맹점, 대표가맹점 상점 : “고객사”가 신용카드 가맹점이 아닌 경우
          “회사”가 “고객사”를 위하여 신용카드 처리업무를 대행하기 위해 자신의
          명의로 개설한 신용카드 가맹점을 대표가맹점이라 한다. 대표가맹점을
          이용하여 이용자에게 상품을 제공하는 “고객사”의 매장을 대표가맹점
          상점이라고 한다.
        </Typography>
        <Typography component="li" sx={{ mb: 1 }}>
          상품 : “고객사”가 “회사”의 전자결제서비스를 이용하여 이용자에게
          제공하는 유무형의 재화와 용역을 말하며, 상용프로그램, 데이터는 물론
          회원이 인터넷 상에서 대금을 지불하고 구매할 수 있는 모든 대상을
          포함한다.
        </Typography>
        <Typography component="li" sx={{ mb: 1 }}>
          전자결제서버 : “고객사”가 “회사”의 전자결제서비스를 이용하여 회원에게
          판매한 상품의 대금결제를 위하여 ”회사”가 운영하는 전산시스템을 말한다.
        </Typography>
        <Typography component="li" sx={{ mb: 1 }}>
          본인인증서비스 : 신용카드사가 제공하는 서비스로 카드번호, 유효기간
          외에 생년월일 및 비밀번호의 일부를 입력 받아 카드의 유효성을 확인해
          주는 서비스를 말한다. (단, 상기 본인인증 서비스는 카드의 유효성을
          확인해 주는 서비스로 실제 본인 사용여부를 확인하는 것은 아니다.)
        </Typography>
        <Typography component="li" sx={{ mb: 1 }}>
          결제기관 : “서비스”를 위하여 결제인가, 수납대행, 결제대금 입금을
          제공하는 신용카드사업자, 은행 등의 원천 기관을 말한다.
        </Typography>
        <Typography component="li" sx={{ mb: 3 }}>
          서비스 제한 또는 불가업종 : “결제기관” 또는 관계법령 등에 의하여
          제서비스의 이용이 제한되거나 불가능한 업종을 의미하며, “본 계약” 체결
          이후에도 “결제기관” 또는 관계법령 등에 의하여 업종 및 제한사항은 추가,
          삭제 및 변동될 수 있다.
        </Typography>
      </Box>

      <Typography sx={{ fontWeight: "bold", mb: 1 }}>
        제 3 조 (서비스 이용기간)
      </Typography>
      <Box className="ol" component="ol">
        <Typography component="li" sx={{ mb: 1 }}>
          본 약관의 유효기간은 계약체결일로부터 1년간 유효하다.
        </Typography>
        <Typography component="li" sx={{ mb: 1 }}>
          전항의 유효기간 만료 1개월 전까지 어느 일방 또는 쌍방에 의한 서면 및
          유선(이메일)의 통보가 없을 경우 본 계약은 동일한 조건으로 1년간 자동
          연장되며 그 이후도 같다.
        </Typography>
        <Typography component="li" sx={{ mb: 1 }}>
          본 계약의 유효기간 중에는 정당한 사유 없이 일방적으로 계약을 해지할 수
          없다. 다만, 본 계약 제5조 각 항에 해당하는 경우에는 그러하지 아니하다.
        </Typography>
      </Box>

      <Typography sx={{ fontWeight: "bold", mb: 1 }}>
        제 4 조 (서비스 이용수수료)
      </Typography>
      <Box className="ol" component="ol">
        <Typography component="li" sx={{ mb: 1 }}>
          "고객사”는 “서비스” 이용의 대가로 “회사”에게 ”수수료”를 이 약관에서
          정한 바에 따라 “대금정산”시 “회사”가 “고객사”에 지급해야 하는
          결제금액에서 차감하는 방식으로 지급합니다.{" "}
        </Typography>
        <Typography component="li" sx={{ mb: 1 }}>
          "회사”는 “고객사”에게 이 약관에 따라 매월 발생한 “수수료”에 대하여
          해당월 말일자를 발행일자로 하여 익월 초까지 세금계산서를 발행합니다.
        </Typography>
        <Typography component="li" sx={{ mb: 1 }}>
          "회사”는 “수수료” 및 정산 주기를 변경할 수 있으며, 변경시 “고객사”에
          변경 사항을 통지합니다. 다만 “수수료”가 인하되는 경우에는 별도
          통지하지 아니할 수 있습니다.
        </Typography>
        <Typography component="li" sx={{ mb: 3 }}>
          "고객사”의 “회사”에 대한 “수수료” 지급 의무는 이용계약이 해지 또는
          종료되더라도 지급할 “수수료”가 존재하는 한 계속되는 것으로 봅니다.
        </Typography>
      </Box>

      <Typography sx={{ fontWeight: "bold", mb: 1 }}>
        제 5 조 (서비스 이용제한 등)
      </Typography>
      <Typography>
        다음의 각 항의 어느 하나에 해당하는 경우에 양 당사자는 계약기간중에도
        불구하고, 서면, 유선 및 이메일 통보로서 이 서비스이용계약을 해지할 수
        있다.
      </Typography>
      <Box className="ol" component="ol">
        <Typography component="li" sx={{ mb: 1 }}>
          “고객사” 또는 “회사”가 본 계약 및 관련 부속약정 사항 또는 관련
          법령을위반한 경우.
        </Typography>
        <Typography component="li" sx={{ mb: 1 }}>
          “고객사” 또는 “회사”가 고의 또는 과실로 상대방의 명예나 신용을
          실추시킨 경우
        </Typography>
        <Typography component="li" sx={{ mb: 1 }}>
          “고객사” 또는 “회사”에게 국세 또는 지방세의 체납처분, 파산, 화의,
          회사정리 등의 절차가 개시 또는 신청되는 경우
        </Typography>
        <Typography component="li" sx={{ mb: 1 }}>
          “고객사”가 제공한 상품에 관하여 회원의 이의제기(철회. 항변권 및 본인
          미사용 주장 포함)나 신용 구매분에 대한 카드사의 대금연체가 대량 또는
          빈번히 발생하여 더 이상 정상적인 거래가 부적합하다고 결제기관 또는
          “회사”가 판단하는 경우
        </Typography>
        <Typography component="li" sx={{ mb: 1 }}>
          상대방의 사전 서면동의 없이 본 계약과 관련된 “고객사”의 소유권 기타
          권리의 전부나 일부를 제3자에게 임대, 판매, 양도하는 경우 “고객사”의
          사이트에서 불법 또는 부정매출(현금성 대출처리 등)이 발생한 경우
        </Typography>
        <Typography component="li" sx={{ mb: 1 }}>
          기타 건전한 상거래관행과 신의성실의 원칙에 어긋나는 행위로 인해
          정상적인 계약유지가 어렵다고 판단되는 경우
        </Typography>
        <Typography component="li" sx={{ mb: 1 }}>
          “회사”의 심사 기준상 비정상적인 인터넷 전자상거래라고 판단되는 경우
          또는 최초 “회사”와 계약 당시의 물품 및 서비스 제공이 상이하거나
          “회사”에 통보 없이 추가 판매 및 서비스를 제공하는 경우 (예:
          다단계판매, 자점매출, 현금깡, 경매, 사업자등록증에 명기되지 않은
          물품[서비스]판매, "회사"의 심사 기준상 불가한 상품 및 서비스 형태의
          서비스 제공 등)
        </Typography>
        <Typography component="li" sx={{ mb: 1 }}>
          특별한 사유 없이 3개월 이상 결제승인 요청이 없거나 사전 서면 통지없이
          타 업체로 전자결제서비스를 변경 또는 중복하여 이용하는 경우
        </Typography>
        <Typography component="li" sx={{ mb: 1 }}>
          “회사”에게 폐업 및 해제요청을 통보하지 않은 경우
        </Typography>
        <Typography component="li" sx={{ mb: 1 }}>
          "고객사"가 "회사"에게 등록 요청한 사이트(홈페이지)외 제3의 사이트에서
          매출이 발생한 경우
        </Typography>
        <Typography component="li" sx={{ mb: 1 }}>
          제19조 (계약 변경 사항 통보 및 손해배상)에 명시한 변경내용의 통보가
          되지 않은 상태에서 "고객사"가 쇼핑몰을 운영하여 "회사"가 이를 확인하고
          "회사"의 전자결제심사기준에 부적합한 경우
        </Typography>
        <Typography component="li" sx={{ mb: 1 }}>
          “고객사”와 “회사”가 서면 합의한 경우
        </Typography>
        <Typography component="li" sx={{ mb: 3 }}>
          “고객사” 이용자의 거래 취소 및 환불이 과다하게 발생하거나 지속해서
          발생하는 경우, “회사”는 “고객사”에 서면으로 통지하고 계약을 해지할 수
          있다.
        </Typography>
      </Box>

      <Typography sx={{ fontWeight: "bold", mb: 1 }}>
        제 6 조 (“고객사”의 의무)
      </Typography>
      <Box className="ol" component="ol">
        <Typography component="li" sx={{ mb: 1 }}>
          “고객사”는 상품에 대한 제조, 판매 등과 관련된 제반 법규를 준수하여야
          하며, 관계당국의 허가, 인가 또는 신고 등 제반 절차가 필요한 경우 이를
          반드시 필(必) 함으로써 적법하게 전자상거래행위를 하여야 한다.
        </Typography>
        <Typography component="li" sx={{ mb: 1 }}>
          “고객사”가 “회사”의 파트너가 될 경우 “고객사”는 이용자와 “회사”에 대해
          성실하고 신뢰성 있게 동 상점을 운영하여야 하며, “고객사”의 불성실한
          상점 운영으로 인하여 “회사” 및 이용자에게 피해를 주지 않도록 하여야
          한다.
        </Typography>
        <Typography component="li" sx={{ mb: 1 }}>
          “고객사”는 “회사”의 전자결제서비스를 이용하기 위해 “고객사”측에 필요한
          장치 및 프로그램을 설치해 두어야 한다.
        </Typography>
        <Typography component="li" sx={{ mb: 1 }}>
          “고객사”는 “회사”의 전자결제서비스를 이용하는 대가로 부칙2의
          전자결제서비스 이용수수료 및 등록비, 연 관리비를 “회사”에게 지불하기로
          한다.
        </Typography>
        <Typography component="li" sx={{ mb: 1 }}>
          “고객사”는 상품의 주문 및 지불완료를 확인한 후에 “고객사”가 정한
          방법에 따라 이용자에게 배송(혹은 서비스제공) 하며, 이때 “고객사”는
          이용자의 부정한 거래에 대비하여 향후 거래자의 확인 또는 추적이
          가능하도록 별도로 정하는 증빙자료를 확보하여야 한다.
        </Typography>
        <Typography component="li" sx={{ mb: 1 }}>
          “고객사”가 “회사”의 대표가맹점 상점으로서 상품을 판매한
          경우,‘㈜옵타움플랫폼'의 명의로 대금이 청구됨을 이용자가 쉽게 인식할 수
          있도록 동 상점의 주요화면에 명시하여야 하며, 이때 “회사”의 오직페이
          로고(이미지)를 삽입한다.
        </Typography>
        <Typography component="li" sx={{ mb: 1 }}>
          "고객사"는 신용카드가 사용될 때(승인) 본인에 의해서 사용되는지 필히
          확인하여야 할 의무가 있으며, 추후 타인의 사용으로 인한 민원 및
          "회사"에게 피해 발생 시 해결될 때까지 모든 책임은 "고객사"가 지도록
          한다.
        </Typography>
        <Typography component="li" sx={{ mb: 1 }}>
          거래의 취소, 환불 또는 부정한 거래로 인한 사고 시 이용자의 확인, 추적
          또는 채권회수를 위하여 “회사”가 필요로 하는 경우, “고객사”는 이용자에
          관한 정보를 지체 없이 “회사”에게 전송한다. (이용자의 성명, 전화번호,
          주소, 전자메일주소 등 일체의 배송 및 서비스 제공정보)
        </Typography>
        <Typography component="li" sx={{ mb: 1 }}>
          사고발생(쇼핑몰의 물품 미배송/부도, 미수금발생 등)에 따라 "고객사"에서
          "회사"와의 계약을 준수하지 못할 경우를 대비하기 위한 최소한의 담보로써
          “고객사”는 “회사”를 피보험자로 하여 보증보험에 가입한다.
        </Typography>
        <Typography component="li" sx={{ mb: 3 }}>
          “고객사”는 계약해지 후 “회사”의 전자결제시스템 이용기간 중 발생한
          거래에 대해 이용자가 민원(결제기관 접수 민원 포함)을 제기할 경우,
          신의성실 원칙에 따라 해결하여야 하며, 그에 대한 결과를 “회사”와
          이용자에게 통지하여야 한다.
        </Typography>
      </Box>
      <Typography sx={{ fontWeight: "bold", mb: 1 }}>
        제 7 조 (“회사”의 의무)
      </Typography>
      <Box className="ol" component="ol">
        <Typography component="li" sx={{ mb: 1 }}>
          “회사”는 본 계약기간 중 이용자가 구입한 “고객사”의 상품과 관련하여
          “고객사”가 그 판매대금을 원활히 지급 받을 수 있도록 신뢰성 있는
          전자결제서비스를 제공한다.
        </Typography>
        <Typography component="li" sx={{ mb: 1 }}>
          “회사”는 “고객사”의 상품을 구매한 이용자가 전자결제서비스를 이용하여
          안전하고 편리하게 지불할 수 있도록 “고객사”가 운영하는 전자상거래
          시스템과 “회사”의 전자결제서버가 연동하도록 지원한다.
        </Typography>
        <Typography component="li" sx={{ mb: 1 }}>
          “회사”는 “고객사”가 파트너가 되는 경우를 대비하여 각 신용카드사들과
          가맹점 계약을 체결하여야 한다. <br />
          (단, "고객사"의 판매물품 및 단가의 특성이 각 개별 카드사의 등록정책에
          맞지 않을 경우, 해당 카드사를 이용하지 못할 수 있다.)
        </Typography>
        <Typography component="li" sx={{ mb: 1 }}>
          전자결제서비스와 관련하여 “고객사”측에 설치된 전자시스템에 대해 기능의
          추가 또는 개선이 필요하다고 판단되는 경우 “회사”는 “고객사”에게
          유선,서면 및 이메일 공지 등으로 기능을 추가하거나 개선한다.
        </Typography>
        <Typography component="li" sx={{ mb: 3 }}>
          “회사”는 전자결제서비스를 연중무휴 24시간 제공함을 원칙으로 한다.
          다만, 동 서비스 관련 시스템의 정기점검 또는 기술상의 필요 등에 인하여
          일시적인 서비스 중단이 불가피한 경우에는 “고객사”에게 사전통보하기로
          한다. 단, 불가항력 또는 천재지변에 의한 경우는 그러하지 아니하다.
        </Typography>
      </Box>
      <Typography sx={{ fontWeight: "bold", mb: 1 }}>
        제 8 조 (거래승인 및 본인인증)
      </Typography>
      <Box className="ol" component="ol">
        <Typography component="li" sx={{ mb: 1 }}>
          거래승인의 의미는 그 거래에 사용되는 카드의 유효성 여부를 확인하는
          것이며, 거래 자체의 진위성 및 본인에 의해 정당하게 사용되는지 여부에
          대한 관리는 “고객사”의 책임으로 한다.
        </Typography>
        <Typography component="li" sx={{ mb: 1 }}>
          “회사”,”VAN사”,”신용카드사”의 3자간 제휴에 의하여 제공하는 신용카드
          본인인증 서비스를 “고객사”가 선택 하여 이용할 경우 “고객사”는 동
          서비스 이용수수료(부칙2)를 부담 하여야 한다.
        </Typography>
        <Typography component="li" sx={{ mb: 1 }}>
          “회사”는 “고객사”가 보내는 메시지 전문에 의거하여 인증여부를 결정하고
          처리하도록 하며, 인증에 대한 책임소재는 전적으로 “고객사”가 보내는
          메시지 전문을 기준으로 한다.
        </Typography>
        <Typography component="li" sx={{ mb: 1 }}>
          해외카드의 경우 부도율이 높은 관계로 “회사”의 시스템에서는 승인거절을
          원칙으로 한다.
        </Typography>
        <Typography component="li" sx={{ mb: 1 }}>
          "회사"는 "고객사"의 전자결제서비스 신청서를 접수받고 "회사"의 심사기준
          및 “고객사”의 신용도, 매출유형(판매형태, 판매물품 등), 이용자의
          이의신청 및 민원발생 빈도, 민원해결을 위한 협조성 등 "회사"의
          리스크관리운영 방침에 따라 “고객사”에 대하여 신용카드(체크카드) 월
          승인 한도 및 월 승인한도액을 제한하여 지정할 수 있다. <br />
          (단, "회사"와의 계약성립 이후 "고객사"의 매출에 대한 민원발생빈도,
          매출유형 (판매형태, 판매물품 등) 등을 기준으로 변동할 수 있다.).
        </Typography>
        <Typography component="li" sx={{ mb: 3 }}>
          "고객사"의 쇼핑몰에서 발생한 신용카드 매출이 신용카드사로부터
          비정상거래 및 신용구매분에 대한 카드사의 대금연체 등의 사유로 확인되어
          승인이 제한된 경우, 신용카드사의 승인제한 조치에 따른 모든 손실 발생
          부분은 "고객사"가 책임을 진다.
        </Typography>
      </Box>
      <Typography sx={{ fontWeight: "bold", mb: 1 }}>
        제 9 조 (거래의 매입요청)
      </Typography>
      <Box className="ol" component="ol">
        <Typography component="li" sx={{ mb: 1 }}>
          매입요청 : “고객사”는 반드시 이용자가 구입한 상품 및 용역이 이상 없이
          제공되었는지 여부를 물품배달 확인서 등을 통하여 확인 또는 물품배송이
          완료된 후 이용자의 구입상품 및 용역대금을 “회사”가 지정한 절차와
          형식에 따라 “회사”에게 통보하며, “회사”는 이를 근거로 해당 카드사에
          매입을 요청한다.
        </Typography>
        <Typography component="li" sx={{ mb: 1 }}>
          요청기간 : 전항의 매입요청은 자동매입을 기본으로 한다.
        </Typography>
        <Typography component="li" sx={{ mb: 1 }}>
          매입요청시기 : 매입요청의 접수마감은 부칙2와 같으며, 접수마감일 이후
          매입요청건은 차기 정산일에 포함되어 정산된다. “회사”의 사정으로 매입
          요청시기가 변경될 경우 “고객사”와의 사전협의를 거쳐 통보한다.
        </Typography>
        <Typography component="li" sx={{ mb: 3 }}>
          취소기간의 확보 : “고객사”의 매출내역에 대하여 매출 취소율이
          과다하다고 판단되는 경우, “회사”는 “고객사”의 매입요청시기를 조정하여
          취소기간을 확보할 수 있다.
        </Typography>
      </Box>

      <Typography sx={{ fontWeight: "bold", mb: 1 }}>
        제 10 조 (이용수수료 정산)
      </Typography>
      <Box className="ol" component="ol">
        <Typography component="li" sx={{ mb: 1 }}>
          신용카드의 경우 “회사”의 대표가맹점을 통하여 거래승인을 받은 후,
          매입요청 건을 정산대상으로 한다.
        </Typography>
        <Typography component="li" sx={{ mb: 1 }}>
          매입요청 후 정산주기에 맞게 정산하는 것을 원칙으로 하며, 지급일이 휴일
          및 공휴일의 경우 익영업일에 지급한다.
        </Typography>
        <Typography component="li" sx={{ mb: 1 }}>
          “회사”는 정산대금의 지급시 신용카드 가맹점수수료, 이자할부수수료,
          인증수수료, 은행이체 수수료, 전자결제서비스 이용수수료, 연 관리비 및
          기타 차감금액을 공제한 대금을 “고객사”에게 지급한다.
        </Typography>
        <Typography component="li" sx={{ mb: 1 }}>
          “회사”는 “고객사”의 대금청구에 대하여 정해진 주기에 따라 정산하여
          “회사”의 수수료를 공제한 금액을 “고객사”에게 지급하고, 회사”가 공제한
          수수료에 대하여 세금계산서를 월 1회 “고객사”에게 교부한다.
        </Typography>
        <Typography component="li" sx={{ mb: 3 }}>
          “고객사”는 “회사”에게 거래취소분 및 기타 차감금액을 즉시 지급하여야
          하며,“회사”는 위 거래취소 분 및 기타 차감금액을 “고객사”에게
          지급하여야 할 대금에서 공제할 수 있다. 단, “고객사”의 매출이 발생하지
          않아 차감하지 못할 경우 “회사”는 “고객사”에게 과 지급액 및 차감
          부족액에 대한 반환청구권을 갖는다.
        </Typography>
      </Box>

      <Typography sx={{ fontWeight: "bold", mb: 1 }}>
        제 11 조 (등록비 및 연 관리비 수수료)
      </Typography>
      <Box className="ol" component="ol">
        <Typography component="li" sx={{ mb: 1 }}>
          “고객사”는 “회사”와의 전자결제서비스 계약을 위하여 등록비를 “회사”에게
          입금한다.
        </Typography>
        <Typography component="li" sx={{ mb: 1 }}>
          “회사”는 원활한 전자결제서비스 제공을 위하여 각종 서비스 개발 및
          유지보수 관리비용으로 연 관리비를 “고객사”에게 청구한다.
        </Typography>
        <Typography component="li" sx={{ mb: 1 }}>
          기 납부된 등록비 및 연 관리비를 단순변심 등 “고객사”의 귀책사유로는
          환불이 불가능 하다.
        </Typography>
        <Typography component="li" sx={{ mb: 3 }}>
          연회비 청구는 “고객사”의 결제대금에서 차감 정산하며, “고객사”의 매출이
          3개월이상 발생치 않아 연회비 차감정산이 불가할 경우 자동으로 계약은
          해지된다. <br />
          (단, 최초 가입시 카드결제나 기타 수단으로 선 입금한 경우는 제외한다.)
        </Typography>
      </Box>

      <Typography sx={{ fontWeight: "bold", mb: 1 }}>
        제 12 조 (이용자의 구매취소 및 환불)
      </Typography>
      <Box className="ol" component="ol">
        <Typography component="li" sx={{ mb: 1 }}>
          “고객사”는 원칙적으로 “이용자”가 “회사”가 제공하는 “서비스”를 통해
          “고객사”의 “쇼핑몰”에서 물품 등을 구입한 최초 거래(이하 “원 거래”)에
          대해 거래일로부터 1년 내에 거래취소 및 환불 요청이 가능합니다. 단, 위
          환불 기관이 초과된 건에 대한 환불 요청은 발생 건 별로 양 당사자간
          협의에 의하여 정합니다.
        </Typography>
        <Typography component="li" sx={{ mb: 1 }}>
          이 약관에 따라 “고객사”에 거래취소 등 처리 의무가 있는 거래에 대해
          “고객사”가 이를 7 영업일 이내에 처리하지 않을 경우, “결제기관” 혹은
          “회사”는 이를 직접 처리하고 그 결과를 “고객사”에 통지할 수 있습니다.
        </Typography>
        <Typography component="li" sx={{ mb: 1 }}>
          “결제기관” 또는 “회사”가 직접 취소 처리한 거래 건이 발생한 경우,
          “회사”는 “이용자”에게 해당 결제금액을 환불합니다. 해당 건이 “회사”와
          “고객사” 간 이미 정산된 경우에 “회사”는 우선 “이용자”에게 해당
          거래대금을 환불하고, 차기 정산대금에서 해당 대금과 결제수수료를 합산한
          금액을 차감합니다. 차감할 금액이 없을 경우 “회사”는 취소매입요청을
          거부할 수 있으며, 이용계약이 해지된 후라도 해당 금액을 “고객사”에게
          청구할 수 있으며 “고객사”는 “회사”의 청구일로부터 7영업일 이내에
          “회사”에 청구금액을 지급해야 합니다.
        </Typography>
        <Typography component="li" sx={{ mb: 1 }}>
          환불이 정상적으로 완료된 건은 일체의 수정 및 번복을 할 수 없습니다.
        </Typography>
        <Typography component="li" sx={{ mb: 1 }}>
          “고객사”의 요청으로 “회사”가 처리한 환불에 대한 모든 책임 및 “회사”가
          제공하는 환불 기능을 이용하지 않아 발생하는 모든 책임은 “고객사”에
          있습니다.
        </Typography>
        <Typography component="li" sx={{ mb: 1 }}>
          “고객사”는 “회사에”에 대한 환불 요청 전에 해당 건이 환불 대상에
          적합한지 반드시 확인해야 하며, 사전에 “이용자”가 부당이득을 취할 수
          없는 정책을 수립하고 “이용자” 동의를 받아야 합니다.
        </Typography>
        <Typography component="li" sx={{ mb: 1 }}>
          “고객사”는 환불을 위하여 “회사”가 요청하는 정보를 전달하여야 하며,
          “고객사”는 정보의 적합성을 보장하고, 해당 정보의 오류로 인하여 발생한
          민원에 대해 “회사”는 일체의 책임을 지지 않습니다.
        </Typography>
        <Typography component="li" sx={{ mb: 1 }}>
          “회사”는 “고객사”의 환불신청을 접수 받은 경우 신속하고 정확하게
          환불처리를 합니다. 단, “고객사” 또는 은행 등 “결제기관”의 시스템의
          장애 등, “회사”의 귀책이 아닌 사유로 환불처리가 지연되거나 오류가
          발생한 건에 대해, “고객사”는 “회사”에게 일체의 책임을 물을 수
          없습니다.
        </Typography>
        <Typography component="li" sx={{ mb: 3 }}>
          “회사”는 “고객사”의 거래규모, 환불처리 규모, 결제수단의 특성 및 고객
          민원수준 등을 고려하여 “고객사”의 월간 환불 가능 한도액을 지정할 수
          있고, “고객사”의 고의, 과실 등에 의하여 환불과 관련한 사고가 발생한
          경우 “회사”는 “고객사”에 환불기능의 제공을 중단하거나 기능의 제한을 할
          수 있습니다. 단, 환불 가능 한도액은 양 당사자의 협의 또는 “고객사”의
          담보 (손실보증금)제출에 의해 조정이 가능합니다.
        </Typography>
      </Box>

      <Typography sx={{ fontWeight: "bold", mb: 1 }}>
        제 13 조 (비정상 및 부정거래에 대한 처리)
      </Typography>
      <Box className="ol" component="ol">
        <Typography component="li" sx={{ mb: 1 }}>
          부정한 또는 불법적 거래로 인한 매출(현금성 대출, 본인
          미사용건[제3자도용,사고]등)에 대하여 “회사”는 “고객사”에게 대금정산
          의무를 지지 않으며 만약 “고객사”에게 이미 지급된 경우에는 사후에
          차감정산 또는 반환 청구권이 있다.
        </Typography>
        <Typography component="li" sx={{ mb: 1 }}>
          "회사"의 결제시스템을 이용하여 발생한 거래 가운데 "고객사"가 정상적인
          거래임을 입증하지 못한 경우, 또는 이용자가 불법적인 거래를 목적으로
          발생시킨 비정상거래에 대하여 "고객사"는 "회사" 또는
          관련기관(금융기관등) 으로부터 서면, 이메일 또는 유선으로 확인 요청이
          있을 경우 거래내역 관련 자료와 함께 제6조 8항에 명시한 제반
          증빙자료(배송장, 주문내역, 수령증 등)를 요청일로부터 제 3 영업일
          이내에 제출하여야 한다.
        </Typography>
        <Typography component="li" sx={{ mb: 1 }}>
          "회사" 또는 관련기관(금융기관 등)에서 비정상 거래로 확인된 해당 거래의
          취소 처리를 요청할 경우 "고객사"는 즉시 취소처리를 하여야 하며,
          “고객사”의 처리가 7일동안 지연될 경우 "회사"는 해당 거래 내역을 임의로
          취소할 수 있다.
        </Typography>
        <Typography component="li" sx={{ mb: 3 }}>
          "고객사"의 사이트에서 발생한 결제수단 별 거래 중 비정상거래로 인하여
          관련기관(금융기관 등)에서 "회사"의 정산대금 가운데 일부 또는 전부를
          지급보류 조치하거나 또는 그에 상응하는 제반조치를 취할 것이 예상된다고
          "회사"가 판단한 경우, "회사"는 "고객사"의 정산대금 중 전부 또는 일부를
          지급보류 조치할 수 있으며, 해당 금액이 부족할 경우 "고객사"는 "회사"가
          지정, 요청한 방법에 따라 "회사"의 손실금액을 보전해주어야 한다.
        </Typography>
      </Box>

      <Typography sx={{ fontWeight: "bold", mb: 1 }}>
        제 14 조 (지급보류)
      </Typography>
      <Box className="ol" component="ol">
        <Typography component="li" sx={{ mb: 1 }}>
          다음 각호의 어느 하나에 해당하는 사유가 발생하는 경우 “회사”는
          정산대금의 일부 또는 전부의 지급을 보류할 수 있으며, 정산대금의
          지급보류시 “고객사”에게 그 사실을 통지하여야 합니다.
        </Typography>
        <Box component="ol">
          <Typography component="li" sx={{ mb: 1 }}>
            “서비스”를 이용하여 현금할인 및 재판매 등의 행위를 한 경우
          </Typography>
          <Typography component="li" sx={{ mb: 1 }}>
            “고객사”가 회원제, 다단계, 상품권, 선불제, 전표유통 등의 사업을
            불법적으로 영위하거나 그 사업의 결과, 장래 거래 취소의 우려가 있는
            경우
          </Typography>
          <Typography component="li" sx={{ mb: 1 }}>
            “이용자”가 본인 미사용 또는 “상품” 미배송 등의 이의를 제기한 경우
          </Typography>
          <Typography component="li" sx={{ mb: 1 }}>
            “고객사”의 “매입” 요청내역이 “결제기관”의 지급내역과 상이한 경우
          </Typography>
          <Typography component="li" sx={{ mb: 1 }}>
            “고객사”가 약관에서 정한 손해배상 및 담보제공을 이행하지 아니한 경우
          </Typography>
          <Typography component="li" sx={{ mb: 1 }}>
            “고객사”의 정산예정금액의 비정상적 증대 또는 감소, 정산된 “이용자”의
            거래가 민원, 이의제기 등으로 취소될 개연성이 있는 경우
          </Typography>
          <Typography component="li" sx={{ mb: 1 }}>
            “고객사”가 제3자로부터 보전처분(가압류, 가처분 등) 또는 강제집행
            등을 받는 경우
          </Typography>
          <Typography component="li" sx={{ mb: 1 }}>
            “고객사”가 “서비스” 이용을 중단하고자 할 때 “회사”가 “고객사” 에게
            지급할 정산대금에 대해 “이용자”의 취소 및 민원제기 등을 대비하여
            일정 금액을 보류해야 하는 경우
          </Typography>
          <Typography component="li" sx={{ mb: 1 }}>
            정산금액이 월 지급 한도액을 초과하는 경우
          </Typography>
          <Typography component="li" sx={{ mb: 1 }}>
            이용계약 무효 사유가 존재하거나 이용신청에 대한 “회사”의 승낙이
            이루어지기 전 정산대금이 발생한 경우
          </Typography>
          <Typography component="li" sx={{ mb: 1 }}>
            “비정상거래”로 확인되거나 의심되는 경우
          </Typography>
          <Typography component="li" sx={{ mb: 3 }}>
            “고객사”가 이 약관, 관련 법령, “회사”의 정책을 위반하거나 위반한
            것으로 의심되는 경우
          </Typography>
        </Box>
        <Typography component="li" sx={{ mb: 1 }}>
          본 조 또는 본 계약에 따라 지급보류 된 정산대금은 보류 3달 후 전항 각
          호의 사유 해결 여부 또는 지급 보류된 정산대금에 상응하는 담보의 추가
          제공 등에 따라 차감 후 "고객사"에게 분할 지급합니다.
        </Typography>
        <Typography component="li" sx={{ mb: 1 }}>
          제1항 제10호의 경우 기 발생한 정산대금에 관하여는 “수수료” 상당의
          비용이 발생할 수 있으며, “회사”는 전항에 따라 “고객사”에 지급되어야
          하는 정산대금에서 상기 비용 및 손해배상액을 상계할 수 있습니다.
        </Typography>
        <Typography component="li" sx={{ mb: 1 }}>
          제1항의 정산대금이 이미 "고객사"에게 지급된 경우 "회사"는 "고객사"에
          지급할 예정인 차기 정산대금의 지급을 보류할 수 있습니다.
        </Typography>
        <Typography component="li" sx={{ mb: 3 }}>
          지급보류 된 정산대금이 지급보류 사유의 해소 또는 지급 보류된
          정산대금에 상응하는 담보의 추가 제공 등의 사유로 “고객사”에게 지급될
          경우 특별한 사정이 없는 한 지급보류 된 정산대금의 원금만 지급되며,
          “회사”는 고의, 중과실이 없는 한 지급보류로 인한 손해배상책임이
          면제됩니다.
        </Typography>
      </Box>

      <Typography sx={{ fontWeight: "bold", mb: 1 }}>
        제 15 조 (손해배상)
      </Typography>
      <Box className="ol" component="ol">
        <Typography component="li" sx={{ mb: 1 }}>
          이용자의 이의제기(주문사실 불인정, 금액상이, 물품에 대한 불만족으로
          인한 반환 요청, 배송사고, 파손 등) 또는 주문접수과정에서 “고객사”의
          과실로 이용자와 “고객사”간에 분쟁이 발생한 경우 전적으로 “고객사”가
          해결해야 하며 “회사” 또는 이용자의 책임이 아닌 사유로 인하여 발생한
          손해에 대하여 “고객사”는 “회사” 또는 이용자가 손해배상을 통지한
          날로부터 7일 이내에 전액을 배상하여야 한다.
        </Typography>
        <Typography component="li" sx={{ mb: 1 }}>
          “고객사”가 전 1항에 규정한 “고객사”의 손해배상 의무를 이행하지 않을
          경우 “회사”는 동 매출을 취소하거나 다음 번 청구금액에 손해배상 금액에
          대한 변제 및 충당 등 제반조치를 취할 수 있다.
        </Typography>
        <Typography component="li" sx={{ mb: 1 }}>
          “회사”는 “회사” 또는 이용자의 손해에 대하여 “고객사”가 배상하지
          못하였을 경우 “손실보증금”과 상계하여 처리 할 수 있다.
        </Typography>
        <Typography component="li" sx={{ mb: 1 }}>
          본 계약을 이행하는 과정에서 계약위반 또는 법규위반 등 귀책사유 있는
          당사자는 상대방 또는 제3자에 대한 손해를 배상하여야 한다. 단,
          천재지변, 전쟁 등의 불가 항력적인 사유로 인한 경우에는 그러하지
          아니하다.
        </Typography>
        <Typography component="li" sx={{ mb: 3 }}>
          양 당사자는 전항의 손해를 자신의 비용으로 해결한 경우, 귀책사유 있는
          상대방에게 구상할 수 있다.
        </Typography>
      </Box>

      <Typography sx={{ fontWeight: "bold", mb: 1 }}>
        제 16 조 (계약담보의 환원)
      </Typography>
      <Typography sx={{ mb: 3 }}>
        본 계약이 해지되는 때에는 “고객사”와의 계약기간 동안 발생한 신용판매와
        관련하여 “회사”에게 손해가 발생하지 아니하고 이용자의 이의제기,
        신용카드사, 국세청 등 관공서에서 제재가 없는 경우에 한하여 6개월 경과 후
        “손실보증금”을 “고객사”에게 환원할 수 있다. <br />
        (단, 보증보험증권에 대하여서는 그러하지 아니하며, 보증보험증권에 대한
        모든 사항은 제14조 2항, 2-1호, 2-2호에 의거하여 적용한다.)
      </Typography>

      <Typography sx={{ fontWeight: "bold", mb: 1 }}>
        제 17 조 (신용카드 서비스)
      </Typography>
      <Box className="ol" component="ol">
        <Typography component="li" sx={{ mb: 1 }}>
          “회사”의 “서비스”를 이용하여 발생한 “고객사”의 할부거래는 “할부거래에
          관한 법률”이 정한 바에 따릅니다.
        </Typography>
        <Typography component="li" sx={{ mb: 1 }}>
          “회사”는 “고객사”에게 신용카드사에서 인정한 할부판매 개월 수를
          제공하는 것을 원칙으로 합니다.
        </Typography>
        <Typography component="li" sx={{ mb: 1 }}>
          “고객사”가 할부판매 시 “이용자”에게 회원가입비(영업수당 제공을 통한
          회원제 형태의 다단계 영업으로 물품을 판매하는 거래, 현실적으로 이행이
          어렵고 민원제기 가능성이 많은 “상품”의 제공을 거래의 목적으로 하는
          경우 포함)를 징구하는 경우에는 할부거래중지 또는 할부거래 개월 수를
          제한할 수 있습니다.
        </Typography>
        <Typography component="li" sx={{ mb: 1 }}>
          “고객사”는 “이용자”로부터 할부 항변의 사유가 발생할 경우를 대비하여
          “회사”가 정한 방법에 따라 담보를 제공하여야 합니다.
        </Typography>
      </Box>

      <Typography sx={{ fontWeight: "bold", mb: 1 }}>
        제 18 조 (정보유출 금지)
      </Typography>
      <Typography sx={{ mb: 3 }}>
        “고객사”와 ”회사”는 전자결제서비스를 제공, 이용하면서 획득하게 된
        이용자의 정보, 쌍방의 대외비 기술자료, 영업비밀 또는 기타 업무상
        기밀사항 등을 제3자에게 누설하거나 본 계약상의 업무 목적 외로
        사용하여서는 아니 되며, 이를 위반할 경우 그로 인하여 발생되는 손해에
        대해서는 그 위반 책임이 있는 자가 손해배상하고 모든 민, 형사상의 책임을
        진다.
      </Typography>

      <Typography sx={{ fontWeight: "bold", mb: 1 }}>
        제 19 조 (서비스 변경)
      </Typography>
      <Box className="ol" component="ol">
        <Typography component="li" sx={{ mb: 1 }}>
          “회사”는 상당한 이유가 있는 경우에 운영상, 기술상의 필요에 따라
          제공하고 있는 전부 또는 일부 “서비스”를 변경할 수 있습니다.
        </Typography>
        <Typography component="li" sx={{ mb: 1 }}>
          “서비스”의 내용, 이용방법, 이용시간에 대하여 변경이 있는 경우에는
          변경사유, 변경될 서비스의 내용 및 제공일자 등은 그 변경 전에 해당
          “서비스” 화면에 게시하여야 합니다.
        </Typography>
        <Typography component="li" sx={{ mb: 3 }}>
          “회사”는 무료로 제공되는 “서비스”의 일부 또는 전부를 “회사”의 정책 및
          운영의 필요상 수정, 중단, 변경할 수 있으며, 이에 대하여 관련 법령에
          특별한 규정이 없는 한 “고객사”에게 별도의 보상을 하지 않습니다.
        </Typography>
      </Box>

      <Typography sx={{ fontWeight: "bold", mb: 1 }}>
        제 20 조 (부 칙)
      </Typography>
      <Box className="ol" component="ol">
        <Typography component="li" sx={{ mb: 1 }}>
          “고객사”가 “회사”의 전자결제서비스를 이용함에 있어, 본 계약상 규정되지
          않은 사항에 관하여는 다음 각 호의 관련 법령이나 규약 등이 적용되며
          각종분쟁은 이를 근거로 처리한다.
        </Typography>
        <Box component="ol">
          <Typography component="li" sx={{ mb: 1 }}>
            여신전문금융업법, 카드사의 가맹점 규약, 선불카드 또는 직불카드
            관련약관 또는 규약, 은행법, 해당 금융기관의 예금거래 기본약관 등
            금융거래 관련 법령 및 규정
          </Typography>
          <Typography component="li" sx={{ mb: 1 }}>
            전자상거래 등에서의 소비자보호에 관한 법률, 독점규제 및 공정거래에
            관한 법률, 할부거래에 관한 법률, 방문판매 등에 관한 법률 등 소비자
            보호관련법령 및 규정
          </Typography>
        </Box>
        <Typography component="li">
          본 계약 내용에 관한 법률적인 해석에 이견이 있는 경우, “고객사”와
          “회사”가 상호 성실히 협의하여 처리하되 협의가 이루어지지 않아 법률적인
          분쟁이 야기되는 경우에는 “회사”의 본점 소재지를 관할하는 법원의 판결에
          따른다.
        </Typography>
      </Box>
      <style>{`
      .ol {
        counter-reset: list;
      }
      .ol > li {
        list-style: none;
      }
      .ol > li:before {
        content: counter(list) ") ";
        counter-increment: list;
      }
      `}</style>
    </>
  );
};
export default EPaymentService;
