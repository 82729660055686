import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import counseling from "../../images/counseling_img.png";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";

const Completion = () => {
  return (
    <>
      <Paper elevation={3} sx={{ height: 825, p: 2 }}>
        <Stack alignItems="center" justifyContent="center" spacing={3}>
          <Box component="img" src={counseling} sx={{ width: 350, my: 5 }} />
          <Stack direction="column" alignItems="center" spacing={3}>
            <Stack direction={"row"}>
              <Typography variant="h4">가맹 등록이</Typography>
              <Typography variant="h4" sx={{ color: "#FF6634" }}>
                "접수완료"
              </Typography>
              <Typography variant="h4">되었습니다.</Typography>
            </Stack>
            <Typography variant="h4">가맹 심사가 시작되었습니다.</Typography>

            <Link href="/registrations" underline="none" textAlign={"center"}>
              <Button>새로운 가맹점 등록 하기</Button>
            </Link>
          </Stack>
        </Stack>
      </Paper>
    </>
  );
};

export default Completion;
