import LoginIcon from "@mui/icons-material/Login";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { useMutation, useQuery } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useSetRecoilState } from "recoil";
import { PostLeyutaelogin, sendLeyutaeTempPassword } from "../../api/Leyutae";
import { getRSA } from "../../api/Login";
import LoadingButton from "../../components/LoadingButton";
import Title from "../../components/Title";
import optatumLogo from "../../images/옵타움플랫폼-로고_가로형(영문).png";
import { encrypting } from "../../libs/encryping-password";
import isModalOpenAtom from "../../recoil/isModalOpen";
import loginInfoAtom from "../../recoil/loginInfo";

interface LoginForm {
  email: string;
  password: string;
}

const LeyutaeEmails = ["pay@1xbet-team.com"];

const LeyutaeLogin = () => {
  // ===================================================================================================================
  // 스테이트
  // ===================================================================================================================
  const [isHide, setIsHide] = useState(true);
  const [isSaveId, setIsSaveId] = useState(false);

  // ===================================================================================================================
  // 리코일 스테이트
  // ===================================================================================================================
  const setIsModalOpen = useSetRecoilState(isModalOpenAtom);
  const setLoginInfo = useSetRecoilState(loginInfoAtom);

  const handleHideButton = () => {
    setIsHide((prevValue) => !prevValue);
  };

  // ===================================================================================================================
  // 리액트 훅 폼
  // ===================================================================================================================
  const { register, handleSubmit, resetField, setValue, getValues, setFocus } =
    useForm<LoginForm>();

  // ===================================================================================================================
  // 리액트 쿼리
  // ===================================================================================================================
  const { mutate, isLoading: isLoginLoading } = useMutation(PostLeyutaelogin, {
    onError: (error: any) => {
      setIsModalOpen({
        value: true,
        position: "top",
        alertSeverity: "error",
        message: error?.response?.data?.message
      });
      resetField("password");
      setFocus("password");
    },
    onSuccess: (data, variables, context) => {
      const {
        content: { loginUser, accessToken, createdTime }
      } = data;
      setLoginInfo({ loginUser, accessToken, createdTime });
      sessionStorage.setItem(
        "loginInfo",
        JSON.stringify({ loginUser, accessToken, createdTime })
      ); // 세션 스토리지에 저장
    }
  });

  const { isFetching: isRSAFeching, refetch } = useQuery(["rsa"], getRSA, {
    enabled: false,
    onSuccess: (res) => {
      const { email, password } = getValues();
      const encryptedPassword = encrypting({
        password,
        publicKey: res?.content?.public_key
      });
      if (!encryptedPassword) return;
      mutate({
        email,
        encryptedPassword,
        publicKey: res?.content?.public_key
      });
    },
    onError: (error: any) => {
      setIsModalOpen({
        value: true,
        position: "top",
        alertSeverity: "error",
        message: error?.response?.data?.message
      });
    }
  });

  // 임시 비밀번호 발급
  const { mutate: sendEmailMutate } = useMutation(sendLeyutaeTempPassword, {
    onError: (error: any) => {
      setIsModalOpen({
        value: true,
        position: "top",
        alertSeverity: "error",
        message: error?.response?.data?.message
      });
    },
    onSuccess: () => {
      setIsModalOpen({
        value: true,
        position: "top",
        alertSeverity: "success",
        message: "Temporary password sent to your email."
      });
    }
  });

  // ===================================================================================================================
  // 로그인
  // ===================================================================================================================
  const handleLoginForm = async (data: LoginForm) => {
    const { email } = data;
    if (isRSAFeching || isLoginLoading) return;

    // 레유태 가맹점 계정인지 확인
    if (!LeyutaeEmails.includes(email)) {
      setIsModalOpen({
        value: true,
        position: "top",
        alertSeverity: "error",
        message: "This account does not have access."
      });
      return;
    }

    refetch();
    if (isSaveId) {
      localStorage.setItem("savedEmail", email);
    } else {
      localStorage.removeItem("savedEmail");
    }
  };
  const handleIsSaveId = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsSaveId(event.target.checked);
  };
  useEffect(() => {
    const email = localStorage.getItem("savedEmail");
    if (Boolean(email)) {
      setIsSaveId(true);
      setValue("email", email ? email : "");
    }
  }, [setValue]);

  return (
    <>
      <Title title="Login" />
      <Box
        sx={{
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          px: 2
        }}
      >
        <Paper
          sx={{
            maxWidth: 500,
            width: "100%",
            py: 10,
            px: 8,
            border: "1px solid #E4E4E7",
            borderRadius: 2
          }}
          elevation={0}
        >
          <Stack sx={{ mb: 5 }} alignItems="center">
            <Box
              component="img"
              src={optatumLogo}
              alt="optatum logo"
              sx={{ width: "60%" }}
            />
          </Stack>
          <form onSubmit={handleSubmit(handleLoginForm)}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  label="Email"
                  fullWidth
                  required
                  {...register("email", { required: true })}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Password"
                  fullWidth
                  required
                  type={isHide ? "password" : "text"}
                  autoComplete="on"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={handleHideButton}>
                          {isHide ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                  {...register("password", { required: true })}
                />
              </Grid>
            </Grid>
            <FormControlLabel
              label="Save login ID"
              control={
                <Checkbox checked={isSaveId} onChange={handleIsSaveId} />
              }
            />
            <Box
              sx={{
                mt: 5
              }}
            >
              <LoadingButton
                icon={<LoginIcon />}
                fullWidth={true}
                size="large"
                loading={isRSAFeching || isLoginLoading}
              >
                Login
              </LoadingButton>
            </Box>
            {/* 비밀번호 찾기 */}
            {/* <Stack direction="row" justifyContent="flex-end" mt={2}>
              <Button
                sx={{
                  color: "black",
                  "&.MuiButtonBase-root:hover": {
                    bgcolor: "transparent"
                  },
                  "&:hover": { fontWeight: "600" }
                }}
                onClick={() => handleTempPassword()}
              >
                Forgot password?
              </Button>
            </Stack> */}
          </form>
        </Paper>
      </Box>
    </>
  );
};

export default LeyutaeLogin;
