import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";

const FinancialTransaction = () => {
  return (
    <>
      <Typography component="h3" sx={{ fontWeight: "bold", mb: 3 }}>
        특정 금융거래정보의 보고 및 이용 등에 관한 법률에 따른 개인정보
        수집∙이용 내역
      </Typography>
      <Typography sx={{ mb: 3 }}>
        「특정 금융거래정보의 보고 및 이용 등에 관한 법률」
        제5조의2(고객확인의무) 및 시행령 제10조의5(실소유자 확인)에 따라
        실소유자 정보 작성 및 자료 제출에 대한 본인확인 용도로만 활용됩니다.
      </Typography>

      <Table
        sx={{
          backgroundColor: "white",
          mb: 3
        }}
      >
        <TableHead>
          <TableRow
            sx={{
              backgroundColor: "#d8d8d8",
              "&:hover": {
                backgroundColor: "#d8d8d8"
              }
            }}
          >
            <TableCell sx={{ borderRight: "1px solid rgba(224, 224, 224, 1)" }}>
              항목
            </TableCell>
            <TableCell sx={{ borderRight: "1px solid rgba(224, 224, 224, 1)" }}>
              목적
            </TableCell>
            <TableCell>보유 및 이용기간</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell sx={{ borderRight: "1px solid rgba(224, 224, 224, 1)" }}>
              생년월일, 주민번호
            </TableCell>
            <TableCell sx={{ borderRight: "1px solid rgba(224, 224, 224, 1)" }}>
              실소유자 고객확인
            </TableCell>
            <TableCell sx={{ borderRight: "1px solid rgba(224, 224, 224, 1)" }}>
              금융거래관계 종료일로부터 5년
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <Typography component="h3" sx={{ fontWeight: "bold" }}>
        고객사”는 ”회사”의 개인정보 수집·이용 동의를 거부할 수 있습니다. 단,
        동의를 거부하는 경우 서비스신청이 정상적으로 완료될 수 없음을
        알려드립니다.
      </Typography>

      <style>{`
        .ol {
          counter-reset: list;
        }
        .ol > li {
          list-style: none;
        }
        .ol > li:before {
          content: counter(list) ") ";
          counter-increment: list;
        }
      `}</style>
    </>
  );
};
export default FinancialTransaction;
