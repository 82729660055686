import Paper from "@mui/material/Paper";
import { useQuery } from "@tanstack/react-query";
import { addMonths, format, isSameMonth, parse, subMonths } from "date-fns";
import { useEffect, useState } from "react";
import { getDailyStatistics, getHolidays } from "../../api/Dashboard";
import CalendarBody from "./CalendarBody";
import CalendarHeader from "./CalendarHeader";
import type {
  CalendarCellData,
  CalendarCellDataWithCheckList,
  Holiday
} from "../../api/Dashboard/types";
import CalendarDetailModal from "./CalendarDetailModal";
import useRenewal from "../../hooks/useRenewal";

interface CalendarProps {
  setCurrentMonthEvent: (date: Date) => void;
}

const Calendar = ({ setCurrentMonthEvent }: CalendarProps) => {
  const { isRenewalTokenLoading, mutateRenewalToken } = useRenewal();
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [cellDatas, setCellDatas] = useState<CalendarCellDataWithCheckList[]>(
    []
  );

  const goToPrevMonth = () => {
    setCurrentMonth(subMonths(currentMonth, 1));
    setCurrentMonthEvent(subMonths(currentMonth, 1));
  };

  const goToNextMonth = () => {
    setCurrentMonth(addMonths(currentMonth, 1));
    setCurrentMonthEvent(addMonths(currentMonth, 1));
  };

  const goToCurrentMonth = () => {
    setCurrentMonth(new Date());
    setCurrentMonthEvent(new Date());
  };

  // 데이터 조회 API
  const { data: cellData, isLoading } = useQuery<CalendarCellData[]>(
    ["/dashboard/dailyStatistics", currentMonth],
    () => getDailyStatistics(currentMonth),
    {
      keepPreviousData: true,
      onSuccess: () => {
        if (isRenewalTokenLoading) return;
        mutateRenewalToken();
      }
    }
  );

  // 휴일 여부
  const { data: holidayData, isLoading: isLoadingForHoliday } = useQuery<
    Holiday | Holiday[]
  >(["/api/holidays", currentMonth], () => getHolidays(currentMonth), {
    keepPreviousData: true
  });

  useEffect(() => {
    let holidays: string[] = [];
    if (Array.isArray(holidayData)) {
      holidays = holidayData.map((holiday) => {
        const parsedDate = parse(holiday.locdate, "yyyyMMdd", new Date());
        return format(parsedDate, "yyyy-MM-dd");
      });
    } else {
      if (holidayData) {
        const parsedDate = parse(holidayData.locdate, "yyyyMMdd", new Date());
        holidays.push(format(parsedDate, "yyyy-MM-dd"));
      }
    }

    // 합치기
    if (cellData) {
      const cellDatas = cellData.map((cell) => {
        // 휴일 유무
        const isHoliday = holidays.includes(cell.referenceDate);

        // 지급 완료 유무
        const isYesterday =
          cell.referenceDate < format(new Date(), "yyyy-MM-dd");
        const hasDepositAmount = cell.depositAmount > 0;
        const isComplete = isYesterday || hasDepositAmount;

        const cellDate = parse(cell.referenceDate, "yyyy-MM-dd", new Date());

        // 이번달 인지 아닌지
        const isCurrentMonth = isSameMonth(cellDate, currentMonth);
        return {
          ...cell,
          isHoliday,
          isComplete,
          isCurrentMonth
        };
      });
      setCellDatas(cellDatas);
    }
  }, [cellData, holidayData, currentMonth]);

  return (
    <Paper
      sx={{
        p: 3,
        border: "1px solid #F2F3F5",
        borderRadius: 3,
        overflow: "hidden"
      }}
    >
      <CalendarHeader
        currentMonth={currentMonth}
        goToPrevMonth={goToPrevMonth}
        goToNextMonth={goToNextMonth}
        goToCurrentMonth={goToCurrentMonth}
      />
      <CalendarBody
        isLoading={isLoading && isLoadingForHoliday}
        data={cellDatas}
      />
      <CalendarDetailModal />
    </Paper>
  );
};

export default Calendar;
