import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import Checkbox from "@mui/material/Checkbox";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import React from "react";
import { useRecoilState } from "recoil";
import { PaymentsDetailState } from "../../recoil/payments/atom";
import { PurchasesDetailState } from "../../recoil/purchases/atom";
import SettlementsState from "../../recoil/settlements/atom";

interface TableType {
  id: number | string;
  // rows: Object[] | undefined;
  rows: any;
  columns: Object[];
  selectedValues?: any[];
  setRowSelection?: (selectedData: any) => void;
  redlineKey?: string | undefined;
  redlineValue?: string[] | undefined;
  totalData?: any;
  sortingTarget?: string;
  sortDirection?: string;
}

const MuiTable = ({
  id,
  rows = [],
  columns,
  selectedValues = [],
  setRowSelection,
  redlineKey = undefined,
  redlineValue = undefined,
  totalData = [],
  sortingTarget = undefined,
  sortDirection = undefined
}: TableType) => {
  // ===================================================================================================================
  // 리코일 스테이트 : 정렬 기준을 업데이트 하기 위함
  // ===================================================================================================================
  const [paymentsDetail, setPaymentsDetail] =
    useRecoilState(PaymentsDetailState);
  const [purchasesDetail, setPurchasesDetail] =
    useRecoilState(PurchasesDetailState);
  const [settlementData, setSettlementData] = useRecoilState(SettlementsState);

  const numSelected = selectedValues.length;
  const rowCount = rows.length;

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!setRowSelection) return;
    if (event.target.checked) {
      const newSelected = [...rows];
      setRowSelection(newSelected);
      return;
    }
    setRowSelection([]);
  };

  const handleSelectClick = (event: React.MouseEvent<unknown>, row: any) => {
    if (!setRowSelection) return;
    const selectedIndex = selectedValues.findIndex((s) => s[id] === row[id]);
    let newSelected: any[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedValues, row);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedValues.slice(1));
    } else if (selectedIndex === selectedValues.length - 1) {
      newSelected = newSelected.concat(selectedValues.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedValues.slice(0, selectedIndex),
        selectedValues.slice(selectedIndex + 1)
      );
    }
    setRowSelection(newSelected);
  };
  const isSelected = (value: number | string) => {
    const index = selectedValues.findIndex((arr) => arr[id] === value);
    return index !== -1;
  };

  // ===================================================================================================================
  // 거래일자 정렬 : 현재 정렬 열과 방향을 업데이트
  // ===================================================================================================================
  const handleSortClick = (column: keyof any) => {
    // 현재 정렬 열과 클릭한 열이 같으면 정렬 방향을 반전시킨다
    const newSortDirection =
      sortingTarget === column
        ? sortDirection === "ASC"
          ? "DESC"
          : "ASC"
        : "ASC";

    // 리코일 스테이트 업데이트
    setPaymentsDetail({
      ...paymentsDetail,
      sort: newSortDirection
    });
    setPurchasesDetail({
      ...purchasesDetail,
      sort: newSortDirection
    });
    setSettlementData({
      ...settlementData,
      sort: newSortDirection
    });
  };

  // ===================================================================================================================
  // 현재 정렬 방향에 따라 아이콘 표시
  // ===================================================================================================================
  const getSortIcon = (columnId: any) => {
    return sortingTarget === columnId ? (
      sortDirection === "ASC" ? (
        <ArrowUpwardIcon
          fontSize="small"
          sx={{
            "&:hover": {
              cursor: "pointer",
              backgroundColor: "#f2f2f2",
              borderRadius: "50%"
            }
          }}
        />
      ) : (
        <ArrowDownwardIcon
          fontSize="small"
          sx={{
            "&:hover": {
              cursor: "pointer",
              backgroundColor: "#f2f2f2",
              borderRadius: "50%"
            }
          }}
        />
      )
    ) : null;
  };

  return (
    <TableContainer sx={{ maxHeight: 550, minHeight: 550 }}>
      <Table
        stickyHeader
        sx={{ ...((!rows || rows.length === 0) && { height: 550 }) }}
      >
        <TableHead>
          <TableRow>
            {setRowSelection ? (
              <TableCell padding="checkbox" sx={{ background: "white" }}>
                <Checkbox
                  color="primary"
                  indeterminate={numSelected > 0 && numSelected < rowCount}
                  checked={rowCount > 0 && numSelected === rowCount}
                  onChange={handleSelectAllClick}
                />
              </TableCell>
            ) : null}
            {columns.map((column: any) => (
              <TableCell
                variant="head"
                key={column.id}
                sx={{ background: "white", whiteSpace: "nowrap" }}
                align="center"
                onClick={() => handleSortClick(column.id)}
              >
                {column.label}
                {getSortIcon(column.id)}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows && rows.length > 0 ? (
            <>
              {rows.map((row: any) => {
                const isItemSelected = isSelected(row[id]);
                return (
                  <TableRow
                    hover
                    key={`Row_${row[id]}`}
                    onClick={
                      setRowSelection
                        ? (event) => handleSelectClick(event, row)
                        : undefined
                    }
                    selected={setRowSelection ? isItemSelected : false}
                  >
                    {setRowSelection ? (
                      <TableCell padding="checkbox">
                        <Checkbox color="primary" checked={isItemSelected} />
                      </TableCell>
                    ) : null}
                    {columns.map((column: any) => {
                      const cellValue = row[column.id];
                      return (
                        <TableCell
                          key={`Row_${row[id]}_${column.id}`}
                          align="center"
                          sx={{
                            whiteSpace: "nowrap",
                            ...(redlineKey &&
                              redlineValue?.includes(row[redlineKey]) && {
                                color: "error.main"
                              })
                          }}
                        >
                          {cellValue}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}

              {/* 상세 조회 테이블 하단에 총합계 row추가 */}
              {totalData && rows && rows.length > 0 ? (
                <TableRow>
                  {setRowSelection ? (
                    <TableCell
                      sx={{
                        backgroundColor: "#f2f2f2",
                        position: "sticky",
                        bottom: 0,
                        zIndex: 1,
                        whiteSpace: "nowrap"
                      }}
                    >
                      합계
                    </TableCell>
                  ) : null}
                  {columns.map((column: any) => {
                    const cellValue = totalData[column.id];
                    return (
                      <TableCell
                        key={`Row_${totalData[id]}_${column.id}`}
                        align="center"
                        sx={{
                          backgroundColor: "#f2f2f2",
                          position: "sticky",
                          bottom: 0,
                          zIndex: 1,
                          whiteSpace: "nowrap"
                        }}
                      >
                        {column.isTotalData ? cellValue : null}
                      </TableCell>
                    );
                  })}
                </TableRow>
              ) : null}
            </>
          ) : (
            <TableRow>
              <TableCell
                colSpan={setRowSelection ? columns.length + 1 : columns.length}
              >
                <Typography align="center">No data</Typography>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default MuiTable;
