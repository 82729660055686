import CloseIcon from "@mui/icons-material/Close";
import { Link, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useForm } from "react-hook-form";
import { useSetRecoilState } from "recoil";
import { postMerchantModify } from "../../api/Merchants";
import isModalOpenAtom from "../../recoil/isModalOpen";
import { getMerchentIsModify } from "../../api/ContractManagement";
import { engValidate, numberValidate } from "../../libs/input-validation";

interface Props {
  merchantData: any;
  merchantId: any;
  closeModal?: () => void;
}

interface FormInputs {
  fax: string;
  mallName: string;
  mallNameEn: string;
  mallUrl: string;
  phone: string;
}

const MerchantModifyModal = ({
  merchantData,
  merchantId,
  closeModal
}: Props) => {
  // ===============================================================================================
  // 리코일 스테이트
  // ===============================================================================================
  const setIsModalOpen = useSetRecoilState(isModalOpenAtom);

  // ===============================================================================================
  // 리액트 쿼리
  // ===============================================================================================
  const { mutate } = useMutation(postMerchantModify, {
    onSuccess: () => {
      setIsModalOpen({
        value: true,
        position: "top",
        alertSeverity: "success",
        message: "가맹 정보 수정 요청이 완료되었습니다."
      });
    },
    onError: (error: any) => {
      setIsModalOpen({
        value: true,
        position: "top",
        alertSeverity: "error",
        message: error?.response?.data?.message
      });
    }
  });

  // 가맹점 정보 수정 신청 상태 유무 확인
  const { data } = useQuery([`/merchants/modify/check/${merchantId}`], () =>
    getMerchentIsModify(merchantId)
  );

  // ===============================================================================================
  // 리액트 훅 폼
  // ===============================================================================================
  const {
    register,
    getValues,
    formState: { isDirty, isValid }
  } = useForm<FormInputs>({
    defaultValues: {
      fax: "",
      mallName: "",
      mallNameEn: "",
      mallUrl: "",
      phone: ""
    }
  });

  const onSubmit = (formData: FormInputs) => {
    const params = {
      ...formData,
      merchantId: merchantId
    };
    mutate(params);
  };
  // getValues()로 폼 데이터 가져오고 onSubmit 호출
  const handleModifyButtonClick = () => {
    onSubmit(getValues());
    closeModal?.();
  };

  return (
    <>
      {/* 이미 신청한 정보가 있는 경우의 모달창 */}
      {data?.content ? (
        <Dialog open={true} fullWidth maxWidth="sm">
          <DialogTitle> 가맹점 정보 수정 요청</DialogTitle>
          <IconButton
            onClick={closeModal}
            sx={{
              position: "absolute",
              right: 8,
              top: 12
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent>
            <Typography mb={4}>
              이미 제출된 정보가 있으며, 가맹점 정보를 업데이트하려면 정보 수정
              관리 페이지를 통해 요청해주시기 바랍니다.
            </Typography>
            <Link href="/merchant-modify" underline="none">
              <Button variant="contained" fullWidth sx={{ color: "white" }}>
                정보 수정 관리 페이지로 이동하기
              </Button>
            </Link>
          </DialogContent>
        </Dialog>
      ) : (
        <Dialog open={true} fullWidth maxWidth="md">
          {/* 신청한 정보가 없는 경우의 모달창 */}
          <DialogTitle> 가맹점 정보 수정 요청</DialogTitle>
          <IconButton
            onClick={closeModal}
            sx={{
              position: "absolute",
              right: 8,
              top: 12
            }}
          >
            <CloseIcon />
          </IconButton>

          <DialogContent>
            <form>
              <Stack spacing={3}>
                <Stack direction={"row"} spacing={3}>
                  <TextField
                    id="input-mallName"
                    label="가맹점명"
                    fullWidth
                    size="small"
                    {...register("mallName")}
                  />
                  {merchantData?.content.merchantInformation?.businessType ===
                  "법인" ? (
                    <TextField
                      id="input-mallNameEn"
                      label="가맹점 영문명"
                      fullWidth
                      size="small"
                      {...register("mallNameEn")}
                      onInput={engValidate}
                    />
                  ) : null}
                  <TextField
                    id="input-phone"
                    label="가맹점 전화 번호"
                    fullWidth
                    size="small"
                    {...register("phone")}
                    onInput={numberValidate}
                    placeholder="&#39;-&#39; 없이 숫자만 입력해 주세요."
                  />
                </Stack>
                <Stack direction={"row"} spacing={3}>
                  <TextField
                    id="input-fax"
                    label="가맹점 팩스 번호"
                    fullWidth
                    size="small"
                    {...register("fax")}
                    onInput={numberValidate}
                    placeholder="&#39;-&#39; 없이 숫자만 입력해 주세요."
                  />
                  <TextField
                    id="input-mallUrl"
                    label="가맹점 URL"
                    fullWidth
                    size="small"
                    {...register("mallUrl")}
                  />
                </Stack>
                <Typography variant="subtitle2" color={"error"}>
                  * 최소한 하나의 필드를 수정해 주십시오.
                  <br />* 수정 내용에 따라 정보 업데이트가 수락 또는 거절될 수
                  있습니다.
                </Typography>
              </Stack>
              <Stack>
                <Button
                  variant="contained"
                  sx={{ color: "white", mt: 3 }}
                  fullWidth
                  onClick={handleModifyButtonClick}
                  disabled={!isDirty || !isValid}
                >
                  정보 수정 요청
                </Button>
              </Stack>
            </form>
          </DialogContent>
        </Dialog>
      )}
    </>
  );
};
export default MerchantModifyModal;
