import React from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";

const Privacy = () => {
  return (
    <Container
      maxWidth="md"
      sx={{
        py: 3,
        backgroundColor: "white",
        minHeight: "100vh"
      }}
    >
      <Typography variant="h5" component="h3" fontWeight="bold" mb={3}>
        개인정보 수집 및 이용 동의
      </Typography>
      <Typography variant="body2">
        <b>(주)옵타움플랫폼(이하 회사라 함)</b>은 "신용정보의 이용 및 보호에
        관한 법률", "전자금융거래법", "전자상거래 등에서의 소비자보호에 관한
        법률", "정보통신망 이용촉진 및 정보보호 등에 관한 법률" 및 "개인정보
        보호법" 등 관련 법령을 준수하여, 이용자 권익 보호에 최선을 다하고
        있습니다.
      </Typography>
      <Typography
        variant="subtitle1"
        component="h4"
        fontWeight="bold"
        mt={7}
        mb={3}
      >
        1. 개인정보의 수집·이용 목적
      </Typography>
      <Box component="ul">
        <Typography variant="body2" component="li" gutterBottom={true}>
          홈페이지 서비스 이용을 위한 사용자 식별 및 가맹점 정보 접근권한 관리
        </Typography>
        <Typography variant="body2" component="li" gutterBottom={true}>
          홈페이지 이용에 대한 문의 및 불만사항 접수, 회신
        </Typography>
        <Typography variant="body2" component="li" gutterBottom={true}>
          홈페이지 이용빈도 파악 및 통계 활용
        </Typography>
      </Box>
      <Typography variant="body2">
        ※ 회사는 개인정보를 위의 목적으로 처리하며, 동의받은 목적 이외의 용도로
        처리하지 않습니다.
      </Typography>
      <Typography
        variant="subtitle1"
        component="h4"
        fontWeight="bold"
        mt={7}
        mb={3}
      >
        2. 수집·이용하는 개인정보 항목
      </Typography>
      <Typography variant="body2">
        성명, 이메일주소, 비밀번호, 휴대폰번호
      </Typography>
      <Typography
        variant="subtitle1"
        component="h4"
        fontWeight="bold"
        mt={7}
        mb={3}
      >
        3. 개인정보의 보유 및 이용기간
      </Typography>
      <Typography variant="body2" mb={3}>
        회원 탈퇴(계정 삭제) 시까지
      </Typography>
      <Typography variant="body2" mb={3}>
        원칙적으로 개인정보의 수집·이용 목적이 달성되면 지체 없이 파기합니다.
        단, 관련 법령의 규정 또는 회사 내부 방침에 의하여 보존할 필요가 있는
        경우 아래에서 정한 기간 동안 개인정보를 보유합니다
      </Typography>
      <Table>
        <TableHead>
          <TableRow
            sx={{
              backgroundColor: "#f2f4f6"
            }}
          >
            <TableCell>구분</TableCell>
            <TableCell>보존 근거</TableCell>
            <TableCell>보존 기간</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>소비자의 불만 또는 분쟁처리에 관한 기록</TableCell>
            <TableCell>전자상거래 등에서의 소비자보호에 관한 법률</TableCell>
            <TableCell>3 년</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>웹사이트 방문에 관한 기록</TableCell>
            <TableCell>통신비밀보호법에 관한 법률</TableCell>
            <TableCell>3 개월</TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <Typography
        variant="subtitle1"
        component="h4"
        fontWeight="bold"
        mt={7}
        mb={3}
      >
        4. 이용자는 회사의 개인정보 수집·이용 동의를 거부할 권리가 있습니다.
        다만, 개인정보의 수집·이용 동의를 거부할 경우 홈페이지의 회원가입 및
        이용이 제한될 수 있음을 알려드립니다.
      </Typography>
    </Container>
  );
};

export default Privacy;
