import RestartAltIcon from "@mui/icons-material/RestartAlt";
import SearchIcon from "@mui/icons-material/Search";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Pagination from "@mui/material/Pagination";
import Paper from "@mui/material/Paper";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { getMerchants } from "../../api/Merchants";
import { AdminMerchantsParams } from "../../api/Merchants/types";
import AdminLayout from "../../components/Layout/Admin/Layout";
import LoadingButton from "../../components/LoadingButton";
import MerchantsDetailModal from "../../components/Merchants/MerchantsDetailModal";
import Title from "../../components/Title";
import useRenewal from "../../hooks/useRenewal";
import MenuItem from "@mui/material/MenuItem";
import { queryClient } from "../..";
import { getBusinessCategories } from "../../api/BusinessCategory";
import { getMerchantNames } from "../../api/v2/Payments";
import Autocomplete from "@mui/material/Autocomplete";

interface Columns {
  id: string;
  label: string;
}

const columns: Columns[] = [
  { id: "mallId", label: "가맹점ID" },
  { id: "mallName", label: "가맹점 명" },
  { id: "businessName", label: "사업자 등록증 상호명" },
  { id: "ownerName", label: "가맹점 대표자 명" },
  { id: "phone", label: "가맹점 대표 번호" },
  { id: "createAt", label: "가맹등록일" },
  { id: "startDate", label: "계약 시작일" },
  { id: "endDate", label: "계약 종료일" },
  { id: "mccType", label: "결제 업종" },
  { id: "registrant", label: "등록자" },
  { id: "status", label: "심사 상태" },
  { id: "contractExtensionStatus", label: "계약 상태" }
];

interface FormInputs {
  mallId: string;
  mallName: string;
  startDate: string;
  endDate: string;
  contractExtensionStatus: string;
  registrationNumber: string;
  registrant?: "CLIENT" | "ADMIN" | string;
  mccType?:
    | "일반"
    | "학원"
    | "병원"
    | "문화"
    | "비영리기부금"
    | "비영리강의료"
    | "정기"
    | string;
}

const ContractManagement = () => {
  const { isRenewalTokenLoading, mutateRenewalToken } = useRenewal();
  // ===================================================================================================================
  // 스테이트
  // ===================================================================================================================
  // params 스테이트
  const [params, setParams] = useState<AdminMerchantsParams>({
    mallId: "",
    mallName: "",
    status: "계약승인",
    contractExtensionStatus: "계약",
    page: 0,
    size: 10
  });

  // 선택된 row 데이터 스테이트
  const [selectedData, setSelectedData] = useState<any>({
    setIsModalOpen: false,
    merchantId: "",
    mallId: "",
    mallName: "",
    ownerName: "",
    phone: "",
    createdAt: "",
    startDate: "",
    endDate: "",
    status: "전체"
  });

  // ===================================================================================================================
  // 리액트 훅 폼
  // ===================================================================================================================
  const { register, handleSubmit, reset, control } = useForm<FormInputs>({
    defaultValues: {
      ...params
    }
  });

  // row 선택
  const selectRow = (row: any) => {
    setSelectedData({ ...row, setIsModalOpen: true });
  };

  // 모달창 닫기
  const closeModal = () => {
    setSelectedData({
      setIsModalOpen: false,
      merchantId: "",
      mallId: "",
      mallName: "",
      ownerName: "",
      phone: "",
      createdAt: "",
      startDate: "",
      endDate: "",
      status: "전체"
    });
  };

  // ===================================================================================================================
  // 리액트 쿼리
  // ===================================================================================================================
  // "계약 승인"된 사용자 리스트 조회
  const { data, isLoading } = useQuery(
    ["/merchants", params],
    () => getMerchants(params),
    {
      onSuccess: () => {
        if (isRenewalTokenLoading) return;
        mutateRenewalToken();
        queryClient.invalidateQueries(selectedData.merchantId);
      }
    }
  );

  /*********************************************************************************************************************
   * 업종 조회
   ********************************************************************************************************************/
  const { data: businessCategories } = useQuery(
    ["/businessCategories"],
    getBusinessCategories,
    {}
  );

  // 현재 운영중인 가맹점 목록 조회
  const { data: getMerchantsNameData } = useQuery(["/merchants/names"], () =>
    getMerchantNames("")
  );

  // ===================================================================================================================
  // 페이징 처리
  // ===================================================================================================================
  const totalPages = data?.pageable?.totalPages
    ? data?.pageable?.totalPages
    : 0;
  const changePage = (event: React.ChangeEvent<unknown>, page: number) => {
    setParams((prev: any) => ({ ...prev, page: page - 1 }));
  };

  // ===================================================================================================================
  // 가맹점 신청 현황 조회 및 초기화
  // ===================================================================================================================
  // 조회
  const handleForm = (formData: FormInputs) => {
    if (isLoading) return;
    setParams((prevValue: any) => ({
      ...prevValue,
      mallName: formData.mallName,
      mallId: formData.mallId,
      registrant: formData.registrant === "전체" ? "" : formData.registrant,
      mccType: formData.mccType === "전체" ? "" : formData.mccType,
      contractExtensionStatus:
        formData.contractExtensionStatus === "전체"
          ? ""
          : formData.contractExtensionStatus
    }));
  };

  // 초기화
  const handleReset = () => {
    reset();
  };

  return (
    <>
      <Title title="계약 관리" />
      <AdminLayout>
        <Paper
          elevation={0}
          sx={{
            p: 3,
            border: "1px solid #F2F3F5",
            borderRadius: 3,
            mb: 3
          }}
        >
          <form onSubmit={handleSubmit(handleForm)}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={2}>
                <TextField
                  id="mallId"
                  label="가맹점 ID"
                  fullWidth
                  size="small"
                  {...register("mallId")}
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <Autocomplete
                  id="input-mallName"
                  options={getMerchantsNameData?.content ?? []}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      id="mallName"
                      label="가맹점명"
                      inputProps={{
                        ...params.inputProps
                      }}
                      fullWidth
                      size="small"
                      {...register("mallName")}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <Controller
                  name="registrant"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      select
                      label="등록자"
                      size="small"
                      fullWidth
                      onChange={onChange}
                      value={value || "전체"}
                    >
                      <MenuItem value={"전체"}>전체</MenuItem>
                      <MenuItem value={"ADMIN"}>ADMIN</MenuItem>
                      <MenuItem value={"CLIENT"}>CLIENT</MenuItem>
                    </TextField>
                  )}
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <Controller
                  name="mccType"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      select
                      label="업종"
                      size="small"
                      fullWidth
                      onChange={onChange}
                      value={value || "전체"}
                    >
                      <MenuItem value={"전체"}>전체</MenuItem>
                      {businessCategories?.content.map((category) => (
                        <MenuItem value={category} key={category}>
                          {category}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <Controller
                  name="contractExtensionStatus"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      select
                      label="계약 상태"
                      size="small"
                      fullWidth
                      onChange={onChange}
                      value={value || "계약"}
                    >
                      <MenuItem value={"전체"}>전체</MenuItem>
                      <MenuItem value={"계약"}>계약</MenuItem>
                      <MenuItem value={"해지"}>해지</MenuItem>
                      <MenuItem value={"연장"}>연장</MenuItem>
                      <MenuItem value={"대기"}>대기</MenuItem>
                      <MenuItem value={"계약종료"}>계약종료</MenuItem>
                    </TextField>
                  )}
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <Stack
                  direction="row"
                  justifyContent="flex-end"
                  alignItems="center"
                  spacing={1}
                >
                  <LoadingButton
                    icon={<SearchIcon />}
                    size="medium"
                    fullWidth={false}
                    loading={false}
                  >
                    조회
                  </LoadingButton>
                  <Button
                    variant="outlined"
                    endIcon={<RestartAltIcon />}
                    onClick={handleReset}
                  >
                    조건 초기화
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </form>
        </Paper>

        <Typography variant="body1" sx={{ mb: 1, fontWeight: "bold" }}>
          계약 관리 목록
        </Typography>
        <Paper
          sx={{
            border: "1px solid #F2F3F5",
            borderRadius: 3,
            overflow: "hidden"
          }}
        >
          <TableContainer>
            <Table
              stickyHeader
              sx={{
                ...((!data || data?.content?.length === 0) && {
                  height: 550
                })
              }}
            >
              <TableHead>
                <TableRow>
                  {columns.map((column: any) => (
                    <TableCell
                      variant="head"
                      key={column.id}
                      align="center"
                      sx={{ background: "#FBFBFB" }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.content.length === 0 && !isLoading ? (
                  <TableRow>
                    <TableCell sx={{ height: 500 }} colSpan={columns.length}>
                      <Typography align="center">
                        조회된 데이터가 없습니다.
                      </Typography>
                    </TableCell>
                  </TableRow>
                ) : (
                  <>
                    {!data &&
                      isLoading &&
                      [...Array(10)].map((_, index) => (
                        <TableRow
                          key={index}
                          hover={true}
                          sx={{ cursor: "pointer" }}
                        >
                          {columns.map((column) => (
                            <TableCell
                              component="th"
                              align="center"
                              key={column.id}
                            >
                              <Skeleton
                                variant={"rounded"}
                                width={"100%"}
                                height={30}
                              ></Skeleton>
                            </TableCell>
                          ))}
                        </TableRow>
                      ))}
                    {data &&
                      data.content.length > 0 &&
                      data.content.map((row) => (
                        <TableRow
                          key={row.merchantId}
                          hover={true}
                          onClick={() => selectRow(row)}
                          sx={{ cursor: "pointer" }}
                        >
                          <TableCell component="th" align="center">
                            {row.mallId}
                          </TableCell>
                          <TableCell align="center">{row.mallName}</TableCell>
                          <TableCell align="center">
                            {row.businessName}
                          </TableCell>
                          <TableCell align="center">{row.ownerName}</TableCell>
                          <TableCell align="center">{row.phone}</TableCell>
                          <TableCell align="center">{row.createdAt}</TableCell>
                          <TableCell align="center">{row.startDate}</TableCell>
                          <TableCell align="center">{row.endDate}</TableCell>
                          <TableCell align="center">{row.mccType}</TableCell>
                          <TableCell align="center">{row.registrant}</TableCell>
                          <TableCell align="center">{row.status}</TableCell>
                          <TableCell align="center">
                            {row.contractExtensionStatus}
                          </TableCell>
                        </TableRow>
                      ))}
                    {data && (
                      <MerchantsDetailModal
                        isOpen={selectedData.setIsModalOpen}
                        closeModal={closeModal}
                        merchantId={selectedData.merchantId}
                      />
                    )}
                  </>
                )}
              </TableBody>
            </Table>
          </TableContainer>

          <Box my={2}>
            <Pagination
              count={totalPages}
              variant="outlined"
              shape="rounded"
              page={params.page + 1}
              onChange={changePage}
              sx={{ display: "flex", justifyContent: "center" }}
            ></Pagination>
          </Box>
        </Paper>
      </AdminLayout>
    </>
  );
};
export default ContractManagement;
