import { instanceWithAuth } from "../../Instance";
import { CommonApi } from "../../type";
import {
  CompleteSettlementParams,
  ConfirmSettlementParams,
  SettlementDetailData,
  SettlementNoteParams,
  SettlementStatementData,
  SettlementStatementParams,
  SettlementsData,
  SettlementsDetailParams,
  SettlementsParams,
  SettlementsTotalData
} from "./types";

// =================================================================================================
// 정산내역 - version 2
// =================================================================================================
/**
 * 정산내역 데이터 조회
 */
export const getSettlements = async (params: SettlementsParams) => {
  const url = "/settlements";
  const { data } = await instanceWithAuth.get<CommonApi<SettlementsData[]>>(
    url,
    { params }
  );
  return data;
};

/**
 * 정산내역 총 합계 데이터 조회
 */
export const getSettlementsTotal = async (params: SettlementsParams) => {
  const url = "/settlement/total";
  const { data } = await instanceWithAuth.get<CommonApi<SettlementsTotalData>>(
    url,
    { params }
  );
  return data;
};

/**
 * 정산지급
 */
export const completeSettlements = async (params: CompleteSettlementParams) => {
  const url = "/settlement/complete";
  const { data } = await instanceWithAuth.post<CommonApi<boolean>>(url, params);
  return data;
};

/**
 * 정산 개별 내역 데이터 조회
 */
export const getSettlementsDetail = async (params: SettlementsDetailParams) => {
  const url = "/settlements/detail";
  const { data } = await instanceWithAuth.get<CommonApi<SettlementDetailData[]>>(url, { params });
  return data;
};

/**
 * 정산 개별 내역 엑셀 데이터 조회
 */
export const getSettlementsDetailExcel = async (params: any) => {
  const url = "/settlements/detail/excel";
  const { data } = await instanceWithAuth.get<CommonApi<any>>(url, {
    params
  });
  return data;
};

/**
 * 정산내역서 데이터 조회
 */
export const getSettlementStatement = async (
  params: SettlementStatementParams
) => {
  const url = "/settlement/statement";
  const { data } = await instanceWithAuth.get<
    CommonApi<SettlementStatementData>
  >(url, {
    params
  });
  return data;
};

/**
 * 정산 확정
 */
export const confirmSettlement = async (params: ConfirmSettlementParams) => {
  const url = "/settlement/confirm";
  const { data } = await instanceWithAuth.post<CommonApi<boolean>>(url, params);
  return data;
};

/**
 * 정산내역서 비고 업데이트
 */
export const updateStatementNote = async (params: SettlementNoteParams) => {
  const url = "/statement";
  const { data } = await instanceWithAuth.put<CommonApi<boolean>>(url, params);
  return data;
};
