import SaveIcon from "@mui/icons-material/Save";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { useRecoilState, useSetRecoilState } from "recoil";
import { getCardTotal } from "../../api/v2/Payments";
import { CardTotalData } from "../../api/v2/Payments/types";
import useRenewal from "../../hooks/useRenewal";
import excelDownload from "../../libs/excel-download";
import isModalOpenAtom from "../../recoil/isModalOpen";
import PaymentsState from "../../recoil/payments/atom";
import LoadingButton from "../LoadingButton";
import CardsDetailModal from "./CardsDetailModal";
import Skeleton from "@mui/material/Skeleton";
import Box from "@mui/material/Box";

const columns = [
  {
    id: "mallId",
    label: "가맹점 ID"
  },
  {
    id: "mallName",
    label: "가맹점명"
  },
  {
    id: "datePeriod",
    label: "거래일자"
  },
  {
    id: "cardCompany",
    label: "카드"
  },
  {
    id: "transactionTotal",
    label: "거래합계 (건수)"
  },
  {
    id: "completeTotal",
    label: "승인합계 (건수)"
  },
  {
    id: "cancelTotal",
    label: "취소합계 (건수)"
  }
];

const CardsTotal = () => {
  const { isRenewalTokenLoading, mutateRenewalToken } = useRenewal();
  // ===================================================================================================================
  // 리코일 스테이트
  // ===================================================================================================================
  const setIsModalOpen = useSetRecoilState(isModalOpenAtom);
  const [paymentsTotal, setPaymentsTotal] = useRecoilState(PaymentsState);
  const [selectedCardCompany, setSelectedCardCompany] = useState("");

  // ===================================================================================================================
  // 스테이트
  // ===================================================================================================================
  const [isOpen, setIsOpen] = useState(false);

  // ===================================================================================================================
  // 리액트 쿼리 - 카드사별 총합계 조회
  // ===================================================================================================================
  const { isLoading, data } = useQuery(
    ["/payments/total/card-company", paymentsTotal],
    () => getCardTotal(paymentsTotal),
    {
      onSuccess: () => {
        if (!isRenewalTokenLoading) {
          mutateRenewalToken();
        }
      }
    }
  );

  // ===================================================================================================================
  // 카드사별 상세 조회 모달창
  // ===================================================================================================================
  const handleCardClick = (cardCompany: string) => {
    if (data?.content) {
      setSelectedCardCompany(cardCompany);
      setIsOpen(true);
    }
  };

  // 테이블 마지막 row는 클릭 x
  const handleTableRowClick = (cardCompany: string, index: number) => {
    if (data?.content && index !== data.content.length - 1) {
      handleCardClick(cardCompany);
    }
  };

  // ===================================================================================================================
  // 카드사별 합계 - 엑셀 다운로드
  // ===================================================================================================================
  const handleCardTotalExcel = () => {
    const content = data?.content;
    if (!content || content.length === 1) {
      setIsModalOpen({
        value: true,
        position: "top",
        alertSeverity: "error",
        message: "데이터가 없습니다."
      });
      return;
    } else {
      const list = content.map((d: CardTotalData) => ({
        가맹점Id: d.mallId,
        가맹점명: d.mallName,
        거래일자: d.datePeriod,
        카드: d.cardCompany,
        "거래합계(건수)": d.transactionTotal,
        "승인합계(건수)": d.completeTotal,
        "취소합계(건수)": d.cancelTotal
      }));
      excelDownload({
        list,
        sheetName:
          paymentsTotal.mallName === ""
            ? "거래내역 카드사별 합계"
            : `거래내역 카드사별 합계_${paymentsTotal.mallName}`,
        fileName:
          paymentsTotal.mallName === ""
            ? "거래내역 카드사별 합계.xlsx"
            : `거래내역 카드사별 합계_${paymentsTotal.mallName}.xlsx`
      });
      setIsModalOpen({
        value: true,
        position: "top",
        alertSeverity: "success",
        message: "다운로드를 시작합니다."
      });
    }
  };

  return (
    <Box>
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"end"}
        mb={1}
      >
        <Typography sx={{ fontWeight: "bold" }}>카드사별 합계</Typography>
        <LoadingButton
          icon={<SaveIcon />}
          size="medium"
          fullWidth={false}
          color="secondary"
          variant="outlined"
          loading={false}
          handleClick={handleCardTotalExcel}
        >
          EXCEL 다운로드
        </LoadingButton>
      </Stack>
      <Paper
        sx={{
          border: "1px solid #F2F3F5",
          borderRadius: 3,
          overflow: "hidden"
        }}
      >
        <TableContainer>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow sx={{ backgroundColor: "#FBFBFB" }}>
                {columns.map((column) => (
                  <TableCell
                    variant="head"
                    align="center"
                    key={column.id}
                    sx={{ borderTop: "none" }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.content.length === 0 ? (
                <>
                  <TableRow>
                    <TableCell colSpan={columns.length}>
                      <Typography align="center">
                        조회된 데이터가 없습니다.
                      </Typography>
                    </TableCell>
                  </TableRow>
                </>
              ) : (
                <>
                  {!data &&
                    isLoading &&
                    [...Array(5)].map((_, index) => (
                      <TableRow key={index}>
                        {columns.map((column) => (
                          <TableCell align="center" key={column.id}>
                            <Skeleton variant="rectangular" height={25} />
                          </TableCell>
                        ))}
                      </TableRow>
                    ))}
                  {data?.content &&
                    !isLoading &&
                    data.content.map((i: CardTotalData, index: number) => (
                      <TableRow
                        key={index}
                        sx={{
                          backgroundColor:
                            index === data.content.length - 1 ? "#FBFBFB" : "",
                          cursor:
                            index !== data.content.length - 1
                              ? "pointer"
                              : "default",
                          borderBottom:
                            index === data.content.length - 1 ? "none" : ""
                        }}
                        onClick={() =>
                          handleTableRowClick(i.cardCompany, index)
                        }
                      >
                        {columns.map((column) => (
                          <TableCell
                            key={column.id}
                            align="center"
                            sx={{
                              borderBottom:
                                index === data.content.length - 1 ? "none" : ""
                            }}
                          >
                            {i[column.id as keyof CardTotalData]}
                          </TableCell>
                        ))}
                      </TableRow>
                    ))}
                </>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      <CardsDetailModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        cardCompany={selectedCardCompany}
      />
    </Box>
  );
};
export default CardsTotal;
