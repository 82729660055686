import { CardCompanies } from "../CardInstallments/types";
import { instanceWithAuth } from "../Instance";
import { CommonApi } from "../type";
import { CardBinForms, CardBinParams, CardBinResponse } from "./types";

/*********************************************************************************************************************
 * 카드사 목록 조회 API
 ********************************************************************************************************************/
export const getCardCompanies = async () => {
  const { data } = await instanceWithAuth.get<CommonApi<CardCompanies>>(
    "/cardBin/cardCompanies"
  );
  return data;
};

/*********************************************************************************************************************
 * 카드 BIN 목록 조회 API
 ********************************************************************************************************************/
export const getCardBins = async (params: CardBinParams) => {
  const url = "/cardBins";
  const { data } = await instanceWithAuth.get<CommonApi<CardBinResponse[]>>(
    url,
    {
      params
    }
  );
  return data;
};

/*********************************************************************************************************************
 * 카드 BIN 등록
 ********************************************************************************************************************/
export const postCardBin = async (params: CardBinForms) => {
  const url = "/cardBin/insert";
  const { data } = await instanceWithAuth.post<CommonApi<boolean>>(url, params);
  return data;
};

/*********************************************************************************************************************
 * 카드 BIN 수정
 ********************************************************************************************************************/
export const putCardBin = async (params: CardBinForms) => {
  const url = "/cardBin/update";
  const { data } = await instanceWithAuth.put<CommonApi<boolean>>(url, params);
  return data;
};

/*********************************************************************************************************************
 * 카드 BIN 삭제
 ********************************************************************************************************************/
export const deleteCardBins = async (binCodes: string[]) => {
  const url = "/cardBin/delete";
  const { data } = await instanceWithAuth.delete<CommonApi<boolean>>(url, {
    params: { binCodes: binCodes.join(",") }
  });
  return data;
};
