import { useMutation } from "@tanstack/react-query";
import { useSetRecoilState } from "recoil";
import LoginInfoAtom from "../recoil/loginInfo";
import TimerAtom, { TimeDefault } from "../recoil/timer";
import { renewalToken } from "../api/User";

/**
 * 가장 메인이 되는 요소에 hook에서 반환된 mutate를 실행
 */

const useRenewal = () => {
  const setLoginInfo = useSetRecoilState(LoginInfoAtom);
  const setTimeLeft = useSetRecoilState(TimerAtom);

  const { isLoading, mutate } = useMutation(renewalToken, {
    onSuccess: (data) => {
      setTimeLeft(TimeDefault);
      setLoginInfo((prevValue) => ({
        ...prevValue,
        accessToken: data.content.accessToken,
        createdTime: data.content.createdTime
      }));
      sessionStorage.setItem("loginInfo", JSON.stringify(data.content));
    },
    onError: () => {
      sessionStorage.removeItem("loginInfo");
      window.location.href = "/login";
    }
  });

  return { isRenewalTokenLoading: isLoading, mutateRenewalToken: mutate };
};

export default useRenewal;
