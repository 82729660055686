import React, { useEffect } from "react";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Title from "../components/Title";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import otp_google from "../images/otp_google.png";
import LockIcon from "@mui/icons-material/Lock";
import TextField from "@mui/material/TextField";
import LoadingButton from "../components/LoadingButton";
import { useLocation, useNavigate, Navigate } from "react-router-dom";
import { useSetRecoilState, useRecoilValue, useRecoilState } from "recoil";
import isModalOpenAtom from "../recoil/isModalOpen";
import MiddleLogoutBtn from "../components/MiddleLogoutBtn.tsx";
import { useForm } from "react-hook-form";
import { useMutation } from "@tanstack/react-query";
import LoginInfoAtom, {
  withToken,
  loginInfoDefault
} from "../recoil/loginInfo";
import type { OtpForm } from "./OTP";
import { verifyOtp } from "../api/Otp";
import { verifyJwt } from "../libs/verify-jwt";

interface IOTPMiniTitle {
  colorText: string;
  text: string;
  handleClick?: React.MouseEventHandler<HTMLSpanElement> | undefined;
}

const OTPMiniTitle = ({
  colorText,
  text,
  handleClick = undefined
}: IOTPMiniTitle) => {
  return (
    <Typography
      variant="subtitle1"
      align="left"
      onClick={handleClick}
      sx={{
        cursor: handleClick ? "pointer" : "default",
        "&:hover": {
          textDecorationLine: handleClick ? "underline" : null,
          textDecorationColor: "#073B77"
        }
      }}
    >
      ㆍ
      <Box
        component="span"
        sx={{
          color: "primary.main",
          fontWeight: "bold"
        }}
      >
        {colorText}
      </Box>{" "}
      {text}
    </Typography>
  );
};

const OTPSetting = () => {
  const { state }: any = useLocation();
  const navigate = useNavigate();
  const setIsModalOpen = useSetRecoilState(isModalOpenAtom);
  const token = useRecoilValue(withToken);
  const [loginInfo, setLoginInfo] = useRecoilState(LoginInfoAtom);
  const { mutate, isLoading } = useMutation(verifyOtp);
  const { register, handleSubmit, reset } = useForm<OtpForm>();
  const handleOtpSubmit = (data: OtpForm) => {
    if (isLoading) return;
    mutate(
      { ...data, token },
      {
        onSuccess: async (data) => {
          try {
            const {
              payload: { otpYn }
            } = await verifyJwt(data.content.newToken);
            if (!otpYn) {
              setLoginInfo(loginInfoDefault);
              return navigate("/login", {
                replace: true
              });
            }

            const newLoginInfo = {
              ...loginInfo,
              accessToken: data.content.newToken
            };
            setLoginInfo(newLoginInfo);
            sessionStorage.setItem("loginInfo", JSON.stringify(newLoginInfo));
          } catch (e) {
            setLoginInfo(loginInfoDefault);
            return navigate("/login", {
              replace: true
            });
          }

          if (loginInfo.loginUser.passwordUpdateFlag === "Y") {
            return navigate("/password", {
              replace: true
            });
          }
          if (loginInfo.loginUser.role === "ADMIN") {
            navigate("/admin", {
              replace: true
            });
          } else {
            navigate("/", {
              replace: true
            });
          }
        },
        onError: (error: any) => {
          setIsModalOpen({
            value: true,
            position: "top",
            alertSeverity: "error",
            message: error?.response?.data?.message
          });
          reset();
        }
      }
    );
  };
  const copySecret = async () => {
    const copyToText = state?.secret || "";
    if ("clipboard" in navigator) {
      await navigator.clipboard.writeText(copyToText);
    } else {
      document.execCommand("copy", true, copyToText);
    }
    setIsModalOpen({
      value: true,
      position: "top",
      alertSeverity: "success",
      message: "복사되었습니다."
    });
  };
  useEffect(() => {
    if (!token) {
      return navigate("/login", { replace: true });
    }
  }, [token, navigate]);
  if (!state) {
    return <Navigate to="../not-found" replace={true} />;
  }
  return (
    <>
      <Title title="OTP 설정" />
      <MiddleLogoutBtn />
      <Box
        sx={{
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          px: 2,
          paddingY: 2
        }}
      >
        <Paper
          sx={{
            maxWidth: 500,
            width: "100%",
            py: 10,
            px: 8
          }}
        >
          <Typography
            variant="h3"
            component="h2"
            align="center"
            sx={{
              marginBottom: 5
            }}
          >
            OTP 설정
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h6" align="left">
                1. OTP 설치하기
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Stack alignItems="flex-start">
                <OTPMiniTitle colorText="안드로이드" text="설치하기" />
                <Box
                  component="img"
                  marginX="auto"
                  sx={{
                    height: 120,
                    width: 120
                  }}
                  src={otp_google}
                />
              </Stack>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Stack alignItems="flex-start">
                <OTPMiniTitle colorText="앱스토어" text="설치하기" />
                <Box
                  component="img"
                  marginX="auto"
                  sx={{
                    height: 120,
                    width: 120
                  }}
                  src={otp_google}
                />
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6" align="left">
                2. OTP 등록하기
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Stack alignItems="flex-start">
                <OTPMiniTitle colorText="OTP" text="QR 코드" />
                <Box
                  component="img"
                  marginX="auto"
                  sx={{
                    height: 120,
                    width: 120
                  }}
                  src={state?.qr || ""}
                />
              </Stack>
            </Grid>
            <Grid item xs={12} sm={6}>
              <OTPMiniTitle
                colorText="OTP secret"
                text="복사하기"
                handleClick={copySecret}
              />
            </Grid>
            <Grid item xs={12}>
              <form onSubmit={handleSubmit(handleOtpSubmit)}>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="center"
                  spacing={2}
                >
                  <TextField
                    label="OTP Code"
                    size="small"
                    required
                    {...register("otpCode", { required: true })}
                  />
                  <LoadingButton
                    icon={<LockIcon />}
                    size="medium"
                    fullWidth={false}
                    loading={isLoading}
                  >
                    입력
                  </LoadingButton>
                </Stack>
              </form>
            </Grid>
          </Grid>
        </Paper>
      </Box>
    </>
  );
};

export default OTPSetting;
