import { atom } from "recoil";

const TimeDefault = 1000 * 60 * 20; // 20분
const TimeExtentionDefault = 1000 * 60 * 2; // 2분

const timer = atom({
  key: "timer",
  default: TimeDefault
});

export { timer as default, TimeDefault, TimeExtentionDefault };
