import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import LogoImage from "../../images/logo_contract.png";
import Divider from "@mui/material/Divider";
import Fonts from "./Fonts";
import grey from "@mui/material/colors/grey";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import CheckIcon from "@mui/icons-material/Check";
import { MerchantsDetail } from "../../api/Merchants/types";
import { addCommas } from "../../libs/thousands-commas";

interface PageProps {
  data?: MerchantsDetail;
}

const Page8 = ({ data }: PageProps) => {
  return (
    <Fonts>
      <Box
        sx={{
          width: "210mm",
          height: "297mm",
          backgroundColor: "white",
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <Card
          sx={{
            width: "195mm",
            height: "282mm",
            backgroundColor: "white",
            border: "1px solid black"
          }}
        >
          {/* 머릿글 ======================================================== */}
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            sx={{
              width: "100%",
              height: "15mm"
            }}
          >
            <Stack
              justifyContent={"center"}
              alignItems={"center"}
              sx={{
                width: "120px"
              }}
            >
              {/* <Box component={"img"} src={LogoImage}></Box> */}
            </Stack>
            <Stack
              justifyContent={"center"}
              alignItems={"center"}
              sx={{ fontSize: "18px", fontWeight: 700, color: "#a29fb0" }}
            >
              [부칙 2] 서비스 신청서
            </Stack>
            <Stack
              justifyContent={"center"}
              alignItems={"center"}
              sx={{
                width: "35mm",
                color: "white",
                fontSize: "16px"
              }}
            >
              <Box component={"img"} src={LogoImage}></Box>
            </Stack>
          </Stack>
          <Divider
            sx={{ border: "none", borderTop: "4px solid #a29fb0", mx: 1 }}
          ></Divider>

          {/* 본문 ========================================================== */}
          <Box
            component={"ol"}
            start={3}
            sx={{
              height: "254mm",
              mt: 1,
              px: 1
            }}
          >
            <Typography sx={{ fontWeight: 700, fontSize: "8pt" }}>
              2. 등록비/운영비(금액: 부가세 포함)
            </Typography>
            <Typography>
              ※ 기 입금된 등록비 및 운영비는 이용계정이 부여된 이후에는 환불되지
              않습니다.
            </Typography>
            <Typography>
              ※ 사업자번호의 변경이나 개인사업자의 대표자 변경시 신규계약으로
              진행되어, 등록금 및 운영비가 추가 발생됩니다.
            </Typography>
            <Typography>
              ※ “고객사”는 매년 연회비를 재계약 전월까지 납부하여야 하며, 미납할
              경우 “고객사”의 정산대금에서 차감될 수 있습니다.
            </Typography>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell
                    align="center"
                    sx={{
                      border: "1px dotted black",
                      borderBottom: "none",
                      p: 0.5,
                      backgroundColor: "#d9d9d9"
                    }}
                  >
                    항목
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      border: "1px dotted black",
                      borderBottom: "none",
                      p: 0.5,
                      backgroundColor: "#d9d9d9"
                    }}
                  >
                    금액
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      border: "1px dotted black",
                      borderBottom: "none",
                      p: 0.5,
                      backgroundColor: "#d9d9d9"
                    }}
                  >
                    납부방식
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      border: "1px dotted black",
                      borderBottom: "none",
                      p: 0.5,
                      backgroundColor: "#d9d9d9"
                    }}
                  >
                    비고
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell
                    align="center"
                    sx={{
                      border: "1px dotted black",
                      borderBottom: "none",
                      p: 0.5
                    }}
                  >
                    신규등록비
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      border: "1px dotted black",
                      borderBottom: "none",
                      p: 0.5,
                      backgroundColor: "#d9d9d9"
                    }}
                  >
                    {addCommas(data?.merchantInformation.registrationFee ?? 0)}
                    원
                  </TableCell>
                  <TableCell
                    sx={{
                      border: "1px dotted black",
                      borderBottom: "none",
                      p: 0.5
                    }}
                  >
                    <Stack direction={"row"} spacing={1}>
                      <Stack direction={"row"}>
                        <Typography>[</Typography>
                        {data?.merchantInformation.registrationFeeType ===
                        "납부" ? (
                          <>
                            <CheckIcon sx={{ backgroundColor: "#d9d9d9" }} />
                            <Typography>] 납부</Typography>
                          </>
                        ) : (
                          <Typography sx={{ ml: 1 }}>] 납부</Typography>
                        )}
                      </Stack>
                      <Stack direction={"row"}>
                        <Typography>[</Typography>
                        {data?.merchantInformation.registrationFeeType ===
                        "정산" ? (
                          <>
                            <CheckIcon sx={{ backgroundColor: "#d9d9d9" }} />{" "}
                            <Typography>] 정산 차감</Typography>
                          </>
                        ) : (
                          <Typography sx={{ ml: 1 }}>] 정산 차감</Typography>
                        )}
                      </Stack>
                      <Stack direction={"row"}>
                        <Typography>[</Typography>
                        {data?.merchantInformation.registrationFeeType ===
                        "면제" ? (
                          <>
                            <CheckIcon sx={{ backgroundColor: "#d9d9d9" }} />{" "}
                            <Typography>] 면제</Typography>
                          </>
                        ) : (
                          <Typography sx={{ ml: 1 }}>] 면제</Typography>
                        )}
                      </Stack>
                    </Stack>
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      border: "1px dotted black",
                      borderBottom: "none",
                      p: 0.5
                    }}
                  >
                    최초 1회 납부
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    align="center"
                    sx={{
                      border: "1px dotted black",
                      p: 0.5
                    }}
                  >
                    연회비
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      border: "1px dotted black",
                      p: 0.5,
                      backgroundColor: "#d9d9d9"
                    }}
                  >
                    {addCommas(data?.annualFee[0].price ?? 0)}
                    원/년
                  </TableCell>
                  <TableCell
                    sx={{
                      border: "1px dotted black",
                      p: 0.5
                    }}
                  >
                    <Stack direction={"row"} spacing={1}>
                      <Stack direction={"row"}>
                        <Typography>[</Typography>
                        {data?.annualFee[0].annualType === "납부" ? (
                          <>
                            <CheckIcon sx={{ backgroundColor: "#d9d9d9" }} />{" "}
                            <Typography>] 납부</Typography>
                          </>
                        ) : (
                          <Typography sx={{ ml: 1 }}>] 납부</Typography>
                        )}
                      </Stack>
                      <Stack direction={"row"}>
                        <Typography>[</Typography>
                        {data?.annualFee[0].annualType === "정산" ? (
                          <>
                            <CheckIcon sx={{ backgroundColor: "#d9d9d9" }} />{" "}
                            <Typography>] 정산 차감</Typography>
                          </>
                        ) : (
                          <Typography sx={{ ml: 1 }}>] 정산 차감</Typography>
                        )}
                      </Stack>
                      <Stack direction={"row"}>
                        <Typography>[</Typography>
                        {data?.annualFee[0].annualType === "면제" ? (
                          <>
                            <CheckIcon sx={{ backgroundColor: "#d9d9d9" }} />{" "}
                            <Typography>] 면제</Typography>
                          </>
                        ) : (
                          <Typography sx={{ ml: 1 }}>] 면제</Typography>
                        )}
                      </Stack>
                    </Stack>
                  </TableCell>
                  <TableCell
                    sx={{
                      border: "1px dotted black",
                      p: 0.5
                    }}
                  ></TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <Typography sx={{ fontWeight: 700, mt: 1, fontSize: "8pt" }}>
              3. 담보현황
            </Typography>
            <Typography>
              ※ “고객사”는 매년 보증보험 혹은 이에 상응하는 금액을 재계약
              전월까지 납부하여야 하며, 미납할 경우 “고객사”의 정산대금에서
              차감될 수 있습니다.
            </Typography>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell
                    align="center"
                    sx={{
                      border: "1px dotted black",
                      borderBottom: "none",
                      p: 0.5,
                      backgroundColor: "#d9d9d9"
                    }}
                  >
                    항목
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      border: "1px dotted black",
                      borderBottom: "none",
                      p: 0.5,
                      backgroundColor: "#d9d9d9"
                    }}
                  >
                    금액
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      border: "1px dotted black",
                      borderBottom: "none",
                      p: 0.5,
                      backgroundColor: "#d9d9d9"
                    }}
                  >
                    제공방법
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      border: "1px dotted black",
                      borderBottom: "none",
                      p: 0.5,
                      backgroundColor: "#d9d9d9"
                    }}
                  >
                    비고
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell
                    align="center"
                    sx={{
                      border: "1px dotted black",
                      borderBottom: "none",
                      p: 0.5
                    }}
                  >
                    보증보험
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      border: "1px dotted black",
                      borderBottom: "none",
                      p: 0.5,
                      backgroundColor: "#d9d9d9"
                    }}
                  >
                    {addCommas(data?.deposit ?? 0)} 원
                  </TableCell>
                  <TableCell
                    sx={{
                      border: "1px dotted black",
                      borderBottom: "none",
                      p: 0.5
                    }}
                  >
                    서울보증보험 가입을 통해 제출
                  </TableCell>
                  <TableCell
                    sx={{
                      border: "1px dotted black",
                      borderBottom: "none",
                      p: 0.5
                    }}
                  ></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    align="center"
                    sx={{
                      border: "1px dotted black",
                      p: 0.5
                    }}
                  >
                    대체비
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      border: "1px dotted black",
                      p: 0.5
                    }}
                  ></TableCell>
                  <TableCell
                    sx={{
                      border: "1px dotted black",
                      p: 0.5
                    }}
                  ></TableCell>
                  <TableCell
                    sx={{
                      border: "1px dotted black",
                      p: 0.5
                    }}
                  ></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    align="center"
                    sx={{
                      border: "1px dotted black",
                      p: 0.5
                    }}
                  >
                    기타
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      border: "1px dotted black",
                      p: 0.5
                    }}
                  ></TableCell>
                  <TableCell
                    sx={{
                      border: "1px dotted black",
                      p: 0.5
                    }}
                  ></TableCell>
                  <TableCell
                    sx={{
                      border: "1px dotted black",
                      p: 0.5
                    }}
                  ></TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <Typography sx={{ fontWeight: 700, mt: 1, fontSize: "8pt" }}>
              4. 결제수단별 요청내용
            </Typography>
            <Typography>※ 결제수단의 범위, 정산주기 및 정산일</Typography>
            <Typography>
              ① 정산일은 영업일을 기준으로 한다. 즉 공휴일, 금융권 비영업일 또는
              정산일이 해당월에 없는 경우에는 도래하는 첫 영업일에 처리하는
              것으로 하며, 일일 정산의 정산일수 계산시 일수는 영업일(금융권)을
              기준으로 합니다.
            </Typography>
            <Typography>
              ② 결제수단별 수수료와 결제주기에 대하여 아래 각 호의 요건에
              해당하는 경우, “회사”는 서면 또는 기타(유선 또는 이메일)의 통지로
              수수료를 증감할 수 있습니다.
            </Typography>
            <Box component={"ol"}>
              <Typography component={"li"} sx={{ ml: -2 }}>
                카드사 등 결제기관과 “회사”간의 결제수단별 수수료와 결제주기가
                변경된 경우 또는 관련법령, 금융사정 및 기타 결제사정의 변화 등의
                이유가 있는 경우
              </Typography>
              <Typography component={"li"} sx={{ ml: -2 }}>
                “고객사”사가 운영하는 전자상거래 쇼핑몰 또는 사업장의 월
                승인금액의 증감, 비정상거래의 발생금액 및 건수, 민원발생의
                빈도수에 따라 수수료를 조정할 필요가 있다고 판단되는 경우
              </Typography>
            </Box>
            <Typography sx={{ mt: 1, fontWeight: 700, fontSize: "8pt" }}>
              4-1. 신용카드 부문
            </Typography>
            <Typography sx={{ fontWeight: 700 }}>
              O 카드사 결재수수료
            </Typography>
            <Typography>
              ※ 온라인 상에서 매출 이력이 없는 가맹점의 최초 가입인 경우
              일반구간 수수료가 적용됩니다
            </Typography>
            <Typography>
              ※ 가맹점 구분은 가맹점의 국세청 매출액 신고기준으로
              여신전문금융업법 등 관련법률에 따라 구분됩니다.
            </Typography>
            <Box component={"ul"}>
              <Typography component={"li"} sx={{ ml: -1 }}>
                여신전문금융업감독규정 제25조의5에 따라 영세한
                중소신용카드가맹점(이하 “영중소 가맹점”)에 해당할 경우
                제25조의6조에 따른 우대수수료(이하 “영중소 우대수수료”)가 하기와
                같이 적용되며, 본 규정 혹은 관련법률이 변경될 경우 그에 따라
                변경됩니다.
              </Typography>
              <Typography component={"li"} sx={{ ml: -1 }}>
                “영중소 우대수수료”는 신용카드, 체크카드 결제에 한하여 적용되며,
                매년 반기별로 국세청이 정한 매출액 구간에 따라 반영하고
                변경합니다.
              </Typography>
            </Box>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell
                    align="center"
                    sx={{
                      border: "1px dotted black",
                      borderBottom: "none",
                      p: 0.5,
                      backgroundColor: "#d9d9d9",
                      width: "30mm"
                    }}
                  >
                    등급
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      border: "1px dotted black",
                      borderBottom: "none",
                      p: 0.5,
                      backgroundColor: "#d9d9d9",
                      width: "50mm"
                    }}
                  >
                    연간 매출액
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      border: "1px dotted black",
                      borderBottom: "none",
                      p: 0.5,
                      backgroundColor: "#d9d9d9",
                      width: "50mm"
                    }}
                  >
                    수수료
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      border: "1px dotted black",
                      borderBottom: "none",
                      p: 0.5,
                      backgroundColor: "#d9d9d9",
                      width: "50mm"
                    }}
                  >
                    비고
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell
                    align="center"
                    sx={{
                      border: "1px dotted black",
                      borderBottom: "none",
                      p: 0.5,
                      pl: 1
                    }}
                  >
                    일반
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      border: "1px dotted black",
                      borderBottom: "none",
                      p: 0.5
                    }}
                  >
                    30 억 초과
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      border: "1px dotted black",
                      borderBottom: "none",
                      p: 0.5
                    }}
                  >
                    3.5% 이하
                  </TableCell>
                  <TableCell
                    sx={{
                      border: "1px dotted black",
                      borderBottom: "none",
                      p: 0.5
                    }}
                  ></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    align="center"
                    sx={{
                      border: "1px dotted black",
                      borderBottom: "none",
                      p: 0.5,
                      pl: 1
                    }}
                  >
                    중소 1
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      border: "1px dotted black",
                      borderBottom: "none",
                      p: 0.5
                    }}
                  >
                    3 억 초과 5 억 이하
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      border: "1px dotted black",
                      borderBottom: "none",
                      p: 0.5
                    }}
                  >
                    2.4% 이하
                  </TableCell>
                  <TableCell
                    sx={{
                      border: "1px dotted black",
                      borderBottom: "none",
                      p: 0.5
                    }}
                  ></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    align="center"
                    sx={{
                      border: "1px dotted black",
                      borderBottom: "none",
                      p: 0.5,
                      pl: 1
                    }}
                  >
                    중소 2
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      border: "1px dotted black",
                      borderBottom: "none",
                      p: 0.5
                    }}
                  >
                    5 억 초과 10 억 이하
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      border: "1px dotted black",
                      borderBottom: "none",
                      p: 0.5
                    }}
                  >
                    2.55% 이하
                  </TableCell>
                  <TableCell
                    sx={{
                      border: "1px dotted black",
                      borderBottom: "none",
                      p: 0.5
                    }}
                  ></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    align="center"
                    sx={{
                      border: "1px dotted black",
                      borderBottom: "none",
                      p: 0.5,
                      pl: 1
                    }}
                  >
                    중소 3
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      border: "1px dotted black",
                      borderBottom: "none",
                      p: 0.5
                    }}
                  >
                    10 억 초과 30 억 이하
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      border: "1px dotted black",
                      borderBottom: "none",
                      p: 0.5
                    }}
                  >
                    3.0% 이하
                  </TableCell>
                  <TableCell
                    sx={{
                      border: "1px dotted black",
                      borderBottom: "none",
                      p: 0.5
                    }}
                  ></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    align="center"
                    sx={{
                      border: "1px dotted black",
                      p: 0.5,
                      pl: 1
                    }}
                  >
                    영세
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      border: "1px dotted black",
                      p: 0.5
                    }}
                  >
                    3 억 이하
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      border: "1px dotted black",
                      p: 0.5
                    }}
                  >
                    1.8% 이하
                  </TableCell>
                  <TableCell
                    sx={{
                      border: "1px dotted black",
                      p: 0.5
                    }}
                  ></TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <Typography sx={{ fontWeight: 700, fontSize: "8pt", mt: 1 }}>
              O 신용카드/체크카드 수수료
            </Typography>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell
                    align="center"
                    sx={{
                      border: "1px dotted black",
                      p: 0.5,
                      backgroundColor: "#d9d9d9",
                      width: "20mm"
                    }}
                  >
                    구분
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      border: "1px dotted black",
                      p: 0.5,
                      backgroundColor: "#d9d9d9",
                      width: "20mm"
                    }}
                  >
                    일반
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      border: "1px dotted black",
                      p: 0.5,
                      backgroundColor: "#d9d9d9",
                      width: "20mm"
                    }}
                  >
                    중소 1
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      border: "1px dotted black",
                      p: 0.5,
                      backgroundColor: "#d9d9d9",
                      width: "20mm"
                    }}
                  >
                    중소 2
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      border: "1px dotted black",
                      p: 0.5,
                      backgroundColor: "#d9d9d9",
                      width: "20mm"
                    }}
                  >
                    중소 3
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      border: "1px dotted black",
                      p: 0.5,
                      backgroundColor: "#d9d9d9",
                      width: "20mm"
                    }}
                  >
                    영세
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      border: "1px dotted black",
                      p: 0.5,
                      backgroundColor: "#d9d9d9"
                    }}
                  >
                    정산주기
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell
                    align="center"
                    sx={{ border: "1px dotted black", p: 0.5 }}
                  >
                    신용카드
                    <br />
                    체크카드
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ border: "1px dotted black", p: 0.5 }}
                  >
                    {data?.merchantFee[0]?.optatumFee
                      ? data?.merchantFee[0]?.optatumFee
                      : ""}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ border: "1px dotted black", p: 0.5 }}
                  >
                    {data?.merchantFee[1]?.optatumFee
                      ? data?.merchantFee[1]?.optatumFee
                      : ""}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ border: "1px dotted black", p: 0.5 }}
                  >
                    {data?.merchantFee[2]?.optatumFee
                      ? data?.merchantFee[2]?.optatumFee
                      : ""}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ border: "1px dotted black", p: 0.5 }}
                  >
                    {data?.merchantFee[3]?.optatumFee
                      ? data?.merchantFee[3]?.optatumFee
                      : ""}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ border: "1px dotted black", p: 0.5 }}
                  >
                    {data?.merchantFee[4]?.optatumFee
                      ? data?.merchantFee[4]?.optatumFee
                      : ""}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ border: "1px dotted black", p: 0.5 }}
                  >
                    주 정산 (일~토 거래, 익주 목요일 정산) <br />
                    (정산일이 공휴일인 경우 익영업일 지급)
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Box>

          {/* 바닥글 ======================================================== */}
          <Divider
            sx={{
              border: "none",
              borderTop: "1px dotted",
              borderColor: grey[700],
              mx: 1
            }}
          ></Divider>
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            sx={{ height: "7mm", color: grey[400], mx: 3, mt: 0 }}
          >
            <Typography>국내 최고의 통합 결제 서비스 OZICPAY</Typography>
            <Typography>페이지 8 / 9</Typography>
          </Stack>
        </Card>
      </Box>
    </Fonts>
  );
};

export default Page8;
