import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import styled from "@mui/material/styles/styled";
import grey from "@mui/material/colors/grey";
import { useRecoilState } from "recoil";
import clientDashboardCalendarCellState from "../../recoil/clientCalendarDetail/atom";
import { addCommas } from "../../libs/thousands-commas";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";

const TitleColumn = styled(TableCell)({
  backgroundColor: grey[200],
  width: "200px",
  textAlign: "right",
  paddingBottom: "8px"
});

const ValueColumn = styled(TableCell)({
  width: "200px",
  textAlign: "right",
  paddingRight: "16px",
  paddingBottom: "8px"
});

const CalendarDetailModal = () => {
  const [selectedCellData, setSelectedCellData] = useRecoilState(
    clientDashboardCalendarCellState
  );
  return (
    <Dialog open={selectedCellData.isOpen} fullWidth maxWidth="xs">
      <DialogTitle>
        {selectedCellData.isOpen && selectedCellData.isComplete
          ? "입금 내역"
          : "매출 내역"}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={() => {
          setSelectedCellData((prev) => ({ ...prev, isOpen: false }));
        }}
        sx={{
          position: "absolute",
          right: 8,
          top: 12
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent dividers={false}>
        {selectedCellData.isOpen && (
          <>
            <Table>
              <TableBody>
                <TableRow>
                  <TitleColumn>결제건</TitleColumn>
                  <ValueColumn>
                    {addCommas(selectedCellData.numOfPayment)}
                  </ValueColumn>
                </TableRow>
                <TableRow>
                  <TitleColumn>취소건</TitleColumn>
                  <ValueColumn>
                    {addCommas(selectedCellData.numOfPaymentCancel)}
                  </ValueColumn>
                </TableRow>
                <TableRow>
                  <TitleColumn>결제금액</TitleColumn>
                  <ValueColumn>
                    {addCommas(selectedCellData.sumOfPayment)}
                  </ValueColumn>
                </TableRow>
                <TableRow>
                  <TitleColumn>취소금액</TitleColumn>
                  <ValueColumn>
                    {addCommas(selectedCellData.sumOfPaymentCancel)}
                  </ValueColumn>
                </TableRow>
                {selectedCellData?.isComplete ? (
                  <>
                    <TableRow>
                      <TitleColumn>PG수수료</TitleColumn>
                      <ValueColumn>
                        {addCommas(selectedCellData.pgFee)}
                      </ValueColumn>
                    </TableRow>
                    <TableRow>
                      <TitleColumn>당일 정산액</TitleColumn>
                      <ValueColumn>
                        {addCommas(selectedCellData.todayDepositAmount)}
                      </ValueColumn>
                    </TableRow>
                    <TableRow>
                      <TitleColumn>입금 완료 정산액</TitleColumn>
                      <ValueColumn>
                        {addCommas(selectedCellData.depositAmount)}
                      </ValueColumn>
                    </TableRow>
                  </>
                ) : (
                  <>
                    <TableRow>
                      <TitleColumn>결제 취소금액</TitleColumn>
                      <ValueColumn>
                        {addCommas(selectedCellData.totalPayment)}
                      </ValueColumn>
                    </TableRow>
                  </>
                )}
              </TableBody>
            </Table>
          </>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default CalendarDetailModal;
