// 인풋 숫자만 받기
export const numberValidate = (e: any) => {
  e.target.value = e.target.value.replace(/[^0-9]/g, "");
};

// 인풋 영문만 받기
export const engValidate = (e: any) => {
  e.target.value = e.target.value.replace(/[^a-zA-Z\s]+$/, "");
};

// ===================================================================================================================
// 인풋 숫자 + 백단위 콤마 입력 받기
// 1. 정규 표현식을 사용하여 숫자가 아닌 모든 문자를 제거한다.
// 2. 입력값의 길이가 1보다 크고 0으로 시작하면 첫 번째 숫자인 0을 제거한다. (단일 0은 허용)
// 3. 정규 표현식을 사용하여 천 단위로 콤마를 추가한다.
// 4. 변환된 값을 입력 필드에 다시 설정한다.
// ===================================================================================================================
export const numberCommaValidate = (e: any) => {
  let value = e.target.value.replace(/[^0-9]/g, "");
  if (value.length > 1 && value.startsWith("0")) {
    value = value.substring(1);
  }
  value = value.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  e.target.value = value;
};
