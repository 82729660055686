import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import LogoImage from "../../images/logo_contract.png";
import { grey, red } from "@mui/material/colors";
import Divider from "@mui/material/Divider";
import Fonts from "./Fonts";
import { MerchantsDetail } from "../../api/Merchants/types";
import { getTodayKor } from "../../libs/get-today";

interface PageProps {
  data?: MerchantsDetail;
}

const Page3 = ({ data }: PageProps) => {
  return (
    <Fonts>
      <Box
        sx={{
          width: "210mm",
          height: "297mm",
          backgroundColor: "white",
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <Card
          sx={{
            width: "195mm",
            height: "282mm",
            backgroundColor: "white",
            border: "1px solid black"
          }}
        >
          <Stack direction={"column"}>
            {/* 머릿글 */}
            <Stack
              direction={"row"}
              justifyContent={"space-between"}
              sx={{
                width: "100%",
                height: "15mm"
              }}
            >
              <Stack
                justifyContent={"center"}
                alignItems={"center"}
                sx={{
                  width: "120px"
                }}
              >
                {/* <Box component={"img"} src={LogoImage}></Box> */}
              </Stack>
              <Stack
                justifyContent={"center"}
                alignItems={"center"}
                sx={{ fontSize: "22px", fontWeight: 700 }}
              >
                오직페이 서비스 이용 계약서
              </Stack>
              <Stack
                justifyContent={"center"}
                alignItems={"center"}
                sx={{
                  width: "35mm",
                  color: "white",
                  fontSize: "16px"
                }}
              >
                <Box component={"img"} src={LogoImage}></Box>
              </Stack>
            </Stack>
            <Divider sx={{ border: "2px solid #a29fb0", mx: 1 }}></Divider>
            {/* 본문 */}
            <Stack
              direction={"column"}
              sx={{
                height: "260mm",
                pr: 1
              }}
            >
              {/* 본문 다단 */}
              <Stack
                direction={"row"}
                justifyContent={"space-between"}
                sx={{ height: "180mm" }}
              >
                {/* 본문 왼쪽 */}
                <Box
                  sx={{
                    width: "95mm"
                  }}
                >
                  <Box
                    component={"ol"}
                    sx={{ ml: -2.5, mt: 1, mb: 0 }}
                    start={3}
                  >
                    <Typography
                      sx={{
                        fontSize: "7pt"
                      }}
                    >
                      2-1) ”회사”와의 계약해지 시에도 보증보험 증권은 유효하며,
                      “고객사”는 보증보험 증권을 보상기간까지 해지할 수 없다.
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "7pt"
                      }}
                    >
                      2-2) “고객사”가 “회사”를 피보험자로 계약한 보증보험 증권은
                      1년 단위로 계약하되, 보증보험 계약 만기 2주전까지 계약
                      연장을 하여야 하며 연장하지 않을 시, “회사”는 “고객사”에게
                      제공하는 전자결제 서비스를 해지하거나, 지급보류 조치를 할
                      수 있다.
                    </Typography>
                    <Typography
                      component={"li"}
                      sx={{
                        fontSize: "7pt"
                      }}
                    >
                      “회사”는 “고객사”의 판매신용도 및 신용판매실적, 민원접수
                      실적 등을 고려하여 전 1항의 “손실보증금”의 증액 또는
                      새로운 담보를 추가 요구할 수 있으며, “고객사”가 이를
                      이행하지 아니하는 경우 본 계약을 해지할 수 있다.
                    </Typography>
                    <Typography
                      component={"li"}
                      sx={{
                        fontSize: "7pt"
                      }}
                    >
                      “고객사”는 전 1항의 “손실보증금”에 대한 이자 등을
                      “회사”에게 따로 청구하지 아니한다.
                    </Typography>
                    <Typography
                      component={"li"}
                      sx={{
                        fontSize: "7pt"
                      }}
                    >
                      전 1항의 손해는 “고객사”가 영업점 폐쇄, 영업중단, 기타
                      사유로 이용자와의 계약을 이행할 수 없게 되었거나 본 계약을
                      위반하여 “회사”에게 각종 피해를 입힌 경우에 발생한 것으로
                      본다.
                    </Typography>
                    <Typography
                      component={"li"}
                      sx={{
                        fontSize: "7pt"
                      }}
                    >
                      “고객사”가 본 계약에 근거하여 현금 또는 정산대금에 대한
                      “회사”의 지급보류 등의 방식으로 “회사”에게 담보금을 납부한
                      경우 “고객사”는 이를 제3자에게 채권 양도하거나 질권설정 등
                      담보로 제공할 수 없다.
                    </Typography>
                  </Box>
                  <Typography
                    sx={{ fontSize: "8px", fontWeight: 700, mt: 1, ml: 1 }}
                  >
                    제 15 조 (손해배상)
                  </Typography>
                  <Box component={"ol"} sx={{ ml: -2.5, mt: 0, mb: 0 }}>
                    <Typography
                      component={"li"}
                      sx={{
                        fontSize: "7pt"
                      }}
                    >
                      이용자의 이의제기(주문사실 불인정, 금액상이, 물품에 대한
                      불만족으로 인한 반환 요청, 배송사고, 파손 등) 또는
                      주문접수과정에서 “고객사”의 과실로 이용자와 “고객사”간에
                      분쟁이 발생한 경우 전적으로 “고객사”가 해결해야 하며
                      “회사” 또는 이용자의 책임이 아닌 사유로 인하여 발생한
                      손해에 대하여 “고객사”는 “회사” 또는 이용자가 손해배상을
                      통지한 날로부터 7일 이내에 전액을 배상하여야 한다.
                    </Typography>
                    <Typography
                      component={"li"}
                      sx={{
                        fontSize: "7pt"
                      }}
                    >
                      “고객사”가 전 1항에 규정한 “고객사”의 손해배상 의무를
                      이행하지 않을 경우 “회사”는 동 매출을 취소하거나 다음 번
                      청구금액에 손해배상 금액에 대한 변제 및 충당 등 제반조치를
                      취할 수 있다.
                    </Typography>
                    <Typography
                      component={"li"}
                      sx={{
                        fontSize: "7pt"
                      }}
                    >
                      “회사”는 “회사” 또는 이용자의 손해에 대하여 “고객사”가
                      배상하지 못하였을 경우 “손실보증금”과 상계하여 처리 할 수
                      있다.
                    </Typography>
                    <Typography
                      component={"li"}
                      sx={{
                        fontSize: "7pt"
                      }}
                    >
                      본 계약을 이행하는 과정에서 계약위반 또는 법규위반 등
                      귀책사유 있는 당사자는 상대방 또는 제3자에 대한 손해를
                      배상하여야 한다. 단, 천재지변, 전쟁 등의 불가 항력적인
                      사유로 인한 경우에는 그러하지 아니하다.
                    </Typography>
                    <Typography
                      component={"li"}
                      sx={{
                        fontSize: "7pt"
                      }}
                    >
                      양 당사자는 전항의 손해를 자신의 비용으로 해결한 경우,
                      귀책사유 있는 상대방에게 구상할 수 있다.
                    </Typography>
                  </Box>
                  <Typography
                    sx={{ fontSize: "8px", fontWeight: 700, mt: 1, ml: 1 }}
                  >
                    제 16 조 (계약담보의 환원)
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "7pt",
                      ml: 1
                    }}
                  >
                    본 계약이 해지되는 때에는 “고객사”와의 계약기간 동안 발생한
                    신용판매와 관련하여 “회사”에게 손해가 발생하지 아니하고
                    이용자의 이의제기, 신용카드사, 국세청 등 관공서에서 제재가
                    없는 경우에 한하여 6개월 경과 후 “손실보증금”을 “고객사”에게
                    환원할 수 있다. (단, 보증보험증권에 대하여서는 그러하지
                    아니하며, 보증보험증권에 대한 모든 사항은 제14조 2항, 2-1호,
                    2-2호에 의거하여 적용한다.).
                  </Typography>
                  <Typography
                    sx={{ fontSize: "8px", fontWeight: 700, mt: 1, ml: 1 }}
                  >
                    제 17 조 (정보유출 금지)
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "7pt",
                      ml: 1
                    }}
                  >
                    “고객사”와 ”회사”는 전자결제서비스를 제공, 이용하면서
                    획득하게 된 이용자의 정보, 쌍방의 대외비 기술자료, 영업비밀
                    또는 기타 업무상 기밀사항 등을 제3자에게 누설하거나 본
                    계약상의 업무 목적 외로 사용하여서는 아니 되며, 이를 위반할
                    경우
                  </Typography>
                </Box>
                {/* 본문 오른쪽 */}
                <Box
                  sx={{
                    width: "95mm"
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "7pt",
                      ml: 1,
                      mt: 1
                    }}
                  >
                    그로 인하여 발생되는 손해에 대해서는 그 위반 책임이 있는
                    자가 손해배상하고 모든 민, 형사상의 책임을 진다.
                  </Typography>
                  <Typography
                    sx={{ fontSize: "8px", fontWeight: 700, mt: 1, ml: 1 }}
                  >
                    제 18 조 (부 칙)
                  </Typography>
                  <Box component={"ol"} sx={{ ml: -2.5, mt: 0, mb: 0 }}>
                    <Typography
                      component={"li"}
                      sx={{
                        fontSize: "7pt"
                      }}
                    >
                      “고객사”가 “회사”의 전자결제서비스를 이용함에 있어, 본
                      계약상 규정되지 않은 사항에 관하여는 다음 각 호의 관련
                      법령이나 규약 등이 적용되며 각종분쟁은 이를 근거로
                      처리한다.
                      <Typography
                        sx={{
                          fontSize: "7pt"
                        }}
                      >
                        1-1) 여신전문금융업법, 카드사의 가맹점 규약, 선불카드
                        또는 직불카드 관련약관 또는 규약, 은행법, 해당
                        금융기관의 예금거래 기본약관 등 금융거래 관련 법령 및
                        규정
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "7pt"
                        }}
                      >
                        1-2) 전자상거래 등에서의 소비자보호에 관한 법률,
                        독점규제 및 공정거래에 관한 법률, 할부거래에 관한 법률,
                        방문판매 등에 관한 법률 등 소비자 보호 관련법령 및 규정
                      </Typography>
                    </Typography>
                    <Typography
                      component={"li"}
                      sx={{
                        fontSize: "7pt"
                      }}
                    >
                      본 계약 내용에 관한 법률적인 해석에 이견이 있는 경우,
                      “고객사”와 “회사”가 상호 성실히 협의하여 처리하되 협의가
                      이루어지지 않아 법률적인 분쟁이 야기되는 경우에는 “회사”의
                      본점 소재지를 관할하는 법원의 판결에 따른다.
                    </Typography>
                  </Box>
                  <Typography
                    sx={{ fontSize: "8px", fontWeight: 700, mt: 1, ml: 1 }}
                  >
                    제 19 조 (계약 변경 사항 통보 및 손해배상)
                  </Typography>
                  <Box component={"ol"} sx={{ ml: -2.5, mt: 0, mb: 0 }}>
                    <Typography
                      component={"li"}
                      sx={{
                        fontSize: "7pt"
                      }}
                    >
                      “고객사”는 “고객사”가 운영하는 사이트에 관하여 사이트 및
                      대표자의 신상 등에 변동사항 발생시 즉시 “회사”에게
                      통보하여야 하며, 만일 통보 지연으로 인하여 이용자의 민원
                      및 기타 문제가 발생할 경우 “고객사”는 이를 책임지고
                      해결하여야 하며, 이때 "회사"는 "고객사"의 전자결제서비스를
                      해지 할 수 있다. (여기서 통보방법은 서면, fax 또는
                      이메일을 이용한다.)
                    </Typography>
                    <Typography
                      component={"li"}
                      sx={{
                        fontSize: "7pt"
                      }}
                    >
                      “고객사”가 “회사”에게 반드시 통보하여야 할 변동사항은 다음
                      각 호와 같다.
                      <Typography
                        sx={{
                          fontSize: "7pt"
                        }}
                      >
                        1) 대표자의 변경
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "7pt"
                        }}
                      >
                        2) 폐업 및 업종변경
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "7pt"
                        }}
                      >
                        3) 사이트 주소 및 대금결제계좌 변경
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "7pt"
                        }}
                      >
                        4) 판매물품 변경 및 추가사항
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "7pt"
                        }}
                      >
                        5) "고객사"의 이메일 주소 및 연락처의 변경
                      </Typography>
                    </Typography>
                    <Typography
                      component={"li"}
                      sx={{
                        fontSize: "7pt"
                      }}
                    >
                      “회사”의 정책 및 카드사의 정책상 발생하는 각종
                      변동사항(수수료 등)에 대해서는 “회사”는 “고객사”에게 변경
                      15일전까지 이메일 또는 서면(공문 또는 fax)으로 통보하여
                      변경할 수 있다.
                      <Typography
                        sx={{
                          fontSize: "7pt",
                          fontWeight: 700
                        }}
                      >
                        * “고객사”의 정보 변동 사항 발생 시 카드사의 정책에 따라
                        신규 계약으로 진행될 수 있다. 단, 사업자번호 변경 시
                        신규 계약 필수 (제신고 불가)
                      </Typography>
                    </Typography>
                  </Box>
                  <Typography
                    sx={{ fontSize: "8px", fontWeight: 700, mt: 1, ml: 1 }}
                  >
                    제 20 조 (계약의 효력발생)
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "7pt",
                      ml: 1
                    }}
                  >
                    본 계약은 “고객사”와 “회사”가 각각 서명, 날인한 날로부터
                    효력이 발생하며, 전자결제서비스 이용수수료 등의 부과는
                    계약의 효력발생일로부터 개시된다.
                  </Typography>
                  <Typography
                    sx={{ fontSize: "8px", fontWeight: 700, mt: 1, ml: 1 }}
                  >
                    제 21 조 (기타)
                  </Typography>
                  <Box component={"ol"} sx={{ ml: -2.5, mt: 0, mb: 0 }}>
                    <Typography
                      component={"li"}
                      sx={{
                        fontSize: "7pt"
                      }}
                    >
                      본 계약에 추가하여 적용할 약정(특약) 및 조항에 대하여는
                      별도의 특약서를 통하여 “고객사”와 “회사”가 상호 날인 후
                      약정하도록 한다.
                    </Typography>
                    <Typography
                      component={"li"}
                      sx={{
                        fontSize: "7pt"
                      }}
                    >
                      상호, 대표이사, 수수료 등 변경사유로 계약을 갱신할 경우
                      별도의 약정이 없다면 기존 계약상의 모든 권리와 의무는 신
                      계약에서 당연히 승계하는 것으로 한다.
                    </Typography>
                  </Box>
                </Box>
              </Stack>
              <Divider sx={{ border: "1px solid #a29fb0", ml: 1 }}></Divider>
              <Stack
                direction={"column"}
                alignItems={"center"}
                justifyContent={"space-between"}
                sx={{ height: "15mm" }}
              >
                <Typography sx={{ fontSize: "8pt" }}>
                  본 계약을 증명하기 위하여 계약서 2부를 작성하여 상호 기명 날인
                  후 각각 1부씩 보관한다.
                </Typography>
                <Typography
                  sx={{
                    fontSize: "8pt",
                    letterSpacing: 2,
                    backgroundColor: grey[300]
                  }}
                >
                  {getTodayKor()}
                </Typography>
              </Stack>
              <Stack
                direction={"row"}
                justifyContent={"space-around"}
                sx={{ mt: 2, ml: 1 }}
              >
                <Stack
                  direction={"column"}
                  gap={1}
                  sx={{
                    width: "90mm"
                  }}
                >
                  <Typography sx={{ fontSize: "8pt", fontWeight: 700 }}>
                    (고객사: 이용자)
                  </Typography>
                  <Stack direction={"row"}>
                    <Typography sx={{ width: "15mm", fontSize: "8pt" }}>
                      상호명:
                    </Typography>
                    <Typography
                      sx={{
                        px: 1,
                        fontSize: "8pt",
                        fontWeight: 700,
                        backgroundColor: grey[300]
                      }}
                    >
                      {data?.mallName}
                    </Typography>
                  </Stack>
                  <Stack direction={"row"}>
                    <Typography sx={{ width: "15mm", fontSize: "8pt" }}>
                      주소:
                    </Typography>
                    <Typography
                      sx={{
                        width: "75mm",
                        height: "10mm",
                        px: 1,
                        fontSize: "8pt",
                        fontWeight: 700,
                        backgroundColor: grey[300]
                      }}
                    >
                      {data?.merchantInformation.address
                        .split(" | ")
                        .join(", ")}
                    </Typography>
                  </Stack>
                  <Stack direction={"row"}>
                    <Typography sx={{ width: "15mm", fontSize: "8pt" }}>
                      대표(이사):
                    </Typography>
                    <Typography
                      sx={{
                        px: 1,
                        fontSize: "10pt",
                        fontWeight: 700,
                        backgroundColor: grey[300],
                        width: "45mm",
                        textAlign: "center",
                        letterSpacing: 10
                      }}
                    >
                      {data?.merchantOwnerInformation[0].name}
                    </Typography>
                    <Typography
                      sx={{
                        width: "30mm",
                        fontSize: "10pt",
                        fontWeight: 700,
                        textAlign: "center",
                        backgroundColor: grey[300]
                      }}
                    >
                      (인)
                    </Typography>
                  </Stack>
                  <Typography
                    sx={{ fontSize: "8pt", fontWeight: 700, color: grey[500] }}
                  >
                    ※ 개인사업자: 대표자 인감 / 법인사업자: 법인 인감
                    <Typography
                      component="strong"
                      sx={{
                        display: "inline",
                        fontSize: "8pt",
                        fontWeight: 700,
                        ml: 1,
                        color: red[500]
                      }}
                    >
                      날인 필수
                    </Typography>{" "}
                  </Typography>
                </Stack>
                <Stack
                  direction={"column"}
                  gap={1}
                  sx={{
                    width: "80mm"
                  }}
                >
                  <Typography sx={{ fontSize: "8pt", fontWeight: 700 }}>
                    (회사: 제공자)
                  </Typography>
                  <Typography sx={{ fontSize: "8pt" }}>
                    ㈜ 옵타움플랫폼
                  </Typography>
                  <Typography sx={{ fontSize: "8pt", height: "10mm" }}>
                    (46293) 부산광역시 금정구 금정로 61, 옵타움빌딩 3-5층
                  </Typography>
                  <Stack direction={"row"}>
                    <Typography sx={{ width: "15mm", fontSize: "8pt" }}>
                      대표이사
                    </Typography>
                    <Typography
                      sx={{
                        width: "45mm",
                        fontSize: "10pt",
                        fontWeight: 700,
                        textAlign: "center",
                        letterSpacing: 10
                      }}
                    >
                      김병규
                    </Typography>
                    <Typography
                      sx={{
                        width: "30mm",
                        fontSize: "8pt",
                        fontWeight: 700,
                        textAlign: "center"
                      }}
                    >
                      (인)
                    </Typography>
                  </Stack>
                  <Typography sx={{ fontSize: "8pt" }}></Typography>
                </Stack>
              </Stack>
            </Stack>
            {/* ============================== 바닥글 ============================== */}
            <Divider
              sx={{
                border: "none",
                borderTop: "1px dotted",
                borderColor: grey[700],
                mx: 1
              }}
            ></Divider>
            <Stack
              direction={"row"}
              justifyContent={"space-between"}
              sx={{ height: "7mm", color: grey[400], mx: 3, mt: 0 }}
            >
              <Typography sx={{ fontSize: "7pt" }}>
                국내 최고의 통합 결제 서비스 OZICPAY
              </Typography>
              <Typography sx={{ fontSize: "7pt" }}>페이지 3 / 9</Typography>
            </Stack>
          </Stack>
        </Card>
      </Box>
    </Fonts>
  );
};

export default Page3;
