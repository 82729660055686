import React from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";

const Marketing = () => {
  return (
    <Container
      maxWidth="md"
      sx={{
        py: 3,
        backgroundColor: "white",
        minHeight: "100vh"
      }}
    >
      <Typography variant="h5" component="h3" fontWeight="bold" mb={3}>
        마케팅 정보 수신 동의
      </Typography>
      <Typography variant="body2">
        <b>(주)옵타움플랫폼</b>에서 제공하는 새로운 기능의 출시 안내 등 다양한
        가맹점 이벤트, 혜택 정보를 이메일, 문자, 카카오톡 메시지 등으로 받아보실
        수 있습니다. 본 동의는 언제든 취소할 수 있습니다.
      </Typography>
    </Container>
  );
};

export default Marketing;
