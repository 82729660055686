import { instanceWithAuth } from "../Instance";
import { CommonApi } from "../type";
import {
  Charge,
  ChargeRequestParams,
  ChargeSaveParams,
  ChargeSearchParams,
  ChargeSearchParamsWithPaging,
  ChargeSearchResponse
} from "./types";

/**
 * 청구금 목록 조회
 */
export const getCharges = async (params: ChargeRequestParams) => {
  const {
    data: { content }
  } = await instanceWithAuth.get<CommonApi<Charge[]>>(`/charge/find`, {
    params
  });

  return content;
};

/**
 * 청구금 저장
 */
export const postCharges = async (params: ChargeSaveParams[]) => {
  const {
    data: { content }
  } = await instanceWithAuth.post(`/charge/save`, params);

  return content;
};

/**
 * 청구금 페이지 목록 조회
 */
export const getChargeList = async (
  params: ChargeSearchParamsWithPaging
): Promise<CommonApi<ChargeSearchResponse[]>> => {
  const { data } = await instanceWithAuth.get<
    CommonApi<ChargeSearchResponse[]>
  >(`/charge/find/list`, { params });

  return data;
};

/**
 * 청구금 페이지 엑셀 다운로드
 */
export const getChargeListExcel = async (
  params: ChargeSearchParams
): Promise<ChargeSearchResponse[]> => {
  const {
    data: { content }
  } = await instanceWithAuth.get<CommonApi<ChargeSearchResponse[]>>(
    `/charge/find/excel`,
    {
      params
    }
  );

  return content;
};
