import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Skeleton from "@mui/material/Skeleton";
import Typography from "@mui/material/Typography";
import { blue, grey, red, teal } from "@mui/material/colors";
import { format, parse } from "date-fns";
import { addCommas } from "../../libs/thousands-commas";
import type { CalendarCellDataWithCheckList } from "../../api/Dashboard/types";
import { useSetRecoilState } from "recoil";
import clientDashboardCalendarCellState from "../../recoil/clientCalendarDetail/atom";

interface CalendarBodyProps {
  isLoading: boolean;
  data: CalendarCellDataWithCheckList[] | undefined;
}

const CalendarBody = ({ isLoading, data }: CalendarBodyProps) => {
  const days = [
    "일요일",
    "월요일",
    "화요일",
    "수요일",
    "목요일",
    "금요일",
    "토요일"
  ];

  const setSelectedCell = useSetRecoilState(clientDashboardCalendarCellState);

  const cellClickHandler = (data: CalendarCellDataWithCheckList) => {
    setSelectedCell({ ...data, isOpen: true });
  };

  return (
    <Grid
      container
      columns={{ xs: 7, sm: 7, md: 7 }}
      direction={"row"}
      justifyContent={"space-between"}
      sx={{
        p: 1
      }}
    >
      {days.map((day, index) => (
        <Grid
          item
          key={index}
          sm={1}
          md={1}
          sx={{
            display: { xs: "none", sm: "block" },
            p: 1,
            textAlign: "start"
          }}
        >
          <Typography
            sx={{
              color: day === "일요일" || day === "토요일" ? red[500] : grey[700]
            }}
          >
            {day}
          </Typography>
        </Grid>
      ))}
      {isLoading
        ? Array.from({ length: 35 }).map((_, index) => (
            <Grid
              key={index}
              item
              xs={7}
              sm={1}
              sx={{ borderBottom: 1, minHeight: 110, p: 1 }}
            >
              <Skeleton
                variant="text"
                animation="wave"
                width={30}
                sx={{ fontSize: "1rem" }}
              />
              <Skeleton
                variant="text"
                animation="wave"
                width={150}
                sx={{ fontSize: "1rem" }}
              />
              <Skeleton
                variant="text"
                animation="wave"
                width={150}
                sx={{ fontSize: "1rem" }}
              />
            </Grid>
          ))
        : data?.map((cell, index) => (
            <Grid
              key={index}
              item
              xs={7}
              sm={1}
              sx={{
                borderBottom: "1px solid #c7c7c7",
                height: 110,
                p: 1
              }}
            >
              <Box
                onClick={() => cellClickHandler(cell)}
                sx={{
                  "&:hover": { backgroundColor: "#e2e2e25a" },
                  cursor: "pointer",
                  borderRadius: 2,
                  transition: "all 0.3s",
                  pb: 0.5
                }}
              >
                <Typography
                  sx={{
                    color:
                      index % 7 === 0 || index % 7 === 6 || cell.isHoliday
                        ? red[500]
                        : grey[700],
                    p: 1,
                    fontWeight: cell.isCurrentMonth ? 600 : null,
                    opacity: cell.isCurrentMonth ? null : 0.5
                  }}
                >
                  {format(
                    parse(cell.referenceDate, "yyyy-MM-dd", new Date()),
                    "dd"
                  )}
                </Typography>
                <Typography
                  sx={{
                    color: blue[500],
                    paddingLeft: 1,
                    fontWeight: cell.isCurrentMonth ? 600 : null,
                    opacity: cell.isCurrentMonth ? null : 0.5
                  }}
                >
                  {addCommas(cell.totalPayment)}
                </Typography>
                <Typography
                  sx={{
                    color: cell.isComplete ? teal[500] : grey[500],
                    paddingLeft: 1,
                    fontWeight: cell.isCurrentMonth ? 600 : null,
                    opacity: cell.isCurrentMonth ? null : 0.5
                  }}
                >
                  {cell.isComplete
                    ? addCommas(cell.depositAmount)
                    : addCommas(cell.expectedDepositAmount)}
                </Typography>
              </Box>
            </Grid>
          ))}
    </Grid>
  );
};

export default CalendarBody;
