import CloseIcon from "@mui/icons-material/Close";
import MuiLoadingButton from "@mui/lab/LoadingButton";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormControlLabel from "@mui/material/FormControlLabel";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useSetRecoilState } from "recoil";
import {
  getCardInstallments,
  postCardCompanyInstallments,
  saveCardInstallments
} from "../../api/CardInstallments";
import { numberCommaValidate } from "../../libs/input-validation";
import { removeCommas } from "../../libs/thousands-commas";
import isModalOpenAtom from "../../recoil/isModalOpen";

interface CardInstallmentModalProps {
  closeModal?: () => void;
  cardCompanyName: string;
  mccType: string;
}

const CardInstallmentModal = ({
  closeModal,
  cardCompanyName,
  mccType
}: CardInstallmentModalProps) => {
  // ===================================================================================================================
  // 리코일 스테이트
  // ===================================================================================================================
  const setIsModalOpen = useSetRecoilState(isModalOpenAtom);


  // ===================================================================================================================
  // 리액트 훅 폼
  // ===================================================================================================================
  const { register, handleSubmit, reset, control } = useForm({
    defaultValues: Object.assign(
      {},
      Array.from({ length: 23 }, (v, i) => ({
        id: i + 1,
        cardCompanyName,
        month: i + 2,
        isAllFree: false,
        price: 0,
        isPartialFree: false,
        partialPrice: 0,
        customerMonth: "0"
      }))
    )
  });

  // ===================================================================================================================
  // 리액트 쿼리
  // ===================================================================================================================
  // 카드사 할부 정보 조회
  const { data } = useQuery(
    ["/cardCompany/installments", cardCompanyName, mccType],
    () => getCardInstallments(cardCompanyName!, mccType!),
    {
      enabled: !!cardCompanyName,
      onSuccess: (data) => {
        const newArr = data.content.installmentInfo.map((i) => ({
          ...i,
          customerMonth: String(i.customerMonth)
        }));
        reset(Object.assign({}, newArr));
      },
      refetchOnWindowFocus: false
    }
  );

  // 카드사 할부정보 수정
  const { mutate, isLoading: mutateLoading } = useMutation(
    saveCardInstallments,
    {
      onSuccess: () => {
        closeModal?.();
        setIsModalOpen({
          value: true,
          position: "top",
          alertSeverity: "success",
          message: "저장되었습니다."
        });
      },
      onError: (error: any) => {
        setIsModalOpen({
          value: true,
          position: "top",
          alertSeverity: "error",
          message: error?.response?.data?.message
        });
      }
    }
  );

  // 카드사 할부 정보 추가 - 최초 할부 정보 설정
  const { mutate: registMutate, isLoading: registLoading } = useMutation(
    postCardCompanyInstallments,
    {
      onSuccess: () => {
        closeModal?.();
        setIsModalOpen({
          value: true,
          position: "top",
          alertSeverity: "success",
          message: "할부 정보가 설정되었습니다."
        });
      },
      onError: (error: any) => {
        setIsModalOpen({
          value: true,
          position: "top",
          alertSeverity: "error",
          message: error?.response?.data?.message
        });
      }
    }
  );

  // ===================================================================================================================
  // 카드사 할부 정보 수정버튼
  // ===================================================================================================================
  const onValid = (formData: any) => {
    if (mutateLoading) return;
    const formDataToArr = Object.values(formData).map((f: any) => ({
      ...f,
      price: removeCommas(
        typeof f.price === "string" ? f.price : String(f.price)
      ),
      partialPrice: removeCommas(
        typeof f.partialPrice === "string"
          ? f.partialPrice
          : String(f.partialPrice)
      )
    }));
    mutate(formDataToArr);
  };

  // ===================================================================================================================
  // 카드사 업종의 할부 정보 최초 설정 버튼
  // 해당 버튼을 누르면 할부 정보의 최소 금액은 50,000원으로 기본 세팅된다.
  // ===================================================================================================================
  const onClick = () => {
    if (registLoading) return;
    const params = {
      cardCompanyName,
      mccType
    };
    registMutate(params);
  };

  return (
    <Dialog open={true} fullWidth maxWidth="md">
      <DialogTitle>
        {cardCompanyName} {mccType} 업종 할부정보
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={() => {
          closeModal?.();
        }}
        sx={{
          position: "absolute",
          right: 8,
          top: 12
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent dividers>
        {/* 등록된 업종의 할부 정보가 없는 경우 */}
        {data?.content.installmentInfo.length === 0 && (
          <>
            <Stack justifyContent={"center"} alignItems={"center"} my={10}>
              <Box textAlign={"center"}>
                <Typography mb={3}>
                  업종의 할부정보가 존재하지 않습니다.
                </Typography>
                <MuiLoadingButton
                  variant="outlined"
                  loading={registLoading}
                  onClick={onClick}
                >
                  할부 정보 설정하기
                </MuiLoadingButton>
              </Box>
            </Stack>
          </>
        )}

        {/* 등록된 업종의 할부 정보를 수정하는 경우 */}
        {data && data?.content.installmentInfo.length > 0 && (
          <form id="installment-form" onSubmit={handleSubmit(onValid)}>
            {Array.from({ length: 23 }, (v, i) => ({
              id: i + 1,
              cardCompanyName,
              month: i + 2,
              isAllFree: false,
              price: 0,
              isPartialFree: false,
              partialPrice: 0,
              customerMonth: 0
            })).map((installment, index) => (
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                key={index}
                mb={2}
              >
                <Box>
                  <Typography component="strong">
                    {installment.month} 개월
                  </Typography>
                  <TextField
                    sx={{
                      display: "none"
                    }}
                    size="small"
                    variant="standard"
                    {...register(`${index}.id`)}
                  />
                </Box>
                <Box>
                  <Controller
                    name={`${index}.isAllFree`}
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <FormControlLabel
                        label="무이자"
                        control={
                          <Checkbox onChange={onChange} checked={value} />
                        }
                      />
                    )}
                  />

                  <TextField
                    label="최소 금액"
                    variant="standard"
                    size="small"
                    {...register(`${index}.price`)}
                    onInput={numberCommaValidate}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">원</InputAdornment>
                      )
                    }}
                    sx={{ mr: 2 }}
                  />
                </Box>
                <Box>
                  <Controller
                    name={`${index}.isPartialFree`}
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <FormControlLabel
                        label="부분 무이자"
                        control={
                          <Checkbox onChange={onChange} checked={value} />
                        }
                      />
                    )}
                  />
                  <TextField
                    label="최소 금액"
                    size="small"
                    variant="standard"
                    {...register(`${index}.partialPrice`)}
                    onInput={numberCommaValidate}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">원</InputAdornment>
                      )
                    }}
                    sx={{ mr: 2 }}
                  />
                  <Controller
                    name={`${index}.customerMonth`}
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <TextField
                        sx={{ minWidth: 110 }}
                        label="고객 부담"
                        size="small"
                        select
                        variant="standard"
                        value={value}
                        onChange={onChange}
                      >
                        {Array.from(
                          { length: installment.month },
                          (v, i) => i
                        ).map((i) => {
                          if (i === 0) {
                            return (
                              <MenuItem key={i} value={i}>
                                없음
                              </MenuItem>
                            );
                          }
                          return (
                            <MenuItem key={i} value={i}>
                              {i} 개월
                            </MenuItem>
                          );
                        })}
                      </TextField>
                    )}
                  />
                </Box>
              </Stack>
            ))}
          </form>
        )}
      </DialogContent>
      <DialogActions>
        {data && data?.content.installmentInfo.length > 0 && (
          <MuiLoadingButton
            type="submit"
            form="installment-form"
            variant="contained"
            sx={{
              color: "white"
            }}
            fullWidth
            loading={mutateLoading}
          >
            저장하기
          </MuiLoadingButton>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default CardInstallmentModal;
