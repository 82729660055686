import { instance } from "../Instance";
import type { CommonApi } from "../type";
import type { OtpParams, VerifyOtp } from "./types";

export const verifyOtp = async (otpParams: OtpParams) => {
  const { otpCode, token } = otpParams;
  const { data } = await instance.post<CommonApi<VerifyOtp>>(
    "/otp/verify",
    { otpCode },
    {
      headers: {
        Authorization: `${token}`
      }
    }
  );
  return data;
};
