import CloseIcon from "@mui/icons-material/Close";
import NavigateNextRoundedIcon from "@mui/icons-material/NavigateNextRounded";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useMutation, useQuery } from "@tanstack/react-query";
import { Controller, useForm } from "react-hook-form";
import { useSetRecoilState } from "recoil";
import { getMerchentModify } from "../../api/ContractManagement";
import { postMerchantModify } from "../../api/Merchants";
import isModalOpenAtom from "../../recoil/isModalOpen";
import { engValidate, numberValidate } from "../../libs/input-validation";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  merchantId: any;
  status: string;
  modifyId: string;
}

interface FormInputs {
  fax: string;
  mallName: string;
  mallNameEn: string;
  mallUrl: string;
  phone: string;
}

const MerchantInfoUpdateModal = ({
  isOpen,
  onClose,
  merchantId,
  status,
  modifyId
}: Props) => {
  // ===============================================================================================
  // 리코일 스테이트
  // ===============================================================================================
  const setIsModalOpen = useSetRecoilState(isModalOpenAtom);

  // ===============================================================================================
  // 리액트 쿼리
  // ===============================================================================================
  // 가맹점 정보 수정 요청 상세 조회
  const { data, isLoading } = useQuery(
    [`/merchants/modify/${modifyId}`, modifyId],
    () => getMerchentModify(modifyId),
    {
      enabled: isOpen,
      onSuccess: async (data) => {
        const content = data.content || {};
        setValue("mallName", content.afterMallName || content.beforeFax);
        setValue(
          "mallNameEn",
          content.afterMallNameEn || content.beforeMallNameEn
        );
        setValue("phone", content.afterPhone || content.beforePhone);
        setValue("fax", content.afterFax || content.beforeFax);
        setValue("mallUrl", content.afterMallUrl || content.beforeMallUrl);
      }
    }
  );

  // 가맹점 정보 업데이트 요청
  const { mutate } = useMutation(postMerchantModify, {
    onSuccess: () => {
      onClose()
      setIsModalOpen({
        value: true,
        position: "top",
        alertSeverity: "success",
        message: "가맹 정보 수정 요청이 완료되었습니다."
      });
    },
    onError: (error: any) => {
      onClose()
      setIsModalOpen({
        value: true,
        position: "top",
        alertSeverity: "error",
        message: error?.response?.data?.message
      });
    }
  });

  // ===============================================================================================
  // 리액트 훅 폼
  // ===============================================================================================

  const {
    setValue,
    control,
    handleSubmit,
    formState: { isDirty, isValid }
  } = useForm<FormInputs>({
    defaultValues: {
      mallName: "",
      mallNameEn: "",
      phone: "",
      fax: "",
      mallUrl: ""
    }
  });

  const onSubmit = (formData: FormInputs) => {
    const params = {
      ...formData,
      merchantId: merchantId
    };
    mutate(params);
  };

  return (
    <>
      {!isLoading && data?.content ? (
        <Dialog open={isOpen} fullWidth maxWidth={"sm"}>
          <DialogTitle>
            정보 수정 요청
            <IconButton
              sx={{
                position: "absolute",
                right: 8,
                top: 12
              }}
              onClick={onClose}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>

          <DialogContent sx={{ mb: 1 }}>
            <Box>
              <Typography variant="subtitle2" color={"error"} mb={2.5}>
                * 최소한 하나의 필드를 수정해 주십시오.
                <br />* 수정 내용에 따라 정보 업데이트가 수락 또는 거절될 수
                있습니다.
              </Typography>
              <Stack direction={"row"} spacing={4} justifyContent={"center"}>
                <Stack spacing={3}>
                  <Chip label="수정 전" />
                  <Stack spacing={3}>
                    <TextField
                      label="가맹점명"
                      size="small"
                      value={data.content.beforeMallName}
                      InputProps={{
                        readOnly: true
                      }}
                    />
                    {data?.content.beforeMallNameEn ? (
                      <TextField
                        label="가맹점 영문명"
                        size="small"
                        value={data.content.beforeMallNameEn}
                        InputProps={{
                          readOnly: true
                        }}
                      />
                    ) : null}
                    <TextField
                      label="가맹점 번호"
                      size="small"
                      value={data.content.beforePhone}
                      InputProps={{
                        readOnly: true
                      }}
                    />
                    <TextField
                      label="가맹점 팩스 번호"
                      size="small"
                      value={data.content.beforeFax}
                      InputProps={{
                        readOnly: true
                      }}
                    />
                    <TextField
                      label="가맹점 URL"
                      size="small"
                      value={data.content.beforeMallUrl}
                      InputProps={{
                        readOnly: true
                      }}
                    />
                    <Button variant="outlined" fullWidth onClick={onClose}>
                      취소
                    </Button>
                  </Stack>
                </Stack>
                <Stack alignItems={"center"} justifyContent={"center"}>
                  <NavigateNextRoundedIcon fontSize="large" color="primary" />
                </Stack>
                <Stack spacing={3}>
                  <Chip label="수정 후" />
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <Stack spacing={3}>
                      <Controller
                        name="mallName"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <TextField
                            label="가맹점명"
                            size="small"
                            value={value}
                            onChange={onChange}
                          />
                        )}
                      />
                      {data?.content.beforeMallNameEn ? (
                        <Controller
                          name="mallNameEn"
                          control={control}
                          render={({ field: { onChange, value } }) => (
                            <TextField
                              label="가맹점 영문명"
                              size="small"
                              value={value}
                              onChange={onChange}
                              onInput={engValidate}
                            />
                          )}
                        />
                      ) : null}
                      <Controller
                        name="phone"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <TextField
                            label="가맹점 번호"
                            size="small"
                            value={value}
                            onChange={onChange}
                            onInput={numberValidate}
                            placeholder="&#39;-&#39; 없이 숫자만 입력해 주세요."
                          />
                        )}
                      />
                      <Controller
                        name="fax"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <TextField
                            label="가맹점 팩스 번호"
                            size="small"
                            value={value}
                            onChange={onChange}
                            onInput={numberValidate}
                            placeholder="&#39;-&#39; 없이 숫자만 입력해 주세요."
                          />
                        )}
                      />
                      <Controller
                        name="mallUrl"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <TextField
                            label="가맹점 URL"
                            size="small"
                            value={value}
                            onChange={onChange}
                          />
                        )}
                      />
                      <Button
                        type="submit"
                        variant="contained"
                        fullWidth
                        sx={{ color: "white" }}
                        disabled={!isDirty || !isValid}
                      >
                        정보 업데이트
                      </Button>
                    </Stack>
                  </form>
                </Stack>
              </Stack>
            </Box>
          </DialogContent>
        </Dialog>
      ) : null}
    </>
  );
};
export default MerchantInfoUpdateModal;
