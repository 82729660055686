import { instanceWithAuth } from "../Instance";
import { CommonApi } from "../type";
import { Bank, MerchantRegistrationTemp } from "./types";

// =======================================================================================
// 가맹점 임시등록, 조회
// =======================================================================================
/**
 * 가맹점 임시 저장 저장하기
 */
export const postMerchantRegistrationTemp = async (
  params: MerchantRegistrationTemp
) => {
  const url = "/merchant/temporary";
  const { data } = await instanceWithAuth.post<
    CommonApi<MerchantRegistrationTemp>
  >(url, params);

  return data;
};
/**
 * 가맹점 임시 저장 불러오기
 */
export const getMerchantRegistrationTemp = async (registrant: string) => {
  const { data } = await instanceWithAuth.get<
    CommonApi<MerchantRegistrationTemp>
  >("/merchant/temporary", { params: { registrant } });
  return data;
};

// =======================================================================================
// 가맹점 등록
// =======================================================================================
/**
 * 가맹점 등록
 */
export const postMerchant = async (params: any) => {
  const axiosConfig = {
    headers: { "Content-Type": "application/json" }
  };
  const { data } = await instanceWithAuth.post(
    "/merchant",
    params,
    axiosConfig
  );
  return data;
};

/**
 * 파일 업로드
 */
export const merchantFileUpload = ({
  file,
  fileType
}: {
  file: File;
  fileType: string;
}) => {
  const formData = new FormData();
  formData.append("file", file);
  formData.append("type", fileType); // 서버에서 기대하는 파일 타입 전달

  return instanceWithAuth.post("/file/upload", formData);
};

/**
 * 사업자등록번호 검증
 */
export const getBusinessNumber = async (businessNumber: string) => {
  const { data } = await instanceWithAuth.get<CommonApi<boolean>>(
    `/bypass/business-number/validate/${businessNumber}`
  );
  return data;
};
/**
 * 은행 리스트 불러오기
 */
export const getBankList = async () => {
  const { data } = await instanceWithAuth.get<CommonApi<Bank[]>>(
    "/bypass/banks"
  );
  return data;
};
