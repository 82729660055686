import { instanceWithAuth } from "../Instance";
import { CommonApi } from "../type";
import {
  ClientNotificationParams,
  NotificationForAdmin,
  NotificationParams,
  NotificationRegistrationParams,
  NotificationUpdateParams
} from "./type";

/**
 * admin 알림 보내기(등록)
 */
export const postNotification = async (
  params: NotificationRegistrationParams
) => {
  const url = "/notifications/send/users";
  const { data } = await instanceWithAuth.post<CommonApi<boolean>>(url, params);

  return data;
};

/**
 * admin 알림 내역 조회
 */
export const getNotificationsForAdmin = async (params: NotificationParams) => {
  const url = "/notification-messages";
  const { data } = await instanceWithAuth.get<
    CommonApi<NotificationForAdmin[]>
  >(url, { params });

  return data;
};

/**
 * admin 알림 내역 수정
 */
export const putNotification = async (params: NotificationUpdateParams) => {
  const url = `/notification-messages/${params.id}`;
  const { data } = await instanceWithAuth.put<CommonApi<boolean>>(url, params);

  return data;
};

/**
 * client 알림 내역 조회
 */
export const getNotificationsForClient = async (
  params: ClientNotificationParams
) => {
  const url = "/notifications";
  const { data } = await instanceWithAuth.get<
    CommonApi<NotificationForAdmin[]>
  >(url, { params });

  return data;
};

/**
 * client 알림 읽음
 */
export const putNotificationRead = async (notificationId: number) => {
  const url = `/notifications/${notificationId}/is-read`;
  const { data } = await instanceWithAuth.put<CommonApi<boolean>>(url);

  return data;
};
