import SaveIcon from "@mui/icons-material/Save";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import { useRecoilState, useSetRecoilState } from "recoil";
import excelDownload from "../../libs/excel-download";
import isModalOpenAtom from "../../recoil/isModalOpen";
import SettlementsState from "../../recoil/settlements/atom";
import LoadingButton from "../LoadingButton";
import Paper from "@mui/material/Paper";

const columns = [
  {
    id: "mallId",
    label: "가맹점 ID"
  },
  {
    id: "mallName",
    label: "가맹점명"
  },
  {
    id: "paidOutDate",
    label: "거래일자"
  },
  {
    id: "transactionTotal",
    label: "거래합계 (건수)"
  },
  {
    id: "completeTotal",
    label: "승인합계 (건수)"
  },
  {
    id: "cancelTotal",
    label: "취소합계 (건수)"
  },
  {
    id: "cardFee",
    label: "카드사 수수료"
  },
  {
    id: "optatumFee",
    label: "옵타움 수수료"
  },
  {
    id: "merchantFee",
    label: "가맹점 수수료"
  },
  {
    id: "vat",
    label: "부가세"
  },
  {
    id: "commissionFee",
    label: "총수수료"
  },
  {
    id: "settlementPrice",
    label: "정산금액"
  },
  {
    id: "actualAmount",
    label: "정산예정금액"
  }
];
const SettlementsTotal = ({ data, isLoading }: any) => {
  // ===================================================================================================================
  // 리코일 스테이트
  // ===================================================================================================================
  const setIsModalOpen = useSetRecoilState(isModalOpenAtom);
  const [SettlementsTotal, setSettlementsTotal] =
    useRecoilState(SettlementsState);

  // ===================================================================================================================
  // 리액트 쿼리
  // ===================================================================================================================
  // const { isLoading, data } = useQuery(
  //   ["/settlement/total", SettlementsTotal],
  //   () => getSettlementsTotal(SettlementsTotal)
  // );

  const handleTotalSettlementExcel = () => {
    const content = data?.content;
    // 거래합계 데이터가 "(0)"을 포함할 경우 함수 종료
    if (!content || content.transactionTotal.includes("(0)")) {
      setIsModalOpen({
        value: true,
        position: "top",
        alertSeverity: "error",
        message: "데이터가 없습니다."
      });
      return;
    } else {
      setIsModalOpen({
        value: true,
        position: "top",
        alertSeverity: "success",
        message: "다운로드를 시작합니다."
      });
    }
    const list = [
      {
        가맹점Id: content.mallId,
        가맹점명: content.mallName,
        거래일자: content.paidOutDate,
        거래합계: content.transactionTotal,
        승인합계: content.completeTotal,
        취소합계: content.cancelTotal
      }
    ];
    excelDownload({
      list,
      sheetName:
        SettlementsTotal.mallName === ""
          ? "정산내역 총합계"
          : `정산내역 총합계_${SettlementsTotal.mallName}`,
      fileName:
        SettlementsTotal.mallName === ""
          ? "정산내역 총합계.xlsx"
          : `정산내역 총합계_${SettlementsTotal.mallName}.xlsx`
    });
  };

  return (
    <Box>
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"end"}
        mb={1}
      >
        <Typography sx={{ fontWeight: "bold" }}>총합계</Typography>
        <LoadingButton
          icon={<SaveIcon />}
          size="medium"
          fullWidth={false}
          color="secondary"
          variant="outlined"
          loading={false}
          handleClick={handleTotalSettlementExcel}
        >
          EXCEL 다운로드
        </LoadingButton>
      </Stack>

      <Paper
        sx={{
          border: "1px solid #F2F3F5",
          borderRadius: 3,
          overflow: "hidden"
        }}
      >
        <TableContainer>
          <Table sx={{ minWidth: 650 }}>
            <TableHead>
              <TableRow sx={{ backgroundColor: "#FBFBFB" }}>
                {columns.map((column) => (
                  <TableCell
                    variant="head"
                    align="center"
                    key={column.id}
                    sx={{ borderTop: "none" }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>

            <TableBody>
              {data?.content && !isLoading ? (
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      align="center"
                      key={column.id}
                      sx={{ borderBottom: "none" }}
                    >
                      {data.content[column.id]}
                    </TableCell>
                  ))}
                </TableRow>
              ) : (
                <TableRow>
                  {columns.map((column, index) => (
                    <TableCell key={index}>
                      <Skeleton variant="rounded" animation="wave" />
                    </TableCell>
                  ))}
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Box>
  );
};
export default SettlementsTotal;
