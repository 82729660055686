import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import LogoImage from "../../images/logo_contract.png";
import Divider from "@mui/material/Divider";
import Fonts from "./Fonts";
import grey from "@mui/material/colors/grey";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import { red } from "@mui/material/colors";

const Page9 = () => {
  return (
    <Fonts>
      <Box
        sx={{
          width: "210mm",
          height: "297mm",
          backgroundColor: "white",
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <Card
          sx={{
            width: "195mm",
            height: "282mm",
            backgroundColor: "white",
            border: "1px solid black"
          }}
        >
          {/* 머릿글 ======================================================== */}
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            sx={{
              width: "100%",
              height: "15mm"
            }}
          >
            <Stack
              justifyContent={"center"}
              alignItems={"center"}
              sx={{
                width: "120px"
              }}
            >
              {/* <Box component={"img"} src={LogoImage}></Box> */}
            </Stack>
            <Stack
              justifyContent={"center"}
              alignItems={"center"}
              sx={{ fontSize: "18px", fontWeight: 700, color: "#a29fb0" }}
            >
              계약서 발송 전 체크사항
            </Stack>
            <Stack
              justifyContent={"center"}
              alignItems={"center"}
              sx={{
                width: "35mm",
                color: "white",
                fontSize: "16px"
              }}
            >
              <Box component={"img"} src={LogoImage}></Box>
            </Stack>
          </Stack>
          <Divider
            sx={{ border: "none", borderTop: "4px solid #a29fb0", mx: 1 }}
          ></Divider>

          {/* 본문 ========================================================== */}
          <Box
            component={"ol"}
            start={3}
            sx={{
              height: "252mm",
              mt: 1,
              px: 1
            }}
          >
            <Typography sx={{ fontSize: "9pt", mt: 2 }}>
              &nbsp;&nbsp;국내 최고의 통합 결제 서비스 오직페이 전자결제서비스를
              이용해 주셔서 대단히 감사합니다. 서비스 가입에 필요한 서류를
              아래와 같이 안내 드리오니, 계약서 발송 전 꼭 체크하여 주시기
              바랍니다.
            </Typography>
            <Typography sx={{ fontSize: "9pt" }}>
              (제출 서류가 미비할 경우{" "}
              <Typography
                component="strong"
                sx={{ display: "inline", fontSize: "9pt", color: red[500] }}
              >
                정산지급이 지연됩니다.
              </Typography>
              )
            </Typography>
            <Typography sx={{ fontSize: "9pt", mt: 2 }}>
              주의사항:{" "}
              <Typography
                component="strong"
                sx={{ display: "inline", fontSize: "9pt", color: red[500] }}
              >
                계약서에 날인된 도장
              </Typography>
              과 (개인대표자/법인)
              <Typography
                component="strong"
                sx={{ display: "inline", fontSize: "9pt", color: red[500] }}
              >
                인감증명서의 도장
              </Typography>
              이{" "}
              <Typography
                component="strong"
                sx={{
                  display: "inline",
                  fontSize: "9pt",
                  color: red[500],
                  fontWeight: 700
                }}
              >
                반드시 일치
              </Typography>
              해야 합니다
            </Typography>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell
                    colSpan={3}
                    align={"center"}
                    sx={{
                      border: "1px dotted black",
                      p: 0.5,
                      backgroundColor: "#e5dfec"
                    }}
                  >
                    <Typography sx={{ fontSize: "9pt", fontWeight: 700 }}>
                      제출서류 관련사항
                    </Typography>
                  </TableCell>
                  <TableCell
                    align={"center"}
                    sx={{
                      border: "1px dotted black",
                      p: 0.5,
                      backgroundColor: "#e5dfec"
                    }}
                  >
                    <Typography sx={{ fontSize: "9pt", fontWeight: 700 }}>
                      셀프체크
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell
                    align={"center"}
                    rowSpan={12}
                    sx={{ border: "1px dotted black", p: 0.5 }}
                  >
                    필수 제출
                  </TableCell>
                  <TableCell
                    align={"center"}
                    rowSpan={5}
                    sx={{ border: "1px dotted black", p: 0.5 }}
                  >
                    개인사업자
                  </TableCell>
                  <TableCell sx={{ border: "1px dotted black", p: 0.5 }}>
                    ① 계약서 2부 (대표자 인감 날인 필수)
                  </TableCell>
                  <TableCell
                    align={"center"}
                    sx={{ border: "1px dotted black", p: 0.5 }}
                  >
                    □
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{ border: "1px dotted black", p: 0.5 }}>
                    ② 사업자등록증 사본 1부
                  </TableCell>
                  <TableCell
                    align={"center"}
                    sx={{ border: "1px dotted black", p: 0.5 }}
                  >
                    □
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{ border: "1px dotted black", p: 0.5 }}>
                    ③ 대표자 명의 통장 사본 1부
                  </TableCell>
                  <TableCell
                    align={"center"}
                    sx={{ border: "1px dotted black", p: 0.5 }}
                  >
                    □
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{ border: "1px dotted black", p: 0.5 }}>
                    ④ 대표자 인감증명서 원본 1부 (시효: 3개월)
                  </TableCell>
                  <TableCell
                    align={"center"}
                    sx={{ border: "1px dotted black", p: 0.5 }}
                  >
                    □
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{ border: "1px dotted black", p: 0.5 }}>
                    ⑤ 대표자 신분증(앞,뒤) 사본 1부
                  </TableCell>
                  <TableCell
                    align={"center"}
                    sx={{ border: "1px dotted black", p: 0.5 }}
                  >
                    □
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    align={"center"}
                    rowSpan={7}
                    sx={{ border: "1px dotted black", p: 0.5 }}
                  >
                    법인사업자
                  </TableCell>
                  <TableCell sx={{ border: "1px dotted black", p: 0.5 }}>
                    ① 계약서 2부 (법인 인감 날인 필수)
                  </TableCell>
                  <TableCell
                    align={"center"}
                    sx={{ border: "1px dotted black", p: 0.5 }}
                  >
                    □
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{ border: "1px dotted black", p: 0.5 }}>
                    ② 사업자등록증 사본 1부
                  </TableCell>
                  <TableCell
                    align={"center"}
                    sx={{ border: "1px dotted black", p: 0.5 }}
                  >
                    □
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{ border: "1px dotted black", p: 0.5 }}>
                    ③ 법인 명의 통장 사본 1부
                  </TableCell>
                  <TableCell
                    align={"center"}
                    sx={{ border: "1px dotted black", p: 0.5 }}
                  >
                    □
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{ border: "1px dotted black", p: 0.5 }}>
                    ④ 법인인감증명서 원본 1부 (시효: 3개월)
                  </TableCell>
                  <TableCell
                    align={"center"}
                    sx={{ border: "1px dotted black", p: 0.5 }}
                  >
                    □
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{ border: "1px dotted black", p: 0.5 }}>
                    ⑤ 등기사항전부증명서 1부 (현재사항) 원본 (시효: 3개월)
                  </TableCell>
                  <TableCell
                    align={"center"}
                    sx={{ border: "1px dotted black", p: 0.5 }}
                  >
                    □
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{ border: "1px dotted black", p: 0.5 }}>
                    ⑥ 대표자 신분증(앞,뒤) 사본 1부
                  </TableCell>
                  <TableCell
                    align={"center"}
                    sx={{ border: "1px dotted black", p: 0.5 }}
                  >
                    □
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{ border: "1px dotted black", p: 0.5 }}>
                    <Typography>⑦ 주주명부(시효: 3개월)</Typography>
                    <Typography>
                      ※ 주주명부 제출 제외대상은 국가기관 및 상장법인에 해당,
                      비영리법인은 정관 추가 필수제출
                    </Typography>
                  </TableCell>
                  <TableCell
                    align={"center"}
                    sx={{ border: "1px dotted black", p: 0.5 }}
                  >
                    □
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    align={"center"}
                    colSpan={2}
                    sx={{ border: "1px dotted black", p: 0.5, opacity: 0.7 }}
                  >
                    선택 제출
                  </TableCell>
                  <TableCell
                    sx={{ border: "1px dotted black", p: 0.5, opacity: 0.7 }}
                  >
                    <Typography>
                      사용인감으로 날인 시,{" "}
                      <Typography
                        component="strong"
                        sx={{ display: "inline", textDecoration: "underline" }}
                      >
                        사용인감증명서
                      </Typography>{" "}
                      제출
                    </Typography>
                    <Typography>※ 법인사업자만 선택제출 가능합니다.</Typography>
                  </TableCell>
                  <TableCell
                    align={"center"}
                    sx={{ border: "1px dotted black", p: 0.5 }}
                  >
                    □
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <Stack direction={"row"} sx={{ mt: 1 }}>
              <Typography>□</Typography>
              <Typography sx={{ fontSize: "9pt", fontWeight: 700, ml: 1 }}>
                보내실 곳
              </Typography>
            </Stack>
            <Typography sx={{ fontSize: "11pt", ml: 1 }}>
              (일반우편으로 발송하면 분실될 우려가 있습니다. 배송추적이 가능한
              <Typography
                component="strong"
                sx={{
                  fontSize: "11pt",
                  display: "inline",
                  textDecoration: "underline",
                  ml: 1
                }}
              >
                등기우편으로 발송해주세요.
              </Typography>
              )
            </Typography>
            <Typography
              align="center"
              sx={{
                fontSize: "12pt",
                position: "relative",
                left: "45%",
                top: "35px",
                backgroundColor: "white",
                width: "30mm"
              }}
            >
              절취선 ✄
            </Typography>
            <Box sx={{ border: "1px dotted black", p: 1, m: 3 }}>
              <Typography sx={{ fontSize: "12pt" }}>받는 사람</Typography>
              <Stack alignItems={"flex-end"} spacing={1}>
                <Typography sx={{ fontSize: "12pt" }}>
                  우편번호 46293
                </Typography>
                <Typography sx={{ fontSize: "12pt" }}>
                  부산광역시 금정구 금정로 61 3F
                </Typography>
                <Typography sx={{ fontSize: "12pt" }}>
                  ㈜옵타움플랫폼 오직페이 계약담당자
                </Typography>
              </Stack>
            </Box>
          </Box>

          {/* 바닥글 ======================================================== */}
          <Divider
            sx={{
              border: "none",
              borderTop: "1px dotted",
              borderColor: grey[700],
              mx: 1
            }}
          ></Divider>
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            sx={{ height: "7mm", color: grey[400], mx: 3, mt: 0 }}
          >
            <Typography>국내 최고의 통합 결제 서비스 OZICPAY</Typography>
            <Typography>페이지 9 / 9</Typography>
          </Stack>
        </Card>
      </Box>
    </Fonts>
  );
};

export default Page9;
