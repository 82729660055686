import { instanceWithAuth } from "../Instance";
import { CommonApi } from "../type";
import {
  CardCompanyDeposit,
  DepositAmountParams,
  HolidaysParams
} from "./type";

export const getDepositAmount = async (params: DepositAmountParams) => {
  const { data } = await instanceWithAuth.get<CommonApi<CardCompanyDeposit[]>>(
    "/cardCompany/deposit/dueAmount",
    { params }
  );
  return data;
};

export const getHolidays = async (params: HolidaysParams) => {
  const { data } = await instanceWithAuth.get<CommonApi<String[]>>(
    "/holidays",
    { params }
  );
  return data;
};
