import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useMutation } from "@tanstack/react-query";
import { useForm } from "react-hook-form";
import { useSetRecoilState } from "recoil";
import { queryClient } from "../..";
import { confirmSettlement } from "../../api/v2/Settlements.ts";
import { ConfirmSettlementParams } from "../../api/v2/Settlements.ts/types.js";
import isModalOpen from "../../recoil/isModalOpen/atom";

interface Props {
  mallId: string;
  paidOutDate: string;
  isConfirm: string;
  closeModal?: () => void;
}
const SettlementConfirmModal = ({ mallId, paidOutDate, isConfirm, closeModal }: Props) => {
  // ===========================================================================
  // 리코일 스테이트
  // ===========================================================================
  const setIsModalOpen = useSetRecoilState(isModalOpen);

  // ===============================================================================================
  // 리액트 훅 폼
  // ===============================================================================================
  const {
    register,
    handleSubmit,
    getValues
  } = useForm({
    mode: "onChange",
    defaultValues: {
      mallId: mallId,
      paidOutDate: paidOutDate,
      note: "",
      isConfirm: true
    }
  });

  // ===============================================================================================
  // 리액트 쿼리
  // ===============================================================================================
  const { mutate } = useMutation(confirmSettlement, {
    onSuccess: () => {
      queryClient.invalidateQueries();
      setIsModalOpen({
        value: true,
        position: "top",
        alertSeverity: "success",
        message: "정산 상태가 변경되었습니다."
      });
    },
    onError: (error: any) => {
      setIsModalOpen({
        value: true,
        position: "top",
        alertSeverity: "error",
        message: error?.response?.data?.message
      });
    }
  });

  // 정산 확정 메모 & 정산 확정
  const onSubmit = (formData: ConfirmSettlementParams) => {
    const params = {
      ...formData,
    };
    mutate(params);
    closeModal?.();
  };

  // 정산 취소 메모 & 정산 취소
  const cancelSettlement = (formData: ConfirmSettlementParams) => {
    const params = {
      ...formData,
      isConfirm: false
    };
    mutate(params);
    closeModal?.();
  };

  return (
    <>
      <Dialog open={true} fullWidth maxWidth="xs">
        <DialogTitle>정산 상태 변경</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={closeModal}
          sx={{
            position: "absolute",
            right: 8,
            top: 12
          }}
        >
          <CloseIcon />
        </IconButton>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent>
            <Typography sx={{ mb: 1.5 }}>
              정산 상태를 변경하시겠습니까?
            </Typography>
            <TextField label="비고" {...register("note")} fullWidth multiline />
          </DialogContent>
          <DialogActions sx={{mx: 2, mb: 1}}>
            <Button
              variant="outlined"
              type="button"
              fullWidth
              disabled={isConfirm === "미확정"}
              onClick={() => cancelSettlement(getValues())}
            >
              정산 확정 취소
            </Button>
            <Button
              type="submit"
              variant="contained"
              fullWidth
              sx={{ color: "white" }}
              disabled={isConfirm === "확정"}
            >
              정산 확정
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};
export default SettlementConfirmModal;
