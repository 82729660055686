import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

interface Props {
  isOpen: boolean;
  memo: string;
  onClose: () => void;
}

const ReadMemoModal = ({ memo, isOpen, onClose }: Props) => {
  return (
    <>
      <Dialog open={isOpen} fullWidth maxWidth="xs">
        <DialogTitle>사유 확인</DialogTitle>

        <IconButton
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 12
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent
          sx={{
            border: "1px solid #999",
            borderRadius: 1,
            p: 2,
            m: 3,
            mt: 0
          }}
        >
          {memo ? memo : "내용이 없습니다."}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ReadMemoModal;
