import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import { diffJson } from "diff";

interface Props {
  beforeData: string;
  afterData: string;
  isOpen: boolean;
  closeModal: () => void;
  methodType: string;
}

const DetailHistoryModal = ({
  beforeData,
  afterData,
  isOpen,
  methodType,
  closeModal
}: Props) => {
  // ===================================================================================================================
  // JSON 형식으로 파싱 후 특정 필드 제거 (createdAt, updatedAt)
  // fields: 제거할 필드명들의 배열
  // ===================================================================================================================
  const removeFields = (data: any, fields: string[]) => {
    // data가 객체 타입인지 확인
    if (typeof data === "object" && data !== null) {
      for (const field of fields) {
        delete data[field];
      }
      // 재귀적으로 객체 내의 모든 필드에 대해 필드 제거를 수행
      // data 객체의 모든 필드명을 순회한다
      for (const key in data) {
        if (typeof data[key] === "object") {
          removeFields(data[key], fields);
        }
      }
    }
  };

  const formatJson = (data: string) => {
    if (!data) return ""; // 데이터가 없을 때 빈 문자열 반환
    try {
      const json = JSON.parse(data);
      // createdAt과 updatedAt 필드 제거
      removeFields(json, ["createdAt", "updatedAt"]);
      return JSON.stringify(json, null, 2);
    } catch (e) {
      console.error("Invalid JSON data:", e);
      return data;
    }
  };

  // ===================================================================================================================
  // 변경전, 후 문자열 비교
  // ===================================================================================================================
  // JSON 비교 후 차이점 강조
  const highlightDifferences = (before: string, after: string) => {
    if (!before || !after) return null;

    try {
      const beforeJson = formatJson(before);
      const afterJson = formatJson(after);

      const diff = diffJson(beforeJson, afterJson);

      return diff.map((part: any, index: any) => {
        const color = part.added ? "red" : part.removed ? "green" : "";
        return (
          <span key={index} style={{ color }}>
            {part.value}
          </span>
        );
      });
    } catch (e) {
      console.error("Error parsing JSON data:", e);
      return null;
    }
  };

  const differences = highlightDifferences(beforeData, afterData);

  return (
    <>
      <Dialog open={isOpen} fullWidth maxWidth="md">
        <DialogTitle
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Typography variant="h6">이력 상세 정보</Typography>
          <Stack direction={"row"} alignItems={"center"} gap={2}>
            <Typography>변경 사항 없음: 검은색</Typography>
            <Typography>
              변경전: <span style={{ color: "green" }}>초록색</span>
            </Typography>
            <Typography>
              변경후: <span style={{ color: "red" }}>빨간색</span>
            </Typography>
            <IconButton
              aria-label="close"
              onClick={() => {
                closeModal?.();
              }}
            >
              <CloseIcon />
            </IconButton>
          </Stack>
        </DialogTitle>

        <DialogContent dividers>
          <Box
            sx={{
              border: "1px solid #ddd",
              borderRadius: 2,
              p: 2,
              backgroundColor: "#f5f5f5",
              overflow: "auto"
            }}
          >
            <Typography variant="h6" gutterBottom>
              변경 내역 {methodType === "CREATE" && ": 생성된 데이터 입니다."}
              {methodType === "DELETE" && ": 삭제된 데이터 입니다."}
            </Typography>
            <Typography variant="body2" component="pre">
              {afterData
                ? differences
                : methodType === "DELETE" && formatJson(beforeData)}
              {methodType === "CREATE" && formatJson(afterData)}
            </Typography>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default DetailHistoryModal;
