import CloseIcon from "@mui/icons-material/Close";
import MuiLoadingButton from "@mui/lab/LoadingButton";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import koLocale from "date-fns/locale/ko";
import { Controller, useForm } from "react-hook-form";
import { useSetRecoilState } from "recoil";
import { getDate } from "../../libs/get-date";
import { getToday } from "../../libs/get-today";
import isModalOpenAtom from "../../recoil/isModalOpen";

interface Props {
  closeModal?: () => void;
  onButtonSubmit: (paidOutDate: string) => void;
  loading: boolean;
}

interface CalendarForm {
  paidOutDate: string;
}

const SettlementCalendarModal = ({
  closeModal,
  onButtonSubmit,
  loading = false
}: Props) => {
  const setIsModalOpen = useSetRecoilState(isModalOpenAtom);
  const { handleSubmit, control } = useForm<CalendarForm>({
    defaultValues: {
      paidOutDate: getToday()
    }
  });
  const onValid = (data: CalendarForm) => {
    const isInvalidData = data.paidOutDate?.includes("NaN");
    if (isInvalidData) {
      setIsModalOpen({
        value: true,
        position: "top",
        alertSeverity: "error",
        message: "정산 지급 일자를 확인해주세요."
      });
      return;
    }
    onButtonSubmit(data.paidOutDate);
    // closeModal?.();
  };
  return (
    <Dialog open={true} fullWidth maxWidth="xs">
      <DialogTitle>정산 지급</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={() => {
          closeModal?.();
        }}
        sx={{
          position: "absolute",
          right: 8,
          top: 12
        }}
      >
        <CloseIcon />
      </IconButton>
      <form onSubmit={handleSubmit(onValid)}>
        <DialogContent>
          <LocalizationProvider
            adapterLocale={koLocale}
            dateAdapter={AdapterDateFns}
          >
            <Controller
              name="paidOutDate"
              control={control}
              render={({ field: { onChange, value } }) => (
                <DatePicker
                  label="지급일"
                  inputFormat="yyyy-MM-dd"
                  value={value}
                  onChange={(value) => onChange(getDate(value))}
                  renderInput={(param) => (
                    <TextField
                      fullWidth
                      margin="dense"
                      size="small"
                      required
                      variant="standard"
                      {...param}
                    />
                  )}
                />
              )}
            />
          </LocalizationProvider>
        </DialogContent>
        <DialogActions>
          <MuiLoadingButton
            type="submit"
            loading={loading}
            variant="contained"
            fullWidth
            sx={{
              color: "white"
            }}
          >
            지급하기
          </MuiLoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default SettlementCalendarModal;
