export interface FileUploadForm {
  businessLicense?: File;
  bankBookCopy?: File;
  sealCertificate?: File;
  idCardFront?: File;
  idCardBack?: File;
  gradeConfirmation?: File;
  corporateRegistration?: File;
  actualOwnerConfirmation?: File;
  corporateSealCertificate?: File;
}

export interface UploadedFile {
  fileName: string;
  filePath: string;
  savedFileName: string;
  type: string;
  id?: string;
}

// =====================================================================================================================
// 개인/법인에 따라 업로드해야 할 파일 타입을 매핑하는 객체 반환
// =====================================================================================================================
// 개인 사업자 파일 등록
export const getFileTypeMapping = (
  businessType: string
): { [key: string]: string } => {
  const fileTypeMappingIndividual: { [key: string]: string } = {
    businessLicense: "사업자등록증",
    bankBookCopy: "통장사본",
    sealCertificate: "인감증명서",
    idCardFront: "신분증_앞",
    idCardBack: "신분증_뒤",
    gradeConfirmation: "등급확인서"
  };

  // 법인 사업자 파일 등록
  const fileTypeMappingCorporation: { [key: string]: string } = {
    businessLicense: "사업자등록증",
    bankBookCopy: "통장사본",
    corporateSealCertificate: "법인인감증명서",
    corporateRegistration: "등기사항전부증명서",
    actualOwnerConfirmation: "실제소유자확인서",
    idCardFront: "신분증_앞",
    idCardBack: "신분증_뒤",
    사용인감계: "인감증명서"
  };

  return businessType === "개인"
    ? fileTypeMappingIndividual
    : fileTypeMappingCorporation;
};

// =====================================================================================================================
// 개인/법인에 따라 업로드해야 할 파일 타입을 매핑하는 객체 반환
// fileMutate: 파일 업로드 함수, file과 fileType을 받아 파일을 업로드한 후 Promise를 반환
// =====================================================================================================================
// 파일 업로드 함수
export const uploadFiles = async (
  fileData: FileUploadForm,
  businessType: string,
  fileMutate: (data: { file: File; fileType: string }) => Promise<any>
): Promise<UploadedFile[]> => {

  // 현재 사업자 유형에 맞는 파일 타입 매핑 객체를 가져옴
  const fileTypeMapping = getFileTypeMapping(businessType);

  // Object.entries(fileData)로 fileData 객체의 각 파일들을 순회한다
  // 각 파일마다 fileMutate를 사용하여 파일을 서버에 업로드한다
  // 업로드할 때 파일의 필드명(key)과 fileTypeMapping 객체에서 매핑된 파일 타입을 함께 전송한다
  const uploadResults = await Promise.all(
    Object.entries(fileData).map(async ([key, file]) => {
      if (file) {
        const result = await fileMutate({
          file,
          fileType: fileTypeMapping[key]
        });
        // 업로드 결과가 유효한 경우 반환
        if (result && result.data && result.data.content) {
          return {
            fileName: result.data.content.fileName,
            filePath: result.data.content.filePath,
            savedFileName: result.data.content.savedFileName,
            type: fileTypeMapping[key] // 파일 타입은 직접 설정
          };
        }
      }
      return null; // 파일이 없거나 업로드 결과가 유효하지 않으면 null 반환
    })
  );

  // 업로드 결과 중 result가 null이 아닌 파일들만 필터링한다
  // 이 결과를 UploadedFile[] 타입으로 변환해 반환한다
  return uploadResults.filter((result) => result !== null) as UploadedFile[];
};
