import React, { useState } from "react";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import KeyboardArrowRightRoundedIcon from "@mui/icons-material/KeyboardArrowRightRounded";
import { StepData } from "../../api/MerchantRegistration/types";
import EPaymentService from "../TermsAndConditions/EPaymentService";
import PrivacyPolicy from "../TermsAndConditions/PrivacyPolicy";
import Box from "@mui/material/Box";
import PrivacyPolicyOthers from "../TermsAndConditions/PrivacyPolicyOthers";
import FinancialTransaction from "../TermsAndConditions/FinancialTransaction";

interface PrivacyPolicyProps {
  nextEvent: ({ currentStep }: StepData) => void;
}

const TermsAndConditionsPage = ({ nextEvent }: PrivacyPolicyProps) => {
  // =======================================================================================
  // 스테이트
  // =======================================================================================
  const [expanded, setExpanded] = useState<string | false>(false);

  // 체크박스가 체크되었는지 확인
  const [checkedButtons, setCheckedButtons] = useState([""]);

  // checked일 경우 checkedButtons에 id를 추가, check가 해제되었을 경우 checkedButtons배열 형태에서 id 삭제
  const checkedHandler = (checked: any, id: any) => {
    if (checked) {
      setCheckedButtons([...checkedButtons, id]);
    } else {
      setCheckedButtons(checkedButtons.filter((button) => button !== id));
    }
  };
  const isAllChecked = checkedButtons.length === 5;
  const disabled = !isAllChecked;

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  // =======================================================================================
  //  다음페이지 이동
  // =======================================================================================
  const nextStep = () => {
    nextEvent({ currentStep: 1 });
  };

  return (
    <>
      <Typography
        sx={{
          fontWeight: "bold",
          mb: 1
        }}
      >
        약관 동의 페이지
      </Typography>
      <Paper
        sx={{
          width: "100%",
          p: 3,
          border: "1px solid #F2F3F5",
          borderRadius: 3
        }}
      >
        <Accordion
          expanded={expanded === "전자결제약관"}
          onChange={handleChange("전자결제약관")}
          sx={{ border: "1px solid #F2F3F5" }}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <FormControlLabel
              onClick={(event) => event.stopPropagation()}
              onFocus={(event) => event.stopPropagation()}
              control={
                <Checkbox
                  id="전자결제약관"
                  //현재 타켓이 체크되었는지를 확인하는 파라미터 'e' checkedButtons에 check가 있으면 true 없으면 false
                  onChange={(e) => {
                    checkedHandler(e.currentTarget.checked, "전자결제약관");
                  }}
                  checked={
                    checkedButtons.includes("전자결제약관") ? true : false
                  }
                />
              }
              label="전자결제서비스 이용약관"
            />
          </AccordionSummary>
          <AccordionDetails>
            <Box
              sx={{
                height: 300,
                overflow: "hidden",
                overflowY: "scroll",
                background: "#f2f2f2",
                p: 3,
                borderRadius: 2
              }}
            >
              <EPaymentService />
            </Box>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "개인정보약관"}
          onChange={handleChange("개인정보약관")}
          sx={{ border: "1px solid #F2F3F5" }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2bh-content"
          >
            <FormControlLabel
              onClick={(event) => event.stopPropagation()}
              onFocus={(event) => event.stopPropagation()}
              control={
                <Checkbox
                  id="개인정보약관"
                  onChange={(e) => {
                    checkedHandler(e.currentTarget.checked, "개인정보약관");
                  }}
                  checked={
                    checkedButtons.includes("개인정보약관") ? true : false
                  }
                />
              }
              label="개인정보 수집 및 이용동의"
            />
          </AccordionSummary>
          <AccordionDetails>
            <Box
              sx={{
                height: 300,
                overflow: "hidden",
                overflowY: "scroll",
                background: "#f2f2f2",
                p: 3,
                borderRadius: 2
              }}
            >
              <PrivacyPolicy />
            </Box>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "제3자제공약관"}
          onChange={handleChange("제3자제공약관")}
          sx={{ border: "1px solid #F2F3F5" }}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <FormControlLabel
              onClick={(event) => event.stopPropagation()}
              onFocus={(event) => event.stopPropagation()}
              control={
                <Checkbox
                  id="제3자제공약관"
                  onChange={(e) => {
                    checkedHandler(e.currentTarget.checked, "제3자제공약관");
                  }}
                  checked={
                    checkedButtons.includes("제3자제공약관") ? true : false
                  }
                />
              }
              label="개인정보 제3자 제공안내 동의"
            />
          </AccordionSummary>
          <AccordionDetails>
            <Box
              sx={{
                height: 300,
                overflow: "hidden",
                overflowY: "scroll",
                background: "#f2f2f2",
                p: 3,
                borderRadius: 2
              }}
            >
              <PrivacyPolicyOthers />
            </Box>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "금융거래약관"}
          onChange={handleChange("금융거래약관")}
          sx={{ border: "1px solid #F2F3F5" }}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <FormControlLabel
              onClick={(event) => event.stopPropagation()}
              onFocus={(event) => event.stopPropagation()}
              control={
                <Checkbox
                  id="금융거래약관"
                  onChange={(e) => {
                    checkedHandler(e.currentTarget.checked, "금융거래약관");
                  }}
                  checked={
                    checkedButtons.includes("금융거래약관") ? true : false
                  }
                />
              }
              label="특정 금융거래정보의 보고 및 이용 등에 관한 법률에 따른 개인정보 수집 이용 내역"
            />
          </AccordionSummary>
          <AccordionDetails>
            <Box
              sx={{
                height: 300,
                overflow: "hidden",
                overflowY: "scroll",
                background: "#f2f2f2",
                p: 3,
                borderRadius: 2
              }}
            >
              <FinancialTransaction />
            </Box>
          </AccordionDetails>
        </Accordion>

        <br />
        <Button
          variant="outlined"
          onClick={nextStep}
          type="submit"
          disabled={disabled}
        >
          다음
          <KeyboardArrowRightRoundedIcon />
        </Button>
      </Paper>
    </>
  );
};
export default TermsAndConditionsPage;
