import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import LogoImage from "../../images/logo_contract.png";
import { grey } from "@mui/material/colors";
import Divider from "@mui/material/Divider";
import Fonts from "./Fonts";
import { MerchantsDetail } from "../../api/Merchants/types";
import { addCommas } from "../../libs/thousands-commas";

interface PageProps {
  data?: MerchantsDetail;
}

const Page2 = ({ data }: PageProps) => {
  return (
    <Fonts>
      <Box
        sx={{
          width: "210mm",
          height: "297mm",
          backgroundColor: "white",
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <Card
          sx={{
            width: "195mm",
            height: "282mm",
            backgroundColor: "white",
            border: "1px solid black"
          }}
        >
          <Stack direction={"column"}>
            {/* 머릿글 */}
            <Stack
              direction={"row"}
              justifyContent={"space-between"}
              sx={{
                width: "100%",
                height: "15mm"
              }}
            >
              <Stack
                justifyContent={"center"}
                alignItems={"center"}
                sx={{
                  width: "120px"
                }}
              >
                {/* <Box component={"img"} src={LogoImage}></Box> */}
              </Stack>
              <Stack
                justifyContent={"center"}
                alignItems={"center"}
                sx={{ fontSize: "22px", fontWeight: 700 }}
              >
                오직페이 서비스 이용 계약서
              </Stack>
              <Stack
                justifyContent={"center"}
                alignItems={"center"}
                sx={{
                  width: "35mm",
                  color: "white",
                  fontSize: "16px"
                }}
              >
                <Box component={"img"} src={LogoImage}></Box>
              </Stack>
            </Stack>
            <Divider sx={{ border: "2px solid #a29fb0", mx: 1 }}></Divider>
            {/* 본문 */}
            <Stack
              direction={"column"}
              sx={{
                height: "260mm",
                pr: 1
              }}
            >
              {/* 본문 다단 */}
              <Stack
                direction={"row"}
                justifyContent={"space-between"}
                sx={{ height: "260mm" }}
              >
                {/* 본문 왼쪽 */}
                <Box
                  sx={{
                    width: "95mm"
                  }}
                >
                  <Box
                    component={"ol"}
                    sx={{ ml: -2.5, mt: 1, mb: 0 }}
                    start={3}
                  >
                    <Typography
                      component={"li"}
                      sx={{
                        fontSize: "7pt"
                      }}
                    >
                      “회사”는 “고객사”가 파트너가 되는 경우를 대비하여 각
                      신용카드사들과 가맹점 계약을 체결하여야 한다. (단,
                      "고객사"의 판매물품 및 단가의 특성이 각 개별 카드사의
                      등록정책에 맞지 않을 경우, 해당 카드사를 이용하지 못할 수
                      있다.)
                    </Typography>
                    <Typography
                      component={"li"}
                      sx={{
                        fontSize: "7pt"
                      }}
                    >
                      전자결제서비스와 관련하여 “고객사”측에 설치된 전자시스템에
                      대해 기능의 추가 또는 개선이 필요하다고 판단되는 경우
                      “회사”는 “고객사”에게 유선,서면 및 이메일 공지 등으로
                      기능을 추가하거나 개선한다.
                    </Typography>
                    <Typography
                      component={"li"}
                      sx={{
                        fontSize: "7pt"
                      }}
                    >
                      “회사”는 전자결제서비스를 연중무휴 24시간 제공함을
                      원칙으로 한다. 다만, 동 서비스 관련 시스템의 정기점검 또는
                      기술상의 필요 등에 인하여 일시적인 서비스 중단이 불가피한
                      경우에는 “고객사”에게 사전통보하기로 한다. 단, 불가항력
                      또는 천재지변에 의한 경우는 그러하지 아니하다.
                    </Typography>
                  </Box>
                  <Typography
                    sx={{ fontSize: "8px", fontWeight: 700, mt: 1, ml: 1 }}
                  >
                    제 8 조 (거래승인 및 본인인증)
                  </Typography>
                  <Box component={"ol"} sx={{ ml: -2.5, mt: 0, mb: 0 }}>
                    <Typography
                      component={"li"}
                      sx={{
                        fontSize: "7pt"
                      }}
                    >
                      거래승인의 의미는 그 거래에 사용되는 카드의 유효성 여부를
                      확인하는 것이며, 거래 자체의 진위성 및 본인에 의해
                      정당하게 사용되는지 여부에 대한 관리는 “고객사”의 책임으로
                      한다.
                    </Typography>
                    <Typography
                      component={"li"}
                      sx={{
                        fontSize: "7pt"
                      }}
                    >
                      “회사”,”VAN사”,”신용카드사”의 3자간 제휴에 의하여 제공하는
                      신용카드 본인인증 서비스를 “고객사”가 선택 하여 이용할
                      경우 “고객사”는 동 서비스 이용수수료(부칙2)를 부담 하여야
                      한다.
                    </Typography>
                    <Typography
                      component={"li"}
                      sx={{
                        fontSize: "7pt"
                      }}
                    >
                      “회사”는 “고객사”가 보내는 메시지 전문에 의거하여
                      인증여부를 결정하고 처리하도록 하며, 인증에 대한
                      책임소재는 전적으로 “고객사”가 보내는 메시지 전문을
                      기준으로 한다.
                    </Typography>
                    <Typography
                      component={"li"}
                      sx={{
                        fontSize: "7pt"
                      }}
                    >
                      해외카드의 경우 부도율이 높은 관계로 “회사”의 시스템에서는
                      승인거절을 원칙으로 한다.
                    </Typography>
                    <Typography
                      component={"li"}
                      sx={{
                        fontSize: "7pt"
                      }}
                    >
                      "회사"는 "고객사"의 전자결제서비스 신청서를 접수받고
                      "회사"의 심사기준 및 “고객사”의 신용도, 매출유형(판매형태,
                      판매물품 등), 이용자의 이의신청 및 민원발생 빈도,
                      민원해결을 위한 협조성 등 "회사"의 리스크관리운영 방침에
                      따라 “고객사”에 대하여 신용카드(체크카드) 월 승인 한도 및
                      월 승인한도액을 제한하여 지정할 수 있다. (단, "회사"와의
                      계약성립 이후 "고객사"의 매출에 대한 민원발생빈도, 매출
                      유형 (판매형태, 판매물품 등) 등을 기준으로 변동할 수
                      있다.).
                    </Typography>
                    <Typography
                      component={"li"}
                      sx={{
                        fontSize: "7pt"
                      }}
                    >
                      "고객사"의 쇼핑몰에서 발생한 신용카드 매출이
                      신용카드사로부터 비정상거래 및 신용구매분에 대한 카드사의
                      대금연체 등의 사유로 확인되어 승인이 제한된 경우,
                      신용카드사의 승인제한 조치에 따른 모든 손실 발생 부분은
                      "고객사"가 책임을 진다.
                    </Typography>
                  </Box>
                  <Typography
                    sx={{ fontSize: "8px", fontWeight: 700, mt: 1, ml: 1 }}
                  >
                    제 9 조 (거래의 매입요청)
                  </Typography>
                  <Box component={"ol"} sx={{ ml: -2.5, mt: 0, mb: 0 }}>
                    <Typography
                      component={"li"}
                      sx={{
                        fontSize: "7pt"
                      }}
                    >
                      매입요청 : “고객사”는 반드시 이용자가 구입한 상품 및
                      용역이 이상 없이 제공되었는지 여부를 물품배달 확인서 등을
                      통하여 확인 또는 물품배송이 완료된 후 이용자의 구입상품 및
                      용역대금을 “회사”가 지정한 절차와 형식에 따라 “회사”에게
                      통보하며, “회사”는 이를 근거로 해당 카드사에 매입을
                      요청한다.
                    </Typography>
                    <Typography
                      component={"li"}
                      sx={{
                        fontSize: "7pt"
                      }}
                    >
                      요청기간 : 전항의 매입요청은 자동매입을 기본으로 한다.
                    </Typography>
                    <Typography
                      component={"li"}
                      sx={{
                        fontSize: "7pt"
                      }}
                    >
                      매입요청시기 : 매입요청의 접수마감은 부칙2와 같으며,
                      접수마감일 이후 매입요청건은 차기 정산일에 포함되어
                      정산된다. “회사”의 사정으로 매입 요청시기가 변경될 경우
                      “고객사”와의 사전협의를 거쳐 통보한다.
                      <Typography sx={{ fontSize: "7pt" }}>
                        1) 자동매입의 경우는 승인시점에 자동으로 매입요청되며,
                        “회사”의 사정으로 매입 요청시기가 변경될 경우
                        “고객사”에게 통보한다.
                      </Typography>
                    </Typography>
                    <Typography
                      component={"li"}
                      sx={{
                        fontSize: "7pt"
                      }}
                    >
                      취소기간의 확보 : “고객사”의 매출내역에 대하여 매출
                      취소율이 과다하다고 판단되는 경우, “회사”는 “고객사”의
                      매입요청시기를 조정하여 취소기간을 확보할 수 있다.
                    </Typography>
                  </Box>
                  <Typography
                    sx={{ fontSize: "8px", fontWeight: 700, mt: 1, ml: 1 }}
                  >
                    제 10 조 (이용수수료 정산)
                  </Typography>
                  <Box component={"ol"} sx={{ ml: -2.5, mt: 0, mb: 0 }}>
                    <Typography
                      component={"li"}
                      sx={{
                        fontSize: "7pt"
                      }}
                    >
                      정산대상 : 신용카드의 경우 “회사”의 대표가맹점을 통하여
                      거래승인을 받은 후, 매입요청 건을 정산대상으로 한다.
                    </Typography>
                    <Typography
                      component={"li"}
                      sx={{
                        fontSize: "7pt"
                      }}
                    >
                      정산시기 : 매입요청 후 부칙2의 정산주기에 맞게 정산하는
                      것을 원칙으로 하며, 지급일이 휴일 및 공휴일의 경우
                      익영업일에 지급한다.
                    </Typography>
                    <Typography
                      component={"li"}
                      sx={{
                        fontSize: "7pt"
                      }}
                    >
                      정산대금의 지급 : “회사”는 정산대금의 지급 시 신용카드
                      가맹점수수료, 무이자할부수수료, 인증수수료, 은행이체
                      수수료, 전자결제서비스 이용수수료, 연 관리비 및 기타
                      차감금액을 공제한 대금을 “고객사”에게 지급한다.
                    </Typography>
                    <Typography
                      component={"li"}
                      sx={{
                        fontSize: "7pt"
                      }}
                    >
                      세금계산서 발부 : “회사”는 “고객사”의 대금청구에 대하여
                      정해진 주기에 따라 정산하여 “회사”의 수수료를 공제한
                      금액을 “고객사”에게 지급하고, “회사”가 공제한 수수료에
                      대하여 세금계산서를 정산대금 지급 시 “고객사”에게
                      교부한다.
                    </Typography>
                    <Typography
                      component={"li"}
                      sx={{
                        fontSize: "7pt"
                      }}
                    >
                      “고객사”는 “회사”에게 거래취소 분 및 기타 차감금액을 즉시
                      지급하여야 하며,“회사”는 위 거래취소 분 및 기타 차감금액을
                      “고객사”에게 지급하여야 할 대금에서 공제할 수 있다. 단,
                      “고객사”의 매출이 발생하지 않아 차감하지 못할 경우
                      “회사”는 “고객사”에게 과 지급액 및 차감 부족액에 대한
                      반환청구권을 갖는다.
                    </Typography>
                  </Box>
                  <Typography
                    sx={{ fontSize: "8px", fontWeight: 700, mt: 1, ml: 1 }}
                  >
                    제 11 조 (등록비 및 연 관리비 입금 / 환불기준)
                  </Typography>
                </Box>
                {/* 본문 오른쪽 */}
                <Box
                  sx={{
                    width: "95mm"
                  }}
                >
                  <Box component={"ol"} sx={{ ml: -2.5, mt: 1, mb: 0 }}>
                    <Typography
                      component={"li"}
                      sx={{
                        fontSize: "7pt"
                      }}
                    >
                      “고객사”는 “회사”와의 전자결제서비스 계약을 위하여 등록비(
                      <Typography
                        component="strong"
                        sx={{
                          display: "inline",
                          px: 1,
                          fontSize: "9pt",
                          fontWeight: 700,
                          backgroundColor: grey[300]
                        }}
                      >
                        {`₩ ${addCommas(
                          data?.merchantInformation.registrationFee ?? 0
                        )}`}
                      </Typography>
                      )원(VAT포함)을 “회사”에게 입금한다. (등록비 내역은 부칙2
                      참조).
                    </Typography>
                    <Typography
                      component={"li"}
                      sx={{
                        fontSize: "7pt"
                      }}
                    >
                      “회사”는 원활한 전자결제서비스 제공을 위하여 각종 서비스
                      개발 및 유지 보수 관리비용으로 연 관리비를 “고객사”에게
                      청구한다. (초기 전자결제서비스 개시일로부터 1년간 선불(
                      <Typography
                        component="strong"
                        sx={{
                          display: "inline",
                          px: 1,
                          fontSize: "9pt",
                          fontWeight: 700,
                          backgroundColor: grey[300]
                        }}
                      >
                        {`₩ ${addCommas(data?.annualFee[0].price ?? 0)}`}
                      </Typography>
                      )원 [VAT포함])
                    </Typography>
                    <Typography
                      component={"li"}
                      sx={{
                        fontSize: "7pt"
                      }}
                    >
                      기 납부된 등록비 및 연 관리비를 단순변심 등 “고객사”의
                      귀책사유로는 환불이 불가능 하다.
                    </Typography>
                    <Typography
                      component={"li"}
                      sx={{
                        fontSize: "7pt"
                      }}
                    >
                      연회비 청구는 “고객사”의 결제대금에서 차감 정산하며,
                      “고객사”의 매출이 3개월이상 발생치 않아 연회비 차감정산이
                      불가할 경우 자동으로 계약은 해지된다. (단, 최초 가입시
                      카드결제나 기타 수단으로 선 입금한 경우는 제외한다.)
                    </Typography>
                  </Box>
                  <Typography
                    sx={{ fontSize: "8px", fontWeight: 700, mt: 1, ml: 1 }}
                  >
                    제 12 조 (이용자의 구매취소 요청 및 민원의 처리)
                  </Typography>
                  <Box component={"ol"} sx={{ ml: -2.5, mt: 0, mb: 0 }}>
                    <Typography
                      component={"li"}
                      sx={{
                        fontSize: "7pt"
                      }}
                    >
                      "고객사"는 "회사"의 전자결제서비스를 이용중에 발생된
                      거래에 대하여 이용자가 이의신청 및 민원을 제기하는 경우
                      "고객사"의 책임하에 제반문제를 성실히 해결하여야 하며
                      "회사"는 문제해결 노력에 최대한 협조한다.
                    </Typography>
                    <Typography
                      component={"li"}
                      sx={{
                        fontSize: "7pt"
                      }}
                    >
                      이용자와 관련기관(금융기관 등)의 이의신청 및 민원발생 시
                      "고객사"와 "회사"는 상호 확보한 거래내역 관련 정보를
                      "신용정보의 이용 및 보호에 관한 법률"에 위배되지 않는 범위
                      내에서 상호 제출 요구일로부터 제 3 영업일 이내에
                      상대방에게 서면 또는 유선으로 제공, 통지하여야 한다.
                    </Typography>
                    <Typography
                      component={"li"}
                      sx={{
                        fontSize: "7pt"
                      }}
                    >
                      관계법령이나 가맹점규약에 의한 정당한 매출취소 사유가
                      발생하여, 이용자가 환불 또는 반품을 요구할 경우 ”고객사”는
                      당해 이용자에게 구매시점 또는 서비스종료시점의 금액을
                      매출취소 해준다. 이때 수수료가 발생하는 경우 “고객사”에서
                      부담한다.
                    </Typography>
                    <Typography
                      component={"li"}
                      sx={{
                        fontSize: "7pt"
                      }}
                    >
                      정상적으로 배송 완료된 상품에 대해 이용자가 매출 취소를
                      요구하는 경우, “고객사”는 회원의 취소요구 사유가
                      정당한지를 판단한 후 거래승인의 취소 여부를 결정할 수
                      있다.
                    </Typography>
                    <Typography
                      component={"li"}
                      sx={{
                        fontSize: "7pt"
                      }}
                    >
                      이용자의 교환 또는 환불요구에 대해 “고객사”는
                      접수시점으로부터 1영업일이내에 교환 또는 환불여부를
                      고객에게 통지 하여야 한다.
                    </Typography>
                    <Typography
                      component={"li"}
                      sx={{
                        fontSize: "7pt"
                      }}
                    >
                      본 계약이 종료된 이후에 접수되는 민원 건이라 하더라도 해당
                      민원건의 매출발생일이 계약기간 내에 발생한 경우라면
                      전적으로 “고객사”가 책임지고 처리토록 한다.
                    </Typography>
                    <Typography
                      component={"li"}
                      sx={{
                        fontSize: "7pt"
                      }}
                    >
                      "고객사"는 "회사"의 결제시스템을 이용하여 이루어진
                      이용자와의 거래에 대해 반품처리 또는 비정상 거래의 사유로
                      인하여 이용자 또는 "회사"의 정당한 취소 요구가 있을 시에는
                      해당 거래의 취소 의무와 취소건에 대한 대금의 환불 또는
                      상환의 책임이 있다.
                    </Typography>
                  </Box>
                  <Typography
                    sx={{ fontSize: "8px", fontWeight: 700, mt: 1, ml: 1 }}
                  >
                    제 13 조 (비정상 및 부정거래 사고에 대한 처리)
                  </Typography>
                  <Box component={"ol"} sx={{ ml: -2.5, mt: 0, mb: 0 }}>
                    <Typography
                      component={"li"}
                      sx={{
                        fontSize: "7pt"
                      }}
                    >
                      부정한 또는 불법적 거래로 인한 매출(현금성 대출, 본인
                      미사용건[제3자도용,사고]등)에 대하여 “회사”는 “고객사”에게
                      대금정산 의무를 지지 않으며 만약 “고객사”에게 이미 지급된
                      경우에는 사후에 차감정산 또는 반환 청구권이 있다.
                    </Typography>
                    <Typography
                      component={"li"}
                      sx={{
                        fontSize: "7pt"
                      }}
                    >
                      "회사"의 결제시스템을 이용하여 발생한 거래 가운데
                      "고객사"가 정상적인 거래임을 입증하지 못한 경우, 또는
                      이용자가 불법적인 거래를 목적으로 발생시킨 비정상거래에
                      대하여 "고객사"는 "회사" 또는 관련기관(금융기관등)
                      으로부터 서면, 이메일 또는 유선으로 확인 요청이 있을 경우
                      거래내역 관련 자료와 함께 제6조 8항에 명시한 제반
                      증빙자료(배송장, 주문내역, 수령증 등)를 요청일로부터 제 3
                      영업일 이내에 제출하여야 한다.
                    </Typography>
                    <Typography
                      component={"li"}
                      sx={{
                        fontSize: "7pt"
                      }}
                    >
                      "회사" 또는 관련기관(금융기관 등)에서 비정상 거래로 확인된
                      해당 거래의 취소 처리를 요청할 경우 "고객사"는 즉시
                      취소처리를 하여야 하며, “고객사”의 처리가 7일동안 지연될
                      경우 "회사"는 해당 거래 내역을 임의로 취소할 수 있다.
                    </Typography>
                    <Typography
                      component={"li"}
                      sx={{
                        fontSize: "7pt"
                      }}
                    >
                      "고객사"의 사이트에서 발생한 결제수단 별 거래 중
                      비정상거래로 인하여 관련기관(금융기관 등)에서 "회사"의
                      정산대금 가운데 일부 또는 전부를 지급보류 조치하거나 또는
                      그에 상응하는 제반조치를 취할 것이 예상된다고 "회사"가
                      판단한 경우, "회사"는 "고객사"의 정산대금 중 전부 또는
                      일부를 지급보류 조치할 수 있으며, 해당 금액이 부족할 경우
                      "고객사"는 "회사"가 지정, 요청한 방법에 따라 "회사"의
                      손실금액을 보전해주어야 한다.
                    </Typography>
                  </Box>
                  <Typography
                    sx={{ fontSize: "8px", fontWeight: 700, mt: 1, ml: 1 }}
                  >
                    제 14 조 (계약담보)
                  </Typography>
                  <Box component={"ol"} sx={{ ml: -2.5, mt: 0, mb: 0 }}>
                    <Typography
                      component={"li"}
                      sx={{
                        fontSize: "7pt"
                      }}
                    >
                      “고객사”의 매출이 현금성 대출로 판명되거나 제16조 이용자의
                      이의제기가 빈번히 발생(민원발생)하는 경우 또는 제5조와
                      제16조의 (모든 조항 해당) 비정상적인 해당매출이 발생된
                      경우 해당매출의 전부 또는 일부를 “회사”가 대금 지급보류를
                      통하여 “회사”의 “손실보증금”으로 처리 할 수 있으며 만일
                      해당매출이 “고객사”에게 이미 지급된 경우에는 사후에
                      해당금액만큼 차감정산 또는 반환 청구권이 있다.
                    </Typography>
                    <Typography
                      component={"li"}
                      sx={{
                        fontSize: "7pt"
                      }}
                    >
                      “고객사”는 최초 계약의 담보로 “회사”가 피보험자로 되어있는
                      보증보험 증권증서 (
                      <Typography
                        sx={{
                          display: "inline",
                          px: 1,
                          fontSize: "9pt",
                          fontWeight: 700,
                          backgroundColor: grey[300]
                        }}
                      >
                        {`₩ ${addCommas(data?.deposit ?? 0)}`}
                      </Typography>
                      )원을 보증보험사에서 발부 받아 “회사”에게 제출한다.
                    </Typography>
                  </Box>
                </Box>
              </Stack>
            </Stack>
            {/* ============================== 바닥글 ============================== */}
            <Divider
              sx={{
                border: "none",
                borderTop: "1px dotted",
                borderColor: grey[700],
                mx: 1
              }}
            ></Divider>
            <Stack
              direction={"row"}
              justifyContent={"space-between"}
              sx={{ height: "7mm", color: grey[400], mx: 3, mt: 0 }}
            >
              <Typography sx={{ fontSize: "7pt" }}>
                국내 최고의 통합 결제 서비스 OZICPAY
              </Typography>
              <Typography sx={{ fontSize: "7pt" }}>페이지 2 / 9</Typography>
            </Stack>
          </Stack>
        </Card>
      </Box>
    </Fonts>
  );
};

export default Page2;
