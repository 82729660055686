import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import LogoImage from "../../images/logo_contract.png";
import { grey } from "@mui/material/colors";
import Divider from "@mui/material/Divider";
import Fonts from "./Fonts";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import { MerchantsDetail } from "../../api/Merchants/types";

interface PageProps {
  data?: MerchantsDetail;
}

const Page4 = ({ data }: PageProps) => {
  const contractManager = data?.merchantInChargeInformation.find(
    (manager) => manager.role === "계약"
  );

  return (
    <Fonts>
      <Box
        sx={{
          width: "210mm",
          height: "297mm",
          backgroundColor: "white",
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <Card
          sx={{
            width: "195mm",
            height: "282mm",
            backgroundColor: "white",
            border: "1px solid black"
          }}
        >
          <Stack direction={"column"}>
            {/* 머릿글 */}
            <Stack
              direction={"row"}
              justifyContent={"space-between"}
              sx={{
                width: "100%",
                height: "15mm"
              }}
            >
              <Stack
                justifyContent={"center"}
                alignItems={"center"}
                sx={{
                  width: "120px"
                }}
              >
                {/* <Box component={"img"} src={LogoImage}></Box> */}
              </Stack>
              <Stack
                justifyContent={"center"}
                alignItems={"center"}
                sx={{ fontSize: "18px", fontWeight: 700, color: "#a29fb0" }}
              >
                [부칙 1] 개인정보 수집 및 이용동의서
              </Stack>
              <Stack
                justifyContent={"center"}
                alignItems={"center"}
                sx={{
                  width: "35mm",
                  color: "white",
                  fontSize: "16px"
                }}
              >
                <Box component={"img"} src={LogoImage}></Box>
              </Stack>
            </Stack>
            <Divider sx={{ border: "2px solid #a29fb0", mx: 1 }}></Divider>
            {/* 본문 */}
            <Stack
              direction={"column"}
              sx={{
                height: "260mm"
              }}
            >
              <Typography sx={{ mt: 2, mx: 1, fontSize: "8pt" }}>
                주식회사 옵타움플랫폼(이하 "회사")은{" "}
                <Typography
                  component="strong"
                  sx={{
                    display: "inline",
                    fontSize: "8pt",
                    backgroundColor: grey[300],
                    px: 1
                  }}
                >
                  {data?.mallName}
                </Typography>
                (이하 “고객사”)의 개인정보를 중요시하며 개인정보 보호법,
                정보통신망 이용촉진 및 정보보호 등에 관한 법률 등 관계법령을
                준수하고 있습니다. “회사”는 개인정보처리방침을 통하여 “고객사”
                의 개인정보가 어떠한 용도와 방식으로 이용되고 있으며
                개인정보보호를 위해 어떠한 조치가 취해지고 있는지 알려드립니다.
              </Typography>
              <Box component={"ol"}>
                <Box
                  component={"li"}
                  sx={{
                    height: "140mm",
                    fontSize: "8pt",
                    ml: -3,
                    mt: -1,
                    mr: 1
                  }}
                >
                  {/* 소제목 */}
                  <Typography sx={{ fontSize: "8pt", fontWeight: 700 }}>
                    개인정보 수집 및 이용
                  </Typography>
                  {/* 표 */}
                  <Stack direction={"column"}>
                    <Stack
                      direction={"row"}
                      sx={{
                        borderTop: "1px dotted black"
                      }}
                    >
                      <Box
                        sx={{
                          width: "50mm",
                          borderRight: "1px dotted black",
                          p: 0.5,
                          display: "flex",
                          alignItems: "center"
                        }}
                      >
                        <Typography sx={{ fontWeight: 700 }}>
                          1) 개인정보의 수집∙이용목적
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          width: "100%",
                          p: 0.5
                        }}
                      >
                        <Typography>
                          “회사”는 다음과 같은 목적으로 ”고객사”의 개인정보
                          항목을 수집 및 이용하고 있습니다.
                        </Typography>
                        <Box component={"ul"} sx={{ ml: -1 }}>
                          <Typography component={"li"}>
                            계약의 성립, 유지 및 종료를 위한 본인식별 및
                            실명확인
                          </Typography>
                          <Typography component={"li"}>
                            원활한 서비스 이용을 위한 결제기관에의 정보제공
                          </Typography>
                          <Typography component={"li"}>
                            서비스의 추가와 변경내역에 대한 공지, 고객민원 및
                            문의에 대한 대응
                          </Typography>
                          <Typography component={"li"}>
                            부정 이용방지 및 비인가 사용방지
                          </Typography>
                          <Typography component={"li"}>
                            입금 알림 서비스 제공 및 월별 정산내역 안내
                          </Typography>
                        </Box>
                      </Box>
                    </Stack>
                    <Stack
                      direction={"row"}
                      sx={{
                        borderTop: "1px dotted black"
                      }}
                    >
                      <Box
                        sx={{
                          width: "50mm",
                          borderRight: "1px dotted black",
                          p: 0.5,
                          display: "flex",
                          alignItems: "center"
                        }}
                      >
                        <Typography sx={{ fontWeight: 700 }}>
                          2) 수집하는 개인정보의 항목
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          width: "100%",
                          p: 0.5
                        }}
                      >
                        <Typography>
                          담당자정보(이름, 이메일 주소, 휴대폰번호), 대금
                          입금계좌정보(은행명, 계좌번호, 예금주), 대표자
                          정보(이름, 국적, 휴대전화번호, 이메일, 생년월일,
                          주민번호)
                        </Typography>
                      </Box>
                    </Stack>
                    <Stack
                      direction={"row"}
                      sx={{
                        borderTop: "1px dotted black",
                        borderBottom: "1px dotted black"
                      }}
                    >
                      <Box
                        sx={{
                          width: "50mm",
                          borderRight: "1px dotted black",
                          p: 0.5,
                          display: "flex",
                          alignItems: "center"
                        }}
                      >
                        <Typography
                          sx={{
                            fontWeight: 700
                          }}
                        >
                          3) 개인정보의 보유·이용기간
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          width: "100%",
                          p: 0.5
                        }}
                      >
                        <Typography>
                          개인정보는 원칙적으로 개인정보의 수집∙이용목적이
                          달성되면 지체 없이 파기 합니다. 단, 전자금융거래법,
                          전자상거래 등에서의 소비자 보호에 관한 법률 등
                          관계법령에 의하여 보존할 필요가 있는 경우 당해
                          법령에서 정한 기간 동안 개인정보를 보존합니다. 이 경우
                          “회사”는 보존하는 정보를 그 보존의 목적으로만
                          이용합니다.
                        </Typography>
                        <Table sx={{ mt: 1 }}>
                          <TableHead>
                            <TableRow>
                              <TableCell
                                sx={{
                                  fontSize: "7pt",
                                  p: 0.35,
                                  textAlign: "center",
                                  borderBottom: "none",
                                  border: "1px dotted black",
                                  backgroundColor: "#e5dfec"
                                }}
                              >
                                내용
                              </TableCell>
                              <TableCell
                                sx={{
                                  fontSize: "7pt",
                                  p: 0.35,
                                  textAlign: "center",
                                  borderBottom: "none",
                                  border: "1px dotted black",
                                  backgroundColor: "#e5dfec"
                                }}
                              >
                                근거법령
                              </TableCell>
                              <TableCell
                                sx={{
                                  fontSize: "7pt",
                                  p: 0.35,
                                  textAlign: "center",
                                  borderBottom: "none",
                                  border: "1px dotted black",
                                  backgroundColor: "#e5dfec"
                                }}
                              >
                                보존기간
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            <TableRow>
                              <TableCell
                                sx={{
                                  fontSize: "9pt",
                                  p: 0.35,
                                  borderBottom: "none",
                                  border: "1px dotted black"
                                }}
                              >
                                계약 또는 청약철회 등에 관한 기록
                              </TableCell>
                              <TableCell
                                rowSpan={4}
                                sx={{
                                  fontSize: "9pt",
                                  p: 0.35,
                                  borderBottom: "none",
                                  border: "1px dotted black"
                                }}
                              >
                                전자상거래 등에서의 소비자보호에 관한 법률
                              </TableCell>
                              <TableCell
                                sx={{
                                  fontSize: "9pt",
                                  p: 0.35,
                                  borderBottom: "none",
                                  border: "1px dotted black",
                                  textAlign: "center"
                                }}
                              >
                                5년
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell
                                sx={{
                                  fontSize: "9pt",
                                  p: 0.35,
                                  borderBottom: "none",
                                  border: "1px dotted black"
                                }}
                              >
                                대금결제 및 재화 등의 공급에 관한 기록
                              </TableCell>
                              <TableCell
                                sx={{
                                  fontSize: "9pt",
                                  p: 0.35,
                                  borderBottom: "none",
                                  border: "1px dotted black",
                                  textAlign: "center"
                                }}
                              >
                                5년
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell
                                sx={{
                                  fontSize: "9pt",
                                  p: 0.35,
                                  borderBottom: "none",
                                  border: "1px dotted black"
                                }}
                              >
                                소비자의 불만 또는 분쟁처리에 관한 기록
                              </TableCell>
                              <TableCell
                                sx={{
                                  fontSize: "9pt",
                                  p: 0.35,
                                  borderBottom: "none",
                                  border: "1px dotted black",
                                  textAlign: "center"
                                }}
                              >
                                3년
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell
                                sx={{
                                  fontSize: "9pt",
                                  p: 0.35,
                                  borderBottom: "none",
                                  border: "1px dotted black"
                                }}
                              >
                                표시, 광고에 관한 기록
                              </TableCell>
                              <TableCell
                                sx={{
                                  fontSize: "9pt",
                                  p: 0.35,
                                  borderBottom: "none",
                                  border: "1px dotted black",
                                  textAlign: "center"
                                }}
                              >
                                6개월
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell
                                sx={{
                                  fontSize: "9pt",
                                  p: 0.35,
                                  borderBottom: "none",
                                  border: "1px dotted black"
                                }}
                              >
                                신용정보 업무처리에 관한 기록
                              </TableCell>
                              <TableCell
                                sx={{
                                  fontSize: "9pt",
                                  p: 0.35,
                                  borderBottom: "none",
                                  border: "1px dotted black"
                                }}
                              >
                                신용정보의 이용 및 보호에 관한 법률
                              </TableCell>
                              <TableCell
                                sx={{
                                  fontSize: "9pt",
                                  p: 0.35,
                                  borderBottom: "none",
                                  border: "1px dotted black",
                                  textAlign: "center"
                                }}
                              >
                                3년
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </Box>
                    </Stack>
                  </Stack>
                  {/* 고지 */}
                  <Typography sx={{ ml: 1, mt: 1 }}>
                    “고객사”는 ”회사”의 개인정보 수집·이용 동의를 거부할 수
                    있습니다. 단, 동의를 거부하는 경우 서비스신청이 정상적으로
                    완료될 수 없음을 알려드립니다.
                  </Typography>
                  {/* 동의 및 서명 */}
                  <Box
                    sx={{ border: "1px dotted black", height: "35mm", mt: 1 }}
                  >
                    <Stack direction={"row"} spacing={1} sx={{ p: 1 }}>
                      <Typography>
                        “고객사”는 위와 같이 개인정보 수집 및 이용에 관해 고지를
                        받았으며 이를 충분히 이해하고 동의합니다.
                      </Typography>
                      <Box>□</Box>
                    </Stack>
                    <Stack direction={"row"} spacing={1} sx={{ px: 1 }}>
                      <Typography>
                        “고객사”는 위와 같이 “고객사”의 담당자 정보를 제공하는데
                        적법한 권한 및 절차를 이행하였음을 이해하고 동의합니다.
                      </Typography>
                      <Box>□</Box>
                    </Stack>
                    <Stack
                      direction={"column"}
                      alignItems={"flex-end"}
                      sx={{ mt: 1 }}
                    >
                      <Stack direction={"row"} sx={{ width: "65mm" }}>
                        <Typography sx={{ width: "12mm" }}>담당자:</Typography>
                        <Typography
                          sx={{
                            backgroundColor: grey[300],
                            textAlign: "center",
                            letterSpacing: 10,
                            width: "40mm",
                            ml: 1.1
                          }}
                        >
                          {contractManager?.name}
                        </Typography>
                        <Typography
                          sx={{
                            backgroundColor: grey[300],
                            textAlign: "center",
                            width: "8mm"
                          }}
                        >
                          (인)
                        </Typography>
                      </Stack>
                      <Typography
                        sx={{ color: grey[500], pr: 1.3, fontSize: "6pt" }}
                      >
                        ※ 담당자 본인서명 필수
                      </Typography>
                      <Stack
                        direction={"row"}
                        sx={{ pt: 1, pr: 1.3, width: "50mmm" }}
                      >
                        <Typography sx={{ width: "15mm" }}>
                          대표(이사):
                        </Typography>
                        <Typography
                          sx={{
                            backgroundColor: grey[300],
                            textAlign: "center",
                            letterSpacing: 10,
                            width: "40mm"
                          }}
                        >
                          {data?.merchantOwnerInformation[0].name}
                        </Typography>
                        <Typography
                          sx={{
                            backgroundColor: grey[300],
                            textAlign: "center",
                            width: "8mm"
                          }}
                        >
                          (인)
                        </Typography>
                      </Stack>
                      <Typography
                        sx={{ color: grey[500], pr: 1.3, fontSize: "6pt" }}
                      >
                        ※ 개인사업자: 대표자 인감 / 법인사업자: 법인 인감 날인
                        필수
                      </Typography>
                    </Stack>
                  </Box>
                </Box>
                <Box
                  component={"li"}
                  sx={{
                    height: "100mm",
                    fontSize: "7pt",
                    ml: -3,
                    mr: 1,
                    mt: 1
                  }}
                >
                  <Typography sx={{ fontWeight: 700 }}>
                    개인정보 제3자 제공 및 위탁
                  </Typography>
                  <Stack
                    direction={"row"}
                    justifyContent={"space-between"}
                    sx={{ height: "30mm" }}
                  >
                    <Stack
                      justifyContent={"center"}
                      sx={{
                        borderTop: "1px dotted black",
                        borderRight: "1px dotted black",
                        width: "40mm",
                        pl: 1
                      }}
                    >
                      <Typography sx={{ fontWeight: 700 }}>
                        1) 개인정보 제3자 제공
                      </Typography>
                    </Stack>
                    <Box
                      sx={{
                        borderTop: "1px dotted black",
                        width: "150mm",
                        p: 0.5
                      }}
                    >
                      <Typography>
                        회사는 “고객사”의 동의 없이 “고객사”의 개인정보를
                        제3자에게 제공하지 않습니다. 회사는 서비스 이행을 위하여
                        개인정보를 다음과 같이 제3자에게 제공하고 있습니다.
                      </Typography>
                      <Stack direction={"row"} sx={{ pt: 0.5 }}>
                        <Typography
                          sx={{
                            backgroundColor: "#e5dfec",
                            borderLeft: "1px dotted black",
                            borderTop: "1px dotted black",
                            width: "28mm",
                            p: 0.5,
                            textAlign: "center"
                          }}
                        >
                          제공목적
                        </Typography>
                        <Typography
                          sx={{
                            backgroundColor: "#e5dfec",
                            borderLeft: "1px dotted black",
                            borderTop: "1px dotted black",
                            width: "45mm",
                            p: 0.5,
                            textAlign: "center"
                          }}
                        >
                          제공받는 자
                        </Typography>
                        <Typography
                          sx={{
                            backgroundColor: "#e5dfec",
                            borderLeft: "1px dotted black",
                            borderTop: "1px dotted black",
                            width: "45mm",
                            p: 0.5,
                            textAlign: "center"
                          }}
                        >
                          제공정보
                        </Typography>
                        <Typography
                          sx={{
                            backgroundColor: "#e5dfec",
                            borderLeft: "1px dotted black",
                            borderTop: "1px dotted black",
                            borderRight: "1px dotted black",
                            width: "28mm",
                            p: 0.5,
                            textAlign: "center"
                          }}
                        >
                          보유 및 이용기간
                        </Typography>
                      </Stack>
                      <Stack direction={"row"}>
                        <Typography
                          sx={{
                            borderLeft: "1px dotted black",
                            borderTop: "1px dotted black",
                            borderBottom: "1px dotted black",
                            width: "28mm",
                            p: 0.5
                          }}
                        >
                          서비스 이용을 위한 결제기관 정보제공
                        </Typography>
                        <Typography
                          sx={{
                            borderLeft: "1px dotted black",
                            borderTop: "1px dotted black",
                            borderBottom: "1px dotted black",
                            width: "45mm",
                            p: 0.5
                          }}
                        >
                          1. 신용카드 : 비씨, KB국민, 삼성, 신한, 롯데, 현대,
                          하나
                        </Typography>
                        <Typography
                          sx={{
                            borderLeft: "1px dotted black",
                            borderTop: "1px dotted black",
                            borderBottom: "1px dotted black",
                            width: "45mm",
                            p: 0.5
                          }}
                        >
                          사업자번호, 상호명, 생년월일, 홈페이지주소, 대표자명,
                          전화번호, 주소
                        </Typography>
                        <Typography
                          sx={{
                            border: "1px dotted black",
                            width: "28mm",
                            p: 0.5,
                            textAlign: "center"
                          }}
                        >
                          서비스 종료 시, 또는 상담 후 폐기
                        </Typography>
                      </Stack>
                    </Box>
                  </Stack>
                  <Stack direction={"row"} sx={{ height: "20mm" }}>
                    <Stack
                      justifyContent={"center"}
                      sx={{
                        borderTop: "1px dotted black",
                        borderRight: "1px dotted black",
                        borderBottom: "1px dotted black",
                        width: "40mm",
                        pl: 1
                      }}
                    >
                      <Typography sx={{ fontWeight: 700 }}>
                        2) 개인정보의 보유·이용기간
                      </Typography>
                    </Stack>
                    <Box
                      sx={{
                        borderTop: "1px dotted black",
                        borderBottom: "1px dotted black",
                        width: "150mm",
                        p: 0.5
                      }}
                    >
                      <Typography>
                        회사는 “고객사”의 개인정보를 원칙적으로 외부에 제공하지
                        않습니다. 다만, 아래의 경우에는 예외로 합니다.
                      </Typography>
                      <Box component={"ul"} sx={{ ml: -1 }}>
                        <Typography component={"li"}>
                          “고객사”들이 사전에 동의한 경우
                        </Typography>
                        <Typography component={"li"}>
                          법령의 규정에 의거하거나, 수사 목적으로 법령에 정해진
                          절차와 방법에 따라 수사기관의 요구가 있는 경우{" "}
                        </Typography>
                        <Typography component={"li"}>
                          도용방지를 위하여 본인확인에 필요한 경우
                        </Typography>
                      </Box>
                    </Box>
                  </Stack>
                  <Typography sx={{ mt: 1 }}>
                    “고객사”는 회사의 개인정보 수집·이용 동의를 거부할 수
                    있습니다. 단, 동의를 거부하는 경우 서비스신청이 정상적으로
                    완료될 수 없음을 알려드립니다
                  </Typography>
                  <Stack
                    sx={{ height: "30mm", border: "1px dotted black", mt: 1 }}
                  >
                    <Stack
                      direction={"row"}
                      alignItems={"center"}
                      sx={{ p: 1 }}
                    >
                      <Typography>
                        “고객사”는 개인정보 및 서비스 이용 관련정보를 회사가
                        위와 같이 제공 및 위탁함에 관하여 고지를 받았으며 이를
                        충분히 이해하고 동의합니다.
                      </Typography>
                      <Typography sx={{ ml: 1, fontSize: "10pt" }}>
                        □
                      </Typography>
                    </Stack>
                    <Stack alignItems={"flex-end"} sx={{ mt: 1 }}>
                      <Stack
                        direction={"row"}
                        sx={{ pt: 1, pr: 1.3, width: "50mmm" }}
                      >
                        <Typography sx={{ width: "15mm" }}>
                          대표(이사):
                        </Typography>
                        <Typography
                          sx={{
                            backgroundColor: grey[300],
                            textAlign: "center",
                            letterSpacing: 10,
                            width: "40mm"
                          }}
                        >
                          {data?.merchantOwnerInformation[0].name}
                        </Typography>
                        <Typography
                          sx={{
                            backgroundColor: grey[300],
                            textAlign: "center",
                            width: "8mm"
                          }}
                        >
                          (인)
                        </Typography>
                      </Stack>
                      <Typography
                        sx={{
                          color: grey[500],
                          pr: 1.3,
                          fontSize: "6pt",
                          mt: 1
                        }}
                      >
                        ※ 개인사업자: 대표자 인감 / 법인사업자: 법인 인감 날인
                        필수
                      </Typography>
                    </Stack>
                  </Stack>
                </Box>
              </Box>
            </Stack>
            {/* ============================== 바닥글 ============================== */}
            <Divider
              sx={{
                border: "none",
                borderTop: "1px dotted",
                borderColor: grey[700],
                mx: 1
              }}
            ></Divider>
            <Stack
              direction={"row"}
              justifyContent={"space-between"}
              sx={{ height: "7mm", color: grey[400], mx: 3, mt: 0 }}
            >
              <Typography sx={{ fontSize: "7pt" }}>
                국내 최고의 통합 결제 서비스 OZICPAY
              </Typography>
              <Typography sx={{ fontSize: "7pt" }}>페이지 4 / 9</Typography>
            </Stack>
          </Stack>
        </Card>
      </Box>
    </Fonts>
  );
};

export default Page4;
