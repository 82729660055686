import { instanceWithAuth } from "../Instance";
import { AdminUserListInfo, AdminUsersListParams, MerchantRegistrationTemp } from "../MerchantRegistration/types";
import { CommonApi } from "../type";

// =======================================================================================
// 어드민 가맹점 임시등록, 조회
// =======================================================================================
/**
 * 가맹점 임시 저장 저장하기
 */
export const postAdminMerchantTemp = async (
  params: MerchantRegistrationTemp
) => {
  const url = "/adminMerchant/temporary";
  const { data } = await instanceWithAuth.post<
    CommonApi<MerchantRegistrationTemp>
  >(url, params);

  return data;
};

// =======================================================================================
// 어드민 가맹점 등록
// =======================================================================================
/**
 * 관리자 가맹점 등록
 */
export const postAdminMerchant = async (params: any) => {
  const axiosConfig = {
    headers: { "Content-Type": "application/json" }
  };
  const { data } = await instanceWithAuth.post(
    "/adminMerchant",
    params,
    axiosConfig
  );
  return data;
};

/**
 * 관리자 가맹등록 사용자 목록 조회
 */
export const getAdminUsersList = async (params: AdminUsersListParams) => {
  const url = "/adminUsers/list";
  const { data } = await instanceWithAuth.get<CommonApi<AdminUserListInfo[]>>(url, {
    params
  });
  return data;
};
