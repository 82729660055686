import CloseIcon from "@mui/icons-material/Close";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { UserMerchantList } from "../../api/Merchants/types";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  data: UserMerchantList[];
}

const UserMerchantListModal = ({ isOpen, onClose, data }: Props) => {
  return (
    <>
      {/* 사용자가 등록한 상점을 확인하는 모달창 */}
      <Dialog open={isOpen} fullWidth maxWidth="sm">
        <DialogTitle>등록된 가맹점</DialogTitle>

        <IconButton
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 12
          }}
        >
          <CloseIcon />
        </IconButton>

        <DialogContent>
          <DialogContentText gutterBottom></DialogContentText>
          <TableContainer
            sx={{
              border: "1px solid #E4E4E7",
              borderRadius: 2,
              overflow: "hidden"
            }}
          >
            <Table>
              <TableHead sx={{ backgroundColor: "#FBFBFB" }}>
                <TableRow>
                  <TableCell align="center"> 가맹점 명</TableCell>
                  <TableCell align="center">가맹 등록일</TableCell>
                  <TableCell align="center">계약 상태</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((m) => (
                  <TableRow
                    key={m.mallId}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell align="center" component="th" scope="row">
                      {m.mallName}
                    </TableCell>
                    <TableCell align="center" component="th" scope="row">
                      {m.createdAt.split(" ").at(0)}
                    </TableCell>
                    <TableCell align="center">{m.status}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default UserMerchantListModal;
