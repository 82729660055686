import { atom } from "recoil";

interface IIsModalOpen {
  value: boolean;
  position: "top" | "bottom";
  alertSeverity: "error" | "success" | "info" | "warning";
  message: string;
}

const isModalOpen = atom<IIsModalOpen>({
  key: "isModalOpen",
  default: {
    value: false,
    position: "top",
    alertSeverity: "success",
    message: ""
  }
});

export default isModalOpen;
