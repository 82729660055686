import RestartAltIcon from "@mui/icons-material/RestartAlt";
import SearchIcon from "@mui/icons-material/Search";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Pagination from "@mui/material/Pagination";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { useQuery } from "@tanstack/react-query";
import koLocale from "date-fns/locale/ko";
import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useSetRecoilState } from "recoil";
import { getMerchantModifyList } from "../../api/ContractManagement";
import { MerchantModifyListData } from "../../api/ContractManagement/types";
import MerchantInfoUpdateModal from "../../components/ClientMerchant/MerchantInfoUpdateModal";
import ClientLayout from "../../components/Layout/Client/Layout";
import LoadingButton from "../../components/LoadingButton";
import Title from "../../components/Title";
import useRenewal from "../../hooks/useRenewal";
import { getDate } from "../../libs/get-date";
import { getToday } from "../../libs/get-today";
import isModalOpenAtom from "../../recoil/isModalOpen";

interface Columns {
  id: string;
  label: string;
}

const columns: Columns[] = [
  { id: "mallName", label: "가맹점 명" },
  { id: "businessName", label: "사업자등록증 상호명" },
  { id: "createdAt", label: "신청일" },
  { id: "status", label: "진행 상태" },
  { id: "note", label: "비고" }
];

interface FormInputs {
  mallName: string;
  startDate: string;
  endDate: string;
  status: string;
}

interface Row extends MerchantModifyListData {
  setIsModalOpen: boolean;
}

const MerchantModifyList = () => {
  const { isRenewalTokenLoading, mutateRenewalToken } = useRenewal();
  // ===================================================================================================================
  // 리코일 스테이트
  // ===================================================================================================================
  const setIsModalOpen = useSetRecoilState(isModalOpenAtom);

  // ===================================================================================================================
  // 컴포넌트 스테이트
  // ===================================================================================================================
  // params 스테이트
  const [params, setParams] = useState<any>({
    mallId: "",
    startDate: getToday(),
    endDate: getToday(),
    status: "",
    page: 0,
    size: 10
  });

  const [selectedData, setSelectedData] = useState<Row>({
    setIsModalOpen: false,
    merchantId: "",
    modifyId: "",
    mallName: "",
    businessName: "",
    createdAt: "",
    status: "",
    note: ""
  });

  // ===================================================================================================================
  // 리액트 훅 폼
  // ===================================================================================================================
  const { register, handleSubmit, control, reset } = useForm<FormInputs>({
    defaultValues: {
      ...params
    }
  });

  // ===================================================================================================================
  // 리액트 쿼리
  // ===================================================================================================================
  // 가맹점 정보 수정 요청 목록 조회
  const { data, isLoading } = useQuery(
    ["/merchants/modifies", params],
    () => getMerchantModifyList(params),
    {
      onSuccess: () => {
        if (isRenewalTokenLoading) return;
        mutateRenewalToken();
      }
    }
  );

  // ===================================================================================================================
  // 정보 수정 관리 모달창
  // ===================================================================================================================
  const handleRowClick = (row: any) => {
    // "신청" 상태인 경우 모달 열기
    if (row.status === "신청") {
      setSelectedData({ ...row, setIsModalOpen: true });
    } else {
      // "신청" 상태가 아닌 경우
      setIsModalOpen({
        value: true,
        position: "top",
        alertSeverity: "error",
        message: "이 항목은 '신청' 상태가 아닙니다."
      });
    }
  };

  const onClose = () => {
    setSelectedData({
      setIsModalOpen: false,
      merchantId: "",
      modifyId: "",
      mallName: "",
      businessName: "",
      createdAt: "",
      status: "",
      note: ""
    });
  };
  // ===================================================================================================================
  // 페이징 처리
  // ===================================================================================================================
  const totalPages = data?.pageable?.totalPages
    ? data?.pageable?.totalPages
    : 0;
  const changePage = (event: React.ChangeEvent<unknown>, page: number) => {
    setParams((prev: any) => ({ ...prev, page: page - 1 }));
  };

  // ===================================================================================================================
  // 가맹 조회 및 초기화
  // ===================================================================================================================
  // 초기화
  const handleReset = () => {
    reset();
  };

  // 조회
  const onClickSubmit = (formData: FormInputs) => {
    if (isLoading) return;
    const isInvalidData =
      formData.startDate?.includes("NaN") || formData.endDate?.includes("NaN");
    const isInvaildPeriod = formData.startDate > formData.endDate;
    if (isInvalidData || isInvaildPeriod) {
      setIsModalOpen({
        value: true,
        position: "top",
        alertSeverity: "error",
        message: "조회 일자를 확인해주세요."
      });
      return;
    }
    setParams((prev: any) => ({
      ...prev,
      ...formData
    }));
  };

  return (
    <>
      <Title title="정보 수정 관리" />
      <ClientLayout>
        <Paper
          sx={{
            p: 3,
            border: "1px solid #F2F3F5",
            borderRadius: 3,
            overflow: "hidden",
            mb: 3
          }}
        >
          <form onSubmit={handleSubmit(onClickSubmit)}>
            <Grid container spacing={2}>
              <LocalizationProvider
                adapterLocale={koLocale}
                dateAdapter={AdapterDateFns}
              >
                <Grid item xs={2}>
                  <Controller
                    name="startDate"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <DatePicker
                        label="조회 시작 날짜"
                        inputFormat="yyyy-MM-dd"
                        value={value}
                        onChange={(value) => onChange(getDate(value))}
                        renderInput={(param) => (
                          <TextField
                            fullWidth
                            size="small"
                            required
                            {...param}
                          />
                        )}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={2}>
                  <Controller
                    name="endDate"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <DatePicker
                        label="조회 종료 날짜"
                        inputFormat="yyyy-MM-dd"
                        value={value}
                        onChange={(value) => onChange(getDate(value))}
                        renderInput={(param) => (
                          <TextField
                            fullWidth
                            size="small"
                            required
                            {...param}
                          />
                        )}
                      />
                    )}
                  />
                </Grid>
              </LocalizationProvider>
              <Grid item xs={12} md={2}>
                <TextField
                  id="input-mallId"
                  label="가맹점명"
                  fullWidth
                  size="small"
                  {...register("mallName")}
                />
              </Grid>
              <Grid item xs={2}>
                <Controller
                  name="status"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      id="input-status"
                      label="진행 상태"
                      fullWidth
                      size="small"
                      select
                      value={value}
                      onChange={onChange}
                    >
                      <MenuItem value={"전체"}>전체</MenuItem>
                      <MenuItem value={"신청"}>신청</MenuItem>
                      <MenuItem value={"승인"}>승인</MenuItem>
                      <MenuItem value={"거절"}>거절</MenuItem>
                    </TextField>
                  )}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Stack
                  direction="row"
                  justifyContent="flex-end"
                  alignItems="center"
                  spacing={1}
                >
                  <LoadingButton
                    icon={<SearchIcon />}
                    size="medium"
                    fullWidth={false}
                    loading={false}
                  >
                    조회
                  </LoadingButton>
                  <Button
                    variant="outlined"
                    endIcon={<RestartAltIcon />}
                    onClick={handleReset}
                  >
                    조건 초기화
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </form>
        </Paper>

        <Typography variant="body1" sx={{ mb: 1, fontWeight: "bold" }}>
          정보 수정 관리 목록
        </Typography>
        <Paper
          sx={{
            border: "1px solid #F2F3F5",
            borderRadius: 3,
            overflow: "hidden"
          }}
        >
          <TableContainer>
            <Table
              stickyHeader
              sx={{
                ...((!data || data?.content?.length === 0) && {
                  height: 550
                })
              }}
            >
              <TableHead>
                <TableRow>
                  {columns.map((column: any) => (
                    <TableCell
                      variant="head"
                      key={column.id}
                      align="center"
                      sx={{ backgroundColor: "#FBFBFB" }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {(!data || data.content.length === 0) && !isLoading ? (
                  <TableRow>
                    <TableCell sx={{ height: 500 }} colSpan={7}>
                      <Typography align="center">
                        조회된 데이터가 없습니다.
                      </Typography>
                    </TableCell>
                  </TableRow>
                ) : (
                  <>
                    {data &&
                      data.content.length > 0 &&
                      data.content.map((row) => (
                        <TableRow
                          key={row.modifyId}
                          onClick={() => handleRowClick(row)}
                          sx={{ cursor: "pointer" }}
                        >
                          <TableCell align="center">{row.mallName}</TableCell>
                          <TableCell align="center">
                            사업자등록증 상호명
                          </TableCell>
                          <TableCell align="center">{row.createdAt}</TableCell>
                          <TableCell align="center">{row.status}</TableCell>
                          <TableCell align="center">{row.note}</TableCell>
                        </TableRow>
                      ))}
                  </>
                )}
              </TableBody>
            </Table>
          </TableContainer>

          <MerchantInfoUpdateModal
            isOpen={selectedData.setIsModalOpen}
            onClose={onClose}
            merchantId={selectedData.merchantId}
            status={selectedData.status}
            modifyId={selectedData.modifyId}
          />

          <Box mt={2} mb={2}>
            <Pagination
              count={totalPages}
              variant="outlined"
              shape="rounded"
              page={params.page + 1}
              onChange={changePage}
              sx={{ display: "flex", justifyContent: "center" }}
            ></Pagination>
          </Box>
        </Paper>
      </ClientLayout>
    </>
  );
};
export default MerchantModifyList;
