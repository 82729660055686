import { instanceWithAuth } from "../Instance";
import { CommonApi } from "../type";
import {
  AddGroupMemberParams,
  AddGroupParams,
  Group,
  GroupMember,
  NotBelongingMember
} from "./types";

export const getGroups = async () => {
  const { data } = await instanceWithAuth.get<CommonApi<Group[]>>("/groups");
  return data;
};

export const addGroup = async (params: AddGroupParams) => {
  const { data } = await instanceWithAuth.post<CommonApi<boolean>>(
    "/group",
    null,
    {
      params,
      headers: {
        "Content-Type": "application/json;charset=UTF-8"
      }
    }
  );
  return data;
};

export const deleteGroup = async (groupId: number) => {
  const { data } = await instanceWithAuth.delete<CommonApi<boolean>>("/group", {
    params: { groupId: String(groupId) }
  });
  return data;
};

export const getGroupMembers = async (groupId: number | null) => {
  const { data } = await instanceWithAuth.get<CommonApi<GroupMember[]>>(
    `/groups/${groupId}`
  );
  return data;
};

export const getNotBelongingMembers = async (groupId: number | null) => {
  const { data } = await instanceWithAuth.get<CommonApi<NotBelongingMember[]>>(
    `/noGroups/${groupId}`
  );
  return data;
};

export const addGroupMember = async (
  addGroupMemberParams: AddGroupMemberParams
) => {
  const { data } = await instanceWithAuth.post<CommonApi<boolean>>(
    "/group/member",
    addGroupMemberParams
  );
  return data;
};

export const removeGroupMember = async (groupMemberId: number[]) => {
  const { data } = await instanceWithAuth.delete<CommonApi<boolean>>(
    "/group/member",
    {
      params: { groupMemberId: groupMemberId.join(",") }
    }
  );
  return data;
};
