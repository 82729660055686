import React, { useState } from "react";
import Title from "../../components/Title";
import ClientLayout from "../../components/Layout/Client/Layout";
import Stack from "@mui/material/Stack";
import Calendar from "../../components/PaymentCalendar/Calendar";
import Summary from "../../components/PaymentCalendar/Summary";

export default function EnhancedTable() {
  const [currentMonth, setCurrentMonth] = useState<Date>(new Date());
  return (
    <>
      <Title title="매출 달력" />
      <ClientLayout>
        <Stack spacing={3}>
          <Summary currentMonth={currentMonth} />
          <Calendar setCurrentMonthEvent={setCurrentMonth} />
        </Stack>
      </ClientLayout>
    </>
  );
}
