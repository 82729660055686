import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useForm } from "react-hook-form";
import { updateStatementNote } from "../../api/v2/Settlements.ts";
import { useMutation } from "@tanstack/react-query";
import { queryClient } from "../../index";
import isModalOpen from "../../recoil/isModalOpen/atom";
import { useSetRecoilState } from "recoil";
import { HelperTextMessage } from "../../pages/Client/MerchantList";

interface Props {
  statementId?: number;
  mallId: string;
  paidOutDate: string;
  isPayOut: string;
  closeModal?: () => void;
}

const SettlementNoteModal = ({
  closeModal,
  statementId,
  mallId,
  paidOutDate,
  isPayOut
}: Props) => {
  // ===============================================================================================
  // 리코일 스테이트
  // ===============================================================================================
  const setIsModalOpen = useSetRecoilState(isModalOpen);

  // ===============================================================================================
  // 리액트 훅 폼
  // ===============================================================================================
  const {
    register,
    handleSubmit,
    formState: { errors, isValid }
  } = useForm({
    mode: "onChange",
    defaultValues: {
      statementId: statementId,
      note: ""
    }
  });

  // ===========================================================================
  // 리액트 쿼리 - 정산내역서 데이터 조회
  // ===========================================================================
  const { mutate } = useMutation(updateStatementNote, {
    onSuccess: () => {
      queryClient.invalidateQueries([
        "/settlement/total",
        {
          mallId: mallId,
          paidOutDate: paidOutDate,
          isPayOut: isPayOut
        }
      ]);
      setIsModalOpen({
        value: true,
        position: "top",
        alertSeverity: "success",
        message: "업데이트가 완료 되었습니다."
      });
      closeModal?.();
    },
    onError: (error: any) => {
      setIsModalOpen({
        value: true,
        position: "top",
        alertSeverity: "error",
        message: error?.response?.data?.message
      });
    }
  });

  const onSubmit = (formData: any) => {
    const params = {
      ...formData
    };
    mutate(params);
  };

  return (
    <>
      <Dialog open={true} fullWidth maxWidth="xs">
        <DialogTitle>비고 내용</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={closeModal}
          sx={{
            position: "absolute",
            right: 8,
            top: 12
          }}
        >
          <CloseIcon />
        </IconButton>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent>
            <Typography sx={{ mb: 1.5 }}>
              비고 내용을 업데이트 하시겠습니까?
            </Typography>
            <TextField
              label="비고"
              {...register("note", {
                maxLength: {
                  value: 60,
                  message: "글자수는 60자 이하로 입력해주세요."
                }
              })}
              fullWidth
              multiline
              helperText={
                <HelperTextMessage>{errors.note?.message}</HelperTextMessage>
              }
            />
          </DialogContent>
          <DialogActions sx={{ mx: 2, mb: 1 }}>
            <Button
              type="submit"
              variant="contained"
              fullWidth
              sx={{ color: "white" }}
              disabled={!isValid}
            >
              비고 내용 업데이트
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};
export default SettlementNoteModal;
