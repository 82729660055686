import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import Divider from "@mui/material/Divider";
import Chip from "@mui/material/Chip";
import InputAdornment from "@mui/material/InputAdornment";
import Skeleton from "@mui/material/Skeleton";
import Typography from "@mui/material/Typography";
import { Controller, useForm } from "react-hook-form";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { lightGreen, amber } from "@mui/material/colors";
import { useEffect, useState } from "react";
import { getCharges, postCharges } from "../../api/Charge";
import { useMutation, useQuery } from "@tanstack/react-query";
import { Charge, ChargeSaveParams } from "../../api/Charge/types";
import { useSetRecoilState } from "recoil";
import isModalOpenAtom from "../../recoil/isModalOpen";
import { addCommas } from "../../libs/thousands-commas";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";

interface ChargeModalProps {
  mallId: string;
  dueDate: string;
  isConfirm: string;
  settlementId: number;
  isModalOpen?: boolean;
  closeModal?: () => void;
  onButtonSubmit: () => void;
}

const SettlementChargeModal = ({
  mallId,
  dueDate,
  isConfirm,
  settlementId,
  isModalOpen = false,
  closeModal,
  onButtonSubmit
}: ChargeModalProps) => {
  const [renderData, setRenderData] = useState<Charge[]>([]);
  const [chargeData, setChargeData] = useState<Charge[]>([]);
  const [sum, setSum] = useState(0);
  const setIsModalOpen = useSetRecoilState(isModalOpenAtom);
  const { control, getValues, setValue } = useForm<Charge>({
    defaultValues: {
      claimReasonType: "",
      claimReason: "",
      claimAmount: 0
    }
  });

  /**
   * 정산 지급 완료 여부
   */
  const 정산지급완료상태 = settlementId > 0;

  /**
   * 데이터 조회
   */
  const { isLoading } = useQuery<Charge[]>(
    ["/charge/find", { mallId, dueDate, settlementId }],
    () => getCharges({ mallId, dueDate, settlementId }),
    {
      // enabled: isModalOpen,
      refetchOnWindowFocus: false,
      select: (data) =>
        data.map((item) => {
          return {
            ...item,
            key: item.id ? item.id : 0
          };
        }),
      onSuccess: (data) => {
        setRenderData(data);
        setChargeData(data);
      }
    }
  );

  /**
   * 입력 폼 초기화
   */
  const initInputForm = () => {
    setValue("claimReasonType", "");
    setValue("claimReason", "");
    setValue("claimAmount", 0);
  };

  /**
   * 더하기 버튼 눌렀을 때
   */
  const addClicked = () => {
    const claimReasonType = getValues("claimReasonType");
    if (!claimReasonType) {
      setIsModalOpen({
        value: true,
        position: "top",
        alertSeverity: "error",
        message: "청구 타입을 입력해 주세요."
      });
      return;
    }

    const claimReason = getValues("claimReason");
    if (!claimReason) {
      setIsModalOpen({
        value: true,
        position: "top",
        alertSeverity: "error",
        message: "청구 이유를 입력해 주세요."
      });
      return;
    }

    let claimAmount = getValues("claimAmount");
    if (!claimAmount || claimAmount === 0) {
      setIsModalOpen({
        value: true,
        position: "top",
        alertSeverity: "error",
        message: "청구 금액을 입력해 주세요."
      });
      return;
    }
    // claimAmount 를 숫자로 변경
    claimAmount =
      typeof claimAmount === "string" ? parseInt(claimAmount) : claimAmount;

    // 가장 큰 플래그 찾는 방식으로 수정해야 함
    let maxChargeKey = 0;
    if (chargeData && chargeData.length > 0) {
      maxChargeKey = chargeData.reduce((prev, current) => {
        return prev.key > current.key ? prev : current;
      }).key;
    }

    const newData = {
      claimReasonType,
      claimReason,
      claimAmount,
      key: maxChargeKey + 1,
      isUse: true,
      claimDate: dueDate,
      mallId: mallId,
      settlementId: settlementId
    };

    setChargeData((prev) => [...prev, newData]);

    setRenderData((prev) => [...prev, newData]);

    initInputForm();
  };

  /**
   * 빼기 버튼을 눌렀을 때
   */
  const removeClicked = (key: number) => {
    // chargeData 가 없으면 종료
    if (!chargeData) return;

    // 화면에 그려지는 데이터 수정
    // 배열에서 해당 flag 의 데이터를 지운다 -> flag 가 다른 데이터들로 새로운 배열을 만든다
    const newRenderDatas = renderData.filter((item) => item.key !== key);
    setRenderData((prev) => newRenderDatas);

    // 디비에 저장할 데이터 수정
    // 해당 flag 의 데이터의 isUse 속성 값을 false 로 변경한다
    const newChargeDatas = chargeData.map((item) => {
      if (item.key === key) {
        item.isUse = false;
      }
      return item;
    });
    setChargeData((prev) => newChargeDatas);
  };

  /**
   * 합계 구하기
   */
  useEffect(() => {
    const getSum = () => {
      let sum = 0;
      renderData.forEach((item) => {
        const amount =
          typeof item.claimAmount !== "number"
            ? parseInt(item.claimAmount)
            : item.claimAmount;
        sum = sum + amount;
      });
      setSum(sum);
    };
    getSum();
  }, [renderData]);

  /**
   * 저장
   */
  const { mutate } = useMutation(postCharges, {
    onSuccess: (response) => {
      // 성공시 토스트 알림
      setIsModalOpen({
        value: true,
        position: "top",
        alertSeverity: "success",
        message: "청구금 내역을 저장하였습니다."
      });

      // 닫기
      closeModal?.();
      onButtonSubmit();
    },
    onError: (error) => {
      setIsModalOpen({
        value: true,
        position: "top",
        alertSeverity: "error",
        message: "청구금 저장에 실패하였습니다."
      });
    }
  });
  const save = (e: React.MouseEvent<HTMLButtonElement>) => {
    // 정산지급완료된 경우 save 안됨
    if (정산지급완료상태) return;

    // chargeData 가 없으면 바로 종료
    if (!chargeData) return;

    // chargeData 를 saveForm 에 맞게 변형해야 한다
    const saveForms: ChargeSaveParams[] = chargeData.map((item) => {
      return {
        id: item.id,
        mallId: mallId,
        claimDate: dueDate,
        claimAmount: item.claimAmount,
        claimReason: item.claimReason,
        claimReasonType: item.claimReasonType,
        isUse: item.isUse
      };
    });
    mutate(saveForms);
  };

  return (
    <Dialog
      open={true}
      onClose={closeModal}
      fullWidth={true}
      maxWidth="md"
      scroll={"paper"}
    >
      <DialogTitle>청구금 내역</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={() => closeModal?.()}
        sx={{
          position: "absolute",
          right: 8,
          top: 12
        }}
      >
        <CloseIcon />
      </IconButton>
      <form>
        <DialogContent>
          {/* 입력 인풋 */}
          {정산지급완료상태 || isConfirm === "확정" ? (
            // settlementId 가 0보다 크면 폼 대신 메뉴만 보이도록 한다
            <Grid container sx={{}}>
              <Grid item xs={3}>
                <TextField
                  label={"청구 타입"}
                  helperText={"청구 타입을 선택하세요"}
                  sx={{ m: 2, width: "95%" }}
                  disabled
                />
              </Grid>
              <Grid item xs={5}>
                <TextField
                  label={"청구 이유"}
                  helperText={"청구 이유를 입력하세요."}
                  sx={{ m: 2, width: "95%" }}
                  disabled
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  label={"청구 금액"}
                  type="number"
                  helperText={"청구 금액을 입력하세요."}
                  sx={{ m: 2, width: "95%" }}
                  disabled
                />
              </Grid>
              <Grid
                item
                xs={1}
                sx={{
                  textAlign: "center"
                }}
              ></Grid>
            </Grid>
          ) : (
            <Grid container sx={{}}>
              <Grid item xs={3}>
                <Controller
                  name="claimReasonType"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      select
                      label={"청구 타입"}
                      value={value}
                      onChange={onChange}
                      helperText={"청구 타입을 선택하세요"}
                      sx={{ m: 2, width: "95%" }}
                    >
                      <MenuItem key={1} value={"연회비"}>
                        연회비
                      </MenuItem>
                      <MenuItem key={2} value={"관리비"}>
                        관리비
                      </MenuItem>
                      <MenuItem key={3} value={"기타"}>
                        기타
                      </MenuItem>
                    </TextField>
                  )}
                />
              </Grid>
              <Grid item xs={5}>
                <Controller
                  name={"claimReason"}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      label={"청구 이유"}
                      value={value}
                      onChange={onChange}
                      helperText={"청구 이유를 입력하세요."}
                      sx={{ m: 2, width: "95%" }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={3}>
                <Controller
                  name={"claimAmount"}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      label={"청구 금액"}
                      type="number"
                      value={value}
                      onChange={onChange}
                      helperText={"청구 금액을 입력하세요."}
                      sx={{ m: 2, width: "95%" }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">₩</InputAdornment>
                        )
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid
                item
                xs={1}
                sx={{
                  textAlign: "center"
                }}
              >
                <AddCircleOutlineIcon
                  sx={{
                    mt: 3,
                    ml: 1,
                    width: "2.5rem",
                    height: "2.5rem",
                    color: lightGreen[400],
                    "&:hover": { color: lightGreen[700], cursor: "pointer" }
                  }}
                  onClick={addClicked}
                />
              </Grid>
            </Grid>
          )}

          {/* 청구금 내역 */}
          <Grid container sx={{ mt: 3 }}>
            {isLoading && !renderData ? (
              <>
                <Grid
                  item
                  container
                  sx={{
                    pb: 2
                  }}
                >
                  <Grid item xs={3} sx={{ pl: 3, pt: 2 }}>
                    <Skeleton variant="text" animation="wave" />
                  </Grid>
                  <Grid item xs={5} sx={{ pl: 3, pt: 2 }}>
                    <Skeleton variant="text" animation="wave" />
                  </Grid>
                  <Grid item xs={3} sx={{ pl: 3, pt: 2 }}>
                    <Skeleton variant="text" animation="wave" />
                  </Grid>
                  <Grid item xs={1} sx={{ pl: 3, pt: 2 }}>
                    <Skeleton variant="text" animation="wave" />
                  </Grid>
                </Grid>
              </>
            ) : (
              <>
                {renderData?.map((item) => (
                  <Grid
                    key={`chargeList-${item.key}`}
                    item
                    container
                    sx={{
                      "&:hover": {
                        backgroundColor: amber[100]
                      }
                    }}
                  >
                    <Grid item xs={3} sx={{ pl: 3, pt: 2 }}>
                      <Typography>{item.claimReasonType}</Typography>
                    </Grid>
                    <Grid item xs={5} sx={{ pl: 3, pt: 2 }}>
                      <Typography>{item.claimReason}</Typography>
                    </Grid>
                    <Grid item xs={3} sx={{ pl: 3, pt: 2 }}>
                      <Typography align="right">{`${addCommas(
                        item.claimAmount
                      )} 원`}</Typography>
                    </Grid>
                    <Grid
                      item
                      xs={1}
                      sx={{ pl: 1, pt: 1, textAlign: "center" }}
                    >
                      {정산지급완료상태 || isConfirm === "확정" ? (
                        <></>
                      ) : (
                        <RemoveCircleOutlineIcon
                          sx={{
                            width: "2.5rem",
                            height: "2.5rem",
                            color: amber[400],
                            "&:hover": { color: amber[700], cursor: "pointer" }
                          }}
                          onClick={() => removeClicked(item.key)}
                        />
                      )}
                    </Grid>
                  </Grid>
                ))}
              </>
            )}
          </Grid>

          {/* 청구금 합계 */}
          <Divider>
            <Chip label="합계" />
          </Divider>
          <Grid container rowSpacing={2}>
            <Grid item xs={3} sx={{ pl: 3 }}></Grid>
            <Grid item xs={5} sx={{ pl: 3 }}></Grid>
            <Grid item xs={3} sx={{ pl: 3 }}>
              {`${addCommas(sum)} 원`}
            </Grid>
            <Grid item xs={1}></Grid>
          </Grid>
        </DialogContent>
        {정산지급완료상태 || isConfirm === "확정" ? (
          <></>
        ) : (
          <>
            <DialogActions>
              <Button
                variant="contained"
                sx={{
                  color: "white"
                }}
                fullWidth={true}
                onClick={save}
              >
                저장
              </Button>
            </DialogActions>
          </>
        )}
      </form>
    </Dialog>
  );
};

export default SettlementChargeModal;
