import styled from "@emotion/styled";
import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import { useMutation } from "@tanstack/react-query";
import { Controller, useForm } from "react-hook-form";
import { useSetRecoilState } from "recoil";
import { queryClient } from "../..";
import { postMerchentContractInfo } from "../../api/ContractManagement";
import isModalOpen from "../../recoil/isModalOpen/atom";

interface Props {
  userId: number;
  closeModal?: () => void;
}

export const HelperTextMessage = styled("span")({
  position: "absolute",
  color: "#d94452"
});

const ExtendContractModal = ({ closeModal, merchantId }: any) => {
  // ===========================================================================
  // 리코일 스테이트
  // ===========================================================================
  const setIsModalOpen = useSetRecoilState(isModalOpen);

  // 가맹점 계약 연장
  const { mutate } = useMutation(postMerchentContractInfo, {
    onSuccess: () => {
      queryClient.invalidateQueries();
      setIsModalOpen({
        value: true,
        position: "top",
        alertSeverity: "success",
        message: "계약 연장이 완료되었습니다."
      });
    },
    onError: (error: any) => {
      setIsModalOpen({
        value: true,
        position: "top",
        alertSeverity: "error",
        message: error?.response?.data?.message
      });
    }
  });

  // ===========================================================================
  // 리액트 훅 폼
  // ===========================================================================
  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm({
    mode: "onChange",
    defaultValues: {
      note: ""
    }
  });

  const onSubmit = (formData: any) => {
    const params = {
      ...formData,
      contractExtensionStatus: "연장",
      merchantId: merchantId
    };
    mutate(params);
    closeModal?.();
  };

  return (
    <>
      <Dialog open={true} fullWidth maxWidth="xs">
        <DialogTitle>사용자 계약 연장</DialogTitle>

        <IconButton
          onClick={closeModal}
          sx={{
            position: "absolute",
            right: 8,
            top: 12
          }}
        >
          <CloseIcon />
        </IconButton>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent>
            <DialogContentText gutterBottom>
              연장 사유를 입력해주세요.
            </DialogContentText>
            <Controller
              name="note"
              control={control}
              rules={{
                maxLength: {
                  value: 50,
                  message: "50자 이하로 입력해 주세요."
                }
              }}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="사유"
                  fullWidth
                  multiline
                  maxRows={2}
                  helperText={
                    <HelperTextMessage>
                      {errors.note?.message}
                    </HelperTextMessage>
                  }
                />
              )}
            />
          </DialogContent>
          <DialogActions>
            <Button
              type="submit"
              variant="contained"
              sx={{ color: "white" }}
              fullWidth
            >
              계약 연장
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

export default ExtendContractModal;
