import React from "react";
import { Helmet } from "react-helmet-async";

interface ITitle {
  title: string;
}

const Title = ({ title }: ITitle) => {
  return (
    <Helmet>
      <title>{title} | Solution</title>
    </Helmet>
  );
};

export default Title;
