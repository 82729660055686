import { instance, instanceWithAuth } from "../Instance";
import { CommonApi } from "../type";
import { PaymentDetailData } from "../v2/Payments/types";
import {
  LeyutaeLoginData,
  LeyutaeLoginParams,
  LeyutaePaymentsParams,
  UpdateLeyutaePasswordParams
} from "./types";
/**
 * 레유태 로그인
 */
export const PostLeyutaelogin = async (loginFormData: LeyutaeLoginParams) => {
  const { data } = await instance.post<CommonApi<LeyutaeLoginData>>(
    "/bypass/leyutae/mall/login",
    loginFormData
  );
  return data;
};

/**
 * 레유태 거래 상세 내역 조회
 */
export const getLeyutaePaymentsDetail = async (
  params: LeyutaePaymentsParams
) => {
  const url = "/leyutae/transaction/details";
  const { data } = await instanceWithAuth.get<CommonApi<PaymentDetailData[]>>(
    url,
    {
      params
    }
  );
  return data;
};

/**
 * 레유태 거래 상세 내역 엑셀 다운로드 
 */
export const getLeyutaePaymentsExcelDetail = async (
  params: LeyutaePaymentsParams
) => {
  const url = "/leyutae/transaction/detail/excel";
  const { data } = await instanceWithAuth.get<CommonApi<PaymentDetailData[]>>(
    url,
    {
      params
    }
  );
  return data;
};

/**
 * 레유태 가맹점 계정 비밀번호 찾기
 */
export const sendLeyutaeTempPassword = async (email: string) => {
  const url = "/bypass/leyutae-tmp-password";
  const { data } = await instance.post<CommonApi<PaymentDetailData[]>>(url, {
    email
  });
  return data;
};

/**
 * 레유태 가맹점 계정 비밀번호 변경
 */
export const updateLeyutaePassword = async (
  params: UpdateLeyutaePasswordParams
) => {
  const { data } = await instanceWithAuth.post(
    "/leyutae-change-password",
    params
  );
  return data;
};
