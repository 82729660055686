import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CloseIcon from "@mui/icons-material/Close";
import LoginIcon from "@mui/icons-material/Login";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useSetRecoilState } from "recoil";
import { queryClient } from "../..";
import { getVerifyEmail, postAdminRegistUser } from "../../api/Merchants";
import { numberValidate } from "../../libs/input-validation";
import { HelperTextMessage } from "../../pages/Client/MerchantRegistration";
import isModalOpenAtom from "../../recoil/isModalOpen";
import LoadingButton from "../LoadingButton";

interface Props {
  closeModal?: () => void;
}

interface RegisterForm {
  authority: "ADMIN" | "SALES" | "CLIENT";
  email: string;
  name: string;
  storeName: string;
  phoneNumber: string;
}

const RegistUserModal = ({ closeModal }: Props) => {
  // ===================================================================================================================
  // 리코일 스테이트
  // ===================================================================================================================
  const setIsModalOpen = useSetRecoilState(isModalOpenAtom);

  // ===================================================================================================================
  // 스테이트
  // ===================================================================================================================
  // 이메일 사용 가능 확인 스테이트
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [isEmailDisabled, setIsEmailDisabled] = useState(false);

  // 이메일 검증 메세지를 관리하는 상태
  const [emailValidationMessage, setEmailValidationMessage] = useState("");

  // ===================================================================================================================
  // 리액트 훅 폼
  // ===================================================================================================================
  const {
    register,
    handleSubmit,
    control,
    watch,
    clearErrors,
    formState: { errors }
  } = useForm<RegisterForm>({
    defaultValues: {
      email: "",
      name: "",
      storeName: "",
      authority: "CLIENT",
      phoneNumber: ""
    }
  });

  // ===================================================================================================================
  // 리액트 쿼리
  // ===================================================================================================================
  // 사용자가 입력한 이메일을 계속 관찰
  const email = watch("email");

  // 이메일 검증 쿼리
  const { refetch, isFetching } = useQuery(
    ["/bypass/verify/email", email],
    () => getVerifyEmail(email),
    {
      enabled: false, // 기본적으로 비활성화하고 수동으로 호출
      onSuccess: (data) => {
        // 이메일 검증 성공 처리
        if (data.content === "valid") {
          setIsEmailValid(false);
          setEmailValidationMessage("이미 등록된 이메일입니다.");
        } else {
          setIsEmailValid(true);
          setIsEmailDisabled(true);
          setEmailValidationMessage("");
        }
      },
      onError: (error: any) => {
        setIsEmailValid(false);
        setEmailValidationMessage(error.response.data.message);
      }
    }
  );

  // 회원등록
  const { mutate, isLoading } = useMutation(postAdminRegistUser, {
    onError: (error: any) => {
      setIsModalOpen({
        value: true,
        position: "top",
        alertSeverity: "error",
        message: error?.response?.data?.message
      });
    },
    onSuccess: () => {
      setIsModalOpen({
        value: true,
        position: "top",
        alertSeverity: "success",
        message: "회원등록이 완료되었습니다."
      });
      queryClient.invalidateQueries(["/users/list"]);
      queryClient.invalidateQueries(["/adminUsers/list"]);
      closeModal?.();
    }
  });

  const handleCheckEmail = () => {
    const emailPattern =
      /^([0-9a-zA-Z_.-]+)@([0-9a-zA-Z_-]+)(\.[0-9a-zA-Z_-]+){1,2}$/;
    if (email && emailPattern.test(email)) {
      setEmailValidationMessage("검증중입니다...");
      refetch();
    } else {
      clearErrors("email");
      setEmailValidationMessage("유효한 이메일 주소를 입력해주세요.");
    }
  };

  // ===================================================================================================================
  // 사용자 등록
  // ===================================================================================================================
  const onSubmit = (data: RegisterForm) => {
    // 이메일이 유효하지 않으면 에러 토스트
    if (!isEmailValid) {
      setIsModalOpen({
        value: true,
        position: "top",
        alertSeverity: "error",
        message: "이미 가입된 이메일입니다."
      });
      return;
    }

    mutate({
      ...data
    });
  };

  return (
    <>
      {/* 사용자 등록 모달창 */}
      <Dialog open={true} fullWidth maxWidth="sm">
        <DialogTitle>사용자 등록</DialogTitle>
        <IconButton
          onClick={closeModal}
          sx={{
            position: "absolute",
            right: 8,
            top: 12
          }}
        >
          <CloseIcon />
        </IconButton>

        <DialogContent>
          {/* 사용자 등록 양식 */}
          <form onSubmit={handleSubmit(onSubmit)} noValidate>
            <Grid container spacing={3.5}>
              <Grid item xs={8}>
                <TextField
                  label="이메일"
                  required
                  fullWidth
                  size="small"
                  {...register("email", {
                    required: "이메일을 입력해주세요.",
                    pattern: {
                      value:
                        /^([0-9a-zA-Z_.-]+)@([0-9a-zA-Z_-]+)(\.[0-9a-zA-Z_-]+){1,2}$/,
                      message: "형식에 맞지 않는 이메일 주소입니다."
                    }
                  })}
                  onChange={(e) => {
                    setEmailValidationMessage(""); // 이메일 인풋의 텍스트를 지울 때 에러 메세지 clear
                    clearErrors("email");
                    register("email").onChange(e);
                  }}
                  helperText={
                    <HelperTextMessage>
                      {errors.email?.message || emailValidationMessage}
                    </HelperTextMessage>
                  }
                  disabled={isEmailDisabled}
                />
              </Grid>
              <Grid item xs={4}>
                {!isEmailDisabled ? (
                  <Button
                    type="button"
                    variant="outlined"
                    fullWidth
                    onClick={handleCheckEmail}
                    disabled={isFetching || isEmailDisabled}
                  >
                    이메일 확인하기
                  </Button>
                ) : (
                  <Button
                    variant="outlined"
                    color="success"
                    sx={{ cursor: "default", textAlign: "center" }}
                    endIcon={<CheckCircleOutlineIcon />}
                    fullWidth
                  >
                    사용 가능
                  </Button>
                )}
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="이름"
                  required
                  fullWidth
                  size="small"
                  {...register("name", {
                    required: "이름을 입력해주세요."
                  })}
                  helperText={
                    <HelperTextMessage>
                      {errors.name?.message}
                    </HelperTextMessage>
                  }
                  disabled={!isEmailValid}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="가맹점명"
                  required
                  fullWidth
                  size="small"
                  {...register("storeName", {
                    required: "가맹점명을 입력해주세요."
                  })}
                  helperText={
                    <HelperTextMessage>
                      {errors.storeName?.message}
                    </HelperTextMessage>
                  }
                  disabled={!isEmailValid}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="authority"
                  rules={{
                    required: "권한을 선택해주세요."
                  }}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      label="권한"
                      required
                      fullWidth
                      size="small"
                      select
                      value={value}
                      onChange={onChange}
                      helperText={
                        <HelperTextMessage>
                          {errors.authority?.message}
                        </HelperTextMessage>
                      }
                      disabled={!isEmailValid}
                    >
                      <MenuItem value="ADMIN">관리자</MenuItem>
                      <MenuItem value="CLIENT">사용자</MenuItem>
                      <MenuItem value="SALES">영업</MenuItem>
                    </TextField>
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="전화번호"
                  required
                  fullWidth
                  size="small"
                  {...register("phoneNumber", {
                    required: "전화번호를 입력해주세요."
                  })}
                  helperText={
                    <HelperTextMessage>
                      {errors.phoneNumber?.message}
                    </HelperTextMessage>
                  }
                  onInput={numberValidate}
                  disabled={!isEmailValid}
                />
              </Grid>
            </Grid>
            <Box
              sx={{
                mt: 3
              }}
            >
              <LoadingButton
                icon={<LoginIcon />}
                fullWidth={true}
                size="large"
                loading={isLoading}
              >
                사용자 등록하기
              </LoadingButton>
            </Box>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default RegistUserModal;
