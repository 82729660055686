import RestartAltIcon from "@mui/icons-material/RestartAlt";
import SearchIcon from "@mui/icons-material/Search";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Pagination from "@mui/material/Pagination";
import Paper from "@mui/material/Paper";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { getMerchants } from "../../api/Merchants";
import { AdminMerchantsParams } from "../../api/Merchants/types";
import AdminLayout from "../../components/Layout/Admin/Layout";
import AdminClientEditModal from "../../components/Merchants/AdminModifyClientDataModal";
import MerchantsDetailModal from "../../components/Merchants/MerchantsDetailModal";
import Title from "../../components/Title";
import useRenewal from "../../hooks/useRenewal";
import { getBusinessCategories } from "../../api/BusinessCategory";

interface MerchantsForm {
  mallName: string;
  mallId: string;
  registrant: "ADMIN" | "CLIENT" | "전체" | string;
  mccType:
    | "일반"
    | "학원"
    | "병원"
    | "문화"
    | "비영리기부금"
    | "비영리강의료"
    | "정기"
    | string;
  status:
    | "전체"
    | "가맹심사대기"
    | "계약심사대기"
    | "가맹심사중"
    | "계약심사중"
    | "계약승인"
    | "가맹심사반려"
    | "계약심사반려"
    | "삭제";
}

interface Columns {
  id: string;
  label: string;
}
const columns: Columns[] = [
  { id: "mallId", label: "가맹점ID" },
  { id: "mallName", label: "가맹점명" },
  { id: "businessName", label: "사업자 등록증 상호명" },
  { id: "ownerName", label: "가맹점 대표자 명" },
  { id: "phone", label: "가맹점 대표 번호" },
  { id: "createAt", label: "가맹등록일" },
  { id: "mccType", label: "결제 업종" },
  { id: "registrant", label: "등록자" },
  { id: "status", label: "심사 상태" }
];

const MerchantsStatus = [
  { name: "전체", value: "전체" },
  { name: "가맹심사대기", value: "가맹심사대기" },
  { name: "가맹심사중", value: "가맹심사중" },
  { name: "가맹심사반려", value: "가맹심사반려" },
  { name: "계약심사대기", value: "계약심사대기" },
  { name: "계약심사중", value: "계약심사중" },
  { name: "계약심사반려", value: "계약심사반려" }
];

interface Row {
  merchantId: string;
  amlCatch?: boolean;
  mallId: string;
  mallName: string;
  ownerName: string;
  phone: string;
  createdAt: string;
  startDate: string;
  endDate: string;
  status:
    | "전체"
    | "가맹심사대기"
    | "계약심사대기"
    | "가맹심사중"
    | "계약심사중"
    | "계약승인"
    | "가맹심사반려"
    | "계약심사반려";
  isModalOpen: boolean;
  // registrant: "ADMIN" | "CLIENT";
}

const Merchants = () => {
  const { isRenewalTokenLoading, mutateRenewalToken } = useRenewal();
  // ===================================================================================================================
  // 컴포넌트 스테이트
  // ===================================================================================================================
  // 파라미터 스테이트
  const [params, setParams] = useState<AdminMerchantsParams>({
    mallId: "",
    mallName: "",
    page: 0,
    size: 10,
    status: "전체",
    registrant: "",
    mccType: ""
  });

  // 선택된 row 데이터 스테이트
  const [selectedData, setSelectedData] = useState<Row>({
    isModalOpen: false,
    amlCatch: false,
    merchantId: "",
    mallId: "",
    mallName: "",
    ownerName: "",
    phone: "",
    createdAt: "",
    startDate: "",
    endDate: "",
    status: "전체"
    // mccType: ""
  });

  // ===================================================================================================================
  // 리액트 훅 폼
  // ===================================================================================================================
  const {
    handleSubmit,
    reset,
    register,
    control,
    formState: { errors }
  } = useForm<MerchantsForm>({
    mode: "onChange",
    defaultValues: {
      ...params,
      registrant: "전체"
    }
  });

  // ===================================================================================================================
  // 리액트 쿼리
  // ===================================================================================================================
  // 등록된 가맹점 조회
  const { data, isLoading } = useQuery(
    ["/merchants", params],
    () => getMerchants(params),
    {
      onSuccess: () => {
        if (isRenewalTokenLoading) return;
        mutateRenewalToken();
      }
    }
  );

  /*********************************************************************************************************************
   * 업종 조회
   ********************************************************************************************************************/
  const { data: businessCategories } = useQuery(
    ["/businessCategories"],
    getBusinessCategories,
    {}
  );

  // ===================================================================================================================
  // 심사 상태 수정
  // ===================================================================================================================
  const selectRow = (row: any) => {
    setSelectedData({ ...row, isModalOpen: true });
  };

  const closeModal = () => {
    setSelectedData({
      isModalOpen: false,
      amlCatch: false,
      merchantId: "",
      mallId: "",
      mallName: "",
      ownerName: "",
      phone: "",
      createdAt: "",
      startDate: "",
      endDate: "",
      status: "전체"
    });
  };

  // ===================================================================================================================
  // 페이징 처리
  // ===================================================================================================================
  const totalPages = data?.pageable?.totalPages
    ? data?.pageable?.totalPages
    : 0;
  const changePage = (event: React.ChangeEvent<unknown>, page: number) => {
    setParams((prev) => ({ ...prev, page: page - 1 }));
  };

  // ===================================================================================================================
  // 가맹점 신청 현황 조회 및 초기화
  // ===================================================================================================================
  // 초기화
  const handleReset = () => {
    reset(selectedData);
  };
  // 조회
  const onClickSubmit = (formData: MerchantsForm) => {
    setParams((prev) => ({
      ...prev,
      mallId: formData.mallId,
      mallName: formData.mallName,
      status: formData.status,
      registrant: formData.registrant === "전체" ? "" : formData.registrant,
      mccType: formData.mccType === "전체" ? "" : formData.mccType
    }));
  };

  return (
    <>
      <Title title="가맹 현황" />
      <AdminLayout>
        <Paper
          sx={{
            p: 3,
            border: "1px solid #F2F3F5",
            borderRadius: 3,
            overflow: "hidden",
            mb: 3
          }}
        >
          <form onSubmit={handleSubmit(onClickSubmit)}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={2}>
                <TextField
                  id="mallId"
                  type="text"
                  label="가맹점 Id"
                  size="small"
                  fullWidth
                  {...register("mallId")}
                />
                {errors.mallId && (
                  <span className="errorMessage">{errors.mallId.message}</span>
                )}
              </Grid>
              <Grid item xs={12} md={2}>
                <TextField
                  id="mallName"
                  type="text"
                  label="가맹점 명"
                  size="small"
                  fullWidth
                  {...register("mallName")}
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <Controller
                  name="registrant"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      select
                      label="등록자"
                      size="small"
                      fullWidth
                      onChange={onChange}
                      value={value || "전체"}
                    >
                      <MenuItem value={"전체"}>전체</MenuItem>
                      <MenuItem value={"ADMIN"}>ADMIN</MenuItem>
                      <MenuItem value={"CLIENT"}>CLIENT</MenuItem>
                    </TextField>
                  )}
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <Controller
                  name="mccType"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      select
                      label="업종"
                      size="small"
                      fullWidth
                      onChange={onChange}
                      value={value || "전체"}
                    >
                      <MenuItem value={"전체"}>전체</MenuItem>
                      {businessCategories?.content.map((category) => (
                        <MenuItem value={category} key={category}>
                          {category}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <Controller
                  name="status"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      select
                      label="심사 상태"
                      size="small"
                      fullWidth
                      onChange={onChange}
                      value={value || "전체"}
                    >
                      {MerchantsStatus.map((c) => (
                        <MenuItem key={c.name} value={c.value}>
                          {c.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <Stack
                  direction="row"
                  justifyContent="flex-end"
                  alignItems="center"
                  spacing={1}
                >
                  <Button
                    type="submit"
                    variant="contained"
                    endIcon={<SearchIcon />}
                    sx={{ color: "white" }}
                  >
                    조회
                  </Button>

                  <Button
                    type="button"
                    variant="outlined"
                    endIcon={<RestartAltIcon />}
                    onClick={handleReset}
                  >
                    초기화
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </form>
        </Paper>

        <Typography variant="body1" sx={{ mb: 1, fontWeight: "bold" }}>
          가맹 현황 목록
        </Typography>
        <Paper
          sx={{
            border: "1px solid #F2F3F5",
            borderRadius: 3,
            overflow: "hidden"
          }}
        >
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  {columns.map((column: any) => (
                    <TableCell
                      variant="head"
                      key={column.id}
                      align="center"
                      sx={{ backgroundColor: "#FBFBFB" }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.content.length === 0 ? (
                  <TableRow>
                    <TableCell sx={{ height: 500 }} colSpan={9}>
                      <Typography align="center">
                        조회된 데이터가 없습니다.
                      </Typography>
                    </TableCell>
                  </TableRow>
                ) : (
                  <>
                    {isLoading &&
                      Array.from(new Array(columns.length)).map(
                        (_, rowIndex) => (
                          <TableRow
                            key={rowIndex}
                            hover={true}
                            sx={{ cursor: "pointer" }}
                          >
                            {columns.map((_, colIndex) => (
                              <TableCell key={colIndex} align="center">
                                <Skeleton
                                  variant="rectangular"
                                  width={"100%"}
                                  height={30}
                                />
                              </TableCell>
                            ))}
                          </TableRow>
                        )
                      )}

                    {data &&
                      data.content.map((row) => (
                        <TableRow
                          key={row.merchantId}
                          onClick={() => selectRow(row)}
                          hover={true}
                          sx={{
                            cursor: "pointer",
                            border: row.amlCatch ? "2px solid #FF6634" : ""
                          }}
                        >
                          <TableCell component="th" align="center">
                            {row.mallId}
                          </TableCell>
                          <TableCell component="th" align="center">
                            {row.mallName}
                          </TableCell>
                          <TableCell component="th" align="center">
                            {row.businessName}
                          </TableCell>
                          <TableCell align="center">{row.ownerName}</TableCell>
                          <TableCell align="center">{row.phone}</TableCell>
                          <TableCell align="center">
                            {row.createdAt.split(" ").at(0)}
                          </TableCell>
                          <TableCell align="center">{row.mccType}</TableCell>
                          <TableCell align="center">{row.registrant}</TableCell>
                          <TableCell align="center">{row.status}</TableCell>
                        </TableRow>
                      ))}
                    {/* 심사 상태가 '가맹심사중'일 때만 가맹점 데이터 수정 모달창을 열 수 있다 */}
                    {selectedData.isModalOpen &&
                      (selectedData.status === "가맹심사중" ? (
                        <AdminClientEditModal
                          isOpen={selectedData.isModalOpen}
                          closeModal={closeModal}
                          merchantId={selectedData.merchantId}
                        />
                      ) : (
                        <MerchantsDetailModal
                          isOpen={selectedData.isModalOpen}
                          closeModal={closeModal}
                          merchantId={selectedData.merchantId}
                        />
                      ))}
                  </>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <Box mt={2} mb={2}>
            <Pagination
              count={totalPages}
              variant="outlined"
              shape="rounded"
              page={params.page + 1}
              onChange={changePage}
              sx={{ display: "flex", justifyContent: "center" }}
            ></Pagination>
          </Box>
        </Paper>
      </AdminLayout>
    </>
  );
};
export default Merchants;
