import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import LogoImage from "../../images/logo_contract.png";
import { grey } from "@mui/material/colors";
import Divider from "@mui/material/Divider";
import Fonts from "./Fonts";
import { MerchantsDetail } from "../../api/Merchants/types";

interface PageProps {
  data?: MerchantsDetail;
}

const Page1 = ({ data }: PageProps) => {
  return (
    <Fonts>
      <Box
        sx={{
          width: "210mm",
          height: "297mm",
          backgroundColor: "white",
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <Card
          sx={{
            width: "195mm",
            height: "282mm",
            backgroundColor: "white",
            border: "1px solid black"
          }}
        >
          <Stack direction={"column"}>
            {/* 머릿글 */}
            <Stack
              direction={"row"}
              justifyContent={"space-between"}
              sx={{
                width: "100%",
                height: "15mm"
              }}
            >
              <Stack
                justifyContent={"center"}
                alignItems={"center"}
                sx={{
                  width: "120px"
                }}
              >
                {/* <Box component={"img"} src={LogoImage}></Box> */}
              </Stack>
              <Stack
                justifyContent={"center"}
                alignItems={"center"}
                sx={{ fontSize: "22px", fontWeight: 700 }}
              >
                오직페이 서비스 이용 계약서
              </Stack>
              <Stack
                justifyContent={"center"}
                alignItems={"center"}
                sx={{
                  width: "35mm",
                  color: "white",
                  fontSize: "16px"
                }}
              >
                <Box component={"img"} src={LogoImage}></Box>
              </Stack>
            </Stack>
            <Divider sx={{ border: "2px solid #a29fb0", mx: 1 }}></Divider>
            {/* 본문 */}
            <Stack
              direction={"column"}
              sx={{
                height: "260mm",
                pr: 1
              }}
            >
              {/* 본문 상단 */}
              <Typography
                sx={{
                  height: "15mm",
                  backgroundColor: "white",
                  fontSize: "12px",
                  pt: 2,
                  px: 2
                }}
              >
                &nbsp;&nbsp;
                <Typography
                  component="strong"
                  sx={{
                    display: "inline",
                    p: 0.5,
                    fontSize: "12px",
                    fontWeight: 700,
                    backgroundColor: grey[300]
                  }}
                >
                  {data?.mallName}
                </Typography>{" "}
                (이하”고객사”라 함)와 주식회사 옵타움플랫폼(이하”회사”라 함)은
                “고객사”와 “회사”의 전자결제서비스를 이용하는 것과 관련하여
                다음과 같이 계약(이하 “본 계약”)을 체결한다.
              </Typography>
              {/* 본문 점선 */}
              <Divider
                sx={{
                  border: "none",
                  borderTop: "1px dotted",
                  borderColor: grey[700],
                  ml: 1
                }}
              ></Divider>
              {/* 본문 다단 */}
              <Stack
                direction={"row"}
                justifyContent={"space-between"}
                sx={{ height: "245mm" }}
              >
                {/* 본문 왼쪽 */}
                <Box
                  sx={{
                    width: "95mm"
                  }}
                >
                  <Typography
                    sx={{ fontSize: "8px", fontWeight: 700, mt: 1, ml: 1 }}
                  >
                    제 1 조 (목 적)
                  </Typography>
                  <Typography sx={{ fontSize: "7pt", ml: 1 }}>
                    본 계약은 ”고객사”가 자신의 상품 또는 서비스(이하”상품”이라
                    함)을 이용자에게 판매함에 있어서 “회사”가 제공하는
                    전자결제서비스를 이용하는 것과 관련하여 필요한 사항을 정하는
                    데에 그 목적이 있다.
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "8px",
                      fontWeight: 700,
                      mt: 1,
                      ml: 1
                    }}
                  >
                    제 2 조 (용어의 정의)
                  </Typography>
                  <Box component={"ol"} sx={{ ml: -2.5, mt: 0, mb: 0 }}>
                    <Typography
                      component={"li"}
                      sx={{
                        fontSize: "7pt"
                      }}
                    >
                      신용카드 결제 서비스 : “고객사”가 인터넷 상에 설치한
                      매장에서 이용자가 OZICPAY를 이용하여 “고객사”의 상품 및
                      서비스를 구매할 때에 “회사”가 제휴한 카드사를 통해 무전표
                      매출승인 및 입금, 대금결제 기타 관련 서비스를 다음 각 호의
                      수단을 통해 제공하는 것을 말한다.
                      <Typography sx={{ fontSize: "7pt" }}>
                        1-1) 인터넷 전자결제 통신프로그램 (결제서비스용 프로그램
                        모듈)의 제공
                      </Typography>
                      <Typography sx={{ fontSize: "7pt" }}>
                        1-2) 위 프로그램의 설치 안내문 제공
                      </Typography>
                    </Typography>
                    <Typography
                      component={"li"}
                      sx={{
                        fontSize: "7pt"
                      }}
                    >
                      이용자 : “고객사”의 재화 또는 용역을 이용하는 자를 말한다.
                    </Typography>
                    <Typography
                      component={"li"}
                      sx={{
                        fontSize: "7pt"
                      }}
                    >
                      대표가맹점, 대표가맹점 상점 : “고객사”가 신용카드 가맹점이
                      아닌 경우 “회사”가 “고객사”를 위하여 신용카드 처리업무를
                      대행하기 위해 자신의 명의로 개설한 신용카드 가맹점을
                      대표가맹점이라 한다. 대표가맹점을 이용하여 이용자에게
                      상품을 제공하는 “고객사”의 매장을 대표가맹점 상점이라고
                      한다.
                    </Typography>
                    <Typography
                      component={"li"}
                      sx={{
                        fontSize: "7pt"
                      }}
                    >
                      상품 : “고객사”가 “회사”의 전자결제서비스를 이용하여
                      이용자에게 제공하는 유무형의 재화와 용역을 말하며,
                      상용프로그램, 데이터는 물론 회원이 인터넷 상에서 대금을
                      지불하고 구매할 수 있는 모든 대상을 포함한다.
                    </Typography>
                    <Typography
                      component={"li"}
                      sx={{
                        fontSize: "7pt"
                      }}
                    >
                      전자결제서버 : “고객사”가 “회사”의 전자결제서비스를
                      이용하여 회원에게 판매한 상품의 대금결제를 위하여 ”회사”가
                      운영하는 전산시스템을 말한다.
                    </Typography>
                    <Typography
                      component={"li"}
                      sx={{
                        fontSize: "7pt"
                      }}
                    >
                      본인인증서비스 : 신용카드사가 제공하는 서비스로 카드번호,
                      유효기간 외에 생년월일 및 비밀번호의 일부를 입력 받아
                      카드의 유효성을 확인해 주는 서비스를 말한다. (단, 상기
                      본인인증 서비스는 카드의 유효성을 확인해 주는 서비스로
                      실제 본인 사용여부를 확인하는 것은 아니다.)
                    </Typography>
                    <Typography
                      component={"li"}
                      sx={{
                        fontSize: "7pt"
                      }}
                    >
                      결제기관 : “서비스”를 위하여 결제인가, 수납대행, 결제대금
                      입금을 제공하는 신용카드사업자, 은행 등의 원천 기관을
                      말한다.
                    </Typography>
                    <Typography
                      component={"li"}
                      sx={{
                        fontSize: "7pt"
                      }}
                    >
                      서비스 제한 또는 불가업종 : “결제기관” 또는 관계법령 등에
                      의하여 결제서비스의 이용이 제한되거나 불가능한 업종을
                      의미하며, “본 계약” 체결 이후에도 “결제기관” 또는 관계법령
                      등에 의하여 업종 및 제한사항은 추가, 삭제 및 변동될 수
                      있다.
                    </Typography>
                  </Box>
                  <Typography
                    sx={{ fontSize: "8px", fontWeight: 700, mt: 1, ml: 1 }}
                  >
                    제 3 조 (계약기간)
                  </Typography>
                  <Box component={"ol"} sx={{ ml: -2.5, mt: 0, mb: 0 }}>
                    <Typography
                      component={"li"}
                      sx={{
                        fontSize: "7pt"
                      }}
                    >
                      본 계약의 유효기간은 계약체결일로부터{" "}
                      {data?.merchantInformation.contractPeriod}년간 유효하다.
                    </Typography>
                    <Typography
                      component={"li"}
                      sx={{
                        fontSize: "7pt"
                      }}
                    >
                      전항의 유효기간 만료 1개월 전까지 어느 일방 또는 쌍방에
                      의한 서면 및 유선(이메일)의 통보가 없을 경우 본 계약은
                      동일한 조건으로 1년간 자동 연장되며 그 이후도 같다.
                    </Typography>
                    <Typography
                      component={"li"}
                      sx={{
                        fontSize: "7pt"
                      }}
                    >
                      본 계약의 유효기간 중에는 정당한 사유 없이 일방적으로
                      계약을 해지할 수 없다. 다만, 본 계약 제5조 각 항에
                      해당하는 경우에는 그러하지 아니하다.
                    </Typography>
                  </Box>
                  <Typography
                    sx={{ fontSize: "8px", fontWeight: 700, mt: 1, ml: 1 }}
                  >
                    제 4 조 (서비스 이용수수료)
                  </Typography>
                  <Typography sx={{ fontSize: "7pt", ml: 1 }}>
                    “회사”가 “고객사”에게 제공하는 전자결제서비스에 대한 수수료
                    및 이용 서비스 내역은 부칙2 와 같다.
                  </Typography>
                  <Typography
                    sx={{ fontSize: "8px", fontWeight: 700, mt: 1, ml: 1 }}
                  >
                    제 5 조 (계약의 해지 및 연장)
                  </Typography>
                  <Typography sx={{ fontSize: "7pt", ml: 1 }}>
                    다음의 각 항의 어느 하나에 해당하는 경우에 양 당사자는
                    계약기간 중에도 불구하고, 서면, 유선 및 이메일 통보로서 이
                    계약을 해지할 수 있다.
                  </Typography>
                  <Box component={"ol"} sx={{ ml: -2.5, mt: 0, mb: 0 }}>
                    <Typography
                      component={"li"}
                      sx={{
                        fontSize: "7pt"
                      }}
                    >
                      “고객사” 또는 “회사”가 본 계약 및 관련 부속약정 사항 또는
                      관련 법령을 위반한 경우.
                    </Typography>
                    <Typography
                      component={"li"}
                      sx={{
                        fontSize: "7pt"
                      }}
                    >
                      “고객사” 또는 “회사”가 고의 또는 과실로 상대방의 명예나
                      신용을 실추시킨 경우
                    </Typography>
                    <Typography
                      component={"li"}
                      sx={{
                        fontSize: "7pt"
                      }}
                    >
                      “고객사” 또는 “회사”에게 국세 또는 지방세의 체납처분,
                      파산, 화의, 회사정리 등의 절차가 개시 또는 신청되는 경우
                    </Typography>
                    <Typography
                      component={"li"}
                      sx={{
                        fontSize: "7pt"
                      }}
                    >
                      “고객사”가 제공한 상품에 관하여 회원의 이의제기(철회.
                      항변권 및 본인 미사용 주장 포함)나 신용 구매분에 대한
                      카드사의 대금연체가 대량 또는 빈번히 발생하여 더 이상
                      정상적인 거래가 부적합하다고 결제기관 또는 “회사”가
                      판단하는 경우
                    </Typography>
                    <Typography
                      component={"li"}
                      sx={{
                        fontSize: "7pt"
                      }}
                    >
                      상대방의 사전 서면동의 없이 본 계약과 관련된 “고객사”의
                      소유권 기타 권리의 전부나 일부를 제3자에게 임대, 판매,
                      양도하는 경우 “고객사”의 사이트에서 불법 또는
                      부정매출(현금성 대출처리 등)이 발생한 경우
                    </Typography>
                    <Typography
                      component={"li"}
                      sx={{
                        fontSize: "7pt"
                      }}
                    >
                      기타 건전한 상거래관행과 신의성실의 원칙에 어긋나는 행위로
                      인해 정상적인 계약유지가 어렵다고 판단되는 경우
                    </Typography>
                    <Typography
                      component={"li"}
                      sx={{
                        fontSize: "7pt"
                      }}
                    >
                      “회사”의 심사 기준상 비정상적인 인터넷 전자상거래라고
                      판단되는 경우 또는 최초 “회사”와 계약 당시의 물품 및
                      서비스 제공이 상이하거나 “회사”에 통보 없이
                    </Typography>
                  </Box>
                </Box>
                {/* 본문 오른쪽 */}
                <Box
                  sx={{
                    width: "95mm"
                  }}
                >
                  <Box
                    component={"ol"}
                    start={8}
                    sx={{ ml: -2.5, mt: 1, mb: 0 }}
                  >
                    <Typography
                      sx={{
                        fontSize: "7pt"
                      }}
                    >
                      추가 판매 및 서비스를 제공하는 경우 (예: 다단계판매,
                      자점매출, 현금깡, 경매, 사업자등록증에 명기되지 않은
                      물품[서비스]판매, "회사"의 심사 기준상 불가한 상품 및
                      서비스 형태의 서비스 제공 등)
                    </Typography>
                    <Typography
                      component={"li"}
                      sx={{
                        fontSize: "7pt"
                      }}
                    >
                      특별한 사유 없이 3개월 이상 결제승인 요청이 없거나 사전
                      서면 통지없이 타 업체로 전자결제서비스를 변경 또는
                      중복하여 이용하는 경우
                    </Typography>
                    <Typography
                      component={"li"}
                      sx={{
                        fontSize: "7pt"
                      }}
                    >
                      “회사”에게 폐업 및 해제요청을 통보하지 않은 경우
                    </Typography>
                    <Typography
                      component={"li"}
                      sx={{
                        fontSize: "7pt"
                      }}
                    >
                      "고객사"가 "회사"에게 등록 요청한 사이트(홈페이지)외 제3의
                      사이트에서 매출이 발생한 경우
                    </Typography>
                    <Typography
                      component={"li"}
                      sx={{
                        fontSize: "7pt"
                      }}
                    >
                      제19조 (계약 변경 사항 통보 및 손해배상)에 명시한
                      변경내용의 통보가 되지 않은 상태에서 "고객사"가 쇼핑몰을
                      운영하여 "회사"가 이를 확인하고 "회사"의
                      전자결제심사기준에 부적합한 경우
                    </Typography>
                    <Typography
                      component={"li"}
                      sx={{
                        fontSize: "7pt"
                      }}
                    >
                      “고객사”와 “회사”가 서면 합의한 경우
                    </Typography>
                    <Typography
                      component={"li"}
                      sx={{
                        fontSize: "7pt"
                      }}
                    >
                      “고객사” 이용자의 거래 취소 및 환불이 과다하게 발생하거나
                      지속해서 발생하는 경우, “회사”는 “고객사”에 서면으로
                      통지하고 계약을 해지할 수 있다.
                    </Typography>
                  </Box>
                  <Typography
                    sx={{ fontSize: "8px", fontWeight: 700, mt: 1, ml: 1 }}
                  >
                    제 6 조 (“고객사”의 의무)
                  </Typography>
                  <Box component={"ol"} sx={{ ml: -2.5, mt: 0, mb: 0 }}>
                    <Typography
                      component={"li"}
                      sx={{
                        fontSize: "7pt"
                      }}
                    >
                      “고객사”는 상품에 대한 제조, 판매 등과 관련된 제반 법규를
                      준수하여야 하며, 관계당국의 허가, 인가 또는 신고 등 제반
                      절차가 필요한 경우 이를 반드시 필(必) 함으로써 적법하게
                      전자상거래행위를 하여야 한다.
                    </Typography>
                    <Typography
                      component={"li"}
                      sx={{
                        fontSize: "7pt"
                      }}
                    >
                      “고객사”가 “회사”의 파트너가 될 경우 “고객사”는 이용자와
                      “회사”에 대해 성실하고 신뢰성 있게 동 상점을 운영하여야
                      하며, “고객사”의 불성실한 상점 운영으로 인하여 “회사” 및
                      이용자에게 피해를 주지 않도록 하여야 한다.
                    </Typography>
                    <Typography
                      component={"li"}
                      sx={{
                        fontSize: "7pt"
                      }}
                    >
                      “고객사”는 “회사”의 전자결제서비스를 이용하기 위해
                      “고객사”측에 필요한 장치 및 프로그램을 설치해 두어야 한다.
                    </Typography>
                    <Typography
                      component={"li"}
                      sx={{
                        fontSize: "7pt"
                      }}
                    >
                      “고객사”는 “회사”의 전자결제서비스를 이용하는 대가로
                      부칙2의 전자결제 서비스 이용수수료 및 등록비, 연 관리비를
                      “회사”에게 지불하기로 한다.
                    </Typography>
                    <Typography
                      component={"li"}
                      sx={{
                        fontSize: "7pt"
                      }}
                    >
                      “고객사”는 상품의 주문 및 지불완료를 확인한 후에
                      “고객사”가 정한 방법에 따라 이용자에게 배송(혹은
                      서비스제공) 하며, 이때 “고객사”는 이용자의 부정한 거래에
                      대비하여 향후 거래자의 확인 또는 추적이 가능하도록 별도로
                      정하는 증빙자료를 확보하여야 한다.
                    </Typography>
                    <Typography
                      component={"li"}
                      sx={{
                        fontSize: "7pt"
                      }}
                    >
                      “고객사”가 “회사”의 대표가맹점 상점으로서 상품을 판매한
                      경우, ‘㈜옵타움플랫폼'의 명의로 대금이 청구됨을 이용자가
                      쉽게 인식할 수 있도록 동 상점의 주요화면에 명시하여야
                      하며, 이때 “회사”의 오직페이 로고(이미지)를 삽입한다.
                    </Typography>
                    <Typography
                      component={"li"}
                      sx={{
                        fontSize: "7pt"
                      }}
                    >
                      "고객사"는 신용카드가 사용될 때(승인) 본인에 의해서
                      사용되는지 필히 확인하여야 할 의무가 있으며, 추후 타인의
                      사용으로 인한 민원 및 "회사"에게 피해 발생 시 해결될
                      때까지 모든 책임은 "고객사"가 지도록 한다.
                    </Typography>
                    <Typography
                      component={"li"}
                      sx={{
                        fontSize: "7pt"
                      }}
                    >
                      거래의 취소, 환불 또는 부정한 거래로 인한 사고 시 이용자의
                      확인, 추적 또는 채권회수를 위하여 “회사”가 필요로 하는
                      경우, “고객사”는 이용자에 관한 정보를 지체 없이 “회사”에게
                      전송한다. (이용자의 성명, 전화번호, 주소, 전자메일주소 등
                      일체의 배송 및 서비스 제공정보)
                      <Typography sx={{ fontSize: "7pt" }}>
                        8-1) 증빙자료에 대해 “고객사”는 매출일로부터 5년간
                        보관하여야 한다. "회사"의 제출 요구가 있을 시에는 제 3
                        영업일이내에 제출하여야 하며, 증빙자료 미제출 및 비정상
                        거래에 대한 책임은 "고객사"에게 있다.
                      </Typography>
                      <Typography sx={{ fontSize: "7pt" }}>
                        8-2) "고객사"는 "회사"의 결제시스템을 이용하여 이루어진
                        모든 거래내역 (이용자 성명, 주소, 전화번호, 이메일,
                        구매내용 등)에 대하여 반드시 이용자의 동의를 획득한 후
                        "회사"에게 제출하여야 하며, "회사"는 "고객사"로부터
                        제공받은 거래내역의 진위성 몇 본인사용여부를 이용자에게
                        확인할 수 있다.
                      </Typography>
                    </Typography>
                    <Typography
                      component={"li"}
                      sx={{
                        fontSize: "7pt"
                      }}
                    >
                      사고발생(쇼핑몰의 물품 미배송/부도, 미수금발생 등)에 따라
                      "고객사"에서 "회사"와의 계약을 준수하지 못할 경우를
                      대비하기 위한 최소한의 담보로써 “고객사”는 “회사”를
                      피보험자로 하여 보증보험에 가입한다.
                    </Typography>
                    <Typography
                      component={"li"}
                      sx={{
                        fontSize: "7pt"
                      }}
                    >
                      “고객사”는 계약해지 후 “회사”의 전자결제시스템 이용기간 중
                      발생한 거래에 대해 이용자가 민원(결제기관 접수 민원
                      포함)을 제기할 경우, 신의성실 원칙에 따라 해결하여야 하며,
                      그에 대한 결과를 “회사”와 이용자에게 통지하여야 한다.
                    </Typography>
                  </Box>
                  <Typography
                    sx={{ fontSize: "8px", fontWeight: 700, mt: 1, ml: 1 }}
                  >
                    제 7 조 (“회사”의 의무)
                  </Typography>
                  <Box component={"ol"} sx={{ ml: -2.5, mt: 0, mb: 0 }}>
                    <Typography
                      component={"li"}
                      sx={{
                        fontSize: "7pt"
                      }}
                    >
                      “회사”는 본 계약기간 중 이용자가 구입한 “고객사”의 상품과
                      관련하여 “고객사”가 그 판매대금을 원활히 지급 받을 수
                      있도록 신뢰성 있는 전자결제서비스를 제공한다.
                    </Typography>
                    <Typography
                      component={"li"}
                      sx={{
                        fontSize: "7pt"
                      }}
                    >
                      “회사”는 “고객사”의 상품을 구매한 이용자가
                      전자결제서비스를 이용하여 안전하고 편리하게 지불할 수
                      있도록 “고객사”가 운영하는 전자상거래 시스템과 “회사”의
                      전자결제서버가 연동하도록 지원한다.
                    </Typography>
                  </Box>
                </Box>
              </Stack>
            </Stack>
            {/* ============================== 바닥글 ============================== */}
            <Divider
              sx={{
                border: "none",
                borderTop: "1px dotted",
                borderColor: grey[700],
                mx: 1
              }}
            ></Divider>
            <Stack
              direction={"row"}
              justifyContent={"space-between"}
              sx={{ height: "7mm", color: grey[400], mx: 3, mt: 0 }}
            >
              <Typography sx={{ fontSize: "7pt" }}>
                국내 최고의 통합 결제 서비스 OZICPAY
              </Typography>
              <Typography sx={{ fontSize: "7pt" }}>페이지 1 / 9</Typography>
            </Stack>
          </Stack>
        </Card>
      </Box>
    </Fonts>
  );
};

export default Page1;
