import React, { useState } from "react";
import Paper from "@mui/material/Paper";
import CalendarHeader from "./CalendarHeader";
import CalendarBody from "./CalendarBody";

const Calendar = () => {
  const [date, setDate] = useState(new Date());
  return (
    <Paper
      elevation={0}
      sx={{
        px: 3,
        py: 2,
        border: "1px solid #F2F3F5",
        borderRadius: 3
      }}
    >
      <CalendarHeader date={date} setDate={setDate} />
      <CalendarBody date={date} />
    </Paper>
  );
};

export default Calendar;
