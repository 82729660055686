import { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import TextField from "@mui/material/TextField";
import MuiTable from "../Table/MuiTable";
import { useQuery } from "@tanstack/react-query";
import { getUsersByQuery } from "../../api/User";
import { UserSearchParam } from "../../api/User/types";
import LoadingButton from "@mui/lab/LoadingButton";
import SearchIcon from "@mui/icons-material/Search";
import { Controller, useForm } from "react-hook-form";
import MenuItem from "@mui/material/MenuItem";
import { User } from "./NotificationSendModal";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

interface Column {
  id: string;
  label: string;
}

const COLUMNS: Column[] = [
  {
    id: "email",
    label: "사용자 이메일"
  }
];

interface Props {
  closeModal?: () => void;
  addReceiverEvent?: (selectedData: User[]) => void;
}

const UserSearchModal = ({ closeModal, addReceiverEvent }: Props) => {
  const [selectedUsers, setSelectedUsers] = useState<User[]>([]);

  const { control, register, getValues } = useForm<UserSearchParam>({
    defaultValues: { searchKeyword: "", type: "EMAIL" }
  });

  // ===========================================================================
  // 사용자 검색
  // ===========================================================================
  const { data, refetch, isFetching } = useQuery(
    [`/users/search`],
    () => getUsersByQuery(getValues()),
    {
      enabled: false,
      cacheTime: 0
    }
  );

  const retrieve = () => {
    refetch();
  };

  // ===========================================================================
  // 사용자 추가
  // - 현재 저장된 선택된 사용자 목록을 부모 컴포넌트에 전달한다
  // - 모달을 닫는다
  // ===========================================================================
  const addReceivers = () => {
    addReceiverEvent?.(selectedUsers);
    closeModal?.();
  };

  return (
    <Dialog open={true} maxWidth={"sm"}>
      <DialogTitle>
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Typography>사용자 검색 및 추가</Typography>
          <IconButton type="button" onClick={closeModal}>
            <CloseIcon />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <Stack spacing={2}>
          <form>
            <Stack
              direction={"row"}
              spacing={2}
              sx={{ pt: 1 }}
              justifyContent={"space-between"}
            >
              <Box sx={{ minWidth: "170px" }}>
                <Controller
                  name="type"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      label="검색 유형"
                      required
                      size="medium"
                      select
                      value={value}
                      onChange={onChange}
                      fullWidth
                    >
                      <MenuItem value="EMAIL">사용자 이메일</MenuItem>
                      <MenuItem value="MALL_NAME">가맹점 이름</MenuItem>
                      <MenuItem value="REGISTRATION_NUMBER">
                        사업자 등록번호
                      </MenuItem>
                    </TextField>
                  )}
                />
              </Box>
              <TextField
                autoFocus={true}
                label={"검색어"}
                required
                sx={{ width: "100%" }}
                {...register("searchKeyword", { required: true })}
              ></TextField>
              <LoadingButton
                loading={isFetching}
                loadingPosition="start"
                startIcon={<SearchIcon />}
                variant="outlined"
                sx={{ minWidth: "90px", minHeight: "56px" }}
                type={"button"}
                onClick={retrieve}
              >
                검색
              </LoadingButton>
            </Stack>
          </form>
          <Box>
            <Divider />
            <MuiTable
              id="id"
              rows={data?.content}
              columns={COLUMNS}
              setRowSelection={setSelectedUsers}
              selectedValues={selectedUsers}
            ></MuiTable>
          </Box>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button
          size={"large"}
          color={"primary"}
          variant={"contained"}
          fullWidth
          sx={{
            color: "white",
            mx: 2,
            mb: 2
          }}
          onClick={addReceivers}
        >
          추가
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UserSearchModal;
