import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import LockIcon from "@mui/icons-material/Lock";
import Divider from "@mui/material/Divider";
import { useMutation } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link as ReactLink, useNavigate } from "react-router-dom";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import LoadingButton from "../components/LoadingButton";
import MiddleLogoutBtn from "../components/MiddleLogoutBtn.tsx";
import Title from "../components/Title";
import Stack from "@mui/material/Stack";
import isModalOpenAtom from "../recoil/isModalOpen";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import LoginInfoAtom, { withToken } from "../recoil/loginInfo";
import { resendEmail, verifyEmail } from "../api/Email";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";

export interface EmailForm {
  code: string;
  collectPersonal: boolean;
  receiveMarketing: boolean;
}

const Email = () => {
  const navigate = useNavigate();

  // ===================================================================================================================
  // 리코일 스테이트
  // ===================================================================================================================
  const authorization = useRecoilValue(withToken);
  const [loginInfo, setLoginInfo] = useRecoilState(LoginInfoAtom);

  // ===================================================================================================================
  // 스테이트
  // ===================================================================================================================
  // 동의 체크박스
  const [checkPrivacy, setCheckPrivacy] = useState(false);
  const [checkMarketing, setCheckMarketing] = useState(false);

  // ===================================================================================================================
  // 리액트 쿼리
  // ===================================================================================================================
  // 이메일 인증
  const { mutate, isLoading: isLoadingEmail } = useMutation(verifyEmail);

  // 이메일 인증 코드 재전송
  const { mutate: mutateResendEmail, isLoading: isLoadingResendEmail } =
    useMutation(resendEmail);
  const setIsModalOpen = useSetRecoilState(isModalOpenAtom);
  const { register, handleSubmit, reset } = useForm<EmailForm>({
    defaultValues: {
      code: ""
    }
  });

  // ===================================================================================================================
  // 이메일 인증
  // - 약관 동의를 하지 않은 사용자라면 개인정보 수집 및 이용에 동의해야 한다
  // ===================================================================================================================
  const onValid = (data: EmailForm) => {
    if (!loginInfo.loginUser.collectPersonal && !checkPrivacy) {
      setIsModalOpen({
        value: true,
        position: "top",
        alertSeverity: "error",
        message: "개인정보 수집 및 이용에 동의해야 합니다."
      });
      return;
    }

    if (isLoadingEmail) return;
    mutate(
      {
        ...data,
        authorization,
        collectPersonal: checkPrivacy,
        receiveMarketing: checkMarketing
      },
      {
        onError: (error: any) => {
          setIsModalOpen({
            value: true,
            position: "top",
            alertSeverity: "error",
            message: error?.response?.data?.message
          });
          reset();
        },
        onSuccess: (data) => {
          const newLoginInfo = {
            ...loginInfo,
            accessToken: data.content.newToken
          };
          setLoginInfo(newLoginInfo);
          sessionStorage.setItem("loginInfo", JSON.stringify(newLoginInfo));
          if (loginInfo.loginUser.passwordUpdateFlag === "Y") {
            return navigate("/password", {
              replace: true
            });
          }
          if (loginInfo.loginUser.role === "ADMIN") {
            navigate("/admin", {
              replace: true
            });
          } else {
            navigate("/", {
              replace: true
            });
          }
        }
      }
    );
  };

  // 이메일 인증 코드 재전송
  const onResendEmail = () => {
    if (isLoadingResendEmail) return;
    mutateResendEmail(authorization, {
      onError: (error: any) => {
        setIsModalOpen({
          value: true,
          position: "top",
          alertSeverity: "error",
          message: error?.response?.data?.message
        });
      },
      onSuccess: () => {
        setIsModalOpen({
          value: true,
          position: "top",
          alertSeverity: "success",
          message: "인증번호를 재발급했습니다."
        });
      }
    });
  };
  useEffect(() => {
    if (!authorization) {
      return navigate("/login", { replace: true });
    }
  }, [authorization, navigate]);

  // ===================================================================================================================
  // 체크박스 상태 변경 핸들러 함수
  // ===================================================================================================================
  // 개인정보 수집 동의 체크박스
  const handleCheckPrivacy = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckPrivacy(event.target.checked);
  };

  // 마케팅 수신 동의 체크박스
  const handleMarketing = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckMarketing(event.target.checked);
  };

  return (
    <>
      <Title title="이메일" />
      <MiddleLogoutBtn />
      <Box
        sx={{
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          px: 2
        }}
      >
        <Paper
          sx={{
            maxWidth: 500,
            width: "100%",
            py: 10,
            px: 8,
            border: "1px solid #E4E4E7",
            borderRadius: 2
          }}
          elevation={0}
        >
          <Typography
            variant="h4"
            align="center"
            sx={{
              mb: 5
            }}
          >
            이메일 인증
          </Typography>
          <form onSubmit={handleSubmit(onValid)}>
            <Grid container>
              <Grid item xs={12}>
                <TextField
                  label="Code"
                  fullWidth
                  required
                  helperText="이메일로 발급받은 코드를 입력해주세요."
                  {...register("code", {
                    required: "발급받은 코드를 입력해주세요."
                  })}
                />
              </Grid>
              {!loginInfo.loginUser.collectPersonal ? (
                <Grid item xs={12}>
                  <Stack
                    mt={1}
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <FormControlLabel
                      label="[필수] 개인정보 수집 및 이용동의"
                      control={
                        <Checkbox
                          checked={checkPrivacy}
                          onChange={handleCheckPrivacy}
                        />
                      }
                    />
                    <Link
                      component={ReactLink}
                      to="/terms/privacy"
                      color="inherit"
                      underline="hover"
                      target="_blank"
                      sx={{
                        "&:hover": {
                          color: "primary.main"
                        }
                      }}
                    >
                      (내용 보기)
                    </Link>
                  </Stack>
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <FormControlLabel
                      label="[선택] 광고·마케팅 수신 동의"
                      control={
                        <Checkbox
                          checked={checkMarketing}
                          onChange={handleMarketing}
                        />
                      }
                    />
                    <Link
                      component={ReactLink}
                      to="/terms/marketing"
                      color="inherit"
                      underline="hover"
                      target="_blank"
                      sx={{
                        "&:hover": {
                          color: "primary.main"
                        }
                      }}
                    >
                      (내용 보기)
                    </Link>
                  </Stack>
                </Grid>
              ) : (
                ""
              )}
            </Grid>
            <Stack
              spacing={2}
              sx={{
                mt: 2
              }}
            >
              <LoadingButton
                icon={<LockIcon />}
                fullWidth={true}
                size="large"
                loading={isLoadingEmail}
              >
                입 력
              </LoadingButton>
              <Divider />
              <LoadingButton
                variant="outlined"
                icon={<RestartAltIcon />}
                fullWidth={true}
                size="large"
                loading={isLoadingResendEmail}
                handleClick={onResendEmail}
              >
                다시 받기
              </LoadingButton>
            </Stack>
          </form>
        </Paper>
      </Box>
    </>
  );
};

export default Email;
