import CloseIcon from "@mui/icons-material/Close";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import InputLabel from "@mui/material/InputLabel";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { Controller, useForm } from "react-hook-form";
import {
  CardBinForms,
  CardBinResponse
} from "../../api/CardBinManagement/types";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import { HelperTextMessage } from "../Merchants/TerminateContractModal";
import { useEffect } from "react";
import { useMutation } from "@tanstack/react-query";
import { putCardBin } from "../../api/CardBinManagement";
import { useSetRecoilState } from "recoil";
import isModalOpen from "../../recoil/isModalOpen";

interface CardBinEditModalProps {
  isOpen: boolean;
  onClose: () => void;
  cardCompanies: string[];
  data: CardBinResponse;
  refresh: () => void;
}

const CardBinEditModal = ({
  isOpen,
  onClose,
  cardCompanies,
  data,
  refresh
}: CardBinEditModalProps) => {
  /*********************************************************************************************************************
   * 리코일 스테이트
   ********************************************************************************************************************/
  const setIsModalOpen = useSetRecoilState(isModalOpen);

  /*********************************************************************************************************************
   * 모달 닫기
   ********************************************************************************************************************/
  const handleClose = () => {
    reset();
    onClose();
  };

  /*********************************************************************************************************************
   * 리액트 훅 폼
   ********************************************************************************************************************/
  const {
    handleSubmit,
    register,
    control,
    reset,
    formState: { errors, isDirty }
  } = useForm<CardBinForms>({
    defaultValues: {
      binCode: "",
      brand: "",
      businessType: "",
      creditOrCheck: "",
      etc: "",
      gradeType: "",
      issuer: "",
      typeGubun: "WHITE"
    }
  });

  /*********************************************************************************************************************
   * 초기값 세팅
   ********************************************************************************************************************/
  useEffect(() => {
    if (isOpen && data) {
      reset(data);
    }
  }, [isOpen, data, reset]);

  /*********************************************************************************************************************
   * 리액트 쿼리
   ********************************************************************************************************************/
  const { mutate } = useMutation(putCardBin);

  /*********************************************************************************************************************
   * 폼 제출 = 수정
   ********************************************************************************************************************/
  const formSubmit = (formData: CardBinForms) => {
    mutate(formData, {
      onSuccess: () => {
        setIsModalOpen({
          value: true,
          position: "top",
          alertSeverity: "success",
          message: "카드 BIN 수정을 완료했습니다."
        });
        refresh();
        handleClose();
      },
      onError: (error: any) => {
        setIsModalOpen({
          value: true,
          position: "top",
          alertSeverity: "error",
          message: error?.response?.data?.message
        });
      }
    });
  };

  return (
    <Dialog open={isOpen} fullWidth={true} maxWidth={"sm"}>
      <DialogTitle>
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Typography variant={"h5"}>카드 BIN 수정</Typography>
          <IconButton
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 12
            }}
          >
            <CloseIcon />
          </IconButton>
        </Stack>
      </DialogTitle>

      {/* 모달 바디 */}
      <DialogContent>
        <form onSubmit={handleSubmit(formSubmit)}>
          <Stack gap={3} mt={2}>
            <Stack direction={"row"} alignItems={"center"}>
              <InputLabel
                htmlFor="issuer"
                sx={{ fontWeight: "bold", width: "100%", maxWidth: "160px" }}
              >
                카드사명
                <Typography color={"red"} variant={"body2"}>
                  (필수값)
                </Typography>
              </InputLabel>
              <Controller
                name="issuer"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    id="issuer"
                    label="카드사 선택"
                    fullWidth
                    size="small"
                    select
                    value={value}
                    onChange={onChange}
                    error={!!errors.issuer}
                    helperText={
                      <HelperTextMessage>
                        {errors.issuer?.message}
                      </HelperTextMessage>
                    }
                  >
                    {cardCompanies &&
                      cardCompanies.map((company, index) => (
                        <MenuItem value={company} key={index}>
                          {company}
                        </MenuItem>
                      ))}
                  </TextField>
                )}
                rules={{
                  required: "카드사명은 필수값 입니다."
                }}
              />
            </Stack>
            <Stack direction={"row"} alignItems={"center"}>
              <InputLabel
                htmlFor="binCode"
                sx={{ fontWeight: "bold", width: "100%", maxWidth: "160px" }}
              >
                카드 BIN
                <Typography color={"red"} variant={"body2"}>
                  (필수값)
                </Typography>
              </InputLabel>
              <Controller
                name={"binCode"}
                control={control}
                render={({ field: { value, onChange } }) => (
                  <TextField
                    id={"binCode"}
                    label="카드 BIN Code"
                    fullWidth
                    size="small"
                    value={value}
                    onChange={onChange}
                    error={!!errors.binCode}
                    helperText={
                      <HelperTextMessage>
                        {errors.binCode?.message}
                      </HelperTextMessage>
                    }
                    disabled={true}
                  />
                )}
                rules={{
                  required: "카드 BIN 은 필수값 입니다."
                }}
              />
            </Stack>
            <Stack direction={"row"} alignItems={"center"}>
              <InputLabel
                htmlFor="typeGubun"
                sx={{ fontWeight: "bold", width: "100%", maxWidth: "160px" }}
              >
                필터 타입
                <Typography color={"red"} variant={"body2"}>
                  (필수값)
                </Typography>
              </InputLabel>
              <Controller
                name="typeGubun"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    id="typeGubun"
                    label="필터타입 선택"
                    fullWidth
                    size="small"
                    select
                    value={value}
                    onChange={onChange}
                    error={!!errors.typeGubun}
                    helperText={
                      <HelperTextMessage>
                        {errors.typeGubun?.message}
                      </HelperTextMessage>
                    }
                  >
                    <MenuItem value={"WHITE"} key={"WHITE"}>
                      화이트 리스트
                    </MenuItem>
                    <MenuItem value={"BLACK"} key={"BLACK"}>
                      블랙 리스트
                    </MenuItem>
                  </TextField>
                )}
                rules={{
                  required: "필터 타입은 필수값 입니다."
                }}
              />
            </Stack>
            <Stack direction={"row"} alignItems={"center"}>
              <InputLabel
                htmlFor="brand"
                sx={{ fontWeight: "bold", width: "100%", maxWidth: "160px" }}
              >
                브랜드 구분
              </InputLabel>
              <TextField
                id={"brand"}
                label="ex) Master, VISA 등"
                fullWidth
                size="small"
                {...register("brand")}
              />
            </Stack>
            <Stack direction={"row"} alignItems={"center"}>
              <InputLabel
                htmlFor="businessType"
                sx={{ fontWeight: "bold", width: "100%", maxWidth: "160px" }}
              >
                회원 구분
              </InputLabel>
              <TextField
                id={"businessType"}
                label="ex) 개인, 법인 등"
                fullWidth
                size="small"
                {...register("businessType")}
              />
            </Stack>
            <Stack direction={"row"} alignItems={"center"}>
              <InputLabel
                htmlFor="gradeType"
                sx={{ fontWeight: "bold", width: "100%", maxWidth: "160px" }}
              >
                등급 구분
              </InputLabel>
              <TextField
                id={"gradeType"}
                label="ex) 일반, 플래티넘 등"
                fullWidth
                size="small"
                {...register("gradeType")}
              />
            </Stack>
            <Stack direction={"row"} alignItems={"center"}>
              <InputLabel
                htmlFor="creditOrCheck"
                sx={{ fontWeight: "bold", width: "100%", maxWidth: "160px" }}
              >
                카드 구분
              </InputLabel>
              <TextField
                id={"creditOrCheck"}
                label="ex) 체크카드, 신용카드 등"
                fullWidth
                size="small"
                {...register("creditOrCheck")}
              />
            </Stack>
            <Stack direction={"row"} alignItems={"center"}>
              <InputLabel
                htmlFor="etc"
                sx={{ fontWeight: "bold", width: "100%", maxWidth: "160px" }}
              >
                비고
              </InputLabel>
              <TextField
                id={"etc"}
                label="비고"
                fullWidth
                size="small"
                {...register("etc")}
              />
            </Stack>
            {/* 수정 버튼 --------------------------------------------------------------------------------------*/}
            <Button
              type="submit"
              variant="contained"
              fullWidth
              sx={{ color: "white" }}
              disabled={!isDirty}
            >
              수정 하기
            </Button>
          </Stack>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default CardBinEditModal;
