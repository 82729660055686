import { JSEncrypt } from "jsencrypt";

interface IParams {
  password: string;
  publicKey: string | undefined;
}

export const encrypting = ({ password, publicKey }: IParams) => {
  if (!publicKey) return "";
  const module = new JSEncrypt();
  module.setPublicKey(publicKey);
  return module.encrypt(password);
};
