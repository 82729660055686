
import { atom } from "recoil";
import { SettlementsParams } from "../../api/v2/Settlements.ts/types";
import { getToday } from "../../libs/get-today";

/**
 * 정산내역 리코일 스테이트
 */
const SettlementsState = atom<SettlementsParams>({
  key: "SettlementsState",
  default: {
    startDate: getToday(),
    endDate: getToday(),
    isPayOut: "전체",
    mallId: "",
    mallName: "",
    sort: "DESC"
  }
});
export default SettlementsState;