import CloseIcon from "@mui/icons-material/Close";
import MuiLoadingButton from "@mui/lab/LoadingButton";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Pagination from "@mui/material/Pagination";
import { useQuery } from "@tanstack/react-query";
import React, { useState } from "react";
import { useSetRecoilState } from "recoil";
import { getSettlementsDetailExcel } from "../../api/v2/Settlements.ts";
import excelDownload from "../../libs/excel-download";
import { addCommas } from "../../libs/thousands-commas";
import isModalOpenAtom from "../../recoil/isModalOpen";
import MuiTable from "../Table/MuiTable";
import { SettlementDetailData } from "../../api/v2/Settlements.ts/types.js";
import {
  getClientSettlementDetail,
  getClientSettlementDetailExcel
} from "../../api/MerchantSettlement";

interface SettlementUnitColumnHeadCell {
  id: keyof SettlementDetailData;
  label: string;
}

const unitColumns: SettlementUnitColumnHeadCell[] = [
  {
    id: "mallId",
    label: "가맹점 ID"
  },
  {
    id: "mallName",
    label: "가맹점명"
  },
  {
    id: "createdAt",
    label: "거래 일자"
  },
  {
    id: "approvalNum",
    label: "승인 번호"
  },
  {
    id: "cardCompany",
    label: "카드사"
  },
  {
    id: "status",
    label: "거래 상태"
  },
  {
    id: "paymentKey",
    label: "거래 고유KEY"
  },
  {
    id: "price",
    label: "거래 금액"
  },
  {
    id: "purchasePrice",
    label: "매입 금액"
  },
  {
    id: "merchantFee",
    label: "가맹점 수수료"
  },
  {
    id: "vat",
    label: "부가세"
  },
  {
    id: "commissionFee",
    label: "총 수수료"
  },
  {
    id: "settlementPrice",
    label: "정산 금액"
  },
  {
    id: "soldDate",
    label: "정산 예정일"
  }
];

interface SettlementUnitModalProps {
  mallId: string;
  mallName: string;
  paidOutDate: string;
  isConfirm: string;
  isPayOut: string;
  closeModal?: () => void;
}

const ClientSettlementUnitModal = ({
  mallId,
  mallName,
  paidOutDate,
  isConfirm,
  isPayOut,
  closeModal
}: SettlementUnitModalProps) => {
  // ===============================================================================================
  // 리코일 스테이트
  // ===============================================================================================
  const [page, setPage] = useState(0);
  const setIsModalOpen = useSetRecoilState(isModalOpenAtom);

  // ===============================================================================================
  // 리액트 쿼리
  // ===============================================================================================
  // isConfirm 데이터 가공
  const newConfirm = isConfirm === "확정" ? "true" : "false";

  // 정산 개별 내역 조회
  const { data } = useQuery(
    [
      "client/settlements/detail",
      {
        isConfirm: newConfirm,
        isPayOut,
        page,
        pageSize: 50,
        mallId,
        mallName,
        paidOutDate
      }
    ],
    () =>
      getClientSettlementDetail({
        isConfirm: newConfirm,
        isPayOut,
        page,
        pageSize: 50,
        mallId,
        mallName,
        paidOutDate,
        sort : ""
      }),
    {
      onError: (error: any) => {
        setPage(0);
        closeModal?.();
        setIsModalOpen({
          value: true,
          position: "top",
          alertSeverity: "error",
          message: error?.response?.data?.message
        });
      },
      select: (res) => ({
        ...res,
        content: res.content.map((data: SettlementDetailData) => ({
          ...data,
          price: `${addCommas(data.price)}`,
          purchasePrice: `${addCommas(data.purchasePrice)}`,
          merchantFee: `${addCommas(data.merchantFee)}`,
          vat: `${addCommas(data.vat)}`,
          commissionFee: `${addCommas(data.commissionFee)}`,
          settlementPrice: `${addCommas(data.settlementPrice)}`
        }))
      })
    }
  );

  // 엑셀 다운로드
  const { isFetching, refetch } = useQuery(
    [
      "client/settlements/detail/excel",
      {
        isConfirm: newConfirm,
        isPayOut,
        mallId,
        mallName,
        paidOutDate
      }
    ],
    () =>
      getClientSettlementDetailExcel({
        isConfirm: newConfirm,
        isPayOut,
        mallId,
        mallName,
        paidOutDate
      }),
    {
      enabled: false,
      onSuccess: (data) => {
        const { content } = data;
        const list = content.map((d: any) => ({
          "가맹점 ID": d.mallId,
          가맹점명: d.mallName,
          "거래 일자": d.createdAt,
          "승인 번호": d.approvalNum,
          카드사: d.cardCompany,
          "거래 상태": d.status,
          "거래 고유KEY": d.paymentKey,
          "거래 금액": `${addCommas(d.price)}`,
          "매입 금액": `${addCommas(d.purchasePrice)}`,
          "가맹점 수수료": `${addCommas(d.merchantFee)}`,
          부가세: `${addCommas(d.vat)}`,
          "총 수수료": `${addCommas(d.commissionFee)}`,
          "정산 금액": `${addCommas(d.settlementPrice)}`,
          "정산 예정일": d.soldDate
        }));
        excelDownload({
          list,
          sheetName: `정산 개별 내역_${mallName}`,
          fileName: `정산개별내역_${mallName}.xlsx`
        });
        setIsModalOpen({
          value: true,
          position: "top",
          alertSeverity: "success",
          message: "다운로드를 시작합니다."
        });
      },
      onError: (error: any) => {
        setIsModalOpen({
          value: true,
          position: "top",
          alertSeverity: "error",
          message: error?.response?.data?.message
        });
      }
    }
  );

  const handleExcel = () => {
    if (isFetching) return;
    refetch();
  };

  // ===============================================================================================
  // 페이지네이션
  // ===============================================================================================
  const totalPages = data?.pageable?.totalPages
    ? data?.pageable?.totalPages
    : 0;
  const handlePage = (event: React.ChangeEvent<unknown>, page: number) => {
    setPage(page - 1);
  };

  return (
    <Dialog open={true} maxWidth="md" fullWidth>
      <DialogTitle>{paidOutDate} 개별 내역 조회</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={() => {
          setPage(0);
          closeModal?.();
        }}
        sx={{
          position: "absolute",
          right: 8,
          top: 12
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <Box>
          <MuiTable
            rows={data?.content}
            columns={unitColumns}
            id="id"
            redlineKey="status"
            redlineValue={["취소", "부분취소"]}
          />
        </Box>
        <Box mt={2}>
          <Pagination
            count={totalPages}
            variant="outlined"
            shape="rounded"
            page={page + 1}
            onChange={handlePage}
            sx={{ display: "flex", justifyContent: "center" }}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <MuiLoadingButton
          color="primary"
          loading={isFetching}
          onClick={handleExcel}
          fullWidth={true}
          variant="contained"
          sx={{
            color: "white"
          }}
        >
          엑셀 다운로드
        </MuiLoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default ClientSettlementUnitModal;
