import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Modal from "@mui/material/Modal";
import DaumPostcodeEmbed from "react-daum-postcode";

interface PostCodeProps {
  openPostCode: {
    isOpen: boolean;
    referenceIndex: number;
  };
  setOpenPostCode: (data: { isOpen: boolean; referenceIndex: number }) => void;
  PostCodeHandler: (data: any, refIndex?: number) => void;
}

const PostCode = ({
  openPostCode,
  setOpenPostCode,
  PostCodeHandler
}: PostCodeProps) => {
  // ===================================================================================================================
  // 주소 선택, 모달 닫기 핸들러
  // ===================================================================================================================
  const handle = {
    //주소 선택 이벤트
    selectAddress: (data: any) => {
      PostCodeHandler(data, openPostCode.referenceIndex);
      setOpenPostCode({
        isOpen: false,
        referenceIndex: openPostCode.referenceIndex
      });
    }
  };

  const handleClose = () =>
    setOpenPostCode({
      isOpen: false,
      referenceIndex: openPostCode.referenceIndex
    });

  //modal style
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 500,
    bgcolor: "background.paper",
    borderRadius: 1,
    boxShadow: 24,
    p: 3
  };

  return (
    <>
      <Modal open={openPostCode.isOpen}>
        <Box sx={style}>
          <Box sx={{ textAlign: "right", mb: 5 }}>
            <IconButton
              onClick={handleClose}
              sx={{
                position: "absolute",
                right: 8,
                top: 12
              }}
            >
              <CloseIcon />
            </IconButton>
          </Box>
          <DaumPostcodeEmbed
            onComplete={handle.selectAddress}
            autoClose={false}
          />
        </Box>
      </Modal>
    </>
  );
};

export default PostCode;
