import AddBusinessIcon from "@mui/icons-material/AddBusiness";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import ArticleIcon from "@mui/icons-material/Article";
import BugReportIcon from "@mui/icons-material/BugReport";
import CalculateIcon from "@mui/icons-material/Calculate";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import CircleIcon from "@mui/icons-material/Circle";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import GroupIcon from "@mui/icons-material/Group";
import KeyIcon from "@mui/icons-material/Key";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import NotificationsIcon from "@mui/icons-material/Notifications";
import PaymentsIcon from "@mui/icons-material/Payments";
import PlaylistAddCheckIcon from "@mui/icons-material/PlaylistAddCheck";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";
import RunningWithErrorsIcon from "@mui/icons-material/RunningWithErrors";
import { useRecoilValue } from "recoil";
import loginInfoAtom from "../../../recoil/loginInfo";
import MenuAccordion from "../../NavLink/MenuAccordion";

// =====================================================================================================================
// Client management (사용자 관리 메뉴)
// =====================================================================================================================
const ClientList = [
  {
    component: <BugReportIcon />,
    componentActive: <BugReportIcon color="primary" />,
    primary: "이력 관리",
    to: "/admin/histories",
    restricted: true
  },
  {
    component: <NotificationsIcon />,
    componentActive: <NotificationsIcon color="primary" />,
    primary: "알림 내역",
    to: "/admin/notifications"
  },

  {
    component: <ManageAccountsIcon />,
    componentActive: <ManageAccountsIcon color="primary" />,
    primary: "사용자 관리",
    to: "/admin/user-management"
  },
  {
    component: <GroupIcon />,
    componentActive: <GroupIcon color="primary" />,
    primary: "그룹 관리",
    to: "/admin/group-management"
  },
  {
    component: <KeyIcon />,
    componentActive: <KeyIcon color="primary" />,
    primary: "API 키 관리",
    to: "/admin/api-key"
  }
];

export const ClientListItems = () => {
  // ===================================================================================================================
  // 허용된 이메일만 '이력 관리'메뉴를 확인할 수 있다.
  // 메뉴 항목의 primary 속성이 '이력 관리'인 경우, 로그인한 사용자의 이메일이 allowedEmails 배열에 포함되어 있지 않으면 제외
  // ===================================================================================================================
  const loginInfo = useRecoilValue(loginInfoAtom);
  const allowedEmails = ["admin@ozicpay.com", "devops@optatumplatform.com"];

  const filteredClientList = ClientList.filter((list) => {
    if (
      list.primary === "이력 관리" &&
      !allowedEmails.includes(loginInfo.loginUser.email)
    ) {
      return false;
    }
    return true;
  });

  return <MenuAccordion title={"사용자 관리"} listItem={filteredClientList} />;
};

// =====================================================================================================================
// Merchant contract (계약 관리 메뉴)
// =====================================================================================================================
const ContractList = [
  {
    component: <AddBusinessIcon />,
    componentActive: <AddBusinessIcon color="primary" />,
    primary: "가맹점 등록",
    to: "/admin/regist-merchant"
  },
  {
    component: <RunningWithErrorsIcon />,
    componentActive: <RunningWithErrorsIcon color="primary" />,
    primary: "가맹 임시 저장",
    to: "/admin/temp-merchants"
  },
  {
    component: <PlaylistAddCheckIcon />,
    componentActive: <PlaylistAddCheckIcon color="primary" />,
    primary: "가맹 현황",
    to: "/admin/merchants"
  },
  {
    component: <ArticleIcon />,
    componentActive: <ArticleIcon color="primary" />,
    primary: "계약 관리",
    to: "/admin/contract-management"
  },
  {
    component: <CalendarMonthIcon />,
    componentActive: <CalendarMonthIcon color="primary" />,
    primary: "입금 예정 금액",
    to: "/admin/deposit-amount"
  },
  {
    component: <ReceiptLongIcon />,
    componentActive: <ReceiptLongIcon color="primary" />,
    primary: "정보 수정 관리",
    to: "/admin/request-list"
  }
];

export const ContractListItems = () => {
  return <MenuAccordion title={"가맹점 관리"} listItem={ContractList} />;
};

// =====================================================================================================================
// Payment (거래 메뉴)
// =====================================================================================================================
const PaymentList = [
  {
    component: <PaymentsIcon />,
    componentActive: <PaymentsIcon color="primary" />,
    primary: "거래 내역",
    to: "/admin/payments"
  }
];

export const PaymentListItems = () => {
  return <MenuAccordion title={"거래"} listItem={PaymentList} />;
};

// =====================================================================================================================
// Purchase (매입 메뉴)
// =====================================================================================================================
const PurchaseList = [
  {
    component: <AddShoppingCartIcon />,
    componentActive: <AddShoppingCartIcon color="primary" />,
    primary: "승인·취소 내역",
    to: "/admin/purchases"
  }
];

export const PurchaseListItems = () => {
  return <MenuAccordion title={"매입"} listItem={PurchaseList} />;
};

// =====================================================================================================================
// Settlement (정산 메뉴)
// =====================================================================================================================
const SettlementList = [
  {
    component: <CalculateIcon />,
    componentActive: <CalculateIcon color="primary" />,
    primary: "정산 내역",
    to: "/admin/settlements"
  },
  {
    component: <RequestQuoteIcon />,
    componentActive: <RequestQuoteIcon color="primary" />,
    primary: "청구금 내역",
    to: "/admin/settlements/charges"
  }
];

export const SettlementListItem = () => {
  return <MenuAccordion title={"정산 관리"} listItem={SettlementList} />;
};

// =====================================================================================================================
// Card limit management (카드사 관리)
// =====================================================================================================================
const CardLimitList = [
  {
    component: <CreditCardIcon />,
    componentActive: <CreditCardIcon color="primary" />,
    primary: "카드사 관리",
    to: "/admin/card-installments"
  },
  {
    component: <CircleIcon sx={{ fontSize: 12, ml: 1 }} />,
    componentActive: (
      <CircleIcon color="primary" sx={{ fontSize: 12, ml: 1 }} />
    ),
    primary: "카드 BIN 관리",
    to: "/admin/card-bin-managements"
  },
  {
    component: <CalculateIcon />,
    // componentActive: <CalculateIcon color="primary" />,
    primary: "전체 카드 한도 관리",
    to: "/admin/card-limit-management"
  },
  {
    component: <CalculateIcon />,
    // componentActive: <CalculateIcon color="primary" />,
    primary: "가맹점별 한도 관리",
    to: "/admin/merchant-card-limit-management"
  }
];

export const CardLimitListItem = () => {
  return <MenuAccordion title={"카드사 관리"} listItem={CardLimitList} />;
};
