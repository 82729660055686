import CloseIcon from "@mui/icons-material/Close";
import ErrorOutline from "@mui/icons-material/ErrorOutline";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Chip from "@mui/material/Chip";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { useMutation, useQuery } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { useSetRecoilState } from "recoil";
import { queryClient } from "../..";
import {
  getBankList,
  getBusinessNumber,
  merchantFileUpload,
  postMerchant
} from "../../api/MerchantRegistration";
import { downloadFile, getMerchantsDetail } from "../../api/Merchants";
import {
  FileUploadForm,
  selectFile,
  fileRequiredValidation as 파일유무검사,
  fileExtensionValidation as 파일확장자검사
} from "../../libs/file-upload";
import { engValidate, numberValidate } from "../../libs/input-validation";
import { HelperTextMessage } from "../../pages/Client/MerchantList";
import { ErrorMessage } from "../../pages/Client/MerchantRegistration";
import isModalOpen from "../../recoil/isModalOpen/atom";
import { CountryList } from "../CountryList";
import PostCode from "../PostCode";
import MerchantsAdminEdit from "./MerchantsAdminEdit";
import { uploadFiles } from "../../libs/file-mapping";
import Loading from "../Loadings/Loading";

// =====================================================================================================================
// 가맹 등록 필드 타입
// =====================================================================================================================
export interface Merchant {
  // 사업자 정보
  id: number;
  businessType: string;
  businessName: string;
  mallId: string;
  mallName: string;
  mallNameEn: string;
  registrationNumber: string;
  corporationNumber: string;
  postCode?: string;
  mallAddress?: string;
  detailAddress?: string;
  headOfficePostCode?: string;
  headOfficeAddress?: string;
  headOfficeDetailAddress?: string;
  mallPhone: string;
  fax: string;
  merchantCategory: string;
  businessConditions: string;
  mailOrderSalesNumber: string;
  mallUrl: string;
  mallIp: string;
  introductionCompany: string;
  testId: string;
  testPassword: string;

  // 대표자 정보
  merchantOwnerInformation: MerchantOwnerInformation[];

  // 은행 정보
  bankName: string;
  bankCode: string;
  accountCode: string;
  accountHolder: string;

  // 담당자 정보
  name: string;
  phone: string;
  email: string;
  managerName: string;
  managerPhone: string;
  managerEmail: string;
  taxName: string;
  taxPhone: string;
  taxEmail: string;

  // 파일 정보 - 개인사업자
  businessLicense: string;
  bankBookCopy: string;
  sealCertificate: string;
  idCardFront: string;
  idCardBack: string;
  gradeConfirmation: string;

  // 파일 정보 - 법인사업자
  corporateRegistration: string;
  actualOwnerConfirmation: string;
  corporateSealCertificate: string;
}

interface MerchantOwnerInformation {
  address: string;
  birth: string;
  name: string;
  nameEn: string;
  nationality: string;
  phone: string;
  ownerPostCode?: string;
  ownerAddress?: string;
  ownerDetailAddress?: string;
}

interface Bank {
  code: string;
  name: string;
}

interface Props {
  isOpen: boolean;
  closeModal: () => void;
  merchantId: any;
}

const AdminModifyClientDataModal = ({
  isOpen,
  closeModal,
  merchantId
}: Props) => {
  // ===================================================================================================================
  // 리코일 스테이트
  // ===================================================================================================================
  const setIsModalOpen = useSetRecoilState(isModalOpen);

  // ===================================================================================================================
  // 스테이트
  // ===================================================================================================================
  // 다음 주소 react-query api 호출 순서 스테이트
  const [isFirst, setIsFirst] = useState(true);
  // 업종 스테이트
  const [businessList, setBusinessList] = useState<string[]>([]);
  // 업태 스테이트
  const [businessStatus, setBusinessStatus] = useState<string[]>([]);
  // 은행 정보 스테이트
  const [selectedBank, setSelectedBank] = useState<Bank>({
    code: "",
    name: ""
  });
  // 주소 체크박스 스테이트
  const [isCheckedAddress, setIsCheckedAddress] = useState(false);
  // 담당자 정보 체크박스 스테이트
  const [isChecked, setIsChecked] = useState(false);
  // 세금 계산 담당자 체크박스 스테이트
  const [isCheckedTax, setIsCheckedTax] = useState(false);
  // 파일 데이터 스테이트
  const [fileData, setFileData] = useState<FileUploadForm>({
    // 개인 사업자
    businessLicense: undefined,
    bankBookCopy: undefined,
    sealCertificate: undefined,
    idCardFront: undefined,
    idCardBack: undefined,
    gradeConfirmation: undefined,
    // 법인사업자
    corporateRegistration: undefined,
    actualOwnerConfirmation: undefined,
    corporateSealCertificate: undefined
  });

  // 체크박스 스테이트
  const [isCheckedTestId, setIsCheckedTestId] = useState(false);

  // 프롬프트 스테이트
  const [openPrompt, setOpenPrompt] = useState(false);
  const handleOpenPrompt = () => {
    setOpenPrompt(true);
  };
  const handleClosePrompt = () => {
    setOpenPrompt(false);
  };

  // 사업자 정보 주소 스테이트
  const [openPostCode, setOpenPostCode] = useState({
    isOpen: false,
    referenceIndex: 0
  });
  // 대표자 주소 스테이트
  const [openOwnerPostCode, setOpenOwnerPostCode] = useState({
    isOpen: false,
    referenceIndex: 0
  });

  // ===================================================================================================================
  // 리액트쿼리 : 가맹점 재등록, 심사 상태 변경 -> 가맹심사중
  // ===================================================================================================================
  // 저장된 사용자 데이터 조회
  const { data, isLoading } = useQuery(
    [`/merchants/${merchantId}/clientEdit`],
    () => getMerchantsDetail({ merchantId }),
    {
      enabled: isOpen && isFirst,
      onSuccess: async (data) => {
        // 데이터 조회 시 에러메세지 삭제
        clearErrors();

        // 업종 데이터가 있는 경우 setBusinessList(빈 배열)에 listData값을 넣어준다
        const listData =
          data?.content.merchantInformation.merchantCategory.split(" | ");
        setBusinessList(listData);
        // 업태 데이터가 있는 경우 setBusinessList(빈 배열)에 StatusData값을 넣어준다
        const StatusData =
          data?.content.merchantInformation.businessConditions.split(" | ");
        setBusinessStatus(StatusData);

        // 사업자 정보 =================================================================================================
        setValue("businessType", data.content.merchantInformation.businessType);
        setValue("businessName", data.content.merchantInformation.businessName);
        setValue("mallName", data.content.mallName);
        setValue("mallNameEn", data.content.mallNameEn);
        setValue(
          "registrationNumber",
          data.content.merchantInformation.registrationNumber
        );
        setValue(
          "corporationNumber",
          data.content.merchantInformation.corporationNumber
        );
        setValue(
          "postCode",
          data.content.merchantInformation.address.split(" | ").at(0)
        );
        setValue(
          "mallAddress",
          data.content.merchantInformation.address.split(" | ").at(1)
        );
        setValue(
          "detailAddress",
          data.content.merchantInformation.address.split(" | ").at(2)
        );
        setValue(
          "headOfficePostCode",
          data.content.merchantInformation.headOfficeAddress.split(" | ").at(0)
        );
        setValue(
          "headOfficeAddress",
          data.content.merchantInformation.headOfficeAddress.split(" | ").at(1)
        );
        setValue(
          "headOfficeDetailAddress",
          data.content.merchantInformation.headOfficeAddress.split(" | ").at(2)
        );
        setValue("mallPhone", data.content.merchantInformation.phone);
        setValue("fax", data.content.merchantInformation.fax);
        setValue(
          "mailOrderSalesNumber",
          data.content.merchantInformation.mailOrderSalesNumber
        );
        setValue("mallUrl", data.content.mallUrl);
        setValue("mallIp", data.content.mallIp);
        setValue(
          "introductionCompany",
          data.content.merchantInformation.introductionCompany
        );
        setValue("testId", data.content.merchantInformation.testId);
        setValue("testPassword", data.content.merchantInformation.testPassword);

        // 대표자 정보 =================================================================================================
        setValue(
          "merchantOwnerInformation",
          data.content.merchantOwnerInformation
        );
        data.content.merchantOwnerInformation.forEach((owner, index) => {
          setValue(
            `merchantOwnerInformation.${index}.birth`,
            owner.birth.replace(/-/g, "")
          );
          setValue(
            `merchantOwnerInformation.${index}.ownerPostCode`,
            owner.address.split(" | ")[0]
          );
          setValue(
            `merchantOwnerInformation.${index}.ownerAddress`,
            owner.address.split(" | ")[1]
          );
          setValue(
            `merchantOwnerInformation.${index}.ownerDetailAddress`,
            owner.address.split(" | ")[2]
          );
        });

        // 은행 정보 ===================================================================================================
        setValue("bankName", data.content.merchantBankInformation.bankName);
        setValue(
          "accountCode",
          data.content.merchantBankInformation.accountCode
        );
        setValue(
          "accountHolder",
          data.content.merchantBankInformation.accountHolder
        );

        // 담당자 정보 =================================================================================================
        setValue("name", data.content.merchantInChargeInformation[0].name);
        setValue("phone", data.content.merchantInChargeInformation[0].phone);
        setValue("email", data.content.merchantInChargeInformation[0].email);
        setValue(
          "managerName",
          data.content.merchantInChargeInformation[1].name
        );
        setValue(
          "managerPhone",
          data.content.merchantInChargeInformation[1].phone
        );
        setValue(
          "managerEmail",
          data.content.merchantInChargeInformation[1].email
        );
        setValue("taxName", data.content.merchantInChargeInformation[2].name);
        setValue("taxPhone", data.content.merchantInChargeInformation[2].phone);
        setValue("taxEmail", data.content.merchantInChargeInformation[2].email);

        // 파일 정보 ===================================================================================================
        data.content.merchantFileDto?.forEach((file) => {
          switch (file.type) {
            case "사업자등록증":
              setValue("businessLicense", file.fileName);
              break;
            case "통장사본":
              setValue("bankBookCopy", file.fileName);
              break;
            case "인감증명서":
              setValue("sealCertificate", file.fileName);
              break;
            case "법인인감증명서":
              setValue("corporateSealCertificate", file.fileName);
              break;
            case "실제소유자확인서":
              setValue("actualOwnerConfirmation", file.fileName);
              break;
            case "등기사항전부증명서":
              setValue("corporateRegistration", file.fileName);
              break;
            case "신분증_앞":
              setValue("idCardFront", file.fileName);
              break;
            case "신분증_뒤":
              setValue("idCardBack", file.fileName);
              break;
            case "등급확인서":
              setValue("gradeConfirmation", file.fileName);
              break;
            default:
              break;
          }
        });

        setIsFirst(false);
      }
    }
  );

  // ===================================================================================================================
  // 리액트 훅 폼
  // ===================================================================================================================
  const {
    setValue,
    getValues,
    setFocus,
    setError,
    clearErrors,
    handleSubmit,
    watch,
    control,
    reset,
    trigger,
    formState: { errors, isValid }
  } = useForm<Merchant>({
    defaultValues: {
      // 사업자 정보
      businessType: "",
      businessName: "",
      mallName: "",
      mallNameEn: "",
      registrationNumber: "",
      corporationNumber: "",
      postCode: "",
      mallAddress: "",
      detailAddress: "",
      headOfficePostCode: "",
      headOfficeAddress: "",
      headOfficeDetailAddress: "",
      mallPhone: "",
      fax: "",
      merchantCategory: "",
      businessConditions: "",
      mailOrderSalesNumber: "",
      mallUrl: "",
      mallIp: "",
      introductionCompany: "",
      testId: "",
      testPassword: "",

      // 대표자 정보
      merchantOwnerInformation: [
        {
          address: "",
          birth: "",
          name: "",
          nameEn: "",
          nationality: "",
          phone: "",
          ownerPostCode: "",
          ownerAddress: "",
          ownerDetailAddress: ""
        }
      ],

      // 은행 정보
      bankName: "",
      accountCode: "",
      accountHolder: "",

      // 담당자 정보
      name: "",
      phone: "",
      email: "",
      managerName: "",
      managerPhone: "",
      managerEmail: "",
      taxName: "",
      taxPhone: "",
      taxEmail: "",

      // 파일 정보 - 개인사업자
      businessLicense: "",
      bankBookCopy: "",
      sealCertificate: "",
      idCardFront: "",
      idCardBack: "",
      gradeConfirmation: "",

      // 파일 정보 - 법인사업자
      corporateRegistration: "",
      actualOwnerConfirmation: "",
      corporateSealCertificate: ""
    },
    mode: "onChange"
  });

  // 개인, 법인 구분
  const currentBusinessType = watch("businessType");

  // ===================================================================================================================
  // 대표자 양식 추가, 삭제
  // ===================================================================================================================
  const { fields, append, remove } = useFieldArray<Merchant>({
    control,
    name: "merchantOwnerInformation"
  });

  // ===================================================================================================================
  // 테스트용 ID, 비밀번호 체크박스
  // ===================================================================================================================
  const watchTestId = watch("testId");

  useEffect(() => {
    if (watchTestId !== "비회원 결제가능") {
      setIsCheckedTestId(false);
    } else {
      setIsCheckedTestId(true);
    }
  }, [watchTestId]);

  // 테스트용 아이디/비밀번호 체크박스 핸들러
  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsCheckedTestId(event.target.checked);
    if (event.target.checked) {
      setValue("testId", "비회원 결제가능");
      setValue("testPassword", "비회원 결제가능");
      clearErrors("testId");
      clearErrors("testPassword");
    } else {
      setValue("testId", "");
      setValue("testPassword", "");
    }
  };

  // ===================================================================================================================
  // 파일 업로드 & 가맹 재등록
  // ===================================================================================================================
  // 파일 업로드
  // `mutateAsync`는 Promise를 반환하는 비동기 함수다. `await`를 사용해 작업이 완료될 때 까지 기다릴 수 있다.
  const { mutateAsync: fileMutate, isLoading: fileLoading } = useMutation(
    merchantFileUpload,
    {
      onError: (error: any) => {
        setIsModalOpen({
          value: true,
          position: "top",
          alertSeverity: "error",
          message: error.message
        });
      }
    }
  );

  const { mutate, isLoading: mutateLoading } = useMutation(postMerchant, {
    onSuccess: () => {
      // merchantId로 시작하는 키로 모든 쿼리 무효화 - 심사상태 값이 바뀌면 가맹 현황 데이터 refresh
      queryClient.invalidateQueries(merchantId);
      setIsModalOpen({
        value: true,
        position: "top",
        alertSeverity: "success",
        message: "가맹등록 저장이 완료 되었습니다."
      });
    },
    onError: (error: any) => {
      setIsModalOpen({
        value: true,
        position: "top",
        alertSeverity: "error",
        message: error.response.data.message
      });
    }
  });

  // ===================================================================================================================
  // 파일 업로드
  // ===================================================================================================================
  const 파일선택 = (
    e: React.ChangeEvent<HTMLInputElement>,
    fileType:
      | "businessLicense"
      | "bankBookCopy"
      | "sealCertificate"
      | "corporateRegistration"
      | "corporateSealCertificate"
      | "actualOwnerConfirmation"
      | "idCardFront"
      | "idCardBack"
      | "gradeConfirmation"
  ) => {
    selectFile(e, fileType, setValue, setFileData, clearErrors, errors);
    trigger(fileType);
  };

  // 파일 유무, 확장자 검사
  const fileValidation = () => {
    let 사업자등록증검사 = false;
    let 사업자등록증확장자검사 = false;
    let 통장사본검사 = false;
    let 통장사본확장자검사 = false;
    // let 사용인감계검사 = false;
    let 사용인감계확장자검사 = false;
    let 신분증앞검사 = false;
    let 신분증앞확장자검사 = false;
    let 신분증뒤검사 = false;
    let 신분증뒤확장자검사 = false;
    let 등기사항전부증명서검사 = false;
    let 등기사항전부증명서확장자검사 = false;
    let 실제소유자확인서검사 = false;
    let 실제소유자확인서확장자검사 = false;
    let 법인인감증명서검사 = false;
    let 법인인감증명서확장자검사 = false;
    let 등급확인서검사 = false;
    let 등급확인서확장자검사 = false;

    if (fileData.businessLicense) {
      사업자등록증검사 = 파일유무검사(
        "businessLicense",
        setError,
        fileData.businessLicense
      );
      사업자등록증확장자검사 = 파일확장자검사(
        "businessLicense",
        setError,
        fileData.businessLicense
      );
    }
    if (fileData.bankBookCopy) {
      통장사본검사 = 파일유무검사(
        "bankBookCopy",
        setError,
        fileData.bankBookCopy
      );
      통장사본확장자검사 = 파일확장자검사(
        "bankBookCopy",
        setError,
        fileData.bankBookCopy
      );
    }
    if (fileData.sealCertificate) {
      // 사용인감계검사 = 파일유무검사(
      //   "sealCertificate",
      //   setError,
      //   fileData.sealCertificate
      // );
      사용인감계확장자검사 = 파일확장자검사(
        "sealCertificate",
        setError,
        fileData.sealCertificate
      );
    }
    if (fileData.corporateSealCertificate) {
      법인인감증명서검사 = 파일유무검사(
        "corporateSealCertificate",
        setError,
        fileData.corporateSealCertificate
      );
      법인인감증명서확장자검사 = 파일확장자검사(
        "corporateSealCertificate",
        setError,
        fileData.corporateSealCertificate
      );
    }
    if (fileData.idCardFront) {
      신분증앞검사 = 파일유무검사(
        "idCardFront",
        setError,
        fileData.idCardFront
      );
      신분증앞확장자검사 = 파일확장자검사(
        "idCardFront",
        setError,
        fileData.idCardFront
      );
    }
    if (fileData.idCardBack) {
      신분증뒤검사 = 파일유무검사("idCardBack", setError, fileData.idCardBack);
      신분증뒤확장자검사 = 파일확장자검사(
        "idCardBack",
        setError,
        fileData.idCardBack
      );
    }
    if (fileData.corporateRegistration) {
      등기사항전부증명서검사 = 파일유무검사(
        "corporateRegistration",
        setError,
        fileData.corporateRegistration
      );
      등기사항전부증명서확장자검사 = 파일확장자검사(
        "corporateRegistration",
        setError,
        fileData.corporateRegistration
      );
    }
    if (fileData.actualOwnerConfirmation) {
      실제소유자확인서검사 = 파일유무검사(
        "actualOwnerConfirmation",
        setError,
        fileData.actualOwnerConfirmation
      );
      실제소유자확인서확장자검사 = 파일확장자검사(
        "actualOwnerConfirmation",
        setError,
        fileData.actualOwnerConfirmation
      );
    }
    if (fileData.gradeConfirmation) {
      // 등급확인서검사 = 파일유무검사(
      //   "gradeConfirmation",
      //   setError,
      //   fileData.gradeConfirmation
      // );
      등급확인서확장자검사 = 파일확장자검사(
        "gradeConfirmation",
        setError,
        fileData.gradeConfirmation
      );
    }

    if (
      사업자등록증검사 ||
      사업자등록증확장자검사 ||
      통장사본검사 ||
      통장사본확장자검사 ||
      // 사용인감계검사 ||
      사용인감계확장자검사 ||
      신분증앞검사 ||
      신분증앞확장자검사 ||
      신분증뒤검사 ||
      신분증뒤확장자검사 ||
      등기사항전부증명서검사 ||
      등기사항전부증명서확장자검사 ||
      실제소유자확인서검사 ||
      실제소유자확인서확장자검사 ||
      법인인감증명서검사 ||
      법인인감증명서확장자검사 ||
      등급확인서검사 ||
      등급확인서확장자검사
    ) {
      return true;
    } else {
      return false;
    }
  };

  // ===================================================================================================================
  // 리액트 쿼리 - 은행 리스트 조회, 사업자 번호 검증
  // ===================================================================================================================
  // 은행 리스트 가져오기
  const { data: bankNameList } = useQuery(["getBankList"], () => getBankList());

  // 사업자 번호 검증
  const { refetch } = useQuery(
    [`/bypass/business-number/validate`, watch("registrationNumber")],
    () => getBusinessNumber(watch("registrationNumber")),
    {
      enabled: false,
      retry: false,
      onError: (error: any) => {
        setIsModalOpen({
          value: true,
          position: "top",
          alertSeverity: "error",
          message: error.response.data.message
        });
        setFocus("registrationNumber");
      }
    }
  );

  // ===================================================================================================================
  // 은행 코드 전송
  // ===================================================================================================================
  useEffect(() => {
    if (!bankNameList || bankNameList.content.length < 1) return;
    const bankName = data?.content.merchantBankInformation.bankName ?? "";
    const bankCode = data?.content.merchantBankInformation.bankCode ?? "";

    setSelectedBank({
      name: bankName,
      code: bankCode
    });
  }, [data?.content.merchantBankInformation, bankNameList]);

  const bankSelectHandler = (event: any) => {
    const selectedBankName = event.target.value;

    if (!bankNameList) return;

    const selectedBank = bankNameList.content.find(
      (db) => db.name === selectedBankName
    );

    if (!selectedBank) return;
    setSelectedBank(selectedBank);
  };

  // ===================================================================================================================
  // 가맹점 재등록
  // ===================================================================================================================
  const onSubmit = async (formData: Merchant) => {
    if (mutateLoading) return;

    // 사업자 등록 번호
    const { isError } = await refetch();
    if (isError) {
      return;
    }
    // 파일 검증
    const fileValidationResult = fileValidation();
    if (fileValidationResult) {
      return;
    }

    // 대표자 정보 묶어서 보내기
    const formMerchantOwnerInfo =
      formData.merchantOwnerInformation?.map((info, index) => {
        const fullAddress = `${info.ownerPostCode} | ${info.ownerAddress} | ${info.ownerDetailAddress}`;
        const year = info.birth.substring(0, 4);
        const month = info.birth.substring(4, 6);
        const day = info.birth.substring(6, 8);
        const birthString = `${year}-${month}-${day}`;
        const ids = data?.content.merchantOwnerInformation[index]?.id;

        return {
          id: ids,
          address: fullAddress,
          birth: birthString,
          name: info.name,
          nameEn: info.nameEn,
          nationality: info.nationality,
          phone: info.phone
        };
      }) ?? [];

    let newHeadOfficeAddress: string | undefined;
    if (formData.headOfficePostCode) {
      newHeadOfficeAddress = `${formData.headOfficePostCode} | ${formData.headOfficeAddress} | ${formData.headOfficeDetailAddress}`;
    } else {
      newHeadOfficeAddress = undefined;
    }

    const merchantFileDto = await uploadFiles(
      fileData,
      formData.businessType,
      fileMutate
    );

    // 폼 데이터
    const newFormData = {
      registrant: data?.content.registrant,
      id: data?.content.id,
      mallId: data?.content.mallId,
      mallIp: formData.mallIp,
      mallName: formData.mallName,
      mallNameEn: formData.mallNameEn,
      mallUrl: formData.mallUrl,
      merchantInformation: {
        address: `${formData.postCode} | ${formData.mallAddress} | ${formData.detailAddress}`,
        headOfficeAddress: newHeadOfficeAddress,
        merchantCategory: businessList.join(" | "),
        businessConditions: businessStatus.join(" | "),
        businessType: formData.businessType,
        businessName: formData.businessName,
        corporationNumber: formData.corporationNumber,
        fax: formData.fax,
        introductionCompany: formData.introductionCompany,
        mailOrderSalesNumber: formData.mailOrderSalesNumber,
        phone: formData.mallPhone,
        registrationNumber: formData.registrationNumber,
        testId: formData.testId,
        testPassword: formData.testPassword
      },
      merchantBankInformation: {
        accountCode: formData.accountCode,
        accountHolder: formData.accountHolder,
        bankCode: selectedBank.code,
        bankName: selectedBank.name
      },
      merchantInChargeInformation: [
        {
          id: data?.content.merchantInChargeInformation[0].id,
          email: formData.email,
          name: formData.name,
          phone: formData.phone,
          role: "계약"
        },
        {
          id: data?.content.merchantInChargeInformation[1].id,
          email: formData.managerEmail,
          name: formData.managerName,
          phone: formData.managerPhone,
          role: "정산"
        },
        {
          id: data?.content.merchantInChargeInformation[2].id,
          email: formData.taxEmail,
          name: formData.taxName,
          phone: formData.taxPhone,
          role: "계산서"
        }
      ],
      merchantOwnerInformation: [...formMerchantOwnerInfo],
      merchantFileDto: merchantFileDto
    };

    mutate(newFormData);
    // 작업 완료 후 모달창 닫힘
    close();
  };

  // ===================================================================================================================
  // 모달 닫기
  // ===================================================================================================================
  const close = () => {
    setIsFirst(true);
    reset();
    closeModal();
  };

  // ===================================================================================================================
  // 주소 검색 모달창
  // ===================================================================================================================
  // 가맹점 주소 검색 모달창 열기
  const openPostCodeModal = (index: number) => {
    setOpenPostCode({
      isOpen: true,
      referenceIndex: index
    });
  };
  // 대표자 주소 검색 모달창 열기
  const openOwnerPostCodeModal = (index: number) => {
    setOpenOwnerPostCode({
      isOpen: true,
      referenceIndex: index
    });
  };
  // 검색된 주소값 가맹점 주소 인풋에 담기
  const postCodeHandler = (data: any, index?: number) => {
    if (index === 0) {
      setValue("postCode", data.zonecode);
      setValue("mallAddress", data.address);
    } else {
      setValue("headOfficePostCode", data.zonecode);
      setValue("headOfficeAddress", data.address);
    }
  };
  // 검색된 주소값 대표자 주소 인풋에 담기
  const ownerAddressHandler = (data: any, index?: number) => {
    if (index === undefined) return;
    setValue(`merchantOwnerInformation.${index}.ownerPostCode`, data.zonecode);
    setValue(`merchantOwnerInformation.${index}.ownerAddress`, data.address);
  };

  // ===================================================================================================================
  // 업종, 업태 추가
  // ===================================================================================================================
  // add list - 업종 추가
  const addList = () => {
    if (businessList.length > 4) {
      setIsModalOpen({
        value: true,
        position: "top",
        alertSeverity: "error",
        message: "업종은 5개 이하로 작성해 주세요."
      });
      setValue("merchantCategory", "");
    }
    const businessValue = getValues("merchantCategory");
    if (businessValue.trim() === "") return;
    setBusinessList((prev) => [...prev, businessValue]);
    setValue("merchantCategory", "");
  };
  // delete list - 업종 삭제
  const deleteList = (index: number) => {
    let temp = businessList.filter((item, i) => i !== index);
    setBusinessList(temp);
  };
  // 업종이 1개 이상일 때 다음 재등록 버튼 활성화
  const validBusiness = () => {
    return businessList.length > 0;
  };
  // keyPress - 업종 추가
  const handleOnKeyPressItem = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      e.preventDefault();
      addList();
    }
  };

  // add status list - 업태 추가
  const addStatusList = () => {
    if (businessStatus.length > 4) {
      setIsModalOpen({
        value: true,
        position: "top",
        alertSeverity: "error",
        message: "업태는 5개 이하로 작성해 주세요."
      });
      setValue("businessConditions", "");
    }
    const businessStatusValue = getValues("businessConditions");
    if (businessStatusValue.trim() === "") return;
    setBusinessStatus((prev) => [...prev, businessStatusValue]);
    setValue("businessConditions", "");
  };
  // delete status - 업태 삭제
  const deleteStatus = (index: number) => {
    let temp = businessStatus.filter((item, i) => i !== index);
    setBusinessStatus(temp);
  };
  // 업태가 1개 이상일 때 다음 재등록 버튼 활성화
  const validStatus = () => {
    return businessStatus.length > 0;
  };
  // keyPress - 업태 추가
  const handleOnKeyPressStatus = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      e.preventDefault();
      addStatusList();
    }
  };

  // ===================================================================================================================
  // 체크박스 내용 붙여넣기
  // ===================================================================================================================
  // 주소 체크박스
  const checkBox = (e: any) => {
    setIsCheckedAddress(e.target.checked);
    if (e.target.checked) {
      const getPostCode = getValues("postCode");
      setValue("headOfficePostCode", getPostCode);
      const getAddress = getValues("mallAddress");
      setValue("headOfficeAddress", getAddress);
      const getDetailAddress = getValues("detailAddress");
      setValue("headOfficeDetailAddress", getDetailAddress);
    }
  };

  // 관리자 체크박스
  const managerCheckBox = (e: any) => {
    setIsChecked(e.target.checked);
    if (e.target.checked) {
      const getName = getValues("name");
      setValue("managerName", getName);
      const getEmail = getValues("email");
      setValue("managerEmail", getEmail);
      const getPhone = getValues("phone");
      setValue("managerPhone", getPhone);
    }
  };

  // 세금 담당자 체크박스
  const taxCheckBox = (e: any) => {
    setIsCheckedTax(e.target.checked);
    if (e.target.checked) {
      const getName = getValues("managerName");
      setValue("taxName", getName);
      const getEmail = getValues("managerEmail");
      setValue("taxEmail", getEmail);
      const getPhone = getValues("managerPhone");
      setValue("taxPhone", getPhone);
    }
  };

  // ===================================================================================================================
  // 파일 다운로드
  // ===================================================================================================================
  // 파일 아이디 찾기
  const findFileId = (fileType: string) => {
    return (
      // 파일타입이 같다면 파일 id를 찾고 타입이 다르면 0
      data?.content.merchantFileDto
        .filter((file) => file.type === fileType)
        ?.at(0)?.id ?? 0
    );
  };

  // 파일 이름 디코딩
  const decodeFileName = (incodedFileName: string) => {
    if (incodedFileName === "") return;
    return decodeURIComponent(incodedFileName);
  };

  // 파일 다운로드
  const { isLoading: isFileDownloading, mutate: fileDownload } = useMutation(
    downloadFile,
    {
      onSuccess: (response) => {
        const contentDisposition = response.headers["content-disposition"];
        if (!contentDisposition) return;

        const [fileNameMatch] = contentDisposition
          .split(";")
          .filter((str: any) => str.includes("filename"));

        if (!fileNameMatch) return;

        const [, incodedFileName] = fileNameMatch.split("=");
        const decodedFileName = decodeFileName(incodedFileName);

        if (!decodedFileName) return;

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `${decodedFileName.trim().replaceAll('"', "")}`
        );
        document.body.appendChild(link);
        link.click();
        setIsModalOpen({
          value: true,
          position: "top",
          alertSeverity: "success",
          message: "파일 다운로드가 시작 되었습니다."
        });
      },
      onError: (error: any) => {
        setIsModalOpen({
          value: true,
          position: "top",
          alertSeverity: "error",
          message: error?.response?.data?.message
        });
      }
    }
  );

  const onClickFileDownload = (fileId: number) => {
    // 파일 아이디가 0 이면 함수 종료
    if (fileId === 0) return;
    if (isFileDownloading) return;
    fileDownload({ fileId: fileId });
  };

  // ===================================================================================================================
  // 가맹점 재등록
  // ===================================================================================================================
  const clickedSubmit = () => {
    handleClosePrompt();
    onSubmit(getValues());
  };

  return (
    <>
      <Dialog open={isOpen} fullWidth maxWidth="lg">
        {/* 가맹 등록 로딩 컴포넌트 ================================================================================= */}
        {fileLoading && <Loading isLoading={fileLoading} />}

        <DialogTitle
          sx={{
            boxShadow: "0px 5px 14px -1px rgba(204, 204, 204, 0.5)",
            display: "flex",
            justifyContent: "space-between"
          }}
        >
          가맹점 상세 정보
          {/* 가맹점 수정 버튼 ====================================================================================== */}
          <Stack direction={"row"} gap={3} mr={6}>
            <Button
              type="button"
              variant="contained"
              sx={{ color: "white" }}
              disabled={!isValid || !validBusiness() || !validStatus()}
              onClick={clickedSubmit}
            >
              수정 완료
            </Button>
          </Stack>
        </DialogTitle>
        <IconButton
          sx={{
            position: "absolute",
            right: 8,
            top: 12
          }}
          onClick={close}
        >
          <CloseIcon />
        </IconButton>

        {!isLoading && data?.content ? (
          <>
            <DialogContent>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Stack gap={3}>
                  {/* 사업자 정보 ================================================================================== */}
                  <Box
                    sx={{
                      border: "1px solid #E4E4E7",
                      borderRadius: 2,
                      overflow: "hidden"
                    }}
                  >
                    <Stack
                      direction={"row"}
                      justifyContent="center"
                      alignItems="center"
                      gap={1}
                      sx={{
                        py: 1,
                        borderBottom: "1px solid #E4E4E7",
                        backgroundColor: "#f7f7f7"
                      }}
                    >
                      <Typography>사업자 정보</Typography>
                      <Typography sx={{ fontSize: 14, fontWeight: "bold" }}>
                        ({data.content.merchantInformation.businessType}사업자)
                      </Typography>
                    </Stack>
                    <Grid container spacing={3} p={3}>
                      <Grid item xs={12} sm={4}>
                        <Controller
                          name={"mallName"}
                          control={control}
                          rules={{
                            required: "가맹점명은 필수값 입니다."
                          }}
                          render={({ field: { value, onChange } }) => (
                            <TextField
                              id="mallName"
                              label="가맹점명"
                              required
                              size="small"
                              value={value}
                              onChange={onChange}
                              helperText={
                                <HelperTextMessage>
                                  {errors.mallName?.message}
                                </HelperTextMessage>
                              }
                              fullWidth
                            />
                          )}
                        ></Controller>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <Controller
                          name={"businessName"}
                          control={control}
                          rules={{
                            required: "사업자 등록증 상호명은 필수값 입니다."
                          }}
                          render={({ field: { value, onChange } }) => (
                            <TextField
                              size="small"
                              id="businessName"
                              label="사업자 등록증 상호명"
                              required
                              value={value}
                              onChange={onChange}
                              helperText={
                                <HelperTextMessage>
                                  {errors.businessName?.message}
                                </HelperTextMessage>
                              }
                              fullWidth
                            />
                          )}
                        ></Controller>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <Controller
                          name={"registrationNumber"}
                          control={control}
                          rules={{
                            required: "사업자등록번호는 필수값 입니다.",
                            maxLength: {
                              value: 10,
                              message: "사업자등록번호는 10자리 입니다."
                            }
                          }}
                          render={({ field: { value, onChange } }) => (
                            <TextField
                              size="small"
                              id="registrationNumber"
                              placeholder="등록번호 10자리"
                              label="사업자등록번호"
                              required
                              value={value}
                              onChange={onChange}
                              helperText={
                                <HelperTextMessage>
                                  {errors.registrationNumber?.message}
                                </HelperTextMessage>
                              }
                              onInput={numberValidate}
                              fullWidth
                            />
                          )}
                        ></Controller>
                      </Grid>

                      {currentBusinessType === "법인" && (
                        <>
                          <Grid item xs={12} sm={4}>
                            <Controller
                              name="mallNameEn"
                              control={control}
                              rules={{
                                required: "영문상호는 필수값 입니다."
                              }}
                              render={({ field: { value, onChange } }) => (
                                <TextField
                                  id="mallNameEn"
                                  size="small"
                                  label="영문상호"
                                  required
                                  value={value}
                                  onChange={onChange}
                                  helperText={
                                    <HelperTextMessage>
                                      {errors.mallNameEn?.message}
                                    </HelperTextMessage>
                                  }
                                  onInput={engValidate}
                                  fullWidth
                                />
                              )}
                            ></Controller>
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <Controller
                              name="corporationNumber"
                              control={control}
                              rules={{
                                required: "법인등록번호는 필수값 입니다.",
                                maxLength: {
                                  value: 13,
                                  message: "법인등록번호는 13자리 입니다."
                                }
                              }}
                              render={({ field: { value, onChange } }) => (
                                <TextField
                                  id="corporationNumber"
                                  size="small"
                                  placeholder="등록번호 13자리"
                                  label="법인등록번호"
                                  required
                                  value={value}
                                  onChange={onChange}
                                  helperText={
                                    <HelperTextMessage>
                                      {errors.corporationNumber?.message}
                                    </HelperTextMessage>
                                  }
                                  onInput={numberValidate}
                                  fullWidth
                                />
                              )}
                            ></Controller>
                          </Grid>
                          <Grid item xs={12} sm={4} />
                        </>
                      )}

                      <Grid item xs={12} sm={12}>
                        <InputLabel sx={{ mb: 1 }}>가맹점 주소</InputLabel>
                        <Stack direction={"row"} gap={3} alignItems={"center"}>
                          <Button
                            type="button"
                            variant="contained"
                            sx={{
                              color: "white",
                              width: 100
                            }}
                            onClick={() => openPostCodeModal(0)}
                          >
                            주소 검색
                          </Button>
                          <Controller
                            name={"postCode"}
                            rules={{ required: "우편번호는 필수값 입니다." }}
                            control={control}
                            render={({ field: { value, onChange } }) => (
                              <TextField
                                id="postCode"
                                size="small"
                                label="우편번호"
                                required
                                value={value}
                                onChange={onChange}
                                sx={{ width: 219 }}
                                helperText={
                                  <HelperTextMessage>
                                    {errors.postCode?.message}
                                  </HelperTextMessage>
                                }
                              />
                            )}
                          ></Controller>

                          <Controller
                            name={"mallAddress"}
                            rules={{ required: "주소는 필수값 입니다." }}
                            control={control}
                            render={({ field: { value, onChange } }) => (
                              <TextField
                                id="mallAddress"
                                size="small"
                                label="주소"
                                required
                                value={value}
                                onChange={onChange}
                                sx={{ width: 346 }}
                                helperText={
                                  <HelperTextMessage>
                                    {errors.mallAddress?.message}
                                  </HelperTextMessage>
                                }
                              />
                            )}
                          ></Controller>
                          <Controller
                            name={"detailAddress"}
                            rules={{ required: "상세주소는 필수값 입니다." }}
                            control={control}
                            render={({ field: { value, onChange } }) => (
                              <TextField
                                id="detailAddress"
                                size="small"
                                label="상세주소"
                                required
                                value={value}
                                onChange={onChange}
                                sx={{ width: 346 }}
                                helperText={
                                  <HelperTextMessage>
                                    {errors.detailAddress?.message}
                                  </HelperTextMessage>
                                }
                              />
                            )}
                          ></Controller>
                        </Stack>
                      </Grid>

                      <Grid item xs={12} sm={12}>
                        <Stack direction={"row"} gap={3} alignItems={"center"}>
                          <InputLabel sx={{ width: 100 }}>본점 주소</InputLabel>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={isCheckedAddress}
                                onChange={checkBox}
                              />
                            }
                            label="가맹점 주소와 동일"
                            labelPlacement="end"
                          />
                        </Stack>

                        <Stack direction={"row"} gap={3} alignItems={"center"}>
                          <Button
                            type="button"
                            variant="contained"
                            sx={{ color: "white", width: 100 }}
                            onClick={() => openPostCodeModal(1)}
                            disabled={isCheckedAddress}
                          >
                            주소 검색
                          </Button>
                          <Controller
                            name={"headOfficePostCode"}
                            control={control}
                            render={({ field: { value, onChange } }) => (
                              <TextField
                                id="headOfficePostCode"
                                size="small"
                                label="우편번호"
                                value={
                                  isCheckedAddress ? watch("postCode") : value
                                }
                                onChange={onChange}
                                sx={{ width: 219 }}
                                disabled={isCheckedAddress}
                              />
                            )}
                          ></Controller>

                          <Controller
                            name={"headOfficeAddress"}
                            control={control}
                            render={({ field: { value, onChange } }) => (
                              <TextField
                                id="headOfficeAddress"
                                size="small"
                                label="주소"
                                value={
                                  isCheckedAddress
                                    ? watch("mallAddress")
                                    : value
                                }
                                onChange={onChange}
                                sx={{ width: 346 }}
                                disabled={isCheckedAddress}
                              />
                            )}
                          ></Controller>
                          <Controller
                            name={"headOfficeDetailAddress"}
                            control={control}
                            render={({ field: { value, onChange } }) => (
                              <TextField
                                id="headOfficeDetailAddress"
                                size="small"
                                label="상세주소"
                                value={
                                  isCheckedAddress
                                    ? watch("detailAddress")
                                    : value
                                }
                                onChange={onChange}
                                sx={{ width: 346 }}
                                disabled={isCheckedAddress}
                              />
                            )}
                          ></Controller>
                        </Stack>
                      </Grid>

                      <PostCode
                        openPostCode={openPostCode}
                        setOpenPostCode={setOpenPostCode}
                        PostCodeHandler={postCodeHandler}
                      />

                      {/* 업종, 업태  */}
                      <Grid item xs={12} sm={12}>
                        <InputLabel htmlFor="businessConditions" sx={{ mb: 1 }}>
                          업태&nbsp;
                          <span style={{ fontSize: 14 }}>
                            (등록할 수 있는 업태의 개수는 최대 5개입니다.)
                          </span>
                        </InputLabel>
                        <Stack direction="row" alignItems="center" gap={3}>
                          <Controller
                            name={"businessConditions"}
                            control={control}
                            render={({ field: { value, onChange } }) => (
                              <TextField
                                id="businessConditions"
                                size="small"
                                label="업태 *"
                                sx={{ width: 345 }}
                                value={value}
                                onChange={onChange}
                                helperText={
                                  <HelperTextMessage>
                                    {errors.businessConditions?.message}
                                  </HelperTextMessage>
                                }
                                onKeyDown={handleOnKeyPressStatus}
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      <Button onClick={addStatusList}>
                                        추가
                                      </Button>
                                    </InputAdornment>
                                  )
                                }}
                              />
                            )}
                          ></Controller>
                          <Stack
                            direction={"row"}
                            sx={{ width: 440, flexWrap: "wrap" }}
                          >
                            {businessStatus.length > 0 &&
                              businessStatus.map((item, i) => (
                                <Chip
                                  id="businessConditions"
                                  key={i}
                                  label={item}
                                  size="small"
                                  variant="outlined"
                                  sx={{ mr: 1 }}
                                  onDelete={() => deleteStatus(i)}
                                />
                              ))}
                          </Stack>
                        </Stack>
                      </Grid>

                      <Grid item xs={12} sm={12}>
                        <InputLabel htmlFor="merchantCategory" sx={{ mb: 1 }}>
                          업종&nbsp;
                          <span style={{ fontSize: 14 }}>
                            (등록할 수 있는 업종의 개수는 최대 5개입니다.)
                          </span>
                        </InputLabel>
                        <Stack direction={"row"} gap={3} alignItems={"center"}>
                          <Controller
                            name={"merchantCategory"}
                            control={control}
                            render={({ field: { value, onChange } }) => (
                              <TextField
                                id="merchantCategory"
                                size="small"
                                label="업종 *"
                                value={value}
                                onChange={onChange}
                                onKeyDown={handleOnKeyPressItem}
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      <Button onClick={addList}>추가</Button>
                                    </InputAdornment>
                                  )
                                }}
                                sx={{ width: 345 }}
                              />
                            )}
                          ></Controller>
                          <Stack
                            direction={"row"}
                            alignItems="center"
                            height={40}
                            spacing={1}
                          >
                            {businessList.length > 0 &&
                              businessList.map((item, i) => (
                                <Chip
                                  id="merchantCategory"
                                  key={i}
                                  label={item}
                                  size="small"
                                  variant="outlined"
                                  sx={{ mr: 1 }}
                                  onDelete={() => deleteList(i)}
                                />
                              ))}
                          </Stack>
                        </Stack>
                      </Grid>

                      <Grid item xs={12} sm={4}>
                        <Controller
                          name="mallPhone"
                          control={control}
                          rules={{
                            required: "가맹점 전화번호는 필수값 입니다.",
                            maxLength: {
                              value: 12,
                              message: "12자 이하로 입력해 주세요."
                            }
                          }}
                          render={({ field: { value, onChange } }) => (
                            <TextField
                              id="mallPhone"
                              label="전화번호"
                              required
                              size="small"
                              value={value}
                              onChange={onChange}
                              helperText={
                                <HelperTextMessage>
                                  {errors.mallPhone?.message}
                                </HelperTextMessage>
                              }
                              onInput={numberValidate}
                              fullWidth
                            />
                          )}
                        ></Controller>
                      </Grid>

                      <Grid item xs={12} sm={4}>
                        <Controller
                          name="fax"
                          control={control}
                          rules={{
                            maxLength: {
                              value: 12,
                              message: "12자 이하로 입력해 주세요."
                            }
                          }}
                          render={({ field: { value, onChange } }) => (
                            <TextField
                              id="fax"
                              size="small"
                              label="팩스번호"
                              value={value}
                              onChange={onChange}
                              helperText={
                                <HelperTextMessage>
                                  {errors.fax?.message}
                                </HelperTextMessage>
                              }
                              onInput={numberValidate}
                              fullWidth
                            />
                          )}
                        ></Controller>
                      </Grid>

                      <Grid item xs={12} sm={4}>
                        <Controller
                          name="mailOrderSalesNumber"
                          control={control}
                          rules={{
                            required: "통신판매번호는 필수값 입니다."
                          }}
                          render={({ field: { value, onChange } }) => (
                            <TextField
                              id="mailOrderSalesNumber"
                              size="small"
                              required
                              label="통신판매번호"
                              value={value}
                              onChange={onChange}
                              helperText={
                                <HelperTextMessage>
                                  {errors.mailOrderSalesNumber?.message}
                                </HelperTextMessage>
                              }
                              fullWidth
                            />
                          )}
                        ></Controller>
                      </Grid>

                      <Grid item xs={12} sm={4}>
                        <Controller
                          name="mallUrl"
                          control={control}
                          rules={{
                            required: "홈페이지 주소는 필수값 입니다."
                          }}
                          render={({ field: { value, onChange } }) => (
                            <TextField
                              id="mallUrl"
                              size="small"
                              required
                              label="홈페이지 주소"
                              value={value}
                              onChange={onChange}
                              helperText={
                                <HelperTextMessage>
                                  {errors.mallUrl?.message}
                                </HelperTextMessage>
                              }
                              fullWidth
                            />
                          )}
                        ></Controller>
                      </Grid>

                      <Grid item xs={12} sm={4}>
                        <Controller
                          name="mallIp"
                          control={control}
                          rules={{
                            required: "가맹점 IP는 필수값 입니다.",
                            pattern: {
                              value:
                                /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/,
                              message: "형식에 맞는 IP주소를 입력해 주세요."
                            }
                          }}
                          render={({ field: { value, onChange } }) => (
                            <TextField
                              id="mallIp"
                              size="small"
                              required
                              label="가맹점 IP"
                              value={value}
                              onChange={onChange}
                              helperText={
                                <HelperTextMessage>
                                  {errors.mallIp?.message}
                                </HelperTextMessage>
                              }
                              fullWidth
                            />
                          )}
                        ></Controller>
                      </Grid>

                      <Grid item xs={12} sm={4}>
                        <Controller
                          name="introductionCompany"
                          control={control}
                          render={({ field: { value, onChange } }) => (
                            <TextField
                              id="introductionCompany"
                              size="small"
                              label="소개업체 (호스팅사)"
                              value={value}
                              onChange={onChange}
                              helperText={
                                <HelperTextMessage>
                                  {errors.introductionCompany?.message}
                                </HelperTextMessage>
                              }
                              fullWidth
                            />
                          )}
                        ></Controller>
                      </Grid>

                      <Grid item xs={12} sm={4}>
                        <Controller
                          name="testId"
                          control={control}
                          rules={{
                            required: "테스트용 아이디는 필수값 입니다."
                          }}
                          render={({ field: { value, onChange } }) => (
                            <TextField
                              id="testId"
                              size="small"
                              required
                              label="테스트용 ID (카드사 심사용)"
                              fullWidth
                              value={value}
                              onChange={onChange}
                              helperText={
                                <HelperTextMessage>
                                  {errors.testId?.message}
                                </HelperTextMessage>
                              }
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <Tooltip
                                      title={
                                        <Typography
                                          variant="subtitle2"
                                          fontSize={11}
                                        >
                                          ID는 연동 신청하는 쇼핑몰에 로그인
                                          가능한 테스트용 아이디 입니다.
                                        </Typography>
                                      }
                                      placement="right"
                                      arrow
                                    >
                                      <IconButton type="button">
                                        <ErrorOutline />
                                      </IconButton>
                                    </Tooltip>
                                  </InputAdornment>
                                )
                              }}
                            />
                          )}
                        ></Controller>
                      </Grid>

                      <Grid item xs={12} sm={4}>
                        <Controller
                          name="testPassword"
                          control={control}
                          rules={{
                            required: "테스트용 비밀번호는 필수값 입니다."
                          }}
                          render={({ field: { value, onChange } }) => (
                            <TextField
                              id="testPassword"
                              size="small"
                              required
                              label="테스트용 비밀번호 (카드사 심사용)"
                              fullWidth
                              value={value}
                              onChange={onChange}
                              helperText={
                                <HelperTextMessage>
                                  {errors.testPassword?.message}
                                </HelperTextMessage>
                              }
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <Tooltip
                                      title={
                                        <Typography
                                          variant="subtitle2"
                                          fontSize={11}
                                        >
                                          비밀번호는 연동 신청하는 쇼핑몰에
                                          로그인 가능한 아이디의 비밀번호
                                          입니다.
                                        </Typography>
                                      }
                                      placement="right"
                                      arrow
                                    >
                                      <IconButton type="button">
                                        <ErrorOutline />
                                      </IconButton>
                                    </Tooltip>
                                  </InputAdornment>
                                )
                              }}
                            />
                          )}
                        ></Controller>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={isCheckedTestId}
                              onChange={handleCheckboxChange}
                            />
                          }
                          label="비회원 결제가능"
                          labelPlacement="end"
                        />
                      </Grid>
                    </Grid>
                  </Box>

                  {/* 대표자 정보 ===================================================================================== */}
                  <Box
                    sx={{
                      border: "1px solid #E4E4E7",
                      borderRadius: 2,
                      overflow: "hidden",
                      pb: 3
                    }}
                  >
                    <Stack
                      justifyContent="center"
                      alignItems="center"
                      sx={{
                        py: 1,
                        borderBottom: "1px solid #E4E4E7",
                        backgroundColor: "#f7f7f7"
                      }}
                    >
                      <Typography>대표자 정보</Typography>
                    </Stack>

                    <Typography variant="body2" sx={{ pl: 3, py: 2 }}>
                      * 공동 대표인 경우 공동 대표 2인 전부 필수 입력
                    </Typography>
                    {fields.map((owner, index) => (
                      <Box key={`owner-${owner.id}`}>
                        {index !== 0 && (
                          <Divider
                            sx={{
                              borderStyle: "dashed",
                              my: 3,
                              ml: 3,
                              width: "100%"
                            }}
                          />
                        )}
                        <Grid container spacing={3} px={3}>
                          <Grid item xs={12} sm={4}>
                            <Controller
                              name={`merchantOwnerInformation.${index}.name`}
                              control={control}
                              rules={{
                                required: "대표자명은 필수값 입니다."
                              }}
                              render={({ field: { value, onChange } }) => (
                                <TextField
                                  id={`merchantOwnerInformation.${index}.name`}
                                  label="대표자명"
                                  required
                                  size="small"
                                  value={value}
                                  onChange={onChange}
                                  helperText={
                                    <HelperTextMessage>
                                      {
                                        errors?.merchantOwnerInformation?.[
                                          index
                                        ]?.name?.message
                                      }
                                    </HelperTextMessage>
                                  }
                                  fullWidth
                                />
                              )}
                            ></Controller>
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <Controller
                              name={`merchantOwnerInformation.${index}.nameEn`}
                              control={control}
                              rules={{
                                required: "대표자 영문성명은 필수값 입니다."
                              }}
                              render={({ field: { value, onChange } }) => (
                                <TextField
                                  id={`merchantOwnerInformation.${index}.nameEn`}
                                  size="small"
                                  label="영문성명"
                                  required
                                  value={value}
                                  onChange={onChange}
                                  fullWidth
                                  helperText={
                                    <HelperTextMessage>
                                      {
                                        errors?.merchantOwnerInformation?.[
                                          index
                                        ]?.nameEn?.message
                                      }
                                    </HelperTextMessage>
                                  }
                                  onInput={engValidate}
                                />
                              )}
                            ></Controller>
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <Button
                              type="button"
                              variant="contained"
                              size="small"
                              onClick={() => {
                                append({
                                  name: "",
                                  nameEn: "",
                                  birth: "",
                                  nationality: "대한민국",
                                  phone: "",
                                  address: "",
                                  ownerPostCode: "",
                                  ownerAddress: "",
                                  ownerDetailAddress: ""
                                });
                              }}
                              sx={{
                                color: "white",
                                height: 38,
                                mr: 2
                              }}
                            >
                              공동 대표자 추가
                            </Button>
                            <Button
                              type="button"
                              variant="outlined"
                              onClick={() => remove(index)}
                              disabled={index === 0}
                              sx={{ height: 38 }}
                            >
                              양식삭제
                            </Button>
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <Controller
                              name={`merchantOwnerInformation.${index}.birth`}
                              control={control}
                              rules={{
                                required: "생년월일은 필수값 입니다.",
                                pattern: {
                                  value:
                                    /^(19[0-9][0-9]|20\d{2})(0[1-9]|1[0-2])(0[1-9]|[1-2][0-9]|3[0-1])$/,
                                  message: "정확한 생년월일을 입력해 주세요."
                                },
                                maxLength: {
                                  value: 8,
                                  message: "생년월일 8자리를 입력해 주세요."
                                },
                                minLength: {
                                  value: 8,
                                  message: "생년월일 8자리를 입력해 주세요."
                                }
                              }}
                              render={({ field: { value, onChange } }) => (
                                <TextField
                                  id={`merchantOwnerInformation.${index}.birth`}
                                  label="생년월일"
                                  required
                                  size="small"
                                  value={value}
                                  onChange={onChange}
                                  fullWidth
                                  helperText={
                                    <HelperTextMessage>
                                      {
                                        errors?.merchantOwnerInformation?.[
                                          index
                                        ]?.birth?.message
                                      }
                                    </HelperTextMessage>
                                  }
                                  onInput={numberValidate}
                                />
                              )}
                            ></Controller>
                          </Grid>

                          <Grid item xs={12} sm={4}>
                            <Controller
                              name={`merchantOwnerInformation.${index}.nationality`}
                              control={control}
                              rules={{
                                required: "대표자 국적은 필수값 입니다."
                              }}
                              render={({ field: { value, onChange } }) => (
                                <TextField
                                  id={`merchantOwnerInformation.${index}.nationality`}
                                  label="대표자 국적"
                                  select
                                  required
                                  size="small"
                                  value={value}
                                  onChange={onChange}
                                  sx={{ width: 346 }}
                                >
                                  {CountryList.map(({ value, text }) => (
                                    <MenuItem key={value} value={text}>
                                      {text}
                                    </MenuItem>
                                  ))}
                                </TextField>
                              )}
                            ></Controller>
                          </Grid>

                          <Grid item xs={12} sm={4}>
                            <Controller
                              name={`merchantOwnerInformation.${index}.phone`}
                              control={control}
                              rules={{
                                required: "대표자 연락처는 필수값 입니다.",
                                maxLength: {
                                  value: 12,
                                  message:
                                    "전화번호는 12자리 이하로 입력 해주세요."
                                }
                              }}
                              render={({ field: { value, onChange } }) => (
                                <TextField
                                  id={`merchantOwnerInformation.${index}.phone`}
                                  label="대표자 연락처"
                                  required
                                  size="small"
                                  value={value}
                                  onChange={onChange}
                                  fullWidth
                                  helperText={
                                    <HelperTextMessage>
                                      {
                                        errors?.merchantOwnerInformation?.[
                                          index
                                        ]?.phone?.message
                                      }
                                    </HelperTextMessage>
                                  }
                                  onInput={numberValidate}
                                />
                              )}
                            ></Controller>
                          </Grid>

                          <Grid item xs={12} sm={12}>
                            <Stack direction="row" alignItems="center" gap={3}>
                              <Button
                                type="button"
                                variant="contained"
                                sx={{
                                  color: "white",
                                  width: 100
                                }}
                                onClick={() => openOwnerPostCodeModal(index)}
                              >
                                주소 검색
                              </Button>
                              <Controller
                                name={`merchantOwnerInformation.${index}.ownerPostCode`}
                                rules={{
                                  required: "우편번호는 필수값 입니다."
                                }}
                                control={control}
                                render={({ field: { value, onChange } }) => (
                                  <TextField
                                    size="small"
                                    label="우편번호"
                                    required
                                    value={value}
                                    onChange={onChange}
                                    sx={{ width: 228 }}
                                    helperText={
                                      <HelperTextMessage>
                                        {
                                          errors?.merchantOwnerInformation?.[
                                            index
                                          ]?.ownerPostCode?.message
                                        }
                                      </HelperTextMessage>
                                    }
                                  />
                                )}
                              ></Controller>
                              <PostCode
                                openPostCode={openOwnerPostCode}
                                setOpenPostCode={setOpenOwnerPostCode}
                                PostCodeHandler={ownerAddressHandler}
                              />
                              <Controller
                                name={`merchantOwnerInformation.${index}.ownerAddress`}
                                rules={{
                                  required: "대표자 주소는 필수값 입니다."
                                }}
                                control={control}
                                render={({ field: { value, onChange } }) => (
                                  <TextField
                                    size="small"
                                    label="대표자 주소"
                                    required
                                    value={value}
                                    onChange={onChange}
                                    sx={{ width: 351 }}
                                    helperText={
                                      <HelperTextMessage>
                                        {
                                          errors?.merchantOwnerInformation?.[
                                            index
                                          ]?.ownerAddress?.message
                                        }
                                      </HelperTextMessage>
                                    }
                                  />
                                )}
                              ></Controller>
                              <Controller
                                name={`merchantOwnerInformation.${index}.ownerDetailAddress`}
                                rules={{
                                  required: "상세주소는 필수값 입니다."
                                }}
                                control={control}
                                render={({ field: { value, onChange } }) => (
                                  <TextField
                                    size="small"
                                    label="주소"
                                    required
                                    value={value}
                                    onChange={onChange}
                                    sx={{ width: 352 }}
                                    helperText={
                                      <HelperTextMessage>
                                        {
                                          errors?.merchantOwnerInformation?.[
                                            index
                                          ]?.ownerDetailAddress?.message
                                        }
                                      </HelperTextMessage>
                                    }
                                  />
                                )}
                              ></Controller>
                            </Stack>
                          </Grid>
                        </Grid>
                      </Box>
                    ))}
                  </Box>

                  {/* 은행 정보 ======================================================================================= */}
                  <Box
                    sx={{
                      border: "1px solid #E4E4E7",
                      borderRadius: 2,
                      overflow: "hidden"
                    }}
                  >
                    <Stack
                      alignItems="center"
                      sx={{
                        py: 1,
                        borderBottom: "1px solid #E4E4E7",
                        backgroundColor: "#f7f7f7"
                      }}
                    >
                      <Typography>은행 정보</Typography>
                    </Stack>
                    <Typography variant="body2" sx={{ pl: 3, pt: 3 }}>
                      * 정산 은행은 개인사업자는 대표자명, 법인사업자는 법인
                      명의의 계좌만 이용 가능합니다. <br />* 예금주의 경우,
                      개인사업자는 대표자 or 상호명, 법인사업자는 상호와
                      일치해야 합니다.
                    </Typography>
                    <Grid container spacing={3} p={3}>
                      <Grid item xs={12} sm={4}>
                        <Controller
                          name="bankName"
                          control={control}
                          rules={{
                            required: "은행명은 필수값 입니다."
                          }}
                          render={({ field }) => (
                            <TextField
                              id="bankName"
                              label="은행명"
                              required
                              size="small"
                              select
                              {...field}
                              fullWidth
                              value={selectedBank.name}
                              onChange={bankSelectHandler}
                            >
                              {bankNameList?.content?.map((bank) => (
                                <MenuItem key={bank.code} value={bank.name}>
                                  {bank.name}
                                </MenuItem>
                              ))}
                            </TextField>
                          )}
                        ></Controller>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <Controller
                          name="accountCode"
                          control={control}
                          rules={{
                            required: "은행 계좌번호는 필수값 입니다."
                          }}
                          render={({ field: { value, onChange } }) => (
                            <TextField
                              id="accountCode"
                              label="은행 계좌번호"
                              required
                              size="small"
                              value={value}
                              onChange={onChange}
                              helperText={
                                <HelperTextMessage>
                                  {errors.accountCode?.message}
                                </HelperTextMessage>
                              }
                              onInput={numberValidate}
                              fullWidth
                            />
                          )}
                        ></Controller>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <Controller
                          name="accountHolder"
                          control={control}
                          rules={{
                            required: "계좌 소유주는 필수값 입니다."
                          }}
                          render={({ field: { value, onChange } }) => (
                            <TextField
                              id="accountHolder"
                              label="소유주 이름"
                              required
                              size="small"
                              value={value}
                              onChange={onChange}
                              helperText={
                                <HelperTextMessage>
                                  {errors.accountHolder?.message}
                                </HelperTextMessage>
                              }
                              fullWidth
                            />
                          )}
                        ></Controller>
                      </Grid>
                    </Grid>
                  </Box>

                  {/* 담당자 정보 ===================================================================================== */}
                  <Box
                    sx={{
                      border: "1px solid #E4E4E7",
                      borderRadius: 2,
                      overflow: "hidden"
                    }}
                  >
                    <Stack
                      alignItems="center"
                      sx={{
                        py: 1,
                        borderBottom: "1px solid #E4E4E7",
                        backgroundColor: "#f7f7f7"
                      }}
                    >
                      <Typography>담당자 정보</Typography>
                    </Stack>

                    <Grid container spacing={3} p={3}>
                      {/* 계약 담당자 */}
                      <Grid item xs={12} sm={12}>
                        <Chip label="계약 담당자" sx={{ fontWeight: "bold" }} />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <Controller
                          name="name"
                          control={control}
                          rules={{
                            required: "담당자 성명은 필수값 입니다."
                          }}
                          render={({ field: { value, onChange } }) => (
                            <TextField
                              id="name"
                              label="담당자 성명"
                              required
                              size="small"
                              value={value}
                              onChange={onChange}
                              helperText={
                                <HelperTextMessage>
                                  {errors.name?.message}
                                </HelperTextMessage>
                              }
                              fullWidth
                            />
                          )}
                        ></Controller>
                      </Grid>

                      <Grid item xs={12} sm={4}>
                        <Controller
                          name="phone"
                          control={control}
                          rules={{
                            required: "전화번호는 필수값 입니다.",
                            maxLength: {
                              value: 12,
                              message: "전화번호는 12자리 이하로 입력 해주세요."
                            }
                          }}
                          render={({ field: { value, onChange } }) => (
                            <TextField
                              id="phone"
                              label="전화번호"
                              required
                              size="small"
                              value={value}
                              onChange={onChange}
                              helperText={
                                <HelperTextMessage>
                                  {errors.phone?.message}
                                </HelperTextMessage>
                              }
                              fullWidth
                              onInput={numberValidate}
                            />
                          )}
                        ></Controller>
                      </Grid>

                      <Grid item xs={12} sm={4}>
                        <Controller
                          name="email"
                          control={control}
                          rules={{
                            required: "이메일은 필수값 입니다.",
                            pattern: {
                              value:
                                /^([0-9a-zA-Z_.-]+)@([0-9a-zA-Z_-]+)(\.[0-9a-zA-Z_-]+){1,2}$/,
                              message: "형식에 맞지 않는 이메일 주소입니다."
                            }
                          }}
                          render={({ field: { value, onChange } }) => (
                            <TextField
                              id="email"
                              label="이메일"
                              required
                              size="small"
                              value={value}
                              onChange={onChange}
                              helperText={
                                <HelperTextMessage>
                                  {errors.email?.message}
                                </HelperTextMessage>
                              }
                              fullWidth
                            />
                          )}
                        ></Controller>
                      </Grid>

                      {/* 정산 담당자 */}
                      <Grid item xs={12} sm={12}>
                        <Divider sx={{ borderStyle: "dashed", mb: 2.5 }} />
                        <Chip label="정산 담당자" sx={{ fontWeight: "bold" }} />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={isChecked}
                              onChange={managerCheckBox}
                            />
                          }
                          label="계약 담당자 정보와 동일"
                          labelPlacement="end"
                          sx={{ ml: 4 }}
                        />
                      </Grid>

                      <Grid item xs={12} sm={4}>
                        <Controller
                          name="managerName"
                          control={control}
                          rules={{
                            required: "담당자 성명은 필수값 입니다."
                          }}
                          render={({ field: { value, onChange } }) => (
                            <TextField
                              id="managerName"
                              label="담당자 성명"
                              required
                              size="small"
                              value={isChecked ? watch("name") : value}
                              onChange={onChange}
                              helperText={
                                <HelperTextMessage>
                                  {errors.managerName?.message}
                                </HelperTextMessage>
                              }
                              disabled={isChecked}
                              fullWidth
                            />
                          )}
                        ></Controller>
                      </Grid>

                      <Grid item xs={12} sm={4}>
                        <Controller
                          name="managerPhone"
                          control={control}
                          rules={{
                            required: "전화번호는 필수값 입니다.",
                            maxLength: {
                              value: 12,
                              message: "전화번호는 12자리 이하로 입력 해주세요."
                            }
                          }}
                          render={({ field: { value, onChange } }) => (
                            <TextField
                              id="managerPhone"
                              label="전화번호"
                              required
                              size="small"
                              value={isChecked ? watch("phone") : value}
                              onChange={onChange}
                              helperText={
                                <HelperTextMessage>
                                  {errors.managerPhone?.message}
                                </HelperTextMessage>
                              }
                              fullWidth
                              onInput={numberValidate}
                              disabled={isChecked}
                            />
                          )}
                        ></Controller>
                      </Grid>

                      <Grid item xs={12} sm={4}>
                        <Controller
                          name="managerEmail"
                          control={control}
                          rules={{
                            required: "이메일은 필수값 입니다.",
                            pattern: {
                              value:
                                /^([0-9a-zA-Z_.-]+)@([0-9a-zA-Z_-]+)(\.[0-9a-zA-Z_-]+){1,2}$/,
                              message: "형식에 맞지 않는 이메일 주소입니다."
                            }
                          }}
                          render={({ field: { value, onChange } }) => (
                            <TextField
                              id="managerEmail"
                              label="이메일"
                              required
                              size="small"
                              value={isChecked ? watch("email") : value}
                              onChange={onChange}
                              helperText={
                                <HelperTextMessage>
                                  {errors.managerEmail?.message}
                                </HelperTextMessage>
                              }
                              disabled={isChecked}
                              fullWidth
                            />
                          )}
                        ></Controller>
                      </Grid>

                      {/* 세금 계산 담당자 */}
                      <Grid item xs={12} sm={12}>
                        <Divider sx={{ borderStyle: "dashed", mb: 2.5 }} />
                        <Chip
                          label="세금 계산 담당자"
                          sx={{ fontWeight: "bold" }}
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={isCheckedTax}
                              onChange={taxCheckBox}
                            />
                          }
                          label="정산 담당자 정보와 동일"
                          labelPlacement="end"
                          sx={{ ml: 1 }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <Controller
                          name="taxName"
                          control={control}
                          rules={{
                            required: "담당자 성명은 필수값 입니다."
                          }}
                          render={({ field: { value, onChange } }) => (
                            <TextField
                              id="taxName"
                              label="담당자 성명"
                              required
                              size="small"
                              value={
                                isCheckedTax ? watch("managerName") : value
                              }
                              onChange={onChange}
                              helperText={
                                <HelperTextMessage>
                                  {errors.taxName?.message}
                                </HelperTextMessage>
                              }
                              disabled={isCheckedTax}
                              fullWidth
                            />
                          )}
                        ></Controller>
                      </Grid>

                      <Grid item xs={12} sm={4}>
                        <Controller
                          name="taxPhone"
                          control={control}
                          rules={{
                            required: "전화번호는 필수값 입니다.",
                            maxLength: {
                              value: 12,
                              message: "전화번호는 12자리 이하로 입력 해주세요."
                            }
                          }}
                          render={({ field: { value, onChange } }) => (
                            <TextField
                              id="taxPhone"
                              label="전화번호"
                              required
                              size="small"
                              value={
                                isCheckedTax ? watch("managerPhone") : value
                              }
                              onChange={onChange}
                              helperText={
                                <HelperTextMessage>
                                  {errors.taxPhone?.message}
                                </HelperTextMessage>
                              }
                              onInput={numberValidate}
                              disabled={isCheckedTax}
                              fullWidth
                            />
                          )}
                        ></Controller>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <Controller
                          name="taxEmail"
                          control={control}
                          rules={{
                            required: "이메일은 필수값 입니다.",
                            pattern: {
                              value:
                                /^([0-9a-zA-Z_.-]+)@([0-9a-zA-Z_-]+)(\.[0-9a-zA-Z_-]+){1,2}$/,
                              message: "형식에 맞지 않는 이메일 주소입니다."
                            }
                          }}
                          render={({ field: { value, onChange } }) => (
                            <TextField
                              id="taxEmail"
                              label="이메일"
                              required
                              size="small"
                              value={
                                isCheckedTax ? watch("managerEmail") : value
                              }
                              onChange={onChange}
                              helperText={
                                <HelperTextMessage>
                                  {errors.taxEmail?.message}
                                </HelperTextMessage>
                              }
                              disabled={isCheckedTax}
                              fullWidth
                            />
                          )}
                        ></Controller>
                      </Grid>
                    </Grid>
                  </Box>
                  {/* 파일 정보 ======================================================================================= */}
                  <Box
                    sx={{
                      border: "1px solid #E4E4E7",
                      borderRadius: 2,
                      overflow: "hidden"
                    }}
                  >
                    <Stack
                      justifyContent={"center"}
                      alignItems={"center"}
                      sx={{
                        py: 1,
                        borderBottom: "1px solid #E4E4E7",
                        backgroundColor: "#f7f7f7"
                      }}
                    >
                      <Typography>파일 정보</Typography>
                    </Stack>
                    <Box
                      sx={{
                        py: 2,
                        px: 4
                      }}
                    >
                      <Typography sx={{ fontSize: "14px", mt: 1 }}>
                        * 제출 서류의 첨부파일 최대 용량은 10MB 입니다. <br />*
                        제출 서류의 첨부 파일 종류는 jpg, jpeg, gif, png, pdf
                        입니다. <br />* 파일은 이미 등록된 파일만 다운로드
                        가능합니다.
                      </Typography>
                      <Divider sx={{ my: 3 }} />
                      {/* 사업자 */}
                      {data.content.merchantInformation.businessType ===
                      "개인" ? (
                        <Stack gap={2}>
                          <Stack direction={"row"} alignItems="center">
                            <InputLabel sx={{ width: 150, color: "black" }}>
                              사업자등록증
                            </InputLabel>
                            <Controller
                              name={"businessLicense"}
                              control={control}
                              render={({ field: { value } }) => (
                                <TextField
                                  size="small"
                                  value={value}
                                  disabled
                                  sx={{ width: 350 }}
                                />
                              )}
                            ></Controller>
                            <Button
                              component="label"
                              variant="contained"
                              color="info"
                              sx={{ mx: 2 }}
                            >
                              <input
                                type="file"
                                accept="image/*, .pdf"
                                hidden
                                onChange={(e) => 파일선택(e, "businessLicense")}
                              />
                              파일 수정
                            </Button>
                            <IconButton
                              type="button"
                              color="primary"
                              onClick={() =>
                                onClickFileDownload(findFileId("사업자등록증"))
                              }
                            >
                              <FileDownloadIcon />
                            </IconButton>
                            {errors.businessLicense && (
                              <ErrorMessage>
                                {errors.businessLicense.message}
                              </ErrorMessage>
                            )}
                          </Stack>
                          <Stack direction={"row"} alignItems="center">
                            <Stack direction={"row"} alignItems="center">
                              <InputLabel sx={{ width: 150, color: "black" }}>
                                통장사본
                                <Typography
                                  sx={{ fontSize: "12px", color: "gray" }}
                                >
                                  (대표자 명의)
                                </Typography>
                              </InputLabel>
                              <Controller
                                name={"bankBookCopy"}
                                control={control}
                                render={({ field: { value, onChange } }) => (
                                  <TextField
                                    size="small"
                                    value={value}
                                    onChange={onChange}
                                    disabled
                                    sx={{ width: 350 }}
                                  />
                                )}
                              ></Controller>
                              <Button
                                component="label"
                                variant="contained"
                                color="info"
                                sx={{ mx: 2 }}
                              >
                                <input
                                  type="file"
                                  accept="image/*, .pdf"
                                  hidden
                                  onChange={(e) => 파일선택(e, "bankBookCopy")}
                                />
                                파일 수정
                              </Button>
                              <IconButton
                                type="button"
                                color="primary"
                                onClick={() =>
                                  onClickFileDownload(findFileId("통장사본"))
                                }
                              >
                                <FileDownloadIcon />
                              </IconButton>
                              {errors.bankBookCopy && (
                                <ErrorMessage>
                                  {errors.bankBookCopy.message}
                                </ErrorMessage>
                              )}
                            </Stack>
                          </Stack>
                          <Stack direction={"row"} alignItems="center">
                            <Stack direction={"row"} alignItems="center">
                              <InputLabel sx={{ width: 150, color: "black" }}>
                                대표자 인감증명서
                                <Typography
                                  sx={{ fontSize: "12px", color: "gray" }}
                                >
                                  (원본 1부)
                                </Typography>
                              </InputLabel>
                              <Controller
                                name={"sealCertificate"}
                                control={control}
                                render={({ field: { value, onChange } }) => (
                                  <TextField
                                    size="small"
                                    value={value}
                                    onChange={onChange}
                                    disabled
                                    sx={{ width: 350 }}
                                  />
                                )}
                              ></Controller>
                              <Button
                                component="label"
                                variant="contained"
                                color="info"
                                sx={{ mx: 2 }}
                              >
                                <input
                                  type="file"
                                  accept="image/*, .pdf"
                                  hidden
                                  onChange={(e) =>
                                    파일선택(e, "sealCertificate")
                                  }
                                />
                                파일 수정
                              </Button>
                              <IconButton
                                type="button"
                                color="primary"
                                onClick={() =>
                                  onClickFileDownload(findFileId("인감증명서"))
                                }
                              >
                                <FileDownloadIcon />
                              </IconButton>
                              <Tooltip
                                title="서류는 제출기한 기준 최근 3개월 이내 발급 받은 것"
                                placement="right"
                                sx={{ ml: 1 }}
                                arrow
                              >
                                <ErrorOutline />
                              </Tooltip>
                              {errors.sealCertificate && (
                                <ErrorMessage>
                                  {errors.sealCertificate.message}
                                </ErrorMessage>
                              )}
                            </Stack>
                          </Stack>
                          <Stack direction={"row"} alignItems="center">
                            <Stack direction={"row"} alignItems="center">
                              <InputLabel sx={{ width: 150, color: "black" }}>
                                대표자 신분증
                                <Typography
                                  sx={{ fontSize: "12px", color: "gray" }}
                                >
                                  (앞, 뒤 사본 각 1부)
                                </Typography>
                              </InputLabel>
                              <Controller
                                name={"idCardFront"}
                                control={control}
                                render={({ field: { value, onChange } }) => (
                                  <TextField
                                    size="small"
                                    value={value}
                                    onChange={onChange}
                                    disabled
                                    sx={{ width: 350 }}
                                  />
                                )}
                              ></Controller>
                              <Button
                                component="label"
                                variant="contained"
                                color="info"
                                sx={{ mx: 2 }}
                              >
                                <input
                                  type="file"
                                  accept="image/*, .pdf"
                                  hidden
                                  onChange={(e) => 파일선택(e, "idCardFront")}
                                />
                                파일 수정 (앞)
                              </Button>
                              <IconButton
                                type="button"
                                color="primary"
                                onClick={() =>
                                  onClickFileDownload(findFileId("신분증_앞"))
                                }
                              >
                                <FileDownloadIcon />
                              </IconButton>
                              {errors.idCardFront && (
                                <ErrorMessage>
                                  {errors.idCardFront.message}
                                </ErrorMessage>
                              )}
                            </Stack>
                          </Stack>
                          <Stack direction={"row"} alignItems="center">
                            <Stack direction={"row"} alignItems="center">
                              <InputLabel sx={{ width: 150, color: "black" }}>
                                {/* 대표자 신분증 (뒤) */}
                              </InputLabel>
                              <Controller
                                name={"idCardBack"}
                                control={control}
                                render={({ field: { value, onChange } }) => (
                                  <TextField
                                    size="small"
                                    value={value}
                                    onChange={onChange}
                                    disabled
                                    sx={{ width: 350 }}
                                  />
                                )}
                              ></Controller>
                              <Button
                                component="label"
                                variant="contained"
                                color="info"
                                sx={{ mx: 2 }}
                              >
                                <input
                                  type="file"
                                  accept="image/*, .pdf"
                                  hidden
                                  onChange={(e) => 파일선택(e, "idCardBack")}
                                />
                                파일 수정 (뒤)
                              </Button>
                              <IconButton
                                type="button"
                                color="primary"
                                onClick={() =>
                                  onClickFileDownload(findFileId("신분증_뒤"))
                                }
                              >
                                <FileDownloadIcon />
                              </IconButton>
                              {errors.idCardBack && (
                                <ErrorMessage>
                                  {errors.idCardBack.message}
                                </ErrorMessage>
                              )}
                            </Stack>
                          </Stack>
                          <Stack direction={"row"} alignItems="center">
                            <Stack direction={"row"} alignItems="center">
                              <InputLabel sx={{ width: 150, color: "black" }}>
                                영중소 등급확인서
                              </InputLabel>
                              <Controller
                                name={"gradeConfirmation"}
                                control={control}
                                render={({ field: { value, onChange } }) => (
                                  <TextField
                                    size="small"
                                    value={value}
                                    onChange={onChange}
                                    disabled
                                    sx={{ width: 350 }}
                                  />
                                )}
                              ></Controller>
                              <Button
                                component="label"
                                variant="contained"
                                color="info"
                                sx={{ mx: 2 }}
                              >
                                <input
                                  type="file"
                                  accept="image/*, .pdf"
                                  hidden
                                  onChange={(e) =>
                                    파일선택(e, "gradeConfirmation")
                                  }
                                />
                                파일 수정
                              </Button>
                              <IconButton
                                type="button"
                                color="primary"
                                onClick={() =>
                                  onClickFileDownload(findFileId("등급확인서"))
                                }
                              >
                                <FileDownloadIcon />
                              </IconButton>
                              {errors.gradeConfirmation && (
                                <ErrorMessage>
                                  {errors.gradeConfirmation.message}
                                </ErrorMessage>
                              )}
                            </Stack>
                          </Stack>
                        </Stack>
                      ) : (
                        // 법인 사업자
                        <Stack gap={2}>
                          <Stack direction={"row"} alignItems="center">
                            <InputLabel sx={{ width: 150, color: "black" }}>
                              사업자등록증
                            </InputLabel>
                            <Controller
                              name={"businessLicense"}
                              control={control}
                              render={({ field: { value, onChange } }) => (
                                <TextField
                                  size="small"
                                  value={value}
                                  onChange={onChange}
                                  disabled
                                />
                              )}
                            ></Controller>
                            <Button
                              component="label"
                              variant="contained"
                              color="info"
                              sx={{ mx: 2 }}
                            >
                              <input
                                type="file"
                                accept="image/*, .pdf"
                                hidden
                                onChange={(e) => 파일선택(e, "businessLicense")}
                              />
                              파일 수정
                            </Button>
                            <IconButton
                              type="button"
                              color="primary"
                              onClick={() =>
                                onClickFileDownload(findFileId("사업자등록증"))
                              }
                            >
                              <FileDownloadIcon />
                            </IconButton>
                            {errors.businessLicense && (
                              <ErrorMessage>
                                {errors.businessLicense.message}
                              </ErrorMessage>
                            )}
                          </Stack>
                          <Stack direction={"row"} alignItems="center">
                            <Stack direction={"row"} alignItems="center">
                              <InputLabel sx={{ width: 150, color: "black" }}>
                                통장 사본
                                <Typography
                                  sx={{ fontSize: "12px", color: "gray" }}
                                >
                                  (법인 명의)
                                </Typography>
                              </InputLabel>
                              <Controller
                                name={"bankBookCopy"}
                                control={control}
                                render={({ field: { value, onChange } }) => (
                                  <TextField
                                    size="small"
                                    value={value}
                                    onChange={onChange}
                                    disabled
                                  />
                                )}
                              ></Controller>
                              <Button
                                component="label"
                                variant="contained"
                                color="info"
                                sx={{ mx: 2 }}
                              >
                                <input
                                  type="file"
                                  accept="image/*, .pdf"
                                  hidden
                                  onChange={(e) => 파일선택(e, "bankBookCopy")}
                                />
                                파일 수정
                              </Button>
                              <IconButton
                                type="button"
                                color="primary"
                                onClick={() =>
                                  onClickFileDownload(findFileId("통장사본"))
                                }
                              >
                                <FileDownloadIcon />
                              </IconButton>
                              {errors.bankBookCopy && (
                                <ErrorMessage>
                                  {errors.bankBookCopy.message}
                                </ErrorMessage>
                              )}
                            </Stack>
                          </Stack>

                          <Stack direction={"row"} alignItems="center">
                            <InputLabel sx={{ width: 150, color: "black" }}>
                              법인인감증명서
                            </InputLabel>
                            <Controller
                              name={"corporateSealCertificate"}
                              control={control}
                              render={({ field: { value, onChange } }) => (
                                <TextField
                                  size="small"
                                  value={value}
                                  onChange={onChange}
                                  disabled
                                />
                              )}
                            ></Controller>
                            <Button
                              component="label"
                              variant="contained"
                              color="info"
                              sx={{ mx: 2 }}
                            >
                              <input
                                type="file"
                                accept="image/*, .pdf"
                                hidden
                                onChange={(e) =>
                                  파일선택(e, "corporateSealCertificate")
                                }
                              />
                              파일 수정
                            </Button>
                            <IconButton
                              type="button"
                              color="primary"
                              onClick={() =>
                                onClickFileDownload(
                                  findFileId("법인인감증명서")
                                )
                              }
                            >
                              <FileDownloadIcon />
                            </IconButton>
                            {errors.corporateSealCertificate && (
                              <ErrorMessage>
                                {errors.corporateSealCertificate.message}
                              </ErrorMessage>
                            )}
                          </Stack>

                          <Stack direction={"row"} alignItems="center">
                            <Stack direction={"row"} alignItems="center">
                              <InputLabel sx={{ width: 150, color: "black" }}>
                                사용인감계
                                <Typography
                                  sx={{ fontSize: "12px", color: "gray" }}
                                >
                                  (원본 1부)
                                </Typography>
                              </InputLabel>
                              <Controller
                                name={"sealCertificate"}
                                control={control}
                                render={({ field: { value, onChange } }) => (
                                  <TextField
                                    size="small"
                                    value={value}
                                    onChange={onChange}
                                    disabled
                                  />
                                )}
                              ></Controller>
                              <Button
                                component="label"
                                variant="contained"
                                color="info"
                                sx={{ mx: 2 }}
                              >
                                <input
                                  type="file"
                                  accept="image/*, .pdf"
                                  hidden
                                  onChange={(e) =>
                                    파일선택(e, "sealCertificate")
                                  }
                                />
                                파일 수정
                              </Button>
                              <IconButton
                                type="button"
                                color="primary"
                                onClick={() =>
                                  onClickFileDownload(findFileId("인감증명서"))
                                }
                              >
                                <FileDownloadIcon />
                              </IconButton>
                              <Tooltip
                                title="서류는 제출기한 기준 최근 3개월 이내 발급 받은 것"
                                placement="right"
                                sx={{ ml: 1 }}
                                arrow
                              >
                                <ErrorOutline />
                              </Tooltip>
                              {errors.sealCertificate && (
                                <ErrorMessage>
                                  {errors.sealCertificate.message}
                                </ErrorMessage>
                              )}
                            </Stack>
                          </Stack>
                          <Stack direction={"row"} alignItems="center">
                            <Stack direction={"row"} alignItems="center">
                              <InputLabel sx={{ width: 150, color: "black" }}>
                                등기사항전부증명서
                                <Typography
                                  sx={{ fontSize: "12px", color: "gray" }}
                                >
                                  (원본 1부)
                                </Typography>
                              </InputLabel>
                              <Controller
                                name={"corporateRegistration"}
                                control={control}
                                render={({ field: { value, onChange } }) => (
                                  <TextField
                                    size="small"
                                    value={value}
                                    onChange={onChange}
                                    disabled
                                  />
                                )}
                              ></Controller>
                              <Button
                                component="label"
                                variant="contained"
                                color="info"
                                sx={{ mx: 2 }}
                              >
                                <input
                                  type="file"
                                  accept="image/*, .pdf"
                                  hidden
                                  onChange={(e) =>
                                    파일선택(e, "corporateRegistration")
                                  }
                                />
                                파일 수정
                              </Button>
                              <IconButton
                                type="button"
                                color="primary"
                                onClick={() =>
                                  onClickFileDownload(
                                    findFileId("등기사항전부증명서")
                                  )
                                }
                              >
                                <FileDownloadIcon />
                              </IconButton>
                              <Tooltip
                                title="서류는 제출기한 기준 최근 3개월 이내 발급 받은 것"
                                placement="right"
                                sx={{ ml: 1 }}
                                arrow
                              >
                                <ErrorOutline />
                              </Tooltip>
                              {errors.corporateRegistration && (
                                <ErrorMessage>
                                  {errors.corporateRegistration.message}
                                </ErrorMessage>
                              )}
                            </Stack>
                          </Stack>
                          <Stack direction={"row"} alignItems="center">
                            <Stack direction={"row"} alignItems="center">
                              <InputLabel sx={{ width: 150, color: "black" }}>
                                실제소유자확인서
                              </InputLabel>
                              <Controller
                                name={"actualOwnerConfirmation"}
                                control={control}
                                render={({ field: { value, onChange } }) => (
                                  <TextField
                                    size="small"
                                    value={value}
                                    onChange={onChange}
                                    disabled
                                  />
                                )}
                              ></Controller>
                              <Button
                                component="label"
                                variant="contained"
                                color="info"
                                sx={{ mx: 2 }}
                              >
                                <input
                                  type="file"
                                  accept="image/*, .pdf"
                                  hidden
                                  onChange={(e) =>
                                    파일선택(e, "actualOwnerConfirmation")
                                  }
                                />
                                파일 수정
                              </Button>
                              <IconButton
                                type="button"
                                color="primary"
                                onClick={() =>
                                  onClickFileDownload(
                                    findFileId("실제소유자확인서")
                                  )
                                }
                              >
                                <FileDownloadIcon />
                              </IconButton>
                              <Tooltip
                                title="주주명부, 정관, 사원명부 중 택 1"
                                placement="right"
                                sx={{ ml: 1 }}
                                arrow
                              >
                                <ErrorOutline />
                              </Tooltip>
                              {errors.actualOwnerConfirmation && (
                                <ErrorMessage>
                                  {errors.actualOwnerConfirmation.message}
                                </ErrorMessage>
                              )}
                            </Stack>
                          </Stack>
                          <Stack direction={"row"} alignItems="center">
                            <Stack direction={"row"} alignItems="center">
                              <InputLabel sx={{ width: 150, color: "black" }}>
                                대표자 신분증
                                <Typography
                                  sx={{ fontSize: "12px", color: "gray" }}
                                >
                                  (앞, 뒤 사본 각 1부)
                                </Typography>
                              </InputLabel>
                              <Controller
                                name={"idCardFront"}
                                control={control}
                                render={({ field: { value, onChange } }) => (
                                  <TextField
                                    size="small"
                                    value={value}
                                    onChange={onChange}
                                    disabled
                                  />
                                )}
                              ></Controller>
                              <Button
                                component="label"
                                variant="contained"
                                color="info"
                                sx={{ mx: 2 }}
                              >
                                <input
                                  type="file"
                                  accept="image/*, .pdf"
                                  hidden
                                  onChange={(e) => 파일선택(e, "idCardFront")}
                                />
                                파일 수정 (앞)
                              </Button>
                              <IconButton
                                type="button"
                                color="primary"
                                onClick={() =>
                                  onClickFileDownload(findFileId("신분증_앞"))
                                }
                              >
                                <FileDownloadIcon />
                              </IconButton>
                              {errors.idCardFront && (
                                <ErrorMessage>
                                  {errors.idCardFront.message}
                                </ErrorMessage>
                              )}
                            </Stack>
                          </Stack>
                          <Stack direction={"row"} alignItems="center">
                            <Stack direction={"row"} alignItems="center">
                              <InputLabel sx={{ width: 150, color: "black" }}>
                                {/* 대표자 신분증 (뒤) */}
                              </InputLabel>
                              <Controller
                                name={"idCardBack"}
                                control={control}
                                render={({ field: { value, onChange } }) => (
                                  <TextField
                                    size="small"
                                    value={value}
                                    onChange={onChange}
                                    disabled
                                  />
                                )}
                              ></Controller>
                              <Button
                                component="label"
                                variant="contained"
                                color="info"
                                sx={{ mx: 2 }}
                              >
                                <input
                                  type="file"
                                  accept="image/*, .pdf"
                                  hidden
                                  onChange={(e) => 파일선택(e, "idCardBack")}
                                />
                                파일 수정 (뒤)
                              </Button>
                              <IconButton
                                type="button"
                                color="primary"
                                onClick={() =>
                                  onClickFileDownload(findFileId("신분증_뒤"))
                                }
                              >
                                <FileDownloadIcon />
                              </IconButton>
                              {errors.idCardBack && (
                                <ErrorMessage>
                                  {errors.idCardBack.message}
                                </ErrorMessage>
                              )}
                            </Stack>
                          </Stack>
                        </Stack>
                      )}
                    </Box>
                  </Box>
                </Stack>
              </form>
            </DialogContent>
            {/* 어드민 수정폼 ======================================================================================= */}
            {data?.content.status === "가맹심사중" && (
              <MerchantsAdminEdit
                merchantId={merchantId}
                closeModal={closeModal}
              />
            )}
          </>
        ) : (
          <Stack
            justifyContent={"center"}
            alignItems="center"
            sx={{ width: 1100, height: 900 }}
          >
            <Typography>데이터를 조회 중입니다.</Typography>
          </Stack>
        )}
      </Dialog>
    </>
  );
};
export default AdminModifyClientDataModal;
