import CloseIcon from "@mui/icons-material/Close";
import MuiLoadingButton from "@mui/lab/LoadingButton";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import { Controller, useForm } from "react-hook-form";
import { numberCommaValidate } from "../../libs/input-validation";
import Typography from "@mui/material/Typography";
import InputAdornment from "@mui/material/InputAdornment";
import { useMutation } from "@tanstack/react-query";
import { putCardCompanyGrade } from "../../api/CardInstallments";
import { removeCommas } from "../../libs/thousands-commas";
import { useSetRecoilState } from "recoil";
import isModalOpenAtom from "../../recoil/isModalOpen";

interface EditModalProps {
  open?: boolean;
  closeModal?: () => void;
  onButtonSubmit: () => void;
  loading?: boolean;
  cardCompanyName: string;
  mccType: string;
  grade: string;
}

const EditModal = ({
  open = true,
  closeModal,
  onButtonSubmit,
  cardCompanyName,
  mccType,
  grade,
  loading = false
}: EditModalProps) => {
  // ===================================================================================================================
  // 리코일 스테이트
  // ===================================================================================================================
  const setIsModalOpen = useSetRecoilState(isModalOpenAtom);

  // ===================================================================================================================
  // 리액트 훅 폼
  // ===================================================================================================================
  const {
    handleSubmit,
    control,
    register,
    watch,
    formState: { isDirty, isValid }
  } = useForm({
    defaultValues: {
      isUse: 1,
      deposit: "0",
      depositType: "보험"
    }
  });

  // ===================================================================================================================
  // 리액트 쿼리
  // ===================================================================================================================
  const { mutate } = useMutation(putCardCompanyGrade, {
    onSuccess: () => {
      closeModal?.();
      setIsModalOpen({
        value: true,
        position: "top",
        alertSeverity: "success",
        message: "정보가 저장되었습니다."
      });
      onButtonSubmit?.();
    },
    onError: (error: any) => {
      setIsModalOpen({
        value: true,
        position: "top",
        alertSeverity: "error",
        message: error?.response?.data?.message
      });
    }
  });

  const onSubmit = (formData: any) => {
    const params = {
      ...formData,
      deposit: removeCommas(formData.deposit),
      cardCompanyName: cardCompanyName,
      mccType: mccType,
      grade: grade
    };
    mutate(params);
  };

  return (
    <Dialog open={open} maxWidth="xs" fullWidth>
      <DialogTitle sx={{ wordWrap: "break-word" }}>
        {cardCompanyName} 카드사 정보 수정
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={() => {
          closeModal?.();
        }}
        sx={{
          position: "absolute",
          right: 8,
          top: 12
        }}
      >
        <CloseIcon />
      </IconButton>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Controller
                name="isUse"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    label="사용여부"
                    required
                    fullWidth
                    size="small"
                    select
                    value={value}
                    onChange={onChange}
                  >
                    <MenuItem value={0}>미사용</MenuItem>
                    <MenuItem value={1}>사용</MenuItem>
                  </TextField>
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="보증 금액"
                fullWidth
                size="small"
                {...register("deposit")}
                onInput={numberCommaValidate}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">원</InputAdornment>
                  )
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="depositType"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    label="보증 타입"
                    required
                    fullWidth
                    size="small"
                    select
                    value={value}
                    onChange={onChange}
                  >
                    <MenuItem value="보험">보험</MenuItem>
                    <MenuItem value="예치금">예치금</MenuItem>
                    <MenuItem value="매출액">매출액</MenuItem>
                    <MenuItem value="보증없음">보증없음</MenuItem>
                  </TextField>
                )}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <MuiLoadingButton
            type="submit"
            variant="contained"
            sx={{ color: "white" }}
            fullWidth
            loading={loading}
            disabled={!watch("deposit")}
          >
            수정 하기
          </MuiLoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default EditModal;
