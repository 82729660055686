import React from "react";
import Title from "../components/Title";
import Stack from "@mui/material/Stack";
import AdminLayout from "../components/Layout/Admin/Layout";
import Calendar from "../components/DepositCalendar/Calendar";

const DepositAmount = () => {
  return (
    <>
      <Title title="카드사 입금 예정 금액" />
      <AdminLayout>
        <Stack spacing={3}>
          <Calendar />
        </Stack>
      </AdminLayout>
    </>
  );
};

export default DepositAmount;
