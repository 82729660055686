import Grid from "@mui/material/Grid";
import Skeleton from "@mui/material/Skeleton";
import Typography from "@mui/material/Typography";
import { blue, grey, orange, red, teal } from "@mui/material/colors";
import { useQuery } from "@tanstack/react-query";
import { format, isSameMonth } from "date-fns";
import { useState } from "react";
import { getDepositAmount, getHolidays } from "../../api/DepositAmount";
import {
  CardCompanyDeposit,
  DepositDetail
} from "../../api/DepositAmount/type";
import { addCommas } from "../../libs/thousands-commas";
import CalendarDetailModal from "./CalendarDetail";
import useRenewal from "../../hooks/useRenewal";
import Box from "@mui/material/Box";

const days = [
  "일요일",
  "월요일",
  "화요일",
  "수요일",
  "목요일",
  "금요일",
  "토요일"
];

interface Props {
  date: Date;
}

interface Cell extends CardCompanyDeposit {
  isHoliday: boolean;
  isCurrentMonth: boolean;
}

export interface ModalDetail {
  referenceDate: string;
  isModalOpen: boolean;
  detail: DepositDetail[];
}

const CalendarBody = ({ date }: Props) => {
  const [year, month] = format(date, "yyyy-MM").split("-");
  const [detailData, setDetailData] = useState<ModalDetail>({
    referenceDate: "",
    isModalOpen: false,
    detail: [
      {
        cardCompanyName: "",
        purchaseSumAmount: 0,
        unsettledPrice: 0,
        todaySumAmount: 0
      }
    ]
  });
  const { isRenewalTokenLoading, mutateRenewalToken } = useRenewal();
  const { data: holidayData } = useQuery(["/holidays", `${year}${month}`], () =>
    getHolidays({ searchMonth: `${year}${month}` })
  );
  const { data, isLoading } = useQuery(
    ["/cardCompany/deposit/dueAmount", `${year}-${month}`],
    () => getDepositAmount({ searchYearMonth: `${year}-${month}` }),
    {
      enabled: !!holidayData?.content,
      select: (res) => ({
        content: res.content.map((dayData: CardCompanyDeposit) => {
          return {
            ...dayData,
            isHoliday: holidayData!.content.includes(dayData.referenceDate),
            isCurrentMonth: isSameMonth(
              new Date(dayData.referenceDate),
              new Date(Number(year), Number(month) - 1)
            )
          };
        })
      }),
      onSuccess: () => {
        if (isRenewalTokenLoading) return;
        mutateRenewalToken();
      }
    }
  );
  return (
    <>
      <Grid
        container
        columns={{ xs: 7, sm: 7, md: 7 }}
        direction={"row"}
        justifyContent={"space-between"}
        sx={{
          p: 1
        }}
      >
        {days.map((day) => (
          <Grid
            item
            key={day}
            sm={1}
            md={1}
            sx={{
              display: { xs: "none", sm: "block" },
              p: 1,
              textAlign: "start"
            }}
          >
            <Typography
              sx={{
                color:
                  day === "일요일" || day === "토요일" ? red[500] : grey[700]
              }}
            >
              {day}
            </Typography>
          </Grid>
        ))}
        {isLoading
          ? Array.from({ length: 35 }).map((_, index) => (
              <Grid
                key={index}
                item
                xs={7}
                sm={1}
                sx={{ borderBottom: 1, minHeight: 110, p: 1 }}
              >
                <Skeleton
                  variant="text"
                  animation="wave"
                  width={30}
                  sx={{ fontSize: "1rem" }}
                />
                <Skeleton
                  variant="text"
                  animation="wave"
                  width={150}
                  sx={{ fontSize: "1rem" }}
                />
                <Skeleton
                  variant="text"
                  animation="wave"
                  width={150}
                  sx={{ fontSize: "1rem" }}
                />
              </Grid>
            ))
          : data?.content?.map((cell: Cell, index: number) => {
              const isThursday = days[index % 7] === "목요일";
              return (
                <Grid
                  key={cell.referenceDate}
                  item
                  xs={7}
                  sm={1}
                  sx={{
                    borderBottom: "1px solid #c7c7c7",
                    height: 150,
                    p: 1,
                    "&:hover": {
                      backgroundColor: "#FCFCFC"
                    },
                    cursor: "pointer",
                    transition: "all 0.3s"
                  }}
                  onClick={() =>
                    setDetailData({
                      referenceDate: cell.referenceDate,
                      isModalOpen: true,
                      detail: cell.detail
                    })
                  }
                >
                  <Box>
                    <Typography
                      sx={{
                        color:
                          index % 7 === 0 || index % 7 === 6 || cell.isHoliday
                            ? red[500]
                            : grey[700],
                        p: 1,
                        fontWeight: cell.isCurrentMonth ? 600 : null,
                        opacity: cell.isCurrentMonth ? null : 0.5
                      }}
                    >
                      {cell.referenceDate.slice(8)}
                    </Typography>
                    <Typography
                      sx={{
                        color: blue[500],
                        paddingLeft: 1,
                        fontWeight: cell.isCurrentMonth ? 600 : null,
                        opacity: cell.isCurrentMonth ? null : 0.5
                      }}
                    >
                      {addCommas(cell.totalAmount)}
                    </Typography>

                    {isThursday && (
                      <div>
                        <Typography
                          sx={{
                            color: orange[500],
                            paddingLeft: 1,
                            fontWeight: cell.isCurrentMonth ? 600 : null,
                            opacity: cell.isCurrentMonth ? null : 0.5
                          }}
                        >
                          {addCommas(cell.settlementAmount)}
                        </Typography>
                        <Typography
                          sx={{
                            color: red[500],
                            paddingLeft: 1,
                            fontWeight: cell.isCurrentMonth ? 600 : null,
                            opacity: cell.isCurrentMonth ? null : 0.5
                          }}
                        >
                          {addCommas(cell.payOutSettlementAmount)}
                        </Typography>
                        <Typography
                          sx={{
                            color: teal[500],
                            paddingLeft: 1,
                            fontWeight: cell.isCurrentMonth ? 600 : null,
                            opacity: cell.isCurrentMonth ? null : 0.5
                          }}
                        >
                          {addCommas(cell.waitSettlementAmount)}
                        </Typography>
                      </div>
                    )}
                  </Box>
                </Grid>
              );
            })}
      </Grid>
      <CalendarDetailModal
        detailData={detailData}
        setDetailData={setDetailData}
      />
    </>
  );
};

export default CalendarBody;
