import { instanceWithAuth } from "../../Instance";
import { CommonApi } from "../../type";
import {
  CardDetailData,
  CardTotalData,
  PaymentDetailData,
  PaymentTotalData,
  PaymentViewData,
  PaymentsDetailParams,
  PaymentsReceiptParams,
  PaymentsTotalParams
} from "./types";

// =================================================================================================
// 거래내역 - version 2
// =================================================================================================
/**
 * 거래내역 가맹점별 합계 테이터 조회
 */
export const getPaymentsTotal = async (params: PaymentsTotalParams) => {
  const url = "/payments/total";
  const { data } = await instanceWithAuth.get<CommonApi<PaymentTotalData[]>>(
    url,
    { params }
  );
  return data;
};

/**
 * 거래내역 카드사별 총합계 조회
 */
export const getCardTotal = async (params: PaymentsTotalParams) => {
  const url = "/payments/total/cardCompany";
  const { data } = await instanceWithAuth.get<CommonApi<CardTotalData[]>>(url, {
    params
  });
  return data;
};

/**
 * 거래내역 카드사별 상세 조회
 */
export const getCardDetail = async (params: PaymentsDetailParams) => {
  const url = "/payments/total/cardCompany/detail";
  const { data } = await instanceWithAuth.get<CommonApi<CardDetailData[]>>(
    url,
    { params }
  );
  return data;
};

/**
 * 거래내역 카드사별 상세 엑셀 데이터 조회
 */
export const getCardsDetailExcel = async (params: PaymentsDetailParams) => {
  const url = "/payments/total/cardCompany/detail/excel";
  const { data } = await instanceWithAuth.get<CommonApi<CardDetailData[]>>(
    url,
    { params }
  );
  return data;
};

/**
 * 거래내역 거래 상세 내역 조회
 */
export const getPaymentsDetail = async (params: PaymentsDetailParams) => {
  const url = "/payments/detail";
  const { data } = await instanceWithAuth.get<CommonApi<PaymentDetailData[]>>(
    url,
    {
      params
    }
  );
  return data;
};

/**
 * 거래내역 엑셀 데이터 조회
 */
export const getPaymentsDetailExcel = async (params: PaymentsTotalParams) => {
  const url = "payments/detail/excel";
  const { data } = await instanceWithAuth.get<CommonApi<PaymentDetailData[]>>(
    url,
    { params }
  );
  return data;
};

/**
 * 현재 운영중인 가맹점 목록 조회
 */
export const getMerchantNames = async (params: string) => {
  const url = "/merchants/names";
  const { data } = await instanceWithAuth.get<CommonApi<string[]>>(url, {
    params
  });
  return data;
};

/**
 * 거래내역 영수증 조회
 */
export const getPaymentsView = async (paymentKeys: string[]) => {
  const url = "/payments/view";
  const params = { paymentKeys };
  const { data } = await instanceWithAuth.get<CommonApi<PaymentViewData>>(url, {
    params
  });
  return data;
};

/**
 * 거래내역 영수증 이메일 전송
 */
export const sendPaymentReceipt = async (params: PaymentsReceiptParams) => {
  const url = "/payments/issuedReceipt";
  const { data } = await instanceWithAuth.get<CommonApi<boolean>>(url, {
    params
  });
  return data;
};
