import React, { useState, useEffect } from "react";
import AdminLayout from "../../components/Layout/Admin/Layout";
import Title from "../../components/Title";
import Stack from "@mui/material/Stack";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  addGroup,
  addGroupMember,
  deleteGroup,
  getGroupMembers,
  getGroups,
  getNotBelongingMembers,
  removeGroupMember
} from "../../api/GroupManagement";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import Chip from "@mui/material/Chip";
import DeleteIcon from "@mui/icons-material/Delete";
import CircularProgress from "@mui/material/CircularProgress";
import { useForm } from "react-hook-form";
import isModalOpenAtom from "../../recoil/isModalOpen";
import { useSetRecoilState } from "recoil";
import Grid from "@mui/material/Grid";
import DeleteModal from "../../components/DeleteModal";
import List from "@mui/material/List";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import useRenewal from "../../hooks/useRenewal";

interface GroupAddForm {
  groupName: string;
}

interface Member {
  groupMemberId: number | null;
  groupName: string | null;
  userId: number | null;
  userEmail: string;
  userName: string;
}

const intersection = (leftSet: Member[], rightSet: Member[]) => {
  return leftSet.filter(
    (leftSetValue: Member) =>
      rightSet.findIndex(
        (rightSetValue: Member) =>
          rightSetValue.userEmail === leftSetValue.userEmail
      ) !== -1
  );
};

const GroupManagement = () => {
  const { isRenewalTokenLoading, mutateRenewalToken } = useRenewal();
  const setIsModalOpen = useSetRecoilState(isModalOpenAtom);
  const [clickedNumber, setClickedNumber] = useState<null | number>(null);
  const handleChipClick = (groupId: number) => {
    setClickedNumber((prev) => {
      if (prev === groupId) return null;
      else return groupId;
    });
  };
  const [checked, setChecked] = useState<Member[]>([]);
  const [left, setLeft] = useState<Member[]>([]);
  const [right, setRight] = useState<Member[]>([]);
  const [deleteModalConfig, setDeleteModalConfig] = useState({
    isOpen: false,
    groupName: "",
    groupId: 0
  });
  const leftCheck = intersection(checked, left);
  const rightCheck = intersection(checked, right);
  const handleToggle = (member: Member) => {
    const memberIndex = checked.findIndex(
      (checkedMember) => checkedMember.userEmail === member.userEmail
    );
    const newChecked = [...checked];

    if (memberIndex === -1) {
      newChecked.push(member);
    } else {
      newChecked.splice(memberIndex, 1);
    }

    setChecked(newChecked);
  };

  const { data, isLoading, refetch } = useQuery(
    ["/admin/group-management"],
    () => getGroups(),
    {
      onError: (error: any) => {
        setIsModalOpen({
          value: true,
          position: "top",
          alertSeverity: "error",
          message: error?.response?.data?.message
        });
      },
      onSuccess: () => {
        if (isRenewalTokenLoading) return;
        mutateRenewalToken();
      }
    }
  );
  const { mutate, isLoading: isMutateLoading } = useMutation(addGroup, {
    onSuccess: () => {
      refetch();
    },
    onError: (error: any) => {
      setIsModalOpen({
        value: true,
        position: "top",
        alertSeverity: "error",
        message: error?.response?.data?.message
      });
    }
  });
  const { mutate: deleteGroupMutate, isLoading: deleteLoading } = useMutation(
    deleteGroup,
    {
      onSuccess: (_, variables) => {
        refetch();
        if (variables === clickedNumber) {
          setLeft([]);
          setRight([]);
        }
      },
      onError: (error: any) => {
        setIsModalOpen({
          value: true,
          position: "top",
          alertSeverity: "error",
          message: error?.response?.data?.message
        });
      }
    }
  );
  const { data: groupMemberData, refetch: getGroupMemberData } = useQuery(
    ["/admin/groups", clickedNumber],
    () => getGroupMembers(clickedNumber),
    {
      enabled: !!clickedNumber,
      select: (res) => ({
        content: res.content.map((groupMember) => ({
          ...groupMember,
          userId: null
        }))
      })
    }
  );
  const { data: notBelongingMemberData, refetch: getNotBelongingMemberData } =
    useQuery(
      ["/admin/noGroups", clickedNumber],
      () => getNotBelongingMembers(clickedNumber),
      {
        enabled: !!clickedNumber,
        select: (res) => ({
          content: res.content.map((notBelongingMember) => ({
            ...notBelongingMember,
            groupMemberId: null,
            groupName: null
          }))
        })
      }
    );

  const { mutate: addGroupMemberMutate, isLoading: addGroupMemeberLoading } =
    useMutation(addGroupMember, {
      onSuccess: () => {
        refetch();
        getGroupMemberData();
        getNotBelongingMemberData();
        setChecked([]);
      },
      onError: (error: any) => {
        setIsModalOpen({
          value: true,
          position: "top",
          alertSeverity: "error",
          message: error?.response?.data?.message
        });
      }
    });

  const {
    mutate: removeGroupMemberMutate,
    isLoading: removeGroupMemberLoading
  } = useMutation(removeGroupMember, {
    onSuccess: () => {
      refetch();
      getGroupMemberData();
      getNotBelongingMemberData();
      setChecked([]);
    },
    onError: (error: any) => {
      setIsModalOpen({
        value: true,
        position: "top",
        alertSeverity: "error",
        message: error?.response?.data?.message
      });
    }
  });

  const { register, handleSubmit, setValue } = useForm<GroupAddForm>();
  const onSubmit = (formData: GroupAddForm) => {
    if (isLoading || isMutateLoading) return;
    if (formData.groupName === "") return;
    if (formData.groupName.trim() === "") {
      setValue("groupName", "");
      return;
    }
    const groupName = formData.groupName.trim();
    if (groupName.length > 60) {
      setIsModalOpen({
        value: true,
        position: "top",
        alertSeverity: "error",
        message: "그룹명은 60자를 넘을 수 없습니다."
      });
      return;
    }
    mutate({ groupName });
    setValue("groupName", "");
  };
  const numberOfChecked = (items: Member[]) =>
    intersection(checked, items).length;
  const handleCheckRight = () => {
    if (removeGroupMemberLoading) return;
    const groupMemberId = leftCheck.map(
      (checkedValue) => checkedValue.groupMemberId
    ) as number[];
    removeGroupMemberMutate(groupMemberId);
  };
  const handleCheckLeft = () => {
    if (addGroupMemeberLoading) return;
    const usersId = rightCheck.map(
      (checkValue) => checkValue.userId
    ) as number[];
    if (clickedNumber !== null) {
      addGroupMemberMutate({ groupId: clickedNumber, usersId });
    }
  };

  const customList = (title: React.ReactNode, items: Member[]) => (
    <Card sx={{ border: "1px solid #F2F3F5" }}>
      <CardHeader
        sx={{ p: 2 }}
        title={title}
        subheader={`${numberOfChecked(items)}/${items.length}개 선택`}
      />
      <Divider />
      <List
        sx={{
          width: 300,
          height: 230,
          overflow: "auto"
        }}
        dense
        component="div"
        role="list"
      >
        {items.map((value: Member) => {
          const labelId = `transfer-list-all-item-${value.userEmail}-label`;

          return (
            <ListItemButton
              key={`${value.userName}-${value.userEmail}`}
              role="listitem"
              onClick={() => handleToggle(value)}
            >
              <ListItemIcon>
                <Checkbox
                  checked={
                    checked.findIndex(
                      (checkedValues: any) =>
                        checkedValues.userEmail === value.userEmail
                    ) !== -1
                  }
                  tabIndex={-1}
                  disableRipple
                  inputProps={{
                    "aria-labelledby": labelId
                  }}
                />
              </ListItemIcon>
              <ListItemText
                id={labelId}
                primary={`${value.userName}(${value.userEmail})`}
              />
            </ListItemButton>
          );
        })}
      </List>
    </Card>
  );
  useEffect(() => {
    if (clickedNumber === null) {
      setLeft([]);
      setRight([]);
    } else {
      if (groupMemberData && notBelongingMemberData) {
        setLeft(groupMemberData?.content);
        setRight(notBelongingMemberData?.content);
      }
    }
  }, [clickedNumber, groupMemberData, notBelongingMemberData]);

  return (
    <>
      <Title title="그룹 관리" />
      <AdminLayout>
        <Paper
          elevation={0}
          sx={{
            p: 3,
            border: "1px solid #F2F3F5",
            borderRadius: 3,
            overflow: "hidden",
            height: 550
          }}
        >
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box
              sx={{
                width: "30%"
              }}
            >
              <TextField
                label="그룹명"
                type="text"
                variant="standard"
                fullWidth
                {...register("groupName")}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {isLoading || isMutateLoading ? (
                        <CircularProgress size={24} />
                      ) : (
                        <IconButton
                          type="submit"
                          sx={{ "&:hover": { color: "primary.main" } }}
                        >
                          <GroupAddIcon />
                        </IconButton>
                      )}
                    </InputAdornment>
                  )
                }}
              />
            </Box>
          </form>
          <Grid spacing={1} mt={2} container>
            {data?.content?.map((group) => (
              <Grid item key={`group_${group.groupId}`}>
                <Chip
                  label={`${group.groupName} (${group.groupMemberCount})`}
                  deleteIcon={<DeleteIcon sx={{ color: "paleturquoise" }} />}
                  onDelete={() =>
                    setDeleteModalConfig({
                      isOpen: true,
                      groupName: group.groupName,
                      groupId: group.groupId
                    })
                  }
                  onClick={() => handleChipClick(group.groupId)}
                  color={
                    group.groupId === clickedNumber ? "primary" : "default"
                  }
                />
              </Grid>
            ))}
          </Grid>
          <DeleteModal
            open={deleteModalConfig.isOpen}
            dialogTitle={`${deleteModalConfig.groupName} 그룹 삭제하기`}
            loading={deleteLoading}
            closeModal={() =>
              setDeleteModalConfig({
                isOpen: false,
                groupName: "",
                groupId: 0
              })
            }
            onButtonSubmit={() => {
              if (deleteModalConfig.groupId === 1) {
                setIsModalOpen({
                  value: true,
                  position: "top",
                  alertSeverity: "error",
                  message: "1번 그룹은 삭제할 수 없습니다."
                });
                return;
              }
              deleteGroupMutate(deleteModalConfig.groupId);
              setDeleteModalConfig({
                isOpen: false,
                groupName: "",
                groupId: 0
              });
            }}
          />
          <Grid
            container
            spacing={2}
            justifyContent="center"
            alignItems="center"
            mt={2}
          >
            <Grid item>{customList("그룹 멤버", left)}</Grid>
            <Grid item>
              <Grid container direction="column" alignItems="center">
                <Button
                  sx={{ my: 0.5 }}
                  variant="outlined"
                  size="small"
                  onClick={handleCheckRight}
                  disabled={leftCheck.length === 0}
                  aria-label="move selected right"
                >
                  &gt;
                </Button>
                <Button
                  sx={{ my: 0.5 }}
                  variant="outlined"
                  size="small"
                  onClick={handleCheckLeft}
                  disabled={rightCheck.length === 0}
                  aria-label="move selected left"
                >
                  &lt;
                </Button>
              </Grid>
            </Grid>
            <Grid item>{customList("추가 가능 멤버", right)}</Grid>
          </Grid>
        </Paper>
      </AdminLayout>
    </>
  );
};

export default GroupManagement;
