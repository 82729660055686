import { instance } from "../Instance";
import type { CommonApi } from "../type";
import type { RSA, Login, LoginParams } from "./types";

export const getRSA = async () => {
  const { data } = await instance.get<CommonApi<RSA>>("/bypass/rsa/public-key");
  return data;
};

export const login = async (loginFormData: LoginParams) => {
  const { data } = await instance.post<CommonApi<Login>>(
    "/bypass/users/login",
    loginFormData
  );
  return data;
};
