import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import TableCell from "@mui/material/TableCell";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableHead from "@mui/material/TableHead";
import { styled } from "@mui/material/styles";
import { grey } from "@mui/material/colors";
import TableBody from "@mui/material/TableBody";
import { addCommas } from "../../libs/thousands-commas";
import { ModalDetail } from "./CalendarBody";
import { DepositDetail } from "../../api/DepositAmount/type";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";

const TH = styled(TableCell)({
  backgroundColor: grey[200],
  textAlign: "right",
  paddingBottom: "8px"
});

const TD = styled(TableCell)({
  textAlign: "right",
  paddingRight: "16px",
  paddingBottom: "8px"
});

interface Props {
  detailData: ModalDetail;
  setDetailData: React.Dispatch<React.SetStateAction<ModalDetail>>;
}

const CalendarDetailModal = ({ detailData, setDetailData }: Props) => {
  return (
    <Dialog open={detailData.isModalOpen} fullWidth maxWidth="sm">
      <DialogTitle>{detailData.referenceDate} 입금 예정 금액</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={() =>
          setDetailData((prev: ModalDetail) => ({
            ...prev,
            isModalOpen: false
          }))
        }
        sx={{
          position: "absolute",
          right: 8,
          top: 12
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <Table>
          <TableHead>
            <TableRow>
              <TH></TH>
              <TH>매입 금액 합계</TH>
              <TH>누적 미정산 금액</TH>
              <TH>실 입금 금액</TH>
            </TableRow>
          </TableHead>
          <TableBody>
            {detailData?.detail?.map((d: DepositDetail) => (
              <TableRow key={d.cardCompanyName}>
                <TH>{d.cardCompanyName}</TH>
                <TD>{addCommas(d.purchaseSumAmount)}</TD>
                <TD>{addCommas(d.unsettledPrice)}</TD>
                <TD>{addCommas(d.todaySumAmount)}</TD>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </DialogContent>
    </Dialog>
  );
};

export default CalendarDetailModal;
