import CloseIcon from "@mui/icons-material/Close";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import SearchIcon from "@mui/icons-material/Search";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Pagination from "@mui/material/Pagination";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useSetRecoilState } from "recoil";
import { getMerchantApiKey } from "../../api/ApiKey";
import { GetApiKey } from "../../api/ApiKey/types";
import ClientLayout from "../../components/Layout/Client/Layout";
import LoadingButton from "../../components/LoadingButton";
import Title from "../../components/Title";
import useRenewal from "../../hooks/useRenewal";
import isModalOpenAtom from "../../recoil/isModalOpen";

interface FormInputs {
  mallName: string;
}

const initialFormValue = {
  mallName: ""
};

interface ApiKeyColumnHeadCell {
  id: keyof GetApiKey;
  label: string;
}

const columns: ApiKeyColumnHeadCell[] = [
  {
    id: "mallId",
    label: "가맹점 ID"
  },
  {
    id: "mallName",
    label: "가맹점명"
  },
  {
    id: "registrationFeeType",
    label: "API 키 타입"
  },
  {
    id: "publicKey",
    label: "공개 키"
  },
  {
    id: "secretKey",
    label: "비밀 키"
  }
];

const ApiKey = () => {
  const { isRenewalTokenLoading, mutateRenewalToken } = useRenewal();
  // ===================================================================================================================
  // 리코일 스테이트
  // ===================================================================================================================
  const setIsModalOpen = useSetRecoilState(isModalOpenAtom);

  // ===================================================================================================================
  // 스테이트
  // ===================================================================================================================
  const [queryData, setQueryData] = useState({
    page: 0,
    pageSize: 20,
    mallName: ""
  });

  const [visibleKeys, setVisibleKeys] = useState<{ [key: string]: boolean }>(
    {}
  );

  // ===================================================================================================================
  // 리액트 훅 폼
  // ===================================================================================================================
  const { register, reset, handleSubmit } = useForm<FormInputs>({
    defaultValues: initialFormValue
  });
  const handleReset = () => {
    reset(initialFormValue);
  };
  const handleForm = (data: FormInputs) => {
    if (isLoading) return;
    setQueryData((prevValue) => ({
      ...prevValue,
      ...data,
      page: 0
    }));
  };

  // ===================================================================================================================
  // 리액트 쿼리
  // ===================================================================================================================
  const { data, isLoading } = useQuery(
    ["/merchant/key", queryData],
    () => getMerchantApiKey(queryData),
    {
      onError: (error: any) => {
        setIsModalOpen({
          value: true,
          position: "top",
          alertSeverity: "error",
          message: error?.response?.data?.message
        });
      },
      onSuccess: () => {
        if (!isRenewalTokenLoading) {
          mutateRenewalToken();
        }
      }
    }
  );

  // ===================================================================================================================
  // 페이지네이션
  // ===================================================================================================================
  const totalPages = data?.pageable?.totalPages
    ? data?.pageable?.totalPages
    : 0;
  const handlePage = (event: React.ChangeEvent<unknown>, page: number) => {
    setQueryData((prevValue) => ({ ...prevValue, page: page - 1 }));
  };

  // ===================================================================================================================
  // 키 복사
  // ===================================================================================================================
  const copyKey = async (key: string) => {
    try {
      await navigator.clipboard.writeText(key);
      setIsModalOpen({
        value: true,
        position: "top",
        alertSeverity: "success",
        message: "복사되었습니다."
      });
    } catch (error) {
      setIsModalOpen({
        value: true,
        position: "top",
        alertSeverity: "error",
        message: "복사에 실패했습니다."
      });
    }
  };

  // 키 값 확인 토글
  const toggleVisibility = (key: string) => {
    setVisibleKeys({ [key]: !visibleKeys[key] });
  };

  return (
    <>
      <Title title="키 관리" />
      <ClientLayout>
        <Paper
          sx={{
            p: 3,
            border: "1px solid #F2F3F5",
            borderRadius: 3,
            mb: 3
          }}
        >
          <form onSubmit={handleSubmit(handleForm)}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={3}>
                <TextField
                  id="input-mallName"
                  label="가맹점명"
                  fullWidth
                  size="small"
                  {...register("mallName")}
                />
              </Grid>
              <Grid item xs={12} md={9}>
                <Stack
                  direction="row"
                  justifyContent="flex-end"
                  alignItems="center"
                  spacing={1}
                >
                  <LoadingButton
                    icon={<SearchIcon />}
                    size="medium"
                    fullWidth={false}
                    loading={false}
                  >
                    조회
                  </LoadingButton>
                  <Button
                    variant="outlined"
                    endIcon={<RestartAltIcon />}
                    onClick={handleReset}
                  >
                    조건 초기화
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </form>
        </Paper>

        <Typography variant="body1" sx={{ mb: 1, fontWeight: "bold" }}>
          API 키 목록
        </Typography>
        <Paper
          sx={{
            border: "1px solid #F2F3F5",
            borderRadius: 3,
            overflow: "hidden"
          }}
        >
          <TableContainer sx={{ maxHeight: 550, minHeight: 550 }}>
            <Table
              stickyHeader
              sx={{
                ...((!data || data?.content?.length === 0) && {
                  height: 550
                })
              }}
            >
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      variant="head"
                      key={column.id}
                      sx={{
                        backgroundColor: "#FBFBFB",
                        whiteSpace: "nowrap",
                        py: 1.5
                      }}
                      align="center"
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {!data || data?.content?.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={columns.length}>
                      <Typography align="center">데이터가 없습니다.</Typography>
                    </TableCell>
                  </TableRow>
                ) : (
                  <>
                    {data?.content?.map((row, rowIndex) => (
                      <TableRow
                        key={`${row.mallId}-${row.registrationFeeType}-${rowIndex}`}
                      >
                        {columns.map((column, colIndex) => {
                          const value = row[column.id];
                          const keyId = `${row.mallId}-${column.id}-${rowIndex}-${colIndex}`;
                          const isVisible = visibleKeys[keyId];
                          return (
                            <TableCell
                              key={column.id}
                              align="center"
                              sx={{ py: 0.5 }}
                            >
                              {column.id === "publicKey" ||
                              column.id === "secretKey" ? (
                                <>
                                  {value ? (
                                    <>
                                      <Box
                                        sx={{
                                          display: "inline-block",
                                          backgroundColor: isVisible
                                            ? "transparent"
                                            : "#e0e0e0",
                                          color: isVisible
                                            ? "inherit"
                                            : "#e0e0e0",
                                          px: 1,
                                          borderRadius: 1,
                                          textAlign: "center",
                                          width: 380,
                                          userSelect: "none"
                                        }}
                                      >
                                        {value}
                                      </Box>
                                      <IconButton
                                        onClick={() =>
                                          isVisible
                                            ? copyKey(value)
                                            : toggleVisibility(keyId)
                                        }
                                      >
                                        {isVisible ? (
                                          <ContentCopyIcon />
                                        ) : (
                                          <VisibilityIcon />
                                        )}
                                      </IconButton>
                                    </>
                                  ) : (
                                    <CloseIcon />
                                  )}
                                </>
                              ) : (
                                value
                              )}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    ))}
                  </>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <Box my={2}>
            <Pagination
              count={totalPages}
              variant="outlined"
              shape="rounded"
              page={queryData.page + 1}
              onChange={handlePage}
              sx={{ display: "flex", justifyContent: "center" }}
            />
          </Box>
        </Paper>
      </ClientLayout>
    </>
  );
};

export default ApiKey;
