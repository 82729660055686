import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useEffect, useRef, useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import LeyutaeLogin from "../components/Leyutae/Login";
import LeyutaePayments from "../components/Leyutae/Payments";
import isModalOpen from "../recoil/isModalOpen/atom";
import LoginInfoAtom from "../recoil/loginInfo";

const LeyutaeEmails = ["pay@1xbet-team.com"];

const Leyutae = () => {
  // ===================================================================================================================
  // 리코일 스테이트
  // ===================================================================================================================
  const loginInfo = useRecoilValue(LoginInfoAtom);
  const setLoginInfo = useSetRecoilState(LoginInfoAtom);
  const setIsModalOpen = useSetRecoilState(isModalOpen);

  // ===================================================================================================================
  // useRef - 컴포넌트가 리렌더링될 때마다 기존 타이머 유지
  // ===================================================================================================================
  const logoutTimerRef = useRef<NodeJS.Timeout | null>(null);
  const warningTimerRef = useRef<NodeJS.Timeout | null>(null);

  // ===================================================================================================================
  // useState - 모달창 상태 관리
  // ===================================================================================================================
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleExtendSession = () => {
    setOpen(false);
    if (logoutTimerRef.current) {
      clearTimeout(logoutTimerRef.current);
    }
    if (warningTimerRef.current) {
      clearTimeout(warningTimerRef.current);
    }

    const logoutTimer = setTimeout(() => {
      setLoginInfo((prevState) => ({ ...prevState, accessToken: "" }));
      setIsModalOpen({
        value: true,
        position: "top",
        alertSeverity: "error",
        message: "Login session has expired."
      });
    }, 3600000);

    const warningTimer = setTimeout(() => {
      setIsModalOpen({
        value: true,
        position: "top",
        alertSeverity: "error",
        message: "Your session will expire in 10 minutes."
      });
      setOpen(true);
    }, 3000000); // 50분 = 3000000

    logoutTimerRef.current = logoutTimer;
    warningTimerRef.current = warningTimer;
  };

  useEffect(() => {
    if (!loginInfo.accessToken) {
      // accessToken이 없으면 로그인 상태를 초기화
      if (loginInfo.accessToken !== "") {
        setLoginInfo((prevState) => ({ ...prevState, accessToken: "" }));
      }
    } else if (!LeyutaeEmails.includes(loginInfo.loginUser.email)) {
      // 레유태 가맹점 이메일이 아니면 에러 토스트를 표시하고 로그인 상태를 초기화
      setIsModalOpen({
        value: true,
        position: "top",
        alertSeverity: "error",
        message: "This account does not have access."
      });
      setLoginInfo((prevState) => ({ ...prevState, accessToken: "" }));
    } else {
      // 1시간 후에 로그아웃되도록 타이머 설정
      if (logoutTimerRef.current) {
        clearTimeout(logoutTimerRef.current);
      }
      if (warningTimerRef.current) {
        clearTimeout(warningTimerRef.current);
      }

      const logoutTimer = setTimeout(() => {
        setLoginInfo((prevState) => ({ ...prevState, accessToken: "" }));
        setIsModalOpen({
          value: true,
          position: "top",
          alertSeverity: "error",
          message: "Login session has expired."
        });
      }, 3600000);

      const warningTimer = setTimeout(() => {
        setOpen(true);
      }, 3000000); // 50 minutes = 3000000 ms

      logoutTimerRef.current = logoutTimer;
      warningTimerRef.current = warningTimer;
    }

    // 컴포넌트가 언마운트될 때 타이머 정리
    return () => {
      if (logoutTimerRef.current) {
        clearTimeout(logoutTimerRef.current);
      }
      if (warningTimerRef.current) {
        clearTimeout(warningTimerRef.current);
      }
    };
  }, [
    loginInfo.accessToken,
    loginInfo.loginUser.email,
    setLoginInfo,
    setIsModalOpen
  ]);

  return (
    <>
      {loginInfo.accessToken === "" ? <LeyutaeLogin /> : <LeyutaePayments />}

      {/* 로그인 시간 연장 모달창 */}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Session Expiry Warning
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Your session will expire in 10 minutes. Would you like to extend
            your session?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={handleExtendSession}
            sx={{ color: "white" }}
          >
            Extend Session
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Leyutae;
