import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider, alpha, createTheme } from "@mui/material/styles";
import { Route, Routes } from "react-router-dom";
import Alert from "./components/Alert";
import {
  AdminRouteGuard,
  ClientRouteGuard,
  PrivateRouteGuard,
  PublicRouteGuard
} from "./components/RouteGuard";
import AdminCharges from "./pages/Admin/Charges";
import DepositAmount from "./pages/DepositAmount";
import Login from "./pages/Login";
import NotFound from "./pages/NotFound";
import OTP from "./pages/OTP";
import OTPSetting from "./pages/OTP-setting";
import TempPassword from "./pages/Password/Temp";
// import Payments from "./pages/Payments";
// import Purchases from "./pages/Purchases";
// import Settlements from "./pages/Settlements";
import SettlementsPayout from "./pages/Settlements/Payout";
import Register from "./pages/register";
// import AdminDashboard from "./pages/Admin/Dashboard";
import AdminApiKey from "./pages/Admin/ApiKey";
import CardBinManagement from "./pages/Admin/CardBinManagement";
import CardInstallments from "./pages/Admin/CardInstallments";
import CardLimitManagement from "./pages/Admin/CardLimitManagement";
import ContractManagement from "./pages/Admin/ContractManagement";
import GroupManagement from "./pages/Admin/GroupManagement";
import MerchantCardLimitManagement from "./pages/Admin/MerchantCardLimit";
import MerchantRequestList from "./pages/Admin/MerchantRequestList";
import Merchants from "./pages/Admin/Merchants";
import AdminNotification from "./pages/Admin/Notifications";
import RegistMerchant from "./pages/Admin/RegistMerchant";
import TempMerchantList from "./pages/Admin/TempMerchantList";
import UserManagement from "./pages/Admin/UserManagement";
import Payments from "./pages/Admin/v2/Payments";
import Purchases from "./pages/Admin/v2/Purchases";
import Settlements from "./pages/Admin/v2/Settlements";
import ApiKey from "./pages/Client/ApiKey";
import ClientCalendar from "./pages/Client/Calendar";
import ClientDashboard from "./pages/Client/Dashboard";
import MerchantContractList from "./pages/Client/MerchantContractList";
import ClientMerchant from "./pages/Client/MerchantList";
import MerchantModify from "./pages/Client/MerchantModifyList";
import MerchantRegistration from "./pages/Client/MerchantRegistration";
import ClientNotification from "./pages/Client/Notifications";
import Settlement from "./pages/Client/Settlement";
import Payment from "./pages/Client/v2/Payment";
import Marketing from "./pages/Common/terms/marketing";
import Privacy from "./pages/Common/terms/privacy";
import Email from "./pages/Email";
import Leyutae from "./pages/Leyutae";
import ChangePassword from "./pages/Password";
import PreviewPage from "./pages/PreviewPage";
import Histories from "./pages/Admin/Histories";
import grey from "@mui/material/colors/grey";

function customShadows() {
  return {
    card: `0 0 2px 0 ${alpha(grey[500], 0.08)}, 0 6px 24px -4px ${alpha(
      grey[500],
      0.08
    )}`
  };
}

const theme = createTheme({
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        // 스크롤바 스타일
        body: {
          "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
            width: 15
          },
          "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
            borderRadius: 8,
            backgroundColor: "#e7e7e7",
            border: "3px solid transparent",
            backgroundClip: "content-box"
          },
          "&::-webkit-scrollbar-track, & *::-webkit-scrollbar-track": {
            m: "6px 0"
          }
        }
      }
    },
    // mui paper 섀도우 컬러 세팅
    MuiPaper: {
      styleOverrides: {
        root: {
          boxShadow: customShadows().card
        }
      }
    },
    // mui table row hover 컬러 세팅
    MuiTableRow: {
      styleOverrides: {
        root: {
          "&:hover": {
            backgroundColor: "#fcfcfc"
          }
        }
      }
    }
  },
  typography: {
    fontFamily: "SUIT-Regular, Arial, sans-serif"
  },
  palette: {
    background: {
      default: "#FFFFFF"
    },
    primary: {
      main: "#FF6634"
    },
    secondary: {
      main: "#35BA9B"
    },
    success: {
      main: "#3AADD9"
    },
    error: {
      main: "#D94452"
    },
    info: {
      main: "#DCDCDC"
    },
    warning: {
      main: "#FDCD56"
    }
  }
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Routes>
        <Route path="/test" element={<PreviewPage />} />
        <Route path="/terms/privacy" element={<Privacy />} />
        <Route path="/terms/marketing" element={<Marketing />} />

        {/* 레유태 가맹점을 위한 페이지 - 허브 솔루션 작업 완료 후 삭제될 페이지 입니다 */}
        <Route path="/1xbet" element={<Leyutae />} />

        <Route element={<PublicRouteGuard />}>
          <Route path="/login" element={<Login />} />
          <Route path="/otp-setting" element={<OTPSetting />} />
          <Route path="/otp" element={<OTP />} />
          <Route path="/register" element={<Register />} />
          <Route path="/password/temp" element={<TempPassword />} />
          <Route path="/email" element={<Email />} />
        </Route>
        <Route element={<PrivateRouteGuard />}>
          <Route path="/password" element={<ChangePassword />} />
          <Route element={<ClientRouteGuard />}>
            <Route index element={<ClientDashboard />} />
            <Route path="notifications" element={<ClientNotification />} />
            <Route path="payments">
              {/* 클라이언트 거래 내역 버전 1 */}
              {/* <Route index element={<ClientPayments />} /> */}
              {/* 클라이언트 거래 내역 버전 2 */}
              <Route index element={<Payment />} />
              <Route path="calendar" element={<ClientCalendar />} />
            </Route>
            <Route path="settlement" element={<Settlement />} />
            <Route path="registrations" element={<MerchantRegistration />} />
            <Route path="client-merchant" element={<ClientMerchant />} />
            <Route path="api-key" element={<ApiKey />} />
            <Route
              path="merchant-contract-list"
              element={<MerchantContractList />}
            />
            <Route path="merchant-modify" element={<MerchantModify />} />
          </Route>

          {/* ------------------------------------Admin---------------------------------------- */}
          <Route path="/admin" element={<AdminRouteGuard />}>
            {/* <Route index element={<AdminDashboard />} /> */}
            <Route index element={<DepositAmount />} />
            <Route path="deposit-amount" element={<DepositAmount />} />
            <Route path="notifications" element={<AdminNotification />} />

            {/* 거래내역 버전 1 */}
            {/* <Route path="payments" element={<Payments />} /> */}
            {/* 거래내역 버전 2 */}
            <Route path="payments" element={<Payments />} />

            {/* 승인.취소내역 버전 1 */}
            {/* <Route path="purchases" element={<Purchases />} /> */}
            {/* 승인.취소내역 버전 2 */}
            <Route path="purchases" element={<Purchases />} />

            {/* 정산내역 버전 1 */}
            {/* <Route path="settlements">
              <Route index element={<Settlements />} />
              <Route path="payout" element={<SettlementsPayout />} />
              <Route path="charges" element={<AdminCharges />} />
            </Route> */}
            {/* 정산내역 버전 2 */}
            <Route path="settlements">
              <Route index element={<Settlements />} />
              <Route path="payout" element={<SettlementsPayout />} />
              <Route path="charges" element={<AdminCharges />} />
            </Route>

            <Route path="group-management" element={<GroupManagement />} />
            <Route path="merchants" element={<Merchants />} />
            <Route path="temp-merchants" element={<TempMerchantList />} />
            <Route path="card-installments" element={<CardInstallments />} />
            <Route path="api-key" element={<AdminApiKey />} />
            <Route path="user-management" element={<UserManagement />} />
            <Route
              path="contract-management"
              element={<ContractManagement />}
            />
            <Route path="request-list" element={<MerchantRequestList />} />
            <Route
              path="card-limit-management"
              element={<CardLimitManagement />}
            />
            <Route
              path="merchant-card-limit-management"
              element={<MerchantCardLimitManagement />}
            />
            <Route path="regist-merchant" element={<RegistMerchant />} />
            <Route
              path="card-bin-managements"
              element={<CardBinManagement />}
            ></Route>
            <Route path="histories" element={<Histories />} />
          </Route>
        </Route>
        <Route path="*" element={<NotFound />} />
      </Routes>
      <Alert />
    </ThemeProvider>
  );
}

export default App;
