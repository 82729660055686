import React from "react";
import {
  NavLink as RouterLink,
  NavLinkProps as RouterLinkProps,
  useMatch
} from "react-router-dom";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ArrowRightRoundedIcon from "@mui/icons-material/ArrowRightRounded";

export interface ListItemLinkProps {
  icon?: React.ReactElement;
  iconActive?: React.ReactElement;
  primary: string;
  to: string;
}

const activeStyle = {
  color: "#FF6634",
  backgroundColor: "#FFF3EF",
  borderRadius: 8
};

const ListItemLink = (props: ListItemLinkProps) => {
  const { icon, primary, to, iconActive } = props;
  const match = useMatch(to);

  const renderLink = React.useMemo(
    () =>
      React.forwardRef<HTMLAnchorElement, Omit<RouterLinkProps, "to">>(
        function Link(itemProps, ref) {
          return (
            <RouterLink
              to={to}
              ref={ref}
              {...itemProps}
              style={match ? activeStyle : undefined}
              role={undefined}
            />
          );
        }
      ),
    [to, match]
  );

  return (
    <li>
      <ListItem
        button
        component={renderLink}
        sx={{ borderRadius: 2, mb: 0.5, height: 40 }}
      >
        {/* {icon && match ? (
          <ListItemIcon>{iconActive}</ListItemIcon>
        ) : (
          <ListItemIcon>{icon}</ListItemIcon>
        )} */}
        <ListItemIcon>
          <ArrowRightRoundedIcon fontSize={"small"} />
        </ListItemIcon>
        <ListItemText primary={primary} />
      </ListItem>
    </li>
  );
};

export default ListItemLink;
