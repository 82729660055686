import styled from "@emotion/styled";
import ReportProblemRoundedIcon from "@mui/icons-material/ReportProblemRounded";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";
import Pagination from "@mui/material/Pagination";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import { useQuery } from "@tanstack/react-query";
import React, { useState } from "react";
import { getMerchants } from "../../api/Merchants";
import { MerchantsParams } from "../../api/Merchants/types";
import ClientEditModal from "../../components/ClientMerchant/ClientEditModal";
import ClientMerchantDetailModal from "../../components/ClientMerchant/ClientMerchantDetailModal";
import ClientLayout from "../../components/Layout/Client/Layout";
import Title from "../../components/Title";
import useRenewal from "../../hooks/useRenewal";

interface Columns {
  id: string;
  label: string;
}

const columns: Columns[] = [
  { id: "mallName", label: "가맹점 명" },
  { id: "ownerName", label: "가맹점 대표자 명" },
  { id: "businessName", label: "사업자등록증 상호명" },
  { id: "phone", label: "가맹점 대표 번호" },
  { id: "createdAt", label: "가맹등록일" },
  // { id: "startDate", label: "계약 시작일" },
  // { id: "endDate", label: "계약 종료일" },
  { id: "status", label: "심사 상태" }
];

interface Row {
  merchantId: string;
  amlCatch?: boolean;
  mallId: string;
  mallName: string;
  ownerName: string;
  businessName: string;
  phone: string;
  createdAt: string;
  startDate: string;
  endDate: string;
  status:
    | "전체"
    | "가맹심사대기"
    | "계약심사대기"
    | "가맹심사중"
    | "계약심사중"
    | "계약승인"
    | "가맹심사반려"
    | "계약심사반려";
  isDetailModalOpen: boolean;
  isEditModalOpen: boolean;
}

const FirstSteps = [
  { id: 1, label: "가맹심사대기" },
  { id: 2, label: "가맹심사중" },
  { id: 3, label: "승인 또는 반려" }
];

const SecondSteps = [
  { id: 1, label: "계약심사대기" },
  { id: 2, label: "계약심사중" },
  { id: 3, label: "승인 또는 반려" }
];

export const HelperTextMessage = styled("span")({
  position: "absolute",
  color: "#d94452"
});

const MerchantList = () => {
  const { isRenewalTokenLoading, mutateRenewalToken } = useRenewal();
  // ===================================================================================================================
  // 컴포넌트 스테이트
  // ===================================================================================================================
  const [params, setParams] = useState<MerchantsParams>({
    mallId: "",
    mallName: "",
    page: 0,
    size: 10,
    status: "전체"
  });

  const [selectedData, setSelectedData] = useState<Row>({
    isDetailModalOpen: false,
    isEditModalOpen: false,
    merchantId: "",
    mallId: "",
    mallName: "",
    ownerName: "",
    businessName: "",
    phone: "",
    createdAt: "",
    startDate: "",
    endDate: "",
    status: "전체"
  });

  // ===================================================================================================================
  // 리액트 쿼리
  // ===================================================================================================================
  const { data } = useQuery(
    ["/merchants", params],
    () => getMerchants(params),
    {
      retry: false,
      onSuccess: () => {
        if (!isRenewalTokenLoading) {
          mutateRenewalToken();
        }
      }
    }
  );

  // ===================================================================================================================
  // 심사 상태 수정
  // ===================================================================================================================
  const selectRow = (row: any) => {
    let selectedData;
    if (row.status === "가맹심사반려") {
      selectedData = {
        ...row,
        isDetailModalOpen: false,
        isEditModalOpen: true
      };
    } else {
      selectedData = {
        ...row,
        isDetailModalOpen: true,
        isEditModalOpen: false
      };
    }
    setSelectedData(selectedData);
  };

  const closeModal = () => {
    setSelectedData({
      isDetailModalOpen: false,
      isEditModalOpen: false,
      merchantId: "",
      mallId: "",
      mallName: "",
      businessName: "",
      ownerName: "",
      phone: "",
      createdAt: "",
      startDate: "",
      endDate: "",
      status: "전체"
    });
  };

  // ===================================================================================================================
  // 페이징 처리
  // ===================================================================================================================
  const totalPages = data?.pageable?.totalPages
    ? data?.pageable?.totalPages
    : 0;
  const changePage = (event: React.ChangeEvent<unknown>, page: number) => {
    setParams((prev) => ({ ...prev, page: page - 1 }));
  };

  return (
    <>
      <Title title="가맹 현황" />
      <ClientLayout>
        {/* 진행 안내 카드 */}
        <Typography variant="body1" sx={{ mb: 1, fontWeight: "bold" }}>
          가맹 심사 프로세스
        </Typography>
        <Paper
          sx={{
            mb: 3,
            p: 3,
            border: "1px solid #F2F3F5",
            borderRadius: 3
          }}
        >
          <Stack direction={"row"} justifyContent={"center"} gap={5}>
            <Box sx={{ width: "100%" }}>
              <Typography variant="subtitle2" sx={{ mb: 1, ml: 1 }}>
                1단계
              </Typography>
              <Stepper>
                {FirstSteps.map((step, index) => (
                  <Step key={step.id} completed={index < 6}>
                    <StepLabel
                      icon={
                        index === 2 ? (
                          <ReportProblemRoundedIcon
                            sx={{ color: "warning.main" }}
                          />
                        ) : (
                          ""
                        )
                      }
                    >
                      {step.label}
                    </StepLabel>
                  </Step>
                ))}
              </Stepper>
            </Box>
            <Divider orientation="vertical" flexItem />
            <Box sx={{ width: "100%" }}>
              <Typography variant="subtitle2" sx={{ mb: 1, ml: 1 }}>
                2단계
              </Typography>
              <Stepper>
                {SecondSteps.map((step, index) => (
                  <Step key={step.id} completed={index < 6}>
                    <StepLabel
                      icon={
                        index === 2 ? (
                          <ReportProblemRoundedIcon
                            sx={{ color: "warning.main" }}
                          />
                        ) : (
                          ""
                        )
                      }
                    >
                      {step.label}
                    </StepLabel>
                  </Step>
                ))}
              </Stepper>
            </Box>
          </Stack>
        </Paper>

        <Typography variant="body1" sx={{ mb: 1, fontWeight: "bold" }}>
          가맹 현황 목록
        </Typography>
        <Paper
          sx={{
            border: "1px solid #F2F3F5",
            borderRadius: 3,
            overflow: "hidden"
          }}
        >
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  {columns.map((column: any) => (
                    <TableCell
                      variant="head"
                      key={column.id}
                      align="center"
                      sx={{ backgroundColor: "#FBFBFB" }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {data && data.content.length > 0 ? (
                  <>
                    {data.content.map((row) => (
                      <TableRow
                        key={row.mallId}
                        onClick={() => selectRow(row)}
                        sx={{ cursor: "pointer" }}
                      >
                        <TableCell component="th" align="center">
                          {row.mallName}
                        </TableCell>
                        <TableCell align="center">{row.ownerName}</TableCell>
                        <TableCell align="center">
                        {row.businessName}
                        </TableCell>
                        <TableCell align="center">{row.phone}</TableCell>
                        <TableCell align="center">
                          {row.createdAt.split(" ").at(0)}
                        </TableCell>
                        {/* <TableCell align="center">{row.startDate}</TableCell>
                          <TableCell align="center">{row.endDate}</TableCell> */}
                        <TableCell align="center">{row.status}</TableCell>
                      </TableRow>
                    ))}

                    <ClientEditModal
                      isOpen={selectedData.isEditModalOpen}
                      closeModal={closeModal}
                      merchantId={selectedData.merchantId}
                    />

                    <ClientMerchantDetailModal
                      isOpen={selectedData.isDetailModalOpen}
                      closeModal={closeModal}
                      merchantId={selectedData.merchantId}
                    />
                  </>
                ) : (
                  <TableRow>
                    <TableCell colSpan={7}>
                      <Stack alignItems={"center"} sx={{ height: 480, pt: 30 }}>
                        <Typography>데이터가 없습니다.</Typography>
                        <Link href="/registrations" underline="none">
                          <Button>가맹 등록 하기</Button>
                        </Link>
                      </Stack>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <Box mt={2} mb={2}>
            <Pagination
              count={totalPages}
              variant="outlined"
              shape="rounded"
              page={params.page + 1}
              onChange={changePage}
              sx={{ display: "flex", justifyContent: "center" }}
            ></Pagination>
          </Box>
        </Paper>
      </ClientLayout>
    </>
  );
};
export default MerchantList;
