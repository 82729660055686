import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { useQuery } from "@tanstack/react-query";
import { useRef } from "react";
import { useReactToPrint } from "react-to-print";
import { useSetRecoilState } from "recoil";
import { getUserInfo } from "../api/Auth";
import { CommonApi } from "../api/type";
import styles from "../components/Contract/Fonts.module.css";
import Page1 from "../components/Contract/Page1";
import Page2 from "../components/Contract/Page2";
import Page3 from "../components/Contract/Page3";
import Page4 from "../components/Contract/Page4";
import Page5 from "../components/Contract/Page5";
import Page6 from "../components/Contract/Page6";
import Page7 from "../components/Contract/Page7";
import Page8 from "../components/Contract/Page8";
import Page9 from "../components/Contract/Page9";
import isModalOpen from "../recoil/isModalOpen/atom";
import { UserInformation } from "./Client/MerchantRegistration";

const PreviewPage = (data: any) => {
  // ===========================================================================
  // 리코일 스테이트
  // ===========================================================================
  const setIsModalOpen = useSetRecoilState(isModalOpen);

  // ===========================================================================
  // 회원의 role 조회
  // ===========================================================================
  const { data: usersRole } = useQuery<CommonApi<UserInformation>>(
    ["/users/information"],
    () => getUserInfo()
  );

  // 계약서 다운로드
  const componentRef = useRef<HTMLDivElement>(null);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "오직페이 솔루션 가맹 등록 계약서",
    // onAfterPrint: () => {
    //   setIsModalOpen({
    //     value: true,
    //     position: "top",
    //     alertSeverity: "success",
    //     message: "계약서가 저장되었습니다."
    //   });
    // },
    onPrintError: () =>
      setIsModalOpen({
        value: true,
        position: "top",
        alertSeverity: "error",
        message: "계약서 저장에 실패했습니다."
      })
  });

  return (
    <>
      <Stack sx={{ flex: 1 }}>
        <Button
          variant={
            usersRole?.content.role === "CLIENT" ? "contained" : "outlined"
          }
          sx={{
            color: usersRole?.content.role === "CLIENT" ? "white" : ""
          }}
          onClick={handlePrint}
        >
          {usersRole?.content.role === "CLIENT"
            ? "계약서 다운로드"
            : "계약서 미리보기"}
        </Button>
      </Stack>
      <div style={{ display: "none" }}>
        <div className={styles.container} ref={componentRef}>
          <Page1 data={data.data.content} />
          <Page2 data={data.data.content} />
          <Page3 data={data.data.content} />
          <Page4 data={data.data.content} />
          <Page5 data={data.data.content} />
          <Page6 data={data.data.content} />
          <Page7 data={data.data.content} />
          <Page8 data={data.data.content} />
          <Page9 />
        </div>
      </div>
    </>
  );
};

export default PreviewPage;
