import CircleIcon from "@mui/icons-material/Circle";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import TodayIcon from "@mui/icons-material/Today";
import { Button, Grid, Stack, Typography } from "@mui/material";
import TextField from "@mui/material/TextField";
import { blue, indigo, orange, red, teal } from "@mui/material/colors";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { addMonths, format, subMonths } from "date-fns";
import koLocale from "date-fns/locale/ko";

interface Props {
  date: Date;
  setDate: (date: Date) => void;
}

const CalendarHeader = ({ date, setDate }: Props) => {
  const handleDate = (type: "Sub" | "Add" | "Current") => {
    let newDate;
    if (type === "Sub") {
      newDate = subMonths(date, 1);
    } else if (type === "Add") {
      newDate = addMonths(date, 1);
    } else {
      newDate = new Date();
    }
    setDate(newDate);
  };
  const yyyyMM = format(date, "yyyy-MM");
  return (
    <Grid
      container
      columns={{ xs: 7, sm: 7, md: 7 }}
      sx={{
        p: 2
      }}
    >
      <Grid item xs={7} sm={7} md={1} pr={3}>
        <LocalizationProvider
          adapterLocale={koLocale}
          dateAdapter={AdapterDateFns}
        >
          <DatePicker
            inputFormat="yyyy-MM"
            views={["year", "month"]}
            value={date}
            onChange={(newDate) => setDate(newDate!)}
            renderInput={(param) => (
              <TextField
                fullWidth
                size="small"
                required
                color="primary"
                {...param}
                variant="standard"
                inputProps={{ style: { fontSize: 30 } }}
                disabled={true}
                value={yyyyMM}
              />
            )}
          />
        </LocalizationProvider>
      </Grid>
      <Grid item xs={7} sm={7} md={4}>
        <Stack
          direction={"row"}
          alignItems="flex-end"
          spacing={2}
          sx={{ height: "100%", minWidth: 400 }}
        >
          <Stack>
            <Stack direction={"row"} spacing={3}>
              <Typography>
                <CircleIcon
                  sx={{
                    width: 16,
                    height: 16,
                    color: blue[500],
                    marginRight: 1
                  }}
                />
                카드사 입금 예정 금액
              </Typography>
              <Typography>
                <CircleIcon
                  sx={{
                    width: 16,
                    height: 16,
                    color: orange[500],
                    marginRight: 1
                  }}
                />
                정산 예정 금액
              </Typography>
     
              <Typography>
                <CircleIcon
                  sx={{
                    width: 16,
                    height: 16,
                    color: red[500],
                    marginRight: 1
                  }}
                />
                정산 금액
              </Typography>
              <Typography>
                <CircleIcon
                  sx={{
                    width: 16,
                    height: 16,
                    color: teal[500],
                    marginRight: 1
                  }}
                />
                정산대기 금액
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      </Grid>
      <Grid item xs={7} sm={7} md={2}>
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <NavigateBeforeIcon
            sx={{
              fontSize: 30,
              color: indigo[700],
              "&:hover": { color: blue[700], cursor: "pointer" }
            }}
            onClick={() => handleDate("Sub")}
          />
          <Button
            variant="text"
            startIcon={<TodayIcon />}
            onClick={() => handleDate("Current")}
            sx={{
              "&:hover": { color: blue[700], cursor: "pointer" }
            }}
          >
            이번 달
          </Button>
          <NavigateNextIcon
            sx={{
              fontSize: 30,
              color: indigo[700],
              "&:hover": { color: blue[700], cursor: "pointer" }
            }}
            onClick={() => handleDate("Add")}
          />
        </Stack>
      </Grid>
    </Grid>
  );
};

export default CalendarHeader;
