import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useSetRecoilState } from "recoil";
import { getBusinessCategories } from "../../api/BusinessCategory";
import {
  getCardCompanies,
  getCardCompanyByName,
  getCardCompanyByNameAndType
} from "../../api/CardInstallments";
import CardCompanyModal from "../../components/CardCompanyModal";
import CardInstallmentModal from "../../components/CardInstallmentModal";
import CardInstallmentReservationModal from "../../components/CardInstallmentReservationModal";
import EditModal from "../../components/EditModal";
import AdminLayout from "../../components/Layout/Admin/Layout";
import ModalTriggerButton from "../../components/PopupTriggerButton";
import Title from "../../components/Title";
import useRenewal from "../../hooks/useRenewal";
import { addCommas } from "../../libs/thousands-commas";
import isModalOpenAtom from "../../recoil/isModalOpen";
import Box from "@mui/material/Box";

// 테이블 컬럼
interface Columns {
  id: string;
  label: string;
}

const columns: Columns[] = [
  { id: "mccType", label: "업종" },
  { id: "grade", label: "가입 등급" },
  { id: "isUse", label: "사용 여부" },
  { id: "optatumRegistrationNumber", label: "카드사 등록번호" },
  { id: "deposit", label: "보증금액 (원)" },
  { id: "depositType", label: "보증 타입" },
  { id: "modifyCard", label: "카드 수정" }
];

const CardInstallments = () => {
  const { isRenewalTokenLoading, mutateRenewalToken } = useRenewal();

  // ===================================================================================================================
  // 리코일 스테이트
  // ===================================================================================================================
  const setIsModalOpen = useSetRecoilState(isModalOpenAtom);

  // ===================================================================================================================
  // 스테이트
  // ===================================================================================================================
  // 선택된 카드사 스테이트
  const [selectedCardCompany, setSelectedCardCompany] = useState("");

  // 선택된 업종 스테이트
  const [selectedMccType, setSelectedMccType] = useState("");

  // ===================================================================================================================
  // 리액트 훅 폼
  // ===================================================================================================================
  // 카드사 선택 검색 필터 폼
  const { control } = useForm({
    defaultValues: {
      cardName: ""
    }
  });

  // 업종 선택 검색 필터 폼
  const { control: mccTypeControl } = useForm({
    defaultValues: {
      mccType: ""
    }
  });

  // ===================================================================================================================
  // 리액트 쿼리
  // ===================================================================================================================
  // 카드사 목록 조회
  const { data, isLoading } = useQuery(
    ["/cardCompanies"],
    () => getCardCompanies(),
    {
      staleTime: Infinity,
      cacheTime: Infinity
    }
  );

  // 카드사 정보 조회
  const { data: cardCompanyData } = useQuery(
    ["/card-companies", selectedCardCompany],
    () => getCardCompanyByName(selectedCardCompany),
    {
      onSuccess: () => {
        if (!isRenewalTokenLoading) {
          mutateRenewalToken();
        }
      },
      // selectedCardCompany가 존재할 때만 쿼리 실행
      enabled: !!selectedCardCompany,
      onError: (error: any) => {
        setIsModalOpen({
          value: true,
          position: "top",
          alertSeverity: "error",
          message: error?.response?.data?.message
        });
      }
    }
  );

  const params = {
    cardName: selectedCardCompany,
    mccType: selectedMccType
  };
  // 업종 선택 조회
  const { data: mccTypeData, refetch: mccTypeRefetch } = useQuery(
    ["/card-companies/grades", params],
    () => getCardCompanyByNameAndType(params),
    {
      // selectedMccType가 존재할 때만 쿼리 실행
      enabled: !!selectedMccType,
      onError: (error: any) => {
        setIsModalOpen({
          value: true,
          position: "top",
          alertSeverity: "error",
          message: error?.response?.data?.message
        });
      }
    }
  );

  /*********************************************************************************************************************
   * 업종 조회
   ********************************************************************************************************************/
  const { data: businessCategories } = useQuery(
    ["/businessCategories"],
    getBusinessCategories,
    {}
  );

  return (
    <>
      <Title title="카드사 관리" />
      <AdminLayout>
        <Stack spacing={3}>
          {/* 카드사 조회 ------------------------------------------------------------------------------------------ */}
          <Paper
            sx={{
              p: 3,
              border: "1px solid #F2F3F5",
              borderRadius: 3,
              overflow: "hidden"
            }}
          >
            <form>
              <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                  <Stack direction={"row"} gap={3} alignItems={"center"}>
                    <Typography sx={{ fontWeight: "bold", width: 150 }}>
                      카드사 선택
                    </Typography>
                    <Controller
                      name="cardName"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <TextField
                          id="input-card-name"
                          label="카드사"
                          required
                          size="small"
                          select
                          value={value}
                          onChange={(e) => {
                            onChange(e); // react-hook-form 업데이트
                            setSelectedCardCompany(e.target.value); // 카드사 선택 시 바로 상태 업데이트
                          }}
                          sx={{ width: 250 }}
                        >
                          <MenuItem value=""></MenuItem>
                          {data?.content?.cardCompanies.map((c: string) => (
                            <MenuItem key={c} value={c}>
                              {c}
                            </MenuItem>
                          ))}
                        </TextField>
                      )}
                    />
                  </Stack>
                </Grid>
              </Grid>
            </form>
          </Paper>

          {/* 업종 조회 -------------------------------------------------------------------------------------------- */}
          {cardCompanyData && (
            <Paper
              sx={{
                p: 3,
                border: "1px solid #F2F3F5",
                borderRadius: 3,
                overflow: "hidden"
              }}
            >
              <form>
                <Stack direction={"row"} gap={3} alignItems={"center"}>
                  <Typography sx={{ fontWeight: "bold", width: 150 }}>
                    {cardCompanyData?.content.name} 업종 선택
                  </Typography>
                  <Controller
                    name="mccType"
                    control={mccTypeControl}
                    render={({ field: { onChange, value } }) => (
                      <TextField
                        id="input-mccType"
                        label="업종"
                        sx={{ width: 250 }}
                        required
                        size="small"
                        select
                        value={value}
                        onChange={(e) => {
                          onChange(e); // react-hook-form 업데이트
                          setSelectedMccType(e.target.value); // 업종 선택 시 바로 상태 업데이트
                        }}
                      >
                        {businessCategories?.content.map((category) => (
                          <MenuItem value={category} key={category}>
                            {category}
                          </MenuItem>
                        ))}
                      </TextField>
                    )}
                  />
                  <Typography>
                    카드사 사업자 등록번호:{" "}
                    {cardCompanyData.content.registrationNumber}
                  </Typography>
                  {mccTypeData && mccTypeData.content.length > 0 && (
                    <Box sx={{ ml: "auto" }}>
                      <ModalTriggerButton
                        size="medium"
                        sx={{ mr: 2 }}
                        modal={
                          <CardInstallmentModal
                            cardCompanyName={selectedCardCompany}
                            mccType={selectedMccType}
                          />
                        }
                      >
                        할부 정보
                      </ModalTriggerButton>

                      <ModalTriggerButton
                        size="medium"
                        modal={
                          <CardInstallmentReservationModal
                            cardCompanyName={selectedCardCompany}
                            mccType={selectedMccType}
                          />
                        }
                      >
                        할부 예약 정보
                      </ModalTriggerButton>
                    </Box>
                  )}
                </Stack>
              </form>
            </Paper>
          )}

          {/* 카드사 관리 테이블 ------------------------------------------------------------------------------------ */}
          <Box>
            {mccTypeData && (
              <>
                <Typography sx={{ fontWeight: "bold", mb: 1 }}>
                  등급별 정보
                </Typography>
                <Paper
                  sx={{
                    border: "1px solid #F2F3F5",
                    borderRadius: 3,
                    overflow: "hidden"
                  }}
                >
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          {columns.map((column) => (
                            <TableCell
                              variant="head"
                              align="center"
                              key={column.id}
                              sx={{ borderTop: "none", bgcolor: "#FBFBFB" }}
                            >
                              {column.label}
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {/* 데이터 조회 중 - 스켈레톤 */}
                        {isLoading &&
                          [...Array(10)].map((_, index) => (
                            <TableRow key={index}>
                              {columns.map((column) => (
                                <TableCell align="center" key={column.id}>
                                  <Skeleton variant="rectangular" height={25} />
                                </TableCell>
                              ))}
                            </TableRow>
                          ))}

                        {/* 데이터 조회 결과 */}
                        {mccTypeData && mccTypeData.content.length > 0
                          ? mccTypeData.content.map(
                              (paymentData: any, index: number) => (
                                <TableRow key={index}>
                                  {columns.map((column) => (
                                    <TableCell
                                      key={column.id}
                                      align="center"
                                      sx={{
                                        py: 1.5,
                                        borderBottom:
                                          index ===
                                          mccTypeData.content.length - 1
                                            ? "none"
                                            : ""
                                      }}
                                    >
                                      {column.id === "modifyCard" ? (
                                        <ModalTriggerButton
                                          size="medium"
                                          modal={
                                            <EditModal
                                              cardCompanyName={
                                                selectedCardCompany
                                              }
                                              onButtonSubmit={mccTypeRefetch}
                                              mccType={selectedMccType}
                                              grade={paymentData.grade}
                                            />
                                          }
                                        >
                                          수정하기
                                        </ModalTriggerButton>
                                      ) : column.id === "isUse" ? (
                                        paymentData.isUse ? (
                                          <Typography>사용</Typography>
                                        ) : (
                                          <Typography>미사용</Typography>
                                        )
                                      ) : column.id === "deposit" ? (
                                        addCommas(paymentData.deposit)
                                      ) : (
                                        paymentData[column.id]
                                      )}
                                    </TableCell>
                                  ))}
                                </TableRow>
                              )
                            )
                          : !isLoading && (
                              <TableRow>
                                <TableCell
                                  sx={{ height: 400, textAlign: "center" }}
                                  colSpan={columns.length}
                                >
                                  <Typography mb={3}>
                                    선택한 업종으로 등록된 등급별 정보가
                                    존재하지 않습니다. 업종을 추가해 주세요.
                                  </Typography>
                                  <ModalTriggerButton
                                    size="medium"
                                    modal={
                                      <CardCompanyModal
                                        onButtonSubmit={mccTypeRefetch}
                                        cardCompanyName={selectedCardCompany}
                                        mccType={selectedMccType}
                                      />
                                    }
                                  >
                                    업종 추가하기
                                  </ModalTriggerButton>
                                </TableCell>
                              </TableRow>
                            )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Paper>
              </>
            )}
          </Box>
        </Stack>
      </AdminLayout>
    </>
  );
};

export default CardInstallments;
