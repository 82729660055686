import { Grid, Paper, Skeleton, Typography } from "@mui/material";
import { addCommas } from "../../libs/thousands-commas";
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
import SavingsIcon from "@mui/icons-material/Savings";
import { blue, grey, teal } from "@mui/material/colors";
import { getTodayStatistics } from "../../api/Dashboard";
import { useQuery } from "@tanstack/react-query";
import { format } from "date-fns";

interface TodayStatistics {
  totalSalesOfMonth: number; // 이번 달 매출 금액
  expectedDepositAmount: number; // 입금 예정 금액
  todayDepositAmount: number; // 오늘 입금된 금액
}

interface SummaryProps {
  currentMonth: Date;
}
const Summary = ({ currentMonth }: SummaryProps) => {
  // 통계 조회 API
  const callAPI = (): Promise<TodayStatistics> =>
    getTodayStatistics(currentMonth);
  const { data, isLoading } = useQuery(
    ["/dashboard/todayStatistics", currentMonth],
    callAPI,
    {
      keepPreviousData: true
    }
  );

  return (
    <Paper
      elevation={0}
      sx={{
        px: 3,
        py: 2,
        border: "1px solid #F2F3F5",
        borderRadius: 3
      }}
    >
      <Grid container sx={{ minHeight: 110 }} columnSpacing={{ xs: 1, sm: 2 }}>
        <Grid item xs={12} sm={4} container justifyContent={"center"}>
          <Grid item xs={10} sm={10} md={8} container alignItems={"center"}>
            <Grid item xs={6} sm={12} md={12}>
              <Typography sx={{ color: grey[500] }}>
                {format(currentMonth, "MM")}월 매출 금액
              </Typography>
            </Grid>
            <Grid
              item
              xs={6}
              sm={12}
              md={12}
              container
              justifyContent={"flex-end"}
            >
              {isLoading ? (
                <Skeleton
                  variant="text"
                  animation="wave"
                  width={120}
                  sx={{ fontSize: "1rem" }}
                />
              ) : (
                <Typography variant={"h5"} sx={{ color: blue[500], pr: 1 }}>
                  {addCommas(
                    data?.totalSalesOfMonth ? data.totalSalesOfMonth : 0
                  )}
                  원
                </Typography>
              )}
            </Grid>
          </Grid>
          <Grid item xs={2} sm={2} md={4} container alignItems={"center"}>
            <Grid item>
              <PointOfSaleIcon
                sx={{
                  color: blue[500],
                  width: "35%",
                  height: "35%",
                  ml: { xs: 4, sm: 1, md: 3, lg: 8 }
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          sx={{ borderLeft: "1px solid #c7c7c7" }}
          xs={12}
          sm={4}
          container
          justifyContent={"center"}
        >
          <Grid item xs={10} sm={10} md={8} container alignItems={"center"}>
            <Grid item xs={6} sm={12} md={12}>
              <Typography sx={{ color: grey[500] }}>입금 예정 금액</Typography>
            </Grid>
            <Grid
              item
              xs={6}
              sm={12}
              md={12}
              container
              justifyContent={"flex-end"}
            >
              {isLoading ? (
                <Skeleton
                  variant="text"
                  animation="wave"
                  width={120}
                  sx={{ fontSize: "1rem" }}
                />
              ) : (
                <Typography variant={"h5"} sx={{ color: grey[500], pr: 1 }}>
                  {addCommas(
                    data?.expectedDepositAmount ? data.expectedDepositAmount : 0
                  )}
                  원
                </Typography>
              )}
            </Grid>
          </Grid>
          <Grid item xs={2} sm={2} md={4} container alignItems={"center"}>
            <Grid item>
              <SavingsIcon
                sx={{
                  color: grey[500],
                  width: "35%",
                  height: "35%",
                  ml: { xs: 4, sm: 1, md: 3, lg: 8 }
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          sx={{ borderLeft: "1px solid #c7c7c7" }}
          xs={12}
          sm={4}
          container
          justifyContent={"center"}
        >
          <Grid item xs={10} sm={10} md={8} container alignItems={"center"}>
            <Grid item xs={6} sm={12} md={12}>
              <Typography sx={{ color: grey[500] }}>
                오늘 입금된 금액
              </Typography>
            </Grid>
            <Grid
              item
              xs={6}
              sm={12}
              md={12}
              container
              justifyContent={"flex-end"}
            >
              {isLoading ? (
                <Skeleton
                  variant="text"
                  animation="wave"
                  width={120}
                  sx={{ fontSize: "1rem" }}
                />
              ) : (
                <Typography variant={"h5"} sx={{ color: teal[500], pr: 1 }}>
                  {addCommas(
                    data?.todayDepositAmount ? data.todayDepositAmount : 0
                  )}
                  원
                </Typography>
              )}
            </Grid>
          </Grid>
          <Grid item xs={2} sm={2} md={4} container alignItems={"center"}>
            <Grid item>
              <SavingsIcon
                sx={{
                  color: teal[500],
                  width: "35%",
                  height: "35%",
                  ml: { xs: 4, sm: 1, md: 3, lg: 8 }
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default Summary;
