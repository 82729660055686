import RestartAltIcon from "@mui/icons-material/RestartAlt";
import SearchIcon from "@mui/icons-material/Search";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Pagination from "@mui/material/Pagination";
import Paper from "@mui/material/Paper";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { useQuery } from "@tanstack/react-query";
import { sub } from "date-fns";
import koLocale from "date-fns/locale/ko";
import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useSetRecoilState } from "recoil";
import { getNotificationsForAdmin } from "../../api/Notification";
import type {
  NotificationForAdmin,
  NotificationParams
} from "../../api/Notification/type";
import AdminLayout from "../../components/Layout/Admin/Layout";
import LoadingButton from "../../components/LoadingButton";
import NotificationSendModal from "../../components/Notification/NotificationSendModal";
import ModalTriggerButton from "../../components/PopupTriggerButton";
import Title from "../../components/Title";
import useRenewal from "../../hooks/useRenewal";
import { getDate } from "../../libs/get-date";
import { getToday } from "../../libs/get-today";
import {
  cellStyle,
  getWriterInfo,
  translateCellValue
} from "../../libs/notifications";
import isModalOpenAtom from "../../recoil/isModalOpen";

interface Columns {
  id: string;
  label: string;
}
const columns: Columns[] = [
  { id: "type", label: "알림 타입" },
  { id: "priority", label: "중요도" },
  { id: "message", label: "메세지" },
  { id: "writer", label: "작성자" },
  { id: "createdAt", label: "생성 일자" }
];

const filter = {
  type: [
    { label: "전체", value: "ALL" },
    { label: "공지", value: "NOTICE" },
    { label: "시스템", value: "SYSTEM" },
    { label: "정산", value: "SETTLEMENT" }
  ],
  priority: [
    { label: "전체", value: "ALL" },
    { label: "일반", value: "GENERAL" },
    { label: "중요", value: "IMPORTANT" },
    { label: "긴급", value: "URGENT" }
  ]
};

type NotificationForm = Omit<NotificationParams, "page" | "size" | "sort">;

interface Row extends NotificationForAdmin {
  isModalOpen: boolean;
}

const Notifications = () => {
  const { isRenewalTokenLoading, mutateRenewalToken } = useRenewal();
  // ==================================================================================================================
  // 리코일 스테이트
  // ==================================================================================================================
  const setIsModalOpen = useSetRecoilState(isModalOpenAtom);

  // ==================================================================================================================
  // 리액트 훅 폼
  // ==================================================================================================================
  const defaultFormValues = {
    startDate: getDate(sub(new Date(), { days: 7 })),
    endDate: getToday(),
    type: "ALL",
    priority: "ALL",
    message: ""
  } as const;

  const { register, handleSubmit, control, reset } = useForm<NotificationForm>({
    defaultValues: {
      ...defaultFormValues
    }
  });

  const handleReset = () => {
    reset(defaultFormValues);
  };
  // ==================================================================================================================
  // 컴포넌트 스테이트
  // ==================================================================================================================
  const [params, setParams] = useState<NotificationParams>({
    page: 0,
    size: 10,
    startDate: defaultFormValues.startDate,
    endDate: defaultFormValues.endDate,
    type: defaultFormValues.type,
    priority: defaultFormValues.priority,
    message: defaultFormValues.message,
    sort: "createdAt,DESC"
  });

  const [selectedRowData, setSelectedRowData] = useState<Row>({
    isModalOpen: false,
    id: 0,
    priority: "GENERAL",
    type: "NOTICE",
    message: "",
    writerId: 0,
    writerEmail: "",
    writerName: "",
    receiverInformationList: [],
    createdAt: "",
    updatedAt: ""
  });

  // ==================================================================================================================
  // 리액트 쿼리 - 알림 내역 조회
  // ==================================================================================================================
  const { data, refetch, isLoading } = useQuery(
    ["/notification-messages", params],
    () => getNotificationsForAdmin(params),
    {
      select: (response) => {
        return {
          ...response,
          content: response?.content?.map((row) => {
            return {
              ...row,
              writer: getWriterInfo(row.writerName, row.writerEmail)
            };
          })
        };
      },
      onError: (error: any) => {
        setIsModalOpen({
          value: true,
          position: "top",
          alertSeverity: "error",
          message: error?.response?.data?.message
        });
      },
      onSuccess: () => {
        if (isRenewalTokenLoading) return;
        mutateRenewalToken();
      }
    }
  );

  // ==================================================================================================================
  // 페이징 처리
  // ==================================================================================================================
  const totalPages = data?.pageable?.totalPages
    ? data?.pageable?.totalPages
    : 0;
  const changePage = (event: React.ChangeEvent<unknown>, page: number) => {
    setParams((prev) => ({ ...prev, page: page - 1 }));
  };

  // ==================================================================================================================
  // 화면 갱신 함수
  // ==================================================================================================================
  const refresh = () => {
    refetch();
  };

  // ==================================================================================================================
  // 알림 수정
  // ==================================================================================================================
  const selectRow = (row: any) => {
    setSelectedRowData({ ...row, isModalOpen: true });
  };
  // 모달창 닫기
  const closeUpdateModal = () => {
    setSelectedRowData({
      isModalOpen: false,
      id: 0,
      priority: "GENERAL",
      type: "NOTICE",
      message: "",
      writerId: 0,
      writerEmail: "",
      writerName: "",
      receiverInformationList: [],
      createdAt: "",
      updatedAt: ""
    });
  };

  // ==================================================================================================================
  // 검색 필터 조회
  // ==================================================================================================================
  const handleForm = (data: NotificationForm) => {
    // 날짜 검증 1 : 날짜 값이 있는지 확인
    const isInvalidData =
      data.startDate?.includes("NaN") || data.endDate?.includes("NaN");

    // 날짜 검증 2 : 날짜 앞뒤가 맞는지 확인
    const isInvaildPeriod = data.startDate > data.endDate;

    // 날짜 검증 실패시 에러 모달
    if (isInvalidData || isInvaildPeriod) {
      setIsModalOpen({
        value: true,
        position: "top",
        alertSeverity: "error",
        message: "알림 일자를 확인해주세요."
      });
      return;
    }

    if (isLoading) return;

    setParams((prev: any) => ({
      ...prev,
      ...data,
      page: 0
    }));
  };

  return (
    <>
      <Title title="알림 내역" />
      <AdminLayout>
        <Paper
          sx={{
            p: 3,
            border: "1px solid #F2F3F5",
            borderRadius: 3,
            overflow: "hidden",
            mb: 3
          }}
        >
          <form onSubmit={handleSubmit(handleForm)}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <LocalizationProvider
                    adapterLocale={koLocale}
                    dateAdapter={AdapterDateFns}
                  >
                    <Grid item xs={12} md={2}>
                      <Controller
                        name="startDate"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <DatePicker
                            label="시작 날짜"
                            inputFormat="yyyy-MM-dd"
                            value={value}
                            onChange={(value) => onChange(getDate(value))}
                            renderInput={(param) => (
                              <TextField
                                fullWidth
                                size="small"
                                required
                                {...param}
                              />
                            )}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} md={2}>
                      <Controller
                        name="endDate"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <DatePicker
                            label="종료 날짜"
                            inputFormat="yyyy-MM-dd"
                            value={value}
                            onChange={(value) => onChange(getDate(value))}
                            renderInput={(param) => (
                              <TextField
                                fullWidth
                                size="small"
                                required
                                {...param}
                              />
                            )}
                          />
                        )}
                      />
                    </Grid>
                  </LocalizationProvider>
                  <Grid item xs={12} md={1.5}>
                    <Controller
                      name="type"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <TextField
                          id="input-noti-type"
                          label="종류"
                          required
                          fullWidth
                          size="small"
                          select
                          value={value}
                          onChange={onChange}
                        >
                          {filter.type.map((t: any) => (
                            <MenuItem key={t.value} value={t.value}>
                              {t.label}
                            </MenuItem>
                          ))}
                        </TextField>
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={1.5}>
                    <Controller
                      name="priority"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <TextField
                          id="input-noti-priority"
                          label="중요도"
                          required
                          fullWidth
                          size="small"
                          select
                          value={value}
                          onChange={onChange}
                        >
                          {filter.priority.map((p: any) => (
                            <MenuItem key={p.value} value={p.value}>
                              {p.label}
                            </MenuItem>
                          ))}
                        </TextField>
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <TextField
                      id="input-message"
                      label="메시지 내용"
                      fullWidth
                      size="small"
                      {...register("message")}
                    />
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <Stack
                      direction="row"
                      justifyContent="flex-end"
                      alignItems="center"
                      spacing={1}
                    >
                      <LoadingButton
                        icon={<SearchIcon />}
                        size="medium"
                        fullWidth={false}
                        loading={isLoading}
                      >
                        조회
                      </LoadingButton>
                      <Button
                        variant="outlined"
                        endIcon={<RestartAltIcon />}
                        onClick={handleReset}
                      >
                        조건 초기화
                      </Button>
                    </Stack>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </Paper>

        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems={"end"}
          mb={1}
        >
          <Typography sx={{ fontWeight: "bold" }}>알림 내역</Typography>
          <ModalTriggerButton
            color={"primary"}
            modal={<NotificationSendModal refreshProps={refresh} />}
            size="medium"
          >
            새 알림 보내기
          </ModalTriggerButton>
        </Stack>
        <Paper
          sx={{
            border: "1px solid #F2F3F5",
            borderRadius: 3,
            overflow: "hidden"
          }}
        >
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  {columns.map((column: any) => (
                    <TableCell
                      variant="head"
                      key={column.id}
                      sx={{
                        whiteSpace: "nowrap",
                        backgroundColor: "#FBFBFB"
                      }}
                      align="center"
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {/* 데이터가 없고 로딩 중일 때 Skeleton을 표시 */}
                {!data &&
                  isLoading &&
                  [1, 2, 3, 4, 5].map((index) => (
                    <TableRow key={index}>
                      {[
                        { id: "type" },
                        { id: "priority" },
                        { id: "message" },
                        { id: "writerEmail" },
                        { id: "createdAt" },
                        { id: "updatedAt" }
                      ].map((value) => (
                        <TableCell
                          align="center"
                          sx={cellStyle(value.id, "")}
                          key={`${index}-${value.id}`}
                        >
                          <Skeleton
                            variant={"rectangular"}
                            width={"100%"}
                            height={30}
                          />
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}

                {/* 데이터가 있을 때 표시 */}
                {data && data.content.length > 0 ? (
                  data.content.map((row: any) => (
                    <TableRow
                      key={`Row_${row["id"]}`}
                      onClick={() => selectRow(row)}
                      hover={true}
                    >
                      {columns.map((column: any) => {
                        const cellValue = row[column.id];
                        return (
                          <TableCell
                            key={`Row_${row["id"]}_${column.id}`}
                            align="center"
                            sx={cellStyle(column.id, cellValue)}
                          >
                            {translateCellValue(column.id, cellValue)}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell sx={{ height: 500 }} colSpan={columns.length}>
                      <Typography align="center">
                        조회된 데이터가 없습니다.
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>

          <Box my={2}>
            <Pagination
              count={totalPages}
              variant="outlined"
              shape="rounded"
              page={params.page + 1}
              onChange={changePage}
              sx={{ display: "flex", justifyContent: "center" }}
            />
          </Box>
        </Paper>
      </AdminLayout>
    </>
  );
};

export default Notifications;
