import React, { useState } from "react";
import Button from "@mui/material/Button";

interface Props {
  modal: React.ReactElement;
  disabled?: boolean;
  children: React.ReactNode;
  isValidToTrigger?: () => boolean;
  size?: "small" | "medium" | "large" | undefined;
  color?:
    | "inherit"
    | "primary"
    | "secondary"
    | "success"
    | "error"
    | "info"
    | "warning"
    | undefined;
  modalType?: "default" | "charge";
  sx?: {};
}

const ModalTriggerButton = ({
  modal,
  disabled = false,
  children,
  isValidToTrigger = () => true,
  size = "large",
  color = "success",
  modalType = "default",
  sx = {}
}: Props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = () => {
    if (isValidToTrigger()) {
      setIsModalOpen(true);
    }
  };
  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Button
        disabled={disabled}
        onClick={openModal}
        size={size}
        color={color}
        sx={sx}
        variant={"outlined"}
      >
        {children}
      </Button>
      {isModalOpen &&
        modalType === "default" &&
        React.cloneElement(modal, { closeModal })}
      {isModalOpen &&
        modalType === "charge" &&
        React.cloneElement(modal, {
          isModalOpen,
          closeModal
        })}
    </>
  );
};

export default ModalTriggerButton;
