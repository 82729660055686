import { instanceWithAuth, instance } from "../Instance";

interface UpdatePasswordParams {
  encryptedPassword: string;
  publicKey: string;
}

export const getRSA = async () => {
  const { data } = await instance.get("/bypass/rsa/public-key");
  return data;
};

export const updatePassword = async (
  updatePasswordParams: UpdatePasswordParams
) => {
  const { data } = await instanceWithAuth.post(
    "/users/password/update",
    updatePasswordParams
  );
  return data;
};
