import * as XLSX from "xlsx";

interface ExcelDownload {
  thead: Array<any>;
  merchantTotalList: any;
  merchantsList: any;
  sheetName: string;
  fileName: string;
}

const NewExcelDownload = ({
  thead,
  merchantTotalList,
  merchantsList,
  sheetName,
  fileName
}: ExcelDownload) => {
  const sheetData = [
    thead,
    Object.values(merchantTotalList),
    [],
    thead,
    ...merchantsList.map(Object.values)
  ];

  const aoaToSheetData = XLSX.utils.aoa_to_sheet(sheetData);
  const workBook = XLSX.utils.book_new();
  // 엑셀에서 워크북을 하나 형성합니다.
  XLSX.utils.book_append_sheet(workBook, aoaToSheetData, sheetName);
  // 생성한 워크북에 데이터를 넣고 sheetName을 지정합니다.
  XLSX.writeFile(workBook, fileName);
  // 해당 워크북에 지정한 파일이름으로 파일을 생성합니다.
};

export default NewExcelDownload;
