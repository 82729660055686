import * as XLSX from "xlsx";

interface ExcelDownload {
  list: Array<any>;
  sheetName: string;
  fileName: string;
}

const excelDownload = ({ list, sheetName, fileName }: ExcelDownload) => {
  const jsonToSheetData = XLSX.utils.json_to_sheet(list);
  const workBook = XLSX.utils.book_new();
  // 엑셀에서 워크북을 하나 형성한다
  XLSX.utils.book_append_sheet(workBook, jsonToSheetData, sheetName);
  // 생성한 워크북에 데이터를 넣고 sheetName을 지정한다
  XLSX.writeFile(workBook, fileName);
  // 해당 워크북에 지정한 파일이름으로 파일을 생성한다
};

export default excelDownload;



