import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import LogoImage from "../../images/logo_contract.png";
import { grey } from "@mui/material/colors";
import Divider from "@mui/material/Divider";
import Fonts from "./Fonts";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import { MerchantsDetail } from "../../api/Merchants/types";

interface PageProps {
  data?: MerchantsDetail;
}

const Page5 = ({ data }: PageProps) => {
  return (
    <Fonts>
      <Box
        sx={{
          width: "210mm",
          height: "297mm",
          backgroundColor: "white",
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <Card
          sx={{
            width: "195mm",
            height: "282mm",
            backgroundColor: "white",
            border: "1px solid black"
          }}
        >
          {/* 머릿글 ======================================================== */}
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            sx={{
              width: "100%",
              height: "15mm"
            }}
          >
            <Stack
              justifyContent={"center"}
              alignItems={"center"}
              sx={{
                width: "120px"
              }}
            >
              {/* <Box component={"img"} src={LogoImage}></Box> */}
            </Stack>
            <Stack
              justifyContent={"center"}
              alignItems={"center"}
              sx={{ fontSize: "18px", fontWeight: 700, color: "#a29fb0" }}
            >
              [부칙 1] 개인정보 수집 및 이용동의서
            </Stack>
            <Stack
              justifyContent={"center"}
              alignItems={"center"}
              sx={{
                width: "35mm",
                color: "white",
                fontSize: "16px"
              }}
            >
              <Box component={"img"} src={LogoImage}></Box>
            </Stack>
          </Stack>
          <Divider sx={{ border: "2px solid #a29fb0", mx: 1 }}></Divider>

          {/* 본문 ========================================================== */}
          <Box
            component={"ol"}
            start={3}
            sx={{
              height: "252mm"
            }}
          >
            <Box component={"li"} sx={{ fontSize: "7pt", ml: -2, mr: 3 }}>
              <Typography sx={{ fontWeight: 700, mt: 1 }}>
                특정 금융거래정보의 보고 및 이용 등에 관한 법률에 따른 개인정보
                수집∙이용 내역
              </Typography>
              <Typography>
                「특정 금융거래정보의 보고 및 이용 등에 관한 법률」
                제5조의2(고객확인의무) 및 시행령 제10조의5(실소유자 확인)에 따라
                실소유자 정보 작성 및 자료 제출에 대한 본인확인 용도로만
                활용됩니다.
              </Typography>
              <Table sx={{ mt: 1, height: "20mm" }}>
                <TableHead>
                  <TableRow>
                    <TableCell
                      sx={{
                        borderBottom: "none",
                        border: "1px dotted black",
                        fontSize: "7pt",
                        fontWeight: 700,
                        backgroundColor: "#e5dfec",
                        textAlign: "center"
                      }}
                    >
                      항목
                    </TableCell>
                    <TableCell
                      sx={{
                        borderBottom: "none",
                        border: "1px dotted black",
                        fontSize: "7pt",
                        fontWeight: 700,
                        backgroundColor: "#e5dfec",
                        textAlign: "center"
                      }}
                    >
                      목적
                    </TableCell>
                    <TableCell
                      sx={{
                        borderBottom: "none",
                        border: "1px dotted black",
                        fontSize: "7pt",
                        fontWeight: 700,
                        backgroundColor: "#e5dfec",
                        textAlign: "center"
                      }}
                    >
                      보유 및 이용기간
                      {/* 보유 및 - */}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell
                      sx={{
                        borderBottom: "none",
                        border: "1px dotted black",
                        fontSize: "9pt",
                        fontWeight: 700,
                        textAlign: "center"
                      }}
                    >
                      생년월일, 주민번호
                    </TableCell>
                    <TableCell
                      sx={{
                        borderBottom: "none",
                        border: "1px dotted black",
                        fontSize: "9pt",
                        fontWeight: 700,
                        textAlign: "center"
                      }}
                    >
                      실소유자 고객확인
                    </TableCell>
                    <TableCell
                      sx={{
                        borderBottom: "none",
                        border: "1px dotted black",
                        fontSize: "9pt",
                        fontWeight: 700,
                        textAlign: "center"
                      }}
                    >
                      금융거래관계 종료일로부터 5년
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
              <Typography sx={{ mt: 1 }}>
                “고객사”는 회사의 개인정보 수집·이용 동의를 거부할 수 있습니다.
                단, 동의를 거부하는 경우 서비스신청이 정상적으로 완료될 수
                없음을 알려드립니다.
              </Typography>
              <Box sx={{ border: "1px dotted black", height: "30mm", mt: 1 }}>
                <Stack
                  direction={"row"}
                  alignItems={"center"}
                  spacing={1}
                  sx={{ p: 1 }}
                >
                  <Typography>
                    “고객사”는 특정 금융거래정보의 보고 및 이용 등에 관한 법률에
                    따른 개인정보 이용 활용에 관하여 고지를 받았으며, 이를
                    충분히 이해하고 동의합니다.
                  </Typography>
                  <Typography sx={{ fontSize: "10pt" }}>□</Typography>
                </Stack>
                <Stack alignItems={"flex-end"}>
                  <Stack
                    direction={"row"}
                    alignItems={"center"}
                    sx={{ pt: 1, pr: 1.3, width: "65mm", mt: 1 }}
                  >
                    <Typography sx={{ width: "15mm" }}>대표(이사):</Typography>
                    <Typography
                      sx={{
                        backgroundColor: grey[300],
                        textAlign: "center",
                        letterSpacing: 10,
                        width: "40mm"
                      }}
                    >
                      {data?.merchantOwnerInformation[0].name}
                    </Typography>
                    <Typography
                      sx={{
                        backgroundColor: grey[300],
                        textAlign: "center",
                        width: "8mm"
                      }}
                    >
                      (인)
                    </Typography>
                  </Stack>
                  <Typography
                    sx={{ color: grey[500], pr: 1.3, fontSize: "6pt", mt: 1 }}
                  >
                    ※ 개인사업자: 대표자 인감 / 법인사업자: 법인 인감 날인 필수
                  </Typography>
                </Stack>
              </Box>
            </Box>
          </Box>

          {/* 바닥글 ======================================================== */}
          {/*   kwk k-oj*/}
          <Divider
            sx={{
              border: "none",
              borderTop: "1px dotted",
              borderColor: grey[700],
              mx: 1
            }}
          ></Divider>
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            sx={{ height: "7mm", color: grey[400], mx: 3, mt: 0 }}
          >
            <Typography sx={{ fontSize: "7pt" }}>
              국내 최고의 통합 결제 서비스 OZICPAY
            </Typography>
            <Typography sx={{ fontSize: "7pt" }}>페이지 5 / 9</Typography>
          </Stack>
        </Card>
      </Box>
    </Fonts>
  );
};

export default Page5;
