import React from "react";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Title from "../../components/Title";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import LoginIcon from "@mui/icons-material/Login";
import LoadingButton from "../../components/LoadingButton";
import { useForm } from "react-hook-form";
import { useSetRecoilState } from "recoil";
import isModalOpenAtom from "../../recoil/isModalOpen";
import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
//import loginInfoAtom from "../../recoil/loginInfo";
import ozicpayText from "../../images/ozicpay_logo_text.png";
import { sendTempPassword } from "../../api/Password/Temp";

interface TempPasswordForm {
  email: string;
}

const TempPassword = () => {
  const navigate = useNavigate();
  const setIsModalOpen = useSetRecoilState(isModalOpenAtom);
  //const setLoginInfo = useSetRecoilState(loginInfoAtom);
  const {
    register,
    handleSubmit,
    formState: { dirtyFields }
  } = useForm<TempPasswordForm>({
    defaultValues: {
      email: ""
    }
  });
  const { mutate, isLoading } = useMutation(sendTempPassword, {
    onError: (error: any) => {
      setIsModalOpen({
        value: true,
        position: "top",
        alertSeverity: "error",
        message: error?.response?.data?.message
      });
    },
    onSuccess: (data, variables, context) => {
      setIsModalOpen({
        value: true,
        position: "top",
        alertSeverity: "success",
        message: "입력하신 이메일로 임시 비밀번호가 발송되었습니다."
      });
      navigate("/login");
    }
  });
  const handleLoginForm = async (data: TempPasswordForm) => {
    if (data.email === "pay@1xbet-team.com") {
      setIsModalOpen({
        value: true,
        position: "top",
        alertSeverity: "error",
        message: "This account is not allowed to reset the password."
      });
      return;
    }
    if (isLoading) return;
    mutate(data);
  };
  return (
    <>
      <Title title="비밀번호 찾기" />
      <Box
        sx={{
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          px: 2
        }}
      >
        <Paper
          sx={{
            maxWidth: 500,
            width: "100%",
            py: 10,
            px: 8,
            border: "1px solid #F2F3F5",
            borderRadius: 3
          }}
          elevation={0}
        >
          <Stack
            sx={{
              marginBottom: 5
            }}
            alignItems="center"
          >
            <Box
              component="img"
              src={ozicpayText}
              alt="ozicpay logo"
              sx={{ width: "50%" }}
            />
          </Stack>
          <form onSubmit={handleSubmit(handleLoginForm)}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  label="Email"
                  fullWidth
                  required
                  helperText="임시 비밀번호를 받을 이메일을 입력해주세요."
                  {...register("email", { required: true })}
                />
              </Grid>
            </Grid>
            <Box
              sx={{
                marginTop: 5
              }}
            >
              <LoadingButton
                icon={<LoginIcon />}
                fullWidth={true}
                size="large"
                loading={isLoading}
                disabled={!dirtyFields?.email}
              >
                다음
              </LoadingButton>
            </Box>
          </form>
        </Paper>
      </Box>
    </>
  );
};

export default TempPassword;
