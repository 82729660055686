import React, { useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { Navigate, Outlet, useNavigate } from "react-router-dom";
import { getUserInfo } from "../../api/Auth";
import { verifyJwt } from "../../libs/verify-jwt";

//라우터 가드에서 리코일 스테이트를 부르면 제대로 작동하지 않음
// 상태값을 불렀을 때 다시 로딩 되다가 라우터 가드에서 걸려서 otp 페이지를 무시하고 자꾸 홈으로 가는 것을 확인
export const PrivateRouteGuard = () => {
  const loginInfo = sessionStorage.getItem("loginInfo");
  const token = loginInfo ? JSON.parse(loginInfo).accessToken : null;
  if (!token) {
    return <Navigate to="/login" replace={true} />;
  }
  return <Outlet />;
};

export const PublicRouteGuard = () => {
  const loginInfo = sessionStorage.getItem("loginInfo");
  const token = loginInfo ? JSON.parse(loginInfo).accessToken : null;
  if (token) {
    return <Navigate to="/" replace={true} />;
  }
  return <Outlet />;
};

export const ClientRouteGuard = () => {
  const CLIENT = "CLIENT";
  const navigate = useNavigate();
  const { data } = useQuery(["getUserInfo"], getUserInfo, {
    onSuccess: async (data) => {
      const loginInfo = JSON.parse(sessionStorage.getItem("loginInfo")!);
      try {
        const {
          payload: { authority }
        } = await verifyJwt(loginInfo.accessToken);
        if (
          data.content.role !== CLIENT ||
          authority !== CLIENT ||
          loginInfo.loginUser.role !== CLIENT
        ) {
          throw new Error("권한이 없습니다.");
        }
      } catch (e) {
        console.log(e);
        sessionStorage.removeItem("loginInfo");
        window.location.replace("/login");
      }
    }
  });
  useEffect(() => {
    if (data && data?.content) {
      if (data.content.passwordUpdateFlag === "Y") {
        navigate("/password", { replace: true });
      }
    }
  }, [data, navigate]);
  return <Outlet />;
};

export const AdminRouteGuard = () => {
  const ADMIN = "ADMIN";
  const navigate = useNavigate();
  const { data } = useQuery(["getUserInfo"], getUserInfo, {
    onSuccess: async (data) => {
      const loginInfo = JSON.parse(sessionStorage.getItem("loginInfo")!);
      try {
        const {
          payload: { authority }
        } = await verifyJwt(loginInfo.accessToken);
        if (
          data.content.role !== ADMIN ||
          authority !== ADMIN ||
          loginInfo.loginUser.role !== ADMIN
        ) {
          throw new Error("권한이 없습니다.");
        }
      } catch (e) {
        console.log(e);
        sessionStorage.removeItem("loginInfo");
        window.location.replace("/login");
      }
    }
  });

  useEffect(() => {
    if (data && data?.content) {
      if (data.content.passwordUpdateFlag === "Y") {
        navigate("/password", { replace: true });
      }
    }
  }, [data, navigate]);
  return <Outlet />;
};
