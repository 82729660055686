import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import { Typography } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import ListItemLink, { ListItemLinkProps } from ".";

interface Props {
  listItem: ListItemLinkProps[];
  title: string;
  icon?: React.ReactElement;
}

const MenuAccordion = ({ title, listItem, icon }: Props) => {
  // ===================================================================================================================
  // useLocation 훅으로 현재 경로를 가져온다.
  // 컴포넌트가 렌더링될 때 isCurrentPathInList 를 기반으로 expanded 상태를 결정한다.
  // ===================================================================================================================

  const location = useLocation();

  const isCurrentPathInList = listItem.some((item) =>
    location.pathname.startsWith(item.to)
  );
  // 현재 경로가 ClientList에 포함되어 있다면 아코디언은 열려 있음
  const [expanded, setExpanded] = useState(isCurrentPathInList);

  const handleAccordionToggle = () => {
    setExpanded((prevExpanded) => !prevExpanded);
  };
  return (
    <Accordion expanded={expanded} sx={{ boxShadow: "none" }}>
      <AccordionSummary
        expandIcon={<ExpandMoreRoundedIcon color="primary" />}
        onClick={handleAccordionToggle}
        sx={{ height: 40, p: 0 }}
      >
        {icon}
        <Typography sx={{ ml: 3 }}> {title}</Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ p: 0, pl: 1.5 }}>
        {listItem.map((list, index) => (
          <ListItemLink
            to={list.to}
            primary={list.primary}
            // icon={list.component}
            // iconActive={list.componentActive}
            key={`${title} ${index}`}
          />
        ))}
      </AccordionDetails>
    </Accordion>
  );
};

export default MenuAccordion;
