import { useState } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  getNotificationsForClient,
  putNotificationRead
} from "../../api/Notification";
import IconButton from "@mui/material/IconButton";
import NotificationsIcon from "@mui/icons-material/Notifications";
import Badge from "@mui/material/Badge";
import Popover from "@mui/material/Popover";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItem from "@mui/material/ListItem";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { deepOrange, grey, lightBlue } from "@mui/material/colors";
import Chip from "@mui/material/Chip";
import { NotificationForAdmin } from "../../api/Notification/type";
import { useSetRecoilState } from "recoil";
import isModalOpenAtom from "../../recoil/isModalOpen";
import { useNavigate } from "react-router-dom";
import {
  translateDateTime,
  translatePriority,
  translateType
} from "../../libs/notifications";

const NotificationAppBar = () => {
  // ===========================================================================
  // 리코일 스테이트
  // ===========================================================================
  const setIsModalOpen = useSetRecoilState(isModalOpenAtom);

  // ===========================================================================
  // 컴포넌트 스테이트
  // ===========================================================================
  const [isOpenNotificationPop, setIsOpenNotificationPop] = useState(false);

  // ===========================================================================
  // 리액트쿼리: 클라이언트 읽지 않은 알림 내역 조회
  // ===========================================================================
  const { data: unreadNotifications, refetch } = useQuery(
    [`/notifications/unread`],
    () =>
      getNotificationsForClient({
        filterType: "NOT_READ",
        message: "",
        priority: "ALL",
        type: "ALL"
      })
  );

  // ===========================================================================
  // 리액트쿼리: 클라이언트 알림 읽음 처리
  // ===========================================================================
  const { mutate } = useMutation(putNotificationRead, {
    onSuccess: () => {
      setIsModalOpen({
        value: true,
        position: "top",
        alertSeverity: "success",
        message: "해당 알림을 읽음 처리 하였습니다"
      });
      refetch();
    },
    onError: () => {
      setIsModalOpen({
        value: true,
        position: "top",
        alertSeverity: "error",
        message: "해당 알림을 읽음 처리 하는데 실패하였습니다"
      });
    }
  });
  const readNotification = (notification: NotificationForAdmin) => {
    mutate(notification.id);
  };

  // ===========================================================================
  // 컴포넌트 함수
  // ===========================================================================
  // 팝업 열기
  const openNotificationPopup = () => {
    setIsOpenNotificationPop(true);
  };

  // 팝업 닫기
  const closeNotificationPopup = () => {
    setIsOpenNotificationPop(false);
  };

  // 알림 내역 페이지로 이동
  const navigate = useNavigate();
  const goToNotificationPage = () => {
    navigate("/notifications");
  };

  // ===========================================================================
  // 컴포넌트 변수
  // ===========================================================================
  const 알림갯수 = unreadNotifications?.content.length ?? 0;

  // ===========================================================================
  // 스타일
  // ===========================================================================
  const priorityChipStyle = (priority: string) => {
    if (priority === "URGENT") {
      return {
        color: grey[50],
        backgroundColor: deepOrange[500]
      };
    } else if (priority === "IMPORTANT") {
      return {
        color: grey[50],
        backgroundColor: lightBlue[500]
      };
    } else {
      return {
        color: grey[50],
        backgroundColor: grey[500]
      };
    }
  };

  return (
    <>
      <IconButton
        id="notificationIcon"
        size="large"
        edge="start"
        color="inherit"
        onClick={openNotificationPopup}
        sx={{
          pr: 2
        }}
      >
        <Badge badgeContent={알림갯수} max={9} color="primary">
          <NotificationsIcon />
        </Badge>
      </IconButton>
      <Popover
        open={isOpenNotificationPop}
        onClose={closeNotificationPopup}
        anchorEl={document.querySelector("#notificationIcon")}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left"
        }}
        sx={{ mt: 2 }}
        elevation={2}
      >
        <Card sx={{ width: "400px" }}>
          <CardContent
            className="container"
            sx={{ p: 0, maxHeight: "600px", overflowY: "auto" }}
          >
            <List>
              {알림갯수 > 0 &&
                unreadNotifications?.content.map((notification, index) => (
                  <ListItem
                    divider={알림갯수 > index + 1}
                    key={index}
                    sx={{ p: 0 }}
                  >
                    <ListItemButton
                      onClick={() => readNotification(notification)}
                    >
                      <Stack
                        direction={"column"}
                        alignItems={"flex-start"}
                        spacing={1}
                      >
                        <Stack
                          direction={"row"}
                          width={"100%"}
                          justifyContent={"space-between"}
                          py={1}
                          pr={1}
                        >
                          <Stack direction={"row"} spacing={1}>
                            <Chip
                              label={translatePriority(notification.priority)}
                              size="small"
                              sx={priorityChipStyle(notification.priority)}
                            />
                            <Chip
                              label={translateType(notification.type)}
                              size="small"
                              sx={{
                                color: grey[50],
                                backgroundColor: grey[500]
                              }}
                            />
                          </Stack>
                          <Typography variant="body2" sx={{ color: grey[600] }}>
                            {translateDateTime(notification.createdAt)}
                          </Typography>
                        </Stack>

                        <Typography
                          sx={{ width: "370px", wordWrap: "break-word" }}
                        >
                          {notification.message}
                        </Typography>
                      </Stack>
                    </ListItemButton>
                  </ListItem>
                ))}
            </List>
          </CardContent>
          <CardActions sx={{ justifyContent: "center" }}>
            <Button
              size="large"
              color="primary"
              fullWidth={true}
              variant={"outlined"}
              sx={{
                "&:hover": {
                  background: "#FF6634",
                  color: "white",
                  cursor: "pointer"
                }
              }}
              onClick={goToNotificationPage}
            >
              전체 보기
            </Button>
          </CardActions>
        </Card>
      </Popover>
      <style>{`
        .container {
          overflow: auto;
        }
        .container::-webkit-scrollbar {
          width: 10px;
        }
        .container::-webkit-scrollbar-thumb {
          background-color: #FF6634;
          border-radius: 10px;
          background-clip: padding-box;
          border: 2px solid transparent;
        }
        .container::-webkit-scrollbar-track {
          background-color: #ff673452;
          border-radius: 10px;
        }
      `}</style>
    </>
  );
};

export default NotificationAppBar;
