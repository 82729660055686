import { instanceWithAuth } from "../Instance";
import { CommonApi } from "../type";
import { GetApiKey, GetApiKeyParams } from "./types";

export const getMerchantApiKey = async (params: GetApiKeyParams) => {
  const { data } = await instanceWithAuth.get<CommonApi<GetApiKey[]>>(
    "/merchant/key",
    { params }
  );

  return data;
};
