import styled from "@emotion/styled";
import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import { useMutation } from "@tanstack/react-query";
import { useSetRecoilState } from "recoil";
import { deleteMerchantTempData } from "../../api/Merchants";
import isModalOpen from "../../recoil/isModalOpen/atom";
import { queryClient } from "../..";

interface Props {
  id: number;
  closeModal?: () => void;
  onClose: () => void;
}

export const HelperTextMessage = styled("span")({
  position: "absolute",
  color: "#d94452"
});

const DeleteMerchantTempDataModal = ({ closeModal, id, onClose }: Props) => {
  // ===================================================================================================================
  // 리코일 스테이트
  // ===================================================================================================================
  const setIsModalOpen = useSetRecoilState(isModalOpen);

  // 임시 저장 데이터 삭제
  const { mutate } = useMutation(deleteMerchantTempData, {
    onSuccess: () => {
      setIsModalOpen({
        value: true,
        position: "top",
        alertSeverity: "success",
        message: "임시 저장 데이터가 삭제되었습니다."
      });
      // 삭제 후 임시 저장 가맹 목록 refetch
      queryClient.invalidateQueries(["/client/temporaries"]);
    },                      
    onError: (error: any) => {
      setIsModalOpen({
        value: true,
        position: "top",
        alertSeverity: "error",                                             
        message: error?.response?.data?.message                                      
      });                                          
    }
  });      
  
  

  // ===================================================================================================================
  // 삭제하기
  // ===================================================================================================================
  const onClick = () => {
    mutate(id);
    closeModal?.(); // 현재 모달 닫기
    onClose(); // 부모 모달 닫기
  };

  return (
    <>
      <Dialog open={true} fullWidth maxWidth="xs">
        <DialogTitle>임시 저장 데이터 삭제</DialogTitle>

        <IconButton
          onClick={closeModal}
          sx={{
            position: "absolute",
            right: 8,
            top: 12
          }}
        >
          <CloseIcon />
        </IconButton>

        <DialogContent>
          <DialogContentText>
            데이터를 삭제하면 복구할 수 없습니다.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            sx={{ color: "white" }}
            fullWidth
            onClick={onClick}
          >
            삭제하기
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DeleteMerchantTempDataModal;
