import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useEffect, useState } from "react";
import { StepData } from "../../api/MerchantRegistration/types";
import ModalTriggerButton from "../PopupTriggerButton";
import AdminRegistMerechantForm from "./AdminRegistMerechantForm";
import DeleteMerchantTempDataModal from "./DeleteMerchantTempDataModal";

interface Props {
  isOpen: boolean;
  closeModal: () => void;
  detailData: string;
  userId: number;
  id: number;
}

const TempMerchantModal = ({
  isOpen,
  closeModal,
  detailData,
  userId,
  id
}: Props) => {
  // ===================================================================================================================
  // 컴포넌트 스테이트
  // ===================================================================================================================
  // 파싱된 데이터를 저장할 상태 변수 추가
  const [parsedData, setParsedData] = useState<StepData | null>(null);

  // 내용 수정 스테이트
  const [isModifyData, setIsModifyData] = useState(false);

  // ===================================================================================================================
  // jsonString 데이터 파싱
  // ===================================================================================================================

  // 모달이 열릴 때 데이터 파싱 수행
  useEffect(() => {
    if (isOpen) {
      const newData = parseData(detailData);
      if (newData) {
        setParsedData(newData);
        setIsModifyData(false);
      } else {
        console.error("Failed to parse data or data is empty");
      }
    }
  }, [isOpen, detailData]);

  // 데이터 파싱 함수
  const parseData = (dataString: string) => {
    if (!dataString) {
      console.error("데이터가 없습니다.");
      return null;
    }

    try {
      const newData = JSON.parse(dataString);
      return newData;
    } catch (error) {
      console.error("데이터 파싱 오류:", error);
      return null;
    }
  };

  const handleModifyData = () => {
    setIsModifyData(true);
  };

  return (
    <>
      <Dialog open={isOpen} fullWidth maxWidth="lg">
        <DialogTitle
          sx={{
            boxShadow: "0px 5px 14px -1px rgba(204, 204, 204, 0.5)",
            display: "flex",
            justifyContent: "space-between",
            border: "1px solid #E4E4E7",
            alignItems: "center",
            mb: 3
          }}
        >
          {isModifyData ? "가맹 등록 내용" : "임시 저장 상세 내용"}
          <Stack direction={"row"} gap={2}>
            {!isModifyData && (
              <Stack direction={"row"} gap={2}>
                <ModalTriggerButton
                  size="medium"
                  color="primary"
                  modal={
                    <DeleteMerchantTempDataModal id={id} onClose={closeModal} />
                  }
                >
                  정보 삭제하기
                </ModalTriggerButton>
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={handleModifyData}
                >
                  정보 수정하기
                </Button>
              </Stack>
            )}
            <IconButton onClick={closeModal}>
              <CloseIcon />
            </IconButton>
          </Stack>
        </DialogTitle>

        <DialogContent>
          <Stack gap={2}>
            {isModifyData ? (
              <>
                <AdminRegistMerechantForm
                  detailData={parsedData}
                  closeModal={closeModal}
                  userId={userId}
                />
              </>
            ) : (
              <>
                {/* 사업자 정보 ==================================================================================== */}
                <Box
                  sx={{
                    border: "1px solid #E4E4E7",
                    borderRadius: 2,
                    overflow: "hidden"
                  }}
                >
                  <Stack
                    direction={"row"}
                    justifyContent="center"
                    alignItems="center"
                    gap={1}
                    sx={{
                      py: 1,
                      borderBottom: "1px solid #E4E4E7",
                      backgroundColor: "#f7f7f7"
                    }}
                  >
                    <Typography>사업자 정보</Typography>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: "bold",
                        color: "#666"
                      }}
                    >
                      ({parsedData?.merchantInformation?.businessType}
                      사업자)
                    </Typography>
                  </Stack>
                  <Stack gap={1.5} sx={{ px: 4, py: 3 }}>
                    <Stack direction="row" sx={{ pl: 1 }}>
                      <Stack direction="row" sx={{ width: 350 }}>
                        <Typography>가맹점명:</Typography>
                        <Typography sx={{ ml: 1 }}>
                          {parsedData?.mallName}
                        </Typography>
                      </Stack>
                      <Stack direction="row" sx={{ width: 350 }}>
                        <Typography>| 사업자 등록증 상호명:</Typography>
                        <Typography sx={{ ml: 1 }}>
                          {parsedData?.merchantInformation?.businessName}
                        </Typography>
                      </Stack>
                      <Stack direction="row">
                        <Typography>| 사업자등록번호:</Typography>
                        <Typography sx={{ ml: 1 }}>
                          {parsedData?.merchantInformation?.registrationNumber}
                        </Typography>
                      </Stack>
                    </Stack>

                    {parsedData?.merchantInformation?.businessType ===
                    "법인" ? (
                      <Stack direction="row" sx={{ pl: 1 }}>
                        <Stack direction="row" sx={{ width: 350 }}>
                          <Typography>영문상호:</Typography>
                          <Typography sx={{ ml: 1 }}>
                            {parsedData?.mallNameEn}
                          </Typography>
                        </Stack>
                        <Stack direction="row">
                          <Typography>| 법인등록번호:</Typography>
                          <Typography sx={{ ml: 1 }}>
                            {parsedData?.merchantInformation?.corporationNumber}
                          </Typography>
                        </Stack>
                      </Stack>
                    ) : (
                      <></>
                    )}

                    <Stack direction="row" sx={{ pl: 1 }}>
                      <Typography>사업장 주소:</Typography>
                      <Typography sx={{ ml: 1 }}>
                        {parsedData?.merchantInformation?.postCode} &nbsp;
                        {parsedData?.merchantInformation?.mallAddress} &nbsp;
                        {parsedData?.merchantInformation?.detailAddress}
                      </Typography>
                    </Stack>

                    {parsedData?.merchantInformation?.headOfficeAddress ? (
                      <Stack direction="row" sx={{ pl: 1 }}>
                        <Typography>본점 주소:</Typography>
                        <Typography sx={{ ml: 1 }}>
                          {parsedData?.merchantInformation?.headOfficePostCode}
                          &nbsp;
                          {parsedData?.merchantInformation?.headOfficeAddress}
                          &nbsp;
                          {
                            parsedData?.merchantInformation
                              ?.headOfficeDetailAddress
                          }
                        </Typography>
                      </Stack>
                    ) : (
                      ""
                    )}

                    <Stack direction="row" sx={{ pl: 1 }}>
                      <Stack direction="row" sx={{ width: 350 }}>
                        <Typography>가맹점 전화번호:</Typography>
                        <Typography sx={{ ml: 1 }}>
                          {parsedData?.merchantInformation?.phone}
                        </Typography>
                      </Stack>
                      <Stack direction="row">
                        <Typography>| 가맹점 팩스번호:</Typography>
                        <Typography sx={{ ml: 1 }}>
                          {parsedData?.merchantInformation?.fax}
                        </Typography>
                      </Stack>
                    </Stack>
                    <Stack direction="row" sx={{ pl: 1 }}>
                      <Typography>업종:</Typography>
                      <Typography sx={{ ml: 1 }}>
                        {parsedData?.merchantInformation?.merchantCategory}
                      </Typography>
                    </Stack>
                    <Stack direction="row" sx={{ pl: 1 }}>
                      <Typography>업태:</Typography>
                      <Typography sx={{ ml: 1 }}>
                        {parsedData?.merchantInformation?.businessConditions}
                      </Typography>
                    </Stack>
                    <Stack direction="row" sx={{ pl: 1 }}>
                      <Stack direction="row" sx={{ width: 350 }}>
                        <Typography>통신판매번호:</Typography>
                        <Typography sx={{ ml: 1 }}>
                          {
                            parsedData?.merchantInformation
                              ?.mailOrderSalesNumber
                          }
                        </Typography>
                      </Stack>
                      <Stack direction="row">
                        <Typography>| 홈페이지:</Typography>
                        <Typography sx={{ ml: 1 }}>
                          {parsedData?.mallUrl}
                        </Typography>
                      </Stack>
                    </Stack>
                    <Stack direction="row" sx={{ pl: 1 }}>
                      <Stack direction="row" sx={{ width: 350 }}>
                        <Typography>가맹점 IP:</Typography>
                        <Typography sx={{ ml: 1 }}>
                          {parsedData?.mallIp}
                        </Typography>
                      </Stack>
                      {parsedData?.merchantInformation?.introductionCompany ? (
                        <Stack direction="row">
                          <Typography>| 호스팅:</Typography>
                          <Typography sx={{ ml: 1 }}>
                            {
                              parsedData?.merchantInformation
                                ?.introductionCompany
                            }
                          </Typography>
                        </Stack>
                      ) : (
                        <></>
                      )}
                    </Stack>
                    <Stack direction="row" sx={{ pl: 1 }}>
                      <Stack direction="row" sx={{ width: 350 }}>
                        <Typography>테스트 ID:</Typography>
                        <Typography sx={{ ml: 1 }}>
                          {parsedData?.merchantInformation?.testId}
                        </Typography>
                      </Stack>
                      <Stack direction="row">
                        <Typography>| 테스트 PW:</Typography>
                        <Typography sx={{ ml: 1 }}>
                          {parsedData?.merchantInformation?.testPassword}
                        </Typography>
                      </Stack>
                    </Stack>
                  </Stack>
                </Box>

                {/* 대표자 정보 ==================================================================================== */}
                <Box
                  sx={{
                    border: "1px solid #E4E4E7",
                    borderRadius: 2,
                    overflow: "hidden"
                  }}
                >
                  <Stack
                    justifyContent="center"
                    alignItems="center"
                    sx={{
                      py: 1,
                      borderBottom: "1px solid #E4E4E7",
                      backgroundColor: "#f7f7f7"
                    }}
                  >
                    <Typography>대표자 정보</Typography>
                  </Stack>
                  <Stack sx={{ px: 4, py: 3 }}>
                    {parsedData?.merchantOwnerInformation?.map(
                      (owner, index) => (
                        <Stack key={owner.name} gap={1.5}>
                          <Stack direction={"row"} sx={{ pl: 1 }}>
                            <Stack direction={"row"} sx={{ width: 350 }}>
                              <Typography>성명:</Typography>
                              <Typography sx={{ ml: 1 }}>
                                {owner.name}
                              </Typography>
                            </Stack>
                            <Stack direction={"row"}>
                              <Typography>| 영문성명:</Typography>
                              <Typography sx={{ ml: 1 }}>
                                {owner.nameEn}
                              </Typography>
                            </Stack>
                          </Stack>
                          <Stack direction={"row"} sx={{ pl: 1 }}>
                            <Stack direction={"row"} sx={{ width: 350 }}>
                              <Typography>전화번호:</Typography>
                              <Typography sx={{ ml: 1 }}>
                                {owner.phone}
                              </Typography>
                            </Stack>
                            <Stack direction={"row"} sx={{ width: 320 }}>
                              <Typography>| 생년월일:</Typography>
                              <Typography sx={{ ml: 1 }}>
                                {owner.birth}
                              </Typography>
                            </Stack>
                            <Stack direction={"row"}>
                              <Typography>| 국적:</Typography>
                              <Typography sx={{ ml: 1 }}>
                                {owner.nationality}
                              </Typography>
                            </Stack>
                          </Stack>
                          <Stack direction={"row"} sx={{ pl: 1 }}>
                            <Typography>주소:</Typography>
                            <Typography sx={{ ml: 1 }}>
                              {owner.postCode} {owner.address}{" "}
                              {owner.detailAddress}
                            </Typography>
                          </Stack>
                          {parsedData?.merchantOwnerInformation &&
                            index !==
                              parsedData?.merchantOwnerInformation.length -
                                1 && <Divider sx={{ mb: 3, mt: 1 }} />}
                        </Stack>
                      )
                    )}
                  </Stack>
                </Box>

                {/* 은행 정보 ====================================================================================== */}
                <Box
                  sx={{
                    border: "1px solid #E4E4E7",
                    borderRadius: 2,
                    overflow: "hidden"
                  }}
                >
                  <Stack
                    alignItems="center"
                    sx={{
                      py: 1,
                      borderBottom: "1px solid #E4E4E7",
                      backgroundColor: "#f7f7f7"
                    }}
                  >
                    <Typography>은행 정보</Typography>
                  </Stack>

                  <Stack sx={{ px: 4, py: 5 }}>
                    <Stack direction={"row"} sx={{ pl: 1 }}>
                      <Stack direction={"row"} sx={{ width: 350 }}>
                        <Typography>은행명:</Typography>
                        <Typography sx={{ ml: 1 }}>
                          {parsedData?.merchantBankInformation?.bankName}
                        </Typography>
                      </Stack>
                      <Stack direction={"row"} sx={{ width: 320 }}>
                        <Typography>| 은행 계좌번호:</Typography>
                        <Typography sx={{ ml: 1 }}>
                          {parsedData?.merchantBankInformation?.accountCode}
                        </Typography>
                      </Stack>
                      <Stack direction={"row"}>
                        <Typography>| 계좌 소유주:</Typography>
                        <Typography sx={{ ml: 1 }}>
                          {parsedData?.merchantBankInformation?.accountHolder}
                        </Typography>
                      </Stack>
                    </Stack>
                  </Stack>
                </Box>

                {/* 담당자 정보 ==================================================================================== */}
                <Box
                  sx={{
                    border: "1px solid #E4E4E7",
                    borderRadius: 2,
                    overflow: "hidden"
                  }}
                >
                  <Stack
                    alignItems="center"
                    sx={{
                      py: 1,
                      borderBottom: "1px solid #E4E4E7",
                      backgroundColor: "#f7f7f7"
                    }}
                  >
                    <Typography>담당자 정보</Typography>
                  </Stack>
                  <Stack gap={1.5} sx={{ pt: 3, px: 4 }}>
                    {parsedData?.merchantInChargeInformation?.map(
                      (inCharge, index) => (
                        <div key={index}>
                          <Stack direction={"row"} sx={{ pl: 1 }}>
                            <Stack direction={"row"} sx={{ width: 350 }}>
                              <Typography>성명:</Typography>
                              <Typography sx={{ ml: 1 }}>
                                {inCharge.name}
                              </Typography>
                            </Stack>
                            <Stack direction={"row"}>
                              <Typography>| 담당역할:</Typography>
                              <Typography sx={{ ml: 1 }}>
                                {inCharge.role}
                              </Typography>
                            </Stack>
                          </Stack>
                          <Stack
                            direction={"row"}
                            sx={{ pl: 1, mt: 1.5, mb: 2.5 }}
                          >
                            <Stack direction={"row"} sx={{ width: 350 }}>
                              <Typography>전화번호:</Typography>
                              <Typography sx={{ ml: 1 }}>
                                {inCharge.phone}
                              </Typography>
                            </Stack>
                            <Stack direction={"row"}>
                              <Typography>| 이메일:</Typography>
                              <Typography sx={{ ml: 1 }}>
                                {inCharge.email}
                              </Typography>
                            </Stack>
                          </Stack>
                          {/* divi */}

                          {/* Divider는 마지막 요소 이후에는 렌더링되지 않도록 조건 추가 */}
                          {parsedData?.merchantInChargeInformation &&
                            index !==
                              parsedData.merchantInChargeInformation.length -
                                1 && <Divider sx={{ my: 1 }} />}
                        </div>
                      )
                    )}
                  </Stack>
                </Box>
              </>
            )}
          </Stack>
        </DialogContent>
      </Dialog>
    </>
  );
};
export default TempMerchantModal;
