import Axios from "axios";
import { loginInfoDefault } from "../recoil/loginInfo/atom";

export const instance = Axios.create({
  baseURL: process.env.REACT_APP_BASE_URL
});

export const instanceWithAuth = Axios.create({
  baseURL: process.env.REACT_APP_BASE_URL
});

instanceWithAuth.interceptors.request.use(
  (config) => {
    if (config && config.headers) {
      const loginInfo = sessionStorage.getItem("loginInfo")
        ? JSON.parse(sessionStorage.getItem("loginInfo") as string)
        : {
            ...loginInfoDefault
          };

      config.headers.Authorization = loginInfo.accessToken;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
