import { instanceWithAuth } from "../Instance";
import { CommonApi } from "../type";
import { GetHistoryDataType, GetHistoryParams } from "./types";

/**
 * 오직페이 갱신 이력 조회
 */
export const getOzicpayHistory = async (params: GetHistoryParams) => {
  const url = "/ozicpay_history";
  const { data } = await instanceWithAuth.get<CommonApi<GetHistoryDataType[]>>(
    url,
    {
      params
    }
  );
  return data;
};
