import CloseIcon from "@mui/icons-material/Close";
import MuiLoadingButton from "@mui/lab/LoadingButton";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { useMutation } from "@tanstack/react-query";
import { Controller, useForm } from "react-hook-form";
import { useSetRecoilState } from "recoil";
import { postCardCompanyGrade } from "../../api/CardInstallments";
import {
  numberCommaValidate,
  numberValidate
} from "../../libs/input-validation";
import { removeCommas } from "../../libs/thousands-commas";
import isModalOpenAtom from "../../recoil/isModalOpen";

interface CardCompanyModalProps {
  closeModal?: () => void;
  onButtonSubmit?: () => void;
  cardCompanyName: string;
  mccType: string;
}

const CardCompanyModal = ({
  closeModal,
  onButtonSubmit,
  cardCompanyName,
  mccType
}: CardCompanyModalProps) => {
  // ===================================================================================================================
  // 리코일 스테이트
  // ===================================================================================================================
  const setIsModalOpen = useSetRecoilState(isModalOpenAtom);

  // ===================================================================================================================
  // 리액트 쿼리
  // ===================================================================================================================
  const { mutate, isLoading } = useMutation(postCardCompanyGrade, {
    onSuccess: () => {
      closeModal?.();
      setIsModalOpen({
        value: true,
        position: "top",
        alertSeverity: "success",
        message: "정보가 저장되었습니다."
      });
      onButtonSubmit?.();
    },
    onError: (error: any) => {
      setIsModalOpen({
        value: true,
        position: "top",
        alertSeverity: "error",
        message: error?.response?.data?.message
      });
    }
  });

  // ===================================================================================================================
  // 리액트 훅 폼
  // ===================================================================================================================
  const {
    handleSubmit,
    control,
    register,
    formState: { isDirty, isValid },
    watch
  } = useForm({
    defaultValues: {
      grades: [
        {
          optatumRegistrationNumber: "",
          deposit: "0",
          depositType: "보험",
          grade: "일반"
        },
        {
          optatumRegistrationNumber: "",
          deposit: "0",
          depositType: "보험",
          grade: "영세"
        },
        {
          optatumRegistrationNumber: "",
          deposit: "0",
          depositType: "보험",
          grade: "중소1"
        },
        {
          optatumRegistrationNumber: "",
          deposit: "0",
          depositType: "보험",
          grade: "중소2"
        },
        {
          optatumRegistrationNumber: "",
          deposit: "0",
          depositType: "보험",
          grade: "중소3"
        }
      ]
    }
  });

  const grades = watch("grades");

  // ===================================================================================================================
  // 업종 추가
  // ===================================================================================================================
  const onValid = (formData: any) => {
    if (isLoading) return;

    const registrationNumbers = formData.grades.map(
      (grade: any) => grade.optatumRegistrationNumber
    );

    // 같은 카드사 등록번호가 있는지 확인
    const hasDuplicates = registrationNumbers.some(
      (item: string, index: number) =>
        registrationNumbers.indexOf(item) !== index
    );

    // 같은 카드사 등록번호가 있는 경우 함수 종료
    if (hasDuplicates) {
      setIsModalOpen({
        value: true,
        position: "top",
        alertSeverity: "error",
        message: "중복된 카드사 등록번호가 있습니다. 다른 번호를 입력해 주세요."
      });
      return;
    }

    // 파라미터 값 정제 및 전달
    const gradeForms = formData.grades.map((grade: any) => ({
      deposit: removeCommas(grade.deposit),
      depositType: grade.depositType,
      grade: grade.grade,
      optatumRegistrationNumber: grade.optatumRegistrationNumber
    }));

    const params = {
      cardCompanyName: cardCompanyName,
      gradeForms: gradeForms,
      mccType: mccType
    };
    mutate(params);
  };

  // 모든 카드사 등록번호 필드 값 유무 확인
  const allRegistrationNumbersFilled = !grades.some(
    (grade) => grade.optatumRegistrationNumber.trim() === ""
  );

  return (
    <Dialog open={true} fullWidth maxWidth="md">
      <DialogTitle>{cardCompanyName} 등급별 업종 추가</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={() => {
          closeModal?.();
        }}
        sx={{
          position: "absolute",
          right: 8,
          top: 12
        }}
      >
        <CloseIcon />
      </IconButton>
      <form onSubmit={handleSubmit(onValid)}>
        <DialogContent>
          <Stack gap={3}>
            {grades.map((grade, index) => (
              <Stack
                direction={"row"}
                alignItems={"center"}
                gap={3}
                key={index}
              >
                <InputLabel sx={{ width: 80 }}>{grade.grade}</InputLabel>
                <Grid container spacing={3}>
                  <Grid item xs={4}>
                    <TextField
                      label="카드사 등록번호"
                      required
                      fullWidth
                      size="small"
                      {...register(`grades.${index}.optatumRegistrationNumber`)}
                      onInput={numberValidate}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Controller
                      name={`grades.${index}.depositType`}
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <TextField
                          label="보증 타입"
                          required
                          fullWidth
                          size="small"
                          select
                          value={value}
                          onChange={onChange}
                        >
                          <MenuItem value="보험">보험</MenuItem>
                          <MenuItem value="예치금">예치금</MenuItem>
                          <MenuItem value="매출액">매출액</MenuItem>
                          <MenuItem value="보증없음">보증없음</MenuItem>
                        </TextField>
                      )}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      label="보증 금액"
                      fullWidth
                      size="small"
                      required
                      {...register(`grades.${index}.deposit`)}
                      onInput={numberCommaValidate}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">원</InputAdornment>
                        )
                      }}
                    />
                  </Grid>
                </Grid>
              </Stack>
            ))}
          </Stack>
        </DialogContent>
        <DialogActions>
          <MuiLoadingButton
            type="submit"
            variant="contained"
            sx={{ color: "white" }}
            fullWidth
            loading={isLoading}
            disabled={!allRegistrationNumbersFilled || !isDirty || !isValid}
          >
            추가하기
          </MuiLoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default CardCompanyModal;
