import LogoutIcon from "@mui/icons-material/Logout";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";
import { useRecoilState } from "recoil";
import optatumLogo from "../../images/옵타움플랫폼-로고_가로형(영문).png";
import LoginInfoAtom, { loginInfoDefault } from "../../recoil/loginInfo";

export default function LeyutaeLayout() {
  // ===================================================================================================================
  // 리코일 스테이트
  // ===================================================================================================================
  const [loginInfo, setLoginInfo] = useRecoilState(LoginInfoAtom);

  const handleLogout = () => {
    setLoginInfo(loginInfoDefault);
    sessionStorage.removeItem("loginInfo");
  };

  return (
    <AppBar
      position="fixed"
      color="inherit"
      elevation={0}
      sx={{ borderBottom: "1px solid #E4E4E7" }}
    >
      <Toolbar>
        <Box sx={{ flexGrow: 1 }}>
          <Link to="/">
            <IconButton>
              <Box component="img" src={optatumLogo} sx={{ height: 40 }} />
            </IconButton>
          </Link>
        </Box>
        <Stack
          direction="row"
          spacing={2}
          divider={
            <Divider
              orientation="vertical"
              flexItem
              sx={{
                borderColor: "white"
              }}
            />
          }
        >
          <Stack
            direction="row"
            alignItems="center"
            spacing={0.5}
            sx={{
              pl: 1
            }}
          >
            <Typography variant="body1">
              {loginInfo.loginUser?.email}
            </Typography>
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="time extention"
              sx={{
                p: 1
              }}
              onClick={handleLogout}
            >
              <LogoutIcon />
            </IconButton>
          </Stack>
        </Stack>
      </Toolbar>
    </AppBar>
  );
}
