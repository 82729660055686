import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { getTempMerchantList } from "../../api/Merchants";
import AdminLayout from "../../components/Layout/Admin/Layout";
import TempMerchantModal from "../../components/Merchants/TempMerchantModal";
import Title from "../../components/Title";
import useRenewal from "../../hooks/useRenewal";

const TempMerchantList = () => {
  const { isRenewalTokenLoading, mutateRenewalToken } = useRenewal();

  // ===================================================================================================================
  // 스테이트
  // ===================================================================================================================
  const [selectedData, setSelectedData] = useState<any>({
    isModalOpen: false,
    created: "",
    jsonString: "",
    userEmail: "",
    userId: "",
    id: 0
  });

  // ===================================================================================================================
  // 리액트 쿼리
  // ===================================================================================================================
  // 임시 저장 데이터 조회
  const { data, isLoading } = useQuery(
    ["/client/temporaries"],
    () => getTempMerchantList("CLIENT"),
    {
      onSuccess: () => {
        if (isRenewalTokenLoading) return;
        mutateRenewalToken();
      }
    }
  );

  // ===================================================================================================================
  // 모달창 열기, 닫기
  // ===================================================================================================================
  const closeModal = () => {
    setSelectedData({
      isModalOpen: false,
      created: "",
      jsonString: "",
      userEmail: ""
    });
  };

  const selectRow = (row: any) => {
    setSelectedData({ ...row, isModalOpen: true });
  };

  return (
    <>
      <Title title="가맹 임시 저장 목록" />
      <AdminLayout>
        <Typography sx={{ fontWeight: "bold", mb: 1 }}>
          임시 저장 가맹 목록
        </Typography>
        <Paper
          sx={{
            p: 3,
            border: "1px solid #F2F3F5",
            borderRadius: 3,
            overflow: "hidden"
          }}
        >
          {data?.content.length === 0 ? (
            <>
              <Stack
                height={550}
                alignItems={"center"}
                justifyContent={"center"}
              >
                <Typography>조회된 데이터가 없습니다.</Typography>
              </Stack>
            </>
          ) : (
            <>
              {!data && isLoading && (
                <Grid container spacing={3} sx={{ px: 3, mb: 3 }}>
                  {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((index) => (
                    <Grid item xs={12} sm={4} key={index}>
                      <Box
                        sx={{
                          backgroundColor: "#f2f2f2",
                          p: 2,
                          borderRadius: 2
                        }}
                      >
                        <Skeleton variant="text" width={"60%"} height={30} />
                        <Skeleton variant="text" width={"80%"} height={30} />
                        <Skeleton variant="text" width={"40%"} height={30} />
                      </Box>
                    </Grid>
                  ))}
                </Grid>
              )}

              {data && data.content.length > 0 && (
                <Grid container spacing={3}>
                  {data.content.map((d) => {
                    const parsedData = JSON.parse(d.jsonString);
                    return (
                      <Grid item xs={12} sm={4} key={d.id}>
                        <Stack
                          sx={{
                            backgroundColor: "#f7f7f7",
                            p: 2,
                            gap: 1,
                            cursor: "pointer",
                            border: "1px solid #E4E4E7",
                            borderRadius: 2
                          }}
                          onClick={() => selectRow(d)}
                        >
                          <Typography variant="subtitle2" fontWeight={"bold"}>
                            {d.created.split("T")[0]}
                          </Typography>
                          <Typography variant="body2">
                            가맹점명: {parsedData.mallName}
                          </Typography>
                          <Typography variant="body2">
                            사용자 이메일: {d.userEmail}
                          </Typography>
                        </Stack>
                      </Grid>
                    );
                  })}
                </Grid>
              )}
            </>
          )}
        </Paper>
        {/* 임시 저장 상세 데이터 조회 모달창 */}
        <TempMerchantModal
          isOpen={selectedData.isModalOpen}
          closeModal={closeModal}
          detailData={selectedData.jsonString}
          userId={selectedData.userId}
          id={selectedData.id}
        />
      </AdminLayout>
    </>
  );
};
export default TempMerchantList;
