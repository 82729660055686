import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import { useMutation } from "@tanstack/react-query";
import { useSetRecoilState } from "recoil";
import { queryClient } from "../..";
import { postUserPassTemp } from "../../api/Merchants";
import isModalOpen from "../../recoil/isModalOpen/atom";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  email: any;
}

const UserPassModal = ({ isOpen, onClose, email }: Props) => {
  // ===========================================================================
  // 리코일 스테이트
  // ===========================================================================
  const setIsModalOpen = useSetRecoilState(isModalOpen);

  // 사용자 계정 비밀번호 카운트 변경
  const { mutate } = useMutation(postUserPassTemp, {
    onSuccess: () => {
      queryClient.invalidateQueries(email);
      setIsModalOpen({
        value: true,
        position: "top",
        alertSeverity: "success",
        message: "임시 비밀번호를 전송했습니다."
      });
    },
    onError: (error: any) => {
      setIsModalOpen({
        value: true,
        position: "top",
        alertSeverity: "error",
        message: error?.response?.data?.message
      });
    }
  });

  const onSubmit = () => {
    const params = {
      email: email
    };
    mutate(params);
    onClose()
  };

  return (
    <>
      {/* 잠금해제 프롬프트 - 버튼을 누르면 비밀번호 실패 카운트 변경 */}
      <Dialog open={isOpen} fullWidth maxWidth="xs">
        <DialogTitle>사용자 비밀번호 임시발급</DialogTitle>

        <IconButton
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 12
          }}
        >
          <CloseIcon />
        </IconButton>

        <DialogContent>
          <DialogContentText gutterBottom>
            사용자의 등록된 이메일로 임시 비밀번호를 발급합니다.
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button
            variant="contained"
            sx={{ color: "white" }}
            fullWidth
            onClick={onSubmit}
          >
            임시 비밀번호 발급
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default UserPassModal;
