import { instanceWithAuth, instance } from "../Instance";
import type { Login } from "../Login/types";
import type { CommonApi } from "../type";
import type { UserSearchParam, UserSearchResult } from "./types";

/**
 * 사용자 검색
 */
export const getUsersByQuery = async (params: UserSearchParam) => {
  const url = `/users/search`;
  const { data } = await instanceWithAuth.get<CommonApi<UserSearchResult[]>>(
    url,
    { params }
  );
  return data;
};

export const getServerTime = async () => {
  const { data } = await instance.get("/bypass/users/servertime");
  return data;
};

export const renewalToken = async () => {
  const { data } = await instanceWithAuth.post<CommonApi<Login>>(
    "/bypass/users/renewal"
  );
  return data;
};
