import React from "react";
import Title from "../../../components/Title";
import AdminLayout from "../../../components/Layout/Admin/Layout";

const SettlementsPayout = () => {
  return (
    <>
      <Title title="정산 지급" />
      <AdminLayout>
        <div>
          <h1>SettlementsPayout</h1>
          <p>SettlementsPayouts List</p>
        </div>
      </AdminLayout>
    </>
  );
};

export default SettlementsPayout;
