import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import MuiLoadingButton from "@mui/lab/LoadingButton";
import {
  Button,
  DialogContentText,
  InputAdornment,
  Typography
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { useMutation, useQuery } from "@tanstack/react-query";
import koLocale from "date-fns/locale/ko";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useSetRecoilState } from "recoil";
import {
  getCardInstallments,
  saveCardInstallmentsReservation
} from "../../api/CardInstallments";
import { getDate } from "../../libs/get-date";
import { getToday } from "../../libs/get-today";
import { numberCommaValidate } from "../../libs/input-validation";
import { addCommas, removeCommas } from "../../libs/thousands-commas";
import isModalOpenAtom from "../../recoil/isModalOpen";

interface CardInstallmentReservationModalProps {
  closeModal?: () => void;
  cardCompanyName?: string;
  mccType?: string;
}

interface Reservation {
  id?: number;
  customerMonth: number;
  cardCompanyInstallmentId: number | undefined;
  isAllFree: boolean;
  isPartialFree: boolean;
  isUse: boolean;
  partialPrice: number;
  reservationDate: string;
  month: number;
  mccType: string;
}

const CardInstallmentReservationModal = ({
  closeModal,
  cardCompanyName,
  mccType
}: CardInstallmentReservationModalProps) => {
  // ===================================================================================================================
  // 리코일 스테이트
  // ===================================================================================================================
  const setIsModalOpen = useSetRecoilState(isModalOpenAtom);

  // ===================================================================================================================
  // 스테이트
  // ===================================================================================================================
  const [reservationList, setReservationList] = useState<Reservation[]>([]);
  const viewList = reservationList.filter((r) => r.isUse === true);
  const deleteList = (reserveDate: string, month: number) => {
    const updatedLists = reservationList.map((list) => {
      if (list.reservationDate === reserveDate && list.month === month) {
        return { ...list, isUse: false };
      }
      return list;
    });
    setReservationList(updatedLists);
  };

  // ===================================================================================================================
  // 리액트 쿼리
  // ===================================================================================================================
  // 카드사 할부 정보 조회
  const { data } = useQuery(
    ["/cardCompany/installments", cardCompanyName, mccType],
    () => getCardInstallments(cardCompanyName!, mccType!),
    {
      enabled: !!cardCompanyName,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        setReservationList(data.content.installmentReservationInfo);
      }
    }
  );

  // 카드사 할부 예약정보 입력, 수정
  const { mutate, isLoading } = useMutation(saveCardInstallmentsReservation, {
    onSuccess: () => {
      closeModal?.();
      setIsModalOpen({
        value: true,
        position: "top",
        alertSeverity: "success",
        message: "저장되었습니다."
      });
    },
    onError: (error: any) => {
      setIsModalOpen({
        value: true,
        position: "top",
        alertSeverity: "error",
        message: error?.response?.data?.message
      });
    }
  });

  // ===================================================================================================================
  // 리액트 훅 폼
  // ===================================================================================================================
  const defaultFormValues = {
    month: "2",
    reservationDate: getToday(),
    isAllFree: "1",
    partialPrice: `${addCommas(50000)}`,
    customerMonth: 1
  };
  const { register, handleSubmit, control, watch, reset } = useForm({
    defaultValues: {
      ...defaultFormValues
    }
  });

  // ===================================================================================================================
  // 예약 저장 버튼
  // ===================================================================================================================
  const handleClickButon = () => {
    if (isLoading) return;
    if (reservationList.length === 0) {
      setIsModalOpen({
        value: true,
        position: "top",
        alertSeverity: "error",
        message: "등록된 할부 예약 정보가 없습니다."
      });
      return;
    }
    // 오늘 날짜 이후인지 검증
    const today = getToday();
    const invalidReservation = reservationList.find(
      (list) => list.reservationDate <= today && list.isUse
    );

    if (invalidReservation) {
      setIsModalOpen({
        value: true,
        position: "top",
        alertSeverity: "error",
        message: "예약 날짜는 오늘 이후의 날짜여야 합니다."
      });
      return;
    }

    const filteredList = reservationList.filter(
      (list) => list.isUse !== false || list.id !== undefined
    );
    mutate(filteredList);
  };

  const isCheckedAllFree = watch("isAllFree");
  const onValid = (formData: any) => {
    const month = Number(formData.month);
    const cardCompanyInstallment = data?.content.installmentInfo.filter(
      (i) => i.month === month
    )[0];
    const newArr = {
      month,
      cardCompanyInstallmentId: cardCompanyInstallment?.id,
      isAllFree: formData.isAllFree === "1" ? true : false,
      isPartialFree: formData.isAllFree === "1" ? false : true,
      partialPrice:
        formData.isAllFree === "1"
          ? 0
          : Number(removeCommas(formData.partialPrice)),
      customerMonth:
        formData.isAllFree === "1" ? 0 : Number(formData.customerMonth),
      reservationDate: formData.reservationDate,
      isUse: true,
      mccType: formData.mccType
    };
    // month가 똑같은 것을 data에서 찾는다
    // month, partialPrice, customerMonth를 숫자로 한다
    // isAllFree가 1이면 무이자라는 것이니 isAllFree를 true로 바꾸고
    // partialPrice는 0, isPartialFree는 false,
    // isAllFree가 0이면 부분무이자라는 것이니 isAllFree를 false로 바꾸고,
    // reservationDate와 isUse는 그대로한다.
    setReservationList((prev) => [...prev, newArr]);
    reset();
  };

  return (
    <Dialog open={true} fullWidth maxWidth="md">
      <DialogTitle>
        {cardCompanyName} {mccType}업종 할부 예약 정보
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={() => {
          closeModal?.();
        }}
        sx={{
          position: "absolute",
          right: 8,
          top: 12
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <DialogContentText>할부 예약 정보를 추가하세요.</DialogContentText>
        <form onSubmit={handleSubmit(onValid)}>
          <Grid container mt={1} spacing={2}>
            <Grid item xs={8}>
              <LocalizationProvider
                adapterLocale={koLocale}
                dateAdapter={AdapterDateFns}
              >
                <Controller
                  name="reservationDate"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <DatePicker
                      label="예약 날짜"
                      inputFormat="yyyy-MM-dd"
                      value={value}
                      onChange={(value) => onChange(getDate(value))}
                      renderInput={(param) => (
                        <TextField fullWidth size="small" required {...param} />
                      )}
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={4}>
              <Controller
                name="month"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    label="할부 개월"
                    required
                    fullWidth
                    size="small"
                    select
                    value={value}
                    onChange={onChange}
                  >
                    {Array.from({ length: 23 }, (v, i) => i + 2).map((item) => (
                      <MenuItem key={item} value={item}>
                        {item} 개월
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
            </Grid>
            <Grid item xs={4}>
              <Controller
                name="isAllFree"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <RadioGroup row value={value} onChange={onChange}>
                    <FormControlLabel
                      label="무이자"
                      value="1"
                      control={<Radio checked={isCheckedAllFree === "1"} />}
                    />
                    <FormControlLabel
                      label="부분 무이자"
                      value="0"
                      control={<Radio checked={isCheckedAllFree === "0"} />}
                    />
                  </RadioGroup>
                )}
              />
            </Grid>
            {isCheckedAllFree === "0" && (
              <>
                <Grid item xs={4}>
                  <TextField
                    label="최소금액"
                    size="small"
                    fullWidth
                    {...register("partialPrice")}
                    onInput={numberCommaValidate}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">원</InputAdornment>
                      )
                    }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    label="고객 부담(월)"
                    size="small"
                    type="number"
                    fullWidth
                    InputProps={{
                      inputProps: {
                        min: 1,
                        max: 22
                      }
                    }}
                    {...register("customerMonth")}
                  />
                </Grid>
              </>
            )}
            <Grid item xs={12}>
              <Stack justifyContent="center" alignItems="flex-end" my={1}>
                <Button type="submit" variant="outlined" endIcon={<AddIcon />}>
                  추가하기
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </form>
        <Divider>목록</Divider>
        {viewList.length > 0 ? (
          viewList.map((list, index) => (
            <Grid
              key={`${list.cardCompanyInstallmentId || index}-${
                list.reservationDate
              }`}
              container
              mt={1}
            >
              <Grid item xs={2}>
                <Typography component="strong">{list.month} 개월</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography>예약 날짜: {list.reservationDate}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography>
                  {list.isAllFree
                    ? "무이자"
                    : `부분 무이자 (고객 부담: ${
                        list.customerMonth
                      } 개월, 최소 금액: ${addCommas(list.partialPrice)} 원)`}
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <Button
                  onClick={() => deleteList(list.reservationDate, list.month)}
                  variant="outlined"
                >
                  삭제
                </Button>
              </Grid>
            </Grid>
          ))
        ) : (
          <DialogContentText mt={1}>
            할부 예약 정보가 없습니다.
          </DialogContentText>
        )}
      </DialogContent>
      <DialogActions>
        <MuiLoadingButton
          variant="contained"
          sx={{
            color: "white"
          }}
          fullWidth
          loading={isLoading}
          onClick={handleClickButon}
        >
          저장하기
        </MuiLoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default CardInstallmentReservationModal;
