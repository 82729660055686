import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Pagination from "@mui/material/Pagination";
import Paper from "@mui/material/Paper";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { getTempMerchantList } from "../../api/Merchants";
import { UsersListParams } from "../../api/Merchants/types";
import RegistMerechantModal from "../../components/AdminRegistMerchant/RegistMerechantModal";
import RegistTempMerechantModal from "../../components/AdminRegistMerchant/RegistTempMerechantModal";
import AdminLayout from "../../components/Layout/Admin/Layout";
import Title from "../../components/Title";
import useRenewal from "../../hooks/useRenewal";

interface SelectedDataType {
  userId?: number;
  isModalOpen: boolean;
  created: string;
  jsonString: string;
  userEmail: string;
}

const RegistMerchant = () => {
  const { isRenewalTokenLoading, mutateRenewalToken } = useRenewal();
  // ===================================================================================================================
  // 컴포넌트 스테이트
  // ===================================================================================================================
  // 가맹점 등록 모달 스테이트
  const [isRegistModalOpen, setIsRegistModalOpen] = useState(false);

  // 임시 저장 모달 스테이트
  const [selectedData, setSelectedData] = useState<SelectedDataType>({
    isModalOpen: false,
    created: "",
    jsonString: "",
    userEmail: ""
  });

  // 파라미터 스테이트
  const [params, setParams] = useState<UsersListParams>({
    email: "",
    isUse: "",
    page: 0,
    size: 0,
    sort: "ASC",
    hasMerchant: false
  });

  // ===================================================================================================================
  // 리액트 쿼리
  // ===================================================================================================================
  // 임시 저장 데이터 조회
  const { data, isLoading, refetch } = useQuery(
    ["/admin/temporaries"],
    () => getTempMerchantList("ADMIN"),

    {
      onSuccess: () => {
        if (isRenewalTokenLoading) return;
        mutateRenewalToken();
      }
    }
  );

  // ===================================================================================================================
  // 모달창 열기, 닫기
  // ===================================================================================================================
  // 모달창 열기
  const selectData = (data: any) => {
    setSelectedData({ ...data, isModalOpen: true });
  };

  // 모달 닫기 및 데이터 새로고침 함수
  const closeModal = () => {
    setIsRegistModalOpen(false);
    setSelectedData({
      isModalOpen: false,
      created: "",
      jsonString: "",
      userEmail: ""
    });
    refetch();
  };

  // ===================================================================================================================
  // 페이징 처리
  // ===================================================================================================================
  const totalPages = data?.pageable?.totalPages
    ? data?.pageable?.totalPages
    : 0;
  const changePage = (event: React.ChangeEvent<unknown>, page: number) => {
    setParams((prev) => ({ ...prev, page: page - 1 }));
  };

  // ===================================================================================================================
  // 데이터 파싱
  // ===================================================================================================================
  // 데이터 파싱 함수
  const parseData = (dataString: string) => {
    try {
      return JSON.parse(dataString);
    } catch (error) {
      console.error("데이터 파싱 오류:", error);
      return null;
    }
  };

  const parsedDetailData = selectedData.jsonString
    ? parseData(selectedData.jsonString)
    : "";

  return (
    <>
      <Title title="가맹점 등록" />
      <AdminLayout>
        {/* 사용자 등록 버튼 -------------------------------------------------------------------------------------*/}
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"end"}
          mb={1}
        >
          <Typography sx={{ fontWeight: "bold" }}>가맹점 등록</Typography>
          <Button
            variant="contained"
            color="secondary"
            sx={{ color: "white" }}
            onClick={() => setIsRegistModalOpen(true)}
          >
            가맹 등록
          </Button>
        </Stack>
        <Paper
          sx={{
            p: 3,
            border: "1px solid #F2F3F5",
            borderRadius: 3
          }}
        >
          {data?.content.length === 0 ? (
            <>
              <Stack
                height={550}
                alignItems={"center"}
                justifyContent={"center"}
              >
                <Typography>조회된 데이터가 없습니다.</Typography>
              </Stack>
            </>
          ) : (
            <>
              {!data && isLoading && (
                <Grid container spacing={3} sx={{ px: 3, mb: 3 }}>
                  {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((index) => (
                    <Grid item xs={12} sm={4} key={index}>
                      <Box
                        sx={{
                          backgroundColor: "#f2f2f2",
                          p: 2,
                          borderRadius: 2
                        }}
                      >
                        <Skeleton variant="text" width={"60%"} height={30} />
                        <Skeleton variant="text" width={"80%"} height={30} />
                        <Skeleton variant="text" width={"40%"} height={30} />
                      </Box>
                    </Grid>
                  ))}
                </Grid>
              )}
              {data && data.content.length > 0 && (
                <Grid container spacing={3}>
                  {data.content.map((d) => {
                    const parsedData = JSON.parse(d.jsonString);
                    return (
                      <Grid item xs={12} sm={4} key={d.id}>
                        <Stack
                          sx={{
                            backgroundColor: "#f7f7f7",
                            p: 2,
                            gap: 1,
                            cursor: "pointer",
                            border: "1px solid #E4E4E7",
                            borderRadius: 2
                          }}
                          onClick={() => selectData(d)}
                        >
                          <Typography variant="subtitle2">
                            {d.created.split("T")[0]}
                          </Typography>
                          <Typography variant="body2">
                            가맹점명: {parsedData.mallName}
                          </Typography>
                          <Typography variant="body2">
                            사용자 이메일: {d.userEmail}
                          </Typography>
                        </Stack>
                      </Grid>
                    );
                  })}
                </Grid>
              )}
            </>
          )}

          {/* 가맹 등록 모달창 */}
          <RegistMerechantModal
            openModal={isRegistModalOpen}
            onClose={closeModal}
          />

          {/* 임시 저장 상세 데이터 모달창 */}
          <RegistTempMerechantModal
            openModal={selectedData.isModalOpen}
            closeModal={closeModal}
            detailData={parsedDetailData}
            userId={selectedData.userId ?? 0}
          />

          <Box sx={{ pt: 3 }}>
            <Pagination
              count={totalPages}
              variant="outlined"
              shape="rounded"
              page={params.page + 1}
              onChange={changePage}
              sx={{ display: "flex", justifyContent: "center" }}
            ></Pagination>
          </Box>
        </Paper>
      </AdminLayout>
    </>
  );
};
export default RegistMerchant;
