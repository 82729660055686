import { atom } from "recoil";
import {
  PurchaseDetailParams,
  PurchaseTotalParams
} from "../../api/v2/Purchases/types";
import { getToday } from "../../libs/get-today";

/**
 * 승인.취소내역 리코일 스테이트
 */
const PurchasesState = atom<PurchaseTotalParams>({
  key: "PurchasesState",
  default: {
    startDate: getToday(),
    endDate: getToday(),
    cardCompany: "기본",
    status: "DEFAULT",
    mallId: "",
    mallName: "",
    paymentKey: "",
    approvalNum: ""
  }
});

/**
 * 승인.취소내역 + 페이지 리코일 스테이트
 */
export const PurchasesDetailState = atom<PurchaseDetailParams>({
  key: "PurchasesDetailState",
  default: {
    page: 0,
    pageSize: 50,
    startDate: getToday(),
    endDate: getToday(),
    cardCompany: "기본",
    status: "DEFAULT",
    mallId: "",
    mallName: "",
    paymentKey: "",
    approvalNum: "",
    sort: "DESC"
  }
});
export default PurchasesState;
