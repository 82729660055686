import { instanceWithAuth } from "../../Instance";
import { CommonApi } from "../../type";
import {
  PurchaseCardDetailData,
  PurchaseCardTotalData,
  PurchaseDetailData,
  PurchaseDetailParams,
  PurchaseTotalData,
  PurchaseTotalParams
} from "./types";

// =================================================================================================
// 승인.취소내역 - version 2
// =================================================================================================
/**
 * 승인.취소내역 가맹점별 합계 테이터 조회
 */
export const getPurchasesTotal = async (params: PurchaseTotalParams) => {
  const url = "/purchase/total/merchant";
  const { data } = await instanceWithAuth.get<CommonApi<PurchaseTotalData[]>>(
    url,
    { params }
  );
  return data;
};

/**
 * 승인.취소내역 카드사별 합계 데이터 조회
 */
export const getPurchasesCardTotal = async (params: PurchaseTotalParams) => {
  const url = "/purchase/total/card";
  const { data } = await instanceWithAuth.get<
    CommonApi<PurchaseCardTotalData[]>
  >(url, { params });
  return data;
};

/**
 * 승인.취소내역 카드사별 상세 합계 데이터 조회
 */
export const getPurchasesCardDetail = async (params: PurchaseDetailParams) => {
  const url = "/purchase/card/detail";
  const { data } = await instanceWithAuth.get<CommonApi<PurchaseCardDetailData[]>>(
    url,
    { params }
  );
  return data;
};

/**
 * 승인.취소내역 카드사별 상세 엑셀 데이터 조회
 */
export const getPurchasesCardDetailExcel = async (
  params: PurchaseTotalParams
) => {
  const url = "/purchase/card/detail/excel";
  const { data } = await instanceWithAuth.get<CommonApi<PurchaseCardDetailData[]>>(
    url,
    { params }
  );
  return data;
};

/**
 * 매입내역 데이터 조회
 */
export const getPurchasesDetail = async (params: PurchaseDetailParams) => {
  const url = "/purchase/detail";
  const { data } = await instanceWithAuth.get<CommonApi<PurchaseDetailData[]>>(
    url,
    { params }
  );
  return data;
};

/**
 * 매입내역 엑셀 데이터 조회
 */
export const getPurchasesDetailExcel = async (params: PurchaseTotalParams) => {
  const url = "/purchase/detail/excel";
  const { data } = await instanceWithAuth.get<CommonApi<PurchaseDetailData[]>>(
    url,
    { params }
  );
  return data;
};
