import Box from "@mui/material/Box";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useQuery } from "@tanstack/react-query";
import { useSetRecoilState } from "recoil";
import {
  getLeyutaePaymentsDetail,
  getLeyutaePaymentsExcelDetail
} from "../../api/Leyutae";
import { PaymentDetailData } from "../../api/v2/Payments/types";
import { addCommas } from "../../libs/thousands-commas";
import isModalOpenAtom from "../../recoil/isModalOpen";
import MuiTable from "../Table/MuiTable";
import { LeyutaePaymentsParams } from "../../api/Leyutae/types";
import excelDownload from "../../libs/excel-download";
import LoadingButton from "../LoadingButton";
import SaveIcon from "@mui/icons-material/Save";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

interface PaymentColumnHeadCell {
  id: keyof PaymentDetailData;
  label: string;
  isTotalData: boolean;
  isSort: boolean;
}

const columns: PaymentColumnHeadCell[] = [
  {
    id: "createdAt",
    label: "Transaction Date",
    isTotalData: false,
    isSort: false
  },
  {
    id: "approvalNum",
    label: "Approval Number",
    isTotalData: false,
    isSort: false
  },
  {
    id: "cardCompany",
    label: "Card",
    isTotalData: false,
    isSort: false
  },
  {
    id: "status",
    label: "Transaction Status",
    isTotalData: false,
    isSort: false
  },
  {
    id: "price",
    label: "Transaction Amount",
    isTotalData: false,
    isSort: false
  },
  {
    id: "purchaseStatus",
    label: "Settlement Status",
    isTotalData: false,
    isSort: false
  },
  {
    id: "receiveDate",
    label: "Settlement Date",
    isTotalData: false,
    isSort: false
  },
  {
    id: "transactionKey",
    label: "Transaction Key",
    isTotalData: false,
    isSort: false
  },
  {
    id: "paymentKey",
    label: "Payment Key",
    isTotalData: false,
    isSort: false
  }
];

interface ReyutaeTransactionDetailProps {
  params: LeyutaePaymentsParams;
  setParams: any;
}

const ReyutaeTransactionDetail = ({
  setParams,
  params
}: ReyutaeTransactionDetailProps) => {
  // ===================================================================================================================
  // 리코일 스테이트
  // ===================================================================================================================
  const setIsModalOpen = useSetRecoilState(isModalOpenAtom);

  // ===================================================================================================================
  // 리액트 쿼리 - 거래 상세 조회
  // ===================================================================================================================
  const { data } = useQuery(
    ["/leyutae/transaction/details", params],
    () => getLeyutaePaymentsDetail({ ...params }),
    {
      keepPreviousData: true,
      onError: (error: any) => {
        setIsModalOpen({
          value: true,
          position: "top",
          alertSeverity: "error",
          message: error?.response?.data?.message
        });
      },
      select: (data) => ({
        ...data,
        content: data.content.map((paymentData: PaymentDetailData) => ({
          ...paymentData,
          price: `${addCommas(Number(paymentData.price))}`
        }))
      })
    }
  );

  // ===================================================================================================================
  // 리액트 쿼리 - 엑셀 다운로드
  // ===================================================================================================================
  const { isFetching, refetch } = useQuery(
    ["/payments/detail/excel", params],
    () => getLeyutaePaymentsExcelDetail(params),
    {
      enabled: false,
      onSuccess: (data) => {
        const content = data?.content;
        const list = content.map((d: PaymentDetailData) => ({
          "Transaction Date": d.createdAt,
          "Approval Number": d.approvalNum,
          Card: d.cardCompany,
          "Transaction Status": d.status,
          "Transaction Amount": `${addCommas(Number(d.price))}`,
          "Settlement Status": d.purchaseStatus,
          "Settlement Date": d.receiveDate,
          "Transaction Key": d.transactionKey,
          "Payment Key": d.paymentKey
        }));
        excelDownload({
          list: list,
          sheetName: "Leyutae_payment_excel_data",
          fileName: "Leyutae_payment_excel_data.xlsx"
        });
        setIsModalOpen({
          value: true,
          position: "top",
          alertSeverity: "success",
          message: "Downloading..."
        });
      },
      onError: (error: any) => {
        setIsModalOpen({
          value: true,
          position: "top",
          alertSeverity: "error",
          message: error?.response?.data?.message
        });
      }
    }
  );

  // ===================================================================================================================
  // 거래 상세 조회 - 엑셀 다운로드
  // ===================================================================================================================
  const handlePaymentsDetailExcel = async () => {
    if (!data?.content || data?.content?.length === 0) {
      setIsModalOpen({
        value: true,
        position: "top",
        alertSeverity: "error",
        message: "No data"
      });
    } else {
      await refetch();
    }
  };

  // ===================================================================================================================
  // 페이징
  // ===================================================================================================================
  const totalPages = data?.pageable?.totalPages
    ? data?.pageable?.totalPages
    : 0;
  const handlePage = (event: React.ChangeEvent<unknown>, page: number) => {
    setParams((prevValue: any) => ({ ...prevValue, page: page - 1 }));
  };

  return (
    <>
      <Stack direction={"row"} justifyContent={"space-between"} sx={{ pb: 1 }}>
        <Typography sx={{ fontWeight: "bold" }}>Transaction history</Typography>
        <LoadingButton
          icon={<SaveIcon />}
          size="medium"
          fullWidth={false}
          color="secondary"
          variant="outlined"
          loading={isFetching}
          handleClick={handlePaymentsDetailExcel}
        >
          DOWNLOAD EXCEL
        </LoadingButton>
      </Stack>

      {/* 공통 컴포넌트 테이블  */}
      <MuiTable
        rows={data?.content}
        columns={columns}
        id="id"
        redlineKey="status"
        redlineValue={["Cancelled", "부분취소"]}
        totalData={false}
        // sortingTarget={"createdAt"}
        // sortDirection={false}
      />

      <Box mt={2}>
        <Pagination
          count={totalPages}
          variant="outlined"
          shape="rounded"
          page={params.page + 1}
          onChange={handlePage}
          sx={{ display: "flex", justifyContent: "center" }}
        />
      </Box>
      {/* 엑셀 다운로드 중일 때  */}
      {isFetching && (
        <Backdrop open={isFetching} sx={{ zIndex: 10 }}>
          <CircularProgress
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              ml: 25,
              zIndex: 10
            }}
          />
        </Backdrop>
      )}
    </>
  );
};
export default ReyutaeTransactionDetail;
