import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { BrowserRouter as Router } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import { RecoilRoot } from "recoil";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import './fonts/font.css'; 

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      onError: (error: any) => {
        const errorCode = error?.response?.status;
        if (errorCode === 401) {
          sessionStorage.removeItem("loginInfo");
          window.location.replace("/login");
        }
      }
    }
  }
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <RecoilRoot>
        <HelmetProvider>
          <Router>
            <App />
          </Router>
        </HelmetProvider>
      </RecoilRoot>
      <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
    </QueryClientProvider>
  </React.StrictMode>
);
