import SaveIcon from "@mui/icons-material/Save";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import { useSetRecoilState } from "recoil";
import excelDownload from "../../libs/excel-download";
import isModalOpenAtom from "../../recoil/isModalOpen";
import LoadingButton from "../LoadingButton";

interface Props {
  mallName: string;
  isLoading: any;
  data: any;
}

const columns = [
  {
    id: "mallId",
    label: "가맹점 ID"
  },
  {
    id: "mallName",
    label: "가맹점명"
  },
  {
    id: "paidOutDate",
    label: "거래일자"
  },
  {
    id: "transactionTotal",
    label: "거래합계 (건수)"
  },
  {
    id: "completeTotal",
    label: "승인합계 (건수)"
  },
  {
    id: "cancelTotal",
    label: "취소합계 (건수)"
  },
  {
    id: "merchantFee",
    label: "가맹점 수수료"
  },
  {
    id: "vat",
    label: "부가세"
  },
  {
    id: "commissionFee",
    label: "총수수료"
  },
  {
    id: "settlementPrice",
    label: "정산금액"
  },
  {
    id: "actualAmount",
    label: "정산예정금액"
  }
];

const ClientSettlementTotal = ({ isLoading, data, mallName }: Props) => {
  // ===================================================================================================================
  // 리코일 스테이트
  // ===================================================================================================================
  const setIsModalOpen = useSetRecoilState(isModalOpenAtom);

  // ===================================================================================================================
  // 총 합계 엑셀 다운로드
  // ===================================================================================================================
  const handleTotalSettlementExcel = () => {
    const content = data?.content;
    // 거래합계 데이터가 "(0)"을 포함할 경우 함수 종료
    if (!content || content.transactionTotal.includes("(0)")) {
      setIsModalOpen({
        value: true,
        position: "top",
        alertSeverity: "error",
        message: "데이터가 없습니다."
      });
      return;
    } else {
      setIsModalOpen({
        value: true,
        position: "top",
        alertSeverity: "success",
        message: "다운로드를 시작합니다."
      });
    }
    const list = [
      {
        가맹점Id: content.mallId,
        가맹점명: content.mallName,
        거래일자: content.paidOutDate,
        거래합계: content.transactionTotal,
        승인합계: content.completeTotal,
        취소합계: content.cancelTotal
      }
    ];
    excelDownload({
      list,
      sheetName:
        mallName === "전체" ? "정산내역 총합계" : `정산내역 총합계_${mallName}`,
      fileName:
        mallName === "전체"
          ? "정산내역 총합계.xlsx"
          : `정산내역 총합계_${mallName}.xlsx`
    });
  };

  return (
    <Box>
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"end"}
        mb={1}
      >
        <Typography sx={{ fontWeight: "bold" }}>총합계</Typography>
        <LoadingButton
          icon={<SaveIcon />}
          size="medium"
          fullWidth={false}
          color="secondary"
          variant="outlined"
          loading={false}
          handleClick={handleTotalSettlementExcel}
        >
          EXCEL 다운로드
        </LoadingButton>
      </Stack>
      <Paper
        sx={{
          border: "1px solid #F2F3F5",
          borderRadius: 3,
          overflow: "hidden"
        }}
      >
        <TableContainer>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    variant="head"
                    align="center"
                    key={column.id}
                    sx={{ backgroundColor: "#FBFBFB" }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                {data?.content && !isLoading ? (
                  columns.map((column) => (
                    <TableCell
                      align="center"
                      key={column.id}
                      sx={{ borderTop: "none", borderBottom: "none" }}
                    >
                      {data.content[column.id]}
                    </TableCell>
                  ))
                ) : (
                  <>
                    {columns.map((column, index) => (
                      <TableCell key={index}>
                        <Skeleton variant="rounded" animation="wave" />
                      </TableCell>
                    ))}
                  </>
                )}
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Box>
  );
};

export default ClientSettlementTotal;
