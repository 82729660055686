import { instanceWithAuth } from "./Instance";
import { useMutation } from "@tanstack/react-query";

export const verifyOtp = async (otpCode: string) => {
  const { data } = await instanceWithAuth.post("/otp/verify", otpCode);
  return data;
};

export const renewLogin = async (_: any) => {
  const { data } = await instanceWithAuth.post("/users/renewal");
  return data;
};

export const useRenewLogin = () => {
  return useMutation(renewLogin);
};

export const getUserInfo = async () => {
  const { data } = await instanceWithAuth.get("/users/information");
  return data;
};
