import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import MuiLoadingButton from "@mui/lab/LoadingButton";

interface DeleteModalProps {
  dialogTitle?: string;
  closeModal?: () => void;
  onButtonSubmit: () => void;
  loading?: boolean;
  open?: boolean;
}

const DeleteModal = ({
  open = true,
  dialogTitle = "삭제",
  closeModal,
  onButtonSubmit,
  loading = false
}: DeleteModalProps) => {
  return (
    <Dialog open={open} maxWidth="xs" fullWidth>
      <DialogTitle sx={{ wordWrap: "break-word" }}>{dialogTitle}</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={() => {
          closeModal?.();
        }}
        sx={{
          position: "absolute",
          right: 8,
          top: 12
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <DialogContentText gutterBottom>
          삭제하면 복구할 수 없습니다.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <MuiLoadingButton
          variant="contained"
          onClick={onButtonSubmit}
          sx={{ color: "white" }}
          fullWidth
          loading={loading}
        >
          삭제하기
        </MuiLoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteModal;
