import { instanceWithAuth } from "../Instance";
import { CommonApi } from "../type";
import {
  ClientSettlementDetailData,
  ClientSettlementParams,
  ClientSettlementsData,
  ClientSettlementsDetailParams,
  ClientSettlementsTotalData
} from "./types";

// =================================================================================================
// 클라이언트 정산내역
// =================================================================================================
/**
 * 사용자 정산내역 데이터 조회
 */
export const getClientSettlement = async (params: ClientSettlementParams) => {
  const url = "/client/settlements";
  const { data } = await instanceWithAuth.get<
    CommonApi<ClientSettlementsData[]>
  >(url, {
    params
  });
  return data;
};

/**
 * 사용자 정산내역 총합계 데이터 조회
 */
export const getClientSettlementTotal = async (params: ClientSettlementParams) => {
  const url = "/client/settlement/total";
  const { data } = await instanceWithAuth.get<
    CommonApi<ClientSettlementsTotalData>
  >(url, { params });
  return data;
};

/**
 * 사용자 정산내역 개별 내역 데이터 조회
 */
export const getClientSettlementDetail = async (
  params: ClientSettlementsDetailParams
) => {
  const url = "/client/settlements/detail";
  const { data } = await instanceWithAuth.get<
    CommonApi<ClientSettlementDetailData[]>
  >(url, {
    params
  });
  return data;
};

/**
 * 사용자 정산내역 개별 내역 엑셀 데이터 조회
 */
export const getClientSettlementDetailExcel = async (
  params: any
) => {
  const url = "/client/settlements/detail/excel";
  const { data } = await instanceWithAuth.get<
    CommonApi<ClientSettlementDetailData[]>
  >(url, {
    params
  });
  return data;
};
