import { instance } from "../Instance";
import type { CommonApi } from "../type";
import type { RegisterParams, RSA } from "./types";

export const getRSA = async () => {
  const { data } = await instance.get<CommonApi<RSA>>("/bypass/rsa/public-key");
  return data;
};

export const registerUser = async (registerFormData: RegisterParams) => {
  const { data } = await instance.post<CommonApi<any>>(
    "/bypass/join/user",
    registerFormData
  );
  return data;
};

interface CodeParams {
  email: string;
}

export const sendCode = async (params: CodeParams) => {
  const { data } = await instance.post("bypass/send/authenticate-code", params);
  return data;
};

interface VerifyCodeParams {
  code: string;
  email: string;
}

export const verifyCode = async (params: VerifyCodeParams) => {
  const { data } = await instance.post(
    "bypass/verify/authenticate-code",
    params
  );
  return data;
};
