import LoginIcon from "@mui/icons-material/Login";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { useMutation, useQuery } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link as ReactLink, useNavigate } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { PostLeyutaelogin } from "../api/Leyutae";
import { getRSA, login } from "../api/Login";
import LoadingButton from "../components/LoadingButton";
import Title from "../components/Title";
import ozicpayText from "../images/ozicpay_logo_text.png";
import { encrypting } from "../libs/encryping-password";
import isModalOpenAtom from "../recoil/isModalOpen";
import loginInfoAtom from "../recoil/loginInfo";

interface LoginForm {
  email: string;
  password: string;
}

const LeyutaeEmails = ["pay@1xbet-team.com"];

const Login = () => {
  const navigate = useNavigate();

  // ===================================================================================================================
  // 스테이트
  // ===================================================================================================================
  const setIsModalOpen = useSetRecoilState(isModalOpenAtom);
  const setLoginInfo = useSetRecoilState(loginInfoAtom);

  // ===================================================================================================================
  // 스테이트
  // ===================================================================================================================
  const [isHide, setIsHide] = useState(true);
  const [isSaveId, setIsSaveId] = useState(false);

  // ===================================================================================================================
  // 리액트 훅 폼
  // ===================================================================================================================
  const { register, handleSubmit, resetField, setValue, getValues } =
    useForm<LoginForm>();

  // ===================================================================================================================
  // 리액트 쿼리
  // ===================================================================================================================
  const { mutate, isLoading: isLoginLoading } = useMutation(login, {
    onError: (error: any) => {
      setIsModalOpen({
        value: true,
        position: "top",
        alertSeverity: "error",
        message: error?.response?.data?.message
      });
      resetField("password");
    },
    onSuccess: (data, variables, context) => {
      const {
        content: { loginUser, accessToken, createdTime }
      } = data;
      setLoginInfo({ loginUser, accessToken, createdTime });
      // if (loginUser.firstLogin === "Y") {
      //   navigate("/otp-setting", {
      //     state: {
      //       qr: loginUser.otpQrUrl,
      //       secret: loginUser.otpSecretKey
      //     },
      //     replace: true
      //   });
      // } else {
      //   navigate("/otp", { replace: true });
      // }
      navigate("/email", { replace: true });
    }
  });

  // TODO: 이 부분은 나중에 지울거에요
  // 레유태 가맹점 로그인
  const { mutate: leyutaeMutate, isLoading: leyutaeLoading } = useMutation(
    PostLeyutaelogin,
    {
      onError: (error: any) => {
        setIsModalOpen({
          value: true,
          position: "top",
          alertSeverity: "error",
          message: error?.response?.data?.message
        });
        resetField("password");
      },
      onSuccess: (data, variables, context) => {
        const {
          content: { loginUser, accessToken, createdTime }
        } = data;
        setLoginInfo({ loginUser, accessToken, createdTime });
        sessionStorage.setItem(
          "loginInfo",
          JSON.stringify({ loginUser, accessToken, createdTime })
        ); // 세션 스토리지에 저장
        navigate("/1xbet", { replace: true });
      }
    }
  );

  const { isFetching: isRSAFeching, refetch } = useQuery(["rsa"], getRSA, {
    enabled: false,
    onSuccess: (res) => {
      const { email, password } = getValues();
      const encryptedPassword = encrypting({
        password,
        publicKey: res?.content?.public_key
      });
      if (!encryptedPassword) return;

      // TODO: 나중에 지워야 하는 코드 - 레유태 가맹점 계정
      // 이메일이 레유태 가맹점 이메일인 경우 새로운 로그인 api를 호출
      if (LeyutaeEmails.includes(email)) {
        leyutaeMutate({
          email,
          encryptedPassword,
          publicKey: res?.content?.public_key
        });
      } else {
        mutate({
          email,
          encryptedPassword,
          publicKey: res?.content?.public_key
        });
      }
    },
    onError: (error: any) => {
      setIsModalOpen({
        value: true,
        position: "top",
        alertSeverity: "error",
        message: error?.response?.data?.message
      });
    }
  });

  const handleLoginForm = async (data: LoginForm) => {
    const { email } = data;

    if (isRSAFeching || isLoginLoading || leyutaeLoading) return;
    refetch();
    if (isSaveId) {
      localStorage.setItem("savedEmail", email);
    } else {
      localStorage.removeItem("savedEmail");
    }
  };
  const handleIsSaveId = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsSaveId(event.target.checked);
  };

  useEffect(() => {
    const email = localStorage.getItem("savedEmail");
    if (Boolean(email)) {
      setIsSaveId(true);
      setValue("email", email ? email : "");
    }
  }, [setValue]);

  const handleHideButton = () => {
    setIsHide((prevValue) => !prevValue);
  };

  // 레유태 가맹점 이메일인 경우 에러 토스트를 띄웁니다
  const handlePasswordResetClick = (
    event: React.MouseEvent<HTMLAnchorElement>
  ) => {
    const email = getValues("email");
    if (email === "pay@1xbet-team.com") {
      event.preventDefault();
      setIsModalOpen({
        value: true,
        position: "top",
        alertSeverity: "error",
        message: "This account is not allowed to reset the password."
      });
    }
  };

  return (
    <>
      <Title title="로그인" />
      <Box
        sx={{
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          px: 2
        }}
      >
        <Paper
          sx={{
            maxWidth: 500,
            width: "100%",
            py: 10,
            px: 8,
            border: "1px solid #F2F3F5",
            borderRadius: 3
          }}
        >
          <Stack
            sx={{
              marginBottom: 5
            }}
            alignItems="center"
          >
            <Box
              component="img"
              src={ozicpayText}
              alt="ozicpay logo"
              sx={{ width: "50%" }}
            />
          </Stack>
          <form onSubmit={handleSubmit(handleLoginForm)}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  label="Email"
                  fullWidth
                  required
                  {...register("email", { required: true })}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Password"
                  fullWidth
                  required
                  type={isHide ? "password" : "text"}
                  autoComplete="on"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={handleHideButton}>
                          {isHide ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                  {...register("password", { required: true })}
                />
              </Grid>
            </Grid>
            <FormControlLabel
              label="아이디 저장하기"
              control={
                <Checkbox checked={isSaveId} onChange={handleIsSaveId} />
              }
            />
            <Box
              sx={{
                marginTop: 5
              }}
            >
              <LoadingButton
                icon={<LoginIcon />}
                fullWidth={true}
                size="large"
                loading={isRSAFeching || isLoginLoading}
              >
                로그인
              </LoadingButton>
            </Box>
            <Stack direction="row" justifyContent="flex-end" spacing={2} mt={2}>
              <Link
                component={ReactLink}
                to="/register"
                color="inherit"
                underline="none"
                sx={{
                  padding: 0.5,
                  "&:hover": { fontWeight: "600" }
                }}
              >
                회원가입
              </Link>
              <Link
                component={ReactLink}
                to="/password/temp"
                color="inherit"
                underline="none"
                sx={{
                  padding: 0.5,
                  "&:hover": { fontWeight: "600" }
                }}
                onClick={handlePasswordResetClick}
              >
                비밀번호 찾기
              </Link>
            </Stack>
          </form>
        </Paper>
      </Box>
    </>
  );
};

export default Login;
