import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import MuiLoadingButton from "@mui/lab/LoadingButton";
import DialogContentText from "@mui/material/DialogContentText";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import MoreTimeIcon from "@mui/icons-material/MoreTime";
import useRenewal from "../../hooks/useRenewal";

interface Props {
  onClose: () => void;
}

const TimeExtensionModal = ({ onClose }: Props) => {
  const { mutateRenewalToken, isRenewalTokenLoading } = useRenewal();
  const handleRenewalToken = () => {
    if (isRenewalTokenLoading) return;
    mutateRenewalToken(undefined, {
      onSuccess: () => {
        onClose();
      }
    });
  };
  return (
    <Dialog open={true} maxWidth="xs" fullWidth>
      <DialogTitle>로그인 시간 연장</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={() => {
          onClose();
        }}
        sx={{
          position: "absolute",
          right: 8,
          top: 12
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <DialogContentText>로그인 시간을 연장하시겠습니까?</DialogContentText>
      </DialogContent>
      <DialogActions>
        <MuiLoadingButton
          color="primary"
          loading={isRenewalTokenLoading}
          fullWidth={true}
          variant="contained"
          endIcon={<MoreTimeIcon />}
          loadingPosition="end"
          sx={{
            color: "white"
          }}
          onClick={handleRenewalToken}
        >
          시간 연장
        </MuiLoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default TimeExtensionModal;
