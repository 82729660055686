const passwordStrengthCheck = (value: string): string | number => {
  // 빈값이 들어오면 레벨이 0
  //레벨이 1일 때는 영어 대문자, 소문자, 숫자, 특수문자(!@#$%^&*)중에 하나만 있음
  //레벨이 2일 때는 2개조합이 있음
  //레벨이 3일 때는 조합이 2일 땐 길이가 10자 이상이거나, 조합이 3개일 땐 길이가 8자 이상임
  let 비밀번호_문자종류 = 0;
  const checkCapitalLetter = /[A-Z]/;
  const checkSmallLetter = /[a-z]/;
  const checkNumber = /[0-9]/;
  const checkSpecialCharacter = /[~!@#$%^&*()]/;

  if (checkCapitalLetter.test(value)) 비밀번호_문자종류++;
  if (checkSmallLetter.test(value)) 비밀번호_문자종류++;
  if (checkNumber.test(value)) 비밀번호_문자종류++;
  if (checkSpecialCharacter.test(value)) 비밀번호_문자종류++;

  if (비밀번호_문자종류 === 2 && value.length >= 10) return "성공";
  if (비밀번호_문자종류 > 2 && value.length >= 8) return "성공";

  if (비밀번호_문자종류 === 0) return 0;
  if (비밀번호_문자종류 === 1) return 1;
  return 2;
};

export default passwordStrengthCheck;
