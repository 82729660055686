import RestartAltIcon from "@mui/icons-material/RestartAlt";
import SearchIcon from "@mui/icons-material/Search";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Pagination from "@mui/material/Pagination";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { useQuery } from "@tanstack/react-query";
import koLocale from "date-fns/locale/ko";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useSetRecoilState } from "recoil";
import { getMerchantModifyList } from "../../api/ContractManagement";
import ContractManagementModal from "../../components/ContractManagement/ContractManagementModal";
import AdminLayout from "../../components/Layout/Admin/Layout";
import LoadingButton from "../../components/LoadingButton";
import Title from "../../components/Title";
import useRenewal from "../../hooks/useRenewal";
import { getDate } from "../../libs/get-date";
import { getToday } from "../../libs/get-today";
import isModalOpenAtom from "../../recoil/isModalOpen";

interface Columns {
  id: string;
  label: string;
}

const columns: Columns[] = [
  { id: "mallName", label: "가맹점 명" },
  { id: "businessName", label: "사업자 등록증 상호명" },
  { id: "createdAt", label: "신청일" },
  { id: "status", label: "진행 상태" },
  { id: "note", label: "비고" }
];

interface FormInputs {
  mallName: string;
  startDate: string;
  endDate: string;
  status: string;
}

const MerchantRequestList = () => {
  const { isRenewalTokenLoading, mutateRenewalToken } = useRenewal();

  // ===================================================================================================================
  // 리코일 스테이트
  // ===================================================================================================================
  const setIsModalOpen = useSetRecoilState(isModalOpenAtom);

  // ===================================================================================================================
  // 컴포넌트 스테이트
  // ===================================================================================================================
  // params 스테이트
  const [params, setParams] = useState<any>({
    mallId: "",
    startDate: getToday(),
    endDate: getToday(),
    status: "",
    page: 0,
    size: 10
  });

  // 어떤 row가 클릭되었는지 추적하는 스테이트
  const [selectedData, setSelectedData] = useState({
    setIsModalOpen: false,
    modifyId: "",
    mallName: "",
    startDate: "",
    endDate: "",
    status: "전체"
  });

  // ===================================================================================================================
  // 리액트 훅 폼
  // ===================================================================================================================
  const { register, handleSubmit, control, reset } = useForm<FormInputs>({
    defaultValues: {
      ...params
    }
  });

  // ===================================================================================================================
  // 리액트 쿼리
  // ===================================================================================================================
  // 가맹점 정보 수정 요청 목록 조회
  const { data, isLoading, refetch } = useQuery(
    ["/merchants/modifies", params],
    () => getMerchantModifyList(params),
    {
      onSuccess: () => {
        // refresh();
        if (isRenewalTokenLoading) return;
        mutateRenewalToken();
      }
    }
  );
  // 리액트 쿼리 refresh
  const refresh = () => {
    refetch();
  };

  // ===================================================================================================================
  // 페이징 처리
  // ===================================================================================================================
  const totalPages = data?.pageable?.totalPages
    ? data?.pageable?.totalPages
    : 0;
  const changePage = (event: React.ChangeEvent<unknown>, page: number) => {
    setParams((prev: any) => ({ ...prev, page: page - 1 }));
  };

  // ===================================================================================================================
  // 가맹점 정보 수정 신청 현황 조회 및 초기화
  // ===================================================================================================================
  // 초기화
  const handleReset = () => {
    reset();
  };

  // 조회
  const onClickSubmit = (formData: FormInputs) => {
    if (isLoading) return;
    const isInvalidData =
      formData.startDate?.includes("NaN") || formData.endDate?.includes("NaN");
    const isInvaildPeriod = formData.startDate > formData.endDate;
    if (isInvalidData || isInvaildPeriod) {
      setIsModalOpen({
        value: true,
        position: "top",
        alertSeverity: "error",
        message: "조회 일자를 확인해주세요."
      });
      return;
    }
    setParams((prev: any) => ({
      ...prev,
      ...formData,
      status: formData.status === "전체" ? "" : formData.status
    }));
  };

  // row를 클릭할 때 해당 row의 modifyId를 selectedRow상태로 변경
  const handleRowClick = (row: any) => {
    setSelectedData({ ...row, setIsModalOpen: true });
  };

  const onClose = () => {
    setSelectedData({
      setIsModalOpen: false,
      modifyId: "",
      mallName: "",
      startDate: "",
      endDate: "",
      status: "전체"
    });
    refresh();
  };

  return (
    <>
      <Title title="정보 수정 관리" />
      <AdminLayout>
        <Paper
          elevation={0}
          sx={{
            p: 3,
            border: "1px solid #F2F3F5",
            borderRadius: 3,
            mb: 3
          }}
        >
          <form onSubmit={handleSubmit(onClickSubmit)}>
            <Grid container spacing={2}>
              <LocalizationProvider
                adapterLocale={koLocale}
                dateAdapter={AdapterDateFns}
              >
                <Grid item xs={2}>
                  <Controller
                    name="startDate"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <DatePicker
                        label="조회 시작 날짜"
                        inputFormat="yyyy-MM-dd"
                        value={value}
                        onChange={(value) => onChange(getDate(value))}
                        renderInput={(param) => (
                          <TextField
                            fullWidth
                            size="small"
                            required
                            {...param}
                          />
                        )}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={2}>
                  <Controller
                    name="endDate"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <DatePicker
                        label="조회 종료 날짜"
                        inputFormat="yyyy-MM-dd"
                        value={value}
                        onChange={(value) => onChange(getDate(value))}
                        renderInput={(param) => (
                          <TextField
                            fullWidth
                            size="small"
                            required
                            {...param}
                          />
                        )}
                      />
                    )}
                  />
                </Grid>
              </LocalizationProvider>
              <Grid item xs={12} md={2}>
                <TextField
                  id="input-mallId"
                  label="가맹점명"
                  fullWidth
                  size="small"
                  {...register("mallName")}
                />
              </Grid>
              <Grid item xs={2}>
                <Controller
                  name="status"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      id="input-status"
                      label="진행 상태"
                      fullWidth
                      size="small"
                      select
                      value={value || "전체"}
                      onChange={onChange}
                    >
                      <MenuItem value={"전체"}>전체</MenuItem>
                      <MenuItem value={"신청"}>신청</MenuItem>
                      <MenuItem value={"승인"}>승인</MenuItem>
                      <MenuItem value={"거절"}>거절</MenuItem>
                    </TextField>
                  )}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Stack
                  direction="row"
                  justifyContent="flex-end"
                  alignItems="center"
                  spacing={1}
                >
                  <LoadingButton
                    icon={<SearchIcon />}
                    size="medium"
                    fullWidth={false}
                    loading={false}
                  >
                    조회
                  </LoadingButton>
                  <Button
                    variant="outlined"
                    endIcon={<RestartAltIcon />}
                    onClick={handleReset}
                  >
                    조건 초기화
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </form>
        </Paper>

        <Typography variant="body1" sx={{ mb: 1, fontWeight: "bold" }}>
          정보 수정 관리
        </Typography>
        <Paper
          sx={{
            border: "1px solid #F2F3F5",
            borderRadius: 3,
            overflow: "hidden"
          }}
        >
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  {columns.map((column: any) => (
                    <TableCell
                      variant="head"
                      key={column.id}
                      align="center"
                      sx={{ background: "#FBFBFB" }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {(!data || data.content.length === 0) && !isLoading ? (
                  <TableRow>
                    <TableCell sx={{ height: 550 }} colSpan={7}>
                      <Typography align="center">
                        조회된 데이터가 없습니다.
                      </Typography>
                    </TableCell>
                  </TableRow>
                ) : (
                  <>
                    {data &&
                      data.content.length > 0 &&
                      data.content.map((row) => (
                        <TableRow
                          key={row.modifyId}
                          hover={true}
                          onClick={() => handleRowClick(row)}
                          sx={{ cursor: "pointer" }}
                        >
                          <TableCell align="center">{row.mallName}</TableCell>
                          <TableCell align="center">
                            {row.businessName}
                          </TableCell>
                          <TableCell align="center">{row.createdAt}</TableCell>
                          <TableCell align="center">{row.status}</TableCell>
                          <TableCell align="center">{row.note}</TableCell>
                        </TableRow>
                      ))}
                  </>
                )}
              </TableBody>
            </Table>
          </TableContainer>

          {data && (
            <ContractManagementModal
              modifyId={selectedData.modifyId}
              isOpen={selectedData.setIsModalOpen}
              onClose={onClose}
              status={selectedData.status}
            />
          )}

          <Box my={2}>
            <Pagination
              count={totalPages}
              variant="outlined"
              shape="rounded"
              page={params.page + 1}
              onChange={changePage}
              sx={{ display: "flex", justifyContent: "center" }}
            ></Pagination>
          </Box>
        </Paper>
      </AdminLayout>
    </>
  );
};
export default MerchantRequestList;
