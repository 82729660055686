/**
 * 숫자를 파라미터로 받아서 3자리 마다 콤마를 찍은 문자열로 변환해 주는 함수
 *
 * 예를 들어 1234567890 이 입력되었다면,
 *  "1,234,567,890" 이 반환 된다
 *
 * @param number
 * @returns string
 */
export function addCommas(number: number) {
  try {
    return number.toLocaleString("ko-KR");
  } catch (e) {
    console.error(e);
    return NaN;
  }
}

/**
 * 콤마가 찍힌 문자열을 받아서 콤마를 제거해 주는 함수
 *
 * 예를 들어 "1,234,567,890" 이 입력되었다면,
 *  "1234567890" 이 반환 된다
 *
 * @param numberString
 * @returns string
 */
export function removeCommas(numberString: string) {
  return numberString.replace(/,/g, "");
}
