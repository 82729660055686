export const getToday = () => {
  const d = new Date();
  const month =
    d.getMonth() + 1 > 9 ? `${d.getMonth() + 1}` : `0${d.getMonth() + 1}`;
  const day = d.getDate() > 9 ? `${d.getDate()}` : `0${d.getDate()}`;
  return `${d.getFullYear()}-${month}-${day}`;
};

export const getTodayKor = () => {
  const d = new Date();
  const month =
    d.getMonth() + 1 > 9 ? `${d.getMonth() + 1}` : `0${d.getMonth() + 1}`;
  const day = d.getDate() > 9 ? `${d.getDate()}` : `0${d.getDate()}`;
  return `${d.getFullYear()}년 ${month}월 ${day}일`;
};
