import React from "react";
import MuiLoadingButton from "@mui/lab/LoadingButton";

interface ILoadingButton {
  children: React.ReactNode;
  icon: React.ReactNode;
  fullWidth: boolean;
  size: "small" | "medium" | "large";
  loading: boolean;
  variant?: "contained" | "outlined" | "text";
  color?: "primary" | "secondary" | "success" | "error" | "info" | "warning";
  handleClick?: () => void;
  disabled?: boolean;
}

const LoadingButton = ({
  children,
  icon,
  fullWidth = false,
  size = "small",
  loading,
  variant = "contained",
  color = "primary",
  disabled = false,
  handleClick
}: ILoadingButton) => {
  return (
    <MuiLoadingButton
      variant={variant}
      endIcon={icon}
      loadingPosition="end"
      fullWidth={fullWidth}
      size={size}
      loading={loading}
      type={handleClick ? "button" : "submit"}
      data-testid="loading-button"
      color={color}
      onClick={handleClick}
      disabled={disabled}
      sx={{
        color: color === "primary" && variant === "contained" ? "white" : color
      }}
    >
      {children}
    </MuiLoadingButton>
  );
};
export default LoadingButton;
