import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";

const PrivacyPolicy = () => {
  return (
    <>
      <Typography component="h3" sx={{ fontWeight: "bold", mb: 3 }}>
        개인정보 수집 및 이용동의
      </Typography>
      <Typography sx={{ mb: 3 }}>
        ㈜ 옵타움플랫폼(이하 "회사")은 가맹점(이하 “고객사”)의 개인정보를
        중요시하며 개인정보 보호법, 정보통신망 이용촉진 및 정보보호 등에 관한
        법률 등 관계법령을 준수하고 있습니다. “회사”는 개인정보처리방침을 통하여
        “고객사” 의 개인정보가 어떠한 용도와 방식으로 이용되고 있으며
        개인정보보호를 위해 어떠한 조치가 취해지고 있는지 알려드립니다.
      </Typography>

      <Box className="ol" component="ol">
        <Typography component="li" sx={{ fontWeight: "bold", mb: 1 }}>
          개인정보의 수집∙이용목적
        </Typography>
        <Typography sx={{ mb: 1 }}>
          “회사”는 다음과 같은 목적으로 ”고객사”의 개인정보 항목을 수집 및
          이용하고 있습니다.
        </Typography>
        <Box component="ol">
          <Typography component="li" sx={{ mb: 1 }}>
            계약의 성립, 유지 및 종료를 위한 본인식별 및 실명확인
          </Typography>
          <Typography component="li" sx={{ mb: 1 }}>
            원활한 서비스 이용을 위한 결제기관에의 정보제공
          </Typography>
          <Typography component="li" sx={{ mb: 1 }}>
            서비스의 추가와 변경내역에 대한 공지, 고객민원 및 문의에 대한 대응
          </Typography>
          <Typography component="li" sx={{ mb: 1 }}>
            부정 이용방지 및 비인가 사용방지
          </Typography>
          <Typography component="li" sx={{ mb: 3 }}>
            입금 알림 서비스 제공 및 월별 정산내역 안내
          </Typography>
        </Box>

        <Typography component="li" sx={{ fontWeight: "bold", mb: 1 }}>
          수집하는 개인정보의 항목
        </Typography>
        <Box component="ol">
          <Typography component="li" sx={{ mb: 1 }}>
            담당자정보(이름, 이메일 주소, 휴대폰번호), 대금 입금계좌정보(은행명,
            계좌번호, 예금주)
          </Typography>
          <Typography component="li" sx={{ mb: 3 }}>
            대표자 정보(이름, 국적, 휴대전화번호, 이메일, 생년월일, 주민번호)
          </Typography>
        </Box>

        <Typography component="li" sx={{ fontWeight: "bold", mb: 1 }}>
          개인정보의 보유·이용기간
        </Typography>
        <Typography sx={{ mb: 3 }}>
          개인정보는 원칙적으로 개인정보의 수집∙이용목적이 달성되면 지체 없이
          파기 합니다. 단, 전자금융거래법, 전자상거래 등에서의 소비자 보호에
          관한 법률 등 관계법령에 의하여 보존할 필요가 있는 경우 당해 법령에서
          정한 기간 동안 개인정보를 보존합니다. 이 경우 “회사”는 보존하는 정보를
          그 보존의 목적으로만 이용합니다.
        </Typography>
        <Table
          sx={{
            backgroundColor: "white",
            mb: 3
          }}
        >
          <TableHead>
            <TableRow
              sx={{
                backgroundColor: "#d8d8d8",
                "&:hover": {
                  backgroundColor: "#d8d8d8"
                }
              }}
            >
              <TableCell
                sx={{ borderRight: "1px solid rgba(224, 224, 224, 1)" }}
              >
                내용
              </TableCell>
              <TableCell
                sx={{ borderRight: "1px solid rgba(224, 224, 224, 1)" }}
              >
                근거 법령
              </TableCell>
              <TableCell>보존 기간</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell
                sx={{ borderRight: "1px solid rgba(224, 224, 224, 1)" }}
              >
                계약 또는 청약철회 등에 관한 기록
              </TableCell>
              <TableCell
                rowSpan={4}
                sx={{ borderRight: "1px solid rgba(224, 224, 224, 1)" }}
              >
                전자상거래 등에서의 소비자보호에 관한 법률
              </TableCell>
              <TableCell>5 년</TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                sx={{ borderRight: "1px solid rgba(224, 224, 224, 1)" }}
              >
                대금결제 및 재화 등의 공급에 관한 기록
              </TableCell>
              <TableCell>5 년</TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                sx={{ borderRight: "1px solid rgba(224, 224, 224, 1)" }}
              >
                소비자의 불만 또는 분쟁처리에 관한 기록
              </TableCell>
              <TableCell>3 년</TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                sx={{ borderRight: "1px solid rgba(224, 224, 224, 1)" }}
              >
                표시•광고에 관한 기록
              </TableCell>
              <TableCell>6 개월</TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                sx={{ borderRight: "1px solid rgba(224, 224, 224, 1)" }}
              >
                신용정보 업무처리에 관한 기록
              </TableCell>
              <TableCell
                sx={{ borderRight: "1px solid rgba(224, 224, 224, 1)" }}
              >
                신용정보의 이용 및 보호에 관한 법률
              </TableCell>
              <TableCell>3 년</TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <Typography component="h3" sx={{ fontWeight: "bold" }}>
          고객사”는 ”회사”의 개인정보 수집·이용 동의를 거부할 수 있습니다. 단,
          동의를 거부하는 경우 서비스신청이 정상적으로 완료될 수 없음을
          알려드립니다.
        </Typography>
      </Box>

      <style>{`
        .ol {
          counter-reset: list;
        }
        .ol > li {
          list-style: none;
        }
        .ol > li:before {
          content: counter(list) ") ";
          counter-increment: list;
        }
      `}</style>
    </>
  );
};
export default PrivacyPolicy;
