import { ErrorOption, FieldErrors } from "react-hook-form";

export const makeFileFormData = (fileData?: any, merchantData?: any) => {
  const formData = new FormData();
  formData.append(
    "merchantForm",
    new Blob([JSON.stringify(merchantData)], { type: "application/json" })
  );
  formData.append("businessLicense", fileData.businessLicense);
  formData.append("bankBookCopy", fileData.bankBookCopy);
  formData.append("sealCertificate", fileData.sealCertificate);
  formData.append("idCardFront", fileData.idCardFront);
  formData.append("idCardBack", fileData.idCardBack);
  formData.append("corporateRegistration", fileData.corporateRegistration);
  formData.append("actualOwnerConfirmation", fileData.actualOwnerConfirmation);
  formData.append(
    "corporateSealCertificate",
    fileData.corporateSealCertificate
  );
  formData.append("gradeConfirmation", fileData.gradeConfirmation);

  return formData;
};

/** 파일 유무 검사 함수 */
export const fileRequiredValidation = (
  fileType: string,
  setError: (
    name: any,
    error: ErrorOption,
    options?: { shouldFocus: boolean }
  ) => void,
  file?: File
) => {
  if (!file) {
    switch (fileType) {
      case "businessLicense": {
        setError(fileType, {
          type: "required",
          message: "사업자등록증은 반드시 있어야 합니다."
        });
        return true;
      }
      case "bankBookCopy": {
        setError(fileType, {
          type: "required",
          message: "통장사본은 반드시 있어야 합니다."
        });
        return true;
      }
      case "sealCertificate": {
        setError(fileType, {
          type: "required",
          message: "사용인감계는 반드시 있어야 합니다."
        });
        return true;
      }
      case "corporateSealCertificate": {
        setError(fileType, {
          type: "required",
          message: "법인인감증명서는 반드시 있어야 합니다."
        });
        return true;
      }
      case "corporateRegistration": {
        setError(fileType, {
          type: "required",
          message: "등기사항전부증명서는 반드시 있어야 합니다."
        });
        return true;
      }
      case "actualOwnerConfirmation": {
        setError(fileType, {
          type: "required",
          message: "실제소유자확인서는 반드시 있어야 합니다."
        });
        return true;
      }
      case "idCardFront": {
        setError(fileType, {
          type: "required",
          message: "신분증(앞)은 반드시 있어야 합니다."
        });
        return true;
      }
      case "idCardBack": {
        setError(fileType, {
          type: "required",
          message: "신분증(뒤)은 반드시 있어야 합니다."
        });
        return true;
      }
      case "gradeConfirmation": {
        setError(fileType, {
          type: "required",
          message: "영중소 등급확인서는 반드시 있어야 합니다."
        });
        return true;
      }
      default:
        return false;
    }
  } else {
    return false;
  }
};

/** 파일 확장자 확인 함수 */
const checkFileExtension = (fileName: string) => {
  // 파일명 길이
  const fileNameLength = fileName.length;
  // 파일의 확장자 추출 - 뒷자리 부터
  const dotIndex = fileName.lastIndexOf(".");
  // 파일의 확장자 소문자로 변경
  const fileExtension = fileName
    .substring(dotIndex + 1, fileNameLength)
    .toLowerCase()
    .trim();
  const result = ["jpg", "jpeg", "gif", "png", "pdf"].find(
    (extension) => fileExtension === extension
  );
  return !result;
};

/** 파일 확장자 검사 함수 */
export const fileExtensionValidation = (
  fileType: string,
  setError: (
    name: any,
    error: ErrorOption,
    options?: { shouldFocus: boolean }
  ) => void,
  file?: File
) => {
  if (!!file && checkFileExtension(file.name)) {
    setError(fileType, {
      type: "required",
      message: "첨부 파일 종류는 jpg, jpeg, gif, png, pdf 입니다."
    });
    return true;
  } else {
    return false;
  }
};

export interface FileUploadForm {
  // 개인사업자
  businessLicense?: File;
  bankBookCopy?: File;
  sealCertificate?: File;
  idCardFront?: File;
  idCardBack?: File;
  gradeConfirmation?: File;
  사용인감계?: File;

  // 법인사업자
  corporateRegistration?: File;
  actualOwnerConfirmation?: File;
  corporateSealCertificate?: File;
}

/**
 * 파일 선택
 * - 선택된 파일이 유효한지 밸리데이션을 한다
 * - 파일의 이름을 인풋에 세팅한다
 * - 파일을 스테이트에 세팅한다
 * - 만약 에러가 있었다면, 에러를 삭제한다
 */
export const selectFile = (
  e: React.ChangeEvent<HTMLInputElement>,
  fileType: string,
  setValue: (
    name: any,
    value: string,
    options?: Partial<{
      shouldValidate: boolean;
      shouldDirty: boolean;
      shouldTouch: boolean;
    }>
  ) => void,
  setFileData: (value: React.SetStateAction<FileUploadForm>) => void,
  clearErrors: (name: any) => void,
  errors: FieldErrors
) => {
  if (!e.target.files || e.target.files.length < 1) {
    return;
  }
  const file = e.target.files[0];
  const { name } = file;
  setValue(fileType, name);
  setFileData((prev) => {
    switch (fileType) {
      case "businessLicense": {
        return { ...prev, businessLicense: file };
      }
      case "bankBookCopy": {
        return { ...prev, bankBookCopy: file };
      }
      case "sealCertificate": {
        return { ...prev, sealCertificate: file };
      }
      case "corporateRegistration": {
        return { ...prev, corporateRegistration: file };
      }
      case "corporateSealCertificate": {
        return { ...prev, corporateSealCertificate: file };
      }
      case "actualOwnerConfirmation": {
        return { ...prev, actualOwnerConfirmation: file };
      }
      case "idCardFront": {
        return { ...prev, idCardFront: file };
      }
      case "idCardBack": {
        return { ...prev, idCardBack: file };
      }
      case "gradeConfirmation": {
        return { ...prev, gradeConfirmation: file };
      }
      default: {
        return { ...prev };
      }
    }
  });
  if (errors.businessLicense || errors.bankBookCopy) {
    clearErrors(fileType);
  }
  if (errors.sealCertificate) {
    clearErrors(fileType);
  }
  if (errors.idCardFront) {
    clearErrors(fileType);
  }
  if (errors.idCardBack) {
    clearErrors(fileType);
  }
  if (errors.corporateRegistration) {
    clearErrors(fileType);
  }
  if (errors.corporateSealCertificate) {
    clearErrors(fileType);
  }
  if (errors.actualOwnerConfirmation) {
    clearErrors(fileType);
  }
  if (errors.gradeConfirmation) {
    clearErrors(fileType);
  }
};
