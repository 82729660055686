import { format, parseISO } from "date-fns";
import { red, blue } from "@mui/material/colors";

// 알림 중요도
export const translatePriority = (priority: string) => {
  if (priority === "URGENT") {
    return "긴급";
  } else if (priority === "IMPORTANT") {
    return "중요";
  } else {
    return "일반";
  }
};

// 알림 타입
export const translateType = (type: string) => {
  if (type === "NOTICE") {
    return "공지";
  } else if (type === "SETTLEMENT") {
    return "정산";
  } else {
    return "시스템";
  }
};

// 날짜 변환
export const translateDateTime = (dateTime: string) => {
  return format(parseISO(dateTime), "yyyy-MM-dd HH:mm:ss");
};

export const translateCellValue = (columnName: string, cellValue: string) => {
  if (columnName === "type") {
    return translateType(cellValue);
  } else if (columnName === "priority") {
    return translatePriority(cellValue);
  } else if (columnName === "createdAt") {
    return translateDateTime(cellValue);
  } else {
    return cellValue;
  }
};

// ===========================================================================
// 셀 스타일 커스터마이징
// ===========================================================================
const defaultCellStyle = {
  whiteSpace: "nowrap",
  width: "150px"
};
const typeCellStyle = { width: "120px" };
const priorityCellStyle = (cellValue: string) => {
  const style = {
    whiteSpace: "nowrap",
    width: "120px"
  };
  if (cellValue === "URGENT") {
    return {
      ...style,
      color: red[500]
    };
  } else if (cellValue === "IMPORTANT") {
    return {
      ...style,
      color: blue[500]
    };
  } else {
    return style;
  }
};
const messageCellStyle = {
  whiteSpace: "wrap",
  wordWrap: "break-word",
  textAlign: "left",
  minWidth: "300px",
  maxWidth: "300px"
};
export const cellStyle = (columnName: string, cellValue: string) => {
  if (columnName === "message") {
    return messageCellStyle;
  } else if (columnName === "type") {
    return typeCellStyle;
  } else if (columnName === "priority") {
    return priorityCellStyle(cellValue);
  } else {
    return defaultCellStyle;
  }
};

// 작성자 정보 합치기
// 작성자 이름(이메일 아이디)
export const getWriterInfo = (writerName: string, writerEmail: string) => {
  const splitedEmailId = writerEmail.split("@")[0];
  return `${writerName}(${splitedEmailId})`;
};
