import LockIcon from "@mui/icons-material/Lock";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useMutation } from "@tanstack/react-query";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { verifyOtp } from "../api/Otp";
import LoadingButton from "../components/LoadingButton";
import MiddleLogoutBtn from "../components/MiddleLogoutBtn.tsx";
import Title from "../components/Title";
import { verifyJwt } from "../libs/verify-jwt";
import isModalOpenAtom from "../recoil/isModalOpen";
import LoginInfoAtom, {
  loginInfoDefault,
  withToken
} from "../recoil/loginInfo";

export interface OtpForm {
  otpCode: string;
}

const OTP = () => {
  const { register, handleSubmit, reset } = useForm<OtpForm>();
  const navigate = useNavigate();
  const token = useRecoilValue(withToken);
  const [loginInfo, setLoginInfo] = useRecoilState(LoginInfoAtom);
  const { mutate, isLoading } = useMutation(verifyOtp);
  const setIsModalOpen = useSetRecoilState(isModalOpenAtom);

  const handleOtpSubmit = (data: OtpForm) => {
    if (isLoading) return;
    mutate(
      { ...data, token },
      {
        onError: (error: any) => {
          setIsModalOpen({
            value: true,
            position: "top",
            alertSeverity: "error",
            message: error?.response?.data?.message
          });
          reset();
        },
        onSuccess: async (data) => {
          try {
            const {
              payload: { otpYn }
            } = await verifyJwt(data.content.newToken);
            if (!otpYn) {
              setLoginInfo(loginInfoDefault);
              return navigate("/login", {
                replace: true
              });
            }
            const newLoginInfo = {
              ...loginInfo,
              accessToken: data.content.newToken
            };
            setLoginInfo(newLoginInfo);
            sessionStorage.setItem("loginInfo", JSON.stringify(newLoginInfo));
          } catch (e) {
            console.error(e);
            setLoginInfo(loginInfoDefault);
            return navigate("/login", {
              replace: true
            });
          }
          if (loginInfo.loginUser.passwordUpdateFlag === "Y") {
            return navigate("/password", {
              replace: true
            });
          }
          if (loginInfo.loginUser.role === "ADMIN") {
            navigate("/admin", {
              replace: true
            });
          } else {
            navigate("/", {
              replace: true
            });
          }
        }
      }
    );
  };
  useEffect(() => {
    if (!token) {
      return navigate("/login", { replace: true });
    }
  }, [token, navigate]);
  return (
    <>
      <Title title="OTP" />
      <MiddleLogoutBtn />
      <Box
        sx={{
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          px: 2
        }}
      >
        <Paper
          sx={{ maxWidth: 500, width: "100%", py: 10, px: 8 }}
          elevation={4}
        >
          <Typography
            variant="h3"
            component="h2"
            align="center"
            sx={{
              marginBottom: 5
            }}
          >
            OTP
          </Typography>
          <form onSubmit={handleSubmit(handleOtpSubmit)}>
            <Grid container>
              <Grid item xs={12}>
                <TextField
                  label="OTP"
                  fullWidth
                  required
                  helperText="발급받은 OTP를 입력해주세요."
                  {...register("otpCode", {
                    required: "발급받은 OTP를 입력해주세요."
                  })}
                />
              </Grid>
            </Grid>
            <Box
              sx={{
                marginTop: 5
              }}
            >
              <LoadingButton
                icon={<LockIcon />}
                fullWidth={true}
                size="large"
                loading={isLoading}
              >
                입 력
              </LoadingButton>
            </Box>
          </form>
        </Paper>
      </Box>
    </>
  );
};

export default OTP;
