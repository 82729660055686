import { instanceWithAuth } from "../Instance";
import { CommonApi } from "../type";
import {
  CardLimitTotalType,
  CardLimitUpdateParams,
  CardMerchantLimitUpdateParams,
  MerchantCardLimitParams,
  MerchantCardLimitType,
  MerchantListType,
  RegistCardLimitParams
} from "./types";

/**
 * 카드사 전체 한도 목록 조회
 */
export const getCardLimitTotals = async () => {
  const url = "/cardLimitTotals";
  const { data } = await instanceWithAuth.get<CommonApi<CardLimitTotalType[]>>(
    url
  );
  return data;
};

/**
 * 카드사 가맹점별 한도 목록 조회
 */
export const getMerchantCardLimit = async (params: MerchantCardLimitParams) => {
  const url = "/cardLimitGrades";
  const { data } = await instanceWithAuth.get<
    CommonApi<MerchantCardLimitType[]>
  >(url, { params });

  return data;
};

/**
 * 카드사 전체 한도 예약 등록/수정
 */
export const postCardLimit = async (params: CardLimitUpdateParams) => {
  const url = "/cardLimitTotalReservation/save";
  const { data } = await instanceWithAuth.post<CommonApi<boolean>>(url, params);

  return data;
};

/**
 * 카드사 가맹별 한도 등록
 */
export const postMerchantCardLimit = async (params: RegistCardLimitParams) => {
  const url = "/cardLimitGrade/insert";
  const { data } = await instanceWithAuth.post<CommonApi<boolean>>(url, params);

  return data;
};

/**
 * 카드사 가맹별 한도 예약 등록/수정
 */
export const updateMerchantCardLimit = async (
  params: CardMerchantLimitUpdateParams
) => {
  const url = "/cardLimitGradeReservation/save";
  const { data } = await instanceWithAuth.post<CommonApi<boolean>>(url, params);

  return data;
};

/**
 * 가맹점명 목록 조회
 */
export const getMerchantList = async () => {
  const url = "/merchants/list";
  const { data } = await instanceWithAuth.get<CommonApi<MerchantListType[]>>(
    url
  );

  return data;
};
