import CloseIcon from "@mui/icons-material/Close";
import SaveIcon from "@mui/icons-material/Save";
import { Stack } from "@mui/material";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Pagination from "@mui/material/Pagination";
import Paper from "@mui/material/Paper";
import Skeleton from "@mui/material/Skeleton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useQuery } from "@tanstack/react-query";
import { useRecoilState } from "recoil";
import { getCardDetail, getCardsDetailExcel } from "../../api/v2/Payments";
import { CardDetailData } from "../../api/v2/Payments/types";
import excelDownload from "../../libs/excel-download";
import isModalOpenAtom from "../../recoil/isModalOpen";
import { PaymentsDetailState } from "../../recoil/payments/atom";
import LoadingButton from "../LoadingButton";

const columns = [
  {
    id: "cardCompany",
    label: "카드"
  },
  {
    id: "createdAt",
    label: "거래일자"
  },
  {
    id: "totalPayment",
    label: "거래합계 (건수)"
  },
  {
    id: "totalApproval",
    label: "승인합계 (건수)"
  },
  {
    id: "totalCancel",
    label: "취소합계 (건수)"
  }
];

const CardsDetailModal = ({ isOpen, setIsOpen, cardCompany }: any) => {
  // ===================================================================================================================
  // 스테이트
  // ===================================================================================================================
  const [isModalOpen, setIsModalOpen] = useRecoilState(isModalOpenAtom);
  const [cardsDetail, setCardsDetail] = useRecoilState(PaymentsDetailState);

  // ===================================================================================================================
  // 리액트 쿼리 - 카드사별 상세 조회
  
  // ===================================================================================================================
  const { isLoading, data: cardDetailData } = useQuery(
    ["/payments/total/cardCompany/detail", cardsDetail, cardCompany],
    () => getCardDetail({ ...cardsDetail, cardCompany: cardCompany }),
    { enabled: !!cardCompany } //cardCompany가 없으면 조회 안됨
  );

  const { isFetching, refetch } = useQuery(
    ["/payments/total/cardCompany/detail/excel", cardsDetail, cardCompany],
    () => getCardsDetailExcel({ ...cardsDetail, cardCompany: cardCompany }),
    {
      enabled: false,
      onSuccess: (data) => {
        const content = data?.content;
        if (!content || content.length === 0) {
          setIsModalOpen({
            value: true,
            position: "top",
            alertSeverity: "error",
            message: "데이터가 없습니다."
          });
        } else {
          const list = content?.map((d: CardDetailData) => ({
            카드: d.cardCompany,
            거래일자: d.datePeriod,
            "거래합계 (건수)": d.transactionTotal,
            "승인합계 (건수)": d.completeTotal,
            "취소합계 (건수)": d.cancelTotal
          }));
          excelDownload({
            list,
            sheetName:
              cardsDetail.mallName === ""
                ? "거래내역 카드사별 합계 상세 내역"
                : `거래내역 카드사별 합계 상세 내역_${cardsDetail.mallName}`,
            fileName:
              cardsDetail.mallName === ""
                ? "거래내역 카드사별 합계 상세 내역.xlsx"
                : `거래내역 카드사별 합계 상세 내역_${cardsDetail.mallName}.xlsx`
          });
          setIsModalOpen({
            value: true,
            position: "top",
            alertSeverity: "success",
            message: "다운로드를 시작합니다."
          });
        }
      },
      onError: (error) => {
        setIsModalOpen({
          value: true,
          position: "top",
          alertSeverity: "error",
          message: "데이터를 다운로드하는 동안 오류가 발생했습니다."
        });
      }
    }
  );

  // ===================================================================================================================
  // 엑셀 다운로드
  // ===================================================================================================================
  // 거래 상세 내역 - 엑셀 다운로드
  const handleCardDetailExcel = async () => {
    await refetch();
  };

  // ===================================================================================================================
  // 페이지 네이션
  // ===================================================================================================================
  const totalPages = cardDetailData?.pageable?.totalPages
    ? cardDetailData?.pageable?.totalPages
    : 0;
  const handlePage = (event: React.ChangeEvent<unknown>, page: number) => {
    setCardsDetail((prevValue: any) => ({ ...prevValue, page: page - 1 }));
  };

  return (
    <>
      <Dialog open={isOpen} fullWidth maxWidth={"md"}>
        <DialogTitle
          sx={{ boxShadow: "0px 5px 14px -1px rgba(204, 204, 204, 0.5)" }}
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          상세 카드 정보
          <Stack
            direction="row"
            justifyContent="right"
            alignItems="center"
            gap={2}
          >
            <LoadingButton
              icon={<SaveIcon />}
              size="medium"
              fullWidth={false}
              color="secondary"
              variant="outlined"
              loading={isFetching}
              handleClick={handleCardDetailExcel}
            >
              EXCEL 다운로드
            </LoadingButton>
            <IconButton onClick={() => setIsOpen(false)}>
              <CloseIcon />
            </IconButton>
          </Stack>
        </DialogTitle>

        <DialogContent>
          <TableContainer
            sx={{
              border: "1px solid #E4E4E7",
              borderRadius: 2,
              mt: 3,
              borderBottom: "none"
            }}
          >
            <Table>
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align="center"
                      sx={{ backgroundColor: "#FBFBFB" }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {isLoading
                  ? // 로딩 중이면 스켈레톤 표시
                    [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((_, index) => (
                      <TableRow key={index}>
                        {columns.map((column) => (
                          <TableCell key={column.id}>
                            <Skeleton variant="rounded" animation="wave" />
                          </TableCell>
                        ))}
                      </TableRow>
                    ))
                  : // 로딩이 완료되면 데이터 표시
                    cardDetailData?.content &&
                    cardDetailData?.content.map(
                      (card: CardDetailData, index) => (
                        <TableRow key={index}>
                          <TableCell align="center">
                            {card.cardCompany}
                          </TableCell>
                          <TableCell align="center">
                            {card.datePeriod}
                          </TableCell>
                          <TableCell align="center">
                            {card.transactionTotal}
                          </TableCell>
                          <TableCell align="center">
                            {card.completeTotal}
                          </TableCell>
                          <TableCell align="center">
                            {card.cancelTotal}
                          </TableCell>
                        </TableRow>
                      )
                    )}
              </TableBody>
            </Table>
          </TableContainer>
          <Box mt={2}>
            <Pagination
              count={totalPages}
              page={cardsDetail.page + 1}
              onChange={handlePage}
              variant="outlined"
              shape="rounded"
              sx={{ display: "flex", justifyContent: "center" }}
            />
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};
export default CardsDetailModal;
