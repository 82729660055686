import { instanceWithAuth } from "../Instance";
import { CommonApi } from "../type";
import {
  ContractHistoryParams,
  MerchantContractData,
  MerchantContractSaveParams,
  MerchantGradeFeeData,
  MerchantModifyInfoData,
  MerchantModifyListData,
  MerchantModifyListParams
} from "./types";

/**
 * 가맹점 계약 연장 정보 조회
 */

export const getMerchentContractHistory = async (
  params: ContractHistoryParams
) => {
  const url = `/merchant/contract/history/${params.merchantId}`;
  const { data } = await instanceWithAuth.get<
    CommonApi<MerchantContractData[]>
  >(url, {
    params
  });
  return data;
};

/**
 * 가맹점 등급수수료 내역 조회
 */

export const getMerchentGradeFeeHistory = async (
  params: ContractHistoryParams
) => {
  const url = `/merchant/gradeFee/history/${params.merchantId}`;
  const { data } = await instanceWithAuth.get<
    CommonApi<MerchantGradeFeeData[]>
  >(url, {
    params
  });
  return data;
};

/**
 * 가맹점 계약 연장 정보 저장
 */
export const postMerchentContractInfo = async (
  params: MerchantContractSaveParams
) => {
  const url = "/merchant/contract/extension";
  const { data } = await instanceWithAuth.post<CommonApi<boolean>>(url, params);
  return data;
};

/**
 * 가맹점 계약 해지
 */
export const terminateContract = async (merchantId: string) => {
  const url = "/merchant/contract/termination";
  const { data } = await instanceWithAuth.post<CommonApi<boolean>>(url, {
    merchantId
  });
  return data;
};

/**
 * 가맹점 정보 수정 목록 조회
 */
export const getMerchantModifyList = async (
  params: MerchantModifyListParams
) => {
  const url = "/merchant/modifies";
  const { data } = await instanceWithAuth.get<
    CommonApi<MerchantModifyListData[]>
  >(url, { params });
  return data;
};

/**
 * 가맹점 정보 수정 요청 상세 조회
 */
export const getMerchentModify = async (modifyId: string) => {
  const url = `/merchant/modify/${modifyId}`;
  const { data } = await instanceWithAuth.get<
    CommonApi<MerchantModifyInfoData>
  >(url);
  return data;
};

/**
 * 가맹점 정보 수정 수락/거절
 */
export const postMerchantApproval = async (params: any) => {
  const url = `/merchant/modify/approval`;
  const { data } = await instanceWithAuth.post<CommonApi<boolean>>(url, params);
  return data;
};

/**
 * 가맹점 정보 수정 신청 상태 유무 조회
 */
export const getMerchentIsModify = async (merchantId: string) => {
  const url = `/merchant/modify/check/${merchantId}`;
  const { data } = await instanceWithAuth.get<CommonApi<boolean>>(url);
  return data;
};
