import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import LogoImage from "../../images/logo_contract.png";
import Divider from "@mui/material/Divider";
import Fonts from "./Fonts";
import grey from "@mui/material/colors/grey";
import { MerchantsDetail } from "../../api/Merchants/types";

interface PageProps {
  data?: MerchantsDetail;
}

const Page6 = ({ data }: PageProps) => {
  return (
    <Fonts>
      <Box
        sx={{
          width: "210mm",
          height: "297mm",
          backgroundColor: "white",
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <Card
          sx={{
            width: "195mm",
            height: "282mm",
            backgroundColor: "white",
            border: "1px solid black"
          }}
        >
          {/* 머릿글 ======================================================== */}
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            sx={{
              width: "100%",
              height: "15mm"
            }}
          >
            <Stack
              justifyContent={"center"}
              alignItems={"center"}
              sx={{
                width: "120px"
              }}
            >
              {/* <Box component={"img"} src={LogoImage}></Box> */}
            </Stack>
            <Stack
              justifyContent={"center"}
              alignItems={"center"}
              sx={{ fontSize: "18px", fontWeight: 700, color: "#a29fb0" }}
            >
              [부칙 2] 서비스 신청서
            </Stack>
            <Stack
              justifyContent={"center"}
              alignItems={"center"}
              sx={{
                width: "35mm",
                color: "white",
                fontSize: "16px"
              }}
            >
              <Box component={"img"} src={LogoImage}></Box>
            </Stack>
          </Stack>
          <Divider
            sx={{ border: "none", borderTop: "4px solid #a29fb0", mx: 1 }}
          ></Divider>

          {/* 본문 ========================================================== */}
          <Box
            component={"ol"}
            start={3}
            sx={{
              height: "254mm",
              mt: 1,
              px: 1
            }}
          >
            <Typography>
              ※ 이 신청서를 작성하여 신청하시는 것은 계약서의 부속 서류로
              계약서와 동일한 법적 효력이 발생합니다.
            </Typography>
            <Typography>
              ※ 서비스 별 상세설명은 [별첨]을 참조하여 주십시오.
            </Typography>
            <Typography sx={{ mt: 1, fontWeight: 700, fontSize: "8pt" }}>
              1. 고객사 기본정보
            </Typography>
            {/* 사업자 정보 ================================================= */}
            <Stack direction={"row"}>
              <Box
                sx={{
                  width: "45mm",
                  border: "1px dotted black",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center"
                }}
              >
                <Typography>사업자 정보</Typography>
              </Box>
              <Box
                sx={{
                  width: "150mm",
                  border: "1px dotted black",
                  py: 1,
                  backgroundColor: grey[200]
                }}
              >
                <Stack direction={"row"} sx={{ pl: 1 }}>
                  <Stack direction={"row"} sx={{ width: "50mm" }}>
                    <Typography>회사명:</Typography>
                    <Typography sx={{ ml: 1 }}>{data?.mallName}</Typography>
                  </Stack>
                  <Stack direction={"row"} sx={{ width: "100mm" }}>
                    <Typography>| 영문상호:</Typography>
                    <Typography sx={{ ml: 1 }}>
                      {data?.merchantInformation.businessType === "법인"
                        ? data?.mallNameEn
                        : ""}
                    </Typography>
                  </Stack>
                </Stack>
                <Stack direction={"row"} sx={{ pl: 1, mt: 1 }}>
                  <Stack direction={"row"} sx={{ width: "50mm" }}>
                    <Typography>사업자등록번호:</Typography>
                    <Typography sx={{ ml: 1 }}>
                      {data?.merchantInformation.registrationNumber}
                    </Typography>
                  </Stack>
                  <Stack direction={"row"} sx={{ width: "100mm" }}>
                    <Typography>| 법인등록번호:</Typography>
                    <Typography sx={{ ml: 1 }}>
                      {data?.merchantInformation.businessType === "법인"
                        ? data?.merchantInformation.corporationNumber
                        : ""}
                    </Typography>
                  </Stack>
                </Stack>
                <Stack direction={"row"} sx={{ pl: 1, mt: 1 }}>
                  <Typography>사업장 주소:</Typography>
                  <Typography sx={{ ml: 1 }}>
                    {data?.merchantInformation.address.split(" | ").join(", ")}
                  </Typography>
                </Stack>
                <Stack direction={"row"} sx={{ pl: 1, mt: 1 }}>
                  <Typography>본점 주소:</Typography>
                  <Typography sx={{ ml: 1 }}>
                    {data?.merchantInformation.headOfficeAddress
                      .split(" | ")
                      .join(", ")}
                  </Typography>
                </Stack>
                <Stack direction={"row"} sx={{ pl: 1, mt: 1 }}>
                  <Stack direction={"row"} sx={{ width: "50mm" }}>
                    <Typography>대표전화번호:</Typography>
                    <Typography sx={{ ml: 1 }}>
                      {data?.merchantInformation.phone}
                    </Typography>
                  </Stack>
                  <Stack direction={"row"} sx={{ width: "50mm" }}>
                    <Typography>| 대표휴대폰번호:</Typography>
                    <Typography sx={{ ml: 1 }}>
                      {data?.merchantInformation.phone}
                    </Typography>
                  </Stack>
                  <Stack direction={"row"} sx={{ width: "50mm" }}>
                    <Typography>| 팩스번호:</Typography>
                    <Typography sx={{ ml: 1 }}>
                      {data?.merchantInformation.fax}
                    </Typography>
                  </Stack>
                </Stack>
                <Stack direction={"row"} sx={{ pl: 1, mt: 1 }}>
                  <Typography>업태:</Typography>
                  <Typography sx={{ ml: 1 }}>
                    {data?.merchantInformation.businessConditions}
                  </Typography>
                </Stack>
                <Stack direction={"row"} sx={{ pl: 1, mt: 1 }}>
                  <Typography>업종:</Typography>
                  <Typography sx={{ ml: 1 }}>
                    {data?.merchantInformation.merchantCategory}
                  </Typography>
                </Stack>
                <Stack direction={"row"} sx={{ pl: 1, mt: 1 }}>
                  <Stack direction={"row"} sx={{ width: "100mm" }}>
                    <Typography>홈페이지:</Typography>
                    <Typography sx={{ ml: 1 }}>{data?.mallUrl}</Typography>
                  </Stack>
                  <Stack direction={"row"} sx={{ width: "50mm" }}>
                    <Typography>| 가맹점 IP:</Typography>
                    <Typography sx={{ ml: 1 }}>{data?.mallIp}</Typography>
                  </Stack>
                </Stack>
                <Stack direction={"row"} sx={{ pl: 1, mt: 1 }}>
                  <Stack direction={"row"} sx={{ width: "50mm" }}>
                    <Typography>테스트 ID:</Typography>
                    <Typography sx={{ ml: 1 }}>
                      {data?.merchantInformation.testId}
                    </Typography>
                  </Stack>
                  <Stack direction={"row"} sx={{ width: "50mm" }}>
                    <Typography>| 테스트 PW:</Typography>
                    <Typography sx={{ ml: 1 }}>
                      {data?.merchantInformation.testPassword}
                    </Typography>
                  </Stack>
                  <Stack direction={"row"} sx={{ width: "50mm" }}>
                    <Typography>| 호스팅:</Typography>
                    <Typography sx={{ ml: 1 }}>
                      {data?.merchantInformation.introductionCompany}
                    </Typography>
                  </Stack>
                </Stack>
              </Box>
            </Stack>
            {/* 대표자 정보 ================================================= */}
            <Stack direction={"row"}>
              <Box
                sx={{
                  width: "45mm",
                  border: "1px dotted black",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center"
                }}
              >
                <Typography>대표자 정보</Typography>
              </Box>
              <Stack>
                {data?.merchantOwnerInformation.map((owner) => (
                  <Box
                    key={owner.id}
                    sx={{
                      width: "150mm",
                      border: "1px dotted black",
                      py: 1,
                      backgroundColor: grey[200]
                    }}
                  >
                    <Stack direction={"row"} sx={{ pl: 1 }}>
                      <Stack direction={"row"} sx={{ width: "50mm" }}>
                        <Typography>성명:</Typography>
                        <Typography sx={{ ml: 1 }}>{owner.name}</Typography>
                      </Stack>
                      <Stack direction={"row"} sx={{ width: "100mm" }}>
                        <Typography>| 영문성명:</Typography>
                        <Typography sx={{ ml: 1 }}>{owner.nameEn}</Typography>
                      </Stack>
                    </Stack>
                    <Stack direction={"row"} sx={{ pl: 1, mt: 1 }}>
                      <Stack direction={"row"} sx={{ width: "50mm" }}>
                        <Typography>전화번호:</Typography>
                        <Typography sx={{ ml: 1 }}>{owner.phone}</Typography>
                      </Stack>
                      <Stack direction={"row"} sx={{ width: "50mm" }}>
                        <Typography>| 생년월일:</Typography>
                        <Typography sx={{ ml: 1 }}>{owner.birth}</Typography>
                      </Stack>
                      <Stack direction={"row"} sx={{ width: "50mm" }}>
                        <Typography>| 국적:</Typography>
                        <Typography sx={{ ml: 1 }}>
                          {owner.nationality}
                        </Typography>
                      </Stack>
                    </Stack>
                  </Box>
                ))}
              </Stack>
            </Stack>
            {/* 담당자 정보 ================================================= */}
            <Stack direction={"row"}>
              <Box
                sx={{
                  width: "45mm",
                  border: "1px dotted black",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center"
                }}
              >
                <Typography>담당자 정보</Typography>
              </Box>
              <Box
                sx={{
                  width: "150mm",
                  border: "1px dotted black",
                  py: 1,
                  backgroundColor: grey[200]
                }}
              >
                <Stack direction={"row"} sx={{ pl: 1 }}>
                  <Stack direction={"row"} sx={{ width: "50mm" }}>
                    <Typography>성명:</Typography>
                    <Typography sx={{ ml: 1 }}>
                      {data?.merchantInChargeInformation[0].name}
                    </Typography>
                  </Stack>
                  <Stack direction={"row"} sx={{ width: "100mm" }}>
                    <Typography>| 담당역할:</Typography>
                    <Typography sx={{ ml: 1 }}>
                      {data?.merchantInChargeInformation[0].role}
                    </Typography>
                  </Stack>
                </Stack>
                <Stack direction={"row"} sx={{ pl: 1, mt: 1 }}>
                  <Stack direction={"row"} sx={{ width: "50mm" }}>
                    <Typography>전화번호:</Typography>
                    <Typography sx={{ ml: 1 }}>
                      {data?.merchantInChargeInformation[0].phone}
                    </Typography>
                  </Stack>
                  <Stack direction={"row"} sx={{ width: "100mm" }}>
                    <Typography>| 이메일:</Typography>
                    <Typography sx={{ ml: 1 }}>
                      {data?.merchantInChargeInformation[0].email}
                    </Typography>
                  </Stack>
                </Stack>
                <Divider
                  sx={{
                    width: "145mm",
                    border: "none",
                    borderTop: "1px dotted black",
                    mt: 1,
                    mx: 1
                  }}
                ></Divider>
                <Stack direction={"row"} sx={{ pl: 1, mt: 1 }}>
                  <Stack direction={"row"} sx={{ width: "50mm" }}>
                    <Typography>성명:</Typography>
                    <Typography sx={{ ml: 1 }}>
                      {data?.merchantInChargeInformation[1].name}
                    </Typography>
                  </Stack>
                  <Stack direction={"row"} sx={{ width: "100mm" }}>
                    <Typography>| 담당역할:</Typography>
                    <Typography sx={{ ml: 1 }}>
                      {data?.merchantInChargeInformation[1].role}
                    </Typography>
                  </Stack>
                </Stack>
                <Stack direction={"row"} sx={{ pl: 1, mt: 1 }}>
                  <Stack direction={"row"} sx={{ width: "50mm" }}>
                    <Typography>전화번호:</Typography>
                    <Typography sx={{ ml: 1 }}>
                      {data?.merchantInChargeInformation[1].phone}
                    </Typography>
                  </Stack>
                  <Stack direction={"row"} sx={{ width: "100mm" }}>
                    <Typography>| 이메일:</Typography>
                    <Typography sx={{ ml: 1 }}>
                      {data?.merchantInChargeInformation[1].email}
                    </Typography>
                  </Stack>
                </Stack>
                <Divider
                  sx={{
                    width: "145mm",
                    border: "none",
                    borderTop: "1px dotted black",
                    mt: 1,
                    mx: 1
                  }}
                ></Divider>
                <Stack direction={"row"} sx={{ pl: 1, mt: 1 }}>
                  <Stack direction={"row"} sx={{ width: "50mm" }}>
                    <Typography>성명:</Typography>
                    <Typography sx={{ ml: 1 }}>
                      {data?.merchantInChargeInformation[2].name}
                    </Typography>
                  </Stack>
                  <Stack direction={"row"} sx={{ width: "100mm" }}>
                    <Typography>| 담당역할:</Typography>
                    <Typography sx={{ ml: 1 }}>
                      {data?.merchantInChargeInformation[2].role}
                    </Typography>
                  </Stack>
                </Stack>
                <Stack direction={"row"} sx={{ pl: 1, mt: 1 }}>
                  <Stack direction={"row"} sx={{ width: "50mm" }}>
                    <Typography>전화번호:</Typography>
                    <Typography sx={{ ml: 1 }}>
                      {data?.merchantInChargeInformation[2].phone}
                    </Typography>
                  </Stack>
                  <Stack direction={"row"} sx={{ width: "100mm" }}>
                    <Typography>| 이메일:</Typography>
                    <Typography sx={{ ml: 1 }}>
                      {data?.merchantInChargeInformation[2].email}
                    </Typography>
                  </Stack>
                </Stack>
              </Box>
            </Stack>
            {/* 정산정보 ==================================================== */}
            <Stack direction={"row"}>
              <Box
                sx={{
                  width: "45mm",
                  border: "1px dotted black",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center"
                }}
              >
                <Typography>정산 정보</Typography>
              </Box>
              <Box
                sx={{
                  width: "150mm",
                  border: "1px dotted black",
                  py: 1,
                  backgroundColor: grey[200]
                }}
              >
                <Stack direction={"row"} sx={{ pl: 1 }}>
                  <Stack direction={"row"} sx={{ width: "50mm" }}>
                    <Typography>은행:</Typography>
                    <Typography sx={{ ml: 1 }}>
                      {data?.merchantBankInformation.bankName}
                    </Typography>
                  </Stack>
                  <Stack direction={"row"} sx={{ width: "50mm" }}>
                    <Typography>| 예금주:</Typography>
                    <Typography sx={{ ml: 1 }}>
                      {data?.merchantBankInformation.accountHolder}
                    </Typography>
                  </Stack>
                  <Stack direction={"row"} sx={{ width: "50mm" }}>
                    <Typography>| 계좌번호:</Typography>
                    <Typography sx={{ ml: 1 }}>
                      {data?.merchantBankInformation.accountCode}
                    </Typography>
                  </Stack>
                </Stack>
              </Box>
            </Stack>
            {/* 계약 담당자 정보 ============================================= */}
            <Stack direction={"row"}>
              <Stack
                justifyContent={"center"}
                alignItems={"center"}
                sx={{
                  width: "45mm",
                  border: "1px dotted black"
                }}
              >
                <Typography>(주)옵타움플랫폼</Typography>
                <Typography>담당자 정보</Typography>
              </Stack>
              <Box
                sx={{
                  width: "150mm",
                  border: "1px dotted black",
                  py: 1,
                  backgroundColor: grey[200]
                }}
              >
                {/* TODO: 옵타움 담당자 백엔드 수정되면 데이터 연동 필요 */}
                <Stack direction={"row"} sx={{ pl: 1 }}>
                  <Stack direction={"row"} sx={{ width: "50mm" }}>
                    <Typography>성명:</Typography>
                    <Typography sx={{ ml: 1 }}>
                      {data?.merchantInformation.inChargeName}
                    </Typography>
                  </Stack>
                  <Stack direction={"row"} sx={{ width: "100mm" }}>
                    <Typography>| 이메일:</Typography>
                    <Typography sx={{ ml: 1 }}>
                      {data?.merchantInformation.inChargeEmail}
                    </Typography>
                  </Stack>
                </Stack>
                <Stack direction={"row"} sx={{ pl: 1, mt: 1 }}>
                  <Stack direction={"row"}>
                    <Typography>전화번호:</Typography>
                    <Typography sx={{ ml: 1 }}>1577-6281</Typography>
                  </Stack>
                </Stack>
              </Box>
            </Stack>
            <Typography sx={{ mt: 1 }}>
              ※ 정산은행은 개인사업자는 대표자명, 법인사업자는 법인명의의 계좌만
              이용 가능합니다.
            </Typography>
            <Typography>
              ※ 예금주의 경우, 개인사업자는 대표자or상호명, 법인사업자는
              상호명과 일치해야 합니다.
            </Typography>
            <Typography>
              ※ 위 신고사항에 변동이 발생하였을 경우 당사(옵타움플랫폼)에
              제신고하여야 하며, 최신성 유지를 위한 제신고 누락, 지연 등에
              의하여 발생한 사고 등에 대한 귀책사유는 귀사(고객사)에 있습니다.
            </Typography>
            <Stack direction={"row"} alignItems={"center"} spacing={1}>
              <Typography sx={{ fontSize: "10pt" }}>□</Typography>
              <Typography>처리동의</Typography>
            </Stack>
          </Box>

          {/* 바닥글 ======================================================== */}
          <Divider
            sx={{
              border: "none",
              borderTop: "1px dotted",
              borderColor: grey[700],
              mx: 1
            }}
          ></Divider>
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            sx={{ height: "7mm", color: grey[400], mx: 3, mt: 0 }}
          >
            <Typography sx={{ fontSize: "7pt" }}>
              국내 최고의 통합 결제 서비스 OZICPAY
            </Typography>
            <Typography sx={{ fontSize: "7pt" }}>페이지 6 / 9</Typography>
          </Stack>
        </Card>
      </Box>
    </Fonts>
  );
};

export default Page6;
