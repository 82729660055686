import SaveIcon from "@mui/icons-material/Save";
import Paper from "@mui/material/Paper";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import { useState } from "react";
import { useRecoilState, useSetRecoilState } from "recoil";
import { PurchaseCardTotalData } from "../../api/v2/Purchases/types";
import excelDownload from "../../libs/excel-download";
import isModalOpenAtom from "../../recoil/isModalOpen";
import PurchasesState from "../../recoil/purchases/atom";
import LoadingButton from "../LoadingButton";
import CardsDetailModal from "./CardsDetailModal";
import Box from "@mui/material/Box";

interface PurchaseCardTotalColumnHeadCell {
  id: keyof PurchaseCardTotalData;
  label: string;
}

const columns: PurchaseCardTotalColumnHeadCell[] = [
  {
    id: "mallId",
    label: "가맹점 ID"
  },
  {
    id: "mallName",
    label: "가맹점명"
  },
  {
    id: "datePeriod",
    label: "매입일자"
  },
  {
    id: "cardCompanyName",
    label: "카드"
  },
  {
    id: "receiveTotal",
    label: "거래합계 (건수)"
  },
  {
    id: "confirmTotal",
    label: "승인합계 (건수)"
  },
  {
    id: "cancelTotal",
    label: "취소합계 (건수)"
  },
  {
    id: "cardConfirmFee",
    label: "카드사승인 수수료"
  },
  {
    id: "cardCancelFee",
    label: "카드사 취소 수수료"
  },
  {
    id: "purchasePrice",
    label: "입금예정금액"
  }
];
const CardsTotal = ({ data, isLoading }: any) => {
  // ===================================================================================================================
  // 리코일 스테이트
  // ===================================================================================================================
  const setIsModalOpen = useSetRecoilState(isModalOpenAtom);
  const [purchasesTotal, setPurchasesTotal] = useRecoilState(PurchasesState);

  // ===================================================================================================================
  // 스테이트
  // ===================================================================================================================
  const [isOpen, setIsOpen] = useState(false);
  const [selectedCardCompany, setSelectedCardCompany] = useState("");

  // ===================================================================================================================
  // 카드사별 상세 조회 모달창
  // ===================================================================================================================
  const handleCardClick = (cardCompanyName: string) => {
    const cardData = data?.content;
    if (cardData) {
      setSelectedCardCompany(cardCompanyName);
      setIsOpen(true);
    }
  };
  // 테이블 마지막 row는 클릭 x
  const handleTableRowClick = (cardCompany: string, index: number) => {
    if (data?.content && index !== data.content.length - 1) {
      handleCardClick(cardCompany);
    }
  };

  // ===================================================================================================================
  // 카드사별 합계 - 엑셀 다운로드
  // ===================================================================================================================
  const handleCardTotalExcel = () => {
    const content = data?.content;
    if (!content || content.length === 1) {
      setIsModalOpen({
        value: true,
        position: "top",
        alertSeverity: "error",
        message: "데이터가 없습니다."
      });
      return;
    } else {
      const list = content.map((d: PurchaseCardTotalData) => ({
        가맹점Id: d.mallId,
        가맹점명: d.mallName,
        매입일자: d.datePeriod,
        카드: d.cardCompanyName,
        "거래합계(건수)": d.receiveTotal,
        "승인합계(건수)": d.confirmTotal,
        "취소합계(건수)": d.cancelTotal,
        "카드사 승인 수수료": d.cardConfirmFee,
        "카드사 취소 수수료": d.cardCancelFee,
        입금예정금액: d.purchasePrice
      }));
      excelDownload({
        list,
        sheetName:
          purchasesTotal.mallName === ""
            ? "승인·취소 내역 카드사별 합계"
            : `승인·취소 내역 카드사별 합계_${purchasesTotal.mallName}`,
        fileName:
          purchasesTotal.mallName === ""
            ? "승인·취소 내역 카드사별 합계.xlsx"
            : `승인·취소 내역 카드사별 합계_${purchasesTotal.mallName}.xlsx`
      });
      setIsModalOpen({
        value: true,
        position: "top",
        alertSeverity: "success",
        message: "다운로드를 시작합니다."
      });
    }
  };
  return (
    <Box>
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"end"}
        mb={1}
      >
        <Typography sx={{ fontWeight: "bold" }}>카드사별 합계</Typography>
        <LoadingButton
          icon={<SaveIcon />}
          size="medium"
          fullWidth={false}
          color="secondary"
          variant="outlined"
          loading={false}
          handleClick={handleCardTotalExcel}
        >
          EXCEL 다운로드
        </LoadingButton>
      </Stack>

      <Paper
        sx={{
          border: "1px solid #F2F3F5",
          borderRadius: 3,
          overflow: "hidden"
        }}
      >
        <TableContainer>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow sx={{ backgroundColor: "#FBFBFB" }}>
                {columns.map((column) => (
                  <TableCell
                    variant="head"
                    align="center"
                    key={column.id}
                    sx={{ borderTop: "none" }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.content && !isLoading ? (
                <>
                  {data?.content.map(
                    (i: PurchaseCardTotalData, index: number) => (
                      <TableRow
                        key={index}
                        sx={{
                          backgroundColor:
                            index === data.content.length - 1 ? "#FBFBFB" : "",
                          cursor:
                            index !== data.content.length - 1
                              ? "pointer"
                              : "default"
                        }}
                        onClick={() =>
                          handleTableRowClick(i.cardCompanyName, index)
                        }
                      >
                        {columns.map((column) => (
                          <TableCell
                            key={column.id}
                            align="center"
                            sx={{
                              borderBottom:
                                index === data.content.length - 1 ? "none" : ""
                            }}
                          >
                            {i[column.id]}
                          </TableCell>
                        ))}
                      </TableRow>
                    )
                  )}
                </>
              ) : (
                [1, 2, 3, 4, 5, 6, 7, 8, 9].map((index: number) => (
                  <TableRow key={index}>
                    {columns.map((column) => (
                      <TableCell key={column.id}>
                        <Skeleton variant="rounded" animation="wave" />
                      </TableCell>
                    ))}
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      <CardsDetailModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        cardCompany={selectedCardCompany}
      />
    </Box>
  );
};

export default CardsTotal;
