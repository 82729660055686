import Title from "../../components/Title";
import ClientLayout from "../../components/Layout/Client/Layout";
import Stack from "@mui/material/Stack";
import Calendar from "../../components/PaymentCalendar/Calendar";
import Summary from "../../components/PaymentCalendar/Summary";
import { useState } from "react";
import { useRecoilValue } from "recoil";
import loginInfoAtom from "../../recoil/loginInfo";
import { Navigate } from "react-router-dom";

const Dashboard = () => {
  const [currentMonth, setCurrentMonth] = useState<Date>(new Date());

  // ===================================================================================================================
  // 레유태 가맹점 계정이라면 /1xbet url로 페이지 이동 - TODO: 나중에 삭제할 코드 🔨
  // ===================================================================================================================
  const loginInfo = useRecoilValue(loginInfoAtom);
  const LeyutaeEmails = ["pay@1xbet-team.com"];
  if (LeyutaeEmails.includes(loginInfo.loginUser.email)) {
    return <Navigate to="/1xbet" replace={true} />;
  }

  return (
    <>
      <Title title="대시 보드" />
      <ClientLayout>
        <Stack spacing={3}>
          <Summary currentMonth={currentMonth} />
          <Calendar setCurrentMonthEvent={setCurrentMonth} />
        </Stack>
      </ClientLayout>
    </>
  );
};

export default Dashboard;
