import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";

const PrivacyPolicyOthers = () => {
  return (
    <>
      <Typography component="h3" sx={{ fontWeight: "bold", mb: 3 }}>
        개인정보 제3자 제공 및 위탁
      </Typography>
      <Typography sx={{ mb: 3 }}>
        회사는 “고객사”의 동의 없이 “고객사”의 개인정보를 제3자에게 제공하지
        않습니다. 회사는 서비스 이행을 위하여 개인정보를 다음과 같이 제3자에게
        제공하고 있습니다
      </Typography>

      <Box className="ol" component="ol">
        <Typography component="li" sx={{ fontWeight: "bold", mb: 1 }}>
          개인정보의 3자 제공
        </Typography>

        <Table
          sx={{
            backgroundColor: "white",
            mb: 3
          }}
        >
          <TableHead>
            <TableRow
              sx={{
                backgroundColor: "#d8d8d8",
                "&:hover": {
                  backgroundColor: "#d8d8d8"
                }
              }}
            >
              <TableCell
                sx={{ borderRight: "1px solid rgba(224, 224, 224, 1)" }}
              >
                제공목적
              </TableCell>
              <TableCell
                sx={{ borderRight: "1px solid rgba(224, 224, 224, 1)" }}
              >
                제공받는 자
              </TableCell>
              <TableCell
                sx={{ borderRight: "1px solid rgba(224, 224, 224, 1)" }}
              >
                제공정보
              </TableCell>
              <TableCell>보유 및 이용기간</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell
                sx={{ borderRight: "1px solid rgba(224, 224, 224, 1)" }}
              >
                신용카드 결제서비스 등록 및 이용을 위한 결제기관의 정보제공
              </TableCell>
              <TableCell
                sx={{ borderRight: "1px solid rgba(224, 224, 224, 1)" }}
              >
                국내 매입카드사 (비씨, 롯데, 하나, 현대, 국민, 신한, 삼성) 및
                국세청
              </TableCell>
              <TableCell
                sx={{ borderRight: "1px solid rgba(224, 224, 224, 1)" }}
              >
                사업자번호, 상호명, 생년월일, 홈페이지주소, 대표자명, 전화번호,
                주소 담당자명, 담당자휴대폰번호. 담당자 이메일
              </TableCell>
              <TableCell>
                개인정보 이용목적 달성시까지(단 관계법령의 규정에 의해 보존의
                필요가 있는경우 및 사전동를 득한 경우는 해당기간까지)
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                sx={{ borderRight: "1px solid rgba(224, 224, 224, 1)" }}
              >
                대금결제 및 재화 등의 공급에 관한 기록
              </TableCell>
              <TableCell
                sx={{ borderRight: "1px solid rgba(224, 224, 224, 1)" }}
              >
                서울보증보험㈜
              </TableCell>
              <TableCell
                sx={{ borderRight: "1px solid rgba(224, 224, 224, 1)" }}
              >
                사업자번호, 상호명, 생년월일, 홈페이지주소, 대표자명, 전화번호,
                주소 담당자명, 담당자휴대폰번호. 담당자 이메일
              </TableCell>
              <TableCell>
                개인정보 이용목적 달성시까지(단 관계법령의 규정에 의해 보존의
                필요가 있는경우 및 사전동를 득한 경우는 해당기간까지)
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>

        <Typography component="li" sx={{ fontWeight: "bold", mb: 1 }}>
          개인정보의 보유·이용기간
        </Typography>
        <Typography sx={{ mb: 1 }}>
          회사는 “고객사”의 개인정보를 원칙적으로 외부에 제공하지 않습니다.
          다만, 아래의 경우에는 예외로 합니다.
        </Typography>
        <Box component="ol">
          <Typography component="li" sx={{ mb: 1 }}>
            “고객사”들이 사전에 동의한 경우
          </Typography>
          <Typography component="li" sx={{ mb: 1 }}>
            법령의 규정에 의거하거나, 수사 목적으로 법령에 정해진 절차와 방법에
            따라 수사기관의 요구가 있는 경우
          </Typography>
          <Typography component="li" sx={{ mb: 3 }}>
            도용방지를 위하여 본인확인에 필요한 경우
          </Typography>
        </Box>

        <Typography component="h3" sx={{ fontWeight: "bold" }}>
          고객사”는 ”회사”의 개인정보 수집·이용 동의를 거부할 수 있습니다. 단,
          동의를 거부하는 경우 서비스신청이 정상적으로 완료될 수 없음을
          알려드립니다.
        </Typography>
      </Box>

      <style>{`
        .ol {
          counter-reset: list;
        }
        .ol > li {
          list-style: none;
        }
        .ol > li:before {
          content: counter(list) ") ";
          counter-increment: list;
        }
      `}</style>
    </>
  );
};
export default PrivacyPolicyOthers;
