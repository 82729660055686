import { instanceWithAuth } from "../Instance";
import { format } from "date-fns";
import Axios from "axios";

/**
 * 대시보드 상단 내 상점 현황 조회
 */
export const getTodayStatistics = async (searchDate: Date) => {
  const url = `dashboard/todayStatistics`;
  const params = {
    searchYearMonth: format(searchDate, "yyyy-MM")
  };
  const {
    data: { content }
  } = await instanceWithAuth.post(url, params);

  return content;
};

/**
 * 대시 보드 하단 캘린더 조회
 */
export const getDailyStatistics = async (searchDate: Date) => {
  const url = `dashboard/dailyStatistics`;
  const params = {
    searchYearMonth: format(searchDate, "yyyy-MM")
  };
  const {
    data: { content }
  } = await instanceWithAuth.post(url, params);
  return content;
};

export const getHolidays = async (date: Date) => {
  const year = format(date, "yyyy");
  const month = format(date, "MM");
  const paramString = `solYear=${year}&solMonth=${month}&ServiceKey=${process.env.REACT_APP_OPEN_API_KEY}&_type=json`;
  const url = `https://apis.data.go.kr/B090041/openapi/service/SpcdeInfoService/getHoliDeInfo?${paramString}`;
  const {
    data: {
      response: {
        body: {
          items: { item }
        }
      }
    }
  } = await Axios.create().get(url);
  return item;
};
