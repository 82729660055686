 export const CountryList = 
[
    {
        "value": "GH",
        "text": "가나"
    },
    {
        "value": "GA",
        "text": "가봉"
    },
    {
        "value": "GY",
        "text": "가이아나"
    },
    {
        "value": "GM",
        "text": "감비아"
    },
    {
        "value": "GG",
        "text": "건지 섬"
    },
    {
        "value": "GP",
        "text": "과들루프"
    },
    {
        "value": "GT",
        "text": "과테말라"
    },
    {
        "value": "GU",
        "text": "괌"
    },
    {
        "value": "GD",
        "text": "그레나다"
    },
    {
        "value": "GR",
        "text": "그리스"
    },
    {
        "value": "GL",
        "text": "그린란드"
    },
    {
        "value": "GN",
        "text": "기니"
    },
    {
        "value": "GW",
        "text": "기니비사우"
    },
    {
        "value": "NA",
        "text": "나미비아"
    },
    {
        "value": "NR",
        "text": "나우루"
    },
    {
        "value": "NG",
        "text": "나이지리아"
    },
    {
        "value": "AQ",
        "text": "남극"
    },
    {
        "value": "SS",
        "text": "남수단 "
    },
    {
        "value": "ZA",
        "text": "남아프리카 공화국"
    },
    {
        "value": "NL",
        "text": "네덜란드"
    },
    {
        "value": "NP",
        "text": "네팔"
    },
    {
        "value": "NO",
        "text": "노르웨이"
    },
    {
        "value": "NF",
        "text": "노퍽 섬"
    },
    {
        "value": "NZ",
        "text": "뉴질랜드"
    },
    {
        "value": "NC",
        "text": "뉴칼레도니아(누벨칼레도니)"
    },
    {
        "value": "NU",
        "text": "니우에"
    },
    {
        "value": "NE",
        "text": "니제르"
    },
    {
        "value": "NI",
        "text": "니카라과"
    },
    {
        "value": "KR",
        "text": "대한민국"
    },
    {
        "value": "DK",
        "text": "덴마크"
    },
    {
        "value": "DO",
        "text": "도미니카 공화국"
    },
    {
        "value": "DM",
        "text": "도미니카 연방"
    },
    {
        "value": "DE",
        "text": "독일"
    },
    {
        "value": "TL",
        "text": "동티모르"
    },
    {
        "value": "LA",
        "text": "라오스"
    },
    {
        "value": "LR",
        "text": "라이베리아(리베리아) "
    },
    {
        "value": "LV",
        "text": "라트비아"
    },
    {
        "value": "RU",
        "text": "러시아"
    },
    {
        "value": "LB",
        "text": "레바논"
    },
    {
        "value": "LS",
        "text": "레소토"
    },
    {
        "value": "RE",
        "text": "레위니옹"
    },
    {
        "value": "RO",
        "text": "루마니아"
    },
    {
        "value": "LU",
        "text": "룩셈부르크"
    },
    {
        "value": "RW",
        "text": "르완다"
    },
    {
        "value": "LY",
        "text": "리비아"
    },
    {
        "value": "LT",
        "text": "리투아니아"
    },
    {
        "value": "LI",
        "text": "리히텐슈타인"
    },
    {
        "value": "MG",
        "text": "마다가스카르"
    },
    {
        "value": "MQ",
        "text": "마르티니크"
    },
    {
        "value": "MH",
        "text": "마셜 제도"
    },
    {
        "value": "YT",
        "text": "마요트"
    },
    {
        "value": "MO",
        "text": "마카오"
    },
    {
        "value": "MW",
        "text": "말라위"
    },
    {
        "value": "MY",
        "text": "말레이시아"
    },
    {
        "value": "ML",
        "text": "말리"
    },
    {
        "value": "IM",
        "text": "맨 섬"
    },
    {
        "value": "MX",
        "text": "멕시코"
    },
    {
        "value": "MC",
        "text": "모나코"
    },
    {
        "value": "MA",
        "text": "모로코"
    },
    {
        "value": "MU",
        "text": "모리셔스"
    },
    {
        "value": "MR",
        "text": "모리타니"
    },
    {
        "value": "MZ",
        "text": "모잠비크"
    },
    {
        "value": "ME",
        "text": "몬테네그로"
    },
    {
        "value": "MS",
        "text": "몬트세랫"
    },
    {
        "value": "MD",
        "text": "몰도바"
    },
    {
        "value": "MV",
        "text": "몰디브"
    },
    {
        "value": "MT",
        "text": "몰타"
    },
    {
        "value": "MN",
        "text": "몽골"
    },
    {
        "value": "US",
        "text": "미국"
    },
    {
        "value": "UM",
        "text": "미국령 군소 제도"
    },
    {
        "value": "VI",
        "text": "미국령 버진아일랜드"
    },
    {
        "value": "MM",
        "text": "미얀마"
    },
    {
        "value": "FM",
        "text": "미크로네시아 연방"
    },
    {
        "value": "VU",
        "text": "바누아투"
    },
    {
        "value": "BH",
        "text": "바레인"
    },
    {
        "value": "BB",
        "text": "바베이도스"
    },
    {
        "value": "BS",
        "text": "바하마"
    },
    {
        "value": "BD",
        "text": "방글라데시"
    },
    {
        "value": "BM",
        "text": "버뮤다"
    },
    {
        "value": "BJ",
        "text": "베냉"
    },
    {
        "value": "VE",
        "text": "베네수엘라"
    },
    {
        "value": "VN",
        "text": "베트남"
    },
    {
        "value": "BE",
        "text": "벨기에"
    },
    {
        "value": "BY",
        "text": "벨라루스"
    },
    {
        "value": "BZ",
        "text": "벨리즈"
    },
    {
        "value": "BQ",
        "text": "보네이어"
    },
    {
        "value": "BA",
        "text": "보스니아 헤르체고비나"
    },
    {
        "value": "BW",
        "text": "보츠와나"
    },
    {
        "value": "BO",
        "text": "볼리비아"
    },
    {
        "value": "BI",
        "text": "부룬디"
    },
    {
        "value": "BF",
        "text": "부르키나파소"
    },
    {
        "value": "BV",
        "text": "부베 섬"
    },
    {
        "value": "BT",
        "text": "부탄"
    },
    {
        "value": "MP",
        "text": "북마리아나 제도"
    },
    {
        "value": "MK",
        "text": "북마케도니아"
    },
    {
        "value": "BG",
        "text": "불가리아"
    },
    {
        "value": "BR",
        "text": "브라질"
    },
    {
        "value": "BN",
        "text": "브루나이"
    },
    {
        "value": "WS",
        "text": "사모아"
    },
    {
        "value": "SA",
        "text": "사우디아라비아"
    },
    {
        "value": "GS",
        "text": "사우스조지아 사우스샌드위치 제도"
    },
    {
        "value": "SM",
        "text": "산마리노"
    },
    {
        "value": "ST",
        "text": "상투메 프린시페"
    },
    {
        "value": "BL",
        "text": "생바르텔레미"
    },
    {
        "value": "PM",
        "text": "생피에르 미클롱"
    },
    {
        "value": "EH",
        "text": "서사하라"
    },
    {
        "value": "SN",
        "text": "세네갈"
    },
    {
        "value": "RS",
        "text": "세르비아"
    },
    {
        "value": "SC",
        "text": "세이셸"
    },
    {
        "value": "LC",
        "text": "세인트루시아"
    },
    {
        "value": "MF",
        "text": "세인트마틴"
    },
    {
        "value": "VC",
        "text": "세인트빈센트 그레나딘"
    },
    {
        "value": "KN",
        "text": "세인트키츠 네비스"
    },
    {
        "value": "SH",
        "text": "세인트헬레나"
    },
    {
        "value": "SO",
        "text": "소말리아"
    },
    {
        "value": "SB",
        "text": "솔로몬 제도"
    },
    {
        "value": "SD",
        "text": "수단"
    },
    {
        "value": "SR",
        "text": "수리남"
    },
    {
        "value": "LK",
        "text": "스리랑카"
    },
    {
        "value": "SJ",
        "text": "스발바르 얀마옌"
    },
    {
        "value": "SZ",
        "text": "스와질란드"
    },
    {
        "value": "SE",
        "text": "스웨덴"
    },
    {
        "value": "CH",
        "text": "스위스"
    },
    {
        "value": "ES",
        "text": "스페인"
    },
    {
        "value": "SK",
        "text": "슬로바키아"
    },
    {
        "value": "SI",
        "text": "슬로베니아"
    },
    {
        "value": "SY",
        "text": "시리아"
    },
    {
        "value": "SL",
        "text": "시에라리온"
    },
    {
        "value": "SX",
        "text": "신트 마틴"
    },
    {
        "value": "SG",
        "text": "싱가포르"
    },
    {
        "value": "AE",
        "text": "아랍에미리트"
    },
    {
        "value": "AW",
        "text": "아루바"
    },
    {
        "value": "AM",
        "text": "아르메니아"
    },
    {
        "value": "AR",
        "text": "아르헨티나"
    },
    {
        "value": "AS",
        "text": "아메리칸사모아"
    },
    {
        "value": "IS",
        "text": "아이슬란드"
    },
    {
        "value": "HT",
        "text": "아이티"
    },
    {
        "value": "IE",
        "text": "아일랜드"
    },
    {
        "value": "AZ",
        "text": "아제르바이잔"
    },
    {
        "value": "AF",
        "text": "아프가니스탄"
    },
    {
        "value": "AD",
        "text": "안도라"
    },
    {
        "value": "AL",
        "text": "알바니아"
    },
    {
        "value": "DZ",
        "text": "알제리"
    },
    {
        "value": "AO",
        "text": "앙골라"
    },
    {
        "value": "AG",
        "text": "앤티가 바부다"
    },
    {
        "value": "AI",
        "text": "앵귈라"
    },
    {
        "value": "ER",
        "text": "에리트레아"
    },
    {
        "value": "EE",
        "text": "에스토니아"
    },
    {
        "value": "EC",
        "text": "에콰도르"
    },
    {
        "value": "ET",
        "text": "에티오피아"
    },
    {
        "value": "SV",
        "text": "엘살바도르"
    },
    {
        "value": "GB",
        "text": "영국"
    },
    {
        "value": "VG",
        "text": "영국령 버진아일랜드"
    },
    {
        "value": "IO",
        "text": "영국령 인도양 지역"
    },
    {
        "value": "YE",
        "text": "예멘"
    },
    {
        "value": "OM",
        "text": "오만"
    },
    {
        "value": "AU",
        "text": "오스트레일리아"
    },
    {
        "value": "AT",
        "text": "오스트리아"
    },
    {
        "value": "HN",
        "text": "온두라스"
    },
    {
        "value": "AX",
        "text": "올란드 제도"
    },
    {
        "value": "WF",
        "text": "왈리스 퓌튀나"
    },
    {
        "value": "JO",
        "text": "요르단"
    },
    {
        "value": "UG",
        "text": "우간다"
    },
    {
        "value": "UY",
        "text": "우루과이"
    },
    {
        "value": "UZ",
        "text": "우즈베키스탄"
    },
    {
        "value": "UA",
        "text": "우크라이나"
    },
    {
        "value": "IQ",
        "text": "이라크"
    },
    {
        "value": "IR",
        "text": "이란"
    },
    {
        "value": "IL",
        "text": "이스라엘"
    },
    {
        "value": "EG",
        "text": "이집트"
    },
    {
        "value": "IT",
        "text": "이탈리아"
    },
    {
        "value": "IN",
        "text": "인도"
    },
    {
        "value": "ID",
        "text": "인도네시아"
    },
    {
        "value": "JP",
        "text": "일본"
    },
    {
        "value": "JM",
        "text": "자메이카"
    },
    {
        "value": "ZM",
        "text": "잠비아"
    },
    {
        "value": "JE",
        "text": "저지 섬"
    },
    {
        "value": "GQ",
        "text": "적도 기니"
    },
    {
        "value": "KP",
        "text": "조선민주주의인민공화국"
    },
    {
        "value": "GE",
        "text": "조지아(그루지야)"
    },
    {
        "value": "CF",
        "text": "중앙아프리카 공화국"
    },
    {
        "value": "TW",
        "text": "중화민국(대만)"
    },
    {
        "value": "CN",
        "text": "중화인민공화국"
    },
    {
        "value": "DJ",
        "text": "지부티"
    },
    {
        "value": "GI",
        "text": "지브롤터"
    },
    {
        "value": "ZW",
        "text": "짐바브웨"
    },
    {
        "value": "TD",
        "text": "차드"
    },
    {
        "value": "CZ",
        "text": "체코"
    },
    {
        "value": "CL",
        "text": "칠레"
    },
    {
        "value": "CM",
        "text": "카메룬"
    },
    {
        "value": "CV",
        "text": "카보베르데"
    },
    {
        "value": "KZ",
        "text": "카자흐스탄"
    },
    {
        "value": "QA",
        "text": "카타르"
    },
    {
        "value": "KH",
        "text": "캄보디아"
    },
    {
        "value": "CA",
        "text": "캐나다"
    },
    {
        "value": "KE",
        "text": "케냐"
    },
    {
        "value": "KY",
        "text": "케이맨 제도"
    },
    {
        "value": "KM",
        "text": "코모로"
    },
    {
        "value": "CR",
        "text": "코스타리카"
    },
    {
        "value": "CC",
        "text": "코코스 제도"
    },
    {
        "value": "CI",
        "text": "코트디부아르"
    },
    {
        "value": "CO",
        "text": "콜롬비아"
    },
    {
        "value": "CG",
        "text": "콩고 공화국"
    },
    {
        "value": "CD",
        "text": "콩고 민주 공화국"
    },
    {
        "value": "CW",
        "text": "쿠라카오"
    },
    {
        "value": "CU",
        "text": "쿠바"
    },
    {
        "value": "KW",
        "text": "쿠웨이트"
    },
    {
        "value": "CK",
        "text": "쿡 제도"
    },
    {
        "value": "HR",
        "text": "크로아티아"
    },
    {
        "value": "CX",
        "text": "크리스마스 섬"
    },
    {
        "value": "KG",
        "text": "키르기스스탄"
    },
    {
        "value": "KI",
        "text": "키리바시"
    },
    {
        "value": "CY",
        "text": "키프로스(사이프러스)"
    },
    {
        "value": "TH",
        "text": "타이(태국)"
    },
    {
        "value": "TJ",
        "text": "타지키스탄"
    },
    {
        "value": "TZ",
        "text": "탄자니아"
    },
    {
        "value": "TC",
        "text": "터크스 케이커스 제도"
    },
    {
        "value": "TR",
        "text": "터키"
    },
    {
        "value": "TG",
        "text": "토고"
    },
    {
        "value": "TK",
        "text": "토켈라우"
    },
    {
        "value": "TO",
        "text": "통가"
    },
    {
        "value": "TM",
        "text": "투르크메니스탄"
    },
    {
        "value": "TV",
        "text": "투발루"
    },
    {
        "value": "TN",
        "text": "튀니지"
    },
    {
        "value": "TT",
        "text": "트리니다드 토바고"
    },
    {
        "value": "PA",
        "text": "파나마"
    },
    {
        "value": "PY",
        "text": "파라과이"
    },
    {
        "value": "PK",
        "text": "파키스탄"
    },
    {
        "value": "PG",
        "text": "파푸아 뉴기니"
    },
    {
        "value": "PW",
        "text": "팔라우"
    },
    {
        "value": "PS",
        "text": "팔레스타인"
    },
    {
        "value": "FO",
        "text": "페로 제도"
    },
    {
        "value": "PE",
        "text": "페루"
    },
    {
        "value": "PT",
        "text": "포르투갈"
    },
    {
        "value": "FK",
        "text": "포클랜드 제도"
    },
    {
        "value": "PL",
        "text": "폴란드"
    },
    {
        "value": "PR",
        "text": "푸에르토리코"
    },
    {
        "value": "FR",
        "text": "프랑스"
    },
    {
        "value": "GF",
        "text": "프랑스령 기아나"
    },
    {
        "value": "TF",
        "text": "프랑스령 남부와 남극 지역"
    },
    {
        "value": "PF",
        "text": "프랑스령 폴리네시아"
    },
    {
        "value": "FJ",
        "text": "피지"
    },
    {
        "value": "FI",
        "text": "핀란드"
    },
    {
        "value": "PH",
        "text": "필리핀"
    },
    {
        "value": "PN",
        "text": "핏케언 제도"
    },
    {
        "value": "HM",
        "text": "허드 맥도널드 제도"
    },
    {
        "value": "HU",
        "text": "헝가리"
    },
    {
        "value": "VA",
        "text": "홀리 시"
    },
    {
        "value": "HK",
        "text": "홍콩"
    }
]
