import CloseIcon from "@mui/icons-material/Close";
import { Grid } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useSetRecoilState } from "recoil";
import { queryClient } from "../..";
import { getBusinessCategories } from "../../api/BusinessCategory";
import {
  getGbtGroup,
  postMerchantInitialInfo,
  putMerchantStatus
} from "../../api/Merchants";
import { inputPriceFormat } from "../../libs/format-utils";
import isModalOpen from "../../recoil/isModalOpen/atom";

interface AdminEditForm {
  inChargeID: number;
  id: number;
  annualFee: {
    price: string;
    annualType: string;
    note: string;
  };
  registrationFee: string;
  registrationFeeType: string;
  registrationFeeNote: string;
  contractPeriod: string;
  deposit: string;
  depositType: string;
  depositNote: string;
  grade: "일반" | "영세" | "중소1" | "중소2" | "중소3";
  merchantFee: {
    grade: string;
    optatumFee: string;
    feeType: string;
    method?: string;
    note?: string;
  }[];
  mccType:
    | "일반"
    | "학원"
    | "병원"
    | "문화"
    | "비영리기부금"
    | "비영리강의료"
    | "정기";
}

interface Props {
  closeModal: () => void;
  merchantId: any;
}

const BusinessSizeTypes = [
  { type: "일반" },
  { type: "영세" },
  { type: "중소1" },
  { type: "중소2" },
  { type: "중소3" }
];

const MerchantsAdminEdit = ({ merchantId, closeModal }: Props) => {
  // ===================================================================================================================
  // 리코일 스테이트
  // ===================================================================================================================
  const setIsModalOpen = useSetRecoilState(isModalOpen);

  // ===================================================================================================================
  // 프롬프트 스테이트
  // ===================================================================================================================
  const [openPrompt, setOpenPrompt] = useState(null);
  const handleOpenPrompt = (type: any) => {
    setOpenPrompt(type);
  };
  const handleClosePrompt = () => {
    setOpenPrompt(null);
  };
  // ===================================================================================================================
  // 리액트 쿼리
  // ===================================================================================================================
  const { data, isLoading } = useQuery(["/groups/1"], () => getGbtGroup(), {});

  // ===================================================================================================================
  // react hook form
  // ===================================================================================================================
  const { control, register, handleSubmit, setValue, getValues, watch } =
    useForm<AdminEditForm>({
      defaultValues: {
        inChargeID: 0,
        id: 0,
        annualFee: {
          price: "0",
          annualType: "정산",
          note: ""
        },
        registrationFee: "0",
        registrationFeeType: "정산",
        registrationFeeNote: "",
        contractPeriod: "",
        deposit: "0",
        depositType: "보험",
        depositNote: "",
        grade: "일반",
        merchantFee: [
          {
            grade: "일반",
            optatumFee: "0",
            feeType: "PERCENT",
            method: "CARD",
            note: ""
          }
        ],
        mccType: "일반"
      },
      mode: "onChange"
    });
  // ===================================================================================================================
  // 리액트쿼리: 어드민 수정 사항 저장
  // ===================================================================================================================
  const { mutate } = useMutation(postMerchantInitialInfo, {
    onSuccess: () => {
      setIsModalOpen({
        value: true,
        position: "top",
        alertSeverity: "success",
        message: "심사가 완료 되었습니다."
      });
    },
    onError: (error: any) => {
      setIsModalOpen({
        value: true,
        position: "top",
        alertSeverity: "error",
        message: error?.response?.data?.message
      });
    }
  });
  // ===================================================================================================================
  // 리액트쿼리: 어드민 심사 상태 업데이트
  // ===================================================================================================================
  const { mutate: updateStatus } = useMutation(putMerchantStatus, {
    onSuccess: () => {
      // merchantId로 시작하는 키로 모든 쿼리 무효화
      // 심사상태 값이 바뀌면 가맹 현황 refresh
      queryClient.invalidateQueries(merchantId);
      setIsModalOpen({
        value: true,
        position: "top",
        alertSeverity: "success",
        message: "심사 상태가 변경 되었습니다."
      });
    },
    onError: (error: any) => {
      setIsModalOpen({
        value: true,
        position: "top",
        alertSeverity: "error",
        message: error?.response?.data?.message
      });
    }
  });

  /*********************************************************************************************************************
   * 업종 조회
   ********************************************************************************************************************/
  const { data: businessCategories } = useQuery(
    ["/businessCategories"],
    getBusinessCategories,
    {}
  );

  // 어드민 수정 가능 폼 - 심사 완료
  const onSubmit = () => {
    if (isLoading) return;
    const merchantFeeValues = getValues("merchantFee")[0];
    const params = {
      ...getValues(),
      id: merchantId,
      annualFee: [
        {
          ...getValues("annualFee"),
          price: Number(getValues("annualFee.price").replace(/,/g, ""))
        }
      ],
      registrationFee: Number(getValues("registrationFee").replace(/,/g, "")),
      contractPeriod: Number(getValues("contractPeriod")),
      deposit: Number(getValues("deposit").replace(/,/g, "")),
      merchantFee: [
        {
          optatumFee: Number(merchantFeeValues?.optatumFee) || 0,
          grade: getValues("grade") || "",
          feeType: merchantFeeValues?.feeType || "",
          method: merchantFeeValues?.method || "",
          note: merchantFeeValues?.note || ""
        }
      ],
      mccType: getValues("mccType")
    };
    mutate(params);
    // 어드민 심사 상태 업데이트 - 심사 완료
    const merchantStatusUpdateForm = {
      id: merchantId,
      status: "계약심사대기"
    };
    updateStatus(merchantStatusUpdateForm);
    // 심사완료 클릭 시 모달창 닫힘
    closeModal();
  };

  // 어드민 수정 가능 폼 - 반려
  const onClickDenied = () => {
    const merchantStatusUpdateForm = {
      id: merchantId,
      status: "가맹심사반려"
    };
    updateStatus(merchantStatusUpdateForm);
    // 반려 클릭 시 모달창 닫힘
    closeModal();
  };

  const inputPercentFormat = (value: string) => {
    // 먼저 콤마의 개수를 파악한다
    const matched = value.match(/\./g);
    // 콤마가 2개 이상일땐 두번째 콤마는 제거하고 replace 함수를 실행한다
    if (matched !== null && matched.length >= 2) {
      const secondCommaIndex = value.lastIndexOf(".");
      const removedCommaValue =
        value.substring(0, secondCommaIndex) +
        value.substring(secondCommaIndex + 1, value.length);
      return removedCommaValue.replace(/[^0-9.]/g, "");
    } else {
      return value.replace(/[^0-9.]/g, ""); // 숫자와 콤마만 허용한다
    }

    // let decimalCount = 0;
    // let result = "";
    // for (let char of value) {
    //   if (char === ".") {
    //     decimalCount += 1;
    //     if (decimalCount <= 1) {
    //       result += char;
    //     }
    //   } else if (Number(char) >= 0 && Number(char) <= 9) {
    //     result += char;
    //   }
    // }
    // return result;
  };

  const inChargeID = watch("inChargeID");
  const contractPeriod = watch("contractPeriod");

  return (
    <>
      <Stack
        sx={{
          boxShadow: "0 -8px 10px -10px rgba(204, 204, 204, 0.603)",
          p: 3
        }}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            {/* 담당자 선택 */}
            <Grid item xs={12} sm={3}>
              <FormControl size="small">
                <Controller
                  name="inChargeID"
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      select
                      label="담당자 선택"
                      value={value}
                      onChange={onChange}
                      required
                      size="small"
                      sx={{ width: 276 }}
                      fullWidth
                    >
                      <MenuItem value={0}>담당자 선택</MenuItem>
                      {data?.content.map((group) => (
                        <MenuItem key={group.userId} value={group.userId}>
                          {group.userName}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                ></Controller>
              </FormControl>
            </Grid>
            {/* 계약년수 */}
            <Grid item xs={12} sm={3}>
              <TextField
                id="contractPeriod"
                label="계약년수"
                required
                {...register("contractPeriod", { required: true })}
                size="small"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">년</InputAdornment>
                  )
                }}
                onChange={(e) => {
                  setValue("contractPeriod", e.target.value);
                }}
                fullWidth
              />
            </Grid>
            {/* 가맹등급 */}
            <Grid item xs={12} sm={3}>
              <FormControl size="small">
                <Controller
                  name="grade"
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      select
                      label="가맹 등급"
                      value={value}
                      onChange={onChange}
                      required
                      size="small"
                      sx={{ width: 276 }}
                    >
                      {BusinessSizeTypes.map((BusinessSizeType) => (
                        <MenuItem
                          key={BusinessSizeType.type}
                          value={BusinessSizeType.type}
                        >
                          {BusinessSizeType.type}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                ></Controller>
              </FormControl>
            </Grid>
            {/* 결제업종 */}
            <Grid item xs={12} sm={3}>
              <FormControl size="small">
                <Controller
                  name="mccType"
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      select
                      label="결제 업종"
                      value={value}
                      onChange={onChange}
                      required
                      size="small"
                      sx={{ width: 276 }}
                    >
                      {businessCategories?.content.map((category) => (
                        <MenuItem value={category} key={category}>
                          {category}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                ></Controller>
              </FormControl>
            </Grid>
            {/* 연회비 */}
            <Grid item xs={14} sm={2}>
              <TextField
                id="price"
                label="연회비"
                required
                {...register("annualFee.price", { required: true })}
                size="small"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">원</InputAdornment>
                  )
                }}
                onChange={(e) => {
                  setValue("annualFee.price", inputPriceFormat(e.target.value));
                }}
                fullWidth
              />
            </Grid>
            {/* 연회비 타입 */}
            <Grid item xs={14} sm={2}>
              <FormControl size="small">
                <Controller
                  name="annualFee.annualType"
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      select
                      label="연회비 타입"
                      value={value}
                      onChange={onChange}
                      required
                      size="small"
                      sx={{ width: 178 }}
                    >
                      <MenuItem value="정산">정산</MenuItem>
                      <MenuItem value="납부">납부</MenuItem>
                      <MenuItem value="면제">면제</MenuItem>
                    </TextField>
                  )}
                ></Controller>
              </FormControl>
            </Grid>
            <Grid item xs={14} sm={2}>
              <TextField
                id="annualFee.note"
                {...register("annualFee.note")}
                label="비고"
                size="small"
              >
                비고
              </TextField>
            </Grid>
            {/* 신규 가입비 */}
            <Grid item xs={12} sm={2}>
              <TextField
                id="registrationFee"
                label="신규 가입비"
                required
                {...register("registrationFee", { required: true })}
                size="small"
                onChange={(e) => {
                  setValue("registrationFee", inputPriceFormat(e.target.value));
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">원</InputAdornment>
                  )
                }}
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <FormControl size="small">
                <Controller
                  name="registrationFeeType"
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      select
                      label="신규 가입비 타입"
                      value={value}
                      onChange={onChange}
                      required
                      size="small"
                      sx={{ width: 178 }}
                    >
                      <MenuItem value="정산">정산</MenuItem>
                      <MenuItem value="납부">납부</MenuItem>
                      <MenuItem value="면제">면제</MenuItem>
                    </TextField>
                  )}
                ></Controller>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={2}>
              <TextField
                id="registrationFeeNote"
                {...register("registrationFeeNote")}
                label="비고"
                size="small"
              >
                비고
              </TextField>
            </Grid>
            {/* 보증보험 */}
            <Grid item xs={12} sm={2}>
              <TextField
                id="deposit"
                label="보증 보험 금액"
                required
                {...register("deposit", { required: true })}
                size="small"
                onChange={(e) => {
                  setValue("deposit", inputPriceFormat(e.target.value));
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">원</InputAdornment>
                  )
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <FormControl size="small">
                <Controller
                  name="depositType"
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      select
                      label="보증 보험 타입"
                      value={value}
                      onChange={onChange}
                      required
                      size="small"
                      sx={{ width: 178 }}
                    >
                      <MenuItem value="보험">보험</MenuItem>
                      <MenuItem value="예치금">예치금</MenuItem>
                    </TextField>
                  )}
                ></Controller>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={2}>
              <TextField
                id="depositNote"
                {...register("depositNote")}
                label="비고"
                size="small"
              >
                비고
              </TextField>
            </Grid>
            {/* 수수료 */}
            <Grid item xs={12} sm={2}>
              <Controller
                name={`merchantFee.${0}.optatumFee`}
                control={control}
                rules={{ required: true }}
                render={({ field: { value, onChange } }) => (
                  <TextField
                    label="수수료"
                    required
                    size="small"
                    value={value}
                    onChange={(e) => {
                      const formattedValue = inputPercentFormat(e.target.value);
                      setValue(`merchantFee.${0}.optatumFee`, formattedValue);
                      onChange(formattedValue);
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">%</InputAdornment>
                      )
                    }}
                  />
                )}
              ></Controller>
            </Grid>

            <Grid item xs={12} sm={2}>
              <TextField
                id={`merchantFee.${0}.note`}
                {...register(`merchantFee.${0}.note`)}
                label="비고"
                size="small"
              >
                비고
              </TextField>
            </Grid>
            <Grid item xs={12} sm={2} />
          </Grid>
          <Stack
            direction={"row"}
            justifyContent="space-between"
            gap={2}
            pt={2}
          >
            <Button
              type="button"
              variant="outlined"
              fullWidth
              onClick={() => handleOpenPrompt("가맹심사반려")}
            >
              반려
            </Button>
            <Button
              type="button"
              variant="contained"
              sx={{ color: "white" }}
              fullWidth
              disabled={!inChargeID || !contractPeriod}
              onClick={() => handleOpenPrompt("가맹심사완료")}
            >
              가맹심사완료
            </Button>
          </Stack>
        </form>
      </Stack>
      {/* 심사 상태 프롬프트 - 반려 */}
      <Dialog open={openPrompt === "가맹심사반려"} fullWidth maxWidth="xs">
        <DialogTitle>가맹점 심사 상태 변경</DialogTitle>

        <IconButton
          onClick={handleClosePrompt}
          sx={{
            position: "absolute",
            right: 8,
            top: 12
          }}
        >
          <CloseIcon />
        </IconButton>

        <DialogContent>
          <DialogContentText gutterBottom>
            심사상태를 변경하면 수정이 불가 합니다.
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button
            type="button"
            variant="contained"
            onClick={onClickDenied}
            sx={{ color: "white" }}
            fullWidth
          >
            가맹심사반려
          </Button>
        </DialogActions>
      </Dialog>

      {/* 심사 상태 프롬프트 - 완료 */}
      <Dialog open={openPrompt === "가맹심사완료"} fullWidth maxWidth="xs">
        <DialogTitle>가맹점 심사 상태 변경</DialogTitle>

        <IconButton
          onClick={handleClosePrompt}
          sx={{
            position: "absolute",
            right: 8,
            top: 12
          }}
        >
          <CloseIcon />
        </IconButton>

        <DialogContent>
          <DialogContentText gutterBottom>
            심사상태를 변경하면 수정이 불가 합니다.
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button
            variant="contained"
            sx={{ color: "white" }}
            fullWidth
            onClick={onSubmit}
          >
            가맹심사완료
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
export default MerchantsAdminEdit;
