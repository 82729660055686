import CloseIcon from "@mui/icons-material/Close";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useSetRecoilState } from "recoil";
import { downloadFile, getMerchantsDetail } from "../../api/Merchants";
import TestPage from "../../pages/PreviewPage";
import isModalOpen from "../../recoil/isModalOpen/atom";
import ModalTriggerButton from "../PopupTriggerButton";
import CancelContractModal from "./CancelContractModal";
import ExtendContractModal from "./ExtendContractModal";
import MerchantModifyModal from "./MerchantModifyModal";

interface Props {
  isOpen: boolean;
  closeModal: () => void;
  merchantId: string;
}

const ClientMerchantDetailModal = ({
  isOpen,
  closeModal,
  merchantId
}: Props) => {
  // ===================================================================================================================
  // 리코일 스테이트
  // ===================================================================================================================
  const setIsModalOpen = useSetRecoilState(isModalOpen);

  // ===================================================================================================================
  // 컴포넌트 스테이트
  // ===================================================================================================================
  const [isButtonEnabled, setIsButtonEnabled] = useState(false);

  // ===================================================================================================================
  // 리액트 쿼리
  // ===================================================================================================================
  const { data, isLoading } = useQuery(
    [`/merchants/${merchantId}`],
    () => getMerchantsDetail({ merchantId }),
    {
      enabled: isOpen
    }
  );
  const close = () => {
    closeModal();
  };

  // ===================================================================================================================
  // 파일 다운로드
  // ===================================================================================================================
  // 인코딩된 파일 이름을 디코딩한다
  // 파일 이름이 빈 문자열이 아니면 decodeURIComponent함수로 디코딩된 문자열 반환
  const decodeFileName = (incodedFileName: string) => {
    if (incodedFileName === "") return;
    return decodeURIComponent(incodedFileName);
  };

  // 파일 다운로드
  const { isLoading: isFileDownloading, mutate } = useMutation(downloadFile, {
    onSuccess: (response) => {
      // 파일 이름을 가져온다
      const contentDisposition = response.headers["content-disposition"];
      if (!contentDisposition) return;

      // 파일 이름을 추출한다
      const [fileNameMatch] = contentDisposition
        .split(";")
        .filter((str: any) => str.includes("filename"));

      if (!fileNameMatch) return;

      // 인코딩된 파일이름을 디코딩한다
      const [, incodedFileName] = fileNameMatch.split("=");
      const decodedFileName = decodeFileName(incodedFileName);

      if (!decodedFileName) return;

      // 다운로드할 파일의 url을 생성한다
      const url = window.URL.createObjectURL(new Blob([response.data]));
      // 앵커 태그를 생성하고 setAttribute로 다운로드 속성을 설정한다
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `${decodedFileName.trim().replaceAll('"', "")}`
      );
      // 링크를 문서에 추가하고 `link.click`으로 다운로드를 시작한다
      document.body.appendChild(link);
      link.click();
      setIsModalOpen({
        value: true,
        position: "top",
        alertSeverity: "success",
        message: "파일 다운로드가 시작 되었습니다."
      });
    },
    onError: (error: any) => {
      setIsModalOpen({
        value: true,
        position: "top",
        alertSeverity: "error",
        message: error?.response?.data?.message
      });
    }
  });

  const onClickFileDownload = (fileId: number) => {
    if (isFileDownloading) return;
    mutate({ fileId: fileId });
  };

  // ===================================================================================================================
  // 계약 종료일 기준 60일 전 '계약해지', '계약승인' 버튼 활성화
  // ===================================================================================================================
  // data.content.endDate로 부터 60일 전인지를 체크하여 버튼 활성화/비활성화 상태를 업데이트한다.
  useEffect(() => {
    if (data?.content.endDate) {
      const endDate = new Date(data.content.endDate);
      const currentDate = new Date();
      const sixtyDaysBeforeEndDate = new Date(endDate);
      sixtyDaysBeforeEndDate.setDate(endDate.getDate() - 60);

      if (currentDate >= sixtyDaysBeforeEndDate) {
        setIsButtonEnabled(true);
      } else {
        setIsButtonEnabled(false);
      }
    }
  }, [data]);

  return (
    <>
      <Dialog open={isOpen} fullWidth maxWidth="lg">
        <DialogTitle
          sx={{ boxShadow: "0px 5px 14px -1px rgba(204, 204, 204, 0.5)" }}
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          가맹점 상세 정보
          <Stack direction={"row"} spacing={3}>
            {data?.content.status === "계약승인" ? (
              <>
                <ModalTriggerButton
                  size="medium"
                  color="primary"
                  modal={<CancelContractModal merchantId={merchantId} />}
                  disabled={!isButtonEnabled}
                >
                  계약 해지
                </ModalTriggerButton>
                <ModalTriggerButton
                  size="medium"
                  modal={<ExtendContractModal merchantId={merchantId} />}
                  disabled={!isButtonEnabled}
                >
                  계약 연장
                </ModalTriggerButton>

                <ModalTriggerButton
                  size="medium"
                  color="secondary"
                  modal={
                    <MerchantModifyModal
                      merchantData={data}
                      merchantId={merchantId}
                    />
                  }
                >
                  정보 수정
                </ModalTriggerButton>
              </>
            ) : null}
            <IconButton onClick={close}>
              <CloseIcon />
            </IconButton>
          </Stack>
        </DialogTitle>

        {!isLoading && data?.content ? (
          <>
            <DialogContent>
              <Stack gap={2} sx={{ pt: 2.5 }}>
                {/* 사업자 정보 ===================================================================================== */}
                <Box
                  sx={{
                    border: "1px solid #E4E4E7",
                    borderRadius: 2,
                    overflow: "hidden"
                  }}
                >
                  <Stack
                    direction={"row"}
                    justifyContent="center"
                    alignItems="center"
                    gap={1}
                    sx={{
                      py: 1,
                      borderBottom: "1px solid #E4E4E7",
                      backgroundColor: "#f7f7f7"
                    }}
                  >
                    <Typography>사업자 정보</Typography>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: "bold",
                        color: "#666"
                      }}
                    >
                      ({data?.content?.merchantInformation?.businessType}사업자)
                    </Typography>
                  </Stack>
                  <Stack
                    gap={1.5}
                    sx={{
                      px: 4,
                      py: 3
                    }}
                  >
                    <Stack direction="row" sx={{ pl: 1 }}>
                      <Stack direction="row" sx={{ width: 350 }}>
                        <Typography>가맹점명:</Typography>
                        <Typography sx={{ ml: 1 }}>
                          {data?.content?.mallName}
                        </Typography>
                      </Stack>
                      <Stack direction="row" sx={{ width: 350 }}>
                        <Typography>| 사업자 등록증 상호명:</Typography>
                        <Typography sx={{ ml: 1 }}>
                          {data?.content?.merchantInformation.businessName}
                        </Typography>
                      </Stack>
                      <Stack direction="row">
                        <Typography>| 사업자등록번호:</Typography>
                        <Typography sx={{ ml: 1 }}>
                          {
                            data?.content?.merchantInformation
                              ?.registrationNumber
                          }
                        </Typography>
                      </Stack>
                    </Stack>
                    {data?.content?.merchantInformation?.businessType ===
                    "법인" ? (
                      <Stack direction="row" sx={{ pl: 1 }}>
                        <Stack direction="row" sx={{ width: 350 }}>
                          <Typography>영문상호:</Typography>
                          <Typography sx={{ ml: 1 }}>
                            {data?.content?.mallNameEn}
                          </Typography>
                        </Stack>
                        <Stack direction="row">
                          <Typography>| 법인등록번호:</Typography>
                          <Typography sx={{ ml: 1 }}>
                            {
                              data?.content?.merchantInformation
                                ?.corporationNumber
                            }
                          </Typography>
                        </Stack>
                      </Stack>
                    ) : (
                      ""
                    )}
                    <Stack direction="row" sx={{ pl: 1 }}>
                      <Typography>사업장 주소:</Typography>
                      <Typography sx={{ ml: 1 }}>
                        {data?.content?.merchantInformation?.address
                          .split(" | ")
                          .join(", ")}
                      </Typography>
                    </Stack>

                    {data.content.merchantInformation.headOfficeAddress ? (
                      <Stack direction="row" sx={{ pl: 1 }}>
                        <Typography>본점 주소:</Typography>
                        <Typography sx={{ ml: 1 }}>
                          {data?.content?.merchantInformation?.headOfficeAddress
                            .split(" | ")
                            .join(", ")}
                        </Typography>
                      </Stack>
                    ) : (
                      <></>
                    )}

                    <Stack direction="row" sx={{ pl: 1 }}>
                      <Stack direction="row" sx={{ width: 350 }}>
                        <Typography>가맹점 전화번호:</Typography>
                        <Typography sx={{ ml: 1 }}>
                          {data?.content?.merchantInformation?.phone}
                        </Typography>
                      </Stack>
                      <Stack direction="row">
                        <Typography>| 가맹점 팩스번호:</Typography>
                        <Typography sx={{ ml: 1 }}>
                          {data?.content?.merchantInformation?.fax}
                        </Typography>
                      </Stack>
                    </Stack>
                    <Stack direction="row" sx={{ pl: 1 }}>
                      <Typography>업태:</Typography>
                      <Typography sx={{ ml: 1 }}>
                        {data?.content?.merchantInformation?.businessConditions}
                      </Typography>
                    </Stack>
                    <Stack direction="row" sx={{ pl: 1 }}>
                      <Typography>업종:</Typography>
                      <Typography sx={{ ml: 1 }}>
                        {data?.content?.merchantInformation?.merchantCategory}
                      </Typography>
                    </Stack>
                    <Stack direction="row" sx={{ pl: 1 }}>
                      <Stack direction="row" sx={{ width: 350 }}>
                        <Typography>통신판매번호:</Typography>
                        <Typography sx={{ ml: 1 }}>
                          {
                            data?.content?.merchantInformation
                              ?.mailOrderSalesNumber
                          }
                        </Typography>
                      </Stack>
                      <Stack direction="row">
                        <Typography>| 홈페이지:</Typography>
                        <Typography sx={{ ml: 1 }}>
                          {data?.content?.mallUrl}
                        </Typography>
                      </Stack>
                    </Stack>
                    <Stack direction="row" sx={{ pl: 1 }}>
                      <Stack direction="row" sx={{ width: 350 }}>
                        <Typography>가맹점 IP:</Typography>
                        <Typography sx={{ ml: 1 }}>
                          {data?.content?.mallIp}
                        </Typography>
                      </Stack>

                      {data.content.merchantInformation.introductionCompany ? (
                        <Stack direction="row">
                          <Typography>| 호스팅:</Typography>
                          <Typography sx={{ ml: 1 }}>
                            {
                              data?.content?.merchantInformation
                                ?.introductionCompany
                            }
                          </Typography>
                        </Stack>
                      ) : (
                        <></>
                      )}
                    </Stack>

                    <Stack direction="row" sx={{ pl: 1 }}>
                      <Stack direction="row" sx={{ width: 350 }}>
                        <Typography>테스트 ID:</Typography>
                        <Typography sx={{ ml: 1 }}>
                          {data?.content?.merchantInformation?.testId}
                        </Typography>
                      </Stack>
                      <Stack direction="row">
                        <Typography>| 테스트 PW:</Typography>
                        <Typography sx={{ ml: 1 }}>
                          {data?.content?.merchantInformation?.testPassword}
                        </Typography>
                      </Stack>
                    </Stack>
                  </Stack>
                </Box>
                {/* 대표자 정보 ===================================================================================== */}
                <Box
                  sx={{
                    border: "1px solid #E4E4E7",
                    borderRadius: 2,
                    overflow: "hidden"
                  }}
                >
                  <Stack
                    justifyContent="center"
                    alignItems="center"
                    sx={{
                      py: 1,
                      borderBottom: "1px solid #E4E4E7",
                      backgroundColor: "#f7f7f7"
                    }}
                  >
                    <Typography>대표자 정보</Typography>
                  </Stack>
                  <Stack
                    sx={{
                      px: 4,
                      py: 3
                    }}
                  >
                    {data?.content?.merchantOwnerInformation?.map(
                      (owner, index) => (
                        <Stack key={owner.id} gap={1.5}>
                          <Stack direction={"row"} sx={{ pl: 1 }}>
                            <Stack direction={"row"} sx={{ width: 350 }}>
                              <Typography>성명:</Typography>
                              <Typography sx={{ ml: 1 }}>
                                {owner.name}
                              </Typography>
                            </Stack>
                            <Stack direction={"row"}>
                              <Typography>| 영문성명:</Typography>
                              <Typography sx={{ ml: 1 }}>
                                {owner.nameEn}
                              </Typography>
                            </Stack>
                          </Stack>
                          <Stack direction={"row"} sx={{ pl: 1 }}>
                            <Stack direction={"row"} sx={{ width: 350 }}>
                              <Typography>전화번호:</Typography>
                              <Typography sx={{ ml: 1 }}>
                                {owner.phone}
                              </Typography>
                            </Stack>
                            <Stack direction={"row"} sx={{ width: 350 }}>
                              <Typography>| 생년월일:</Typography>
                              <Typography sx={{ ml: 1 }}>
                                {owner.birth}
                              </Typography>
                            </Stack>
                            <Stack direction={"row"}>
                              <Typography>| 국적:</Typography>
                              <Typography sx={{ ml: 1 }}>
                                {owner.nationality}
                              </Typography>
                            </Stack>
                          </Stack>
                          <Stack direction={"row"} sx={{ pl: 1 }}>
                            <Typography>주소:</Typography>
                            <Typography sx={{ ml: 1 }}>
                              {owner.address.split(" | ").join(", ")}
                            </Typography>
                          </Stack>
                          {index !==
                            data?.content?.merchantOwnerInformation?.length -
                              1 && <Divider sx={{ mb: 3, mt: 1 }} />}
                        </Stack>
                      )
                    )}
                  </Stack>
                </Box>
                {/* 은행 정보 ======================================================================================= */}
                <Box
                  sx={{
                    border: "1px solid #E4E4E7",
                    borderRadius: 2,
                    overflow: "hidden"
                  }}
                >
                  <Stack
                    alignItems="center"
                    sx={{
                      py: 1,
                      borderBottom: "1px solid #E4E4E7",
                      backgroundColor: "#f7f7f7"
                    }}
                  >
                    <Typography>은행 정보</Typography>
                  </Stack>

                  <Box
                    sx={{
                      px: 4,
                      py: 5
                    }}
                  >
                    <Stack direction={"row"} sx={{ pl: 1 }}>
                      <Stack direction={"row"} sx={{ width: 350 }}>
                        <Typography>은행명:</Typography>
                        <Typography sx={{ ml: 1 }}>
                          {data?.content?.merchantBankInformation?.bankName}
                        </Typography>
                      </Stack>
                      <Stack direction={"row"} sx={{ width: 350 }}>
                        <Typography>| 은행 계좌번호:</Typography>
                        <Typography sx={{ ml: 1 }}>
                          {data?.content?.merchantBankInformation?.accountCode}
                        </Typography>
                      </Stack>
                      <Stack direction={"row"}>
                        <Typography>| 계좌 소유주:</Typography>
                        <Typography sx={{ ml: 1 }}>
                          {
                            data?.content?.merchantBankInformation
                              ?.accountHolder
                          }
                        </Typography>
                      </Stack>
                    </Stack>
                  </Box>
                </Box>

                {/* 담당자 정보 ===================================================================================== */}
                <Box
                  sx={{
                    border: "1px solid #E4E4E7",
                    borderRadius: 2,
                    overflow: "hidden"
                  }}
                >
                  <Stack
                    alignItems="center"
                    sx={{
                      py: 1,
                      borderBottom: "1px solid #E4E4E7",
                      backgroundColor: "#f7f7f7"
                    }}
                  >
                    <Typography>담당자 정보</Typography>
                  </Stack>
                  <Stack
                    gap={1.5}
                    sx={{
                      pt: 2,
                      px: 4
                    }}
                  >
                    {data.content.merchantInChargeInformation?.map(
                      (inCharge, index) => (
                        <div key={index}>
                          <Stack direction={"row"} sx={{ pl: 1 }}>
                            <Stack direction={"row"} sx={{ width: 350 }}>
                              <Typography>성명:</Typography>
                              <Typography sx={{ ml: 1 }}>
                                {inCharge.name}
                              </Typography>
                            </Stack>
                            <Stack direction={"row"}>
                              <Typography>| 담당역할:</Typography>
                              <Typography sx={{ ml: 1 }}>
                                {inCharge.role}
                              </Typography>
                            </Stack>
                          </Stack>
                          <Stack
                            direction={"row"}
                            sx={{ pl: 1, mt: 1.5, mb: 2.5 }}
                          >
                            <Stack direction={"row"} sx={{ width: 350 }}>
                              <Typography>전화번호:</Typography>
                              <Typography sx={{ ml: 1 }}>
                                {inCharge.phone}
                              </Typography>
                            </Stack>
                            <Stack direction={"row"}>
                              <Typography>| 이메일:</Typography>
                              <Typography sx={{ ml: 1 }}>
                                {inCharge.email}
                              </Typography>
                            </Stack>
                          </Stack>

                          {/* Divider는 마지막 요소 이후에는 렌더링되지 않도록 조건 추가 */}
                          {index !==
                            data.content.merchantInChargeInformation.length -
                              1 && <Divider sx={{ my: 1 }} />}
                        </div>
                      )
                    )}
                  </Stack>
                </Box>

                {/* 파일 정보 ======================================================================================= */}
                <Box
                  sx={{
                    border: "1px solid #E4E4E7",
                    borderRadius: 2,
                    overflow: "hidden"
                  }}
                >
                  <Stack
                    justifyContent={"center"}
                    alignItems={"center"}
                    sx={{
                      py: 1,
                      borderBottom: "1px solid #E4E4E7",
                      backgroundColor: "#f7f7f7"
                    }}
                  >
                    <Typography>파일 정보</Typography>
                  </Stack>
                  <Box
                    sx={{
                      py: 2,
                      px: 4
                    }}
                  >
                    {data.content.merchantFileDto &&
                      data.content.merchantFileDto.map((file) => (
                        <Stack
                          direction={"row"}
                          alignItems="center"
                          key={file.id}
                        >
                          <Stack direction={"row"}>
                            <Typography sx={{ ml: 1 }}>{file.type}:</Typography>
                            <Typography sx={{ ml: 1 }}>
                              {file.fileName}
                            </Typography>
                          </Stack>
                          <IconButton
                            color="primary"
                            onClick={() => onClickFileDownload(file.id)}
                          >
                            <FileDownloadIcon />
                          </IconButton>
                        </Stack>
                      ))}
                  </Box>
                </Box>

                {/* 계약서 다운로드 ================================================================================== */}
                {data.content.status === "계약심사대기" ||
                data.content.status === "계약심사반려" ? (
                  <>
                    <TestPage data={data} />
                  </>
                ) : null}
              </Stack>
            </DialogContent>
          </>
        ) : (
          <Stack
            justifyContent={"center"}
            alignItems="center"
            sx={{ width: 1100, height: 900 }}
          >
            <Typography>데이터를 조회 중입니다.</Typography>
          </Stack>
        )}
      </Dialog>
    </>
  );
};
export default ClientMerchantDetailModal;
