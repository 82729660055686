import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import { useMutation } from "@tanstack/react-query";
import { useSetRecoilState } from "recoil";
import { queryClient } from "../..";
import { postBlockUser } from "../../api/Merchants";
import { BlockUserParams } from "../../api/Merchants/types";
import isModalOpen from "../../recoil/isModalOpen/atom";

interface Props {
  userId: number;
  closeModal?: () => void;
}

const UserUnLockModal = ({ closeModal, userId }: Props) => {
  // ===========================================================================
  // 리코일 스테이트
  // ===========================================================================
  const setIsModalOpen = useSetRecoilState(isModalOpen);

  // 사용자 계정 활성화
  const { mutate } = useMutation(postBlockUser, {
    onSuccess: () => {
      queryClient.invalidateQueries();
      setIsModalOpen({
        value: true,
        position: "top",
        alertSeverity: "success",
        message: "계정이 활성화되었습니다."
      });
    },
    onError: (error: any) => {
      setIsModalOpen({
        value: true,
        position: "top",
        alertSeverity: "error",
        message: error?.response?.data?.message
      });
    }
  });

  // ===========================================================================
  // 리액트 훅 폼
  // ===========================================================================
  // 사용자 계정 활성화 버튼
  const onClick :React.MouseEventHandler<HTMLButtonElement> = () => {
    const formData: BlockUserParams = {
      isUse: true,
      userId: userId,
      memo: ""
    };
    mutate(formData);
    closeModal?.();
  };

  return (
    <>
      {/* 비활성화 모달 */}
      <Dialog open={true} fullWidth maxWidth="xs">
        <DialogTitle>사용자 계정 활성화</DialogTitle>

        <IconButton
          onClick={closeModal}
          sx={{
            position: "absolute",
            right: 8,
            top: 12
          }}
        >
          <CloseIcon />
        </IconButton>

        <DialogContent>사용자 계정을 활성화 하시겠습니까?</DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            sx={{ color: "white" }}
            fullWidth
            onClick={onClick}
          >
            활성화 상태로 전환
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default UserUnLockModal;
