import PaymentsIcon from "@mui/icons-material/Payments";
import ListSubheader from "@mui/material/ListSubheader";
import ListItemLink from "../../NavLink";
//import ConnectWithoutContactIcon from "@mui/icons-material/ConnectWithoutContact";
import CalculateIcon from "@mui/icons-material/Calculate";
import HowToRegIcon from "@mui/icons-material/HowToReg";
import KeyIcon from "@mui/icons-material/Key";
import NotificationsIcon from "@mui/icons-material/Notifications";
import PlaylistAddCheckIcon from "@mui/icons-material/PlaylistAddCheck";
import ArticleIcon from "@mui/icons-material/Article";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";

// =====================================================================================================================
// Payment (거래)
// =====================================================================================================================
const PaymentList = [
  {
    component: <PaymentsIcon />,
    componentActive: <PaymentsIcon color="primary" />,
    primary: "거래 내역",
    to: "/payments"
  }
  // {
  //   component: <CalendarMonthIcon />,
  //   componentActive: <CalendarMonthIcon color="primary" />,
  //   primary: "매출 달력",
  //   to: "/payments/calendar"
  // }
];

export const PaymentListItems = () => (
  <>
    <ListSubheader component="div">거래</ListSubheader>
    {PaymentList.map((list, index) => (
      <ListItemLink
        to={list.to}
        primary={list.primary}
        icon={list.component}
        iconActive={list.componentActive}
        key={`Payment ${index}`}
      />
    ))}
  </>
);

// =====================================================================================================================
// Settlement (정산)
// =====================================================================================================================
const SettlementList = [
  {
    component: <CalculateIcon />,
    componentActive: <CalculateIcon color="primary" />,
    primary: "정산 내역",
    to: "/settlement"
  }
];

export const SettlementListItems = () => (
  <>
    <ListSubheader component="div">정산</ListSubheader>
    {SettlementList.map((list, index) => (
      <ListItemLink
        to={list.to}
        primary={list.primary}
        icon={list.component}
        iconActive={list.componentActive}
        key={`Payment ${index}`}
      />
    ))}
  </>
);

// =====================================================================================================================
// Management (관리)
// =====================================================================================================================
const ManagementList = [
  {
    component: <NotificationsIcon />,
    componentActive: <NotificationsIcon color="primary" />,
    primary: "알림 내역",
    to: "/notifications"
  },
  {
    component: <HowToRegIcon />,
    componentActive: <HowToRegIcon color="primary" />,
    primary: "가맹 등록",
    to: "/registrations"
  },
  {
    component: <PlaylistAddCheckIcon />,
    componentActive: <PlaylistAddCheckIcon color="primary" />,
    primary: "가맹 현황",
    to: "/client-merchant"
  },
  {
    component: <ArticleIcon />,
    componentActive: <ArticleIcon color="primary" />,
    primary: "계약 관리",
    to: "/merchant-contract-list"
  },
  {
    component: <ReceiptLongIcon />,
    componentActive: <ReceiptLongIcon color="primary" />,
    primary: "정보 수정 관리",
    to: "/merchant-modify"
  },
  {
    component: <KeyIcon />,
    componentActive: <KeyIcon color="primary" />,
    primary: "API 키 관리",
    to: "/api-key"
  }
];

export const ManagementListItems = () => (
  <>
    <ListSubheader component="div">관리</ListSubheader>
    {ManagementList.map((list, index) => (
      <ListItemLink
        to={list.to}
        primary={list.primary}
        icon={list.component}
        iconActive={list.componentActive}
        key={`Management ${index}`}
      />
    ))}
  </>
);
