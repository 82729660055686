import SaveIcon from "@mui/icons-material/Save";
import Box from "@mui/material/Box";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { useRecoilState, useSetRecoilState } from "recoil";
import {
  getPaymentsDetail,
  getPaymentsDetailExcel
} from "../../api/v2/Payments";
import {
  PaymentDetailData,
  PaymentTotalData
} from "../../api/v2/Payments/types";
import excelDownload from "../../libs/excel-download";
import { addCommas } from "../../libs/thousands-commas";
import isModalOpenAtom from "../../recoil/isModalOpen";
import { PaymentsDetailState } from "../../recoil/payments/atom";
import LoadingButton from "../LoadingButton";
import PaymentViewModal from "./PaymentViewModal";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import Skeleton from "@mui/material/Skeleton";
import Paper from "@mui/material/Paper";

interface PaymentColumnHeadCell {
  id: keyof PaymentDetailData;
  label: string;
  isTotalData: boolean;
  isSort: boolean;
}

const columns: PaymentColumnHeadCell[] = [
  {
    id: "mallId",
    label: "가맹점 ID",
    isTotalData: true,
    isSort: false
  },
  {
    id: "mallName",
    label: "가맹점명",
    isTotalData: false,
    isSort: false
  },
  {
    id: "businessName",
    label: "사업자 등록증 상호명",
    isTotalData: false,
    isSort: false
  },
  {
    id: "merchantGrade",
    label: "거래등급",
    isTotalData: false,
    isSort: false
  },
  {
    id: "createdAt",
    label: "거래 일자",
    isTotalData: true,
    isSort: true
  },
  {
    id: "approvalNum",
    label: "승인 번호",
    isTotalData: false,
    isSort: false
  },
  {
    id: "cardCompany",
    label: "카드사",
    isTotalData: false,
    isSort: false
  },
  {
    id: "status",
    label: "거래 상태",
    isTotalData: false,
    isSort: false
  },
  {
    id: "price",
    label: "거래 금액",
    isTotalData: true,
    isSort: false
  },
  {
    id: "purchaseStatus",
    label: "매입 상태",
    isTotalData: false,
    isSort: false
  },
  {
    id: "receiveDate",
    label: "매입일",
    isTotalData: false,
    isSort: false
  },
  {
    id: "transactionKey",
    label: "거래 KEY",
    isTotalData: false,
    isSort: false
  },
  {
    id: "paymentKey",
    label: "거래고유 KEY",
    isTotalData: false,
    isSort: false
  }
];

const TransactionDetail = ({ totalData }: any) => {
  // ========================================================================================================================
  // 리코일 스테이트
  // ===================================================================================================================
  const setIsModalOpen = useSetRecoilState(isModalOpenAtom);
  const [paymentsDetail, setPaymentsDetail] =
    useRecoilState(PaymentsDetailState);

  // ===================================================================================================================
  // 스테이트
  // ===================================================================================================================
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedPaymentKey, setSelectedPaymentKey] = useState<string | null>(
    null
  );
  // 오름차순, 내림차순
  const [sortOrder, setSortOrder] = useState<"ASC" | "DESC">("ASC");

  // ===================================================================================================================
  // 리액트 쿼리 - 거래 상세 조회
  // ===================================================================================================================
  const { data, isLoading } = useQuery(
    ["/payments/detail", paymentsDetail, sortOrder],
    () => getPaymentsDetail({ ...paymentsDetail, sort: sortOrder }),
    {
      onError: (error: any) => {
        setIsModalOpen({
          value: true,
          position: "top",
          alertSeverity: "error",
          message: error?.response?.data?.message
        });
      },
      select: (data) => ({
        ...data,
        content: data.content.map((paymentData: PaymentDetailData) => ({
          ...paymentData,
          price: `${addCommas(Number(paymentData.price))}`
        }))
      })
    }
  );

  // ===================================================================================================================
  // 리액트 쿼리 - 엑셀 다운로드
  // ===================================================================================================================
  const { isFetching, refetch } = useQuery(
    ["/payments/detail/excel", paymentsDetail],
    () => getPaymentsDetailExcel(paymentsDetail),
    {
      enabled: false,
      onSuccess: (data) => {
        const content = data?.content;
        const list = content.map((d: PaymentDetailData) => ({
          "가맹점 ID": d.mallId,
          가맹점명: d.mallName,
          "사업자 등록증 상호명": d.businessName,
          거래등급: d.merchantGrade,
          "거래 일자": d.createdAt,
          "승인 번호": d.approvalNum,
          카드사: d.cardCompany,
          "거래 상태": d.status,
          "거래 금액": `${addCommas(Number(d.price))}`,
          "매입 상태": d.purchaseStatus,
          매입일: d.receiveDate,
          "거래 KEY": d.transactionKey,
          "거래 고유KEY": d.paymentKey
        }));
        // 상세 조회 테이블 아래 합계 row도 같이 저장
        const totalList = totalData?.content.map((d: PaymentTotalData) => ({
          "가맹점 ID": d.mallId,
          가맹점명: "",
          "거래 일자": d.datePeriod,
          "승인 번호": "",
          카드사: "",
          "거래 상태": "",
          "거래 금액": d.transactionTotal,
          "매입 상태": "",
          매입일: "",
          "거래 KEY": "",
          "거래 고유KEY": ""
        }));
        // 기존 list에 totalList추가
        const combinedList = [...list];
        totalList?.forEach((item: any) => {
          combinedList.push(item);
        });

        excelDownload({
          list: combinedList,
          sheetName:
            paymentsDetail.mallName === ""
              ? "거래 상세 내역"
              : `거래 상세 내역_${paymentsDetail.mallName}`,
          fileName:
            paymentsDetail.mallName === ""
              ? "거래 상세 내역.xlsx"
              : `거래 상세 내역_${paymentsDetail.mallName}.xlsx`
        });
        setIsModalOpen({
          value: true,
          position: "top",
          alertSeverity: "success",
          message: "다운로드를 시작합니다."
        });
      },
      onError: (error: any) => {
        setIsModalOpen({
          value: true,
          position: "top",
          alertSeverity: "error",
          message: error?.response?.data?.message
        });
      }
    }
  );

  // ===================================================================================================================
  // 거래 상세 조회 - 엑셀 다운로드
  // ===================================================================================================================
  const handlePaymentsDetailExcel = async () => {
    if (!data?.content || data?.content?.length === 0) {
      setIsModalOpen({
        value: true,
        position: "top",
        alertSeverity: "error",
        message: "데이터가 없습니다."
      });
    } else {
      await refetch();
    }
  };

  // ===================================================================================================================
  // 페이징
  // ===================================================================================================================
  const totalPages = data?.pageable?.totalPages
    ? data?.pageable?.totalPages
    : 0;
  const handlePage = (event: React.ChangeEvent<unknown>, page: number) => {
    setPaymentsDetail((prevValue: any) => ({ ...prevValue, page: page - 1 }));
  };

  // ===================================================================================================================
  // 거래 내역 조회 영수증 모달창
  // ===================================================================================================================
  const handlePaymentClick = (paymentKey: string) => {
    setSelectedPaymentKey(paymentKey);
    setModalOpen(true);
  };

  // ===================================================================================================================
  // 정렬 아이콘 클릭 핸들러
  // ===================================================================================================================
  const handleSortClick = () => {
    setSortOrder((prevOrder) => (prevOrder === "ASC" ? "DESC" : "ASC"));
    setPaymentsDetail((prevValue: any) => ({
      ...prevValue,
      sortOrder: sortOrder
    }));
  };

  return (
    <Box>
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"end"}
        mb={1}
      >
        <Typography sx={{ fontWeight: "bold" }}>거래 상세 조회</Typography>
        <LoadingButton
          icon={<SaveIcon />}
          size="medium"
          fullWidth={false}
          color="secondary"
          variant="outlined"
          loading={isFetching}
          handleClick={handlePaymentsDetailExcel}
        >
          EXCEL 다운로드
        </LoadingButton>
      </Stack>

      <Paper
        sx={{
          border: "1px solid #F2F3F5",
          borderRadius: 3,
          overflow: "hidden"
        }}
      >
        <TableContainer sx={{ maxHeight: 550, minHeight: 550 }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow sx={{ backgroundColor: "#FBFBFB" }}>
                {columns.map((column) => (
                  <TableCell
                    variant="head"
                    align="center"
                    key={column.id}
                    sx={{
                      borderTop: "none",
                      whiteSpace: "nowrap",
                      backgroundColor: "#fbfbfb"
                    }}
                  >
                    {column.label}
                    {column.id === "createdAt" && (
                      <Box
                        component="span"
                        onClick={handleSortClick}
                        sx={{
                          cursor: "pointer",
                          display: "inline-flex",
                          verticalAlign: "middle",
                          ml: 1
                        }}
                      >
                        {sortOrder === "ASC" ? (
                          <ArrowUpwardIcon fontSize="small" />
                        ) : (
                          <ArrowDownwardIcon fontSize="small" />
                        )}
                      </Box>
                    )}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {/* 데이터 조회 중 - 스켈레톤 */}
              {isLoading &&
                [...Array(10)].map((_, index) => (
                  <TableRow key={index}>
                    {columns.map((column) => (
                      <TableCell align="center" key={column.id}>
                        <Skeleton variant="rectangular" height={25} />
                      </TableCell>
                    ))}
                  </TableRow>
                ))}

              {/* 데이터 조회 결과 */}
              {!isLoading && data?.content && data.content.length > 0
                ? data.content.map(
                    (paymentData: PaymentDetailData, index: number) => (
                      <TableRow
                        key={index}
                        sx={{
                          cursor: "pointer",
                          whiteSpace: "nowrap"
                        }}
                      >
                        {columns.map((column) => (
                          <TableCell
                            key={column.id}
                            align="center"
                            onClick={() =>
                              handlePaymentClick(paymentData.paymentKey)
                            }
                          >
                            {paymentData[column.id]}
                          </TableCell>
                        ))}
                      </TableRow>
                    )
                  )
                : !isLoading && (
                    <TableRow>
                      <TableCell sx={{ height: 500 }} colSpan={columns.length}>
                        <Typography align="center">
                          조회된 데이터가 없습니다.
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )}
            </TableBody>
          </Table>
        </TableContainer>
        <Box py={2}>
          <Pagination
            count={totalPages}
            variant="outlined"
            shape="rounded"
            page={paymentsDetail.page + 1}
            onChange={handlePage}
            sx={{ display: "flex", justifyContent: "center" }}
          />
        </Box>
      </Paper>

      {/* 영수증 모달창 */}
      <PaymentViewModal
        isOpen={modalOpen}
        setIsOpen={setModalOpen}
        paymentKeys={selectedPaymentKey}
      />
    </Box>
  );
};
export default TransactionDetail;
