import { instance } from "../../Instance";

interface TempPasswordParams {
  email: string;
}

export const sendTempPassword = async (
  tempPasswordParams: TempPasswordParams
) => {
  const { data } = await instance.post(
    "/bypass/find/password",
    tempPasswordParams
  );
  return data;
};
